import React, { useEffect } from "react";
import styledd from "styled-components";
import { Box } from "@mui/system";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import ImageIcon from "@mui/icons-material/Image";
import Grid from "@mui/material/Grid";
import { getInstaller_data_Action } from "../../Redux/Slices/Installers/InstallersSlice";
import { getSingleTicket } from "../../Redux/Slices/SingleTicketSlice/SingleTicketSlice";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MainTabsStyled } from "./installerDataStyle";
import Avatar from "@mui/material/Avatar";
import { deepOrange } from "@mui/material/colors";
import LottieAnimation from "../../Components/LottieAnimation/LottieAnimation";
import loading from "../../Assets/lottie/loading.json";

const StateInstallers = () => {
  const dispatch = useDispatch();
  const installersList = useSelector((state) => state.installers);
  const single_ticket = useSelector((state) => state.single_ticket);
  const { installer_id } = useParams();
  const { lead_id } = useParams();
  const { state_id } = useParams();

  useEffect(() => {
    dispatch(getSingleTicket(lead_id));
    const reqData = {
      installer_id: installer_id,
      state_id: state_id,
    };
    dispatch(getInstaller_data_Action(reqData));
  }, [lead_id]);
  return (
    <>
      {installersList?.isLodaing && single_ticket?.isLodaing ? (
        <LottieAnimation lotti={loading} width={180} height={180} />
      ) : (
        <Grid container columns={18}>
          <Grid item lg={4} md={6} xs={18}>
            <SideLeadStyled>
              <div className="mainInfo">
                {installersList?.isLodaing ? (
                  ""
                ) : (
                  <>
                    <Avatar
                      sx={{ bgcolor: deepOrange[500] }}
                      alt="Remy Sharp"
                      src="/broken-image.jpg"
                    >
                      {installersList?.installers?.data?.name?.charAt(0)}
                    </Avatar>
                    <Typography align="center">
                      {" "}
                      {installersList?.installers?.data?.name}
                    </Typography>
                  </>
                )}
              </div>

              <div className="LeadInfo">
                <ul>
                  <li>
                    <p style={{ color: "black" }}>Email :</p>
                    <span> {installersList?.installers?.data?.email} </span>
                  </li>

                  <li>
                    <p style={{ color: "black" }}>phone :</p>
                    <span> {installersList?.installers?.data?.phone} </span>
                  </li>
                  <li>
                    <p style={{ color: "black" }}>date of origination :</p>
                    <span>
                      {" "}
                      {
                        installersList?.installers?.data?.date_of_origination
                      }{" "}
                    </span>
                  </li>
                  <li>
                    <p style={{ color: "black" }}>company Name :</p>
                    <span>
                      {" "}
                      {installersList?.installers?.data?.company_name}{" "}
                    </span>
                  </li>
                  <li>
                    <p style={{ color: "black" }}>business fax :</p>
                    <span>
                      {" "}
                      {installersList?.installers?.data?.business_fax}{" "}
                    </span>
                  </li>
                  <li>
                    <p style={{ color: "black" }}>address :</p>
                    <span> {installersList?.installers?.data?.address} </span>
                  </li>
                </ul>
              </div>
              {installersList?.installers?.data?.Days &&
                installersList?.installers?.data?.Days?.length > 0 && (
                  <div className="LeadInfo">
                    <Typography variant="h6">Working Days</Typography>
                    {installersList?.installers?.data?.Days?.map((day) => (
                      <ul>
                        <li>
                          <span> {day?.name} </span>
                        </li>
                      </ul>
                    ))}
                  </div>
                )}
            </SideLeadStyled>
          </Grid>

          <Grid item lg={14} md={12} xs={18}>
            <>
              <MainTabsStyled>
                <Box
                  sx={{ width: "100%", typography: "body1" }}
                  style={{ backgroundColor: "white" }}
                >
                  <Grid style={{ padding: "20px" }}>
                    <h1>Licenses :</h1>
                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                      {installersList?.installers?.data?.business_licence &&
                      installersList?.installers?.data?.business_licence
                        ?.length > 0 ? (
                        installersList?.installers?.data?.business_licence?.map(
                          (business_licence) => (
                            <Grid item sm={6} xs={12}>
                              <CardContent
                                style={{
                                  background: "rgb(249, 245, 255)",
                                  border: "1px solid rgb(214, 187, 251)",
                                  borderRadius: "8px",
                                  padding: "1rem",
                                  margin: "0.5rem 0px",
                                }}
                              >
                                <Typography>
                                  {" "}
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: "#7e7ec0",
                                    }}
                                  >
                                    {" "}
                                    License Number :{" "}
                                  </span>{" "}
                                  {business_licence?.license_number}
                                </Typography>

                                <Typography>
                                  {" "}
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: "#7e7ec0",
                                    }}
                                  >
                                    {" "}
                                    License State :{" "}
                                  </span>{" "}
                                  {business_licence?.state}
                                </Typography>
                                <Typography>
                                  {" "}
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: "#7e7ec0",
                                    }}
                                  >
                                    {" "}
                                    License Type :{" "}
                                  </span>{" "}
                                  {business_licence?.license_type}
                                </Typography>
                                <Typography>
                                  {" "}
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: "#7e7ec0",
                                    }}
                                  >
                                    {" "}
                                    License Expiration Date :{" "}
                                  </span>{" "}
                                  {business_licence?.license_expiration_date}
                                </Typography>
                                {business_licence?.file?.license_img && (
                                  <Typography>
                                    {" "}
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        color: "#7e7ec0",
                                      }}
                                    >
                                      {" "}
                                      License Image :{" "}
                                    </span>{" "}
                                    <a
                                      href={business_licence?.file?.license_img}
                                      target="_blank"
                                    >
                                      <ImageIcon />{" "}
                                    </a>
                                  </Typography>
                                )}
                              </CardContent>
                            </Grid>
                          )
                        )
                      ) : (
                        <Grid
                          container
                          rowSpacing={1}
                          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        >
                          <Typography>There is no business licenc</Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </MainTabsStyled>
            </>
          </Grid>
        </Grid>
      )}
    </>
  );
};

const Value = styledd.span`
  color: rgb(136, 136, 136);
`;
const SideLeadStyled = styledd.div`
  margin: 14px 5px;
  background-color: white;
  border-radius: 5px;
  .mainInfo {
    padding: 1rem;
    text-align: center;
    border-bottom: 1px solid #777;
    display: flex;
    justify-content: space-around;
    &__icon {
      svg {
        font-size: 5rem;
        color: #777;
      }
    }
    &__title {
      margin-top: 15px;
    }
    .img {
      width: 55px;
      height: 55px;
    }
  }
  .LeadInfo {
    padding: 2rem;

    ul {
      li {
        color: #888888;
        padding: 0.2rem 0;
        margin-bottom: 5px;
      }
    }
  }
`;

export default StateInstallers;
