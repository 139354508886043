import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import call_supplier_Service from "./CallSupplierService";
import { toast } from "react-toastify";
const initialState = {
  callSuppliers: {},
  isError: false,
  isSuccess: false,
  isLodaing: false,
  massage: "",
};

export const store_call_suupliers = createAsyncThunk(
  "callSuppliers/post",
  async (data, thunkAPI) => {
    try {
      return await call_supplier_Service.store_call_suppliers(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      error?.response?.data?.errors?.map((err) =>
        toast.error(err.msg, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      );
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const get_call_suupliers_data = createAsyncThunk(
  "callSupplier/get",
  async (ticket_id, thunkAPI) => {
    try {
      return await call_supplier_Service.get_store_call_supplier_data(
        ticket_id
      );
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const update_call_suupliers = createAsyncThunk(
  "callSuppliers/post",
  async (data, thunkAPI) => {
    try {
      return await call_supplier_Service.edit_call_supplier(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          console.log(error.response , "error.response"),
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      error?.response?.data?.errors?.map((err) =>
        toast.error(err.msg, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      );
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const callSupplierSlice = createSlice({
  name: "callSuppliers",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLodaing = false;
      state.massage = "";
    },
  },
  extraReducers: {
    //store_call_suupliers
    [store_call_suupliers.pending]: (state) => {
      state.isLodaing = true;
    },
    [store_call_suupliers.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.callSuppliers = payload;
    },
    [store_call_suupliers.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },

    //get_call_suupliers_data
    [get_call_suupliers_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [get_call_suupliers_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.callSuppliers = payload;
    },
    [get_call_suupliers_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.callSuppliers = null;
      state.massage = action.payload;
    },

    //edit_call_suupliers
    [update_call_suupliers.pending]: (state) => {
      state.isLodaing = true;
    },
    [update_call_suupliers.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.callSuppliers = payload;
    },
    [update_call_suupliers.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
  },
});

export const { reset } = callSupplierSlice.actions;
export default callSupplierSlice.reducer;
