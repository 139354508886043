import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import utilityBillService from "./UtilityBillService";

const initialState = {
  sendUtilityBill: false,
  isError: false,
  isSuccess: false,
  isLodaing: false,
  massage: "",
};

export const sendUtilityBill = createAsyncThunk(
  "sendUtilityBill/post",
  async (data, thunkAPI) => {
    try {
      return await utilityBillService.sendUtilityBillAction(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const sendUtilityBillSlice = createSlice({
  name: "sendUtilityBill",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLodaing = false;
      state.massage = "";
    },
  },
  extraReducers: {
    //send welcome package
    [sendUtilityBill.pending]: (state) => {
      state.isLodaing = true;
    },
    [sendUtilityBill.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.sendUtilityBill = payload;
    },
    [sendUtilityBill.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
  },
});

export const { reset } = sendUtilityBillSlice.actions;
export default sendUtilityBillSlice.reducer;
