import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import getticketCardService from "./ticketCradService";
import { toast } from "react-toastify";
const initialState = {
  ticket_card: null,
  isError: false,
  isSuccess: false,
  isLodaing: false,
  massage: "",
};


//   export const getSingleCardofTicket = createAsyncThunk(
//     "getSingleCardofticket/get",
//     async (data, thunkAPI) => {
//       try {
//         return await getticketCardService.getSingleCardofticketAction(data.card_id , data.ticket_id);
//       } catch (error) {
//         const massage =
//           (error.response &&
//             error.response.data &&
//             error.response.data.massage) ||
//           error.response.data.error ||
//           error.message ||
//           error.toString();
//         return thunkAPI.rejectWithValue(massage);
//       }
//     }
//   );

  export const getSingleCardofTicket = createAsyncThunk(
    "getSingleticketCard/get",
    async (data, thunkAPI) => {
      try {
        return await  getticketCardService.getSingleCardofticketAction(data);
      } catch (error) {
        const massage =
          (error.response &&
            error.response.data &&
            error.response.data.massage) ||
          error.response.data.error ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(massage);
      }
    }
  );


export const ticketCardSlice = createSlice({
  name: "get_ticket_card",
  initialState,
  reducers: {
    reset_ticket_cards: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLodaing = false;
      state.massage = "";
    },
  },
  extraReducers: {
     //get sigle card data Action
     [getSingleCardofTicket.pending]: (state) => {
        state.isLodaing = true;
      },
      [getSingleCardofTicket.fulfilled]: (state, { payload }) => {
        state.isLodaing = false;
        state.isSuccess = true;
        state.ticket_card = payload;
      },
      [getSingleCardofTicket.rejected]: (state, action) => {
        state.isLodaing = false;
        state.isError = true;
        state.ticket_card = null;
        state.massage = action.payload;
      },
  },
});

export const { reset_ticket_cards } = ticketCardSlice.actions;
export default ticketCardSlice.reducer;
