import React from "react";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import { TextField } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { PrimaryButton } from ".././../Layouts/ButtonsControlled";
const EditCardAdders = ({
  AddersData,
  handleAddersDataChange,
  handle_edit,
}) => {
  return (
    <>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <FormGroup>
          <FormControl variant="outlined">
            <div style={{ marginTop: "20px" }}>
              <Select
                style={{ width: "300px", marginTop: "15px" }}
                name="name"
                value={AddersData.name}
                onChange={({ target: { name, value } }) =>
                  handleAddersDataChange(name, value)
                }
                label="Name"
              >
                <MenuItem value="online_monitoring">online monitoring</MenuItem>
                <MenuItem value="site_survey">site survey</MenuItem>
                <MenuItem value="permit_fees">permit fees</MenuItem>
                <MenuItem value="permit_run">permit run</MenuItem>
                <MenuItem value="roofing_price">roofing price</MenuItem>

                <MenuItem value="mpu_fees">mpu fees</MenuItem>
                <MenuItem value="solar_installation_fees">
                  solar installation fees
                </MenuItem>

                <MenuItem value="pto_fees">pto fees</MenuItem>

                <MenuItem value="solar_products">solar products</MenuItem>
                <MenuItem value="hvac">hvac</MenuItem>
                <MenuItem value="attic_run"> attic run</MenuItem>
                <MenuItem value="tree_trimming"> tree trimming</MenuItem>

                <MenuItem value="tree_trimming"> tree trimming</MenuItem>

                <MenuItem value="mpu_relocation"> mpu relocation</MenuItem>
                <MenuItem value="de_rate"> de rate</MenuItem>
                <MenuItem value="sub_panel"> sub panel</MenuItem>
                <MenuItem value="meter_socket"> meter socket</MenuItem>
                <MenuItem value="ev_charger"> ev_charger</MenuItem>
                <MenuItem value="ev_mlo"> ev_mlo</MenuItem>
                <MenuItem value="designated_plugs"> designated_plugs</MenuItem>
                <MenuItem value="battery"> battery</MenuItem>
                <MenuItem value="generator"> generator</MenuItem>
                <MenuItem value="ducting"> ducting</MenuItem>
                <MenuItem value="led lighting"> led lighting</MenuItem>
                <MenuItem value="energy efficient"> energy efficient</MenuItem>
                <MenuItem value="solar_lip"> solar_lip</MenuItem>
                <MenuItem value="bird_netting"> bird_netting</MenuItem>
                <MenuItem value="other">other</MenuItem>
              </Select>
            </div>
            {AddersData.name == "other" && (
              <TextField
                fullWidth
                label="other"
                multiline
                rows={1}
                style={{ width: "300px", marginTop: "50px" }}
                name="other"
                value={AddersData.other}
                onChange={({ target: { name, value } }) =>
                  handleAddersDataChange(name, value)
                }
              />
            )}

            <TextField
              fullWidth
              label="Cost"
              // multiline
              rows={1}
              style={{ width: "300px", marginTop: "50px" }}
              name="cost"
              value={AddersData.cost}
              onChange={({ target: { name, value } }) =>
                handleAddersDataChange(name, value)
              }
            />
          </FormControl>
        </FormGroup>

        <PrimaryButton
          fullWidth
          style={{
            padding: "0",
            width: "85%",
            margin: "15px auto",
            height: "42px",
          }}
          className="saveButton"
          onClick={handle_edit}
        >
          Update
        </PrimaryButton>
      </div>
    </>
  );
};

export default EditCardAdders;
