import React from "react";
import Typography from "@mui/material/Typography";
import { Grid, Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import { useSelector } from "react-redux";
function SecCharterForm({ formik }) {
  const single_ticket = useSelector((state) => state.single_ticket);
  const jurisdiction = single_ticket?.singleTicket?.data?.lead?.county;
  const utility_company =
    single_ticket?.singleTicket?.data?.opportunity?.utility_provider_id;
  console.log(utility_company, "djsjdhjshdjsdhj");
  return (
    <>
      <Grid item xs={12} lg={4} md={6} mt={3}>
        <Typography className="fieldDetails" variant="h5" mb={2}>
          {" "}
          Solar Permitting{" "}
        </Typography>
      </Grid>
      <Grid
        container
        columns={12}
        spacing={5}
        justifyContent="center"
        mb={3}
        // mt={2}
      >
        <Grid item xs={12} sm={5} lg={4}>
          <InputLabel htmlFor="component-simple">jurisdiction</InputLabel>
          <TextField
            fullWidth
            id="input-with-icon-textfield"
            name="jurisdiction"
            value={jurisdiction}
            onChange={formik.handleChange}
            error={
              formik.touched.jurisdiction && Boolean(formik.errors.jurisdiction)
            }
            helperText={
              formik.touched.jurisdiction && formik.errors.jurisdiction
            }
          />
        </Grid>

        <Grid item xs={12} sm={5} lg={4}>
          <InputLabel htmlFor="component-simple">Submittal Process</InputLabel>
          <Select
            fullWidth
            id="input-with-icon-textfield"
            name="submittal_process"
            value={formik.values.submittal_process}
            onChange={formik.handleChange}
            error={
              formik.touched.submittal_process &&
              Boolean(formik.errors.submittal_process)
            }
            helperText={
              formik.touched.submittal_process &&
              formik.errors.submittal_process
            }
          >
            <MenuItem value="inperson" label="inperson">
              inperson
            </MenuItem>
            <MenuItem value="email" label="email">
              email
            </MenuItem>

            <MenuItem value="portal" label="portal">
              portal
            </MenuItem>
            <MenuItem value="postmail" label="postmail">
              postmail
            </MenuItem>
          </Select>
        </Grid>
      </Grid>

      <Grid container columns={12} spacing={5} justifyContent="center" mb={3}>
        <Grid item xs={12} sm={5} lg={4}>
          <InputLabel htmlFor="component-simple">
            jurisdiction Contact Email
          </InputLabel>
          <TextField
            fullWidth
            id="input-with-icon-textfield"
            name="jurisdiction_contact_email"
            value={formik.values.jurisdiction_contact_email}
            onChange={formik.handleChange}
            error={
              formik.touched.jurisdiction_contact_email &&
              Boolean(formik.errors.jurisdiction_contact_email)
            }
            helperText={
              formik.touched.jurisdiction_contact_email &&
              formik.errors.jurisdiction_contact_email
            }
          />
        </Grid>

        <Grid item xs={12} sm={5} lg={4}>
          <InputLabel htmlFor="component-simple">
            jurisdiction Contact Phone
          </InputLabel>
          <TextField
            fullWidth
            id="input-with-icon-textfield"
            name="jurisdiction_contact_mobile"
            value={formik.values.jurisdiction_contact_mobile}
            onChange={formik.handleChange}
            error={
              formik.touched.jurisdiction_contact_mobile &&
              Boolean(formik.errors.jurisdiction_contact_mobile)
            }
            helperText={
              formik.touched.jurisdiction_contact_mobile &&
              formik.errors.jurisdiction_contact_mobile
            }
          />
        </Grid>
      </Grid>
      <Grid container columns={12} spacing={5} justifyContent="center" mb={3}>
        <Grid item xs={12} sm={5} lg={4}>
          <InputLabel htmlFor="component-simple">
            jurisdiction Estimated Duration (Days)
          </InputLabel>
          <TextField
            fullWidth
            id="input-with-icon-textfield"
            name="jurisdiction_estimated_duration"
            value={formik.values.jurisdiction_estimated_duration}
            onChange={formik.handleChange}
            error={
              formik.touched.jurisdiction_estimated_duration &&
              Boolean(formik.errors.jurisdiction_estimated_duration)
            }
            helperText={
              formik.touched.jurisdiction_estimated_duration &&
              formik.errors.jurisdiction_estimated_duration
            }
          />
        </Grid>
        <Grid item xs={12} sm={5} lg={4}>
          <InputLabel htmlFor="component-simple">Business License</InputLabel>
          <Select
            fullWidth
            id="input-with-icon-textfield"
            name="business"
            value={formik.values.business}
            onChange={formik.handleChange}
            error={formik.touched.business && Boolean(formik.errors.business)}
            helperText={formik.touched.business && formik.errors.business}
          >
            <MenuItem value="have_license">
              already have business license{" "}
            </MenuItem>
            <MenuItem value="portal">portal</MenuItem>
            <MenuItem value="email">Email</MenuItem>
            <MenuItem value="in_person">inperson</MenuItem>
            <MenuItem value="no_have_license">No have license</MenuItem>
          </Select>
        </Grid>
      </Grid>

      <Grid container columns={12} spacing={5} justifyContent="center" mb={3}>
        <Grid item xs={12} sm={5} lg={4}>
          <InputLabel htmlFor="component-simple">
            Type Of Inspections
          </InputLabel>
          <TextField
            fullWidth
            id="input-with-icon-textfield"
            name="type_of_inspections"
            value={formik.values.type_of_inspections}
            onChange={formik.handleChange}
            error={
              formik.touched.type_of_inspections &&
              Boolean(formik.errors.type_of_inspections)
            }
            helperText={
              formik.touched.type_of_inspections &&
              formik.errors.type_of_inspections
            }
          />
        </Grid>

        <Grid item xs={12} sm={5} lg={4}>
          <InputLabel htmlFor="component-simple">
            Structrual Calculation
          </InputLabel>
          <Select
            fullWidth
            id="input-with-icon-textfield"
            name="structural_calculation"
            value={formik.values.structural_calculation}
            onChange={formik.handleChange}
            error={
              formik.touched.structural_calculation &&
              Boolean(formik.errors.structural_calculation)
            }
            helperText={
              formik.touched.structural_calculation &&
              formik.errors.structural_calculation
            }
          >
            <MenuItem value="1" label="yes">
              yes
            </MenuItem>
            <MenuItem value="0" label="No">
              No
            </MenuItem>
          </Select>
        </Grid>
      </Grid>
      <Grid container columns={12} spacing={5} justifyContent="center" mb={3}>
        <Grid item xs={12} sm={10} lg={8}>
          <InputLabel htmlFor="component-simple">
            Special Requirements
          </InputLabel>
          <Select
            fullWidth
            id="input-with-icon-textfield"
            name="special_requirements"
            value={formik.values.special_requirements}
            onChange={formik.handleChange}
            error={
              formik.touched.special_requirements &&
              Boolean(formik.errors.special_requirements)
            }
            helperText={
              formik.touched.special_requirements &&
              formik.errors.special_requirements
            }
          >
            <MenuItem value="extra_disconnect">extra disconnects</MenuItem>
            <MenuItem value="meter_socket">Meter Sockets</MenuItem>
            <MenuItem value="other">Other</MenuItem>
          </Select>
        </Grid>
      </Grid>
      {formik.values.special_requirements == "other" && (
        <Grid container columns={12} spacing={5} justifyContent="center" mb={3}>
          <Grid item xs={12} sm={10} lg={8}>
            <InputLabel htmlFor="component-simple">
              other special requirements
            </InputLabel>
            <TextField
              fullWidth
              id="input-with-icon-textfield"
              name="other_special_requirements"
              value={formik.values.other_special_requirements}
              onChange={formik.handleChange}
              error={
                formik.touched.other_special_requirements &&
                Boolean(formik.errors.other_special_requirements)
              }
              helperText={
                formik.touched.other_special_requirements &&
                formik.errors.other_special_requirements
              }
            />
          </Grid>
        </Grid>
      )}

      {/* Utility */}
      <Grid item xs={12} lg={4} md={6} mt={3}>
        <Typography className="fieldDetails" variant="h5" mb={2}>
          {" "}
          PTO{" "}
        </Typography>
      </Grid>
      <Grid container columns={12} spacing={5} justifyContent="center" mb={3}>
        <Grid item xs={12} sm={5} lg={4}>
          <InputLabel htmlFor="component-simple">Utility Company</InputLabel>
          <TextField
            fullWidth
            id="input-with-icon-textfield"
            name="utility_company"
            //value={formik.values.utility_company}
            value={utility_company}
            onChange={formik.handleChange}
            error={
              formik.touched.utility_company &&
              Boolean(formik.errors.utility_company)
            }
            helperText={
              formik.touched.utility_company && formik.errors.utility_company
            }
            disabled
          />
        </Grid>

        <Grid item xs={12} sm={5} lg={4}>
          <InputLabel htmlFor="component-simple">PTO Process</InputLabel>
          <Select
            fullWidth
            id="input-with-icon-textfield"
            name="utility_pto_process"
            value={formik.values.utility_pto_process}
            onChange={formik.handleChange}
            error={
              formik.touched.utility_pto_process &&
              Boolean(formik.errors.utility_pto_process)
            }
            helperText={
              formik.touched.utility_pto_process &&
              formik.errors.utility_pto_process
            }
          >
            <MenuItem value="inperson" label="inperson">
              inperson
            </MenuItem>
            <MenuItem value="email" label="email">
              email
            </MenuItem>

            <MenuItem value="portal" label="portal">
              portal
            </MenuItem>
            <MenuItem value="postmail" label="postmail">
              postmail
            </MenuItem>
          </Select>
        </Grid>
      </Grid>

      <Grid container columns={12} spacing={5} justifyContent="center" mb={3}>
        <Grid item xs={12} sm={5} lg={4}>
          <InputLabel htmlFor="component-simple">
            Utility Contact Email
          </InputLabel>
          <TextField
            fullWidth
            id="input-with-icon-textfield"
            name="utility_contact_email"
            value={formik.values.utility_contact_email}
            onChange={formik.handleChange}
            error={
              formik.touched.utility_contact_email &&
              Boolean(formik.errors.utility_contact_email)
            }
            helperText={
              formik.touched.utility_contact_email &&
              formik.errors.utility_contact_email
            }
          />
        </Grid>

        <Grid item xs={12} sm={5} lg={4}>
          <InputLabel htmlFor="component-simple">
            Utility Contact Mobile
          </InputLabel>
          <TextField
            fullWidth
            id="input-with-icon-textfield"
            name="utility_contact_mobile"
            value={formik.values.utility_contact_mobile}
            onChange={formik.handleChange}
            error={
              formik.touched.utility_contact_mobile &&
              Boolean(formik.errors.utility_contact_mobile)
            }
            helperText={
              formik.touched.utility_contact_mobile &&
              formik.errors.utility_contact_mobile
            }
          />
        </Grid>
      </Grid>
      <Grid container columns={12} spacing={5} justifyContent="center" mb={3}>
        <Grid item xs={12} sm={5} lg={4}>
          <InputLabel htmlFor="component-simple">
            utility Estimated Duration (Days)
          </InputLabel>
          <TextField
            fullWidth
            id="input-with-icon-textfield"
            name="utility_estimated_duration"
            value={formik.values.utility_estimated_duration}
            onChange={formik.handleChange}
            error={
              formik.touched.utility_estimated_duration &&
              Boolean(formik.errors.utility_estimated_duration)
            }
            helperText={
              formik.touched.utility_estimated_duration &&
              formik.errors.utility_estimated_duration
            }
          />
        </Grid>

        <Grid item xs={12} sm={5} lg={4}>
          <InputLabel htmlFor="component-simple">
            Special Requirements
          </InputLabel>
          <Select
            fullWidth
            id="input-with-icon-textfield"
            name="special_requirements_pto"
            value={formik.values.special_requirements_pto}
            onChange={formik.handleChange}
            error={
              formik.touched.special_requirements_pto &&
              Boolean(formik.errors.special_requirements_pto)
            }
            helperText={
              formik.touched.special_requirements_pto &&
              formik.errors.special_requirements_pto
            }
          >
            <MenuItem value="extra_disconnect">extra disconnects</MenuItem>
            <MenuItem value="meter_socket">Meter Sockets</MenuItem>
            <MenuItem value="other">Other</MenuItem>
          </Select>
        </Grid>
      </Grid>
      {formik.values.special_requirements_pto == "other" && (
        <Grid container columns={12} spacing={5} justifyContent="center" mb={3}>
          <Grid item xs={12} sm={10} lg={8}>
            <InputLabel htmlFor="component-simple">
              other special requirements
            </InputLabel>
            <TextField
              fullWidth
              id="input-with-icon-textfield"
              name="other_special_requirements_pto"
              value={formik.values.other_special_requirements_pto}
              onChange={formik.handleChange}
              error={
                formik.touched.other_special_requirements_pto &&
                Boolean(formik.errors.other_special_requirements_pto)
              }
              helperText={
                formik.touched.other_special_requirements_pto &&
                formik.errors.other_special_requirements_pto
              }
            />
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default SecCharterForm;
