import axios from "axios";
import { site_survey_images } from "../../../Api/Api";

const site_survey_images_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${site_survey_images}/${data.id}`,
    headers: headers,
    params: {
      status: data.status,
    },
  });
  return response.data;
};

const SurveyImagesService = {
  site_survey_images_Action,
};
export default SurveyImagesService;
