import { Typography, Grid } from "@mui/material";
import React, { useEffect } from "react";
import styled from "styled-components";
import Divider from "@mui/material/Divider";
import LottieAnimation from "../../Components/LottieAnimation/LottieAnimation";
import loading from "../../Assets/lottie/loading.json";
import { connect, useDispatch, useSelector } from "react-redux";
import { reduxForm, Field } from "redux-form";
import MuiTextField from "../../Common/muiTextField/MuiTextField"
import { PrimaryButton } from "../../Layouts/ButtonsControlled";
import { store_designPlanCost } from "../../Redux/Slices/designPlanCost/DesignPlanCostSlice"
import { useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { getSingleDesignReqDetails } from "../../Redux/Slices/DesignRequest/DesignRequestSlice";

const SingleDesignReqData = ({ design_req_details, handleSubmit }) => {
  const dispatch = useDispatch();
  const { lead_id, design_req_id } = useParams()
  const design_req_data = design_req_details?.design_req?.data?.plan;
  console.log(design_req_details, "design_req_details454");
  console.log(design_req_data, "design_req_details");
  console.log(lead_id, "lead_id");
  const designPlanCost_state = useSelector((state) => state.designPlanCost);
  console.log(designPlanCost_state, "designPlanCost_state")
  const design_req_info = useSelector((state) => state.design_req);
  console.log(design_req_info?.design_req?.data?.plan?.cost
    , "design_req_info")

  const submit = async (values) => {
    let reqData = {
      id: design_req_id
    }
    await dispatch(store_designPlanCost({ ...values, ...reqData }));
    dispatch(getSingleDesignReqDetails(design_req_id));
  }

  useEffect(() => {
    dispatch(getSingleDesignReqDetails(design_req_id));
  }, []);
  return (
    <div style={{ marginLeft: "20px", padding: "5px" }}>
      {design_req_details?.isLodaing ? (
        <LottieAnimation lotti={loading} width={180} height={180} />
      ) : design_req_details?.design_req !== null ? (
        <>
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="baseline"
              columns={18}
              spacing={4}
              style={{ textAlign: "left" }}
            >
              <Grid item lg={18} md={18} sm={18} xs={18}>
                {/* <Heading> */}
                <Typography align="center" variant="h6">
                  Design Request Details
                </Typography>
                {/* </Heading> */}
              </Grid>
              <Grid item lg={18} md={18} sm={18} xs={18}>
                {/* <Heading> */}
                <Typography variant="h6">Customer Information : </Typography>
                {/* </Heading> */}
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  customer_name : <Value>{design_req_data?.customer_name}</Value>
                </p>
              </Grid>
              <Grid item lg={8} md={6} sm={12} xs={12}>
                <p>
                  customer_address :{" "}
                  <Value>{design_req_data?.customer_address}</Value>
                </p>
              </Grid>

              <Grid item xs={18} style={{ margin: "5px auto" }}>
                <Divider />
              </Grid>
              <Grid item lg={18} md={18} sm={18} xs={18}>
                {/* <Heading> */}
                <Typography variant="h6">Designer Information : </Typography>
                {/* </Heading> */}
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  designer : <Value>{design_req_data?.designer_id}</Value>
                </p>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Designer email: <Value>{design_req_data?.email}</Value>
                </p>
              </Grid>

              <Grid item xs={18} style={{ margin: "5px auto" }}>
                <Divider />
              </Grid>
              <Grid item lg={18} md={18} sm={18} xs={18}>
                {/* <Heading> */}
                <Typography variant="h6">Details : </Typography>
                {/* </Heading> */}
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  panels_wattage :{" "}
                  <Value>{design_req_data?.panels_wattage}</Value>
                </p>
              </Grid>
{/* 
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  panels_model : <Value>{design_req_data?.panels_model}</Value>
                </p>
              </Grid> */}

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  inverter_manufacture :{" "}
                  <Value>{design_req_data?.invert_manufacture}</Value>
                </p>
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  inverter_model : <Value>{design_req_data?.invert_model}</Value>
                </p>
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  is_mpu : <Value>{design_req_data?.is_mpu}</Value>
                </p>
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  mpu_from_to : <Value>{design_req_data?.mpu_from_to}</Value>
                </p>
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  msp : <Value>{design_req_data?.msp}</Value>
                </p>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  location : <Value>{design_req_data?.location}</Value>
                </p>
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  battery : <Value>{design_req_data?.battery}</Value>
                </p>
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  installation type : <Value>{design_req_data?.installation_type}</Value>
                </p>
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  contractor_name :{" "}
                  <Value>{design_req_data?.contractor_name}</Value>
                </p>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  contractor_address :{" "}
                  <Value>{design_req_data?.contractor_address}</Value>
                </p>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  contractor_license :{" "}
                  <Value>{design_req_data?.contractor_license}</Value>
                </p>
              </Grid>


              <Grid item lg={18} md={18} sm={18} xs={18}>
                {/* <Heading> */}
                <Typography variant="h6">status : </Typography>
                {/* </Heading> */}
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Status : <Value >{design_req_data?.status}</Value>
                </p>
              </Grid>


            </Grid>

          </Grid>


          {design_req_data?.status == "approved" && (
            <Grid item lg={12} md={6} sm={12} xs={12}>
              <form onSubmit={handleSubmit((values) => submit(values))}>
                <Grid container columns={12}  >
                  <Grid item xs={6} sm={6} lg={3}>
                    <Field
                      required
                      type="number"
                      name="cost"
                      component={MuiTextField}
                      label="Cost"

                    />
                  </Grid>
                  <Grid item xs={12} sm={10} lg={4}>
                    <PrimaryButton
                      fullWidth
                      type="submit"
                      style={{
                        padding: "0",
                        width: "55%",
                        margin: "20px",
                        height: "42px",
                      }}
                      className="saveButton"
                    >

                      {designPlanCost_state?.isLodaing ? (
                        <CircularProgress size={25} thickness={18} />
                      ) : null}
                      Save
                    </PrimaryButton>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          )}

        </>
      ) : (
        <div style={{ marginLeft: "20px", padding: "5px" }}>
          <Typography align="center" variant="h6">
            there is no data for this Card
          </Typography>
        </div>
      )}
    </div>
  );
};
const Value = styled.span`
  color: rgb(136, 136, 136);
  font-weight:bold
`;
// export default SingleDesignReqData;
const mapStateToProps = (state) => {
  return {
    // initialValues: {
    //   cost: state.design_req?.data?.plan?.cost,
    // },
  };
};

export default connect(null)(
  reduxForm({ form: "DesignRequestStatusForm", enableReinitialize: true })(
    SingleDesignReqData
  )
);
