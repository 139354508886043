import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import {
  get_All_procedures,
  delete_procedures,
} from "../../Redux/Slices/Procedures/ProceduresSlice";
import { useDispatch, useSelector } from "react-redux";
import LottieAnimation from "../../Components/LottieAnimation/LottieAnimation";
import loading from "../../Assets/lottie/loading.json";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { CardActions } from "@mui/material";
import ReactHtmlParser from "react-html-parser";
const AllProceduresTab = () => {
  const dispatch = useDispatch();
  const procedures = useSelector((state) => state.procedures);

  useEffect(() => {
    dispatch(get_All_procedures());
  }, []);

  const delete_procedure = async (procedures) => {
    const reqData = {
      id: procedures.id,
    };
    await dispatch(delete_procedures(reqData));
    dispatch(get_All_procedures());
  };
  return (
    <>
      {procedures?.isLodaing ? (
        <LottieAnimation lotti={loading} width={180} height={180} />
      ) : procedures?.procedures?.data?.data &&
        procedures?.procedures?.data?.data?.length > 0 ? (
        procedures?.procedures?.data?.data?.map((procedures) => (
          //   <Grid item sm={6} xs={12}>
          <>
            <CardContent
              style={{
                background: "rgb(249, 245, 255)",
                border: "1px solid rgb(214, 187, 251)",
                borderRadius: "8px",
                padding: "1rem",
                margin: "0.5rem 0px",
              }}
            >
              <Typography style={{ fontWeight: "bold" }}>
                {" "}
                <span
                  style={{
                    fontWeight: "bolder",
                    color: "#7e7ec0",
                  }}
                >
                  {" "}
                  County :{" "}
                </span>{" "}
                {procedures?.county}
              </Typography>
              <Typography>{ReactHtmlParser(procedures?.text)}</Typography>
              <Typography>
                {" "}
                <span style={{ fontWeight: "bold" }}>created at : </span>
                {
                  new Date(procedures?.created_at)
                    ?.toISOString()
                    ?.split("T", 1)[0]
                }
              </Typography>
              <Typography>
                {" "}
                <span style={{ fontWeight: "bold" }}>updated at : </span>
                {
                  new Date(procedures?.updated_at)
                    ?.toISOString()
                    ?.split("T", 1)[0]
                }
              </Typography>

              <CardActions
                style={{
                  display: "flex",
                  justifyContent: "end",
                  cursor: "pointer",
                }}
              >
                <DeleteForeverIcon
                  onClick={() => delete_procedure(procedures)}
                />
              </CardActions>
            </CardContent>
          </>
        ))
      ) : (
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Typography>There is no Counties</Typography>
        </Grid>
      )}
    </>
  );
};

export default AllProceduresTab;
