import axios from "axios";
import {
  create_issue,
  get_Issues,
  UploadIssues,
  deleteIssueFile,
  edit_issue
} from "../../../Api/Api";
import { toast } from "react-toastify";
const Create_issue_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${create_issue}`,
    headers: headers,
    data: data,
  });
  console.log(response?.status, "create-issue-response");
  if (response?.status == 200) {
    toast.success("issues added Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};


const update_issue_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${edit_issue}/${data.id}`,
    headers: headers,
    data: data,
  });
  console.log(response?.status, "create-issue-response");
  if (response?.status == 200) {
    toast.success("issues added Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const getAll_Issues_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${get_Issues}/${data.id}`,
    headers: headers,
    params: {
      type: data.type,
    },
  });
  return response.data;
};

const upload_issue_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${UploadIssues}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Files Uploaded Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const Delete_issue_file_Action = async (id) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "DELETE",
    baseURL: `${deleteIssueFile}/${id}`,
    headers: headers,
    // data: data,
  });
  return response.data;
};
const IssuesService = {
  Create_issue_Action,
  update_issue_Action,
  getAll_Issues_Action,
  upload_issue_Action,
  Delete_issue_file_Action,
};
export default IssuesService;
