import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { Login } from "../../../Api/Api";
import { Logout } from "../../../Api/Api";

// Get the Data From Local Storage
try {
  var user = JSON.parse(localStorage.getItem("user"));
} catch (e) {}
// Header for Apis
const headers = {
  // Authorization: `Bearer ${token}`,
  Accept: "application/json",
  XMLHttpRequest: "XMLHttpRequest",
};
/* Login Function */
export const authentication = createAsyncThunk(
  "auth/getAuth",
  async (data, thunkAPI) => {
    try {
      return await axios({
        method: "POST",
        baseURL: `${Login}`,
        headers: headers,
        data: data,
      }).then((res) => {
        if (res) {
          localStorage.setItem("user", JSON.stringify(res.data.data));
          toast.success("Welcome", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        return res.data;
      });
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();

      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return thunkAPI.rejectWithValue(message);
    }
  }
);
/* Logout Function */
export const logout = createAsyncThunk("auth/logout", async () => {
  await localStorage.removeItem("user");
});
/* Authentication Slice */
const authSlice = createSlice({
  name: "auth",
  initialState: {
    data: user ? user : null,
    token: "",
    isAuth: false,
    status: "",
    error: "",
  },
  reducers: {},
  extraReducers: {
    [authentication.pending]: (state, { payload }) => {
      state.status = "loading";
    },
    [authentication.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.data = payload;
      state.isAuth = true;
    },
    [authentication.rejected]: (state, { payload }) => {
      state.status = "failed";
      state.isAuth = false;
      state.error = payload;
    },
    [logout.fulfilled]: (state, { payload }) => {
      state.isAuth = false;
      state.data = null;
    },
  },
});

export default authSlice.reducer;
