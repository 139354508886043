import styled from "styled-components";
export const SideLeadStyled = styled.div`
  margin: 14px 5px;
  background-color: white;
  border-radius: 5px;
  .mainInfo {
    padding: 1rem;
    text-align: center;
    border-bottom: 1px solid #777;
    display: flex;
    justify-content: space-around;
    &__icon {
      svg {
        font-size: 5rem;
        color: #777;
      }
    }
    &__title {
      margin-top: 15px;
    }
    .img {
      width: 55px;
      height: 55px;
    }
  }
  .LeadInfo {
    padding: 1rem 2rem;

    ul {
      li {
        color: #888888;
        padding: 0.2rem 0;
        margin-bottom: 5px;
      }
    }
  }
`;
