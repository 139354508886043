import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Tooltip from '@mui/material/Tooltip';
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import LottieAnimation from "../../Components/LottieAnimation/LottieAnimation";
import loading from "../../Assets/lottie/loading.json";
import { useDispatch, useSelector } from "react-redux";
import ImageIcon from "@mui/icons-material/Image";
import EditExtraAddersModal from "../../Components/Modals/EditExtraAddersModal/EditExtraAddersModal"
import {
    IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import { get_AddersCostSpecificCards, delete_AddersCostSpecificCards, reset } from "../../Redux/Slices/AddersCostSpecificCards/AddersCostSpecificCardsSlice"
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#003656",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));


function ExtraAddersDetails({ lead_id }) {
    const dispatch = useDispatch();
    const [editModal, setEditModal] = useState(false);
    const [selectedAdder, setSelectedAdder] = useState()
    const extra_adders_state = useSelector((state) => state.AddersCostSpecificCards);

    useEffect(() => {
        let apis = async () => {
            await dispatch(get_AddersCostSpecificCards(lead_id));
            await dispatch(reset())
        }
        apis()
    }, []);

    const handleDeleteAdder = async (adder) => {
        await dispatch(delete_AddersCostSpecificCards(adder.id))
        await dispatch(get_AddersCostSpecificCards(lead_id));
        console.log(adder.id, "adder.id")


    }

   const handleDeleteMedia = ()=>{
    console.log("delete" )
   } 

    const handleOpenModal = () => {
        setEditModal(true)
    }

    const handleEditAdder = (row) => {
        setEditModal(true)
        setSelectedAdder(row)
    }

    const handleCloseModal = () => {
        setEditModal(false)
    }

    return (
        <>
            {extra_adders_state?.isLodaing ? (
                <LottieAnimation lotti={loading} width={180} height={180} />
            ) : (
                <TableContainer component={Paper} style={{ marginTop: "50px" }}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Name</StyledTableCell>
                                <StyledTableCell align="center">cost</StyledTableCell>
                                <StyledTableCell align="center">uploaded image</StyledTableCell>
                                <StyledTableCell align="center">action</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {extra_adders_state?.AddersCostSpecificCards?.data?.length > 0 ? (
                                extra_adders_state?.AddersCostSpecificCards?.data?.map((row) => (
                                    <StyledTableRow key={row.name}>
                                        <StyledTableCell component="th" scope="row">
                                            <>
                                                <FiberManualRecordIcon
                                                    style={{
                                                        fontSize: "10px",
                                                        color: "#0DBF7C",
                                                        marginRight: "5px",
                                                    }}
                                                />

                                                {row.name}
                                            </>
                                        </StyledTableCell>

                                        <StyledTableCell align="center">
                                            {row.cost}
                                        </StyledTableCell>
                                        {row?.media?.map((img) => (
                                            <StyledTableCell align="center">
                                                <a
                                                    href={img?.path}
                                                    target="_blank"
                                                >
                                                    <ImageIcon />{" "}
                                                </a>
                                                {/* <Tooltip title="Delete Image">
                                                <IconButton
                                                edge="end"
                                                aria-label="delete "
                                                onClick={() => handleDeleteMedia(row)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                            </Tooltip> */}
                                            </StyledTableCell>
                                        ))}

                                        <StyledTableCell align="center">
                                            <IconButton
                                                edge="end"
                                                aria-label="delete"
                                                onClick={() => handleDeleteAdder(row)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>

                                            <IconButton
                                                edge="end"
                                                aria-label="delete"
                                                onClick={() => handleEditAdder(row)}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </StyledTableCell>



                                    </StyledTableRow>
                                ))
                            ) : (
                                <StyledTableRow
                                    component="th"
                                    scope="row"
                                    align="center"
                                    style={{
                                        display: "flex",
                                        justifyContent: "end",
                                        margin: "15px",
                                        backgroundColor: "transparent",
                                    }}
                                >
                                    {/* <StyledTableCell component="th" scope="row" align="center"> */}
                                    There are no adders for this ticket
                                    {/* </StyledTableCell> */}
                                </StyledTableRow>
                            )}
                        </TableBody>
                    </Table>
                    {editModal && (
                        <EditExtraAddersModal
                            handleEditAdder={handleEditAdder}
                            handleCloseModal={handleCloseModal}
                            handleOpenModal={handleOpenModal}
                            selectedAdder={selectedAdder}
                            initialValues={{
                                name: selectedAdder.name,
                                cost: selectedAdder.cost
                            }}
                            extra_adders_state={extra_adders_state}
                            lead_id={lead_id}
                        />
                    )}

                </TableContainer>



            )}

        </>
    );
}

export default ExtraAddersDetails;
