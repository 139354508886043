import axios from "axios";
import { Get_PM_Users } from "../../../Api/Api";

const Get_PM_Users_Action = async (pm) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${Get_PM_Users}`,
    headers: headers,
    params: {
      return_all: "1",
      type: "pm",
    },
  });
  return response.data;
};

const PM_Users_Service = {
  Get_PM_Users_Action,
};
export default PM_Users_Service;
