import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import welcomeCallService from "./WelcomeCallServise";
import { toast } from "react-toastify";
const initialState = {
  WelcomeCall: {},
  isError: false,
  isSuccess: false,
  isLodaing: false,
  massage: "",
};

export const postWelcomeCall = createAsyncThunk(
  "WelcomeCall/post",
  async (data, thunkAPI) => {
    try {
      return await welcomeCallService.welcomeCallAction(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      error?.response?.data?.errors?.map((err) =>
        toast.error(err.msg, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      );
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const getWelcomeCall = createAsyncThunk(
  "getWelcomeCall/get",
  async (id, thunkAPI) => {
    try {
      return await welcomeCallService.getWelcomeCallAction(id);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const getTicketWelcomeCll = createAsyncThunk(
  "getTicketWelcomeCall/get",
  async (id, thunkAPI) => {
    try {
      return await welcomeCallService.getTicketWelcomeCallsAction(id);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const WelcomeCallSlice = createSlice({
  name: "WelcomeCall",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLodaing = false;
      state.massage = "";
    },
  },
  extraReducers: {
    //post welcome call  Action
    [postWelcomeCall.pending]: (state) => {
      state.isLodaing = true;
    },
    [postWelcomeCall.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.WelcomeCall = payload;
    },
    [postWelcomeCall.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.WelcomeCall = null;
      state.massage = action.payload;
    },
    //get welcome call
    [getWelcomeCall.pending]: (state) => {
      state.isLodaing = true;
    },
    [getWelcomeCall.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.WelcomeCall = payload;
    },
    [getWelcomeCall.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
      state.WelcomeCall = null;
    },

    //get ticket welcome call
    [getTicketWelcomeCll.pending]: (state) => {
      state.isLodaing = true;
    },
    [getTicketWelcomeCll.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.WelcomeCall = payload;
    },
    [getTicketWelcomeCll.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
      state.welcomeCall = null;
    },
  },
});

export const { reset } = WelcomeCallSlice.actions;
export default WelcomeCallSlice.reducer;
