import React from "react";
import UserSection from "../../Components/UserSection/UserSection";
import NavbarRouts from "../../Components/NavbarRouts/NavbarRouts";
import RoutsPages from "../Routs/RoutsPages";
import styled from "styled-components";

const UserSecAndRouts = () => {
  return (
    <>
      <UserSection />

      <Tabs>
        <NavbarRouts />
        <RoutsPages />
      </Tabs>
    </>
  );
};
const Tabs = styled.section`
  padding: 1rem;
  border-radius: 1rem;
`;
export default UserSecAndRouts;
