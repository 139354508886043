import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import designReqService from "./DesignRequestService";
import { toast } from "react-toastify";
const initialState = {
  design_req: {},
  isError: false,
  isSuccess: false,
  isLodaing: false,
  massage: "",
};

export const send_design_req = createAsyncThunk(
  "design_req/post",
  async (data, thunkAPI) => {
    try {
      return await designReqService.send_design_req_Action(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      error?.response?.data?.errors?.map((err) =>
        toast.error(err.msg, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      );
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const getDesignReqDetails = createAsyncThunk(
  "getdesignReqDetails/get",
  async (id, thunkAPI) => {
    try {
      return await designReqService.getDesignReqDetailsAction(id);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const getSingleDesignReqDetails = createAsyncThunk(
  "getsingledesignReqDetails/get",
  async (id, thunkAPI) => {
    try {
      return await designReqService.getSingleDesignReqDetailsAction(id);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);
export const design_req_Slice = createSlice({
  name: "design_req",
  initialState,
  reducers: {
    resetDesingRequest: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLodaing = false;
      state.massage = "";
    },
  },
  extraReducers: {
    //post Assign_PM call  Action
    [send_design_req.pending]: (state) => {
      state.isLodaing = true;
    },
    [send_design_req.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.design_req = payload;
    },
    [send_design_req.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },

    //get  design Req Details Action
    [getDesignReqDetails.pending]: (state) => {
      state.isLodaing = true;
    },
    [getDesignReqDetails.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.design_req = payload;
    },
    [getDesignReqDetails.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
      state.design_req = null;
    },

     //get single design Req Details Action
     [getSingleDesignReqDetails.pending]: (state) => {
      state.isLodaing = true;
    },
    [getSingleDesignReqDetails.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.design_req = payload;
    },
    [getSingleDesignReqDetails.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
      state.design_req = null;
    },
  },
});

export const { resetDesingRequest } = design_req_Slice.actions;
export default design_req_Slice.reducer;
