import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { useDispatch } from 'react-redux';
 import { DeleteCardAcion } from "../../../Redux/Slices/CreateCard/CreateCardSlice";
import {getCardsOrder,} from "../../../Redux/Slices/CardsListSlice/CardsListSlice";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// export default function AlertDialogSlide() {
const DeleteCardModal = ({openDeleteCardModal , setOpenDeleteCardModal , selectedCard  , card}) => {
    const dispatch = useDispatch()


const handleDeleteCard = async (card) => {
    console.log(card, "delete card -- card_id")
    await dispatch(DeleteCardAcion({ id: card.card_id }));
    await setOpenDeleteCardModal(false)
    await dispatch(getCardsOrder())
    //window.location.reload()

  }

  const handleClose = () => {
    setOpenDeleteCardModal(false);
  };

  return (
    <div>
      <Dialog
        open={openDeleteCardModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>  {selectedCard.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
          Are you sure to delete the  {selectedCard.title}  card ?
          
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button  onClick={() => handleDeleteCard(card)}>confirm</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default  DeleteCardModal;