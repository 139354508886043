import React, { useState, useEffect } from "react";
import { reduxForm, Field } from "redux-form";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Grid, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { PrimaryButton } from "../../../Layouts/ButtonsControlled";
import { ModalContent } from "../../Modals/PmAssignModal/PmAssignModalStyle";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import FormGroup from "@mui/material/FormGroup";
import Box from "@mui/material/Box";
import MuiSelectField from "../../../Common/MuiSelectField/MuiSelectField";
import MuiTextField from "../../../Common/muiTextField/MuiTextField";
import {update_issue} from "../../../Redux/Slices/CreateIssue/Create_Issue_Slice";
import {getIssues} from "../../../Redux/Slices/CreateIssue/Create_Issue_Slice"
const EditIssueModal = ({
  handleSubmit,
  handleopenModal,
  handleCloseModal,
  lead_id,
  selectedIssue,
  setOpenEditIssueModal,
  type

}) => {
  const dispatch = useDispatch();
  const submit = async (values) => {
   console.log( values , "values")
    const reqData = {
    id : selectedIssue.id,
    _method: "PUT"
   }
   const IssuereqData = { id: lead_id, type: type };
   await dispatch(update_issue({...reqData , ...values}));
   await setOpenEditIssueModal(false)
   
   await dispatch(getIssues(IssuereqData));
 
  };

  return (
    <Dialog
      maxWidth={"md"}
      fullWidth
      open={handleopenModal}
      onClose={handleCloseModal}
    >
      <DialogContent>
        <ModalContent>
          <Box>
            <form onSubmit={handleSubmit((values) => submit(values))}>
              <Grid>
                <FormGroup>
                    <Grid>
                      <Field                
                        type="text"
                        name="title"
                        component={MuiTextField}
                        label="issue title"
                      />
                    </Grid>
                

                  <Grid className="" style={{ marginTop: "10px" }}>
                    <Field
                      required
                      name="status"
                      component={MuiSelectField}
                      label="status"
                    >
                      <MenuItem value="pending">pending </MenuItem>
                      <MenuItem value="completed">completed</MenuItem>
                      <MenuItem value="in_progress">in_progress</MenuItem>
                    </Field>
                  </Grid>
                </FormGroup>
              </Grid>
              <DialogActions>
                <PrimaryButton
                  fullWidth
                  type="submit"
                  style={{
                    padding: "0",
                    width: "85%",
                    margin: "15px auto",
                    height: "42px",
                  }}
                  className="saveButton"
                >
                      update issue
                </PrimaryButton>
              </DialogActions>
            </form>
          </Box>
        </ModalContent>
      </DialogContent>
    </Dialog>
  );
};

export default reduxForm({
  form: "EditIssueForm",
})(EditIssueModal);
