import React from "react";
import "./App.css";
import Home from "./Pages/Home/Home";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./Pages/Login/Login";
import TicketsRouts from "./Pages/Tickets/TicketsRouts";
import Installers from "./Pages/Installers/Installers";
import Distributors from "./Pages/Distributors/Distributors";
import PendingTickets from "./Pages/Tickets/PendingTickets";
import ApprovedTickets from "./Pages/Tickets/ApprovedTickets";
import RejectedTickets from "./Pages/Tickets/RejectedTickets";
import AssignedTickets from "./Pages/Tickets/AssignedTickets";
import AllTickets from "./Pages/Tickets/AllTickets";
import Designers from "./Pages/Designers/Designers";
import PMAgaints from "./Pages/PMAgaints/PMAgaints";
import CharterSteper from "./Components/CharterSteper/CharterSteper";
import SendDesignRequestForm from "./Pages/DesignRequestForm/DesignRequestForm";
import WokOrdersList from "./Pages/WorkOrdersList/WokOrdersList";
import SiteSurveyList from "./Pages/SiteSurveyList/SiteSurveyList";
import StateInstallers from "./Pages/StateInstallers/StateInstallers";
import CreateWorkOrderForm from "./Pages/CreateWorkOrderForm/CreateWorkOrderForm";
import CreateSiteSurveyForm from "./Pages/SiteSurveyForm/SiteSurveyForm";
import SolarSolution from "./Pages/SolarSolution/SolarSolution";
import { GlobalStyle } from "./Styles/GlobalStyles";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserSecAndRouts from "./Pages/UserSecAndRouts/UserSecAndRouts";
import ProcessSteper from "./Pages/ProcessSteper";
//import ProcessSteper from "./Pages/ProcessSteper_new";
import LeadTabs from "./Components/LeadTabs/LeadTabs";
import CardIssuess from "./Pages/CardIssues/CardIssues";
import NewCardDetails from "./Pages/NewCardsDetails/NewCardsDetails";
import CharterDetails from "./Pages/CharterDetails/CharterDetails";
import DesignReqDetails from "./Pages/DesignReqDetails/DesignReqDetails";
import StateInstallersTabe from "./Pages/StateInstallersTable/StateInstallers";
import SingleDesignReqDetails from "./Pages/SingleDesignReqData/SingleDesignReqData"
import AddersTable from "./Pages/AddersTable/AddersTable";
import Procedures from "./Pages/Procedures/Procedures";
import AddonsForm from "./Pages/AddonsPage/AddonsForm";
import AllAddons from "./Pages/AllAddons/AllAddons";
import AllProceduresTab from "./Components/AllProceduresTab/AllProceduresTab";
import CallUtilityCompany from "./Components/CardIssues/CallUtilityCompany";
function App() {
  const isAutherized = useSelector((state) => state.auth?.data);
  return (
    <>
      <GlobalStyle />

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {isAutherized ? (
        <Routes>
          <Route
            path="/"
            element={<Navigate to="/home/user-section/tickets/allTickets" />}
          />

          <Route
            path="*"
            element={<Navigate to="/home/user-section/tickets/allTickets" />}
          />

          <Route path="/home" element={<Home />}>
            <Route
              path="CreateCharter/:ticket_id"
              element={<CharterSteper />}
            />
            <Route path="addons_form/:lead_id" element={<AddonsForm />} />
            <Route path="all_addons/:lead_id" element={<AllAddons />} />
            <Route
              path="DesignReq/:ticket_id"
              element={<SendDesignRequestForm />}
            />

            <Route
              path="state_Installers/:ticket_id"
              element={<StateInstallersTabe />}
            />
            {/* <Route path="Adders/:ticket_id" element={<AddersTable />} /> */}
            <Route
              path="installers/:lead_id/:installer_id/:state_id"
              element={<StateInstallers />}
            />
            <Route path="procedures/:lead_id" element={<Procedures />} />
            {/* <Route path="Work_orders/:ticket_id" element={<WokOrdersList />} /> */}
            <Route
              path="Create_work_order/:ticket_id"
              element={<CreateWorkOrderForm />}
            />
            <Route
              path="Create_Site_survey/:ticket_id"
              element={<CreateSiteSurveyForm />}
            />
            <Route path="SolarSolution" element={<SolarSolution />}>
              <Route
                path="start-project/:lead_id"
                element={<ProcessSteper />}
              />

              <Route path="tabs/:lead_id" element={<LeadTabs />} />

              <Route
                path="step_details/:lead_id/:type"
                element={<CardIssuess />}
              />

              <Route
                path="New_card_details/:lead_id/:type/:card_id"
                element={<NewCardDetails />}
              />

              <Route
                path="charter_details/:lead_id"
                element={<CharterDetails />}
              />
              <Route
                path="design_req_details/:lead_id"
                element={<DesignReqDetails />}
              />

              <Route
                path="single_design_req_details/:lead_id/:design_req_id"
                element={<SingleDesignReqDetails />}
              />


              <Route path="Work_orders/:lead_id" element={<WokOrdersList />} />

              <Route
                path="site_survey_data/:lead_id"
                element={<SiteSurveyList />}
              />

              <Route
                path="Edit_Utility_Company_data/:lead_id/:type"
                element={<CallUtilityCompany />}
              />

              {/* <Route
                path="Work_orders/:ticket_id"
                element={<WokOrdersList />}
              /> */}
              <Route path="Adders/:lead_id" element={<AddersTable />} />
            </Route>
            <Route path="user-section" element={<UserSecAndRouts />}>
              <Route path="Installers" element={<Installers />} />
              <Route path="Distributors" element={<Distributors />} />
              <Route path="PMagaints" element={<PMAgaints />} />
              <Route path="all_counties" element={<AllProceduresTab />} />
              <Route path="Designers" element={<Designers />} />
              <Route path="tickets" element={<TicketsRouts />}>
                <Route path="allTickets" element={<AllTickets />} />
                <Route path="pendingTeckets" element={<PendingTickets />} />
                <Route path="ApprovedTickets" element={<ApprovedTickets />} />
                <Route path="RejectedTickets" element={<RejectedTickets />} />
                <Route path="AssignedTickets" element={<AssignedTickets />} />
              </Route>
            </Route>
          </Route>
        </Routes>
      ) : (
        <Routes>
          <Route path="*" element={<Login />} />
        </Routes>
      )}
    </>
  );
}

export default App;
