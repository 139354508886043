import axios from "axios";
import { procedures, delete_procedure } from "../../../Api/Api";
import { toast } from "react-toastify";
const store_procedures_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${procedures}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("procedure created Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const edit_procedures_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${procedures}/${data.procedure_id}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("procedure Updated Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const get_procedures_data = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${procedures}/${data}`,
    headers: headers,
    // data: data,
  });
  return response.data;
};

const get_All_procedures_data = async () => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${procedures}`,
    headers: headers,
    // data: data,
  });
  return response.data;
};

const delete_procedure_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${delete_procedure}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("procedure deleted Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const procedures_Service = {
  store_procedures_Action,
  get_procedures_data,
  get_All_procedures_data,
  delete_procedure_Action,
  edit_procedures_Action,
};
export default procedures_Service;
