import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import city_office_utilities_Service from "./city_office_utilities_service";
import { toast } from "react-toastify";
const initialState = {
  city_office_utilities: {},
  isError: false,
  isSuccess: false,
  isLodaing: false,
  massage: "",
};

export const store_city_office_utilities = createAsyncThunk(
  "city_office_utilities/post",
  async (data, thunkAPI) => {
    try {
      return await city_office_utilities_Service._store_city_office_utilities(
        data
      );
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      error?.response?.data?.errors?.map((err) =>
        toast.error(err.msg, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      );
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const get_city_office_utilities_data = createAsyncThunk(
  "city_office_utilities/get",
  async (data, thunkAPI) => {
    try {
      return await city_office_utilities_Service._get_city_office_utilities(
        data
      );
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const update_city_office_utilities = createAsyncThunk(
  "city_office_utilities/post",
  async (data, thunkAPI) => {
    try {
      return await city_office_utilities_Service.edit_city_office_utilities(
        data
      );
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      error?.response?.data?.errors?.map((err) =>
        toast.error(err.msg, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      );
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const city_office_utilitiesSlice = createSlice({
  name: "city_office_utilities",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLodaing = false;
      state.massage = "";
    },
  },
  extraReducers: {
    //store_city_office_utilities
    [store_city_office_utilities.pending]: (state) => {
      state.isLodaing = true;
    },
    [store_city_office_utilities.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.city_office_utilities = payload;
    },
    [store_city_office_utilities.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },

    //get_city_office_utilities_data
    [get_city_office_utilities_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [get_city_office_utilities_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.city_office_utilities = payload;
    },
    [get_city_office_utilities_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.city_office_utilities = null;
      state.massage = action.payload;
    },

    //edit_city_office_utilities
    [update_city_office_utilities.pending]: (state) => {
      state.isLodaing = true;
    },
    [update_city_office_utilities.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.city_office_utilities = payload;
    },
    [update_city_office_utilities.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
  },
});

export const { reset } = city_office_utilitiesSlice.actions;
export default city_office_utilitiesSlice.reducer;
