import axios from "axios";
import { cost_design_plan } from "../../../Api/Api";

const designPlanCost = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${cost_design_plan}`,
    headers: headers,
    data: data,
  });
  return response.data;
};



const designPlanCostService = {
    designPlanCost
};
export default designPlanCostService;
