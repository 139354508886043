import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Grid,
  ListItemText,
  List,
  ListItem,
  Typography,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import FolderIcon from "@mui/icons-material/Folder";
import EditCardAdders from "./EditCardAdders";
import { useDispatch, useSelector } from "react-redux";
import { updateAddersCostSpecificCard } from "../../Redux/Slices/AddersCardSlice/AddersCardSlice";
import { get_AddersCostCard } from "../../Redux/Slices/AddersCardSlice/AddersCardSlice";
const AddersDetailsGroup = ({ type, lead_id }) => {
  const addersCardDetails = useSelector((state) => state.AddersCostCard);
  const [openEditForm, setOpenEditForm] = useState(false);
  const [openCardData, setOpenCardData] = useState(true);
  const dispatch = useDispatch();
  const [AddersData, setAddersData] = useState({
    name: "",
    other: "",
    cost: "",
  });
  console.log(addersCardDetails, "addersCardDetailskjkjkj");
  console.log(type, "jhhj");

  const closeEditForm = () => {
    setOpenCardData(true);
    setOpenEditForm(false);
  };

  const handleShowEditForm = (detail) => {
    setOpenEditForm(true);
    setAddersData({
      name: detail.name,
      other: detail.other,
      cost: detail?.cost,
    });
  };

  const handleAddersDataChange = (key, value) =>
    setAddersData({ ...AddersData, [key]: value });

  const handle_edit = async (detail) => {
    const reqData = {
      id: detail.id,
      name: AddersData.name,
      cost: AddersData.cost,
      other: AddersData.other,
      _method: "PUT",
    };
    const reqUpdatedData = {
      id: lead_id,
      // type: type,
    };

    await dispatch(updateAddersCostSpecificCard(reqData));
    await setOpenEditForm(false);
    await window.location.reload();
    await dispatch(get_AddersCostCard(reqUpdatedData));
  };
  return (
    <>
      {addersCardDetails?.isLodaing ? (
        <Typography align="center">Loading ...</Typography>
      ) : (
        <div>
          {addersCardDetails?.AddersCostCard?.data?.map((detail) => (
            <Accordion key={detail?.id}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon key={detail?.id} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>{detail?.name}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Grid
                    className="display"
                    justifyContent="space-between"
                    mb={1}
                  >
                    <div>
                      <Typography>
                        {" "}
                        Name :
                        <span style={{ color: "gray", fontWeight: "bold" }}>
                          {detail?.name}
                        </span>{" "}
                      </Typography>
                      <Typography>
                        {" "}
                        Cost:
                        <span style={{ color: "gray", fontWeight: "bold" }}>
                          {detail?.cost}
                        </span>{" "}
                      </Typography>
                    </div>
                    <div>
                      {!openEditForm ? (
                        <IconButton onClick={() => handleShowEditForm(detail)}>
                          <EditIcon />
                        </IconButton>
                      ) : (
                        <IconButton onClick={closeEditForm}>
                          <CloseIcon />
                        </IconButton>
                      )}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {openEditForm && (
                        <>
                          <EditCardAdders
                            AddersData={AddersData}
                            handleAddersDataChange={handleAddersDataChange}
                            handle_edit={() => handle_edit(detail)}
                          />
                        </>
                      )}
                    </div>
                  </Grid>
                  {type == "adders" &&
                  detail?.media &&
                  detail?.media?.length > 0 ? (
                    <Grid>
                      <Typography variant="h6" align="center">
                        Uploaded Files
                      </Typography>
                      <List>
                        {detail?.media &&
                          detail?.media?.map((file) => (
                            <ListItem
                            // secondaryAction={
                            //   <IconButton
                            //     edge="end"
                            //     aria-label="delete"
                            //     onClick={() => handleDelete(file)}
                            //   >
                            //     <DeleteIcon />
                            //   </IconButton>
                            // }
                            >
                              <ListItemAvatar>
                                <a href={file.path} target="_blank">
                                  <Avatar>
                                    <FolderIcon />
                                  </Avatar>
                                </a>
                              </ListItemAvatar>
                              <ListItemText
                                primary="View File"
                                // secondary={"Secondary text"}
                              />
                            </ListItem>
                          ))}
                      </List>
                    </Grid>
                  ) : (
                    <>{type !== "adders" && <p>no files</p>}</>
                  )}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      )}
    </>
  );
};

export default AddersDetailsGroup;
