import axios from "axios";
import {
  store_first_step_of_survey,
  get_first_step_of_survey,
  update_first_step_of_survey,
  store_second_step_of_survey,
  get_second_step_of_survey,
  update_second_step_of_survey,
  store_quality_control_survey,
  get_quality_control_survey,
  update_quality_control_survey,
} from "../../../Api/Api";

const store_first_step_of_survey_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${store_first_step_of_survey}`,
    headers: headers,
    data: data,
  });
  return response.data;
};

const get_first_step_of_survey_Action = async (id) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${get_first_step_of_survey}/${id}`,
    headers: headers,
    // data: data,
  });
  return response.data;
};

const update_first_step_of_survey_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${update_first_step_of_survey}`,
    headers: headers,
    data: data,
  });
  return response.data;
};

const store_second_step_of_survey_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${store_second_step_of_survey}`,
    headers: headers,
    data: data,
  });
  return response.data;
};

const get_second_step_of_survey_Action = async (id) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${get_second_step_of_survey}/${id}`,
    headers: headers,
    // data: data,
  });
  return response.data;
};

const update_second_step_of_survey_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${update_second_step_of_survey}`,
    headers: headers,
    data: data,
  });
  return response.data;
};

const store_quality_control_step_of_survey_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${store_quality_control_survey}`,
    headers: headers,
    data: data,
  });
  return response.data;
};

const get_quality_control_step_of_survey_Action = async (id) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${get_quality_control_survey}/${id}`,
    headers: headers,
    // data: data,
  });
  return response.data;
};

const update_quality_control_step_of_survey_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${update_quality_control_survey}`,
    headers: headers,
    data: data,
  });
  return response.data;
};

const SurveyStepsService = {
  store_first_step_of_survey_Action,
  get_first_step_of_survey_Action,
  update_first_step_of_survey_Action,
  store_second_step_of_survey_Action,
  get_second_step_of_survey_Action,
  update_second_step_of_survey_Action,
  store_quality_control_step_of_survey_Action,
  get_quality_control_step_of_survey_Action,
  update_quality_control_step_of_survey_Action,
};
export default SurveyStepsService;
