import styled from "styled-components";

export const UL = styled.ul`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #99999955;
  margin: 0;
  list-style: none;
  padding-left: 0px;

  li {
    position: relative;
    flex-basis: 11%;
    margin-bottom: 10px;
    padding: 0px 10px;

    a {
      /* padding: 1rem 0; */
      color: #999999;
      transition: all 0.3s ease-out;
      font-weight: 600;
      display: flex;
      align-items: left;
      font-size: 15px;
      text-decoration: none;
      &:hover {
        color: #358ee1;
      }
      &.active {
        color: #358ee1;
        &:after {
          content: "";
          position: absolute;

          top: 138%;
          left: 8%;
          width: 70%;
          height: 3px;

          border-radius: 1rem;
          background: #358ee1;
        }
      }
      svg {
        margin-right: 5px;
      }
      svg {
        @media screen and (max-width: 768px) {
          font-size: 1.5vw;
        }
      }

      @media screen and (max-width: 768px) {
        font-size: 1.5vw;
      }
    }
  }
`;
