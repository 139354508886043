import axios from "axios";
import { contractorsList } from "../../../Api/Api";

const Get_Contractors_Action = async () => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${contractorsList}`,
    headers: headers,
    params: {
      return_all: "1",
    },
  });
  return response.data;
};

const contractors_Service = {
  Get_Contractors_Action,
};
export default contractors_Service;
