import axios from "axios";
import {
  store_call_jurisdictions,
  get_call_jurisdictions,
  update_call_jurisdictions,
} from "../../../Api/Api";
import { toast } from "react-toastify";
const _store_call_jurisdictions = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${store_call_jurisdictions}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success(" created Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const get_call_jurisdictions_data = async (ticket_id) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${get_call_jurisdictions}/${ticket_id}`,
    headers: headers,
    // data: data,
  });
  return response.data;
};

const edit_call_jurisdictions = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${update_call_jurisdictions}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("modified Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const call_jurisdictions_Service = {
  _store_call_jurisdictions,
  get_call_jurisdictions_data,
  edit_call_jurisdictions,
};
export default call_jurisdictions_Service;
