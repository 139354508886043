import React, { useState, useEffect } from "react";
import { reduxForm, Field } from "redux-form";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Grid, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { PrimaryButton } from "../../../Layouts/ButtonsControlled";
import { ModalContent } from "../../Modals/PmAssignModal/PmAssignModalStyle";
import { getTicketStatus } from "../../../Redux/Slices/TicketStatus/TicketStatusSlice";
import { get_business_license } from "../../../Redux/Slices/businessLicenseSlice/businessLicenseSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import { store_call_suupliers } from "../../../Redux/Slices/CallSupplierSlice/CallSupplierSlice";
import { update_call_suupliers } from "../../../Redux/Slices/CallSupplierSlice/CallSupplierSlice";
import MuiSelectField from "../../../Common/MuiSelectField/MuiSelectField";
import MuiTextField from "../../../Common/muiTextField/MuiTextField";
const CallSupplierModal = ({
  handleSubmit,
  handleopenModal,
  handleCloseModal,
  open_Call_supplier_Modal,
  set_Open_Call_supplier_Modal,
  call_suppliers_status,
  call_supplier_details,
  card_status,
  setOpenEditModal,
  productAvailability_status
}) => {
  const dispatch = useDispatch();

  const { lead_id } = useParams();
  const [confirm_panels, set_confirm_panels] = React.useState(0);
  console.log(
    call_supplier_details,
    call_suppliers_status,
    card_status,
    "call_supplier_details 565"
  );
  const [
    confirm_inverter_or_micro_inverter,
    set_confirm_inverter_or_micro_inverter,
  ] = React.useState(0);

  const handleChange_confirm_inverter_or_micro_inverter = (event) => {
    set_confirm_inverter_or_micro_inverter(event.target.checked);
  };

  const handleChange_confirm_panels = (event) => {
    set_confirm_panels(event.target.checked);
  };

  useEffect(() => {
    (call_suppliers_status == "5" || call_suppliers_status ==  "2" || card_status == "5"  ) &&
      set_confirm_panels(call_supplier_details?.confirm_panels);
    (call_suppliers_status == "5" || call_suppliers_status ==  "2"  || card_status == "5") &&
      set_confirm_inverter_or_micro_inverter(
        call_supplier_details?.confirm_inverter_or_micro_inverter
      );
  }, []);
  const submit = async (values) => {
    const reqData = {
      ticket_id: +lead_id,
      id: call_supplier_details?.id,
      confirm_panels: confirm_panels ? 1 : 0,
      confirm_inverter_or_micro_inverter: confirm_inverter_or_micro_inverter
        ? 1
        : 0,
    };
    call_suppliers_status == "1" || card_status == "1"
      ? (await dispatch(store_call_suupliers({ ...values, ...reqData }))) &&
        (await set_Open_Call_supplier_Modal(false))
      : (await dispatch(update_call_suupliers({ ...values, ...reqData }))) &&
        (await setOpenEditModal(false));
    await dispatch(getTicketStatus(lead_id));
  };

  return (
    <Dialog
      maxWidth={"md"}
      fullWidth
      open={handleopenModal}
      onClose={handleCloseModal}
    >
      <DialogContent>
        <ModalContent>
          <Box>
            <form onSubmit={handleSubmit((values) => submit(values))}>
              <Grid>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={confirm_panels}
                        name="confirm_panels"
                        onChange={handleChange_confirm_panels}
                        checked={confirm_panels}
                      />
                    }
                    label=" Confirm panels used in the contract are available in stock."
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        value={confirm_inverter_or_micro_inverter}
                        name="confirm_inverter_or_micro_inverter"
                        onChange={
                          handleChange_confirm_inverter_or_micro_inverter
                        }
                        checked={confirm_inverter_or_micro_inverter}
                      />
                    }
                    label="Confirm if Inverter or Micro-inverters are available."
                  />
                  {confirm_inverter_or_micro_inverter == 1 && (
                    <Grid>
                      <Field
                        required={confirm_inverter_or_micro_inverter == 1}
                        type="text"
                        rows={2}
                        multiline
                        name="inverter_or_micro_inverter"
                        component={MuiTextField}
                        label="Inverter or Micro-inverters are available"
                      />
                    </Grid>
                  )} 

                  <Grid className="" style={{ marginTop: "10px" }}>
                    <Field
                      required
                      name="status"
                      component={MuiSelectField}
                      label="status"
                    >
                      <MenuItem value="pending">pending </MenuItem>
                      <MenuItem value="completed">completed</MenuItem>
                    </Field>
                  </Grid>
                </FormGroup>
              </Grid>
              <DialogActions>
                <PrimaryButton
                  fullWidth
                  type="submit"
                  style={{
                    padding: "0",
                    width: "85%",
                    margin: "15px auto",
                    height: "42px",
                  }}
                  className="saveButton"
                >
                  {call_suppliers_status == "5" ? "Update" : "save"}
                </PrimaryButton>
              </DialogActions>
            </form>
          </Box>
        </ModalContent>
      </DialogContent>
    </Dialog>
  );
};

export default reduxForm({
  form: "CallSupplierForm",
})(CallSupplierModal);
