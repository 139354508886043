import React from "react";
import { UL } from "./NavbarRoutsStyle";
import { NavLink } from "react-router-dom";
import { SupervisedUserCircle } from "@mui/icons-material";
function NavbarRouts() {
  return (
    <nav>
      <UL>
        <li>
          <NavLink to={`tickets/allTickets`}>
            <SupervisedUserCircle />
            Ticketsk
          </NavLink>
        </li>

        <li>
          <NavLink to="Installers">
            <SupervisedUserCircle />
            Installers
          </NavLink>
        </li>
        <li>
          <NavLink to="Distributors">
            <SupervisedUserCircle />
            Distributors
          </NavLink>
        </li>

        <li>
          <NavLink to="Designers">
            <SupervisedUserCircle />
            Designers
          </NavLink>
        </li>
        <li>
          <NavLink to="PMagaints">
            <SupervisedUserCircle />
            PM Agents
          </NavLink>
        </li>

        <li>
          <NavLink to="all_counties">
            <SupervisedUserCircle />
            All Counties
          </NavLink>
        </li>
      </UL>
    </nav>
  );
}

export default NavbarRouts;
