import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get_call_suupliers_data } from "../../Redux/Slices/CallSupplierSlice/CallSupplierSlice";
import { getTicketStatus } from "../../Redux/Slices/TicketStatus/TicketStatusSlice";
import { Grid, IconButton, Typography , Card } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import CheckIcon from "@mui/icons-material/Check";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import EditIcon from "@mui/icons-material/Edit";
import EditCallSupplierModal from "../../Components/Modals/EditCallSupplierData/EditCallSupplierModal";
import CallSupplierModal from "../../Components/Modals/CallSupplierModal/CallSupplierModal";
const CallSupplierDetails = ({ lead_id }) => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const dispatch = useDispatch();
  const call_suupliers_Data = useSelector((state) => state?.callSuppliers);
  const cardStatus = useSelector((state) => state.TicketStatus);
  const call_suppliers_status = cardStatus?.TicketStatus?.data?.call_supplier;
  const call_supplier_details = call_suupliers_Data?.callSuppliers?.data;

  useEffect(() => {
    dispatch(get_call_suupliers_data(lead_id));
    dispatch(getTicketStatus(lead_id));
  }, [openEditModal]);

  const handleopenModal = () => {
    setOpenEditModal(true);
  };

  const handleCloseModal = () => {
    setOpenEditModal(false);
  };
  return call_suupliers_Data?.isLodaing ? (
    <h1>loading</h1>
  ) : call_suupliers_Data?.callSuppliers !== null ? (
    <Grid item xs={11} style={{ padding: "10px 55px" }} >
     <Card

style={{
  background: "rgb(249, 245, 255)",
  border: "1px solid rgb(214, 187, 251)",
  borderRadius: "8px",
  padding: "1rem",
  margin: "0.5rem 0px",
  position: "relative",


}}
>


<CardContent
  style={{ marginTop: "40px" }}

>

      

<Typography>
            {" "}
            <span
              style={{
                fontWeight: "bold",
                color: "#7e7ec0",
              }}
            >
              {" "}
              Confirm if Inverter or Micro-inverters are available
              {" "}
            </span>{" "}
            {call_supplier_details?.confirm_inverter_or_micro_inverter == 1 ? (
              "Yes"
            ) : (
              "No"
            )}
          </Typography>
        <Divider
          variant="inset"
          align="center"
          style={{ margin: "8px" }}
        />
        {call_supplier_details?.confirm_inverter_or_micro_inverter == 1 && (
          <>

<Typography>
                {" "}
                <span
                  style={{
                    fontWeight: "bold",
                    color: "#7e7ec0",
                  }}
                >
                  {" "}
                  inverter or micro nverter :
                  {" "}
                </span>{" "}
                {call_supplier_details?.inverter_or_micro_inverter}
              </Typography>

            <Divider
              variant="inset"
              align="center"
              style={{ margin: "8px" }}
            />
          </>
        )}

      
        <Typography>
            {" "}
            <span
              style={{
                fontWeight: "bold",
                color: "#7e7ec0",
              }}
            >
              {" "}
              Confirm panels used in the contract are available in stock.
              {" "}
            </span>{" "}
            {call_supplier_details?.confirm_panels == 1 ? (
              "Yes"
            ) : (
              "No"
            )}
          </Typography>


          <Divider
          variant="inset"
          align="center"
          style={{ margin: "8px" }}
        />

        
      

<Typography>
                {" "}
                <span
                  style={{
                    fontWeight: "bold",
                    color: "#7e7ec0",
                  }}
                >
                  {" "}
                  Status :
                  {" "}
                </span>{" "}
                {call_supplier_details?.status}
              </Typography>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton onClick={handleopenModal}>
            <EditIcon />
          </IconButton>
        </div>
      </CardContent>
      </Card>
      {openEditModal && (
        // <EditCallSupplierModal
        //   openEditModal={openEditModal}
        //   setOpenEditModal={setOpenEditModal}
        //   handleopenModal={handleopenModal}
        //   handleCloseModal={handleCloseModal}
        //   lead_id={lead_id}
        //   call_suupliers_Data={call_suupliers_Data}
        // />
        <CallSupplierModal
          call_suppliers_status={call_suppliers_status}
          call_supplier_details={call_supplier_details}
          initialValues={{
            inverter_or_micro_inverter:
              call_supplier_details?.inverter_or_micro_inverter,
            status: call_supplier_details?.status,
            confirm_inverter_or_micro_inverter: call_supplier_details?.confirm_inverter_or_micro_inverter,
            confirm_panels:call_supplier_details?.confirm_panels
           
          }}
          openEditModal={openEditModal}
          setOpenEditModal={setOpenEditModal}
          handleopenModal={handleopenModal}
          handleCloseModal={handleCloseModal}
          lead_id={lead_id}
        />
      )}
    </Grid>
  ) : (
    <Grid>
      <Typography align="center">
        Theres is no data for call supplier card
      </Typography>
    </Grid>
  );
};

export default CallSupplierDetails;
