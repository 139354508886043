import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AssignService from "./AssignService";

const initialState = {
  Assign_PM: {},
  isError: false,
  isSuccess: false,
  isLodaing: false,
  massage: "",
};

export const postAssignPM_User = createAsyncThunk(
  "AssignPM_Users/post",
  async (data, thunkAPI) => {
    try {
      return await AssignService.PM_Assign_Action(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const Assign_PM_Slice = createSlice({
  name: "Assign_PM",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLodaing = false;
      state.massage = "";
    },
  },
  extraReducers: {
    //post Assign_PM call  Action
    [postAssignPM_User.pending]: (state) => {
      state.isLodaing = true;
    },
    [postAssignPM_User.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.Assign_PM = payload;
    },
    [postAssignPM_User.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
  },
});

export const { reset } = Assign_PM_Slice.actions;
export default Assign_PM_Slice.reducer;
