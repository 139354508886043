import React from "react";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import styled from "styled-components";

const Lead = () => {
  const single_ticket = useSelector((state) => state.single_ticket);
  console.log(single_ticket?.singleTicket?.data, "single ticket in opooop");
  const leadData = single_ticket?.singleTicket?.data?.lead;
  const ticket_location =
    single_ticket?.singleTicket?.data?.ticket?.client_address;
  const opportunityData = single_ticket?.singleTicket?.data?.opportunity;
  return (
    <div style={{ marginLeft: "20px" }}>
      <Grid item>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="baseline"
          columns={18}
          spacing={4}
          style={{ textAlign: "left" }}
        >
          <Grid item lg={18} md={18} sm={18} xs={18}>
            {/* <Heading>Lead</Heading> */}
            <Typography mb={2}>Lead</Typography>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <p>
              Cutomer Type: <Value> {leadData?.customer_type}</Value>{" "}
            </p>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <p>
              First name: <Value> {leadData?.first_name}</Value>{" "}
            </p>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <p>
              {" "}
              Last Name: <Value> {leadData?.last_name}</Value>{" "}
            </p>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <p>
              Phone: <Value> {leadData?.phone}</Value>
            </p>
          </Grid>
          {leadData?.is_phone_receives_txt === 1 && (
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <p>
                Is phone receives txt:{" "}
                <Value>
                  {Boolean(leadData?.is_phone_receives_txt).toString()}
                </Value>{" "}
              </p>
            </Grid>
          )}

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <p>
              Mobile: <Value> {leadData?.mobile}</Value>{" "}
            </p>
          </Grid>
          {leadData?.is_mobile_receives_txt === 1 && (
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <p>
                Is mobile receives txt :{" "}
                <Value>
                  {Boolean(leadData?.is_mobile_receives_txt).toString()}
                </Value>{" "}
              </p>
            </Grid>
          )}

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <p>
              {" "}
              Email: <Value>{leadData?.email}</Value>{" "}
            </p>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <p>
              Perferred language: <Value> {leadData?.preferred_language}</Value>
            </p>
          </Grid>

          {leadData?.is_hoa === 1 && (
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <p>
                Is hoa: <Value> {Boolean(leadData?.is_hoa).toString()}</Value>{" "}
              </p>
            </Grid>
          )}

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <p>
              Street: <Value> {ticket_location}</Value>
            </p>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <p>
              {" "}
              Zip Code: <Value>{leadData?.zip_code}</Value>
            </p>
          </Grid>
          {leadData?.unit === null ? (
            <Value style={{ display: "hidden" }}></Value>
          ) : (
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <p>
                Unit: <Value> {leadData?.unit}</Value>{" "}
              </p>
            </Grid>
          )}

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <p>
              Created: <Value> {leadData?.days} Days ago </Value>
            </p>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <p>
              {" "}
              Status: <Value>{leadData?.status}</Value>{" "}
            </p>
          </Grid>

          {leadData?.company_name === null ? (
            <Value style={{ display: "hidden" }}></Value>
          ) : (
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <p>
                Company name: <Value>{leadData?.company_name}</Value>{" "}
              </p>
            </Grid>
          )}
          {leadData?.company_business_model === null ? (
            <Value style={{ display: "hidden" }}></Value>
          ) : (
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <p>
                Company business model:{" "}
                <Value>{leadData?.company_business_model}</Value>
              </p>
            </Grid>
          )}

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <p>
              Building type: <Value>{leadData?.building_type}</Value>
            </p>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <p>
              {" "}
              Date:{" "}
              <Value>
                {new Date(leadData?.time_to_contact).toLocaleDateString(
                  "en-US",
                  {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  }
                )}
              </Value>
            </p>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <p>
              Way to contact: <Value>{leadData?.way_to_contact}</Value>{" "}
            </p>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}></Grid>
          {leadData?.is_decision_maker_present === 1 && (
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <p>
                Is decision maker present:
                <Value>
                  {Boolean(leadData?.is_decision_maker_present).toString()}{" "}
                </Value>
              </p>
            </Grid>
          )}

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <p>
              {" "}
              House ownership: <Value>{leadData?.house_ownership} </Value>
            </p>
          </Grid>
          {opportunityData?.aerial_photo === "" ? (
            <div></div>
          ) : (
            <Grid item lg={18} md={18} sm={18} xs={18}>
              <img
                style={{
                  width: "100%",
                  borderRadius: "2rem",
                  display: "block",
                  margin: "auto",
                }}
                src={opportunityData?.aerial_photo}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

const Value = styled.span`
  color: rgb(136, 136, 136);
`;
export default Lead;
