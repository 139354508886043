import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import Ticket from "../../Components/Ticket/Ticket";
import { GetTickets } from "../../Redux/Slices/Tickets/TicketSlice";
import { useDispatch, useSelector } from "react-redux";
import LottieAnimation from "../../Components/LottieAnimation/LottieAnimation";
import loading from "../../Assets/lottie/loading.json";
import Pagination from "@mui/material/Pagination";
import { useParams } from "react-router-dom";
const ApprovedTickets = () => {
  const dispatch = useDispatch();
  const tickets = useSelector((state) => state.tickets);
  const { allTickets } = useSelector((state) => state.tickets);
  let all_tickets = allTickets?.data || [];
  const pageAfterLoad = allTickets?.data?.current_page;
  const [pagee, setPage] = useState(pageAfterLoad);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  console.log(tickets, "allTickets");
  console.log(pagee, "page in all tickets");
  console.log(allTickets?.data?.data, "allTickets per page ");

  useEffect(() => {
    const reqData = {
      page: pagee,
      status: "approved",
    };
    dispatch(GetTickets(reqData));
  }, [pagee]);

  console.log(allTickets?.data?.current_page, "allTicketsallTicketshgh");
  return (
    <>
      {tickets?.status == "loading" ? (
        <LottieAnimation lotti={loading} width={180} height={180} />
      ) : (
        <>
          <Grid container columns={12} spacing={2}>
            {allTickets?.data?.data?.length > 0 ? (
              allTickets?.data?.data?.map((e, index) => {
                return (
                  <>
                    {" "}
                    <Grid key={e.id} item xs={12} lg={4} md={6}>
                      <Ticket e={e} />
                    </Grid>
                  </>
                );
              })
            ) : (
              <Grid item xs={12}>
                <Typography mt={5} align="center">
                  {" "}
                  there are no tickets{" "}
                </Typography>
              </Grid>
            )}
          </Grid>

          <Typography>Page: {pagee}</Typography>
          <Pagination
            count={allTickets?.data?.total_pages}
            page={pagee}
            onChange={handleChange}
          />
        </>
      )}
    </>
  );
};

export default ApprovedTickets;
