import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Installers_Service from "./InstallersService";

const initialState = {
  installers: {},
  isError: false,
  isSuccess: false,
  isLodaing: false,
  massage: "",
};

export const getInstallersListAction = createAsyncThunk(
  "installers/getAll",
  async (data, thunkAPI) => {
    try {
      return await Installers_Service.Get_InstallersList_Action(data);
    } catch (error) {
      const massage = error.message;
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const getInstaller_data_Action = createAsyncThunk(
  "installerData/getAll",
  async (data, thunkAPI) => {
    try {
      return await Installers_Service.Get_Installer_data_Action(data);
    } catch (error) {
      const massage = error.message;
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const InstallersSlice = createSlice({
  name: "installers",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLodaing = false;
      state.isDeleteLodaing = false;
      state.massage = "";
    },
  },
  extraReducers: {
    [getInstallersListAction.pending]: (state) => {
      state.isLodaing = true;
    },
    [getInstallersListAction.fulfilled]: (state, action) => {
      state.isLodaing = false;
      state.installers = action.payload;
      state.isSuccess = true;
    },
    [getInstallersListAction.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },

    [getInstaller_data_Action.pending]: (state) => {
      state.isLodaing = true;
    },
    [getInstaller_data_Action.fulfilled]: (state, action) => {
      state.isLodaing = false;
      state.installers = action.payload;
      state.isSuccess = true;
    },
    [getInstaller_data_Action.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;

      state.massage = action.payload;
    },
  },
});

export const { reset } = InstallersSlice.actions;
export default InstallersSlice.reducer;
