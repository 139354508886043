import React, { useEffect, useState } from "react";
import {
  Button,
  Typography,
  Grid,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  IconButton,
  Divider,
  ListItemText,
  ListItemAvatar,
  List,
  ListItem,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import FolderIcon from "@mui/icons-material/Folder";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { get_city_office_utilities_data } from "../../Redux/Slices/city_office_utilities_slice/city_office_utilities_slice";
import EditIcon from "@mui/icons-material/Edit";
import CallCityofficeutilityModal from "../../Components/Modals/Call_City_office_utility_Modal/CallCityofficeutilityModal";
import { getTicketStatus } from "../../Redux/Slices/TicketStatus/TicketStatusSlice";
import { getSingleCard } from "../../Redux/Slices/CreateCard/CreateCardSlice";
import {getSingleCardofTicket} from "../../Redux/Slices/ticketCards/ticketCardSlice"
import LottieAnimation from "../../Components/LottieAnimation/LottieAnimation";
import loading from "../../Assets/lottie/loading.json";
import NewCardFieldsModal from "../../Components/Modals/new_cards_fields/NewCardsFields";
import { useParams } from "react-router-dom";
const NewCardDetails = ({ type, card_id }) => {
  const dispatch = useDispatch();
  const {lead_id} = useParams();
  const create_card = useSelector((state) => state.create_card);
  const ticket_cards = useSelector((state) => state.get_ticket_card);
  const [openNewCardsFieldsModal, setOpenNewCardsFieldsModal] = useState(false);
  console.log(create_card?.create_card?.data, "create_card");
  console.log(card_id, "card_id");
  const allCardData = create_card?.create_card?.data;
console.log(lead_id  , "params in single card details");
console.log(ticket_cards?.ticket_card?.data?.body , "ticket_cards________________")
  let fieldsData = {} || [];
console.log(fieldsData  , "fieldsData 1")
  ticket_cards?.ticket_card?.data?.body?.length > 0 &&
    ticket_cards?.ticket_card?.data?.body?.map(
      (field) =>
        (fieldsData[`${field.type}_${field.key}`] = field.value
          ? field.value
          : "")
    );
    console.log(fieldsData  , "fieldsData 2")



  useEffect(() => {
    dispatch(getSingleCard(card_id));
    const reqData = {
      card_id:card_id,
      ticket_id:lead_id
    }
    dispatch(getSingleCardofTicket(reqData))
  }, [openNewCardsFieldsModal]);

  const handleopenNewCardsFieldsModal = () => {
    setOpenNewCardsFieldsModal(true);
  };

  const handleCloseNewCardsFieldsModal = () => {
    setOpenNewCardsFieldsModal(false);
  };

  return (
    <>
      <Grid item xs={11} style={{ padding: "10px 55px" }}>
        <Card
          style={{
            background: "rgb(249, 245, 255)",
            border: "1px solid rgb(214, 187, 251)",
            borderRadius: "8px",
            padding: "1rem",
            margin: "0.5rem 0px",
            position: "relative",
          }}
        >
          {create_card?.isLodaing ? (
            <LottieAnimation lotti={loading} width={180} height={180} />
          ) : (
            <>
              <CardContent
              //   style={{ marginTop: "40px" }}
              >
                {ticket_cards?.ticket_card?.data?.body &&
                ticket_cards?.ticket_card?.data?.body.length > 0 ? (
                  ticket_cards?.ticket_card?.data?.body?.map((field) => (
                    <>
                      {field?.type !== "file" && (
                        <Typography>
                          {" "}
                          <span
                            style={{
                              fontWeight: "bold",
                              color: "#7e7ec0",
                            }}
                          >
                            {" "}
                            {field?.type !== "file" && field?.label}{" "}
                          </span>{" "}
                          {field?.type !== "file" && field?.value}
                        </Typography>
                      )}
                      {field?.type == "file" && (
                        <List>
                          {field?.value && field?.type == "file" &&
                            field?.value.map((file, index) => (
                                <>
                                <a  href={`https://pmb.boxbyld.tech/profiles/${file.path}`} target="_blank">
                              <ListItem
                                key={index}
                                //   secondaryAction={
                                //     <IconButton
                                //       edge="end"
                                //       aria-label="delete"
                                //       //   onClick={() => handleRemoveItem(file)}
                                //     >
                                //       <DeleteIcon />
                                //     </IconButton>
                                //   }
                              >
                                <ListItemAvatar
                                  style={{ display: "flex" }}
                                //   href={`https://pmb.boxbyld.tech/profiles/${file.path}`}
                                >
                                  {/* <a  href={`https://pmb.boxbyld.tech/profiles/${file.path}`} target="_blank"> */}
                                    <FolderIcon />
                                 

                                  <Typography>view File</Typography>{" "}
                                </ListItemAvatar>
                                <ListItemText />
                              </ListItem>
                              </a>
                              </>
                            ))}
                        </List>
                      )}

                      {/* <Divider
                                    variant="inset"
                                    align="center"
                                    style={{ margin: "8px" }}
                                /> */}
                    </>
                  ))
                ) : (
                  <p>no data </p>
                )}

                {/* {field?.type !== "file" && ( */}
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <IconButton onClick={handleopenNewCardsFieldsModal}>
                    <EditIcon />
                  </IconButton>
                </div>
                {/* )} */}
              </CardContent>
            </>
          )}

          {openNewCardsFieldsModal && (
            <NewCardFieldsModal
              handleCloseNewCardsFieldsModal={handleCloseNewCardsFieldsModal}
              openNewCardsFieldsModal={openNewCardsFieldsModal}
              setOpenNewCardsFieldsModal={setOpenNewCardsFieldsModal}
              lead_id={lead_id}
               fieldsData={fieldsData}
              initialValues={fieldsData}
              card_id={card_id}
              allCardData={allCardData}
            />
          )}
        </Card>
      </Grid>
    </>
  );
};

export default NewCardDetails;
