import { Typography, Grid } from "@mui/material";
import React from "react";
import styled from "styled-components";
import Divider from "@mui/material/Divider";

const CharterData = ({ charter_details }) => {
  const charterData = charter_details?.charter?.data;
  console.log(charterData, "charter_details in new");
  return (
    <div style={{ marginLeft: "20px", padding: "5px" }}>
      <Grid item>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="baseline"
          columns={18}
          spacing={4}
          style={{ textAlign: "left" }}
        >
          <Grid item lg={18} md={18} sm={18} xs={18}>
            {/* <Heading> */}
            <Typography align="center" variant="h5">
              Charter Details
            </Typography>
            {charterData?.approval_status && (
              <Typography
                align="left"
                style={{ color: "green", fontWeight: "bold" }}
              >
                Charter status : {charterData?.approval_status}
              </Typography>
            )}
            {/* </Heading> */}
          </Grid>
          <Grid item lg={18} md={18} sm={18} xs={18}>
            {/* <Heading> */}
            <Typography variant="h6">General Project Information : </Typography>
            {/* </Heading> */}
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <p>
              Project Name: <Value>{charterData?.project_name}</Value>
            </p>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <p>
              Project Manager: <Value>{charterData?.project_manager}</Value>
            </p>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <p>
              Project Address: <Value>{charterData?.project_address}</Value>
            </p>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <p>
              System Size(K/W):{" "}
              <Value>{charterData?.project_system_size}</Value>
            </p>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <p>
              Installation Type:{" "}
              <Value>{charterData?.project_installation_type}</Value>
            </p>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <p>
              Additional Permits:{" "}
              <Value>{charterData?.project_additional_permits}</Value>
            </p>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <p>
              Project Roofer: <Value>{charterData?.project_roofer}</Value>
            </p>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <p>
              Project Designer: <Value>{charterData?.project_designer}</Value>
            </p>
          </Grid>
          <Grid item xs={18} style={{ margin: "5px auto" }}>
            <Divider />
          </Grid>
          <Grid item lg={18} md={18} sm={18} xs={18}>
            {/* <Heading> */}
            <Typography variant="h6">Solar Permitting : </Typography>
            {/* </Heading> */}
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <p>
              Jurisdiction: <Value>{charterData?.jurisdiction}</Value>
            </p>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <p>
              Submittal Process: <Value>{charterData?.submittal_process}</Value>
            </p>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <p>
              Jurisdiction Contact Email:{" "}
              <Value>{charterData?.jurisdiction_contact_email}</Value>
            </p>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <p>
              Jurisdiction Contact Mobile:{" "}
              <Value>{charterData?.jurisdiction_contact_mobile}</Value>
            </p>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <p>
              jurisdiction_estimated_duration:{" "}
              <Value>{charterData?.jurisdiction_estimated_duration}</Value>
            </p>
          </Grid>
          <Grid item xs={18} style={{ margin: "5px auto" }}>
            <Divider />
          </Grid>
          <Grid item lg={18} md={18} sm={18} xs={18}>
            {/* <Heading> */}
            <Typography variant="h6">PTO : </Typography>
            {/* </Heading> */}
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <p>
              Utility Company: <Value>{charterData?.utility_company}</Value>
            </p>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <p>
              utility PTO Processy:{" "}
              <Value>{charterData?.utility_pto_process}</Value>
            </p>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <p>
              Utility Contact Email:{" "}
              <Value>{charterData?.utility_contact_email}</Value>
            </p>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <p>
              Utility Contact Mobile:{" "}
              <Value>{charterData?.utility_contact_mobile}</Value>
            </p>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <p>
              utility Estimated Duration:{" "}
              <Value>{charterData?.utility_estimated_duration}</Value>
            </p>
          </Grid>
          <Grid item xs={18} style={{ margin: "5px auto" }}>
            <Divider />
          </Grid>
          <Grid item lg={18} md={18} sm={18} xs={18}>
            {/* <Heading> */}
            <Typography variant="h6">Roofing: </Typography>
            {/* </Heading> */}
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <p>
              Roofing Contract Redecking:{" "}
              <Value>{charterData?.roofing_contract_redecking}</Value>
            </p>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <p>
              Roofing Shingles: <Value>{charterData?.roofing_shingles}</Value>
            </p>
          </Grid>
          <Grid item xs={18} style={{ margin: "5px auto" }}>
            <Divider />
          </Grid>
          <Grid item lg={18} md={18} sm={18} xs={18}>
            {/* <Heading> */}
            <Typography variant="h6">MPU: </Typography>
            {/* </Heading> */}
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <p>
              MPU Meter Spot: <Value>{charterData?.mpu_meter_spot}</Value>
            </p>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <p>
              MPU Shutoff Required:{" "}
              <Value>{charterData?.mpu_shutoff_required}</Value>
            </p>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <p>
              MPU Shutoff Required:{" "}
              <Value>{charterData?.mpu_shutoff_required}</Value>
            </p>
          </Grid>
          <Grid item xs={18}>
            <Divider />
          </Grid>
          <Grid item lg={18} md={18} sm={18} xs={18}>
            {/* <Heading> */}
            <Typography variant="h6">Products: </Typography>
            {/* </Heading> */}
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <p>
              Project Valuation: <Value>{charterData?.project_valuation}</Value>
            </p>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <p>
              Products Manufacturer:{" "}
              <Value>{charterData?.products_manufacturer}</Value>
            </p>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <p>
              inverter Manufacturer Model:{" "}
              <Value>{charterData?.inverter_manufacturer_model}</Value>
            </p>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <p>
              Products Total Price:{" "}
              <Value>{charterData?.products_total_price}</Value>
            </p>
          </Grid>
          <Grid item xs={18} style={{ margin: "5px auto" }}>
            <Divider />
          </Grid>
          <Grid item lg={18} md={18} sm={18} xs={18}>
            {/* <Heading> */}
            <Typography variant="h6">Schedule: </Typography>
            {/* </Heading> */}
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <p>
              Welcome Call Date:{" "}
              <Value>
                {new Date(charterData?.welcome_call).toLocaleDateString(
                  "en-US",
                  {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  }
                )}
              </Value>
            </p>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <p>
              Site Survey Date:{" "}
              <Value>
                {new Date(charterData?.site_survey).toLocaleDateString(
                  "en-US",
                  {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  }
                )}
              </Value>
            </p>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <p>
              Business License Date:{" "}
              <Value>
                {new Date(
                  charterData?.business_license_date
                ).toLocaleDateString("en-US", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })}
              </Value>
            </p>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <p>
              Meter Spot Date:{" "}
              <Value>
                {new Date(charterData?.meterspot_date).toLocaleDateString(
                  "en-US",
                  {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  }
                )}
              </Value>
            </p>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
const Value = styled.span`
  color: rgb(136, 136, 136);
`;
export default CharterData;
