import React from 'react';
import { Field, FieldArray, reduxForm } from 'redux-form';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import MuiTextField from "../../../Common/muiTextField/MuiTextField"


const renderAddersFields = ({ fields, meta: { touched, error, submitFailed } }) => (

  <ul>
    <li>
      <button type="button" onClick={() => fields.push({})}>Add </button>
      {(touched || submitFailed) && error && <span>{error}</span>}
    </li>
    <li >
        <button
       //   type="button"
        //  title="Remove Member"
         // onClick={() => fields.remove(index)}
        >Remove </button>
        <h4>adder #</h4>
        <Field
          name={`firstName_0`}
          type="text"
          component={MuiTextField}
          label="First Name"
        />
        <Field
          name={`lastName_0`}
          type="text"
          component={MuiTextField}
          label="Last Name"
        />
       {/* // <FieldArray name={`${member}.hobbies`} component={renderHobbies} /> */}
      </li>
    {
  
    fields.map((member, index) => (
        console.log(fields , "fields"),
      <li key={index}>
        <button
       //   type="button"
        //  title="Remove Member"
          onClick={() => fields.remove(index)}
        >Remove </button>
        <h4>adder #{index + 1}</h4>
        <Field
          name={`${member}.firstName`}
          type="text"
          component={MuiTextField}
          label="First Name"
        />
        <Field
          name={`${member}.lastName`}
          type="text"
          component={MuiTextField}
          label="Last Name"
        />
       {/* // <FieldArray name={`${member}.hobbies`} component={renderHobbies} /> */}
      </li>
    ))}
  </ul>
);

const NewExtraAdders = props => {
  const { handleSubmit, pristine, reset, submitting , handleOpenAddersFormModal ,handleCloseAddersFormModal  } = props;

  const submit = async (values) => {
    console.log(values , "values fields array")
  }
  return (
    <Dialog
    maxWidth={"md"}
    open={handleOpenAddersFormModal}
    onClose={handleCloseAddersFormModal}
  >
    <DialogContent>
    <form onSubmit={handleSubmit((values) => submit(values))}>
      <FieldArray name="addersList" component={renderAddersFields} />
      <div>
        <button type="submit" disabled={submitting}>Submit</button>
      </div>
    </form>
    </DialogContent>
    </Dialog>
  );
};

export default reduxForm({
  form: 'fieldArrays', // a unique identifier for this form
})(NewExtraAdders);
