import React, { useState, useEffect, useRef } from "react";
import Accordion from "@mui/material/Accordion";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloseIcon from "@mui/icons-material/Close";
import styled from "styled-components";
import {
  Button,
  Typography,
  Grid,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  IconButton,
  Badge,
  FormControl,
} from "@mui/material";

import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  getIssues,
  Upload_issue,
  delete_Issues,
} from "../../Redux/Slices/CreateIssue/Create_Issue_Slice";
import {
  get_pto_data,
  get_NTP_data,
  get_meter_spot_data,
  get_quotes_for_products_data,
  get_product_orders_data,
  get_permit_to_finance_data,
  get_order_place_cards_data,
  get_coordinate_product_delivery_data,
  get_coordinate_installations_data,
  get_schedule_inspections_data,
  get_installer_invoices_data,
  get_upload_to_pto_data,
  get_send_full_details_to_client_data,
  get_review_profit_loss_statement_data,
  get_closure_report_data,
  get_installation_payment_collection_data,
  get_installer_commissions_data,
  get_installation_pics_data,
  get_confirm_inspection_data,
  get_apply_permits_data,
  get_end_project_call_data,
  get_upload_PTO_to_finance_data,
  get_final_job_card_data,
  get_schedule_with_city_office_data,
  get_inform_inspection_result_data,
  get_monitoring_optimizers_data,
  get_m1_collection_data,
  get_m2_collection_data
} from "../../Redux/Slices/CardsStatusSlics/CardStepsSlice";
import { get_job_reviews_data } from "../../Redux/Slices/JobReviewsWithSalesSlice/JobReviewsSlice";
import { get_call_suupliers_data } from "../../Redux/Slices/CallSupplierSlice/CallSupplierSlice";
import { get_confirm_with_salesAndCustomer_data } from "../../Redux/Slices/ConfirmWithSalesAndCustomerSlice/ConfirmWithSalesAndCustomerSlice";
import { getTicketWelcomeCll } from "../../Redux/Slices/WelcomeCallSlice/WelcomeCallSlice";
import { getWelcomeCall } from "../../Redux/Slices/WelcomeCallSlice/WelcomeCallSlice";
import { get_business_license } from "../../Redux/Slices/businessLicenseSlice/businessLicenseSlice";
import { useDispatch, useSelector } from "react-redux";
import { PrimaryButton } from ".././../Layouts/ButtonsControlled";
import { useParams } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import DeleteIcon from "@mui/icons-material/Delete";
import FolderIcon from "@mui/icons-material/Folder";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";

import EditIcon from "@mui/icons-material/Edit";
import Avatar from "@mui/material/Avatar";
import LottieAnimation from "../LottieAnimation/LottieAnimation";
import loading from "../../Assets/lottie/loading.json";
import UploadFilesForCard from "./UploadFilesForCard";
import CardStatusDetails from "./CardStatusDetails";
import EditIssueModal from "../../Components/Modals/EditIssueModal/EditIssueModal";
import { Add } from "@mui/icons-material";
const CardIssues = ({ lead_id, ticket_status }) => {
  const dispatch = useDispatch();
  const IssuesData = useSelector((state) => state?.issues);
  const business_license_Data = useSelector((state) => state?.business_license);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [UpdatedUploadFiles, setUpdatedUploadedFiles] = useState([]);
  const MAX_COUNT = 500;
  const Submit_ref = useRef();
  const [showUpload, setShowUpload] = useState(false);
  const [openeditIssueModal, setOpenEditIssueModal] = useState(false)
  const [selectedIssue, setSelectedIssue] = useState();
  const [selectedEditIssue, setSelectedEditIssue] = useState()
  const [selectedUploadedFile, setSelectedUploadedFiles] = useState();
  const AllIssues = IssuesData?.issues?.data;
  const [fileLimit, setFileLimit] = useState(false);
  console.log(selectedIssue, AllIssues, "selectedIssue54545")
  const { type } = useParams();
  const welcomCallDetails = useSelector((state) => state.WelcomeCall);
  const cardStatus = useSelector((state) => state?.cardStatus);
  const handleDelete = async (file) => {
    await setSelectedUploadedFiles(file);
    await dispatch(delete_Issues(selectedUploadedFile.id));
    await dispatch(getIssues(reqData));
  };
  const handleShowUploadFiles = (id) => {
    setSelectedIssue(id);
    console.log(selectedIssue, "12345")

    setShowUpload(true);


  };


  const handleUploadFiles = (files) => {
    const uploaded = [...UpdatedUploadFiles];
    let limitExceeded = false;
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        setUpdatedUploadedFiles(uploaded);
        if (uploaded.length === MAX_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_COUNT) {
          alert(`You can only add a maximum of ${MAX_COUNT} files`);
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    });
    if (!limitExceeded) {
      setUploadedFiles(uploaded);
    }
  };
  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };


  const handleUploadIssues = async () => {
    const formData = new FormData();
    uploadedFiles.map((e, i) => {
      // for (const property in e) {
      //   console.log(`${property}: ${e[property]}`);
      formData.append(`media[${i}]`, e);
      // }
    });
    formData.append("pm_status_issue_id", selectedIssue.id);

    await dispatch(Upload_issue(formData));
    await dispatch(getIssues(reqData));
    //console.log(formData, "formData", files, "files");
  };

  const handleRemoveItem = (file) => {
    const name = UpdatedUploadFiles.map((file) => file.name);
    setUpdatedUploadedFiles(
      UpdatedUploadFiles.filter((item) => item.name !== file.name)
    );
    console.log(name, "name");
    console.log(UpdatedUploadFiles, "UpdatedUploadFilesssssssss");
  };
  const reqData = { id: lead_id, type: type };
  console.log(reqData, "reqdaata");



  useEffect(() => {
    dispatch(getIssues(reqData));
  }, [openeditIssueModal]);

  useEffect(() => {
    dispatch(getIssues(reqData));

    if (type == "apply_pto") {
      dispatch(get_pto_data(lead_id));
      dispatch(getIssues(reqData));
    } else if (type == "ntp_finance_portal") {
      dispatch(get_NTP_data(lead_id));
      dispatch(getIssues(reqData));
    } else if (type == "apply_meter_spot") {
      dispatch(get_meter_spot_data(lead_id));
    } else if (type == "products_quotes") {
      dispatch(get_quotes_for_products_data(lead_id));
    } else if (type == "order_product") {
      dispatch(get_product_orders_data(lead_id));
    } else if (type == "upload_permit_to_finance") {
      dispatch(get_permit_to_finance_data(lead_id));
    } else if (type == "order_placards") {
      dispatch(get_order_place_cards_data(lead_id));
    } else if (type == "coordinate_product_delivery") {
      dispatch(get_coordinate_product_delivery_data(lead_id));
    } else if (type == "coordinate_client_installer_installation") {
      dispatch(get_coordinate_installations_data(lead_id));
    } else if (type == "schedule_inspection") {
      dispatch(get_schedule_inspections_data(lead_id));
    } else if (type == "invoice_for_installer") {
      dispatch(get_installer_invoices_data(lead_id));
    } else if (type == "upload_to_pto") {
      dispatch(get_upload_to_pto_data(lead_id));
    } else if (type == "send_full_details_to_client") {
      dispatch(get_send_full_details_to_client_data(lead_id));
    } else if (type == "review_profit_loss_statement") {
      dispatch(get_review_profit_loss_statement_data(lead_id));
    } else if (type == "closure_report") {
      dispatch(get_closure_report_data(lead_id));
    } else if (type == "payment_collection") {
      dispatch(get_installation_payment_collection_data(lead_id));
    } else if (type == "installer_commissions") {
      dispatch(get_installer_commissions_data(lead_id));
    } else if (type == "upload_installation_pics_to_finance") {
      dispatch(get_installation_pics_data(lead_id));
    } else if (type == "confirm_installer_inspection") {
      dispatch(get_confirm_inspection_data(lead_id));
    } else if (type == "apply_all_required_permits") {
      dispatch(get_apply_permits_data(lead_id));
    } else if (type == "end_project_call") {
      dispatch(get_end_project_call_data(lead_id));
    } else if (type == "pto_to_finance") {
      dispatch(get_upload_PTO_to_finance_data(lead_id));
    } else if (type == "final_job_card") {
      dispatch(get_final_job_card_data(lead_id));
    } else if (type == "confirm_city_office_time_frame") {
      dispatch(get_schedule_with_city_office_data(lead_id));
    } else if (type == "inform_inspection_result") {
      dispatch(get_inform_inspection_result_data(lead_id));
    } else if (type == "monitoring_optimizer") {
      dispatch(get_monitoring_optimizers_data(lead_id));
    } else if (type == "m1_collection") {
      dispatch(get_m1_collection_data(lead_id));
    }
    else if (type == "m2_payment_collection") {
      dispatch(get_m2_collection_data(lead_id));
    }
    else if (type == "business_license") {
      dispatch(get_business_license(lead_id));
    } else if (type == "call_supplier") {
      dispatch(get_call_suupliers_data(lead_id));
    } else if (type == "confirm_items_with_sales_and_customer") {
      dispatch(get_confirm_with_salesAndCustomer_data(lead_id));
    } else if (type == "job_review") {
      dispatch(get_job_reviews_data(lead_id));
    }

    // openEditForm &&
    //   setPTOData({
    //     comment: cardStatus?.cardStatus?.data?.comment,
    //     status: cardStatus?.cardStatus?.data?.status,
    //   });
  }, [type]);
  // const welcomeCallDetails = () => (
  //   <WelcomeCallDetails welcomCallDetails={welcomCallDetails} />
  // );

  const handleopenModal = (issue) => {
    setOpenEditIssueModal(true)
    setSelectedIssue(issue)
    console.log(issue, "issue")
    console.log(selectedEditIssue, "selectedEditIssue")
  }

  const handleCloseModal = () => {
    setOpenEditIssueModal(false)
  }

  const handlecloseUpload = () => {
    setShowUpload(false);
  };

  return (
    <div>
      {IssuesData?.isLodaing ? (
        <LottieAnimation lotti={loading} width={180} height={180} />
      ) : IssuesData?.isSuccess || cardStatus?.isSuccess ? (
        <>
          {/* <CardStatusDetails type={type} lead_id={lead_id} ticket_status={ticket_status} /> */}
          <Grid style={{ padding: "20px" }}>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              style={{ display: "block" }}
            >

              {/* {AllIssues?.length > 0 && (
              <Grid style={{padding:"5px 100px"}}>
                <Typography align="left" variant="h5">Card issues:</Typography>
                </Grid>
              )} */}

              {AllIssues?.length > 0 ? (

                AllIssues.map((issue, index) => (
                  <>
                    {/* <Accordion key={index}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>{issue?.title}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div style={{display:"flex" , justifyContent:"space-between"}}>
                    <div>
                    <Typography>
                      {" "}
                      Issue note :
                      <span style={{ color: "gray", fontWeight: "bold" }}>
                        {issue?.note}
                      </span>{" "}
                    </Typography>
                    <Typography>
                      {" "}
                      Issue Status:
                      <span style={{ color: "gray", fontWeight: "bold" }}>
                        {issue?.status}
                      </span>{" "}
                    </Typography>
                    </div>
                    <IconButton color="primary" aria-label="upload picture" component="label" onClick={handleopenModal}>
                      <EditIcon />
                    </IconButton>
                 
                    </div>
                  

                    {issue?.media?.length == 0 ? (
                      <div>
                        <UploadFilesForCard
                          handleShowUploadFiles={handleShowUploadFiles}
                          issue={issue}
                          selectedIssue={selectedIssue}
                          lead_id={lead_id}
                          type={type}
                          setShowUpload={setShowUpload}
                          showUpload={showUpload}
                          setUploadedFiles={setUploadedFiles}
                          uploadedFiles={uploadedFiles}
                          UpdatedUploadFiles={UpdatedUploadFiles}
                          setUpdatedUploadedFiles={setUpdatedUploadedFiles}
                          MAX_COUNT={MAX_COUNT}
                        />
                        {UpdatedUploadFiles?.map((file, index) => (
                          <>
                            <ListItem
                              secondaryAction={
                                <IconButton
                                  edge="end"
                                  aria-label="delete"
                                  onClick={() => handleRemoveItem(file)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              }
                            >
                              <ListItemAvatar style={{ display: "flex" }}>
                                <FolderIcon />
                                <Typography>{file.name}</Typography>{" "}
                              </ListItemAvatar>
                              <ListItemText
                              />
                            </ListItem>
                          </>
                        ))}
                        <Grid className="display" mt={4}>
                          <PrimaryButton
                            fullWidth
                            style={{
                              padding: "0",
                              width: "85%",
                              margin: "15px auto",
                              height: "42px",
                            }}
                            className="saveButton"
                            onClick={handleUploadIssues}
                          >
                            Save
                          </PrimaryButton>
                        </Grid>
                      </div>
                    ) : (
                      <Grid>
                        <Typography variant="h6" align="center">
                          Uploaded Files
                        </Typography>
                        <List>
                          {issue?.media &&
                            issue?.media?.map((file) => (
                              <ListItem
                                secondaryAction={
                                  <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    onClick={() => handleDelete(file)}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                }
                              >
                                <ListItemAvatar>
                                  <a href={file.image} target="_blank">
                                    <Avatar>
                                      <FolderIcon />
                                    </Avatar>
                                  </a>
                                </ListItemAvatar>
                                <ListItemText
                                  primary="View File"
                                />
                              </ListItem>
                            ))}
                        </List>
                      </Grid>
                    )}
                  </AccordionDetails>
                  {openeditIssueModal && (
                    <EditIssueModal
                    handleopenModal={handleopenModal} 
                    handleCloseModal={handleCloseModal}
                    initialValues={{
                      title:issue?.title,
                      status:issue?.status }}
                      lead_id={lead_id}
                    />
                  )}
                </Accordion> */}
                    <Grid item sm={12} xs={12} style={{ padding: "10px 80px" }}  >

                      <Card
                        key={index}
                        style={{
                          background: "rgb(249, 245, 255)",
                          border: "1px solid rgb(214, 187, 251)",
                          borderRadius: "8px",
                          padding: "1rem",
                          margin: "0.5rem 0px",
                          position: "relative",


                        }}
                      >


                        <CardContent
                          style={{ marginTop: "40px" }}

                        >

                          <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div>
                              <Typography>
                                {" "}
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    color: "#7e7ec0",
                                  }}
                                >
                                  {" "}
                                  issue title
                                  :{" "}
                                </span>{" "}
                                {issue?.title}
                              </Typography>

                              <Typography>
                                {" "}
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    color: "#7e7ec0",
                                  }}
                                >
                                  {" "}
                                  issue status
                                  :{" "}
                                </span>{" "}
                                {issue?.status
                                }
                              </Typography>

                              <Typography>
                                {" "}
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    color: "#7e7ec0",
                                  }}
                                >
                                  {" "}
                                  comment
                                  :{" "}
                                </span>{" "}
                                {issue?.note
                                }
                              </Typography>
                            </div>

                            <div>
                              <IconButton color="primary" aria-label="upload picture" component="label" onClick={() => handleopenModal(issue)}>
                                <EditIcon />
                              </IconButton>
                            </div>

                          </div>


                          {issue?.media?.length == 0 ? (
                            // <div>
                            //   <UploadFilesForCard
                            //     handleShowUploadFiles={handleShowUploadFiles}
                            //     issue={issue}
                            //     selectedIssue={selectedIssue}
                            //     lead_id={lead_id}
                            //     type={type}
                            //     setShowUpload={setShowUpload}
                            //     showUpload={showUpload}
                            //     setUploadedFiles={setUploadedFiles}
                            //     uploadedFiles={uploadedFiles}
                            //     UpdatedUploadFiles={UpdatedUploadFiles}
                            //     setUpdatedUploadedFiles={setUpdatedUploadedFiles}
                            //     MAX_COUNT={MAX_COUNT}
                            //   />
                            //   {UpdatedUploadFiles?.map((file, index) => (
                            //     <>
                            //       <ListItem
                            //       key={index}
                            //         secondaryAction={
                            //           <IconButton
                            //             edge="end"
                            //             aria-label="delete"
                            //             onClick={() => handleRemoveItem(file)}
                            //           >
                            //             <DeleteIcon />
                            //           </IconButton>
                            //         }
                            //       >
                            //         <ListItemAvatar style={{ display: "flex" }}>
                            //           <FolderIcon />
                            //           <Typography>{file.name}</Typography>{" "}
                            //         </ListItemAvatar>
                            //         <ListItemText
                            //         />
                            //       </ListItem>
                            //     </>
                            //   ))}
                            //   <Grid className="display" mt={4}>
                            //     <PrimaryButton
                            //       fullWidth
                            //       style={{
                            //         padding: "0",
                            //         width: "85%",
                            //         margin: "15px auto",
                            //         height: "42px",
                            //       }}
                            //       className="saveButton"
                            //       onClick={handleUploadIssues}
                            //     >
                            //       Save
                            //     </PrimaryButton>
                            //   </Grid>
                            // </div>
                            <>
                              <IconButton
                                ref={Submit_ref}
                                type="submit"
                                onClick={() => handleShowUploadFiles(issue)}

                              >
                                <Typography>
                                  <span style={{ color: "black", fontWeight: "bold" }}>
                                    Upload Files
                                    {!showUpload && <CloudUploadIcon style={{ marginLeft: "30px" }} />}
                                  </span>{" "}
                                </Typography>
                              </IconButton>
                              {selectedIssue?.id == issue.id && (
                                <>
                                  {/* <p>statrt upload </p> */}

                                  {showUpload && (
                                    <IconButton onClick={handlecloseUpload}>
                                      <CloseIcon />
                                    </IconButton>
                                  )}

                                  {showUpload && (
                                    <Grid xs={12}>
                                      <Grid
                                        className="display"
                                        mt={4}
                                      // style={{ display: "flex", justifyContent: "center" }}
                                      >
                                        <FormControl variant="outlined">
                                          <label htmlFor="contained-button-file">
                                            <Input
                                              accept="image/*,video/*"
                                              id="contained-button-file"
                                              multiple
                                              type="file"
                                              // value={utiltyBill.media}
                                              onChange={handleFileEvent}
                                            />
                                            <Button component="span" className="btn_upload">
                                              <span className="icon">
                                                <Add />
                                              </span>
                                              <span className="title"> Upload Files</span>
                                            </Button>
                                          </label>
                                          {/* </Grid> */}
                                        </FormControl>
                                        <List>
                                          {UpdatedUploadFiles.map((file, index) => (
                                            <ListItem
                                              key={index}
                                              secondaryAction={
                                                <IconButton
                                                  edge="end"
                                                  aria-label="delete"
                                                  onClick={() => handleRemoveItem(file)}
                                                >
                                                  <DeleteIcon />
                                                </IconButton>
                                              }
                                            >
                                              <ListItemAvatar style={{ display: "flex" }}>
                                                <FolderIcon />
                                                <Typography>{file.name}</Typography>{" "}
                                              </ListItemAvatar>
                                              <ListItemText
                                              // primary={file.image}
                                              // secondary={"Secondary text"}
                                              />
                                            </ListItem>
                                          ))}
                                        </List>

                                      </Grid>
                                      <Grid className="display" mt={4}>
                                        <PrimaryButton
                                          fullWidth
                                          style={{
                                            padding: "0",
                                            width: "85%",
                                            margin: "15px auto",
                                            height: "42px",
                                          }}
                                          className="saveButton"
                                          onClick={handleUploadIssues}
                                        >
                                          Save
                                        </PrimaryButton>
                                      </Grid>
                                    </Grid>
                                  )}
                                  {/* <p>end upload</p> */}
                                </>)}


                            </>
                          ) : (
                            <Grid>
                              <Typography variant="h6" align="center">
                                Uploaded Files
                              </Typography>
                              <List>
                                {issue?.media &&
                                  issue?.media?.map((file, index) => (
                                    <ListItem
                                      key={index}
                                      secondaryAction={
                                        <IconButton
                                          edge="end"
                                          aria-label="delete"
                                          onClick={() => handleDelete(file)}
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      }
                                    >
                                      <ListItemAvatar>
                                        <a href={file.image} target="_blank">
                                          <Avatar>
                                            <FolderIcon />
                                          </Avatar>
                                        </a>
                                      </ListItemAvatar>
                                      <ListItemText
                                        primary="View File"
                                      />
                                    </ListItem>
                                  ))}
                              </List>
                            </Grid>
                          )}
                        </CardContent>
                      </Card>
                      {openeditIssueModal && (
                        <EditIssueModal
                          handleopenModal={handleopenModal}
                          handleCloseModal={handleCloseModal}
                          selectedIssue={selectedIssue}
                          setOpenEditIssueModal={setOpenEditIssueModal}
                          type={type}
                          initialValues={{
                            title: selectedIssue?.title,
                            status: selectedIssue?.status
                          }}
                          lead_id={lead_id}
                        />
                      )}
                    </Grid>

                  </>
                ))
              ) : (
                <Grid >
                  <Typography variant="h6" align="center" mt={5}>
                    no issues found for this card
                  </Typography>
                </Grid>

              )}
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid style={{ padding: "20px" }}>
            <Typography align="center" variant="h6" m={4}>
              There are no Issues Created
            </Typography>
          </Grid>
        </>
      )}
    </div>
  );
};

const Input = styled.input`
  display: none;
`;
export default CardIssues;
