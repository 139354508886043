import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import designersService from "./DesignersService";

const initialState = {
  designers: {},
  isError: false,
  isSuccess: false,
  isLodaing: false,
  massage: "",
};

export const get_designers = createAsyncThunk(
  "designers/getAll",
  async (thunkAPI) => {
    try {
      return await designersService.Get_designers_Action();
    } catch (error) {
      const massage = error.message;
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const designers_Slice = createSlice({
  name: "designers",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLodaing = false;
      state.isDeleteLodaing = false;
      state.massage = "";
    },
  },
  extraReducers: {
    [get_designers.pending]: (state) => {
      state.isLodaing = true;
    },
    [get_designers.fulfilled]: (state, action) => {
      state.isLodaing = false;
      state.designers = action.payload;
      state.isSuccess = true;
    },
    [get_designers.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
  },
});

export const { reset } = designers_Slice.actions;
export default designers_Slice.reducer;
