import React from "react";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import styled from "styled-components";

const SolarSolution = () => {
  const single_ticket = useSelector((state) => state.single_ticket);
  console.log(
    single_ticket?.singleTicket?.data?.opportunity,
    "single ticket in solution"
  );
  const opportunityData = single_ticket?.singleTicket?.data?.opportunity;
  const NumberOfPanelsNeeded = Math.ceil(
    opportunityData?.annual_kwh_usage /
      365 /
      4.2 /
      (opportunityData?.panel_wattage / 1000)
  );
  return (
    <>
      <div style={{ marginLeft: "20px", padding: "5px" }}>
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="baseline"
            columns={18}
            spacing={4}
            style={{ textAlign: "left" }}
          >
            {opportunityData?.pdf_path && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Pdf path:{" "}
                  <PdfButton
                    target="_blank"
                    onClick={() => {
                      window.open(`${opportunityData?.pdf_path}`);
                    }}
                  >
                    <Value> open Pdf</Value>
                  </PdfButton>{" "}
                </p>
              </Grid>
            )}

            {/* {opportunityData?.aerial_photo === "" ? (
              <div></div>
            ) : (
              <Grid item lg={18} md={18} sm={18} xs={18}>
                <img
                  style={{
                    width: "100%",
                    borderRadius: "2rem",
                    display: "block",
                    margin: "auto",
                  }}
                  src={opportunityData?.aerial_photo}
                />
              </Grid>
            )} */}
          </Grid>
        </Grid>
      </div>
    </>
  );
};
const Value = styled.span`
  color: rgb(136, 136, 136);
`;
const PdfButton = styled.button`
  background-color: white;
  color: black;
  padding: 5px 8px;
  border-radius: 25px;
  cursor: pointer;
`;
export default SolarSolution;
