import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useSelector, useDispatch } from "react-redux";
import LottieAnimation from "../../Components/LottieAnimation/LottieAnimation";
import loading from "../../Assets/lottie/loading.json";
import { get_designers } from "../../Redux/Slices/Designers/DesignsersSlice";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#003656",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function Designers() {
  const dispatch = useDispatch();
  const DesignersList = useSelector((state) => state.designers);
  const Designers = DesignersList?.designers?.data;

  useEffect(() => {
    dispatch(get_designers());
  }, []);

  return (
    <>
      {DesignersList?.isLodaing ? (
        <LottieAnimation lotti={loading} width={180} height={180} />
      ) : (
        <TableContainer component={Paper} style={{ marginTop: "50px" }}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell align="center">phone</StyledTableCell>
                <StyledTableCell align="center">Email</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Designers?.length > 0 ? (
                Designers?.map((user) => (
                  <StyledTableRow key={user.name}>
                    <StyledTableCell component="th" scope="row">
                      <>
                        <FiberManualRecordIcon
                          style={{
                            fontSize: "10px",
                            color: "#0DBF7C",
                            marginRight: "5px",
                          }}
                        />
                        {user.name}
                      </>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {user.phone}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {user.email}
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                <StyledTableRow
                  component="th"
                  scope="row"
                  align="center"
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    margin: "15px",
                    backgroundColor: "transparent",
                  }}
                >
                  {/* <StyledTableCell component="th" scope="row" align="center"> */}
                  There are no Designers
                  {/* </StyledTableCell> */}
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}

export default Designers;
