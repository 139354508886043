import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import UploadFilesService from "./UploadFilesService";
import { toast } from "react-toastify";
const initialState = {
  UploadFiles: {},
  isError: false,
  isSuccess: false,
  isLodaing: false,
  massage: "",
};

export const postUploadFiles = createAsyncThunk(
  "uploadFiles/post",
  async (data, thunkAPI , ) => {
console.log(data.get('type') , "daat1223")
    try {
      return await UploadFilesService.uploadFilesAction(data);
    } catch (error) {
      const massage =
        (error.response &&   
          error.response.data &&
          console.log(error?.response , "error?.response"),
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      error?.response?.data?.errors?.map((err) =>
        toast.error(err.msg, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      );
      return thunkAPI.rejectWithValue(massage);
    }
  }
);




export const UploadFilesSlice = createSlice({
  name: "UploadFiles",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLodaing = false;
      state.massage = "";
    },
  },
  extraReducers: {
    // post UploadFiles  Action
    [postUploadFiles.pending]: (state) => {
      state.isLodaing = true;
    },
    [postUploadFiles.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.UploadFiles = payload;
    },
    [postUploadFiles.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.UploadFiles = null;
      state.massage = action.payload;
    },
 


  },
});

export const { reset } = UploadFilesSlice.actions;
export default UploadFilesSlice.reducer;
