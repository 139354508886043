import React, { useEffect, useState } from "react";
import { Grid, Typography, MenuItem } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { reduxForm, Field } from "redux-form";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import MuiTextField from "../Common/muiTextField/MuiTextField";
import { useSelector, useDispatch } from "react-redux";
import {
  CreateCardAcion,
  resetCteateCard,
} from "../Redux/Slices/CreateCard/CreateCardSlice";
import MuiSelectField from "../Common/MuiSelectField/MuiSelectField";
import { getCardsOrder } from "../Redux/Slices/CardsListSlice/CardsListSlice";
// import { ReactFormBuilder } from 'react-form-builder2';
// import { ReactFormGenerator } from 'react-form-builder2';
import { FormBuilder } from "cb-react-forms";
// import { FormBuilder as FormBuilderIo, Formio, FormEdit } from "react-formio";
import "formiojs/dist/formio.full.css";

import { ReactFormBuilder, ElementStore } from "react-form-builder2";
import { ReactFormGenerator } from "react-form-builder2";
import "react-form-builder2/dist/app.css";

const formIoData = {
  display: "form",
  components: [
    {
      label: "Checkbox",
      tableView: false,
      key: "checkbox",
      type: "checkbox",
      input: true,
    },

    {
      label: "Text Field",
      tableView: true,
      validate: {
        // pattern: "/^([A-Z][a-z .'-]*)*$/",
        customMessage: "Test error",
        // "custom": "valid = (input !== 'Joe')",
        minLength: 3,
        maxLength: 10,
      },
      errorLabel: "Please fill in only letters.",
      key: "textField",
      type: "textfield",
      input: true,
    },
    {
      type: "button",
      label: "Submit",
      key: "submit",
      disableOnInvalid: true,
      input: true,
      tableView: false,
    },
  ],
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement,
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddCardWithForm = ({
  handleClose,
  open,
  handleSubmit,
  lead_id,
  setOpen,
  cardGroup,
}) => {
  const dispatch = useDispatch();
  const formNotify = useSelector((state) => state.form);
  const createCard = useSelector((state) => state.create_card);
  const [formFields, setFormFields] = useState([]);
  console.log(createCard?.isSuccess, "createCard");
  console.log(formNotify?.CreateCardForm?.values?.title, "formNotify");
  console.log(formFields, "formFields");
  const [formData, setFormData] = useState(formIoData);
  // const printResult = () => {
  //     // if (selectedSection === "formIo") {

  //     Formio.createForm(document.getElementById("formio-result"), {
  //       components: formData.components
  //     }).then((form) => {
  //       console.log(form.component.components);
  //       form.on("submit", (data) => console.log("submit", data));
  //       // console.log(document.getElementById("formio-result"));
  //     });
  //     // }
  //   };
  var items = [
    {
      key: "Dropdown",
      name: "Dropdown",
      icon: "fa fa-caret-square-o-down",
    },

    // {
    //     key: "Checkboxes",
    //     name: "Checkboxes",
    //     icon: "fa fa-check-square-o",
    // },
    //   {
    //     key: "RadioButtons",
    //     name: "Multiple Choice",
    //     icon: "fa fa-dot-circle-o",
    //   },
    {
      key: "TextInput",
      name: "Text Input",
      icon: "fa fa-font",
    },
    {
      key: "NumberInput",
      name: "Number Input",
      icon: "fa fa-plus",
    },
    {
      key: "TextArea",
      name: "Multi-line Input",
      icon: "fa fa-text-height",
    },
    {
      key: "DatePicker",
      name: "Date",
      icon: "fa fa-calendar",
    },
    {
      key: "FileUpload",
      name: "FileUpload",
      icon: "fa fa-calendar",
    },
  ];

  const handleBuildForm = async () => {
    console.log("clickedddd", formFields);
    const formData = new FormData();
    formData.append("ticket_id", lead_id);
    formData.append("title", formNotify?.CreateCardForm?.values?.title);
    formData.append(
      "is_default",
      formNotify?.CreateCardForm?.values?.is_default
    );
    formData.append("card_group", cardGroup);
    formFields?.map((e, i) => {
      for (const property in e) {
      }
      e.element !== "FileUpload" &&
        formData.append(
          `forms[${i}][type]`,
          e.element == "DatePicker" ? "date" : e.element
        );
      e.element == "FileUpload" && formData.append(`forms[${i}][type]`, "file");
      //  formData.append(`forms[${i}][type]`, e.element == "NumberInput" ? "number" : e.element);

      formData.append(`forms[${i}][key]`, e.id);
      // formData.append(`forms[${i}][label]`, e.label.blocks.map((block) => block.text));
      formData.append(`forms[${i}][label]`, e.label);
      e.element == "Dropdown" &&
        e.options.map((e, j) => {
          for (const property in e) {
            formData.append(`forms[${i}][values][${j}][key]`, e.value);
          }
        });
      e.element == "Checkboxes" &&
        e.options.map((e, j) => {
          for (const property in e) {
            formData.append(`forms[${i}][values][${j}][key]`, e.value);
          }
        });
      // formData.append(`forms[${i}][value][${i}][key]`, e.options.map((option)=>option.value ));
    });

    await dispatch(CreateCardAcion(formData));
    // await setOpen(false);
    await dispatch(getCardsOrder());
    // Submit Function
  };

  // const onSubmitFunc = async (data) => {
  //     console.log(JSON.parse(data), "form datata");
  //     const dataaa = JSON.parse(data)
  //     const formData = new FormData();
  //     formData.append("ticket_id", lead_id);
  //     formData.append("title", formNotify?.CreateCardForm?.values?.title);
  //     formData.append("is_default", formNotify?.CreateCardForm?.values?.is_default);
  //     formData.append("card_group", cardGroup)
  //     const reqData = {
  //         ticket_id: lead_id,
  //         title: formNotify?.CreateCardForm?.values?.title,
  //         forms: JSON.parse(data).map((e, i) => {
  //             for (const property in e) { }
  //             formData.append(`forms[${i}][type]`, e.element == "Date" ? "date" : e.element);
  //          //  formData.append(`forms[${i}][type]`, e.element == "NumberInput" ? "number" : e.element);

  //             formData.append(`forms[${i}][key]`, e.id);
  //             formData.append(`forms[${i}][label]`, e.label.blocks.map((block) => block.text));
  //             e.element == "Dropdown" && e.options.map((e, j) => {
  //                 for (const property in e) {
  //                     formData.append(`forms[${i}][values][${j}][key]`, e.value);
  //                 }
  //             });
  //             e.element == "Checkboxes" && e.options.map((e, j) => {
  //                 for (const property in e) {
  //                     formData.append(`forms[${i}][values][${j}][key]`, e.value);
  //                 }
  //             });
  //             // formData.append(`forms[${i}][value][${i}][key]`, e.options.map((option)=>option.value ));
  //         })
  //     }

  //     await dispatch(CreateCardAcion(formData));
  //     // await setOpen(false);
  //     await dispatch(getCardsOrder())
  //     // Submit Function
  // };

  useEffect(() => {
    let apis = async () => {
      if (
        createCard?.isSuccess
        // && formNotify?.CreateCardForm?.values?.title !== undefined
        // && formNotify?.CreateCardForm?.values?.is_default !== undefined
      ) {
        await setOpen(false);
        await dispatch(resetCteateCard());
        await setOpen(false);
      }
    };
    apis();
  }, [createCard?.isSuccess]);

  const submit = async (values) => {
    console.log(values, "values");
  };
  useEffect(() => {
    ElementStore.subscribe((state) => onChange(state.data));
  }, []);
  const onChange = (data) => {
    console.log(data, "tessst");
    setFormFields([
      ...data,
      ...formFields, // Put old items at the end
    ]);
    console.log(formFields, "formFields454545");
  };

  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar
          sx={{ position: "relative" }}
          style={{ backgroundColor: "white", color: "black" }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Add new card to {cardGroup} Group
            </Typography>
          </Toolbar>
        </AppBar>

        {/* <ReactFormBuilder 
        url='path/to/GET/initial.json'
        saveUrl='path/to/POST/built/form.json'
        toolbarItems={items}
        onSubmit={onSubmitFunc}
        
        /> */}
        {/* <ReactFormBuilder
       
        onSubmit={onSubmitFunc}
    
      /> */}
        <div>
          <form onSubmit={handleSubmit((values) => submit(values))}>
            <Grid container columns={12} spacing={5} justifyContent="end">
              <Grid item xs={3} sm={3} lg={3}></Grid>
              <Grid item xs={3} sm={3} lg={3}>
                <Button
                variant="contained"
                  onClick={handleBuildForm}
                  style={{
                    position: "absolute",
                    top: "86%",
                    zIndex: "1",
                    left: "80%",
                  }}
                >
                  create card with fields
                </Button>
              </Grid>

              <Grid
                item
                xs={3}
                md={{ ml: 5 }}
                sm={3}
                lg={3}
                style={{ marginRight: "20px" }}
              >
                <Field
                  required
                  type="text"
                  name="title"
                  component={MuiTextField}
                  label="Card name"
                />
              </Grid>
            </Grid>
            <Grid
              container
              columns={12}
              spacing={5}
              justifyContent="end"
              mb={4}
            >
              <Grid item xs={3} sm={3} lg={3}></Grid>
              <Grid item xs={3} sm={3} lg={3}></Grid>
              <Grid
                item
                xs={3}
                sm={3}
                md={{ ml: 5 }}
                lg={3}
                style={{ marginRight: "20px" }}
              >
                <Field
                  required
                  name="is_default"
                  component={MuiSelectField}
                  label="Is default?"
                >
                  <MenuItem value="1">YES</MenuItem>
                  <MenuItem value="0">NO</MenuItem>
                </Field>
              </Grid>
            </Grid>
          </form>
          {/* <button className="green" onClick={printResult}>
          display result
        </button>
        <FormBuilderIo
          form={formIoData}
           onChange={schema => setFormData(schema)}
          onSubmit={(data) => {
            console.log(data , "jhkljdfhkdjlsjfk");
          }}
          saveForm={(data) => setFormData(data)}
          saveText="Save Form"
          onSubmitDone={(data) => console.log(data)}
        />
        <div style={{ display: "none" }}>
          <div id="formio-result" />
        </div> */}
        </div>

        <div className="form_builder">
          {/* <FormBuilder
                        url='path/to/GET/initial.json'
                         saveUrl='path/to/POST/built/form.json'
                        items={items}
                        onSubmit={onSubmitFunc}
                    /> */}

          <ReactFormBuilder
            //url='path/to/GET/initial.json'
            toolbarItems={items}
            //saveUrl='path/to/POST/built/form.json'
            //   submitButton={<button type="submit" >Submit</button>}
            //   onSubmit={onSubmitFunc}
          />
        </div>
      </Dialog>
    </>
  );
};

export default reduxForm({
  form: "CreateCardForm",
})(AddCardWithForm);
