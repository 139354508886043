import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import CreateCardService from "./CreateCardService";
import { toast } from "react-toastify";
const initialState = {
    create_card: null,
  isError: false,
  isSuccess: false,
  isLodaing: false,
  massage: "",
};

export const CreateCardAcion = createAsyncThunk(
  "Create_card/post",
  async (data, thunkAPI) => {
    try {
      return await CreateCardService.CreateCardAction(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      error?.response?.data?.errors?.map((err) =>
        toast.error(err.msg, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      );
      return thunkAPI.rejectWithValue(massage);
    }
  }
);


export const UpdateCardAcion = createAsyncThunk(
    "edit_card/post",
    async (data, thunkAPI) => {
      try {
        return await CreateCardService.UpdateCardAction(data);
      } catch (error) {
        const massage =
          (error.response &&
            error.response.data &&
            error.response.data.massage) ||
          error.response.data.error ||
          error.message ||
          error.toString();
        error?.response?.data?.errors?.map((err) =>
          toast.error(err.msg, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        );
        return thunkAPI.rejectWithValue(massage);
      }
    }
  );

export const getSingleCard = createAsyncThunk(
    "getSingleCard/get",
    async (id, thunkAPI) => {
      try {
        return await CreateCardService.getSingleCardAction(id);
      } catch (error) {
        const massage =
          (error.response &&
            error.response.data &&
            error.response.data.massage) ||
          error.response.data.error ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(massage);
      }
    }
  );

  export const DeleteCardAcion = createAsyncThunk(
    "Create_card/post",
    async (data, thunkAPI) => {
      try {
        return await CreateCardService.DeleteCardAction(data);
      } catch (error) {
        const massage =
          (error.response &&
            error.response.data &&
            error.response.data.massage) ||
          error.response.data.error ||
          error.message ||
          error.toString();
        error?.response?.data?.errors?.map((err) =>
          toast.error(err.msg, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        );
        return thunkAPI.rejectWithValue(massage);
      }
    }
  );




export const CreateCardSlice = createSlice({
  name: "create_card",
  initialState,
  reducers: {
    resetCteateCard: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLodaing = false;
      state.massage = "";
    },
  },
  extraReducers: {
    //post single card Action
    [CreateCardAcion.pending]: (state) => {
      state.isLodaing = true;
    },
    [CreateCardAcion.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.create_card = payload;
    },
    [CreateCardAcion.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.create_card = null;
      state.massage = action.payload;
    },

     //get sigle card data Action
     [getSingleCard.pending]: (state) => {
        state.isLodaing = true;
      },
      [getSingleCard.fulfilled]: (state, { payload }) => {
        state.isLodaing = false;
        state.isSuccess = true;
        state.create_card = payload;
      },
      [getSingleCard.rejected]: (state, action) => {
        state.isLodaing = false;
        state.isError = true;
        state.create_card = null;
        state.massage = action.payload;
      },

       //update single card  Action
      [UpdateCardAcion.pending]: (state) => {
        state.isLodaing = true;
      },
      [UpdateCardAcion.fulfilled]: (state, { payload }) => {
        state.isLodaing = false;
        state.isSuccess = true;
        state.create_card = payload;
      },
      [UpdateCardAcion.rejected]: (state, action) => {
        state.isLodaing = false;
        state.isError = true;
        state.create_card = null;
        state.massage = action.payload;
      },
   
        //delete single card  Action
        [DeleteCardAcion.pending]: (state) => {
            state.isLodaing = true;
          },
          [DeleteCardAcion.fulfilled]: (state, { payload }) => {
            state.isLodaing = false;
            state.isSuccess = true;
            state.create_card = payload;
          },
          [DeleteCardAcion.rejected]: (state, action) => {
            state.isLodaing = false;
            state.isError = true;
            state.create_card = null;
            state.massage = action.payload;
          },

 
  },
});

export const { resetCteateCard } = CreateCardSlice.actions;
export default CreateCardSlice.reducer;
