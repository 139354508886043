import axios from "axios";
import { Assign_PM_User } from "../../../Api/Api";
import { toast } from "react-toastify";
const PM_Assign_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${Assign_PM_User}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Ticket Assigned Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const AssignService = {
  PM_Assign_Action,
};
export default AssignService;
