import axios from "axios";
import { Get_Tickets_Counts } from "../../../Api/Api";

const Get_Tickets_Counts_Action = async (pm) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${Get_Tickets_Counts}`,
    headers: headers,
  });
  return response.data;
};

const Tickets_Counts_Service = {
  Get_Tickets_Counts_Action,
};
export default Tickets_Counts_Service;
