import axios from "axios";
import { work_orders_to_installer } from "../../../Api/Api";
import { toast } from "react-toastify";
const send_work_order_to_installer_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${work_orders_to_installer}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("work order sent Successfully ", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const work_order_to_installer_Service = {
  send_work_order_to_installer_Action,
};
export default work_order_to_installer_Service;
