import axios from "axios";
import {
  StoreBusinessLicense,
  get_business_license_Data,
  update_business_license_Data,
} from "../../../Api/Api";
import { toast } from "react-toastify";
const store_business_license_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${StoreBusinessLicense}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Status added Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const get_business_license_data = async (id) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${get_business_license_Data}/${id}`,
    headers: headers,
    // data: data,
  });
  return response.data;
};

const edit__business_license_data = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "Post",
    baseURL: `${update_business_license_Data}/${data.id}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Updated Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const business_licenseService = {
  store_business_license_Action,
  get_business_license_data,
  edit__business_license_data,
};
export default business_licenseService;
