import axios from "axios";
import {
  store_confirm_with_sales_and_customers,
  update_confirm_with_sales_and_customers,
} from "../../../Api/Api";
import { toast } from "react-toastify";
const store_confirm_with_salesAndCustomer = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${store_confirm_with_sales_and_customers}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success(" created Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const get_confirm_with_salesAndCustomer_data = async (ticket_id) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${store_confirm_with_sales_and_customers}/${ticket_id}`,
    headers: headers,
    // data: data,
  });
  return response.data;
};

const edit_confirm_with_salesAndCustomer = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${update_confirm_with_sales_and_customers}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("modified Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const confirm_with_salesAndCustomer_Service = {
  store_confirm_with_salesAndCustomer,
  get_confirm_with_salesAndCustomer_data,
  edit_confirm_with_salesAndCustomer,
};
export default confirm_with_salesAndCustomer_Service;
