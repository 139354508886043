import React, { useEffect, useState } from "react";
import {
  Grid,
  ListItemText,
  List,
  ListItem,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import FolderIcon from "@mui/icons-material/Folder";
import { useDispatch, useSelector } from "react-redux";
import { get_AddersCostSpecificCards } from "../../Redux/Slices/AddersCostSpecificCards/AddersCostSpecificCardsSlice";
import { get_AddersCostCard } from "../../Redux/Slices/AddersCardSlice/AddersCardSlice";
import { updateAddersCostSpecificCard } from "../../Redux/Slices/AddersCardSlice/AddersCardSlice";
import AddersDetailsGroup from "./AddersDetailsGroup";
import EditCardAdders from "./EditCardAdders";
const CardAddersDetails = ({ lead_id, type }) => {
  const dispatch = useDispatch();
  const AddersDetails = useSelector((state) => state.AddersCostSpecificCards);
  const addersCardDetails = useSelector((state) => state.AddersCostCard);
  const [openEditForm, setOpenEditForm] = useState(false);
  const [openCardData, setOpenCardData] = useState(true);
  const [AddersData, setAddersData] = useState({
    name: "",
    other: "",
    cost: "",
  });

  useEffect(() => {
    setAddersData({
      name: AddersDetails?.AddersCostSpecificCards?.data?.name,
      other: AddersDetails?.AddersCostSpecificCards?.data?.other,
      cost: AddersDetails?.AddersCostSpecificCards?.data?.cost,
    });
  }, [type, openEditForm]);

  const closeEditForm = () => {
    setOpenCardData(true);
    setOpenEditForm(false);
  };

  const handleShowEditForm = () => {
    setOpenEditForm(true);
  };

  const handleAddersDataChange = (key, value) =>
    setAddersData({ ...AddersData, [key]: value });

  const handle_edit = async () => {
    const reqData = {
      id: AddersDetails?.AddersCostSpecificCards?.data?.id,
      name: AddersData.name,
      cost: AddersData.cost,
      other: AddersData.other,
      _method: "PUT",
    };
    const reqUpdatedData = {
      id: lead_id,
      type: type,
    };

    await dispatch(updateAddersCostSpecificCard(reqData));
    await setOpenEditForm(false);
    await dispatch(get_AddersCostSpecificCards(reqUpdatedData));
  };

  console.log(AddersDetails?.AddersCostSpecificCards, "AdderrrrrsDetails");
  console.log(addersCardDetails, "addersCardDetailsssklks");
  useEffect(() => {
    const reqData = {
      id: lead_id,
      type: type,
    };

    (type == "documents_to_installer" ||
      type == "request_to_designer" ||
      type == "apply_all_required_permits" ||
      type == "apply_pto" ||
      type == "order_placards" ||
      type == "business_license" ||
      type == "order_product" ||
      type == "adders") &&
      dispatch(get_AddersCostSpecificCards(reqData));
  }, [type]);
  useEffect(() => {
    const reqDataa = {
      id: lead_id,
    };
    type == "adders" && dispatch(get_AddersCostCard(reqDataa));
  }, [type == "adders", openEditForm]);
  return (
    <>
    {type == "adders" && (
 <div style={{ display: "flex", justifyContent: "space-between" }}>
 {
 // (type == "documents_to_installer" ||
 //   type == "request_to_designer" ||
 //   type == "apply_all_required_permits" ||
 //   type == "apply_pto" ||
 //   type == "order_placards" ||
 //   type == "business_license" ||
 //   type == "order_product") 

   
   AddersDetails?.isLodaing ? (
     <Typography align="center">Loading ...</Typography>
   ) : (
     AddersDetails?.AddersCostSpecificCards !== null && (
       <div style={{ padding: "10px 30px" }}>
         <Typography align="center">Adders Details</Typography>

         <div
           style={{ display: "flex", justifyContent: "space-between" }}
         >
           {openEditForm && (
             <>
               <EditCardAdders
                 AddersData={AddersData}
                 handleAddersDataChange={handleAddersDataChange}
                 handle_edit={handle_edit}
               />
             </>
           )}
         </div>

         {AddersDetails?.AddersCostSpecificCards?.data?.name &&
           openCardData &&
           !openEditForm &&
           type !== "welcome_call" && (
             //   type !== "business_license" &&
             <Grid className="display" mb={1}>
               <Typography>
                 {" "}
                 name:
                 <span style={{ color: "gray", fontWeight: "bold" }}>
                   {AddersDetails?.AddersCostSpecificCards?.data?.name}
                 </span>{" "}
               </Typography>
             </Grid>
           )}

         {AddersDetails?.AddersCostSpecificCards?.data?.cost &&
           openCardData &&
           !openEditForm &&
           type !== "welcome_call" && (
             //   type !== "business_license" &&
             <Grid className="display" mb={1}>
               <Typography>
                 {" "}
                 Cost:
                 <span style={{ color: "gray", fontWeight: "bold" }}>
                   {AddersDetails?.AddersCostSpecificCards?.data?.cost}
                 </span>{" "}
               </Typography>
             </Grid>
           )}

         {type !== "adders" &&
         AddersDetails?.AddersCostSpecificCards?.data?.media &&
         AddersDetails?.AddersCostSpecificCards?.data?.media.length >
           0 ? (
           <Grid>
             <Typography variant="h6" align="center">
               Uploaded Files
             </Typography>
             <List>
               {AddersDetails?.AddersCostSpecificCards?.data?.media &&
                 AddersDetails?.AddersCostSpecificCards?.data?.media?.map(
                   (file) => (
                     <ListItem
                     // secondaryAction={
                     //   <IconButton
                     //     edge="end"
                     //     aria-label="delete"
                     //     onClick={() => handleDelete(file)}
                     //   >
                     //     <DeleteIcon />
                     //   </IconButton>
                     // }
                     >
                       <ListItemAvatar>
                         <a href={file.path} target="_blank">
                           <Avatar>
                             <FolderIcon />
                           </Avatar>
                         </a>
                       </ListItemAvatar>
                       <ListItemText
                         primary="View File"
                         // secondary={"Secondary text"}
                       />
                     </ListItem>
                   )
                 )}
             </List>
           </Grid>
         ) : (
           <>{type !== "adders" && <p>no files</p>}</>
         )}
       </div>
     )
   )}

 {!openEditForm
   ? AddersDetails?.AddersCostSpecificCards !== null && (
       <IconButton onClick={handleShowEditForm}>
         <EditIcon />
       </IconButton>
     )
   : AddersDetails?.AddersCostSpecificCards !== null && (
       <IconButton onClick={closeEditForm}>
         <CloseIcon />
       </IconButton>
     )}
</div>
    ) }
     

      <div>
        {type == "adders" && (
          <>
            {addersCardDetails?.isLodaing ? (
              <Typography align="center">Loading ...</Typography>
            ) : (
              <AddersDetailsGroup
                addersCardDetails={addersCardDetails}
                type={type}
                lead_id={lead_id}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default CardAddersDetails;
