import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Grid } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { PrimaryButton } from "../../../Layouts/ButtonsControlled";
import { ModalContent } from "../../../Components/Modals/PmAssignModal/PmAssignModalStyle";
import { TextField } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import FolderIcon from "@mui/icons-material/Folder";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloseIcon from "@mui/icons-material/Close";
import { Add } from "@mui/icons-material";
import { Typography, Button, IconButton } from "@mui/material";
import styled from "styled-components";
import { sendClientPlan } from "../../../Redux/Slices/SendClientPlan/SendClientPlanSlice";
import { getDesignReqDetails } from "../../../Redux/Slices/DesignRequest/DesignRequestSlice";
import { getSingleTicket } from "../../../Redux/Slices/SingleTicketSlice/SingleTicketSlice";
import { getTicketStatus } from "../../../Redux/Slices/TicketStatus/TicketStatusSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import MuiTextField from "../../../Common/muiTextField/MuiTextField"
const SendCustomerPlanModal = ({
  openSendCustomerPlanModal,
  handleCloseSendCustomerPlanModal,
  setOpenSendCustomerPlanModal,
  ticket_id,
  handleSubmit
}) => {
  const dispatch = useDispatch();
  const { lead_id } = useParams();

  const formData = new FormData();
  const [showUpload, setShowUpload] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [UpdatedUploadFiles, setUpdatedUploadedFiles] = useState([]);
  const [fileLimit, setFileLimit] = useState(false);
  const design_req_details = useSelector((state) => state.design_req);
  const single_ticket = useSelector((state) => state.single_ticket);
  const lead_id_of_ticket_data = single_ticket?.singleTicket?.data?.lead?.id;

  const design_plan_id = design_req_details?.design_req?.data?.id;
  console.log(design_req_details?.design_req , "jkjkj 5454NNNNN")
  console.log(design_plan_id , "design_plan_id")
  console.log(design_req_details?.design_req?.data?.id, "design_req_details");
  const MAX_COUNT = 5;
  const [customerPlanData, setCustometPlanData] = useState({
    email_body: "",
  });
  const [addSendCustomerPlan, setAddSendCustomerPlan] = React.useState(0);
  const handleAddSendCustomerPlan = () => {
    setAddSendCustomerPlan(1);
  };

  const [files, setFiles] = useState([]);
  useEffect(() => {
    dispatch(getDesignReqDetails(lead_id));
    dispatch(getSingleTicket(lead_id));
  }, []);
  const handleRemoveItem = (file) => {
    const name = UpdatedUploadFiles.map((file) => file.name);
    setUpdatedUploadedFiles(
      UpdatedUploadFiles.filter((item) => item.name !== file.name)
    );
    console.log(name, "name");
    console.log(UpdatedUploadFiles, "UpdatedUploadFilesssssssss");
  };
  const handleChange = (key, value) =>
    setCustometPlanData({ ...addSendCustomerPlan, [key]: value });
  const handleUploadFiles = (files) => {
    const uploaded = [...UpdatedUploadFiles];
    let limitExceeded = false;
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        setUpdatedUploadedFiles(uploaded);
        if (uploaded.length === MAX_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_COUNT) {
          alert(`You can only add a maximum of ${MAX_COUNT} files`);
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    });
    if (!limitExceeded) {
      setUploadedFiles(uploaded);
    }
  };
  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };
  const handleShowUploadFiles = () => {
    setShowUpload(true);
  };
  const handlecloseUpload = () => {
    setShowUpload(false);
  };
  // const handleSendClientPlan = async () => {
  //   uploadedFiles.map((e, i) => {
  //     formData.append(`pdfs[${i}]`, e);
  //   });
  //   formData.append("ticket_id", lead_id);
  //   formData.append("design_plan_id", design_plan_id);
  //   formData.append("email_body", customerPlanData.email_body);
  //   formData.append("lead_id", lead_id_of_ticket_data);
  //   await dispatch(sendClientPlan(formData));
  //   await setOpenSendCustomerPlanModal(false);
  //   await dispatch(getTicketStatus(lead_id));
  // };

  const submit = async (values) => {

    console.log(values, "values");
        uploadedFiles.map((e, i) => {
      formData.append(`pdfs[${i}]`, e);
    });
        formData.append("email_body", values.email_body);
        formData.append("ticket_id", lead_id);
    formData.append("design_plan_id", design_plan_id);
        formData.append("lead_id", lead_id_of_ticket_data);
    await dispatch(sendClientPlan(formData));
    await setOpenSendCustomerPlanModal(false);
    await dispatch(getTicketStatus(lead_id));
  }

  const  design_plan_id_exist = ()=>(
    <ModalContent>
        <form onSubmit={handleSubmit((values) => submit(values))}>
          <Grid className="display">
        
                  <Field
              required
              multiline
              rows={3}
              type="text"
              name="email_body"
              component={MuiTextField}
              label="email body"
            />
          </Grid>

          <Grid className="display">
            <>
              <div mt={4}>
                <IconButton onClick={() => handleShowUploadFiles()}>
                  <Typography>
                    <span style={{ color: "black", fontWeight: "bold" }}>
                      Upload Files
                    </span>{" "}
                  </Typography>{" "}
                  {!showUpload && (
                    <CloudUploadIcon style={{ marginLeft: "30px" }} />
                  )}
                </IconButton>

                {showUpload && (
                  <IconButton onClick={handlecloseUpload}>
                    <CloseIcon />
                  </IconButton>
                )}
              </div>

              {showUpload && (
                <Grid xs={12} mt={4}>
                  <Grid
                    className="display"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <FormControl variant="outlined">
                      <label htmlFor="contained-button-file">
                        <Input
                          //accept="image/*,video/*"
                          accept="application/pdf"
                          id="contained-button-file"
                          multiple
                          type="file"
                          // value={utiltyBill.media}
                          onChange={handleFileEvent}
                        />
                        <Button component="span" className="btn_upload">
                          <span className="icon">
                            <Add />
                          </span>
                          <span className="title"> Upload Files</span>
                        </Button>
                      </label>
                      {/* </Grid> */}
                    </FormControl>
                    {UpdatedUploadFiles.map((file, index) => (
                      <div>
                        <ListItem
                          secondaryAction={
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => handleRemoveItem(file)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          }
                        >
                          <ListItemAvatar style={{ display: "flex" }}>
                            <FolderIcon />
                            <Typography>{file.name}</Typography>{" "}
                          </ListItemAvatar>
                          <ListItemText
                          // primary={file.image}
                          // secondary={"Secondary text"}
                          />
                        </ListItem>
                      </div>
                    ))}
                  </Grid>
                </Grid>
              )}
            </>
          </Grid>
          <DialogActions>
        <PrimaryButton
          fullWidth
          disabled={uploadedFiles.length == 0}
          type="submit"
          style={{
            padding: "0",
            width: "85%",
            margin: "15px auto",
            height: "42px",
          }}
          className="saveButton"
         // onClick={handleSendClientPlan}
        >
          Send
        </PrimaryButton>
      </DialogActions>
          </form>
        </ModalContent>
  )

  const design_plan_id_not_exist = ()=>(
    <>
    <ModalContent>
    <Typography variant="h6" align="center"> you cant send customet plan  before send designe request </Typography>
    </ModalContent>
  
    </>
  )
  return (
    <Dialog
      // maxWidth={"xs"}

      fullWidth
      open={openSendCustomerPlanModal}
      onClose={handleCloseSendCustomerPlanModal}
    >
      <DialogContent>
     {design_req_details?.design_req == null ? design_plan_id_not_exist() :  design_plan_id_exist()}
      </DialogContent>
     
    </Dialog>
  );
};
const Input = styled.input`
  display: none;
`;


export default connect(null)(
  reduxForm({ form: "SendCustomerPlanForm", enableReinitialize: true })(
    SendCustomerPlanModal
  )
);
