import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import SendClientPlanService from "./SendClientPlanService";
import { toast } from "react-toastify";
const initialState = {
  sendClientPlan: false,
  isError: false,
  isSuccess: false,
  isLodaing: false,
  massage: "",
};

export const sendClientPlan = createAsyncThunk(
  "sendClientPlan/post",
  async (data, thunkAPI) => {
    try {
      return await SendClientPlanService.sendClientPlanAction(data);
    } catch (error) {
      const massage =
        (error.response &&
         
          error.response.data &&
          console.log(error.response?.data?.message , "error.response"),
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      error?.response?.data?.errors?.length > 0 ?
      error?.response?.data?.errors?.map((err) =>
        toast.error(err.msg, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      ):
      toast.error(error.response?.data?.message , {
        position: "top-right",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })

      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const sendClientPlanSlice = createSlice({
  name: "sendClientPlan",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLodaing = false;
      state.massage = "";
    },
  },
  extraReducers: {
    //sendClientPlan
    [sendClientPlan.pending]: (state) => {
      state.isLodaing = true;
    },
    [sendClientPlan.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.sendClientPlan = payload;
    },
    [sendClientPlan.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
  },
});

export const { reset } = sendClientPlanSlice.actions;
export default sendClientPlanSlice.reducer;
