import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { ImageController } from "../../Layouts/ImgController";
import Logo from "../../Assets/Images/whiteLogo.png";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import userimg from "../../Assets/Images/userimg.png";
import { UserInfo } from "./HeaderStyle";
import Logout from "@mui/icons-material/Logout";
import Tooltip from "@mui/material/Tooltip";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import { useDispatch } from "react-redux";
import { logout } from "../../Redux/Slices/AuthSlice/AuthSlice";
import { useSelector } from "react-redux";
import { Navigate, useNavigate, Link, useParams } from "react-router-dom";
import { ListItem } from "@mui/material";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
function Header() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const user = JSON.parse(localStorage.getItem("user"));
  const name = user?.name;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    await dispatch(logout());
  };

  //mobile
  const [mobilAnchorEl, setMobilAnchorEl] = React.useState(null);
  const openMobileAvatar = Boolean(mobilAnchorEl);
  const handleClickInMobile = (event) => {
    setMobilAnchorEl(event.currentTarget);
  };

  const handleCloseInMobile = () => {
    setMobilAnchorEl(null);
  };

  const handleMobileLogout = async () => {
    await dispatch(logout());
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" style={{ backgroundColor: "#1A1A19" }}>
        <Toolbar>
          <Link to={`/home/user-section/tickets/allTickets`}>
            <ImageController src={Logo} width="130px" />
          </Link>

          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "flex" } }}>
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
            >
              <NotificationsIcon style={{ color: " #929EAE" }} />
            </IconButton>
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <UserInfo>
                <Button
                  className="userdropdown"
                  id="demo-customized-button"
                  aria-controls={open ? "demo-customized-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  variant="contained"
                  disableElevation
                  onClick={handleClick}
                >
                  <div style={{ display: "flex" }}>
                    <ImageController
                      src={userimg}
                      width="30px"
                      h="30px"
                      br="50%"
                      objectFit="cover"
                    />
                    <p className="userName">{name}</p>
                  </div>

                  <KeyboardArrowDownIcon />
                </Button>
                <StyledMenu
                  id="demo-customized-menu"
                  MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem
                    onClick={handleClose}
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <Button onClick={handleLogout}>
                      Logout
                      <Logout />
                    </Button>
                  </MenuItem>

                  {/* <Divider sx={{ my: 0.5 }} /> */}
                </StyledMenu>
              </UserInfo>
            </Box>
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <Tooltip title="Account settings">
                <IconButton
                  onClick={handleClickInMobile}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={openMobileAvatar ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openMobileAvatar ? "true" : undefined}
                >
                  <ImageController
                    src={userimg}
                    width="30px"
                    h="30px"
                    br="50%"
                    objectFit="cover"
                  />
                </IconButton>
              </Tooltip>

              <Menu
                anchorEl={mobilAnchorEl}
                id="account-menu"
                open={openMobileAvatar}
                onClose={handleCloseInMobile}
                onClick={handleCloseInMobile}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <ListItem>
                  <p className="userName">{name}</p>
                </ListItem>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  <Button onClick={handleMobileLogout}>Logout</Button>
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default Header;
