import axios from "axios";
import {
  store_call_utility_companies,
  update_call_utility_companies,
} from "../../../Api/Api";
import { toast } from "react-toastify";
const store_call_utility__companies = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${store_call_utility_companies}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success(" created Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const get_call_utility_companies_data = async (ticket_id) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${store_call_utility_companies}/${ticket_id}`,
    headers: headers,
    // data: data,
  });
  return response.data;
};

const edit_call_utility_companies = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${update_call_utility_companies}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("modified Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const call_utility_companies_Service = {
  store_call_utility__companies,
  get_call_utility_companies_data,
  edit_call_utility_companies,
};
export default call_utility_companies_Service;
