import React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CheckIcon from "@mui/icons-material/Check";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#003656",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(name, Phone, Email, AvailableDays, Address, rate, action) {
  return { name, Phone, Email, AvailableDays, Address, rate, action };
}

const rows = [
  createData(
    "Distributor 1",
    "+0123456789",
    "nasrxl55@gmail.com",
    "mon,tus,Wed",
    "eltalbaya 14 str"
  ),
  createData(
    "Distributor 2",
    "+0123456789",
    "nasrxl55@gmail.com",
    "mon,tus,Wed",
    "eltalbaya 14 str"
  ),
  createData(
    "Distributor 3",
    "+0123456789",
    "nasrxl55@gmail.com",
    "mon,tus,Wed",
    "eltalbaya 14 str"
  ),
];

function Distributors() {
  return (
    <TableContainer component={Paper} style={{ marginTop: "50px" }}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Name</StyledTableCell>
            <StyledTableCell align="center">Phone</StyledTableCell>
            <StyledTableCell align="center">Email</StyledTableCell>
            <StyledTableCell align="center">Available Days</StyledTableCell>
            <StyledTableCell align="center">Address</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row">
                <>
                  <FiberManualRecordIcon
                    style={{
                      fontSize: "10px",
                      color: "#0DBF7C",
                      marginRight: "5px",
                    }}
                  />
                  {row.name}
                </>
              </StyledTableCell>
              <StyledTableCell align="center">{row.Phone}</StyledTableCell>
              <StyledTableCell align="center">{row.Email}</StyledTableCell>
              <StyledTableCell align="center" style={{ color: "#0DBF7C" }}>
                {row.AvailableDays}
              </StyledTableCell>
              <StyledTableCell align="center">{row.Address}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default Distributors;
