import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import { IconButton } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PersonIcon from "@mui/icons-material/Person";
import CallIcon from "@mui/icons-material/Call";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import {
  TicketContainer,
  LeftItem,
  UpBorder,
  DownBorder,
  RightItem,
} from "./TicketStyle";
import PmAssignModal from "../../Components/Modals/PmAssignModal/PmAssignModal";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LaunchIcon from "@mui/icons-material/Launch";
import { useSelector } from "react-redux";
import TicketConcertDataModal from "../../Components/Modals/TicketConcertDataModal/TicketConcertDataModal";
const Ticket = ({ openTicketModa, e }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isAutherized = useSelector((state) => state.auth);
  const LoadedAutherized = useSelector((state) => state.auth);
  const single_ticket = useSelector((state) => state.single_ticket);
  const [openTicketModal, setOpenTicketModal] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState({});
  const [open, setOpen] = useState();
  console.log(LoadedAutherized, "LoadedAutherized");
  console.log(LoadedAutherized?.data?.type, "m,mm");
  console.log(e, "tickeeet");
  const handleOpenTicketModal = (e) => {
    setSelectedTicket(e);
    setOpenTicketModal(true);
  };
  const handleCloseTicketModal = () => setOpenTicketModal(false);
  const handleCloseconcertDataModal = () => setOpen(false);
  return (
    <>
      <TicketContainer>
        {/* <Link to={`/home/SolarSolution/tabs/${e.id}`}> */}
        <LeftItem>
          <div>
            <div className="leftItemData">
              <CalendarMonthIcon style={{ color: "#358EE1" }} />
              <Typography
                style={{ color: "#358ee1", fontWeight: "600" }}
                className="ticketDetails"
              >
                {new Date(e?.created_at).toLocaleDateString("en-US", {
                  day: "2-digit",
                  month: "short",
                  year: "numeric",
                })}
              </Typography>
            </div>

            <div className="leftItemData">
              <PersonIcon />
              <Typography className="ticketDetails">
                {e?.client_name}
              </Typography>
            </div>
            <div className="leftItemData">
              <CallIcon />
              <Typography className="ticketDetails">
                {e?.client_phone}
              </Typography>
            </div>
            <div className="leftItemData">
              <LocationOnIcon />
              <Typography
                style={{
                  marginLeft: "0",
                  textAlign: "left",
                }}
                className="ticketDetails"
              >
                {e?.client_address}
              </Typography>
            </div>
            {/* <div className="leftItemData">
                <LocalAtmIcon />
                <Typography className="ticketDetails">Adm</Typography>
              </div> */}
          </div>

          <UpBorder></UpBorder>
          <DownBorder></DownBorder>
        </LeftItem>

        <RightItem>
          <div>
            <Typography className="roundedDetal">
              {e?.opportunity?.custom_service_type}
            </Typography>
            <Typography className="status">
              {e?.financial_status !== "" ? (
                <>{e?.financial_status}</>
              ) : e?.finance_company !== "Cash" ? (
                <div style={{ display: "flex" }}>
                  <p style={{ color: "black" }}>Financed with : </p>
                  <span>
                    {e?.finance_company == null ? "cash" : e?.finance_company}
                  </span>
                </div>
              ) : (
                <span>
                  {e?.finance_company == null ? "cash" : e?.finance_company}
                </span>
              )}{" "}
            </Typography>
            {/* <Typography className="roundedDetal">
                {" "}
                completed Project certificate Requestd{" "}
              </Typography> */}
            <Typography className="status">{e?.pm_status} </Typography>
            <Typography className="status" style={{ display: "flex" }}>
              <p style={{ color: "black" }}>Contract amount: </p>
              <span> ${e?.contract_amount}</span>
            </Typography>
            {/* {e?.project_custom_status && (
                <Typography className="status" style={{ display: "flex" }}>
                  <p style={{ color: "black" }}>Status:</p>
                  <span style={{ fontWeight: "bold", color: "black" }}>
                    {" "}
                    {e?.project_custom_status}
                  </span>
                </Typography>
              )} */}
            {e?.user !== null && (
              <Typography className="status" style={{ display: "flex" }}>
                <p style={{ color: "black" }}>Assigned to:</p>
                <span style={{ fontWeight: "bold" }}> {e?.user?.name}</span>
              </Typography>
            )}

            {/* <Typography
                style={{ marginBottom: "5px", color: "black" }}
                className="status"
              >
                Pto{" "}
              </Typography> */}
            {e?.finance_status?.type == "goodleap" && (
              <Typography className="status" style={{ display: "flex" }}>
                <p style={{ color: "black" }}>financial status:</p>
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  {e?.financial_status?.length == 0 ? "NA" : e?.finance_status}
                </span>
              </Typography>
            )}

            {e?.finance_status?.type == "concert" && (
              <button
                className="btn_success"
                onClick={() => {
                  setOpen(true);
                }}
              >
                concert
              </button>
            )}
          </div>
          <div className="tecketActions">
            {/* {location.pathname.includes("AssignedTickets") ? (
              <>
                <Link to="/home/CreateCharter">
                  {" "}
                  <button className="btn">Create Charter</button>
                </Link>
              </>
            ) : (
              <>
                <button
                  className="btn"
                  onClick={() => navigate(`/home/SolarSolution/tabs/${e.id}`)}
                >
                  View Details
                </button>
              </>
            )} */}
            <button
              className="btn"
              onClick={() => navigate(`/home/SolarSolution/tabs/${e.id}`)}
            >
              View Details
            </button>
          </div>
        </RightItem>

        {/* </Link> */}

        {/* {LoadedAutherized?.status == "loading" ? (
          <p>loading</p>
        ) : (
          <div className="buttons">
            {" "}
            {LoadedAutherized?.data?.type == "admin" && e?.user == null && (
              <IconButton onClick={() => handleOpenTicketModal(e)}>
                <LaunchIcon style={{ color: "#358EE1" }} />
              </IconButton>
            )}
          </div>
        )} */}

        {(LoadedAutherized?.isAuth == false ||
          LoadedAutherized?.isAuth == true) &&
          (LoadedAutherized?.data?.data?.type == "admin" ||
            LoadedAutherized?.data?.type) &&
          e?.user == null && (
            <div className="buttons">
              {" "}
              {/* {LoadedAutherized?.data?.type == "admin" && e?.user == null && ( */}
              <IconButton onClick={() => handleOpenTicketModal(e)}>
                <LaunchIcon style={{ color: "#358EE1" }} />
              </IconButton>
              {/* )} */}
            </div>
          )}
      </TicketContainer>
      {openTicketModal && (
        <PmAssignModal
          handleCloseTicketModal={handleCloseTicketModal}
          openTicketModal={openTicketModal}
          selectedTicket={selectedTicket}
          setOpenTicketModal={setOpenTicketModal}
        />
      )}
      {open && (
        <TicketConcertDataModal
          open={open}
          handleCloseconcertDataModal={handleCloseconcertDataModal}
          ticket={e}
        />
      )}
    </>
  );
};

export default Ticket;
