import React, { useEffect } from "react";

const ZillowTap = () => {
  return (
    <>
      <iframe
        src="https://www.zillow.com/"
        width="950"
        height="500"
        allowfullscreen
      ></iframe>
    </>
  );
};

export default ZillowTap;
