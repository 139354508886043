import styled from "styled-components";

// tickets info component
export const TicketInfo = styled.div`
  background-color: white;
  padding: 0px;
  margin-top: 13px;
  .flexDirection {
    flex-direction: row;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
    .link {
      text-decoration: none;
      color: black;
      &:hover {
        color: #358ee1;
      }
      &.active {
        color: #358ee1;
        background-color: #d2eaff;
      }
    }
  }
  .item-info {
    padding: 12px;
    display: flex;
    height: 50px;
    margin-right: 15px;
    background-color: transparent;

    @media screen and (max-width: 768px) {
      margin-right: 0px;
      justify-content: center;
    }

    .ticketsInfoNum {
      border-radius: 50%;
      width: 26px;
      height: 26px;
      text-align: center;
      margin-left: 12px;
      padding-top: 4px;
      color: white;
    }
  }
`;
