export const Login = "https://pmb.boxbyld.tech/api/login";
export const Logout = "https://pmb.boxbyld.tech/api/logout";
export const WelcomeCall = "https://pmb.boxbyld.tech/api/StoreWelcomeCall";
export const getWelcomeCall = "https://pmb.boxbyld.tech/api/GetWelcomeCall";
export const Get_Ticket = "https://pmb.boxbyld.tech/api/tickets";
export const Get_Single_Ticket = "https://pmb.boxbyld.tech/api/tickets";
export const Get_Tickets_Counts = "https://pmb.boxbyld.tech/api/tickets/counts";
export const Get_PM_Users = "https://pmb.boxbyld.tech/api/users";
export const Assign_PM_User = "https://pmb.boxbyld.tech/api/tickets/assign-pm";
export const send_welcom_package =
  "https://pmb.boxbyld.tech/api/send-welcome-mail";
export const ticket_status = "https://pmb.boxbyld.tech/api/tickets/pm-statuses";
export const Creat_charter = "https://pmb.boxbyld.tech/api/charter/create";
export const create_issue =
  "https://pmb.boxbyld.tech/api/tickets/pm-statuses/create-issue";
  export const edit_issue =
  "https://pmb.boxbyld.tech/api/tickets/pm-statuses";
export const charter_details = "https://pmb.boxbyld.tech/api/charter";
export const approve_chartet =
  "https://pmb.boxbyld.tech/api/charter/change-status";
export const get_Issues =
  "https://pmb.boxbyld.tech/api/tickets/pm-statuses/issues";
export const UploadIssues =
  "https://pmb.boxbyld.tech/api/tickets/pm-statuses/create-issue-media";
export const vendorsList = "https://alpha.boxbyld.tech/api/v1/admin/vendor";
export const post_product_availability =
  "https://pmb.boxbyld.tech/api/tickets/product-availability";
export const deleteIssueFile =
  "https://pmb.boxbyld.tech/api/tickets/pm-statuses/media";
export const designesList = "https://pmb.boxbyld.tech/api/designers";
export const contractorsList =
  "https://pmb.boxbyld.tech/api/designers/contractors";
export const sendDesignReq = "https://pmb.boxbyld.tech/api/designers/send-plan";
export const designRequestDetails =
  "https://pmb.boxbyld.tech/api/designers/plan";
export const apply_pto = "https://pmb.boxbyld.tech/api/apply-pto";
export const delete_PTO_files =
  "https://pmb.boxbyld.tech/api/apply-pto/remove-media";
export const NTP = "https://pmb.boxbyld.tech/api/ntps";
export const delete_NTP_files =
  "https://pmb.boxbyld.tech/api/ntps/remove-media";
export const apply_meter_spot = "https://pmb.boxbyld.tech/api/meter-spots";
export const delete_meter_spot_files =
  "https://pmb.boxbyld.tech/api/meter-spots/remove-media";
export const quotes_for_products =
  "https://pmb.boxbyld.tech/api/quotes-for-products";
export const delete_quotes_for_products =
  "https://pmb.boxbyld.tech/api/quotes-for-products/remove-media";
export const product_orders = "https://pmb.boxbyld.tech/api/product-orders";
export const delete_product_orders =
  "https://pmb.boxbyld.tech/api/product-orders/remove-media";
export const permit_to_finance =
  "https://pmb.boxbyld.tech/api/permit-to-finance";
export const delete_permit_to_finance_files =
  "https://pmb.boxbyld.tech/api/permit-to-finance/remove-media";
export const order_place_cards =
  "https://pmb.boxbyld.tech/api/order-place-cards";
export const delete_order_place_cards =
  "https://pmb.boxbyld.tech/api/order-place-cards/remove-media";

export const coordinate_product_delivery =
  "https://pmb.boxbyld.tech/api/coordinate-product-delivery";
export const delete_coordinate_product_delivery =
  "https://pmb.boxbyld.tech/api/coordinate-product-delivery/remove-media";

export const coordinate_installations =
  "https://pmb.boxbyld.tech/api/coordinate-installations";
export const delete_coordinate_installations_media =
  "https://pmb.boxbyld.tech/api/coordinate-installations/remove-media";

export const schedule_inspections =
  "https://pmb.boxbyld.tech/api/schedule-inspections";
export const delete_schedule_inspections_media =
  "https://pmb.boxbyld.tech/api/schedule-inspections/remove-media";

export const send_client_plan =
  "https://pmb.boxbyld.tech/api/client-plans/send";

export const installer_invoices =
  "https://pmb.boxbyld.tech/api/installer-invoices";
export const delete_installer_invoices_files =
  "https://pmb.boxbyld.tech/api/installer-invoices/remove-media";

export const upload_to_pto = "https://pmb.boxbyld.tech/api/upload-to-pto";
export const delete_upload_to_pto_files =
  "https://pmb.boxbyld.tech/api/upload-to-pto/remove-media";

export const send_full_details_to_client =
  "https://pmb.boxbyld.tech/api/send-full-details-to-client";
export const delete_send_full_details_to_client_files =
  "https://pmb.boxbyld.tech/api/send-full-details-to-client/remove-media";

export const review_profit_loss_statement =
  "https://pmb.boxbyld.tech/api/review-profit-loss-statement";
export const delete_review_profit_loss_statement_files =
  "https://pmb.boxbyld.tech/api/review-profit-loss-statement/remove-media";

export const closure_report = "https://pmb.boxbyld.tech/api/closure-report";
export const delete_closure_report_files =
  "https://pmb.boxbyld.tech/api/closure-report/remove-media";

export const installation_payment_collection =
  "https://pmb.boxbyld.tech/api/installation-payment-collection";
export const delete_installation_payment_collection_files =
  "https://pmb.boxbyld.tech/api/installation-payment-collection/remove-media";

export const installer_commissions =
  "https://pmb.boxbyld.tech/api/installer-commission-systems";
export const delete_installer_commissions_files =
  "https://pmb.boxbyld.tech/api/installer-commission-systems/remove-media";

export const installation_pics =
  "https://pmb.boxbyld.tech/api/installation-pics";
export const delete_installation_pics_files =
  "https://pmb.boxbyld.tech/api/installation-pics/remove-media";

export const confirm_inspection =
  "https://pmb.boxbyld.tech/api/confirm-inspections";
export const delete_confirm_inspection_files =
  "https://pmb.boxbyld.tech/api/confirm-inspections/remove-media";

export const apply_permits = "https://pmb.boxbyld.tech/api/perimts";
export const delete_apply_permits_files =
  "https://pmb.boxbyld.tech/api/perimts/remove-media";

export const upload_PTO_to_finance =
  "https://pmb.boxbyld.tech/api/upload-pto-to-finance";
export const delete_upload_PTO_to_finance_files =
  "https://pmb.boxbyld.tech/api/upload-pto-to-finance/remove-media";

export const final_job_card = "https://pmb.boxbyld.tech/api/finial-job-card";
export const delete_final_job_card_files =
  "https://pmb.boxbyld.tech/api/finial-job-card/upload-media";

export const end_project_call = "https://pmb.boxbyld.tech/api/end-project-call";
export const delete_end_project_call_files =
  "https://pmb.boxbyld.tech/api/end-project-call/remove-media";

export const schedule_with_city_office =
  "https://pmb.boxbyld.tech/api/schedule-with-city-office";
export const delete_schedule_with_city_office_files =
  "https://pmb.boxbyld.tech/api/schedule-with-city-office/remove-media";
export const inform_inspection_result =
  "https://pmb.boxbyld.tech/api/inspection-outcome";
export const delete_inspection_result_files =
  "https://pmb.boxbyld.tech/api/inspection-outcome/remove-media";

export const monitoring_optimizers =
  "https://pmb.boxbyld.tech/api/monitoring-optimizers";
export const delete_monitoring_optimizers_files =
  "https://pmb.boxbyld.tech/api/monitoring-optimizers/remove-media";

export const m1_collection = "https://pmb.boxbyld.tech/api/m1-collection";
export const delete_m1_collection =
  "https://pmb.boxbyld.tech/api/m1-collection/remove-media";

  export const m2_collection = "https://pmb.boxbyld.tech/api/m2-collection";
  export const delete_m2_collection =
    "https://pmb.boxbyld.tech/api/m2-collection/remove-media";

export const StoreFinalInspection =
  "https://pmb.boxbyld.tech/api/final-inspection";
export const delete_final_inspection_files =
  "https://pmb.boxbyld.tech/api/final-inspection/remove-media";

export const Store_call_designer = "https://pmb.boxbyld.tech/api/call-designer";
export const edit_call_designer =
  "https://pmb.boxbyld.tech/api/call-designer/edit";

export const delete_call_designer_data =
  "https://pmb.boxbyld.tech/api/final-inspection/remove-media";

export const installer_list = "https://pmb.boxbyld.tech/api/installers";
export const work_orders_to_installer =
  "https://pmb.boxbyld.tech/api/work-orders";
export const StoreBusinessLicense =
  "https://pmb.boxbyld.tech/api/businessLicense/StoreBusinessLicense";

export const get_business_license_Data =
  "https://pmb.boxbyld.tech/api/businessLicense/GetBusinessLicense";
export const update_business_license_Data =
  "https://pmb.boxbyld.tech/api/businessLicense/UpdateBusinessLicense";
export const work_orders_list = "https://pmb.boxbyld.tech/api/work-orders";
export const send_utility_bill =
  "https://pmb.boxbyld.tech/api/tickets/request-utility-bill";
export const create_adders_costs_specific_cards =
  "https://pmb.boxbyld.tech/api/adders-costs";
export const show_adders_costs_specific_cards =
  "https://pmb.boxbyld.tech/api/adders-costs";

export const get_adders_table =
  "https://pmb.boxbyld.tech/api/project-costs/adders";
export const update_adders_table =
  "https://pmb.boxbyld.tech/api/project-costs/adders/edit";

export const procedures = "https://pmb.boxbyld.tech/api/procedures";
export const delete_procedure =
  "https://pmb.boxbyld.tech/api/procedures/delete";

export const store_addons =
  "https://pmb.boxbyld.tech/api/project-costs/addons/create";
export const get_addons = "https://pmb.boxbyld.tech/api/project-costs/addons";
export const delete_addon =
  "https://pmb.boxbyld.tech/api/project-costs/addons/delete";
export const store_call_supplier =
  "https://pmb.boxbyld.tech/api/call-suppliers";
export const update_call_supplier =
  "https://pmb.boxbyld.tech/api/call-suppliers/edit";

export const store_call_utility_companies =
  "https://pmb.boxbyld.tech/api/call-utility-companies";
export const update_call_utility_companies =
  "https://pmb.boxbyld.tech/api/call-utility-companies/edit";

export const store_job_reviews = "https://pmb.boxbyld.tech/api/job-reviews";
export const update_job_reviews =
  "https://pmb.boxbyld.tech/api/job-reviews/edit";

export const store_confirm_with_sales_and_customers =
  "https://pmb.boxbyld.tech/api/confirm-items-with-sales-and-customers";
export const update_confirm_with_sales_and_customers =
  "https://pmb.boxbyld.tech/api/confirm-items-with-sales-and-customers/edit";

export const CardsListOrder = "https://pmb.boxbyld.tech/api/cards/orders";
export const transferCard = "https://pmb.boxbyld.tech/api/cards/change-group";
export const UpdateCardsOrder =
  "https://pmb.boxbyld.tech/api/cards/orders/edit";

export const store_first_step_of_survey =
  "https://pmb.boxbyld.tech/api/surveys/first-step/create";
export const get_first_step_of_survey =
  "https://pmb.boxbyld.tech/api/surveys/first-step";
export const update_first_step_of_survey =
  "https://pmb.boxbyld.tech/api/surveys/first-step/edit";

export const store_second_step_of_survey =
  "https://pmb.boxbyld.tech/api/surveys/second-step/create";
export const get_second_step_of_survey =
  "https://pmb.boxbyld.tech/api/surveys/second-step";
export const update_second_step_of_survey =
  "https://pmb.boxbyld.tech/api/surveys/second-step/edit";

export const store_quality_control_survey =
  "https://pmb.boxbyld.tech/api/surveys/quality-control/create";
export const get_quality_control_survey =
  "https://pmb.boxbyld.tech/api/surveys/quality-control";
export const update_quality_control_survey =
  "https://pmb.boxbyld.tech/api/surveys/quality-control/edit";

export const site_survey_images =
  "https://pmb.boxbyld.tech/api/work-orders/site-surveys";

export const store_call_jurisdictions =
  "https://pmb.boxbyld.tech/api/intakes/call-jurisdictions/create";
export const get_call_jurisdictions =
  "https://pmb.boxbyld.tech/api/intakes/call-jurisdictions";
export const update_call_jurisdictions =
  "https://pmb.boxbyld.tech/api/intakes/call-jurisdictions/edit";

export const store_city_office_utilities =
  "https://pmb.boxbyld.tech/api/permits/call-city-office-utilities/create";
export const get_city_office_utilities =
  "https://pmb.boxbyld.tech/api/permits/call-city-office-utilities";
export const update_city_office_utilities =
  "https://pmb.boxbyld.tech/api/permits/call-city-office-utilities/edit";

export const get_site_servey_data =
  "https://pmb.boxbyld.tech/api/work-orders/all/site-surveys";

export const single_design_req_data = "https://pmb.boxbyld.tech/api/designers/single/plan";
export const cost_design_plan = "https://pmb.boxbyld.tech/api/designers/design-plan/cost";
export const upload_files = "https://pmb.boxbyld.tech/api";
export const craete_card = "https://pmb.boxbyld.tech/api/cards/create";
export const get_single_card = "https://pmb.boxbyld.tech/api/cards";
export const update_single_card = "https://pmb.boxbyld.tech/api/cards/edit";
export const delete_single_card = "https://pmb.boxbyld.tech/api/cards/delete"

//export const all_site_surveys = "https://pmb.boxbyld.tech/api/work-orders/all/site-surveys"

//https://web.postman.co/workspace/My-Workspace~e2fb7189-ec79-44c2-8195-bd7aba342d9f/request/7580409-71cc97bc-9f0d-4571-9481-b2a61b3bc301
// https://documenter.getpostman.com/view/22096793/2s8ZDVb4Wk