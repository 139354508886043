import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get_all_addons } from "../../Redux/Slices/AddonsSlice/AddonsSlice";
import LottieAnimation from "../../Components/LottieAnimation/LottieAnimation";
import loading from "../../Assets/lottie/loading.json";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { delete_addon } from "../../Redux/Slices/AddonsSlice/AddonsSlice";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Grid, Typography } from "@mui/material";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#003656",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const AddonsList = ({ lead_id }) => {
  const dispatch = useDispatch();
  const all_addons = useSelector((state) => state.addons);
  const generators =
    all_addons?.addons?.data &&
    all_addons?.addons?.data.length > 0 &&
    all_addons?.addons?.data?.filter((a) => a.type == "generator");
  const hvac =
    all_addons?.addons?.data &&
    all_addons?.addons?.data.length > 0 &&
    all_addons?.addons?.data?.filter((a) => a.type == "hvac");
  const batteries =
    all_addons?.addons?.data &&
    all_addons?.addons?.data.length > 0 &&
    all_addons?.addons?.data?.filter((a) => a.type == "battery");

  useEffect(() => {
    dispatch(get_all_addons(lead_id));
  }, []);

  const handleDeleteAddone = async (addone) => {
    const reqData = {
      id: addone.id,
    };
    await dispatch(delete_addon(reqData));
    await dispatch(get_all_addons(lead_id));
  };
  const table = (addon) => (
    <TableContainer component={Paper} style={{ marginTop: "50px" }}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Name</StyledTableCell>
            <StyledTableCell align="center">number</StyledTableCell>
            <StyledTableCell align="center">price</StyledTableCell>
            <StyledTableCell align="center">total</StyledTableCell>
            <StyledTableCell align="center">type</StyledTableCell>
            <StyledTableCell align="center">delete</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {addon?.map((addon) => (
            <StyledTableRow key={addon.id}>
              <StyledTableCell component="th" scope="row">
                <>{addon.name}</>
              </StyledTableCell>
              <StyledTableCell align="center">{addon.number}</StyledTableCell>
              <StyledTableCell align="center">{addon.price}</StyledTableCell>
              <StyledTableCell align="center">{addon.total}</StyledTableCell>
              <StyledTableCell align="center">{addon.type}</StyledTableCell>
              <StyledTableCell
                align="center"
                style={{ cursor: "pointer" }}
                onClick={() => handleDeleteAddone(addon)}
              >
                <DeleteForeverIcon />
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
  const _gene = () => table(generators);
  const _hvac = () => table(hvac);
  const _batteries = () => table(batteries);
  return (
    <>
      {all_addons?.isLodaing ? (
        <LottieAnimation lotti={loading} width={180} height={180} />
      ) : all_addons?.addons?.data && all_addons?.addons?.data?.length > 0 ? (
        <>
          <Grid item xs={18}>
            <div style={{ marginTop: "20px" }}>
              <h3>Generators Information</h3>
            </div>
          </Grid>
          <p>{_gene()}</p>
          <Grid item xs={18}>
            <div style={{ marginTop: "20px" }}>
              <h3>Battery Information</h3>
            </div>
          </Grid>
          <p>{_batteries()}</p>

          <Grid item xs={18}>
            <div style={{ marginTop: "20px" }}>
              <h3>HVAC Information</h3>
            </div>
          </Grid>
          <p>{_hvac()}</p>
        </>
      ) : (
        <Grid style={{ padding: "30px" }}>
          <Typography align="center" variant="h5">
            {" "}
            No addons founded
          </Typography>
        </Grid>
      )}
    </>
  );
};

export default AddonsList;
