import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import createCharterService from "./CharterServise";
import { toast } from "react-toastify";
const initialState = {
  charter: {},
  isError: false,
  isSuccess: false,
  isLodaing: false,
  massage: "",
};

export const postCreateCharter = createAsyncThunk(
  "charter/post",
  async (data, thunkAPI) => {
    try {
      return await createCharterService.createCharterAction(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      error?.response?.data?.errors?.map((err) =>
        toast.error(err.msg, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      );
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const getCharterDetails = createAsyncThunk(
  "getCharterDetails/get",
  async (id, thunkAPI) => {
    try {
      return await createCharterService.getCharterDetailsAction(id);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const approveCharter = createAsyncThunk(
  "approve_charter/post",
  async (data, thunkAPI) => {
    try {
      return await createCharterService.approveCharterAction(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);
export const charterSlice = createSlice({
  name: "charter",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLodaing = false;
      state.massage = "";
    },
  },
  extraReducers: {
    //post create charter  Action
    [postCreateCharter.pending]: (state) => {
      state.isLodaing = true;
    },
    [postCreateCharter.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.charter = payload;
    },
    [postCreateCharter.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },

    //get  charter  Action
    [getCharterDetails.pending]: (state) => {
      state.isLodaing = true;
    },
    [getCharterDetails.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.charter = payload;
    },
    [getCharterDetails.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
      state.charter = null;
    },

    //post approve charter  Action
    [approveCharter.pending]: (state) => {
      state.isLodaing = true;
    },
    [approveCharter.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.charter = payload;
    },
    [approveCharter.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
  },
});

export const { reset } = charterSlice.actions;
export default charterSlice.reducer;
