import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import usersService from "./UserService";

const initialState = {
  PMusers: null,
  isError: false,
  isSuccess: false,
  isLodaing: false,
  massage: "",
};

export const getAllPM_Users = createAsyncThunk(
  "PM_user/getAll",
  async (thunkAPI) => {
    try {
      return await usersService.Get_PM_Users_Action();
    } catch (error) {
      const massage = error.message;
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const PM_usersSlice = createSlice({
  name: "PM_user",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLodaing = false;
      state.isDeleteLodaing = false;
      state.massage = "";
    },
  },
  extraReducers: {
    [getAllPM_Users.pending]: (state) => {
      state.isLodaing = true;
    },
    [getAllPM_Users.fulfilled]: (state, action) => {
      state.isLodaing = false;
      state.PMusers = action.payload;
      state.isSuccess = true;
    },
    [getAllPM_Users.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
  },
});

export const { reset } = PM_usersSlice.actions;
export default PM_usersSlice.reducer;
