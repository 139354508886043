import React, { useState, useEffect } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab } from "@mui/material";
import { Box } from "@mui/system";
import styled from "styled-components";
import SolarAttachments from "../SolarAttachments/SolarAttachments";
import JobCardDetails from "../JobCardDetails/JobCardDetails";
import SolarSolution from "../SolarSolution/SolarSolution";
import MapTap from "../mapTap/MapTap";
import ZillowTap from "../ZillowTap/ZillowTap";
import AllProceduresTab from "../AllProceduresTab/AllProceduresTab";
import Lead from "../Lead/Lead";
import { getSingleTicket } from "../../Redux/Slices/SingleTicketSlice/SingleTicketSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
const LeadTabs = () => {
  const dispatch = useDispatch();
  const { lead_id } = useParams();
  const [value, setValue] = useState("1");
  const single_ticket = useSelector((state) => state.single_ticket);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const params = useParams();
  console.log(params, "454545");
  console.log(
    single_ticket?.singleTicket?.data?.lead?.location,
    "single_ticket"
  );
  useEffect(() => {
    dispatch(getSingleTicket(lead_id));
  }, []);
  return (
    <>
      <>
        <MainTabsStyled>
          <Box
            sx={{ width: "100%", typography: "body1" }}
            style={{ backgroundColor: "white" }}
          >
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                >
                  <Tab label="lead" value="1" />
                  <Tab label="Job Card Details" value="2" />
                  <Tab label="Solution" value="4" />
                  <Tab label="Attachments " value="3" />
                  {/*<Tab label="Map" value="5" />*/}
                  {/* <Tab label="Procedures" value="5" /> */}

                  <a
                    href={`https://www.zillow.com/homes/${single_ticket?.singleTicket?.data?.lead?.location}`}
                    target="_blank"
                  >
                    <Tab label="Zillow" value="6" />
                  </a>
                </TabList>
              </Box>

              <TabPanel value="4">
                <SolarSolution />
              </TabPanel>
              <TabPanel value="3">
                <SolarAttachments />
              </TabPanel>
              <TabPanel value="2">
                <JobCardDetails />
              </TabPanel>

              <TabPanel value="1">
                <Lead />
              </TabPanel>

              {/*<TabPanel value="5">
                <MapTap />
              </TabPanel>*/}
              {/* <TabPanel value="5">
                <AllProceduresTab />
              </TabPanel> */}

              <TabPanel value="6">
                <ZillowTap />
              </TabPanel>
            </TabContext>
          </Box>
        </MainTabsStyled>
      </>
    </>
  );
};
const MainTabsStyled = styled.div`
  margin: 1rem 0.8rem;
  border-radius: 5px;
`;
export default LeadTabs;
