import styled from "styled-components";

export const UserContainer = styled.div`
  background-color: #ffffff;
  padding: 0.5rem;
  margin: 0 0 1rem;
  border-radius: 10px;
  font-weight: 200;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 14%);
  align-items: center;
  display: flex;
  justify-content: space-around;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
  .user {
    flex-basis: 75%;
    &__data {
      display: flex;
      justify-content: start;
      align-items: center;
      &--img {
        padding: 1rem;
        height: 6rem;
        width: 6rem;
        overflow: hidden;
        margin-right: 15px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      &--content {
        display: flex;
        flex-direction: column;
        margin-left: 0.5rem;
        .userName {
          margin: 0;
          padding: 0;
          font-size: 1rem;
          font-weight: 600;
          color: #363636;
        }
        .caption {
          color: #babec6;
          display: block;
        }
      }
    }
  }
  .search {
    display: flex;
    flex-basis: 30%;
    width: 100%;
    @media screen and (max-width: 768px) {
      display: contents;
    }
    .search-input {
      position: relative;
      .form-control {
        width: 300px;
        height: 50px;
        overflow: hidden;
        border-radius: 3rem;
        border: none;
        padding: 0.5rem;
        border: 2px solid #e6eaf0;
        position: relative;
        font-size: 15px;
        &:focus {
          outline: none;
          border: 2px solid #76adff;
        }
        @media only screen and (min-width: 321px) and (max-width: 768px) {
          width: 252px;
          height: 39px;
          margin-bottom: 10px;
        }
        @media only screen and (max-width: 320px) {
          width: 180px;
          height: 30px;
          margin-bottom: 10px;
          font-size: 10px;
        }
      }
      .searchIcon {
        position: absolute;
        right: 14px;
        font-size: 25px;
        top: 50%;
        transform: translateY(-50%);
        color: #b4b7bd;
      }
    }
  }
`;
