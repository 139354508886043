import React, { useState  , useEffect} from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Button,
  IconButton,
  Typography,
  Grid,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { PrimaryButton } from "../../../Layouts/ButtonsControlled";
import { reduxForm, Field } from "redux-form";
import MuiSelectField from "../../../Common/MuiSelectField/MuiSelectField";
import { transfer_Cards , getCardsOrder } from "../../../Redux/Slices/CardsListSlice/CardsListSlice";
import { useDispatch  , useSelector} from "react-redux";
export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const TransferCardModal = ({
  openTransferCardtModal,
  handleCloseTransferCardtModal,
  selectedCard,
  transferCard,
  handleUpdate_Receiving_Projectcards,
  handleSubmit,

  setTransferCard
}) => {
  // const [card_group, set_Card_group] = useState();
  const dispatch = useDispatch();


  console.log(selectedCard, "selectedCard from transfer card 4545");
 
  const submit = async (values) => {
   console.log(values, "values");
    const reqData ={
        id:selectedCard.id,
      card_group :values.card_group
    }
   
    await dispatch(transfer_Cards(reqData));
    await setTransferCard(false);
    await  dispatch(getCardsOrder());
  };

  return (
    <Dialog
      fullWidth
      maxWidth={"sm"}
      open={transferCard}
      onClose={handleCloseTransferCardtModal}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={handleCloseTransferCardtModal}
      >
        transfer {selectedCard?.title} card  from {selectedCard?.card_group} group to ..
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <form onSubmit={handleSubmit((values) => submit(values))}>
        
          <Grid
            // container
            columns={12}
            // spacing={5}
            //  justifyContent="center"
            mb={3}
          >
            <Field
              required
              name="card_group"
              component={MuiSelectField}
              label="card group"
            >
              <MenuItem value="Receiving Project">Receiving Project</MenuItem>
              <MenuItem value="Design Submittal">Design Submittal</MenuItem>
              <MenuItem value="PTO">PTO</MenuItem>
              <MenuItem value="PTO submittal">PTO submittal</MenuItem>
              <MenuItem value="Permits">Permits</MenuItem>
              <MenuItem value="interconnection">interconnection</MenuItem>
              <MenuItem value="Installation">Installation</MenuItem>
              <MenuItem value="Inspection">Inspection</MenuItem>
              <MenuItem value="Survey">Survey</MenuItem>
              <MenuItem value="Closing The Project">Closing The Project</MenuItem>
            </Field>
          </Grid>

          {/* </Grid> */}
          <DialogActions>
            <PrimaryButton
              type="submit"
              fullWidth
              style={{
                padding: "0",
                width: "85%",
                margin: "15px auto",
                height: "42px",
              }}
              className="saveButton"
            >
              Save
            </PrimaryButton>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default reduxForm({
  form: "transfer_cards_Form",
  enableReinitialize: true,
})(TransferCardModal);
