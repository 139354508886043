import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { Get_Ticket } from "../../../Api/Api";

export const GetTickets = createAsyncThunk(
  "ticket/getTickets",
  async (data, thunkAPI) => {
    try {
      const { token } = JSON.parse(localStorage.getItem("user"));
      const headers = {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      };
      return await axios({
        method: "GET",
        baseURL: `${Get_Ticket}`,
        headers: headers,
        params: {
          page: data.page,
          status: data.status,
        },
      }).then((res) => {
        if (res) {
          return res.data;
        }
      });
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

const ticketSlice = createSlice({
  name: "Tikcets",
  initialState: {
    allTickets: "",
    status: "",
    error: "",
  },
  reducers: {},
  extraReducers: {
    [GetTickets.pending]: (state, { payload }) => {
      state.status = "loading";
    },
    [GetTickets.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.allTickets = payload;
      state.isAuth = true;
    },
    [GetTickets.rejected]: (state, { payload }) => {
      state.status = "failed";
      state.isAuth = false;
      state.error = payload;
    },
  },
});
export default ticketSlice.reducer;
