import React from "react";
import { Grid } from "@mui/material";
import { ImageController } from "../../Layouts/ImgController";
import { HeaderForm } from "./LoginStyled";
import LoginForm from "./LoginForm";
import loginImg from "../../Assets/Images/loginImg.png";
import logo from "../../Assets/Images/logo.svg";
import Lottie from "lottie-react";
import lottieLogin from'./lottieLogin.json'
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from "react-redux";

function Login() {
  const message=useSelector((state)=>state.auth.error)
 

  // React.useEffect(()=>{
  //   notify()
  // },[])
  
  return (
    <>
      <Grid container columns={12} direction="row" justifyContent="flex-start">
        <div style={{ margin: "20px 100px" }}>
          <ImageController src={logo} />
        </div>
      </Grid>

      <Grid
        container
        columns={12}
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ height: "100vh" }}
      >
        <Grid item md={6} xs={12} justifyContent="flex-start">
          <HeaderForm>
            <h2>Welcme back </h2>
            <p>Welcome back! Please enter your details.</p>
            <LoginForm />
          </HeaderForm>
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
          className="center-flex"
          sx={{ sm: "block", display: { xs: "none", md: "flex" } }}
        >
          {/* <ImageController
            src={loginImg}
            width="600px"
            objectFit="cover"
            drop="0px 3px 5px #8d8d8d"
            m="-2rem 0  4rem "
          /> */}
           <Lottie 
           loop={ false}
           animationData={lottieLogin} />
        </Grid>
      </Grid>
     
    </>
  );
}

export default Login;
