import React, { useEffect, useState } from "react";
//import { styled } from "@mui/material/styles";
import styled from "styled-components";
import { Box } from "@mui/system";
import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from "react-redux";
import LottieAnimation from "../../Components/LottieAnimation/LottieAnimation";
import { getSingleTicket } from "../../Redux/Slices/SingleTicketSlice/SingleTicketSlice";
import {
  get_all_adders_table,
  edit_adders_data,
} from "../../Redux/Slices/AddersTableSlice/AddersTableSlice";
import Button from "@mui/material/Button";
import { useParams } from "react-router-dom";
import loading from "../../Assets/lottie/loading.json";
import { useNavigate } from "react-router-dom";
import { Typography, Grid , Toolbar  , AppBar} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
function AddersTable() {
  const dispatch = useDispatch();
const navigate = useNavigate();
  const single_ticket = useSelector((state) => state.single_ticket);
  const all_adders = useSelector((state) => state.addersTable);
  const [structual_calculation_prices, set_structual_calculation_prices] =
    useState("");
  const [site_survey_price, set_site_survey_prices] = useState("");
  const [permit_fees_price, set_permit_fees_prices] = useState("");
  const [permit_run_price, set_permit_run_prices] = useState("");
  const [roofing_price, set_roofing_price] = useState("");
  const [mpu_fees_price, set_mpu_fees_price] = useState("");
  const [solar_installation_fees, set_solar_installation_fees] = useState("");
  const [pto_fees, set_pto_fees] = useState("");
  const [solar_products_fees, set_solar_products_fees] = useState("");
  const [online_monitoring_fees, set_online_monitoring_fees] = useState("");
  const [attic_run_fees, set_attic_run_fees] = useState("");
  const [tree_trimming_fees, set_tree_trimming_fees] = useState("");
  const [mpu_relocation_fees, set_mpu_relocation_fees] = useState("");
  const [de_rate_fees, set_de_rate_fees] = useState("");
  const [upgrade_electrical_panel_fees, set_upgrade_electrical_panel_fees] = useState("");
  const [sub_panel_fees, set_sub_panel_fees] = useState("");
  const [meter_socket, set_meter_socket] = useState("");
  const [ev_charger, set_ev_charger] = useState("");
  const [ev_mlo, set_ev_mlo] = useState("");
  const [designated_plugs, set_designated_plugs] = useState("");
  const [ducting, set_ducting] = useState("");
  const [led_lighting, set_led_lighting] = useState("");
  const [energy_efficient, set_energy_efficient] = useState("");
  const [solar_lip, set_solar_lip] = useState("");
  const [bird_netting, set_bird_netting] = useState("");
  const [trenching_dirt, set_trenching_dirt] = useState("");
  const [trenching_concrete, set_trenching_concrete] = useState("");
  const [warranty, set_warranty] = useState("");

  const data = all_adders?.addersTable?.data || {};
  console.log(all_adders?.addersTable, "all_adders ");
  const { lead_id } = useParams();

  const state_id =
    single_ticket?.singleTicket?.data?.opportunity?.lead?.state_id;

  useEffect(() => {
    dispatch(getSingleTicket(lead_id));
    dispatch(get_all_adders_table(lead_id));

    //to Do : get all adders
  }, [lead_id, state_id]);

  const submit_attic_price = async (e) => {
    e.preventDefault();
    const reqData = {
      key: "structual_calculation",
      ticket_id: +lead_id,
      price_from: all_adders?.addersTable?.data?.structual_calculation,
      price_to: +structual_calculation_prices,
    };
    await dispatch(edit_adders_data(reqData));
    await dispatch(get_all_adders_table(lead_id));
    await set_structual_calculation_prices("");
  };

  const submit_permit_fees_price = async (e) => {
    e.preventDefault();
    const reqData = {
      key: "permit_fees",
      ticket_id: +lead_id,
      price_from: all_adders?.addersTable?.data?.structual_calculation,
      price_to: +permit_fees_price,
    };
    await dispatch(edit_adders_data(reqData));
    await dispatch(get_all_adders_table(lead_id));
    await set_permit_fees_prices("");
  };

  const submit_site_survey_price = async (e) => {
    e.preventDefault();
    const reqData = {
      key: "site_survey",
      ticket_id: +lead_id,
      price_from: all_adders?.addersTable?.data?.site_survey,
      price_to: +site_survey_price,
    };
    await dispatch(edit_adders_data(reqData));
    await dispatch(get_all_adders_table(lead_id));
    await set_site_survey_prices("");
  };

  const submit_permit_run_price = async (e) => {
    e.preventDefault();
    const reqData = {
      key: "permit_run",
      ticket_id: +lead_id,
      price_from: all_adders?.addersTable?.data?.permit_run,
      price_to: +permit_run_price,
    };
    await dispatch(edit_adders_data(reqData));
    await dispatch(get_all_adders_table(lead_id));
    await set_permit_run_prices("");
  };

  const submit_roofing_price_price = async (e) => {
    e.preventDefault();
    const reqData = {
      key: "roofing_price",
      ticket_id: +lead_id,
      price_from: all_adders?.addersTable?.data?.roofing_price,
      price_to: +roofing_price,
    };
    await dispatch(edit_adders_data(reqData));
    await dispatch(get_all_adders_table(lead_id));
    await set_roofing_price("");
  };

  const submit_mpu_fees_price = async (e) => {
    e.preventDefault();
    const reqData = {
      key: "mpu_fees",
      ticket_id: +lead_id,
      price_from: all_adders?.addersTable?.data?.mpu_fees,
      price_to: +mpu_fees_price,
    };
    await dispatch(edit_adders_data(reqData));
    await dispatch(get_all_adders_table(lead_id));
    await set_mpu_fees_price("");
  };

  const submit_solar_installation_fees = async (e) => {
    e.preventDefault();
    const reqData = {
      key: "solar_installation_fees",
      ticket_id: +lead_id,
      price_from: all_adders?.addersTable?.data?.solar_installation_fees,
      price_to: +solar_installation_fees,
    };
    await dispatch(edit_adders_data(reqData));
    await dispatch(get_all_adders_table(lead_id));
    await set_solar_installation_fees("");
  };

  const submit_pto_fees = async (e) => {
    e.preventDefault();
    const reqData = {
      key: "pto_fees",
      ticket_id: +lead_id,
      price_from: all_adders?.addersTable?.data?.pto_fees,
      price_to: +pto_fees,
    };
    await dispatch(edit_adders_data(reqData));
    await dispatch(get_all_adders_table(lead_id));
    await set_pto_fees("");
  };

  const submit_solar_products_fees = async (e) => {
    e.preventDefault();
    const reqData = {
      key: "solar_products",
      ticket_id: +lead_id,
      price_from: all_adders?.addersTable?.data?.solar_products,
      price_to: +solar_products_fees,
    };
    await dispatch(edit_adders_data(reqData));
    await dispatch(get_all_adders_table(lead_id));
    await set_solar_products_fees("");
  };

  const submit_online_monitoring_fees = async (e) => {
    e.preventDefault();
    const reqData = {
      key: "online_monitoring",
      ticket_id: +lead_id,
      price_from: all_adders?.addersTable?.data?.online_monitoring,
      price_to: +online_monitoring_fees,
    };
    await dispatch(edit_adders_data(reqData));
    await dispatch(get_all_adders_table(lead_id));
    await set_online_monitoring_fees("");
  };

  const submit_attic_run_fees = async (e) => {
    e.preventDefault();
    const reqData = {
      key: "attic_run",
      ticket_id: +lead_id,
      price_from: all_adders?.addersTable?.data?.attic_run,
      price_to: +attic_run_fees,
    };
    await dispatch(edit_adders_data(reqData));
    await dispatch(get_all_adders_table(lead_id));
    await set_attic_run_fees("");
  };

  const submit_tree_trimming_fees = async (e) => {
    console.log(e.value, "eee");
    e.preventDefault();
    const reqData = {
      key: "tree_trimming",
      ticket_id: +lead_id,
      price_from: all_adders?.addersTable?.data?.tree_trimming,
      price_to: +tree_trimming_fees,
    };
    await dispatch(edit_adders_data(reqData));
    await dispatch(get_all_adders_table(lead_id));
    await set_tree_trimming_fees("");
  };

  const submit_mpu_relocation_fees = async (e) => {
    e.preventDefault();
    const reqData = {
      key: "mpu_relocation",
      ticket_id: +lead_id,
      price_from: all_adders?.addersTable?.data?.mpu_relocation,
      price_to: +mpu_relocation_fees,
    };
    await dispatch(edit_adders_data(reqData));
    await dispatch(get_all_adders_table(lead_id));
    await set_mpu_relocation_fees("");
  };

  const submit_de_rate_fees = async (e) => {
    e.preventDefault();
    const reqData = {
      key: "de_rate",
      ticket_id: +lead_id,
      price_from: all_adders?.addersTable?.data?.de_rate,
      price_to: +de_rate_fees,
    };
    await dispatch(edit_adders_data(reqData));
    await dispatch(get_all_adders_table(lead_id));
    await set_de_rate_fees("");
  };

  const submit_upgrade_electrical_panel_fees = async (e) => {
    e.preventDefault();
    const reqData = {
      key: "upgrade_electrical_panel",
      ticket_id: +lead_id,
      price_from: all_adders?.addersTable?.data?.upgrade_electrical_panel,
      price_to: +upgrade_electrical_panel_fees,
    };
    await dispatch(edit_adders_data(reqData));
    await dispatch(get_all_adders_table(lead_id));
    await set_upgrade_electrical_panel_fees("");
  };

  const submit_sub_panel_fees = async (e) => {
    e.preventDefault();
    const reqData = {
      key: "sub_panel",
      ticket_id: +lead_id,
      price_from: all_adders?.addersTable?.data?.sub_panel,
      price_to: +sub_panel_fees,
    };
    await dispatch(edit_adders_data(reqData));
    await dispatch(get_all_adders_table(lead_id));
    await set_sub_panel_fees("");
  };

  const submit_meter_socket = async (e) => {
    e.preventDefault();
    const reqData = {
      key: "meter_socket",
      ticket_id: +lead_id,
      price_from: all_adders?.addersTable?.data?.meter_socket,
      price_to: +meter_socket,
    };
    await dispatch(edit_adders_data(reqData));
    await dispatch(get_all_adders_table(lead_id));
    await set_meter_socket("");
  };

  const submit_ev_charger = async (e) => {
    e.preventDefault();
    const reqData = {
      key: "ev_charger",
      ticket_id: +lead_id,
      price_from: all_adders?.addersTable?.data?.ev_charger,
      price_to: +ev_charger,
    };
    await dispatch(edit_adders_data(reqData));
    await dispatch(get_all_adders_table(lead_id));
    await set_ev_charger("");
  };

  const submit_ev_mlo = async (e) => {
    e.preventDefault();
    const reqData = {
      key: "ev_mlo",
      ticket_id: +lead_id,
      price_from: all_adders?.addersTable?.data?.ev_mlo,
      price_to: +ev_mlo,
    };
    await dispatch(edit_adders_data(reqData));
    await dispatch(get_all_adders_table(lead_id));
    await set_ev_mlo("");
  };

  const submit_designated_plugs = async (e) => {
    e.preventDefault();
    const reqData = {
      key: "designated_plugs",
      ticket_id: +lead_id,
      price_from: all_adders?.addersTable?.data?.designated_plugs,
      price_to: +designated_plugs,
    };
    await dispatch(edit_adders_data(reqData));
    await dispatch(get_all_adders_table(lead_id));
    await set_designated_plugs("");
  };
  const submit_ducting = async (e) => {
    e.preventDefault();
    const reqData = {
      key: "ducting",
      ticket_id: +lead_id,
      price_from: all_adders?.addersTable?.data?.ducting,
      price_to: +ducting,
    };
    await dispatch(edit_adders_data(reqData));
    await dispatch(get_all_adders_table(lead_id));
    await set_ducting("");
  };
  const submit_led_lighting = async (e) => {
    e.preventDefault();
    const reqData = {
      key: "led_lighting",
      ticket_id: +lead_id,
      price_from: all_adders?.addersTable?.data?.led_lighting,
      price_to: +led_lighting,
    };
    await dispatch(edit_adders_data(reqData));
    await dispatch(get_all_adders_table(lead_id));
    await set_led_lighting("");
  };

  const submit_energy_efficient = async (e) => {
    e.preventDefault();
    const reqData = {
      key: "energy_efficient",
      ticket_id: +lead_id,
      price_from: all_adders?.addersTable?.data?.energy_efficient,
      price_to: +energy_efficient,
    };
    await dispatch(edit_adders_data(reqData));
    await dispatch(get_all_adders_table(lead_id));
    await set_energy_efficient("");
  };

  const submit_solar_lip = async (e) => {
    e.preventDefault();
    const reqData = {
      key: "solar_lip",
      ticket_id: +lead_id,
      price_from: all_adders?.addersTable?.data?.solar_lip,
      price_to: +solar_lip,
    };
    await dispatch(edit_adders_data(reqData));
    await dispatch(get_all_adders_table(lead_id));
    await set_solar_lip("");
  };

  const submit_bird_netting = async (e) => {
    e.preventDefault();
    const reqData = {
      key: "bird_netting",
      ticket_id: +lead_id,
      price_from: all_adders?.addersTable?.data?.bird_netting,
      price_to: +bird_netting,
    };
    await dispatch(edit_adders_data(reqData));
    await dispatch(get_all_adders_table(lead_id));
    await set_bird_netting("");
  };

  const submit_trenching_dirt = async (e) => {
    e.preventDefault();
    const reqData = {
      key: "trenching_dirt",
      ticket_id: +lead_id,
      price_from: all_adders?.addersTable?.data?.trenching_dirt,
      price_to: +trenching_dirt,
    };
    await dispatch(edit_adders_data(reqData));
    await dispatch(get_all_adders_table(lead_id));
    await set_trenching_dirt("");
  };

  const submit_trenching_concrete = async (e) => {
    e.preventDefault();
    const reqData = {
      key: "trenching_concrete",
      ticket_id: +lead_id,
      price_from: all_adders?.addersTable?.data?.trenching_concrete,
      price_to: +trenching_concrete,
    };
    await dispatch(edit_adders_data(reqData));
    await dispatch(get_all_adders_table(lead_id));
    await set_trenching_concrete("");
  };

  const submit_warranty = async (e) => {
    e.preventDefault();
    const reqData = {
      key: "warranty",
      ticket_id: +lead_id,
      price_from: all_adders?.addersTable?.data?.warranty,
      price_to: +warranty,
    };
    await dispatch(edit_adders_data(reqData));
    await dispatch(get_all_adders_table(lead_id));
    await set_warranty("");
  };

  return (
    <>
      {all_adders?.isLodaing ? (
        <LottieAnimation lotti={loading} width={180} height={180} />
      ) : (
        <MainTabsStyled>
        <Box
          sx={{ width: "100%", typography: "body1" }}
          style={{ backgroundColor: "white"  ,
          
          padding:"0px 0px"}}
        >
        <AppBar position="static"
              style={{ backgroundColor: "white", color: "black"  , marginBottom:"25px"}}
            >
              <Toolbar>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                  onClick={() => navigate(`/home/SolarSolution/start-project/${lead_id}`)}

                >
                  <ArrowBackIcon />
                </IconButton>
              
                <Typography variant="h5" align="center"> Adders </Typography>
              </Toolbar>
            </AppBar>

<>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Grid >
             
              {Object.keys(data)?.map((keyName, i) => (
                <Grid container  lg={12}>
                  {keyName !== "id" &&
                    keyName !== "created_at" &&
                    keyName !== "ticket_id" &&
                    keyName !== "updated_at" &&
                    keyName !== "project_cost_id" &&
                    keyName !== "deleted_at" && (
                      <FormControl
                        variant="outlined"
                        style={{ margin: "10px" ,  }}
                      >
                        <TextField
                          fullWidth
                          label={keyName}
                          name="price_to"
                          value={all_adders?.addersTable?.data[keyName]}
                          disabled
                        />
                      
                      </FormControl>
                    )}
                </Grid>
              ))}
            </Grid>
            {all_adders?.addersTable !== null && (
              <div style={{ display: "block", justifyContent: "center" }}>
                <div style={{ display: "flex", margin: "7px  0px  15px 10px" }}>
                  <div>
                    <FormControl variant="outlined"  style={{marginBottom:"5px"}} >
                      <TextField
                        fullWidth
                        label="structual_calculation"
                        name="price_to"
                        value={structual_calculation_prices}
                        onChange={(e) =>
                          set_structual_calculation_prices(e.target.value)
                        }
                      />
                    </FormControl>
                  </div>

                  <div>
                    <Button variant="contained" color="success" startIcon={ <DoneIcon />}  onClick={submit_attic_price} style={{margin:"10px"}} >
                       update
                     </Button>
                  </div>
                </div>

                <div style={{ display: "flex", margin: "7px  0px  15px 10px" }}>
                  <div>
                    <FormControl variant="outlined" style={{marginBottom:"5px"}}>
                      <TextField
                        fullWidth
                        label="site_survey"
                        name="price_to"
                        value={site_survey_price}
                        onChange={(e) => set_site_survey_prices(e.target.value)}
                      />
                    </FormControl>
                  </div>

                  <div>
               

                 <Button variant="contained" color="success" startIcon={ <DoneIcon />}   onClick={submit_site_survey_price} style={{margin:"10px"}} >
                       update
                     </Button>
                  </div>
                </div>

                <div style={{ display: "flex", margin: "7px  0px  15px 10px" }}>
                  <div>
                    <FormControl variant="outlined" style={{marginBottom:"5px"}}>
                      <TextField
                        fullWidth
                        label="permit_fees"
                        name="price_to"
                        value={permit_fees_price}
                        onChange={(e) => set_permit_fees_prices(e.target.value)}
                      />
                    </FormControl>
                  </div>

                  <div>

                   <Button variant="contained" color="success" startIcon={ <DoneIcon />}  onClick={submit_permit_fees_price} style={{margin:"10px"}} >
                       update
                     </Button>
                  </div>
                </div>

                <div style={{ display: "flex", margin: "7px  0px  15px 10px" }}>
                  <div>
                    <FormControl variant="outlined" style={{marginBottom:"5px"}}>
                      <TextField
                        fullWidth
                        label="permit_run"
                        name="price_to"
                        value={permit_run_price}
                        onChange={(e) => set_permit_run_prices(e.target.value)}
                      />
                    </FormControl>
                  </div>

                  <div>
                   <Button variant="contained" color="success" startIcon={ <DoneIcon />}  onClick={submit_permit_run_price} style={{margin:"10px"}} >
                       update
                  </Button>
                  </div>
                </div>

                <div style={{ display: "flex", margin: "7px  0px  15px 10px" }}>
                  <div>
                    <FormControl variant="outlined" style={{marginBottom:"5px"}}>
                      <TextField
                        fullWidth
                        label="roofing_price"
                        name="price_to"
                        value={roofing_price}
                        onChange={(e) => set_roofing_price(e.target.value)}
                      />
                    </FormControl>
                  </div>

                  <div>
                   <Button variant="contained" color="success" startIcon={ <DoneIcon />}  onClick={submit_roofing_price_price} style={{margin:"10px"}} >
                       update
                  </Button>
                  </div>
                </div>

                <div style={{ display: "flex", margin: "7px  0px  15px 10px" }}>
                  <div>
                    <FormControl variant="outlined" style={{marginBottom:"5px"}}>
                      <TextField
                        fullWidth
                        label="mpu_fees"
                        name="price_to"
                        value={mpu_fees_price}
                        onChange={(e) => set_mpu_fees_price(e.target.value)}
                      />
                    </FormControl>
                  </div>

                  <div>
                   <Button variant="contained" color="success" startIcon={ <DoneIcon />}  onClick={submit_mpu_fees_price} style={{margin:"10px"}} >
                       update
                  </Button>
                  </div>
                </div>

                <div style={{ display: "flex", margin: "7px  0px  15px 10px" }}>
                  <div>
                    <FormControl variant="outlined" style={{marginBottom:"5px"}}>
                      <TextField
                        fullWidth
                        label="solar_installation_fees"
                        name="price_to"
                        value={solar_installation_fees}
                        onChange={(e) =>
                          set_solar_installation_fees(e.target.value)
                        }
                      />
                    </FormControl>
                  </div>

                  <div>
                   <Button variant="contained" color="success" startIcon={ <DoneIcon />}  onClick={submit_solar_installation_fees} style={{margin:"10px"}} >
                       update
                  </Button>
                  </div>
                </div>

                <div style={{ display: "flex", margin: "7px  0px  15px 10px" }}>
                  <div>
                    <FormControl variant="outlined" style={{marginBottom:"5px"}}>
                      <TextField
                        fullWidth
                        label="pto_fees"
                        name="price_to"
                        value={pto_fees}
                        onChange={(e) => set_pto_fees(e.target.value)}
                      />
                    </FormControl>
                  </div>

                  <div>
                   <Button variant="contained" color="success" startIcon={ <DoneIcon />} onClick={submit_pto_fees} style={{margin:"10px"}} >
                       update
                  </Button>
                  </div>
                </div>
                <div style={{ display: "flex", margin: "7px  0px  15px 10px" }}>
                  <div>
                    <FormControl variant="outlined" style={{marginBottom:"5px"}}>
                      <TextField
                        fullWidth
                        label="solar_products"
                        name="price_to"
                        value={solar_products_fees}
                        onChange={(e) =>
                          set_solar_products_fees(e.target.value)
                        }
                      />
                    </FormControl>
                  </div>

                  <div>
                    <Button variant="contained" color="success" startIcon={ <DoneIcon />} onClick={submit_solar_products_fees} style={{margin:"10px"}} >
                       update
                  </Button>
                  </div>
                </div>

                <div style={{ display: "flex", margin: "7px  0px  15px 10px" }}>
                  <div>
                    <FormControl variant="outlined" style={{marginBottom:"5px"}}>
                      <TextField
                        fullWidth
                        label="online_monitoring"
                        name="price_to"
                        value={online_monitoring_fees}
                        onChange={(e) =>
                          set_online_monitoring_fees(e.target.value)
                        }
                      />
                    </FormControl>
                  </div>

                  <div>
                  
                  <Button variant="contained" color="success" startIcon={ <DoneIcon />}  onClick={submit_online_monitoring_fees} style={{margin:"10px"}} >
                       update
                  </Button>
                  </div>
                </div>
                <div style={{ display: "flex", margin: "7px  0px  15px 10px" }}>
                  <div>
                    <FormControl variant="outlined" style={{marginBottom:"5px"}}>
                      <TextField
                        fullWidth
                        label="attic_run"
                        name="price_to"
                        value={attic_run_fees}
                        onChange={(e) => set_attic_run_fees(e.target.value)}
                      />
                    </FormControl>
                  </div>

                  <div>
                  <Button variant="contained" color="success" startIcon={ <DoneIcon />}  onClick={submit_attic_run_fees} style={{margin:"10px"}} >
                       update
                  </Button>
                  </div>
                </div>
                <div style={{ display: "flex", margin: "7px  0px  15px 10px" }}>
                  <div>
                    <FormControl variant="outlined" style={{marginBottom:"5px"}}>
                      <TextField
                        fullWidth
                        label="tree_trimming"
                        name="price_to"
                        value={tree_trimming_fees}
                        onChange={(e) => set_tree_trimming_fees(e.target.value)}
                      />
                    </FormControl>
                  </div>

                  <div>

                   <Button variant="contained" color="success" startIcon={ <DoneIcon />}  onClick={submit_tree_trimming_fees} style={{margin:"10px"}} >
                       update
                  </Button>
                  </div>
                </div>

               
                <div style={{ display: "flex", margin: "7px  0px  15px 10px" }}>
                  <div>
                    <FormControl variant="outlined" style={{marginBottom:"5px"}}>
                      <TextField
                        fullWidth
                        label="mpu_relocation"
                        name="price_to"
                        value={mpu_relocation_fees}
                        onChange={(e) =>
                          set_mpu_relocation_fees(e.target.value)
                        }
                      />
                    </FormControl>
                  </div>

                  <div>
                   <Button variant="contained" color="success" startIcon={ <DoneIcon />}  onClick={submit_mpu_relocation_fees} style={{margin:"10px"}} >
                       update
                  </Button>
                  </div>
                </div>

                <div style={{ display: "flex", margin: "7px  0px  15px 10px" }}>
                  <div>
                    <FormControl variant="outlined" style={{marginBottom:"5px"}}>
                      <TextField
                        fullWidth
                        label="upgrade_electrical_panel"
                        name="price_to"
                        value={upgrade_electrical_panel_fees}
                        onChange={(e) => set_upgrade_electrical_panel_fees(e.target.value)}
                      />
                    </FormControl>
                  </div>

                  <div>
                      <Button variant="contained" color="success" startIcon={ <DoneIcon />}  onClick={submit_upgrade_electrical_panel_fees} style={{margin:"10px"}} >
                       update
                     </Button>
                  </div>
                </div>


                <div style={{ display: "flex", margin: "7px  0px  15px 10px" }}>
                  <div>
                    <FormControl variant="outlined" style={{marginBottom:"5px"}}>
                      <TextField
                        fullWidth
                        label="de_rate"
                        name="price_to"
                        value={de_rate_fees}
                        onChange={(e) => set_de_rate_fees(e.target.value)}
                      />
                    </FormControl>
                  </div>

                  <div>
                      <Button variant="contained" color="success" startIcon={ <DoneIcon />}  onClick={submit_de_rate_fees} style={{margin:"10px"}} >
                       update
                     </Button>
                  </div>
                </div>


                <div style={{ display: "flex", margin: "7px  0px  15px 10px" }}>
                  <div>
                    <FormControl variant="outlined" style={{marginBottom:"5px"}}>
                      <TextField
                        fullWidth
                        label="sub_panel"
                        name="price_to"
                        value={sub_panel_fees}
                        onChange={(e) => set_sub_panel_fees(e.target.value)}
                      />
                    </FormControl>
                  </div>

                  <div>
                    <Button variant="contained" color="success" startIcon={ <DoneIcon />}   onClick={submit_sub_panel_fees} style={{margin:"10px"}} >
                       update
                     </Button>
                  </div>
                </div>

                <div style={{ display: "flex", margin: "7px  0px  15px 10px" }}>
                  <div>
                    <FormControl variant="outlined" style={{marginBottom:"5px"}}>
                      <TextField
                        fullWidth
                        label="meter_socket"
                        name="price_to"
                        value={meter_socket}
                        onChange={(e) => set_meter_socket(e.target.value)}
                      />
                    </FormControl>
                  </div>

                  <div>
                    <Button variant="contained" color="success" startIcon={ <DoneIcon />}  onClick={submit_meter_socket} style={{margin:"10px"}} >
                       update
                     </Button>
                  </div>
                </div>

                <div style={{ display: "flex", margin: "7px  0px  15px 10px" }}>
                  <div>
                    <FormControl variant="outlined" style={{marginBottom:"5px"}}>
                      <TextField
                        fullWidth
                        label="ev_charger"
                        name="price_to"
                        value={ev_charger}
                        onChange={(e) => set_ev_charger(e.target.value)}
                      />
                    </FormControl>
                  </div>

                  <div>
                     <Button variant="contained" color="success" startIcon={ <DoneIcon />} onClick={submit_ev_charger} style={{margin:"10px"}} >
                       update
                     </Button>
                  </div>
                </div>

                <div style={{ display: "flex", margin: "7px  0px  15px 10px" }}>
                  <div>
                    <FormControl variant="outlined" style={{marginBottom:"5px"}}>
                      <TextField
                        fullWidth
                        label="ev_mlo"
                        name="price_to"
                        value={ev_mlo}
                        onChange={(e) => set_ev_mlo(e.target.value)}
                      />
                    </FormControl>
                  </div>

                  <div>
                    
                 <Button variant="contained" color="success" startIcon={ <DoneIcon />}  onClick={submit_ev_mlo} style={{margin:"10px"}} >
                       update
                     </Button>
                  </div>
                </div>

                <div style={{ display: "flex", margin: "7px  0px  15px 10px" }}>
                  <div>
                    <FormControl variant="outlined" style={{marginBottom:"5px"}}>
                      <TextField
                        fullWidth
                        label="designated_plugs"
                        name="price_to"
                        value={designated_plugs}
                        onChange={(e) => set_designated_plugs(e.target.value)}
                      />
                    </FormControl>
                  </div>

                  <div>
                 

<Button variant="contained" color="success" startIcon={ <DoneIcon />}  onClick={submit_designated_plugs} style={{margin:"10px"}} >
                       update
                     </Button>
                  </div>
                </div>

                <div style={{ display: "flex", margin: "7px  0px  15px 10px" }}>
                  <div>
                    <FormControl variant="outlined" style={{marginBottom:"5px"}}>
                      <TextField
                        fullWidth
                        label="ducting"
                        name="price_to"
                        value={ducting}
                        onChange={(e) => set_ducting(e.target.value)}
                      />
                    </FormControl>
                  </div>

                  <div>
                  
                      <Button variant="contained" color="success" startIcon={ <DoneIcon />} onClick={submit_ducting}style={{margin:"10px"}}>
                       update
                     </Button>
                  </div>
                </div>

                <div style={{ display: "flex", margin: "7px  0px  15px 10px" }}>
                  <div>
                    <FormControl variant="outlined" style={{marginBottom:"5px"}}>
                      <TextField
                        fullWidth
                        label="led_lighting"
                        name="price_to"
                        value={led_lighting}
                        onChange={(e) => set_led_lighting(e.target.value)}
                      />
                    </FormControl>
                  </div>

                  <div>
                   

                  <Button variant="contained" color="success" startIcon={ <DoneIcon />}  onClick={submit_led_lighting} style={{margin:"10px"}}>
                       update
                     </Button>
                  </div>
                </div>

                <div style={{ display: "flex", margin: "7px  0px  15px 10px" }}>
                  <div>
                    <FormControl variant="outlined" style={{marginBottom:"5px"}}>
                      <TextField
                        fullWidth
                        label="energy_efficient"
                        name="price_to"
                        value={energy_efficient}
                        onChange={(e) => set_energy_efficient(e.target.value)}
                      />
                    </FormControl>
                  </div>

                  <div>
                  
                       <Button variant="contained" color="success" startIcon={ <DoneIcon />}   onClick={submit_energy_efficient} style={{margin:"10px"}}>
                       update
                     </Button>
                  </div>
                </div>

                <div style={{ display: "flex", margin: "7px  0px  15px 10px" }}>
                  <div>
                    <FormControl variant="outlined" style={{marginBottom:"5px"}}>
                      <TextField
                        fullWidth
                        label="solar_lip"
                        name="price_to"
                        value={solar_lip}
                        onChange={(e) => set_solar_lip(e.target.value)}
                      />
                    </FormControl>
                  </div>

                  <div>
                   

                  <Button variant="contained" color="success" startIcon={ <DoneIcon />}  onClick={submit_solar_lip} style={{margin:"10px"}}>
                       update
                     </Button>
                  </div>
                </div>

                <div style={{ display: "flex", margin: "7px  0px  15px 10px" }}>
                  <div>
                    <FormControl variant="outlined" style={{marginBottom:"5px"}}>
                      <TextField
                        fullWidth
                        label="bird_netting"
                        name="price_to"
                        value={bird_netting}
                        onChange={(e) => set_bird_netting(e.target.value)}
                      />
                    </FormControl>
                  </div>

                  <div>
                   
                     <Button variant="contained" color="success" startIcon={ <DoneIcon />}   onClick={submit_bird_netting} style={{margin:"10px"}}>
                       update
                     </Button>
                  </div>
                </div>

                <div style={{ display: "flex", margin: "7px  0px  15px 10px" }}>
                  <div>
                    <FormControl variant="outlined" style={{marginBottom:"5px"}}>
                      <TextField
                        fullWidth
                        label="trenching_dirt"
                        name="price_to"
                        value={trenching_dirt}
                        onChange={(e) => set_trenching_dirt(e.target.value)}
                      />
                    </FormControl>
                  </div>

                  <div>
                 
                     <Button variant="contained" color="success" startIcon={ <DoneIcon />}   onClick={submit_trenching_dirt} style={{margin:"10px"}}>
                       update
                     </Button>
                  </div>
                </div>

                <div style={{ display: "flex", margin: "7px  0px  15px 10px" }}>
                  <div>
                    <FormControl variant="outlined" style={{marginBottom:"5px"}}>
                      <TextField
                        fullWidth
                        label="trenching_concrete"
                        name="price_to"
                        value={trenching_concrete}
                        onChange={(e) => set_trenching_concrete(e.target.value)}
                      />
                    </FormControl>
                  </div>

                  <div>
                   

                 <Button variant="contained" color="success" startIcon={ <DoneIcon />}  onClick={submit_trenching_concrete} style={{margin:"10px"}}>
                       update
                     </Button>
                  </div>
                </div>

                <div style={{ display: "flex", margin: "7px  0px  15px 10px" }}>
                  <div>
                    <FormControl variant="outlined">
                      <TextField
                        fullWidth
                        label="warranty"
                        name="price_to"
                        value={warranty}
                        onChange={(e) => set_warranty(e.target.value)}
                      />
                    </FormControl>
                  </div>

                  <div>
                  

                    <Button variant="contained" color="success" startIcon={ <DoneIcon />} onClick={submit_warranty} style={{margin:"10px"}}>
                       update
                     </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
          {all_adders?.addersTable == null && (
            <Grid style={{ padding: "20px" }}>
              <Typography align="center">
                There is no adders data for this ticket
              </Typography>
            </Grid>
          )}
        </>
        </Box>
        </MainTabsStyled>
     
      )}
    </>
  );
}
const MainTabsStyled = styled.div`
  margin: 1rem 0.8rem;
  border-radius: 5px;

`;
export default AddersTable;
