import styled from "styled-components";

export const ImageController = styled.img`
  width: ${(props) => props.width};
  height: ${(props) => props.h};
  object-fit: ${(props) => props.objectFit};
  object-position: ${(props) => props.objectPosition};
  filter: drop-shadow(${(props) => props.drop});
  margin: ${(props) => props.m};
  border-radius: ${(props) => props.br};
`;
