import React, { useState } from "react";
import {
  Grid,
  FormControl,
  IconButton,
  Button,
  Typography,
  ListItemText,
  ListItem,
} from "@mui/material";
import styled from "styled-components";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloseIcon from "@mui/icons-material/Close";
import { PrimaryButton } from ".././../Layouts/ButtonsControlled";
import FolderIcon from "@mui/icons-material/Folder";
import DeleteIcon from "@mui/icons-material/Delete";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { Add } from "@mui/icons-material";
import {postUploadFiles } from "../../Redux/Slices/UploadFiles/UploadFilesSlice";
import {
  get_pto_data,
  get_NTP_data,
  get_meter_spot_data,
  get_quotes_for_products_data,
  get_product_orders_data,
  get_permit_to_finance_data,
  get_order_place_cards_data,
  get_coordinate_product_delivery_data,
  get_coordinate_installations_data,
  get_schedule_inspections_data,
  get_installer_invoices_data,
  get_upload_to_pto_data,
  get_send_full_details_to_client_data,
  get_review_profit_loss_statement_data,
  get_closure_report_data,
  get_installation_payment_collection_data,
  get_installer_commissions_data,
  get_installation_pics_data,
  get_confirm_inspection_data,
  get_apply_permits_data,
  get_end_project_call_data,
  get_upload_PTO_to_finance_data,
  get_final_job_card_data,
  get_final_inspection_data,
  get_schedule_with_city_office_data,
  get_inform_inspection_result_data,
  get_monitoring_optimizers_data,
  get_m1_collection_data,
  get_m2_collection_data
} from "../../Redux/Slices/CardsStatusSlics/CardStepsSlice";
import { useDispatch } from "react-redux";
const UploadMediaForCard = ({
  issue,
  selectedIssue,
  lead_id,
  type,
  cardStatus,
  MAX_COUNT,
}) => {
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  const [showUpload, setShowUpload] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [UpdatedUploadFiles, setUpdatedUploadedFiles] = useState([]);
  const [fileLimit, setFileLimit] = useState(false);
  const [reqdaataType , setReqDataType] = useState("")
  const handlecloseUpload = () => {
    setShowUpload(false);
  };

  //apply-pto
  const handleShowUploadFiles = (id) => {
    setShowUpload(true);
    
    switch (type) {
      case "ntp_finance_portal":
          setReqDataType("ntps");
        break;
        case "apply_pto":
          setReqDataType("apply-pto");
        break;
        case "upload_permit_to_finance":
          setReqDataType("permit-to-finance");
        break;
        case "order_placards":
          setReqDataType("order-place-cards");
        break;
        case "coordinate_product_delivery":
          setReqDataType("coordinate-product-delivery");
        break;
        case "coordinate_client_installer_installation":
          setReqDataType("coordinate-installations");
        break;
        case "order_product":
          setReqDataType("product-orders");
        break;
        case "schedule_inspection":
          setReqDataType("schedule-inspections");
        break;
        case "apply_all_required_permits":
          setReqDataType("perimts");
        break;
        case "products_quotes":
          setReqDataType("quotes-for-products");
        break;
        case "apply_meter_spot":
          setReqDataType("meter-spots");
        break;
        case "m1_collection":
          setReqDataType("m1-collection");
        break;
        case "m2_payment_collection":
          setReqDataType("m2-collection");
        break;
        case "upload_installation_pics_to_finance":
          setReqDataType("installation-pics");
        break;
        case "monitoring_optimizer":
          setReqDataType("monitoring-optimizers");
        break;
        case "confirm_city_office_time_frame":
          setReqDataType("schedule-with-city-office");
        break;
        case "invoice_for_installer":
          setReqDataType("installer-invoices");
        break;
        case "final_job_card":
          setReqDataType("finial-job-card");
        break;
        case "closure_report":
          setReqDataType("closure-report");
        break;
        case "end_project_call":
          setReqDataType("end-project-call");
        break;
        case "send_full_details_to_client":
          setReqDataType("send-full-details-to-client");
        break;
        case "pto_to_finance":
          setReqDataType("upload-pto-to-finance");
        break;
        case "confirm_installer_inspection":
          setReqDataType("confirm-inspections");
        break;
        case "final_inspection":
          setReqDataType("final-inspection");
        break;
        
        
      default:
          setReqDataType("test")
        break;
    }
  };


  
  console.log(uploadedFiles, UpdatedUploadFiles, "uploadedFiles");
  const handleUploadFiles = (files) => {
    const uploaded = [...UpdatedUploadFiles];
    let limitExceeded = false;
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        setUpdatedUploadedFiles(uploaded);
        if (uploaded.length === MAX_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_COUNT) {
          alert(`You can only add a maximum of ${MAX_COUNT} files`);
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    });
    if (!limitExceeded) {
      setUploadedFiles(uploaded);
    }
  };

  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };

    const handleRemoveItem = (file) => {
      const name = UpdatedUploadFiles.map((file) => file.name);
      setUpdatedUploadedFiles(
        UpdatedUploadFiles.filter((item) => item.name !== file.name)
      );
      console.log(name, "name");
      console.log(UpdatedUploadFiles, "UpdatedUploadFilesssssssss");
    };


  const handle_upload_file = async () => {

    const formData = new FormData();
    console.log(reqdaataType , "reqdaataType")
    await  formData.append("type", reqdaataType);
    await  formData.append("id", cardStatus?.cardStatus?.data?.id);
    await  uploadedFiles.map((e, i) => {
      formData.append(`media[${i}]`, e);
    });

    if (type == "ntp_finance_portal") {
      let submitAction = postUploadFiles;
      await dispatch(submitAction(formData ));
      await  dispatch(get_NTP_data(lead_id));
    } 
    else if (type == "apply_pto") {
      let submitAction = postUploadFiles;
      await dispatch(submitAction(formData ));
     await dispatch(get_pto_data(lead_id));
    }
    else if (type == "upload_permit_to_finance") {
      let submitAction = postUploadFiles;
      await dispatch(submitAction(formData ));
     await dispatch(get_permit_to_finance_data(lead_id));
    }
    else if (type == "order_placards") {
      let submitAction = postUploadFiles;
      await dispatch(submitAction(formData ));
     await dispatch(get_order_place_cards_data(lead_id));
    }
    else if (type == "coordinate_product_delivery") {
      let submitAction = postUploadFiles;
      await dispatch(submitAction(formData ));
     await dispatch(get_coordinate_product_delivery_data(lead_id));
    }
    else if (type == "coordinate_client_installer_installation") {
      let submitAction = postUploadFiles;
      await dispatch(submitAction(formData ));
     await dispatch(get_coordinate_installations_data(lead_id));
    }
    else if (type == "order_product") {
      let submitAction = postUploadFiles;
      await dispatch(submitAction(formData ));
     await dispatch(get_product_orders_data(lead_id));
    }
    else if (type == "schedule_inspection") {
      let submitAction = postUploadFiles;
      await dispatch(submitAction(formData ));
     await dispatch(get_schedule_inspections_data(lead_id));
    }
    else if (type == "apply_all_required_permits") {
      let submitAction = postUploadFiles;
      await dispatch(submitAction(formData ));
     await dispatch(get_apply_permits_data(lead_id));
    }
    else if (type == "products_quotes") {
      let submitAction = postUploadFiles;
      await dispatch(submitAction(formData ));
     await dispatch(get_quotes_for_products_data(lead_id));
    }
    else if (type == "apply_meter_spot") {
      let submitAction = postUploadFiles;
      await dispatch(submitAction(formData ));
     await dispatch(get_meter_spot_data(lead_id));
    }
    else if (type == "m1_collection") {
      let submitAction = postUploadFiles;
      await dispatch(submitAction(formData ));
     await dispatch(get_m1_collection_data(lead_id));
    }
    else if (type == "m2_payment_collection") {
      let submitAction = postUploadFiles;
      await dispatch(submitAction(formData ));
     await dispatch(get_m2_collection_data(lead_id));
    }
    else if (type == "upload_installation_pics_to_finance") {
      let submitAction = postUploadFiles;
      await dispatch(submitAction(formData ));
     await dispatch(get_installation_pics_data(lead_id));
    }
    else if (type == "monitoring_optimizer") {
      let submitAction = postUploadFiles;
      await dispatch(submitAction(formData ));
     await dispatch(get_monitoring_optimizers_data(lead_id));
    }
    else if (type == "confirm_city_office_time_frame") {
      let submitAction = postUploadFiles;
      await dispatch(submitAction(formData ));
     await dispatch(get_schedule_with_city_office_data(lead_id));
    }
    else if (type == "invoice_for_installer") {
      let submitAction = postUploadFiles;
      await dispatch(submitAction(formData ));
     await dispatch(get_installer_invoices_data(lead_id));
    }
    else if (type == "final_job_card") {
      let submitAction = postUploadFiles;
      await dispatch(submitAction(formData ));
     await dispatch(get_final_job_card_data(lead_id));
    }
    else if (type == "closure_report") {
      let submitAction = postUploadFiles;
      await dispatch(submitAction(formData ));
     await dispatch(get_closure_report_data(lead_id));
    }
    else if (type == "end_project_call") {
      let submitAction = postUploadFiles;
      await dispatch(submitAction(formData ));
     await dispatch(get_end_project_call_data(lead_id));
    }
    else if (type == "send_full_details_to_client") {
      let submitAction = postUploadFiles;
      await dispatch(submitAction(formData ));
     await dispatch(get_send_full_details_to_client_data(lead_id));
    }
    else if (type == "pto_to_finance") {
      let submitAction = postUploadFiles;
      await dispatch(submitAction(formData ));
     await dispatch(get_upload_PTO_to_finance_data(lead_id));
    }
    else if (type == "confirm_installer_inspection") {
      let submitAction = postUploadFiles;
      await dispatch(submitAction(formData ));
     await dispatch(get_confirm_inspection_data(lead_id));
    }
    else if (type == "final_inspection") {
      let submitAction = postUploadFiles;
      await dispatch(submitAction(formData ));
     await dispatch(get_final_inspection_data(lead_id));
    }
    // await setOpenEditForm(false);
  };
  return (
    <>
      <IconButton onClick={() => handleShowUploadFiles(selectedIssue)}>
        <Typography>
          <span style={{ color: "black", fontWeight: "bold" }}>
            Upload Files
          </span>{" "}
        </Typography>{" "}
        {!showUpload && <CloudUploadIcon style={{ marginLeft: "30px" }} />}
      </IconButton>
      {showUpload && (
        <IconButton onClick={handlecloseUpload}>
          <CloseIcon />
        </IconButton>
      )}

      {showUpload && (
        <Grid xs={12}>
          <Grid
            className="display"
            mt={4}
            // style={{ display: "flex", justifyContent: "center" }}
          >
            <FormControl variant="outlined">
              <label htmlFor="contained-button-file">
                <Input
                  accept="image/*,video/*"
                  id="contained-button-file"
                  multiple
                  type="file"
                  // value={utiltyBill.media}
                  onChange={handleFileEvent}
                />
                <Button component="span" className="btn_upload">
                  <span className="icon">
                    <Add />
                  </span>
                  <span className="title"> Upload Files</span>
                </Button>
              </label>
              {/* </Grid> */}
            </FormControl>
            {UpdatedUploadFiles.map((file, index) => (
              <ListItem
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleRemoveItem(file)}
                  >
                    <DeleteIcon />
                  </IconButton>
                }
              >
                <ListItemAvatar style={{ display: "flex" }}>
                  <FolderIcon />
                  <Typography>{file.name}</Typography>{" "}
                </ListItemAvatar>
                <ListItemText
                // primary={file.image}
                // secondary={"Secondary text"}
                />
              </ListItem>
            ))}
            <Grid className="display" mt={4}>
              <PrimaryButton
                fullWidth
                style={{
                  padding: "0",
                  width: "85%",
                  margin: "15px auto",
                  height: "42px",
                }}
                className="saveButton"
               onClick={handle_upload_file}
              >
                Save
              </PrimaryButton>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};
const Input = styled.input`
  display: none;
`;
export default UploadMediaForCard;
