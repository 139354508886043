import axios from "axios";
import { installer_list } from "../../../Api/Api";

const Get_InstallersList_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${installer_list}?state_id=${data.state_id}&lat=${data.lat}&lng=${data.lng}`,
    headers: headers,
  });
  return response.data;
};

const Get_Installer_data_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${installer_list}/${data.installer_id}`,
    headers: headers,
    params: {
      state_id: data.state_id,
    },
  });
  return response.data;
};

const Installers_Service = {
  Get_InstallersList_Action,
  Get_Installer_data_Action,
};
export default Installers_Service;
