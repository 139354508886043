import React from "react";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import styled from "styled-components";

const JobCardDetails = () => {
  const single_ticket = useSelector((state) => state.single_ticket);
  console.log(
    single_ticket?.singleTicket?.data?.opportunity,
    "single ticket in solution"
  );
  const opportunityData = single_ticket?.singleTicket?.data?.opportunity;
  const NumberOfPanelsNeeded = Math.ceil(
    opportunityData?.annual_kwh_usage /
      365 /
      4.2 /
      (opportunityData?.panel_wattage / 1000)
  );
  return (
    <>
      <div style={{ marginLeft: "20px", padding: "5px" }}>
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="baseline"
            columns={18}
            spacing={4}
            style={{ textAlign: "left" }}
          >
            <Grid item lg={18} md={18} sm={18} xs={18}>
              {/* <Heading> */}
              <p>Opportunity</p>
              {/* </Heading> */}
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <p>
                Opportunity date time:{" "}
                <Value>
                  {new Date(
                    opportunityData?.opportunity_date_time
                  ).toLocaleDateString("en-US", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  })}
                </Value>
              </p>
            </Grid>
            {opportunityData?.is_any_non_permitted_structures === 1 && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Is any non permitted structures:{" "}
                  <Value>
                    {Boolean(
                      opportunityData?.is_any_non_permitted_structures
                    ).toString()}
                  </Value>
                </p>
              </Grid>
            )}

            {/* <Grid item lg={6} md={6} sm={12} xs={12}>
              <p>
                Installation type:{" "}
                <Value>{opportunityData?.installation_type}</Value>
              </p>
            </Grid> */}
            {opportunityData?.battery_power?.length > 0 && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  battery power: <Value>{opportunityData?.battery_power}</Value>
                </p>
              </Grid>
            )}

            {/* <Grid item lg={6} md={6} sm={12} xs={12}>
              <p>
                Rate: <Value>{opportunityData?.rate}</Value>
              </p>
            </Grid> */}
            {/* 
{opportunityData?.existing_electrical_panel?.length > 0  && (
 <Grid item lg={6} md={6} sm={12} xs={12}>
 <p>
 existing electrical panel :{" "}
   <Value>{opportunityData?.existing_electrical_panel}</Value>
 </p>
</Grid>
            )} */}

            {/* <Grid item lg={6} md={6} sm={12} xs={12}>
              <p>
                avg annual bill:{" "}
                <Value>$ {opportunityData?.avg_annual_bill}</Value>
              </p>
            </Grid> */}

            {opportunityData?.hvac_horse_power?.length > 0 && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  hvac horse power :{" "}
                  <Value>{opportunityData?.hvac_horse_power}</Value>
                </p>
              </Grid>
            )}

            {/* <Grid item lg={6} md={6} sm={12} xs={12}>
              <p>
                Monthly cost:{" "}
                <Value>
                  $ {(opportunityData?.monthly_cost || 0.0).toFixed(2)}
                </Value>
              </p>
            </Grid> */}

            {opportunityData?.hvac_location?.length > 0 && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  hvac location :{" "}
                  <Value>{opportunityData?.hvac_location}</Value>
                </p>
              </Grid>
            )}
            {/* <Grid item lg={6} md={6} sm={12} xs={12}>
              <p>
                Aannual increases Percentage:{" "}
                <Value> {opportunityData?.annual_increases_percentage}%</Value>{" "}
              </p>
            </Grid> */}

            {opportunityData?.installation_type?.length > 0 && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  installation type :{" "}
                  <Value>{opportunityData?.installation_type}</Value>
                </p>
              </Grid>
            )}
            {/* <Grid item lg={6} md={6} sm={12} xs={12}>
              <p>
                Roof Materials: <Value>{opportunityData?.roof_materials}</Value>
              </p>
            </Grid> */}

            {opportunityData?.inverter_type?.length > 0 && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  inverter type :{" "}
                  <Value>{opportunityData?.inverter_type}</Value>
                </p>
              </Grid>
            )}
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <p>
                is any non permitted structures:
                <Value>
                  {opportunityData?.is_any_non_permitted_structures == 0
                    ? "No"
                    : "YES"}
                </Value>
              </p>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <p>
                is_attic:{" "}
                <Value>{opportunityData?.is_attic == 0 ? " NO" : " YES"}</Value>
              </p>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <p>
                Is air vent:{" "}
                <Value> {opportunityData?.air_vent == 0 ? "NO" : "YES"} </Value>
              </p>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <p>
                {" "}
                annual kwh usage:{" "}
                <Value>{opportunityData?.annual_kwh_usage}</Value>
              </p>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <p>
                {" "}
                Panel wattage: <Value>{opportunityData?.panel_wattage}</Value>
              </p>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <p>
                {" "}
                Panels: <Value>{opportunityData?.number_of_panels}</Value>
              </p>
            </Grid>
            {opportunityData?.pdf_path && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Pdf path:{" "}
                  <PdfButton
                    target="_blank"
                    onClick={() => {
                      window.open(`${opportunityData?.pdf_path}`);
                    }}
                  >
                    <Value> open Pdf</Value>
                  </PdfButton>{" "}
                </p>
              </Grid>
            )}

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <p>
                Status: <Value>{opportunityData?.status}</Value>
              </p>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <p>
                Job type: <Value>{opportunityData?.job_type}</Value>
              </p>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <p>
                slope degree : <Value>{opportunityData?.slope_degree}</Value>
              </p>
            </Grid>

            {/* {opportunityData?.number_of_solar_arrays === 0 ? (
              <span style={{ display: "hidden" }}></span>
            ) : (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Added/(Subtracted Panels):{" "}
                  <Value>{opportunityData?.number_of_solar_arrays} </Value>{" "}
                  Panels
                </p>
              </Grid>
            )} */}

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <p>
                shading :<Value>{opportunityData?.shading}</Value>
              </p>
            </Grid>
            {opportunityData?.is_attic === 1 && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Is attic:{" "}
                  <Value>{Boolean(opportunityData?.is_attic).toString()}</Value>
                </p>
              </Grid>
            )}

            {/* {opportunityData?.number_of_conduit_arrays === 0 ? (
              <span style={{ display: "hidden" }}></span>
            ) : (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Number of attic arrays: <Value>{NumberOfPanelsNeeded}</Value>
                </p>
              </Grid>
            )} */}

            {/* <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Upgrade elctrical panel:{" "}
                  <Value>{opportunityData?.upgrade_electrical_panel} </Value>
                </p>
              </Grid>   */}
            {opportunityData?.is_reverse_tilt === 1 && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Is reverse tilt:{" "}
                  <Value>
                    {Boolean(opportunityData?.is_reverse_tilt).toString()}
                  </Value>
                </p>
              </Grid>
            )}

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <p>
                utility provider :
                <Value>{opportunityData?.utility_provider?.title}</Value>
              </p>
            </Grid>

            {opportunityData?.is_north_faced === 1 && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Is north faced:{" "}
                  <Value>
                    {Boolean(opportunityData?.is_north_faced).toString()}
                  </Value>
                </p>
              </Grid>
            )}
            {opportunityData?.two_story_home === 1 && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Two story home:{" "}
                  <Value>
                    {Boolean(opportunityData?.two_story_home).toString()}{" "}
                  </Value>
                </p>
              </Grid>
            )}

            {/* {opportunityData?.is_online_monitoring === 1 && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Is online monitoring:{" "}
                  <Value>
                    {Boolean(opportunityData?.is_online_monitoring).toString()}
                  </Value>
                </p>
              </Grid>
            )} */}

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <p>
                Inverter type: <Value>{opportunityData?.inverter_type}</Value>
              </p>
            </Grid>
            {opportunityData?.no_of_ft_from_main_service === 0 ? (
              <span style={{ display: "hidden" }}></span>
            ) : (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Number of ft from main service:{" "}
                  <Value>{opportunityData?.no_of_ft_from_main_service}</Value>
                </p>
              </Grid>
            )}

            {/* {opportunityData?.how_many_ft_trench_dirt === 0 ? (
              <span style={{ display: "hidden" }}></span>
            ) : (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  How many ft trench dirt:{" "}
                  <Value>{opportunityData?.how_many_ft_trench_dirt}</Value>
                </p>
              </Grid>
            )} */}

            {opportunityData?.is_trenching_dirt === 1 && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Is trenching dirt:{" "}
                  <Value>
                    {Boolean(opportunityData?.is_trenching_dirt).toString()}
                  </Value>
                </p>
              </Grid>
            )}
            {opportunityData?.how_many_ft_trench_concrete === 0 ? (
              <span style={{ display: "hidden" }}></span>
            ) : (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  How many ft trench concrete:{" "}
                  <Value>{opportunityData?.how_many_ft_trench_concrete}</Value>
                </p>
              </Grid>
            )}

            {opportunityData?.is_trenching_concrete === 1 && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Is trenching concrete{" "}
                  <Value>
                    {" "}
                    {Boolean(
                      opportunityData?.is_trenching_concrete
                    ).toString()}{" "}
                  </Value>
                </p>
              </Grid>
            )}

            {opportunityData?.digging_slope === 1 && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Digging slope:{" "}
                  <Value>
                    {" "}
                    {Boolean(opportunityData?.digging_slope).toString()}
                  </Value>
                </p>
              </Grid>
            )}
            {opportunityData?.slope_degree === 0 ? (
              <span style={{ display: "hidden" }}></span>
            ) : (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Slope degree: <Value>{opportunityData?.slope_degree}</Value>
                </p>
              </Grid>
            )}
            {opportunityData?.how_many_digging_ft === 0 ? (
              <span style={{ display: "hidden" }}></span>
            ) : (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  How many digging ft:{" "}
                  <Value>{opportunityData?.how_many_digging_ft}</Value>{" "}
                </p>
              </Grid>
            )}

            {opportunityData?.is_separate_electrical_meter === 1 && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Is separate electrical meter:{" "}
                  <Value>
                    {Boolean(
                      opportunityData?.is_separate_electrical_meter
                    ).toString()}
                  </Value>
                </p>
              </Grid>
            )}
            {opportunityData?.is_electric_work_currently_on_garage === 1 && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Is electric work currently on garage:{" "}
                  <Value>
                    {Boolean(
                      opportunityData?.is_electric_work_currently_on_garage
                    ).toString()}
                  </Value>
                </p>
              </Grid>
            )}
            {opportunityData?.is_sub_panel_on_detached_garage === 1 && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Is sub panel on detached garage:{" "}
                  <Value>
                    {Boolean(
                      opportunityData?.is_sub_panel_on_detached_garage
                    ).toString()}
                  </Value>
                </p>
              </Grid>
            )}

            {opportunityData?.finalMonthlyPayment === null ? (
              <span style={{ display: "hidden" }}></span>
            ) : (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Final monthly payment:{" "}
                  <Value>$ {opportunityData?.finalMonthlyPayment}</Value>
                </p>
              </Grid>
            )}

            {opportunityData?.finalEscalatedMonthlyPayment === null ? (
              <span style={{ display: "hidden" }}></span>
            ) : (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Final Escalated Monthly Payment:{" "}
                  <Value>
                    $ {opportunityData?.finalEscalatedMonthlyPayment}
                  </Value>
                </p>
              </Grid>
            )}
            {opportunityData?.monthlyPayment === null ? (
              <span style={{ display: "hidden" }}></span>
            ) : (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Monthly payment:{" "}
                  <Value>$ {opportunityData?.monthlyPayment}</Value>
                </p>
              </Grid>
            )}

            {opportunityData?.escalatedMonthlyPayment === null ? (
              <span style={{ display: "hidden" }}></span>
            ) : (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Escalated monthly payment:{" "}
                  <Value>$ {opportunityData?.escalatedMonthlyPayment}</Value>
                </p>
              </Grid>
            )}

            {opportunityData?.paydownPercentage === 0 ? (
              <span style={{ display: "hidden" }}></span>
            ) : (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Paydown Percentage:{" "}
                  <Value>{opportunityData?.paydownPercentage}%</Value>
                </p>
              </Grid>
            )}
            {opportunityData?.alternateMonthlyPayment === null ? (
              <span style={{ display: "hidden" }}></span>
            ) : (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Alternate monthly payment:{" "}
                  <Value>{opportunityData?.alternateMonthlyPayment}</Value>
                </p>
              </Grid>
            )}

            {opportunityData?.is_add_on === 1 && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Is add on:{" "}
                  <Value>
                    {Boolean(opportunityData?.is_add_on).toString()}
                  </Value>
                </p>
              </Grid>
            )}

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <p>
                System size: <Value>{opportunityData?.system_size} k.W</Value>
              </p>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <p>
                system size ac :{" "}
                <Value>{opportunityData?.system_size_ac}</Value>
              </p>
            </Grid>

            {opportunityData?.is_finance === 1 && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Is finance:{" "}
                  <Value>
                    {Boolean(opportunityData?.is_finance).toString()}
                  </Value>
                </p>
              </Grid>
            )}
            {opportunityData?.is_sandbox === 1 && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Is sandbox:{" "}
                  <Value>
                    {Boolean(opportunityData?.is_sandbox).toString()}
                  </Value>
                </p>
              </Grid>
            )}

            {/* <Grid item lg={6} md={6} sm={12} xs={12}>
              <p>
                Term: <Value>{opportunityData?.loan_years} </Value>
              </p>
            </Grid> */}
            {/* <Grid item lg={6} md={6} sm={12} xs={12}>
              <p>
                Productivity offset:{" "}
                <Value>{opportunityData?.productivity_offset} %</Value>
              </p>
            </Grid> */}
            {/* <Grid item lg={6} md={6} sm={12} xs={12}>
              <p>
                Federal tax: <Value>$ {opportunityData?.federal_tax}</Value>
              </p>
            </Grid> */}
            {opportunityData?.apr === null ? (
              <span style={{ display: "hidden" }}></span>
            ) : (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Apr: <Value>{opportunityData?.apr} </Value>
                </p>
              </Grid>
            )}

            {opportunityData?.is_re_roofing === 1 && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Is re roofing:{" "}
                  <Value>
                    {Boolean(opportunityData?.is_re_roofing).toString()}{" "}
                  </Value>
                </p>
              </Grid>
            )}
            {opportunityData?.is_roof_overlay === 1 && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Is roof overlay:{" "}
                  <Value>
                    {Boolean(opportunityData?.is_roof_overlay).toString()}
                  </Value>
                </p>
              </Grid>
            )}
            {opportunityData?.is_roof_relay === 1 && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Is roof relay:{" "}
                  <Value>
                    {Boolean(opportunityData?.is_roof_relay).toString()}{" "}
                  </Value>
                </p>
              </Grid>
            )}
            {opportunityData?.is_re_roof_shingle === 1 && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Is re roof shingle:{" "}
                  <Value>
                    {Boolean(opportunityData?.is_re_roof_shingle).toString()}
                  </Value>
                </p>
              </Grid>
            )}

            {opportunityData?.is_re_roof_tear_off === 1 && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Is re roof tear off:{" "}
                  <Value>
                    {Boolean(opportunityData?.is_re_roof_tear_off).toString()}
                  </Value>
                </p>
              </Grid>
            )}

            {opportunityData?.is_re_roofing === 1 && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Is re roofing:{" "}
                  <Value>
                    {Boolean(opportunityData?.is_re_roofing).toString()}
                  </Value>
                </p>
              </Grid>
            )}

            {opportunityData?.number_of_layers === 0 ? (
              <span style={{ display: "hidden" }}></span>
            ) : (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Number of layers:{" "}
                  <Value>{opportunityData?.number_of_layers}</Value>
                </p>
              </Grid>
            )}

            {opportunityData?.is_re_roof_presidential === 1 && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  is re roof presidential:{" "}
                  <Value>
                    {Boolean(
                      opportunityData?.is_re_roof_presidential
                    ).toString()}
                  </Value>
                </p>
              </Grid>
            )}
            {opportunityData?.is_re_roof_spanish_tiles === 1 && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Is re roof spanish tiles:{" "}
                  <Value>
                    {Boolean(
                      opportunityData?.is_re_roof_spanish_tiles
                    ).toString()}
                  </Value>
                </p>
              </Grid>
            )}
            {opportunityData?.is_re_roof_flat_tiles === 1 && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Is re roof flat tiles:{" "}
                  <Value>
                    {Boolean(opportunityData?.is_re_roof_flat_tiles).toString()}{" "}
                  </Value>
                </p>
              </Grid>
            )}

            {opportunityData?.is_re_roof_re_decking === 1 && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Is re roof re decking:{" "}
                  <Value>
                    {Boolean(opportunityData?.is_re_roof_re_decking).toString()}
                  </Value>
                </p>
              </Grid>
            )}
            {opportunityData?.is_re_roof_fascia_board === 1 && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Is re roof fascia board:{" "}
                  <Value>
                    {Boolean(
                      opportunityData?.is_re_roof_fascia_board
                    ).toString()}
                  </Value>
                </p>
              </Grid>
            )}
            {opportunityData?.number_of_feet === 0 ? (
              <span style={{ display: "hidden" }}></span>
            ) : (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Number of feet:{" "}
                  <Value>{opportunityData?.number_of_feet}</Value>
                </p>
              </Grid>
            )}

            {opportunityData?.is_warranty_extend === 1 && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Is warranty extend:{" "}
                  <Value>
                    {Boolean(opportunityData?.is_warranty_extend).toString()}
                  </Value>
                </p>
              </Grid>
            )}
            {opportunityData?.is_air_vent === 1 && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Is air vent:{" "}
                  <Value>
                    {" "}
                    {Boolean(opportunityData?.is_air_vent).toString()}
                  </Value>
                </p>
              </Grid>
            )}
            {opportunityData?.air_vent === "" ? (
              <span style={{ display: "hidden" }}></span>
            ) : (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Air vent: <Value>{opportunityData?.air_vent}</Value>
                </p>
              </Grid>
            )}

            {opportunityData?.number_of_vents === 0 ? (
              <span style={{ display: "hidden" }}></span>
            ) : (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Number of vents:{" "}
                  <Value>{opportunityData?.number_of_vents}</Value>
                </p>
              </Grid>
            )}

            {opportunityData?.is_double_hand === 1 && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Is double hand:{" "}
                  <Value>
                    {Boolean(opportunityData?.is_double_hand).toString()}
                  </Value>
                </p>
              </Grid>
            )}
            {opportunityData?.is_smoke_detectors === 1 && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Is smoke detectors:{" "}
                  <Value>
                    {Boolean(opportunityData?.is_smoke_detectors).toString()}
                  </Value>
                </p>
              </Grid>
            )}
            {opportunityData?.number_of_smoke_detectors === 0 ? (
              <span style={{ display: "hidden" }}></span>
            ) : (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Number of smoke detectors:{" "}
                  <Value>{opportunityData?.number_of_smoke_detectors}</Value>
                </p>
              </Grid>
            )}
            {opportunityData?.number_of_re_roof_squares === 0 ? (
              <span style={{ display: "hidden" }}></span>
            ) : (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Number of re roof squares:{" "}
                  <Value>{opportunityData?.number_of_re_roof_squares}</Value>
                </p>
              </Grid>
            )}

            {opportunityData?.is_led_lighting === 1 && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Is led lightning:{" "}
                  <Value>
                    {Boolean(opportunityData?.is_led_lighting).toString()}
                  </Value>
                </p>
              </Grid>
            )}
            {opportunityData?.number_of_led_lighting === 0 ? (
              <span style={{ display: "hidden" }}></span>
            ) : (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Number of led lighting:{" "}
                  <Value>{opportunityData?.number_of_led_lighting}</Value>
                </p>
              </Grid>
            )}

            {opportunityData?.is_energy_efficient_plugs === 1 && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Is energy efficient plugs:{" "}
                  <Value>
                    {Boolean(
                      opportunityData?.is_energy_efficient_plugs
                    ).toString()}
                  </Value>
                </p>
              </Grid>
            )}
            {opportunityData?.is_solar_lip === 1 && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Is solar lip:{" "}
                  <Value>
                    {Boolean(opportunityData?.is_solar_lip).toString()}
                  </Value>
                </p>
              </Grid>
            )}
            {opportunityData?.number_of_solar_lip === 0 ? (
              <span style={{ display: "hidden" }}></span>
            ) : (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Number of solar lip:{" "}
                  <Value>{opportunityData?.number_of_solar_lip}</Value>
                </p>
              </Grid>
            )}

            {opportunityData?.is_bird_netting === 1 && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Is bird netting:{" "}
                  <Value>
                    {Boolean(opportunityData?.is_bird_netting).toString()}
                  </Value>
                </p>
              </Grid>
            )}
            {opportunityData?.number_of_bird_netting === 0 ? (
              <span style={{ display: "hidden" }}></span>
            ) : (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Number of bird netting:{" "}
                  <Value>{opportunityData?.number_of_bird_netting}</Value>
                </p>
              </Grid>
            )}

            {opportunityData?.is_battery === 1 && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Is battery:{" "}
                  <Value>
                    {Boolean(opportunityData?.is_battery).toString()}
                  </Value>
                </p>
              </Grid>
            )}
            {opportunityData?.battery_power === "" ? (
              <span style={{ display: "hidden" }}></span>
            ) : (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Battery power: <Value>{opportunityData?.battery_power}</Value>
                </p>
              </Grid>
            )}

            {opportunityData?.is_warranty_package === 1 && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Is warranty package:{" "}
                  <Value>
                    {Boolean(opportunityData?.is_warranty_package).toString()}
                  </Value>
                </p>
              </Grid>
            )}
            {opportunityData?.warranty === "" ? (
              <span style={{ display: "hidden" }}></span>
            ) : (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Warranty: <Value> {opportunityData?.warranty}</Value>
                </p>
              </Grid>
            )}

            {opportunityData?.is_hvac === 1 && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Is hvac:{" "}
                  <Value>{Boolean(opportunityData?.is_hvac).toString()}</Value>
                </p>
              </Grid>
            )}
            {opportunityData?.hvac_location === "" ? (
              <span style={{ display: "hidden" }}></span>
            ) : (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Hvac location: <Value>{opportunityData?.hvac_location}</Value>
                </p>
              </Grid>
            )}
            {opportunityData?.hvac_horse_power === "" ? (
              <span style={{ display: "hidden" }}></span>
            ) : (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  hvac horse power:{" "}
                  <Value>{opportunityData?.hvac_horse_power}</Value>
                </p>
              </Grid>
            )}

            {opportunityData?.is_ducting === 1 && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Is ducting:{" "}
                  <Value>
                    {Boolean(opportunityData?.is_ducting).toString()}
                  </Value>
                </p>
              </Grid>
            )}
            {opportunityData?.number_of_ducting_run === 0 ? (
              <span style={{ display: "hidden" }}></span>
            ) : (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Number of ducting run:{" "}
                  <Value>{opportunityData?.number_of_ducting_run}</Value>
                </p>
              </Grid>
            )}

            {opportunityData?.is_standalone_roof_mount === 1 && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Is standalone roof mount{" "}
                  <Value>
                    {Boolean(
                      opportunityData?.is_standalone_roof_mount
                    ).toString()}
                  </Value>
                </p>
              </Grid>
            )}

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <p>
                MSP:{" "}
                <Value>{opportunityData?.existing_electrical_panel} A</Value>
              </p>
            </Grid>
            {/* {Number(opportunityData?.upgrade_electrical_panel) == 0 ? (
              <span style={{ display: "hidden" }}></span>
            ) : ( */}
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <p>
                Upgrade elctrical panel:{" "}
                <Value>{opportunityData?.upgrade_electrical_panel} </Value>
              </p>
            </Grid>
            {/* // )} */}

            {opportunityData?.is_ev_charger === 1 && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Is ev charger:{" "}
                  <Value>
                    {Boolean(opportunityData?.is_ev_charger).toString()}
                  </Value>
                </p>
              </Grid>
            )}
            {opportunityData?.is_meter_socket === 1 && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Is meter socket:{" "}
                  <Value>
                    {Boolean(opportunityData?.is_meter_socket).toString()}
                  </Value>
                </p>
              </Grid>
            )}
            {opportunityData?.is_ev_mlo === 1 && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Is ev mlo:{" "}
                  <Value>
                    {Boolean(opportunityData?.is_ev_mlo).toString()}
                  </Value>
                </p>
              </Grid>
            )}
            {opportunityData?.is_sub_panel === 1 && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Is sub Panel:{" "}
                  <Value>
                    {Boolean(opportunityData?.is_sub_panel).toString()}{" "}
                  </Value>
                </p>
              </Grid>
            )}
            {opportunityData?.is_mpu_relocation === 1 && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Is mpu relocation:{" "}
                  <Value>
                    {Boolean(opportunityData?.is_mpu_relocation).toString()}
                  </Value>
                </p>
              </Grid>
            )}
            {opportunityData?.number_of_mpu_relocation_foot === 0 ? (
              <span style={{ display: "hidden" }}></span>
            ) : (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Number of mpu relocation foot:{" "}
                  <Value>
                    {opportunityData?.number_of_mpu_relocation_foot}
                  </Value>
                </p>
              </Grid>
            )}

            {opportunityData?.is_de_rate === 1 && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Is de rate:{" "}
                  <Value>
                    {Boolean(opportunityData?.is_de_rate).toString()}
                  </Value>
                </p>
              </Grid>
            )}
            {opportunityData?.is_stucco === 1 && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  {" "}
                  Is stucco:{" "}
                  <Value>
                    {Boolean(opportunityData?.is_stucco).toString()}
                  </Value>
                </p>
              </Grid>
            )}
            {opportunityData?.is_rafter_exchange === 1 && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Is rafter exchange:{" "}
                  <Value>
                    {Boolean(opportunityData?.is_rafter_exchange).toString()}
                  </Value>
                </p>
              </Grid>
            )}
            {opportunityData?.number_hvac_ft === 0 ? (
              <span style={{ display: "hidden" }}></span>
            ) : (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Number hvac ft:{" "}
                  <Value>{opportunityData?.number_hvac_ft}</Value>
                </p>
              </Grid>
            )}
            {opportunityData?.number_of_battaries === null ? (
              <span style={{ display: "hidden" }}></span>
            ) : (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Number of batteries:{" "}
                  <Value>{opportunityData?.number_of_battaries}</Value>
                </p>
              </Grid>
            )}

            {opportunityData?.is_steep === 1 && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  {" "}
                  Is steep:{" "}
                  <Value>{Boolean(opportunityData?.is_steep).toString()}</Value>
                </p>
              </Grid>
            )}
            {opportunityData?.roof_number_of_sq === null ? (
              <span style={{ display: "hidden" }}></span>
            ) : (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Roof number of sq:{" "}
                  <Value> {opportunityData?.roof_number_of_sq}</Value>
                </p>
              </Grid>
            )}

            {/* {opportunityData?.has_contract === true ? (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Has contract:{" "}
                  <Value>
                    {Boolean(opportunityData?.has_contract).toString()}{" "}
                  </Value>
                </p>
              </Grid>
            ) : (
              <span style={{ display: "hidden" }}></span>
            )} */}

            {/* {opportunityData?.roof_area === "" ? (
              <div></div>
            ) : (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Roof Area: <Value>{opportunityData?.roof_area} sqft </Value>
                </p>
              </Grid>
            )} */}

            {opportunityData?.aerial_photo === "" ? (
              <div></div>
            ) : (
              <Grid item lg={18} md={18} sm={18} xs={18}>
                <img
                  style={{
                    width: "100%",
                    borderRadius: "2rem",
                    display: "block",
                    margin: "auto",
                  }}
                  src={opportunityData?.aerial_photo}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
};
const Value = styled.span`
  color: rgb(136, 136, 136);
`;
const PdfButton = styled.button`
  background-color: white;
  color: black;
  padding: 5px 8px;
  border-radius: 25px;
  cursor: pointer;
`;
export default JobCardDetails;
