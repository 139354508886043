import React, { useState } from "react";
import { reduxForm, Field } from "redux-form";
import { validate } from "../../../Common/Valiation/Validate";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Grid, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { PrimaryButton } from "../../../Layouts/ButtonsControlled";
import { ModalContent } from "../../Modals/PmAssignModal/PmAssignModalStyle";
import { getTicketStatus } from "../../../Redux/Slices/TicketStatus/TicketStatusSlice";
import { store_call_utility__companies } from "../../../Redux/Slices/CallUtilityCompany/CallUtilityCompanySlice";
import { update_utility_companies } from "../../../Redux/Slices/CallUtilityCompany/CallUtilityCompanySlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import MuiTextField from "../../../Common/muiTextField/MuiTextField";
import MuiSelectField from "../../../Common/MuiSelectField/MuiSelectField";

const CallUtilityCompanyModal = ({
  handleopenModal,
  handleCloseModal,
  open_CallUtilityCompany_Modal,
  set_Open_CallUtilityCompany_Modal,
  handleSubmit,
  open_to_edit,
  set_Open_Modal,
  updated_id,

  initialize,
}) => {
  const dispatch = useDispatch();
  const { lead_id } = useParams();
  const formNotify = useSelector((state) => state.form);

  console.log(formNotify, "formNotify");

  const call_utility_company = useSelector(
    (state) => state.call_utility_companie
  );
  let tecket_id = {
    ticket_id: lead_id,
  };

  let id = {
    id: updated_id,
  };

  const submit = async (values) => {
    console.log(values, "redux form values");
    open_to_edit
      ? await dispatch(
          update_utility_companies({
            ...values,
            ...tecket_id,
            ...id,
          })
        )
      : await dispatch(
          store_call_utility__companies({
            ...values,
            ...tecket_id,
          })
        );
    call_utility_company?.isSuccess &&
      (await set_Open_CallUtilityCompany_Modal(false));
    // call_utility_company?.isSuccess && (await set_Open_Modal(false));

    await dispatch(getTicketStatus(lead_id));
  };
  return (
    <Dialog
      maxWidth={"md"}
      fullWidth
      open={handleopenModal}
      onClose={handleCloseModal}
    >
      <DialogContent>
        <ModalContent>
          <Box>
            <form onSubmit={handleSubmit((values) => submit(values))}>
              <Grid item xs={12} sm={12} lg={12} style={{ padding: "10px" }}>
                <Typography variant="h5" style={{ margin: "10px" }}>
                  Call utility company
                </Typography>
              </Grid>
              <Grid
                container
                columns={12}
                style={{ padding: "15px" }}
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 5 }}
              >
                <Grid item xs={12} sm={12} lg={6}>
                  <Field
                    required
                    type="text"
                    name="process_of_acquiring_PTO"
                    multiline
                    rows={3}
                    component={MuiTextField}
                    label=" What is the process of acquiring PTO"
                  />
                </Grid>

                <Grid item xs={12} sm={12} lg={6}>
                  <Field
                    required
                    type="text"
                    name="speed_up_process"
                    multiline
                    rows={3}
                    component={MuiTextField}
                    label=" Is there an expedited method we could use  in order to
                help speed up the process."
                  />
                </Grid>
              </Grid>
              <Grid
                container
                columns={12}
                style={{ padding: "0px 15px" }}
                rowSpacing={0}
                columnSpacing={{ xs: 1, sm: 2, md: 5 }}
              >
                <Grid item xs={12} sm={12} lg={6}>
                  <Field
                    required
                    name="submit_for_interconnection"
                    component={MuiSelectField}
                    label="How to submit for interconnection?"
                  >
                    <MenuItem value="online">online </MenuItem>
                    <MenuItem value="email_or_portal">email or portal</MenuItem>
                    <MenuItem value="person/via_mail">
                      person or via_mail
                    </MenuItem>
                  </Field>
                </Grid>

                <Grid item xs={12} sm={12} lg={6}>
                  <Field
                    required
                    name="when_submit_for_interconnection"
                    component={MuiSelectField}
                    label="when submit for interconnection"
                  >
                    <MenuItem value="before_installation">
                      before installation{" "}
                    </MenuItem>
                    <MenuItem value="after_installation">
                      after installation
                    </MenuItem>
                  </Field>
                </Grid>
              </Grid>
              <Grid
                container
                columns={12}
                style={{ padding: "0px 15px" }}
                rowSpacing={0}
                columnSpacing={{ xs: 1, sm: 2, md: 5 }}
              >
                {formNotify?.UtilityCompanyForm?.values
                  ?.submit_for_interconnection == "online" && (
                  <Grid item xs={12} sm={12} lg={6}>
                    <Field
                      required
                      rows={1}
                      type="text"
                      name="submit_for_interconnection_link"
                      component={MuiTextField}
                      label="submit for interconnection link."
                    />
                  </Grid>
                )}
                {formNotify?.UtilityCompanyForm?.values
                  ?.when_submit_for_interconnection ==
                  "before_installation" && (
                  <Grid item xs={12} sm={12} lg={6}>
                    <Field
                      required
                      rows={1}
                      type="text"
                      name="before_installation_timeline_for_pto"
                      component={MuiTextField}
                      label="how long does it take from final inspection date for PTO
                  to be acquired."
                    />
                  </Grid>
                )}
              </Grid>

              <Grid
                container
                columns={12}
                style={{ padding: "0px 15px" }}
                rowSpacing={0}
                columnSpacing={{ xs: 1, sm: 2, md: 5 }}
              >
                <Grid item xs={12} sm={12} lg={6}>
                  <Field
                    required
                    type="text"
                    name="approval_time"
                    component={MuiTextField}
                    label="approval time."
                  />
                </Grid>

                <Grid item xs={12} sm={12} lg={6}>
                  <Field
                    required
                    rows={1}
                    type="number"
                    name="cost"
                    component={MuiTextField}
                    label="cost"
                  />
                </Grid>
              </Grid>

              <Grid
                container
                columns={12}
                style={{ padding: "0px 15px" }}
                rowSpacing={0}
                columnSpacing={{ xs: 1, sm: 2, md: 5 }}
              >
                <Grid item xs={12} sm={12} lg={6}>
                  <Field
                    required
                    type="text"
                    rows={2}
                    multiline
                    name="any_special_requirements"
                    component={MuiTextField}
                    label="Do they have any special requirements on plans?"
                  />
                </Grid>

                <Grid item xs={12} sm={12} lg={6}>
                  <Field
                    required
                    name="status"
                    component={MuiSelectField}
                    label="status"
                  >
                    <MenuItem value="pending">pending </MenuItem>
                    <MenuItem value="completed">completed</MenuItem>
                  </Field>
                </Grid>
              </Grid>
              <Grid
                container
                columns={12}
                style={{ padding: "0px 15px" }}
                rowSpacing={0}
                columnSpacing={{ xs: 1, sm: 2, md: 5 }}
              >
                <PrimaryButton
                  fullWidth
                  type="submit"
                  style={{
                    padding: "0",
                    width: "55%",
                    margin: "15px auto",
                    height: "42px",
                  }}
                  className="saveButton"
                >
                  {open_to_edit ? "Update" : "Save"}
                  {call_utility_company?.isLodaing ? (
                    <CircularProgress size={25} thickness={18} />
                  ) : null}
                </PrimaryButton>
              </Grid>
            </form>
          </Box>
        </ModalContent>
      </DialogContent>
    </Dialog>
  );
};

export default reduxForm({
  form: "UtilityCompanyForm",
  validate,
})(CallUtilityCompanyModal);
