import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import postProductAvailabilityService from "./ProductAvailabilityServices";

const initialState = {
  productAvailability: false,
  isError: false,
  isSuccess: false,
  isLodaing: false,
  massage: "",
};

export const postProductAvailability = createAsyncThunk(
  "product_availability/post",
  async (data, thunkAPI) => {
    try {
      return await postProductAvailabilityService.post_product_availability_Action(
        data
      );
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const get_ProductAvailability = createAsyncThunk(
  "ProductAvailability/get",
  async (id, thunkAPI) => {
    try {
      return await postProductAvailabilityService.get_product_availability_data(id);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);


export const updateProductAvailability = createAsyncThunk(
  "product_availability/post",
  async (data, thunkAPI) => {
    try {
      return await postProductAvailabilityService.update_product_availability_Action(
        data
      );
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const productAvailabilitySlice = createSlice({
  name: "productAvailability",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLodaing = false;
      state.massage = "";
    },
  },
  extraReducers: {
    //post product availability
    [postProductAvailability.pending]: (state) => {
      state.isLodaing = true;
    },
    [postProductAvailability.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.productAvailability = payload;
    },
    [postProductAvailability.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },

     //get productAvailability
     [get_ProductAvailability.pending]: (state) => {
      state.isLodaing = true;
    },
    [get_ProductAvailability.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.productAvailability = payload;
    },
    [get_ProductAvailability.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.productAvailability = null;
      state.massage = action.payload;
    },

     //edit product availability
     [updateProductAvailability.pending]: (state) => {
      state.isLodaing = true;
    },
    [updateProductAvailability.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.productAvailability = payload;
    },
    [updateProductAvailability.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
  },
});

export const { reset } = productAvailabilitySlice.actions;
export default productAvailabilitySlice.reducer;
