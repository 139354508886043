import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import IssuesService from "./Create_issue_Servise";
import { toast } from "react-toastify";
const initialState = {
  issues: {},
  isError: false,
  isSuccess: false,
  isLodaing: false,
  massage: "",
};

export const create_issue = createAsyncThunk(
  "create_issue/post",
  async (data, thunkAPI) => {
    try {
      return await IssuesService.Create_issue_Action(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      error?.response?.data?.errors?.map((err) =>
        toast.error(err.msg, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      );
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const update_issue = createAsyncThunk(
  "update_issue/post",
  async (data, thunkAPI) => {
    try {
      return await IssuesService.update_issue_Action(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      error?.response?.data?.errors?.map((err) =>
        toast.error(err.msg, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      );
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const getIssues = createAsyncThunk(
  "getIssues/get",
  async (data, thunkAPI) => {
    // const { id, type } = data;
    try {
      return await IssuesService.getAll_Issues_Action(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const Upload_issue = createAsyncThunk(
  "upload_issue/post",
  async (data, thunkAPI) => {
    try {
      return await IssuesService.upload_issue_Action(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const delete_Issues = createAsyncThunk(
  "Issues/delete",
  async (id, thunkAPI) => {
    // const { id, type } = data;
    try {
      return await IssuesService.Delete_issue_file_Action(id);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const create_issue_Slice = createSlice({
  name: "issues",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLodaing = false;
      state.massage = "";
    },
  },

  extraReducers: {
    //create_issue
    [create_issue.pending]: (state) => {
      state.isLodaing = true;
    },
    [create_issue.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.issues = payload;
    },
    [create_issue.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },


       //edit_issue
       [update_issue.pending]: (state) => {
        state.isLodaing = true;
      },
      [update_issue.fulfilled]: (state, { payload }) => {
        state.isLodaing = false;
        state.isSuccess = true;
        state.issues = payload;
      },
      [update_issue.rejected]: (state, action) => {
        state.isLodaing = false;
        state.isError = true;
        state.massage = action.payload;
      },

    //get_issue
    [getIssues.pending]: (state) => {
      state.isLodaing = true;
    },
    [getIssues.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.issues = payload;
    },
    [getIssues.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },

    //upload files for  issues
    [Upload_issue.pending]: (state) => {
      state.isLodaing = true;
    },
    [Upload_issue.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.issues = payload;
    },
    [Upload_issue.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },

    // delete uploaded files of issue
    [delete_Issues.pending]: (state) => {
      state.isLodaing = true;
    },
    [delete_Issues.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.issues = payload;
    },
    [delete_Issues.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
  },
});

export const { reset } = create_issue_Slice.actions;
export default create_issue_Slice.reducer;
