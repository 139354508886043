import React from "react";
import { Grid } from "@mui/material";
import { TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
function FifthCharterForm({ formik }) {
  return (
    <>
      <Grid
        container
        columns={12}
        spacing={5}
        justifyContent="center"
        mb={3}
        mt={3}
      >
        <Grid item xs={6} sm={5} lg={4}>
          <InputLabel htmlFor="component-simple">site survey cost $</InputLabel>
          <TextField
            fullWidth
            id="input-with-icon-textfield"
            name="site_survey_cost"
            value={formik.values.site_survey_cost}
            onChange={formik.handleChange}
            error={
              formik.touched.site_survey_cost &&
              Boolean(formik.errors.site_survey_cost)
            }
            helperText={
              formik.touched.site_survey_cost && formik.errors.site_survey_cost
            }
          />
        </Grid>
        <Grid item xs={6} sm={5} lg={4}>
          <InputLabel htmlFor="component-simple">permit fees cost $</InputLabel>
          <TextField
            fullWidth
            id="input-with-icon-textfield"
            name="permit_fees_cost"
            value={formik.values.permit_fees_cost}
            onChange={formik.handleChange}
            error={
              formik.touched.permit_fees_cost &&
              Boolean(formik.errors.permit_fees_cost)
            }
            helperText={
              formik.touched.permit_fees_cost && formik.errors.permit_fees_cost
            }
          />
        </Grid>
      </Grid>

      <Grid container columns={12} spacing={5} justifyContent="center" mb={3}>
        <Grid item xs={6} sm={5} lg={4}>
          <InputLabel htmlFor="component-simple">permit run cost $ </InputLabel>
          <TextField
            fullWidth
            id="input-with-icon-textfield"
            name="permit_run_cost"
            value={formik.values.permit_run_cost}
            onChange={formik.handleChange}
            error={
              formik.touched.permit_run_cost &&
              Boolean(formik.errors.permit_run_cost)
            }
            helperText={
              formik.touched.permit_run_cost && formik.errors.permit_run_cost
            }
          />
        </Grid>
        <Grid item xs={6} sm={5} lg={4}>
          <InputLabel htmlFor="component-simple">Roofing Price $</InputLabel>
          <TextField
            fullWidth
            id="input-with-icon-textfield"
            name="roofing_price"
            value={formik.values.roofing_price}
            onChange={formik.handleChange}
            error={
              formik.touched.roofing_price &&
              Boolean(formik.errors.roofing_price)
            }
            helperText={
              formik.touched.roofing_price && formik.errors.roofing_price
            }
          />
        </Grid>
      </Grid>

      <Grid container columns={12} spacing={5} justifyContent="center" mb={3}>
        <Grid item xs={6} sm={5} lg={4}>
          <InputLabel htmlFor="component-simple">MPU Fees $ </InputLabel>
          <TextField
            fullWidth
            id="input-with-icon-textfield"
            name="mpu_fees_cost"
            value={formik.values.mpu_fees_cost}
            onChange={formik.handleChange}
            error={
              formik.touched.mpu_fees_cost &&
              Boolean(formik.errors.mpu_fees_cost)
            }
            helperText={
              formik.touched.mpu_fees_cost && formik.errors.mpu_fees_cost
            }
          />
        </Grid>
        <Grid item xs={6} sm={5} lg={4}>
          <InputLabel htmlFor="component-simple">
            Solar Installation Fees $
          </InputLabel>
          <TextField
            fullWidth
            id="input-with-icon-textfield"
            name="solar_installation_fees_cost"
            value={formik.values.solar_installation_fees_cost}
            onChange={formik.handleChange}
            error={
              formik.touched.solar_installation_fees_cost &&
              Boolean(formik.errors.solar_installation_fees_cost)
            }
            helperText={
              formik.touched.solar_installation_fees_cost &&
              formik.errors.solar_installation_fees_cost
            }
          />
        </Grid>
      </Grid>

      <Grid container columns={12} spacing={5} justifyContent="center" mb={3}>
        <Grid item xs={6} sm={5} lg={4}>
          <InputLabel htmlFor="component-simple">PTO fees $</InputLabel>
          <TextField
            fullWidth
            id="input-with-icon-textfield"
            name="pto_fees_cost"
            value={formik.values.pto_fees_cost}
            onChange={formik.handleChange}
            error={
              formik.touched.pto_fees_cost &&
              Boolean(formik.errors.pto_fees_cost)
            }
            helperText={
              formik.touched.pto_fees_cost && formik.errors.pto_fees_cost
            }
          />
        </Grid>
        <Grid item xs={6} sm={5} lg={4}>
          <InputLabel htmlFor="component-simple">
            solar products cost $
          </InputLabel>
          <TextField
            fullWidth
            id="input-with-icon-textfield"
            name="solar_products_cost"
            value={formik.values.solar_products_cost}
            onChange={formik.handleChange}
            error={
              formik.touched.solar_products_cost &&
              Boolean(formik.errors.solar_products_cost)
            }
            helperText={
              formik.touched.solar_products_cost &&
              formik.errors.solar_products_cost
            }
          />
        </Grid>
      </Grid>

      <Grid container columns={12} spacing={5} justifyContent="center" mb={3}>
        <Grid item xs={6} sm={5} lg={4}>
          <InputLabel htmlFor="component-simple">hvac cost $</InputLabel>
          <TextField
            fullWidth
            id="input-with-icon-textfield"
            name="hvac_cost"
            value={formik.values.hvac_cost}
            onChange={formik.handleChange}
            error={formik.touched.hvac_cost && Boolean(formik.errors.hvac_cost)}
            helperText={formik.touched.hvac_cost && formik.errors.hvac_cost}
          />
        </Grid>
        <Grid item xs={6} sm={5} lg={4}>
          <InputLabel htmlFor="component-simple">
            online monitoring cost $
          </InputLabel>
          <TextField
            fullWidth
            id="input-with-icon-textfield"
            name="online_monitoring_cost"
            value={formik.values.online_monitoring_cost}
            onChange={formik.handleChange}
            error={
              formik.touched.online_monitoring_cost &&
              Boolean(formik.errors.online_monitoring_cost)
            }
            helperText={
              formik.touched.online_monitoring_cost &&
              formik.errors.online_monitoring_cost
            }
          />
        </Grid>
      </Grid>

      <Grid container columns={12} spacing={5} justifyContent="center" mb={3}>
        <Grid item xs={6} sm={5} lg={4}>
          <InputLabel htmlFor="component-simple">attic run cost $</InputLabel>
          <TextField
            fullWidth
            id="input-with-icon-textfield"
            name="attic_run_cost"
            value={formik.values.attic_run_cost}
            onChange={formik.handleChange}
            error={
              formik.touched.attic_run_cost &&
              Boolean(formik.errors.attic_run_cost)
            }
            helperText={
              formik.touched.attic_run_cost && formik.errors.attic_run_cost
            }
          />
        </Grid>
        <Grid item xs={6} sm={5} lg={4}>
          <InputLabel htmlFor="component-simple">
            tree trimming cost $
          </InputLabel>
          <TextField
            fullWidth
            id="input-with-icon-textfield"
            name="tree_trimming_cost"
            value={formik.values.tree_trimming_cost}
            onChange={formik.handleChange}
            error={
              formik.touched.tree_trimming_cost &&
              Boolean(formik.errors.tree_trimming_cost)
            }
            helperText={
              formik.touched.tree_trimming_cost &&
              formik.errors.tree_trimming_cost
            }
          />
        </Grid>
      </Grid>
      <Grid container columns={12} spacing={5} justifyContent="center" mb={3}>
        <Grid item xs={6} sm={5} lg={4}>
          <InputLabel htmlFor="component-simple">
            mpu relocation cost $
          </InputLabel>
          <TextField
            fullWidth
            id="input-with-icon-textfield"
            name="mpu_relocation_cost"
            value={formik.values.mpu_relocation_cost}
            onChange={formik.handleChange}
            error={
              formik.touched.mpu_relocation_cost &&
              Boolean(formik.errors.mpu_relocation_cost)
            }
            helperText={
              formik.touched.mpu_relocation_cost &&
              formik.errors.mpu_relocation_cost
            }
          />
        </Grid>
        <Grid item xs={6} sm={5} lg={4}>
          <InputLabel htmlFor="component-simple">rate cost $</InputLabel>
          <TextField
            fullWidth
            id="input-with-icon-textfield"
            name="de_rate_cost"
            value={formik.values.de_rate_cost}
            onChange={formik.handleChange}
            error={
              formik.touched.de_rate_cost && Boolean(formik.errors.de_rate_cost)
            }
            helperText={
              formik.touched.de_rate_cost && formik.errors.de_rate_cost
            }
          />
        </Grid>
      </Grid>

      <Grid container columns={12} spacing={5} justifyContent="center" mb={3}>
        <Grid item xs={6} sm={5} lg={4}>
          <InputLabel htmlFor="component-simple">sub panel cost $</InputLabel>
          <TextField
            fullWidth
            id="input-with-icon-textfield"
            name="sub_panel_cost"
            value={formik.values.sub_panel_cost}
            onChange={formik.handleChange}
            error={
              formik.touched.sub_panel_cost &&
              Boolean(formik.errors.sub_panel_cost)
            }
            helperText={
              formik.touched.sub_panel_cost && formik.errors.sub_panel_cost
            }
          />
        </Grid>
        <Grid item xs={6} sm={5} lg={4}>
          <InputLabel htmlFor="component-simple">
            meter socket cost $
          </InputLabel>
          <TextField
            fullWidth
            id="input-with-icon-textfield"
            name="meter_socket_cost"
            value={formik.values.meter_socket_cost}
            onChange={formik.handleChange}
            error={
              formik.touched.meter_socket_cost &&
              Boolean(formik.errors.meter_socket_cost)
            }
            helperText={
              formik.touched.meter_socket_cost &&
              formik.errors.meter_socket_cost
            }
          />
        </Grid>
      </Grid>

      <Grid container columns={12} spacing={5} justifyContent="center" mb={3}>
        <Grid item xs={6} sm={5} lg={4}>
          <InputLabel htmlFor="component-simple">ev charger cost $</InputLabel>
          <TextField
            fullWidth
            id="input-with-icon-textfield"
            name="ev_charger_cost"
            value={formik.values.ev_charger_cost}
            onChange={formik.handleChange}
            error={
              formik.touched.ev_charger_cost &&
              Boolean(formik.errors.ev_charger_cost)
            }
            helperText={
              formik.touched.ev_charger_cost && formik.errors.ev_charger_cost
            }
          />
        </Grid>
        <Grid item xs={6} sm={5} lg={4}>
          <InputLabel htmlFor="component-simple">ev mlo cost $</InputLabel>
          <TextField
            fullWidth
            id="input-with-icon-textfield"
            name="ev_mlo_cost"
            value={formik.values.ev_mlo_cost}
            onChange={formik.handleChange}
            error={
              formik.touched.ev_mlo_cost && Boolean(formik.errors.ev_mlo_cost)
            }
            helperText={formik.touched.ev_mlo_cost && formik.errors.ev_mlo_cost}
          />
        </Grid>
      </Grid>

      <Grid container columns={12} spacing={5} justifyContent="center" mb={3}>
        <Grid item xs={6} sm={5} lg={4}>
          <InputLabel htmlFor="component-simple">
            designated plugs cost $
          </InputLabel>
          <TextField
            fullWidth
            id="input-with-icon-textfield"
            name="designated_plugs_cost"
            value={formik.values.designated_plugs_cost}
            onChange={formik.handleChange}
            error={
              formik.touched.designated_plugs_cost &&
              Boolean(formik.errors.designated_plugs_cost)
            }
            helperText={
              formik.touched.designated_plugs_cost &&
              formik.errors.designated_plugs_cost
            }
          />
        </Grid>
        <Grid item xs={6} sm={5} lg={4}>
          <InputLabel htmlFor="component-simple">battery cost $</InputLabel>
          <TextField
            fullWidth
            id="input-with-icon-textfield"
            name="battery_cost"
            value={formik.values.battery_cost}
            onChange={formik.handleChange}
            error={
              formik.touched.battery_cost && Boolean(formik.errors.battery_cost)
            }
            helperText={
              formik.touched.battery_cost && formik.errors.battery_cost
            }
          />
        </Grid>
      </Grid>

      <Grid container columns={12} spacing={5} justifyContent="center" mb={3}>
        <Grid item xs={6} sm={5} lg={4}>
          <InputLabel htmlFor="component-simple">generator cost $</InputLabel>
          <TextField
            fullWidth
            id="input-with-icon-textfield"
            name="generator_cost"
            value={formik.values.generator_cost}
            onChange={formik.handleChange}
            error={
              formik.touched.generator_cost &&
              Boolean(formik.errors.generator_cost)
            }
            helperText={
              formik.touched.generator_cost && formik.errors.generator_cost
            }
          />
        </Grid>
        <Grid item xs={6} sm={5} lg={4}>
          <InputLabel htmlFor="component-simple">
            ducting cost cost $
          </InputLabel>
          <TextField
            fullWidth
            id="input-with-icon-textfield"
            name="ducting_cost_cost"
            value={formik.values.ducting_cost_cost}
            onChange={formik.handleChange}
            error={
              formik.touched.ducting_cost_cost &&
              Boolean(formik.errors.ducting_cost_cost)
            }
            helperText={
              formik.touched.ducting_cost_cost &&
              formik.errors.ducting_cost_cost
            }
          />
        </Grid>
      </Grid>

      <Grid container columns={12} spacing={5} justifyContent="center" mb={3}>
        <Grid item xs={6} sm={5} lg={4}>
          <InputLabel htmlFor="component-simple">
            led lighting cost $
          </InputLabel>
          <TextField
            fullWidth
            id="input-with-icon-textfield"
            name="led_lighting_cost"
            value={formik.values.led_lighting_cost}
            onChange={formik.handleChange}
            error={
              formik.touched.led_lighting_cost &&
              Boolean(formik.errors.led_lighting_cost)
            }
            helperText={
              formik.touched.led_lighting_cost &&
              formik.errors.led_lighting_cost
            }
          />
        </Grid>
        <Grid item xs={6} sm={5} lg={4}>
          <InputLabel htmlFor="component-simple">
            energy efficient cost $
          </InputLabel>
          <TextField
            fullWidth
            id="input-with-icon-textfield"
            name="energy_efficient_cost"
            value={formik.values.energy_efficient_cost}
            onChange={formik.handleChange}
            error={
              formik.touched.energy_efficient_cost &&
              Boolean(formik.errors.energy_efficient_cost)
            }
            helperText={
              formik.touched.energy_efficient_cost &&
              formik.errors.energy_efficient_cost
            }
          />
        </Grid>
      </Grid>

      <Grid container columns={12} spacing={5} justifyContent="center" mb={3}>
        <Grid item xs={6} sm={5} lg={4}>
          <InputLabel htmlFor="component-simple">solar lip cost$</InputLabel>
          <TextField
            fullWidth
            id="input-with-icon-textfield"
            name="solar_lip_cost"
            value={formik.values.solar_lip_cost}
            onChange={formik.handleChange}
            error={
              formik.touched.solar_lip_cost &&
              Boolean(formik.errors.solar_lip_cost)
            }
            helperText={
              formik.touched.solar_lip_cost && formik.errors.solar_lip_cost
            }
          />
        </Grid>
        <Grid item xs={6} sm={5} lg={4}>
          <InputLabel htmlFor="component-simple">
            bird netting cost $
          </InputLabel>
          <TextField
            fullWidth
            id="input-with-icon-textfield"
            name="bird_netting_cost"
            value={formik.values.bird_netting_cost}
            onChange={formik.handleChange}
            error={
              formik.touched.bird_netting_cost &&
              Boolean(formik.errors.bird_netting_cost)
            }
            helperText={
              formik.touched.bird_netting_cost &&
              formik.errors.bird_netting_cost
            }
          />
        </Grid>
      </Grid>
    </>
  );
}

export default FifthCharterForm;
