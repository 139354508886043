import React, { useEffect } from "react";
import CreatSiteSurvey from "../../Components/CreateSiteSurvey/CreateSiteSurvey";
import { useDispatch, useSelector } from "react-redux";
import { getSingleTicket } from "../../Redux/Slices/SingleTicketSlice/SingleTicketSlice";
import { useParams } from "react-router-dom";
const CreateSiteSurveyForm = () => {
  const dispatch = useDispatch();
  const { ticket_id } = useParams();
  const single_ticket = useSelector((state) => state.single_ticket);
  console.log(single_ticket, "single_ticket");
  useEffect(() => {
    dispatch(getSingleTicket(ticket_id));
    // dispatch(getInstallersListAction(state_id));
  }, []);
  return (
    <>
      <CreatSiteSurvey />
    </>
  );
};

export default CreateSiteSurveyForm;
