import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import work_order_to_installer from "./SendDocumentsServices";
import { toast } from "react-toastify";
const initialState = {
  work_order: {},
  isError: false,
  isSuccess: false,
  isLodaing: false,
  massage: "",
};

export const send_work_order_req = createAsyncThunk(
  "work_order/post",
  async (data, thunkAPI) => {
    try {
      return await work_order_to_installer.send_work_order_to_installer_Action(
        data
      );
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      console.log(error?.response?.data?.message
        , "error response");
      error?.response?.data?.errors?.map((err) =>
        toast.error(err.msg, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      );

      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })

      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const work_order_Slice = createSlice({
  name: "work_order",
  initialState,
  reducers: {
    resetWorkOrder: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLodaing = false;
      state.massage = "";
    },
  },
  extraReducers: {
    [send_work_order_req.pending]: (state) => {
      state.isLodaing = true;
    },
    [send_work_order_req.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.work_order = payload;
    },
    [send_work_order_req.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
  },
});

export const { resetWorkOrder } = work_order_Slice.actions;
export default work_order_Slice.reducer;
