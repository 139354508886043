import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { Grid, Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { useSelector, useDispatch } from "react-redux";
import { TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import { get_designers } from "../../../Redux/Slices/Designers/DesignsersSlice";
function FirstCharterForm({ formik }) {
  const dispatch = useDispatch();
  const designers = useSelector((state) => state.designers);
  const designersList = designers?.designers?.data;
  const single_ticket = useSelector((state) => state.single_ticket);
  console.log(
    single_ticket,
    single_ticket?.singleTicket?.data?.opportunity?.lead?.first_name,
    "single_tickettt"
  );
  const isAutherized = useSelector((state) => state.auth?.data);
  // charter default values
  //const project_manager = isAutherized?.name;
  const project_manager = isAutherized?.name;
  const project_name = `${single_ticket?.singleTicket?.data?.opportunity?.lead?.first_name} ${single_ticket?.singleTicket?.data?.opportunity?.lead?.last_name}`;
  const system_size = Math.round(
    single_ticket?.singleTicket?.data?.opportunity?.system_size_ac
  );
  const project_address =
    single_ticket?.singleTicket?.data?.opportunity?.lead?.location;
  const installation_type =
    single_ticket?.singleTicket?.data?.opportunity?.job_type;
  useEffect(() => {
    dispatch(get_designers());
  }, []);
  console.log(designersList, "designers");
  console.log(formik?.values?.project_name, project_name, "formik");
  return (
    <>
      <Grid item xs={12} lg={4} md={6} mt={3}>
        <Typography className="fieldDetails" variant="h5" mb={3}>
          {" "}
          General Project Information{" "}
        </Typography>
      </Grid>

      <Grid container columns={12} spacing={5} justifyContent="center" mb={3}>
        <Grid item xs={12} sm={5} lg={4}>
          <InputLabel htmlFor="component-simple">Project Name</InputLabel>
          <TextField
            fullWidth
            id="input-with-icon-textfield"
            name="project_name"
            //value={project_name}
            value={`${single_ticket?.singleTicket?.data?.opportunity?.lead?.first_name} ${single_ticket?.singleTicket?.data?.opportunity?.lead?.last_name}`}
            onChange={formik.handleChange}
            error={
              formik.touched.project_name && Boolean(formik.errors.project_name)
            }
            helperText={
              formik.touched.project_name && formik.errors.project_name
            }
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={5} lg={4}>
          <InputLabel htmlFor="component-simple">Project Manager</InputLabel>

          <TextField
            fullWidth
            id="input-with-icon-textfield"
            name="project_manager"
            //value={formik.values.project_manager}
            value={project_manager}
            onChange={formik.handleChange}
            error={
              formik.touched.project_manager &&
              Boolean(formik.errors.project_manager)
            }
            helperText={
              formik.touched.project_manager && formik.errors.project_manager
            }
            disabled
          />
        </Grid>
      </Grid>

      <Grid container columns={12} spacing={6} justifyContent="center" mb={3}>
        <Grid item xs={12} sm={5} lg={4}>
          <InputLabel htmlFor="component-simple">Project Address</InputLabel>
          <TextField
            fullWidth
            id="input-with-icon-textfield"
            name="project_address"
            //value={formik.values.project_address}
            value={single_ticket?.isLodaing ? ".." : project_address}
            onChange={formik.handleChange}
            error={
              formik.touched.project_address &&
              Boolean(formik.errors.project_address)
            }
            helperText={
              formik.touched.project_address && formik.errors.project_address
            }
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={5} lg={4}>
          <InputLabel htmlFor="component-simple">System Size (k/w)</InputLabel>
          <TextField
            fullWidth
            id="input-with-icon-textfield"
            name="project_system_size"
            value={system_size}
            onChange={formik.handleChange}
            error={
              formik.touched.project_system_size &&
              Boolean(formik.errors.project_system_size)
            }
            helperText={
              formik.touched.project_system_size &&
              formik.errors.project_system_size
            }
            disabled
          />
        </Grid>
      </Grid>
      <Grid container columns={12} spacing={6} justifyContent="center" mb={3}>
        <Grid item xs={12} sm={5} lg={4}>
          <InputLabel htmlFor="component-simple">Installation Type</InputLabel>
          <TextField
            fullWidth
            id="input-with-icon-textfield"
            name="project_installation_type"
            // value={formik.values.project_installation_type}
            value={installation_type}
            onChange={formik.handleChange}
            error={
              formik.touched.project_installation_type &&
              Boolean(formik.errors.project_installation_type)
            }
            helperText={
              formik.touched.project_installation_type &&
              formik.errors.project_installation_type
            }
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={5} lg={4}>
          <InputLabel htmlFor="component-simple">
            Additional Permits?
          </InputLabel>
          <TextField
            fullWidth
            id="input-with-icon-textfield"
            name="project_additional_permits"
            value={formik.values.project_additional_permits}
            onChange={formik.handleChange}
            error={
              formik.touched.project_additional_permits &&
              Boolean(formik.errors.project_additional_permits)
            }
            helperText={
              formik.touched.project_additional_permits &&
              formik.errors.project_additional_permits
            }
          />
        </Grid>
      </Grid>

      <Grid container columns={12} spacing={6} justifyContent="center" mb={3}>
        <Grid item xs={12} sm={5} lg={4}>
          <InputLabel htmlFor="component-simple">project Roofer</InputLabel>
          <TextField
            fullWidth
            id="input-with-icon-textfield"
            name="project_roofer"
            value={formik.values.project_roofer}
            onChange={formik.handleChange}
            error={
              formik.touched.project_roofer &&
              Boolean(formik.errors.project_roofer)
            }
            helperText={
              formik.touched.project_roofer && formik.errors.project_roofer
            }
          />
        </Grid>
        <Grid item xs={12} sm={5} lg={4}>
          <InputLabel htmlFor="component-simple">Designer</InputLabel>
          <Select
            style={{ height: "14px !important" }}
            fullWidth
            id="input-with-icon-textfield"
            name="project_designer"
            value={formik.values.project_designer}
            onChange={formik.handleChange}
            error={
              formik.touched.project_designer &&
              Boolean(formik.errors.project_designer)
            }
            helperText={
              formik.touched.project_designer && formik.errors.project_designer
            }
          >
            {designersList?.map((designer) => (
              <MenuItem value={designer.id} name="user_id">
                {designer.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
    </>
  );
}

export default FirstCharterForm;
