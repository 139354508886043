import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useDispatch, useSelector } from "react-redux";
import LottieAnimation from "../../Components/LottieAnimation/LottieAnimation";
import { getSingleTicket } from "../../Redux/Slices/SingleTicketSlice/SingleTicketSlice";
import { getInstallersListAction } from "../../Redux/Slices/Installers/InstallersSlice";
import Button from "@mui/material/Button";
import { useParams } from "react-router-dom";
import loading from "../../Assets/lottie/loading.json";
import { useNavigate } from "react-router-dom";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#003656",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function StateInstallersTabe() {
  const dispatch = useDispatch();
  const installersList = useSelector((state) => state.installers);
  const installers_list = installersList?.installers?.data;
  const single_ticket = useSelector((state) => state.single_ticket);

  const { ticket_id } = useParams();
  const navigate = useNavigate();

  const state_id =
    single_ticket?.singleTicket?.data?.opportunity?.lead?.state_id;
  const lat = single_ticket?.singleTicket?.data?.opportunity?.lead?.lat;
  const lng = single_ticket?.singleTicket?.data?.opportunity?.lead?.lng;
  //   const handleClick = () => {
  //     setOpen(!open);
  //   };
  const reqData = {
    state_id: state_id,
    lat: lat,
    lng: lng,
  };

  const handleToInstallerData = (user) => {
    navigate(`/home/installers/${ticket_id}/${user.id}/${state_id}`);
  };
  useEffect(() => {
    dispatch(getSingleTicket(ticket_id));
    !single_ticket?.isLodaing && dispatch(getInstallersListAction(reqData));
  }, [ticket_id, state_id]);

  return (
    <>
      {installersList?.isLodaing ? (
        <LottieAnimation lotti={loading} width={180} height={180} />
      ) : (
        <TableContainer component={Paper} style={{ marginTop: "50px" }}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell align="center">Distance</StyledTableCell>
                <StyledTableCell align="center">Email</StyledTableCell>
                <StyledTableCell align="center">Details</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {installers_list?.length > 0 ? (
                installers_list?.map((user) => (
                  <StyledTableRow key={user.name}>
                    <StyledTableCell component="th" scope="row">
                      <>
                        <FiberManualRecordIcon
                          style={{
                            fontSize: "10px",
                            color: "#0DBF7C",
                            marginRight: "5px",
                          }}
                        />

                        {user.name}
                      </>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {user.distance} mile
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {user.email}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Button
                        variant="outlined"
                        onClick={() => handleToInstallerData(user)}
                      >
                        Details
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                <StyledTableRow
                  component="th"
                  scope="row"
                  align="center"
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    margin: "15px",
                    backgroundColor: "transparent",
                  }}
                >
                  {/* <StyledTableCell component="th" scope="row" align="center"> */}
                  There are no Installers
                  {/* </StyledTableCell> */}
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}

export default StateInstallersTabe;
