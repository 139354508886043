import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import call_utility_companies_Service from "./CallUtiltyCompanyService";
import { toast } from "react-toastify";
const initialState = {
  call_utility_companie: {},
  isError: false,
  isSuccess: false,
  isLodaing: false,
  massage: "",
};

export const store_call_utility__companies = createAsyncThunk(
  "callutility__companies/post",
  async (data, thunkAPI) => {
    try {
      return await call_utility_companies_Service.store_call_utility__companies(
        data
      );
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      error?.response?.data?.errors?.map((err) =>
        toast.error(err.msg, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      );
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const get_call_utility_companies_data = createAsyncThunk(
  "callutility_companies/get",
  async (ticket_id, thunkAPI) => {
    try {
      return await call_utility_companies_Service.get_call_utility_companies_data(
        ticket_id
      );
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const update_utility_companies = createAsyncThunk(
  "callutility_companies/post",
  async (data, thunkAPI) => {
    try {
      return await call_utility_companies_Service.edit_call_utility_companies(
        data
      );
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      error?.response?.data?.errors?.map((err) =>
        toast.error(err.msg, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      );
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const call_utility_companieSlice = createSlice({
  name: "call_utility_companie",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLodaing = false;
      state.massage = "";
    },
  },
  extraReducers: {
    //store_call_utility__companies
    [store_call_utility__companies.pending]: (state) => {
      state.isLodaing = true;
    },
    [store_call_utility__companies.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.call_utility_companie = payload;
    },
    [store_call_utility__companies.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },

    //get_call_utility_companies_data
    [get_call_utility_companies_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [get_call_utility_companies_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.call_utility_companie = payload;
    },
    [get_call_utility_companies_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.call_utility_companie = null;
      state.massage = action.payload;
    },

    //update_utility_companies
    [update_utility_companies.pending]: (state) => {
      state.isLodaing = true;
    },
    [update_utility_companies.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.call_utility_companie = payload;
    },
    [update_utility_companies.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
  },
});

export const { reset } = call_utility_companieSlice.actions;
export default call_utility_companieSlice.reducer;
