import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import CardsListService from "./CardsListService";

const initialState = {
  cardsOrder: null,
  isError: false,
  isSuccess: false,
  isLodaing: false,
  massage: "",
};

export const getCardsOrder = createAsyncThunk(
  "cards/getCardsOrder",
  async (thunkAPI) => {
    try {
      return await CardsListService.get_cards_order_Action();
    } catch (error) {
      const massage = error.message;
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const Update_Cards_order = createAsyncThunk(
  "cards/post",
  async (data, thunkAPI) => {
    try {
      let newArr = { cards: data };
      return await CardsListService.update_order(newArr);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const transfer_Cards = createAsyncThunk(
  "cards/post",
  async (data, thunkAPI) => {
    try {
      // let newArr = { cards: data };
      return await CardsListService.transferCard_Action(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const CardsOrderSlice = createSlice({
  name: "cardsOrder",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLodaing = false;
      state.isDeleteLodaing = false;
      state.massage = "";
    },
  },
  extraReducers: {
    [getCardsOrder.pending]: (state) => {
      state.isLodaing = true;
    },
    [getCardsOrder.fulfilled]: (state, action) => {
      state.isLodaing = false;
      state.cardsOrder = action.payload;
      state.isSuccess = true;
    },
    [getCardsOrder.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },

    //update cards order
    [Update_Cards_order.pending]: (state) => {
      state.isLodaing = true;
    },
    [Update_Cards_order.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardsOrder = payload;
    },
    [Update_Cards_order.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.cardsOrder = null;
      state.massage = action.payload;
    },

    //transfer_Cards
    [transfer_Cards.pending]: (state) => {
      state.isLodaing = true;
    },
    [transfer_Cards.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardsOrder = payload;
    },
    [transfer_Cards.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.cardsOrder = null;
      state.massage = action.payload;
    },
  },
});

export const { reset } = CardsOrderSlice.actions;
export default CardsOrderSlice.reducer;
