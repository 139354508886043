import React, { useEffect, useState } from "react";
import {
  Button,
  Typography,
  Grid,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  IconButton,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import LottieAnimation from "../../Components/LottieAnimation/LottieAnimation";
import loading from "../../Assets/lottie/loading.json";
import { useDispatch, useSelector } from "react-redux";
import { get_city_office_utilities_data } from "../../Redux/Slices/city_office_utilities_slice/city_office_utilities_slice";
import EditIcon from "@mui/icons-material/Edit";
import CallCityofficeutilityModal from "../../Components/Modals/Call_City_office_utility_Modal/CallCityofficeutilityModal";
import { getTicketStatus } from "../../Redux/Slices/TicketStatus/TicketStatusSlice";
const CallCityOffice = ({ lead_id, type }) => {
  const dispatch = useDispatch();
  const [open_edit_mmodal, set_edit_modal] = useState(false);
  const city_office_utilities = useSelector(
    (state) => state.city_office_utilities
  );
  console.log(city_office_utilities?.city_office_utilities , "city_office_utilities")
  const city_office_utilities_details =
    city_office_utilities?.city_office_utilities?.data;
  const cardStatus = useSelector((state) => state.TicketStatus);
  const CallCityOffice_status =
    cardStatus?.TicketStatus?.data?.call_city_utility;


  const handleopenModal = () => {
    set_edit_modal(true);
  };
  const handleCloseModal = () => {
    set_edit_modal(false);
  };
  useEffect(() => {
    const reqData = {
      type:
        type == "call_city_office"
          ? "permit"
          : type == "call_city_utility"
          ? "pto_submittal"
          : "",
      // type: type == "call_city_utility" && "pto_submittal",
      ticket_id: lead_id,
    };
    dispatch(get_city_office_utilities_data(reqData));
    console.log(type ,  reqData, "reqDatatypeeeee")
    dispatch(getTicketStatus(lead_id));
  }, [open_edit_mmodal, lead_id, type]);
  return (
    <>
      {city_office_utilities?.isLodaing ? (
        <>
          <LottieAnimation lotti={loading} width={180} height={180} />
        </>
      ) : (
        <>
          <Grid item xs={12} sm={12} lg={12}>
            {city_office_utilities?.city_office_utilities == null ? (
              <Typography align="center">there is no data</Typography>
            ) : (
              <Card>
                <CardHeader
                  title="call city office utilities Data"
                  subheader={`Created at: ${new Date(
                    city_office_utilities?.city_office_utilities?.data?.created_at
                  ).toLocaleDateString("en-US", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  })}
                
                `}
                />

                <CardContent>
                  <Typography variant="h6" color="text.secondary">
                    Status: {city_office_utilities_details?.status}
                  </Typography>
                  <Typography variant="h6" color="text.primary">
                    timeline:{" "}
                    <span style={{ color: "gray" }}>
                      {city_office_utilities_details?.timeline}
                    </span>
                  </Typography>
                  <Typography variant="h6" color="text.primary">
                    person to follow name:{" "}
                    <span style={{ color: "gray" }}>
                      {city_office_utilities_details?.person_to_follow_name}
                    </span>
                  </Typography>
                  <Typography variant="h6" color="text.primary">
                    person to follow phone:{" "}
                    <span style={{ color: "gray" }}>
                      {city_office_utilities_details?.person_to_follow_phone}
                    </span>
                  </Typography>
                  <Typography variant="h6" color="text.primary">
                    person to follow email:{" "}
                    <span style={{ color: "gray" }}>
                      {city_office_utilities_details?.person_to_follow_email}
                    </span>
                  </Typography>
                  <Typography variant="h6" color="text.primary">
                    inspection type:{" "}
                    <span style={{ color: "gray" }}>
                      {city_office_utilities_details?.inspection_type}
                    </span>
                  </Typography>
                  <Typography variant="h6" color="text.primary">
                    payment type:{" "}
                    <span style={{ color: "gray" }}>
                      {city_office_utilities_details?.payment_type}
                    </span>
                  </Typography>
                  {city_office_utilities_details?.payment_type == "check" && (
                    <>
                      <Typography variant="h6" color="text.primary">
                        send check to:{" "}
                        <span style={{ color: "gray" }}>
                          {city_office_utilities_details?.send_check_to}
                        </span>
                      </Typography>

                      <Typography variant="h6" color="text.primary">
                        information required to send check:{" "}
                        <span style={{ color: "gray" }}>
                          {
                            city_office_utilities_details?.information_required_to_send_check
                          }
                        </span>
                      </Typography>
                    </>
                  )}
                  <Typography variant="h6" color="text.primary">
                    is have license:{" "}
                    {city_office_utilities_details?.is_can_get_permit_faster ==
                    1 ? (
                      <CheckIcon style={{ color: "green" }} />
                    ) : (
                      <CloseIcon style={{ color: "red" }} />
                    )}
                  </Typography>
                  {city_office_utilities_details?.is_can_get_permit_faster ==
                    1 && (
                    <Typography variant="h6" color="text.primary">
                      method to get permit faster:{" "}
                      <span style={{ color: "gray" }}>
                        {
                          city_office_utilities_details?.method_to_get_permit_faster
                        }
                      </span>
                    </Typography>
                  )}
                </CardContent>
                <CardActions disableSpacing>
                  <IconButton aria-label="share" onClick={handleopenModal}>
                    <EditIcon />
                  </IconButton>
                </CardActions>
              </Card>
            )}
            {open_edit_mmodal && (
              <CallCityofficeutilityModal
                handleCloseModal={handleCloseModal}
                open_edit_mmodal={open_edit_mmodal}
                handleopenModal={handleopenModal}
                set_edit_modal={set_edit_modal}
                city_office_utilities_details={city_office_utilities_details}
                CallCityOffice_status={CallCityOffice_status}
                city_office_utilities_data ={city_office_utilities?.city_office_utilities}
                initialValues={{
                  status: city_office_utilities_details?.status,
                  timeline: city_office_utilities_details?.timeline,
                  person_to_follow_name:
                    city_office_utilities_details?.person_to_follow_name,
                  person_to_follow_phone:
                    city_office_utilities_details?.person_to_follow_phone,
                  person_to_follow_email:
                    city_office_utilities_details?.person_to_follow_email,
                  inspection_type:
                    city_office_utilities_details?.inspection_type,
                  payment_type: city_office_utilities_details?.payment_type,
                  send_check_to: city_office_utilities_details?.send_check_to,
                  information_required_to_send_check:
                    city_office_utilities_details?.information_required_to_send_check,
                  method_to_get_permit_faster:
                    city_office_utilities_details?.method_to_get_permit_faster,
                }}
              />
            )}
          </Grid>
        </>
      )}
    </>
  );
};

export default CallCityOffice;
