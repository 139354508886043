import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import procedures_Service from "./ProceduresServices";
import { toast } from "react-toastify";
const initialState = {
  procedures: {},
  isError: false,
  isSuccess: false,
  isLodaing: false,
  massage: "",
};

export const store_procedures = createAsyncThunk(
  "procedures/post",
  async (data, thunkAPI) => {
    try {
      return await procedures_Service.store_procedures_Action(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      error?.response?.data?.errors?.map((err) =>
        toast.error(err.msg, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      );
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const edit_procedures = createAsyncThunk(
  "procedures/post",
  async (data, thunkAPI) => {
    try {
      return await procedures_Service.edit_procedures_Action(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      error?.response?.data?.errors?.map((err) =>
        toast.error(err.msg, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      );
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const get_procedures = createAsyncThunk(
  "procedures/get",
  async (data, thunkAPI) => {
    try {
      return await procedures_Service.get_procedures_data(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const get_All_procedures = createAsyncThunk(
  "procedures/get",
  async (thunkAPI) => {
    try {
      return await procedures_Service.get_All_procedures_data();
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const delete_procedures = createAsyncThunk(
  "procedures/post",
  async (data, thunkAPI) => {
    try {
      return await procedures_Service.delete_procedure_Action(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);
export const proceduresSlice = createSlice({
  name: "procedures",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLodaing = false;
      state.massage = "";
    },
  },
  extraReducers: {
    //post store_procedures
    [store_procedures.pending]: (state) => {
      state.isLodaing = true;
    },
    [store_procedures.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.procedures = payload;
    },
    [store_procedures.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },

    //edit_procedures
    [edit_procedures.pending]: (state) => {
      state.isLodaing = true;
    },
    [edit_procedures.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.procedures = payload;
    },
    [edit_procedures.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },

    //get business_license
    [get_procedures.pending]: (state) => {
      state.isLodaing = true;
    },
    [get_procedures.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.procedures = payload;
    },
    [get_procedures.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.procedures = null;
      state.massage = action.payload;
    },

    // get_All_procedures
    [get_All_procedures.pending]: (state) => {
      state.isLodaing = true;
    },
    [get_All_procedures.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.procedures = payload;
    },
    [get_All_procedures.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.procedures = null;
      state.massage = action.payload;
    },

    //delete procedures
    [delete_procedures.pending]: (state) => {
      state.isLodaing = true;
    },
    [delete_procedures.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.procedures = payload;
    },
    [delete_procedures.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
  },
});

export const { reset } = proceduresSlice.actions;
export default proceduresSlice.reducer;
