import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get_confirm_with_salesAndCustomer_data } from "../../Redux/Slices/ConfirmWithSalesAndCustomerSlice/ConfirmWithSalesAndCustomerSlice";
import { Grid, IconButton, Typography } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import EditIcon from "@mui/icons-material/Edit";
import ConfirmWithSalesAndCustomer from "../../Components/Modals/ConfirmWithSalesAndCustomerModal/ConfirmWithSalesAndCustomer";
import { useParams } from "react-router-dom";
import LottieAnimation from "../../Components/LottieAnimation/LottieAnimation";
import loading from "../../Assets/lottie/loading.json";
const ConfirmWithSalesAndCustomerDetails = () => {
  const { lead_id } = useParams();
  const [openEditModal, set_Open_Modal] = useState(false);
  const dispatch = useDispatch();
  const confirm_with_salesAndCustomer_Data = useSelector(
    (state) => state?.confirm_with_sales_and_customer
  );
  const confirmation_items_with_sales_and_customer =
    confirm_with_salesAndCustomer_Data?.confirm_with_sales_and_customer;
  console.log(
    confirm_with_salesAndCustomer_Data?.confirm_with_sales_and_customer,
    "confirm_with_salesAndCustomer_Data54"
  );
  useEffect(() => {
    dispatch(get_confirm_with_salesAndCustomer_data(lead_id));
  }, [openEditModal, lead_id]);

  const handleopenModal = () => {
    set_Open_Modal(true);
  };

  const handleCloseModal = () => {
    set_Open_Modal(false);
  };
  return confirm_with_salesAndCustomer_Data?.isLodaing ? (
    <LottieAnimation lotti={loading} width={180} height={180} />
  ) : confirm_with_salesAndCustomer_Data?.confirm_with_sales_and_customer !==
    null ? (
      <Grid item xs={12} style={{ padding: "10px 55px" }} >
      <Card
        style={{
          background: "rgb(249, 245, 255)",
          border: "1px solid rgb(214, 187, 251)",
          borderRadius: "8px",
          padding: "1rem",
          margin: "0.5rem 0px",
        }}
        // sx={{ maxWidth: 545 }}
      >
        <CardContent>
          <ListItemText
            secondary={
              <React.Fragment>
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                  fontWeight="bold"
                >
                  Confirm Items :
                </Typography>
                {
                  confirm_with_salesAndCustomer_Data
                    ?.confirm_with_sales_and_customer?.data?.confirm_items
                }
              </React.Fragment>
            }
          />
          {/* <Divider
            variant="inset"
            align="center"
            style={{ marginBottom: "8px" }}
          /> */}

          <ListItemText
            secondary={
              <React.Fragment>
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                  fontWeight="bold"
                >
                  Status :
                </Typography>
                {
                  confirm_with_salesAndCustomer_Data
                    ?.confirm_with_sales_and_customer?.data?.status
                }
              </React.Fragment>
            }
          />

          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton onClick={handleopenModal}>
              <EditIcon />
            </IconButton>
          </div>
        </CardContent>
      </Card>

      {openEditModal && (
        <ConfirmWithSalesAndCustomer
          openEditModal={openEditModal}
          set_Open_Modal={set_Open_Modal}
          handleopenModal={handleopenModal}
          handleCloseModal={handleCloseModal}
          lead_id={lead_id}
          initialValues={{
            confirm_items:
              confirm_with_salesAndCustomer_Data
                ?.confirm_with_sales_and_customer?.data?.confirm_items,
            status:
              confirm_with_salesAndCustomer_Data
                ?.confirm_with_sales_and_customer?.data?.status,
          }}
        />
      )}
    </Grid>
  ) : (
    <Grid>
      <Typography align="center">
        Theres is no data for confirmation items with sales and customers
      </Typography>
    </Grid>
  );
};

export default ConfirmWithSalesAndCustomerDetails;
