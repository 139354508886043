import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { CharterForm } from "./CharterFormStyle";
import { useFormik, Formik } from "formik";
import { CircularProgress } from "@material-ui/core";
import LottieAnimation from "../../Components/LottieAnimation/LottieAnimation";
import loading from "../../Assets/lottie/loading.json";
import * as yup from "yup";
import FirstCharterForm from "../../Components/CharterForm/FirstCharterForm/FirstCharterForm";
import SecCharterForm from "../../Components/CharterForm/SecCharterForm/SecCharterForm";
import ThirdCharterForm from "../../Components/CharterForm/ThirdCharterForm/ThirdCharterForm";
import ForthCharterForm from "../../Components/CharterForm/ForthCharterForm/ForthCharterForm";
import FifthCharterForm from "../../Components/CharterForm/FifthCharterForm/FifthCharterForm";
import { useNavigate } from "react-router-dom";
import { postCreateCharter } from "../../Redux/Slices/CharterSlice/CharterSlice";
import { useDispatch, useSelector } from "react-redux";
import { getSingleTicket } from "../../Redux/Slices/SingleTicketSlice/SingleTicketSlice";
import {
  FirstStepValidation,
  secStepValidation,
  thirdStepValidation,
  fourthStepValidation,
  fifthStepValidation,
} from "./CharterValidation";
// validate the form steps
const validationFirstStepSchema = yup.object().shape(FirstStepValidation);
const validationSecStepSchema = yup.object().shape(secStepValidation);
const validationThirdStepSchema = yup.object().shape(thirdStepValidation);
const validationFourthStepSchema = yup.object().shape(fourthStepValidation);
const validationFifthStepSchema = yup.object().shape(fifthStepValidation);

const validationSchema = (activeStep) => {
  switch (activeStep) {
    case 0:
      return validationFirstStepSchema;
    case 1:
      return validationSecStepSchema;
    case 2:
      return validationThirdStepSchema;
    case 3:
      return validationFourthStepSchema;
    case 4:
      return validationFifthStepSchema;
    default:
      break;
  }
};
function CreateCharterForm({ activeStep, setActiveStep, ticket_id, steps }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let id = {
    ticket_id: ticket_id,
  };
  useEffect(() => {
    dispatch(getSingleTicket(ticket_id));
  }, [ticket_id]);

  const isLastStep = activeStep === steps.length - 1;
  console.log(isLastStep, "isLastStep");

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const single_ticket = useSelector((state) => state.single_ticket);
  console.log(single_ticket, "single_tickeeet");
  const isAutherized = useSelector((state) => state.auth?.data);
  const create_charter = useSelector((state) => state.charter);
  console.log(create_charter?.isSuccess, "create_charter in formmmmm");
  // charter default values
  const project_manager = isAutherized?.name;
  const project_name = `${single_ticket?.singleTicket?.data?.opportunity?.lead?.first_name} ${single_ticket?.singleTicket?.data?.opportunity?.lead?.last_name}`;
  console.log(project_name, "test");
  const system_size = Math.round(
    single_ticket?.singleTicket?.data?.opportunity?.system_size_ac
  );
  const utility_company =
    single_ticket?.singleTicket?.data?.opportunity?.utility_provider?.title;
  const project_address =
    single_ticket?.singleTicket?.data?.opportunity?.lead?.location;
  const installation_type =
    single_ticket?.singleTicket?.data?.opportunity?.job_type;
  const inverter_type =
    single_ticket?.singleTicket?.data?.opportunity?.inverter_type;
  const Panels_Manufacturer =
    single_ticket?.singleTicket?.data?.opportunity?.panels;
  const is_meter_socket =
    single_ticket?.singleTicket?.data?.opportunity?.is_meter_socket;
  const roof_materials =
    single_ticket?.singleTicket?.data?.opportunity?.roof_materials;
  const project_valuation = single_ticket?.singleTicket?.data?.contract?.cost;
  const jurisdiction = single_ticket?.singleTicket?.data?.lead?.county;
  console.log(
    single_ticket?.singleTicket?.data,
    "single_ticket in charter form",
    is_meter_socket
  );

  // useEffect(() => {
  //   formik.values.project_name = project_name;
  //   formik.values.project_manager = project_manager;
  //   console.log();
  // }, [ticket_id]);

  const formik = useFormik({
    initialValues: {
      project_name: "",
      project_manager: "",
      project_address: "",
      project_system_size: "",
      project_installation_type: "",
      project_additional_permits: "",
      project_roofer: "",
      project_designer: "",
      jurisdiction: "",
      submittal_process: "",
      jurisdiction_contact_email: "",
      jurisdiction_estimated_duration: "",
      type_of_inspections: "",
      structural_calculation: "",
      special_requirements: "",
      other_special_requirements: "",
      business: "",
      utility_company: "",
      utility_pto_process: "",
      utility_contact_email: "",
      utility_contact_mobile: "",
      utility_estimated_duration: "",
      special_requirements_pto: "",
      other_special_requirements_pto: "",
      roofing_contract_redecking: "",
      roofing_shingles: "",
      type_of_inspections_roofing: "",
      mpu_meter_spot: "",
      mpu_shutoff_required: "",
      type_of_inspections_mpu: "",
      project_valuation: "",
      products_manufacturer: "",
      inverter_manufacturer_model: "",
      products_total_price: "",
      welcome_call: null,
      site_survey: null,
      Business_license_date: null,
      meterspot_date: null,
      mpu_application_submission_date_start: null,
      mpu_permit_issuance_date_end: null,
      solar_application_submission_date_start: null,
      solar_permit_issuance_date_end: null,
      roofing_application_submission_date_start: null,
      roofing_permit_issuance_date_end: null,
      roofing_installation_start_time: null,
      roofing_installation_complete: null,
      mpu_installation_start_time: null,
      mpu_installation_complete: null,
      solar_installation_start_time: null,
      solar_installation_complete: null,
      roofing_inspection_start_date: null,
      mpu_inspection_start_date: null,
      solar_inspection_start_date: null,
      pto_application_submittal_date_start: null,
      pto_application_submittal_date_end: null,
      site_survey_cost: "",
      permit_fees_cost: "",
      permit_run_cost: "",
      roofing_price_cost: "",
      mpu_fees_cost: "",
      solar_installation_fees_cost: "",
      pto_fees_cost: "",
      solar_products_cost: "",
      hvac_cost: "",
      online_monitoring_cost: "",
      attic_run_cost: "",
      tree_trimming_cost: "",
      mpu_relocation_cost: "",
      de_rate_cost: "",
      sub_panel_cost: "",
      meter_socket_cost: "",
      ev_charger_cost: "",
      ev_mlo_cost: "",
      designated_plugs_cost: "",
      battery_cost: "",
      generator_cost: "",
      ducting_cost_cost: "",
      led_lighting_cost: "",
      energy_efficient_cost: "",
      solar_lip_cost: "",
      bird_netting_cost: "",
    },
    //validationSchema: validationSchema(activeStep),

    onSubmit: (values, actions) => {
      if (isLastStep) {
        dispatch(postCreateCharter({ ...values, ...id }));
        create_charter?.isSuccess &&
          navigate("/home/user-section/tickets/allTickets");
        // console.log("All Values in if", formik.values);
        // alert(JSON.stringify(values));
        actions.setSubmitting(false);
      } else {
        console.log("All Values in else", formik.values);
        setActiveStep(activeStep + 1);
        actions.setTouched({});
        actions.setSubmitting(false);
      }
    },
  });
  useEffect(() => {
    if (single_ticket) {
      formik.values.project_name = project_name;
      formik.values.project_manager = project_manager;
      formik.values.project_address = project_address;
      formik.values.project_system_size = system_size;
      formik.values.project_installation_type = installation_type;
      formik.values.jurisdiction = jurisdiction;
      formik.values.utility_company = utility_company;
      formik.values.roofing_shingles = roof_materials;
      formik.values.mpu_meter_spot = is_meter_socket === 0 ? "No" : "";
      formik.values.project_valuation = project_valuation;
      formik.values.Panels_Manufacturer = Panels_Manufacturer;
      formik.values.inverter_manufacturer_model = inverter_type;
      formik.values.products_manufacturer = Panels_Manufacturer;
    }
    // console.log();
  }, [single_ticket]);

  const renderCreateCharterSteps = (step) => {
    switch (step) {
      case 0:
        return <FirstCharterForm formik={formik} project_name />;
      case 1:
        return <SecCharterForm formik={formik} />;
      case 2:
        return (
          <ThirdCharterForm formik={formik} is_meter_socket={is_meter_socket} />
        );
      case 3:
        return <ForthCharterForm formik={formik} />;
      case 4:
        return <FifthCharterForm formik={formik} />;
      default:
        return <div>Not Found</div>;
    }
  };

  return (
    <CharterForm>
      <Formik initialValues={formik.initialValues}>
        {({ isSubmitting }) => (
          <form onSubmit={formik.handleSubmit}>
            {single_ticket?.isLodaing ? (
              <LottieAnimation lotti={loading} width={180} height={180} />
            ) : (
              <>
                {renderCreateCharterSteps(activeStep)}
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  {activeStep !== 0 && (
                    <Button
                      color="inherit"
                      disabled={activeStep === 0}
                      sx={{ mr: 1 }}
                      onClick={handleBack}
                    >
                      Back
                    </Button>
                  )}

                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    {isLastStep ? "Save" : "Next"}
                  </Button>
                  {isSubmitting && <CircularProgress size={24} />}
                </Box>
              </>
            )}
          </form>
        )}
      </Formik>
    </CharterForm>
  );
}

export default CreateCharterForm;
