import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { PrimaryButton } from "../../Layouts/ButtonsControlled";
import TextField from "@mui/material/TextField";
import styled from "styled-components";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Grid,
  IconButton,
  Typography,
  Button,
  ListItemAvatar,
  ListItemText,
  ListItem,
  List,
  FormControl,
} from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import DeleteIcon from "@mui/icons-material/Delete";
import { Add } from "@mui/icons-material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloseIcon from "@mui/icons-material/Close";
import MenuItem from "@mui/material/MenuItem";
import { useSelector, useDispatch } from "react-redux";
import MuiTextField from "../../Common/muiTextField/MuiTextField";
import MuiSelectField from "../../Common/MuiSelectField/MuiSelectField";
import { get_designers } from "../../Redux/Slices/Designers/DesignsersSlice";
import { get_all_contractors } from "../../Redux/Slices/contractors/contractorsSlice";
import {
  resetDesingRequest,
  send_design_req,
} from "../../Redux/Slices/DesignRequest/DesignRequestSlice";
import { getSingleTicket } from "../../Redux/Slices/SingleTicketSlice/SingleTicketSlice";
import { useNavigate } from "react-router-dom";
const DesignReqForm = ({ handleSubmit, ticket_id, initialValues }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const single_ticket = useSelector((state) => state.single_ticket);
  const designers = useSelector((state) => state.designers);
  const contractors = useSelector((state) => state.contractors);
  const send_design_reqq = useSelector((state) => state.design_req);
  const designersList = designers?.designers?.data;
  const contractorsList = contractors?.contractors?.data;
  const [selectedDesigner, setSelectedDesigner] = useState();
  const [selectedContractor, setSelectedContractor] = useState();
  const MAX_COUNT = 500;
  const [showUpload, setShowUpload] = useState(false);
  const [fileLimit, setFileLimit] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [UpdatedUploadFiles, setUpdatedUploadedFiles] = useState([]);
  console.log(send_design_reqq, "send_design_reqq");
  console.log(send_design_reqq?.isSuccess, "send_design_reqq?.isSuccess");
  let ContractorLicense = selectedContractor?.license;
  let ContractorAddress = selectedContractor?.address;
  let designerEmail = selectedDesigner?.email;
  console.log(send_design_reqq, "send_design_reqq");
  console.log(designerEmail, "designerEmail");
  useEffect(() => {
    let apis = async () => {
      await dispatch(get_designers());
      await dispatch(get_all_contractors());
      await dispatch(resetDesingRequest());
    };
    apis();
  }, []);

  const selectDesigner = (designer) => {
    setSelectedDesigner(designer);
  };

  const selectcontractor = (contractor) => {
    setSelectedContractor(contractor);
  };

  const handleShowUploadFiles = () => {
    setShowUpload(true);
  };

  const handlecloseUpload = () => {
    setShowUpload(false);
  };

  const handleRemoveItem = (file) => {
    const name = UpdatedUploadFiles.map((file) => file.name);
    setUpdatedUploadedFiles(
      UpdatedUploadFiles.filter((item) => item.name !== file.name)
    );
    console.log(name, "name");
    console.log(UpdatedUploadFiles, "UpdatedUploadFilesssssssss");
  };

  const handleUploadFiles = (files) => {
    const uploaded = [...UpdatedUploadFiles];
    let limitExceeded = false;
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        setUpdatedUploadedFiles(uploaded);
        if (uploaded.length === MAX_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_COUNT) {
          alert(`You can only add a maximum of ${MAX_COUNT} files`);
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    });
    if (!limitExceeded) {
      setUploadedFiles(uploaded);
    }
  };
  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };

  const submit = async (values) => {
    // e.preventDefault();
    console.log(values, "values");
    const formData = new FormData();
    formData.append("customer_name", values.customer_name);
    formData.append("customer_address", values.customer_address);
    formData.append("designer_id", values.designer_id);
    formData.append("plan_set", values.plan_set);
    formData.append("stamped_plan_set", values.stamped_plan_set);
    formData.append("structure_calculation", values.structure_calculation);
    formData.append("installation_type", values.installation_type);
    formData.append("system_type", values.system_type);
    formData.append("system_size", values.system_size);
    formData.append("panels_number", values.panels_number);
    formData.append("panels_manufacture", values.panels_manufacture);
    formData.append("panels_wattage", values.panels_wattage);
    // formData.append("panels_model", values.panels_model);
    formData.append("invert_manufacture", values.invert_manufacture);
    formData.append("optimizer", values.optimizer);
    formData.append("is_mpu", values.is_mpu);
    formData.append("mpu_from_to", values.mpu_from_to);
    formData.append("msp", values.msp);
    formData.append("location", values.location);
    formData.append("battery", values.battery);
    formData.append("contractor_name", values.contractor_name);
    formData.append("contractor_license", ContractorLicense);
    formData.append("contractor_address", ContractorAddress);
    formData.append("contractor", values.contractor);
    formData.append("roof_type", values.roof_type);
    formData.append("pitch_degree", values.pitch_degree);
    formData.append("runs_type", values.runs_type);
    formData.append("invert_model", values.invert_model);
    formData.append("email", designerEmail);
    formData.append("ticket_id", ticket_id);
    formData.append("mailed_type", values.mailed_type);
    UpdatedUploadFiles.map((e, i) => {
      formData.append(`media[${i}]`, e);
    });
    await dispatch(send_design_req(formData));
  };
  React.useEffect(() => {
    let apis = async () => {
      if (send_design_reqq?.isSuccess) {
        await navigate(`/home/SolarSolution/start-project/${ticket_id}`);
        await dispatch(resetDesingRequest());
      }
    };
    apis();
  }, [send_design_reqq?.isSuccess]);
  return (
    <>
      <form onSubmit={handleSubmit((values) => submit(values))}>
        <Grid container columns={12} spacing={5} justifyContent="center" mb={3}>
          <Grid item xs={12} sm={5} lg={4}>
            <Field
              required
              type="text"
              name="customer_name"
              component={MuiTextField}
              label="Customer Name"
              disabled
            />
          </Grid>

          <Grid item xs={12} sm={5} lg={4}>
            <Field
              required
              type="text"
              name="customer_address"
              component={MuiTextField}
              label="Customer Address"
            />
          </Grid>
        </Grid>
        <Grid container columns={12} spacing={5} justifyContent="center" mb={3}>
          <Grid item xs={12} sm={5} lg={4}>
            <Field
              required
              name="designer_id"
              component={MuiSelectField}
              label="Designer"
            >
              {designersList &&
                designersList.length > 0 &&
                designersList.map((option) => (
                  <MenuItem
                    key={option.id}
                    value={option.id}
                    onClick={() => selectDesigner(option)}
                  >
                    {option.name}
                  </MenuItem>
                ))}
            </Field>
          </Grid>
          <Grid item xs={12} sm={5} lg={4}>
            <TextField
              name="email"
              id="outlined-basic"
              // label="Designer email"
              variant="outlined"
              fullWidth
              focused
              value={designerEmail}
              style={{ marginTop: "12px" }}
              disabled
            />
          </Grid>
        </Grid>
        <Grid container columns={12} spacing={5} justifyContent="center" mb={3}>
          <Grid item xs={12} sm={5} lg={2}>
            <Field
              required
              type="text"
              name="installation_type"
              component={MuiTextField}
              label="installation type"
            />
          </Grid>
          <Grid item xs={12} sm={5} lg={2}>
            <Field
              required
              type="number"
              name="system_size"
              component={MuiTextField}
              label="System size"
            />
          </Grid>
          <Grid item xs={12} sm={10} lg={4}>
            <Field
              required
              name="mailed_type"
              component={MuiSelectField}
              label="mailed type"
            >
              <MenuItem value="wet_stamped" label="option 1">
                wet stamped
              </MenuItem>
              <MenuItem value="digital_stamp" label="option 2">
                digital stamp
              </MenuItem>
              <MenuItem value="no_structure_calculation" label="option 2">
                no structure calculation
              </MenuItem>
            </Field>
          </Grid>
        </Grid>
        <Grid container columns={12} spacing={5} justifyContent="center" mb={3}>
          <Grid item xs={12} sm={5} lg={4}>
            <Field
              required
              type="number"
              name="optimizer"
              component={MuiTextField}
              label="optimizer"
            />
          </Grid>
          <Grid item xs={12} sm={5} lg={4}>
            <Field
              required
              type="number"
              name="panels_number"
              component={MuiTextField}
              label="panels number"
            />
          </Grid>
        </Grid>
        <Grid container columns={12} spacing={5} justifyContent="center" mb={3}>
          <Grid item xs={12} sm={5} lg={4}>
            <Field
              required
              type="text"
              name="panels_manufacture"
              component={MuiTextField}
              label="panels manufacture"
            />
          </Grid>
          <Grid item xs={12} sm={5} lg={4}>
            <Field
              required
              type="number"
              name="panels_wattage"
              component={MuiTextField}
              label="panels wattage"
            />
          </Grid>
        </Grid>
        <Grid container columns={12} spacing={5} justifyContent="center" mb={3}>
          <Grid item xs={12} sm={5} lg={4}>
            <Field
              required
              type="text"
              name="invert_manufacture"
              component={MuiTextField}
              label="invert manufacture"
            />
          </Grid>
          <Grid item xs={12} sm={5} lg={4}>
            <Field
              required
              type="text"
              name="invert_model"
              component={MuiTextField}
              label="invert model"
            />
          </Grid>
        </Grid>
        <Grid container columns={12} spacing={5} justifyContent="center" mb={3}>
          <Grid item xs={12} sm={5} lg={2}>
            <Field
              required
              name="stamped_plan_set"
              component={MuiSelectField}
              label="stamped plan set"
            >
              <MenuItem value="0" label="option 1">
                NO
              </MenuItem>
              <MenuItem value="1" label="option 2">
                Yes
              </MenuItem>
            </Field>
          </Grid>

          <Grid item xs={12} sm={5} lg={2}>
            <Field
              required
              name="structure_calculation"
              component={MuiSelectField}
              label="structure calculation"
            >
              <MenuItem value="0" label="option 1">
                NO
              </MenuItem>
              <MenuItem value="1" label="option 2">
                Yes
              </MenuItem>
            </Field>
          </Grid>
          <Grid item xs={12} sm={10} lg={4}>
            <Field
              required
              name="system_type"
              component={MuiSelectField}
              label="system type"
            >
              <MenuItem value="new_system" label="new_system">
                Roof-mount
              </MenuItem>
              <MenuItem value="upgrade_existing_system" label="new_system">
                Geound-mount
              </MenuItem>
            </Field>
          </Grid>
        </Grid>
        <Grid container columns={12} spacing={5} justifyContent="center" mb={3}>
          <Grid item xs={12} sm={5} lg={2}>
            <Field
              required
              type="number"
              name="msp"
              component={MuiTextField}
              label="MSP"
            />
          </Grid>
          <Grid item xs={12} sm={5} lg={2}>
            <Field
              required
              name="is_mpu"
              component={MuiSelectField}
              label="is MPU"
            >
              <MenuItem value="0" label="option 1">
                NO
              </MenuItem>
              <MenuItem value="1" label="option 2">
                Yes
              </MenuItem>
            </Field>
          </Grid>
          <Grid item xs={12} sm={10} lg={4}>
            <Field
              required
              name="plan_set"
              component={MuiSelectField}
              label="plan set"
            >
              <MenuItem value="0" label="option 1">
                NO
              </MenuItem>
              <MenuItem value="1" label="option 2">
                Yes
              </MenuItem>
            </Field>
          </Grid>
        </Grid>
        <Grid container columns={12} spacing={5} justifyContent="center" mb={3}>
          <Grid item xs={12} sm={5} lg={2}>
            <Field
              required
              type="number"
              name="pitch_degree"
              component={MuiTextField}
              label="pitch degree"
            />
          </Grid>
          <Grid item xs={12} sm={5} lg={2}>
            <Field
              required
              type="text"
              name="roof_type"
              component={MuiTextField}
              label="roof type"
            />
          </Grid>

          <Grid item xs={12} sm={10} lg={4}>
            <Field
              required
              name="runs_type"
              component={MuiSelectField}
              label="runs type"
            >
              <MenuItem value="conduit_ran_outside">
                conduit ran outside
              </MenuItem>
              <MenuItem value="attic_runs">attic runs</MenuItem>
              <MenuItem value="none">none</MenuItem>
            </Field>
          </Grid>
        </Grid>
        <Grid container columns={12} spacing={5} justifyContent="center" mb={3}>
          <Grid item xs={12} sm={5} lg={2}>
            <Field
              required
              type="text"
              name="battery"
              component={MuiTextField}
              label="battery"
            />
          </Grid>
          <Grid item xs={12} sm={5} lg={2}>
            <Field
              required
              type="text"
              name="mpu_from_to"
              component={MuiTextField}
              label="mpu from to"
            />
          </Grid>
          <Grid item xs={12} sm={10} lg={4}>
            <Field
              required
              type="text"
              name="location"
              component={MuiTextField}
              label="location"
            />
          </Grid>
        </Grid>
        <Grid container columns={12} spacing={5} justifyContent="center" mb={3}>
          <Grid item xs={12} sm={5} lg={2}>
            <Field
              required
              name="contractor"
              component={MuiSelectField}
              label="contractor name"
            >
              {contractorsList &&
                contractorsList.length > 0 &&
                contractorsList.map((option) => (
                  <MenuItem
                    key={option.id}
                    value={option.id}
                    onClick={() => selectcontractor(option)}
                  >
                    {option.name}
                  </MenuItem>
                ))}
            </Field>
          </Grid>
          <Grid item xs={12} sm={5} lg={4}>
            <TextField
              name="contractor_Address"
              id="outlined-basic"
              label="contractor Address"
              variant="outlined"
              fullWidth
              focused
              value={ContractorAddress}
              style={{ margin: "12px" }}
            />
          </Grid>
          <Grid item xs={12} sm={10} lg={2}>
            <TextField
              name="contractor_license"
              id="outlined-basic"
              label="contractor icense"
              variant="outlined"
              fullWidth
              focused
              value={ContractorLicense}
              style={{ marginTop: "12px" }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          columns={12}
          spacing={5}
          justifyContent="center"
          mb={5}
          mt={5}
        >
          <IconButton onClick={() => handleShowUploadFiles()}>
            <Typography>
              <span style={{ color: "black", fontWeight: "bold" }}>
                Upload Files
              </span>{" "}
            </Typography>{" "}
            {!showUpload && <CloudUploadIcon style={{ marginLeft: "30px" }} />}
          </IconButton>
          {showUpload && (
            <IconButton onClick={handlecloseUpload}>
              <CloseIcon />
            </IconButton>
          )}

          {showUpload && (
            <Grid xs={12}>
              <Grid className="display" mt={4}>
                <FormControl variant="outlined">
                  <label htmlFor="contained-button-file">
                    <Input
                      accept="image/*"
                      id="contained-button-file"
                      multiple
                      type="file"
                      onChange={handleFileEvent}
                    />
                    <Button component="span" className="btn_upload">
                      <span className="icon">
                        <Add />
                      </span>
                      <span className="title"> Upload Files</span>
                    </Button>
                  </label>
                </FormControl>
                <List
                  sx={{
                    width: "100%",
                    maxWidth: 460,
                    marginTop: "30px",
                    display: "inline-block",
                  }}
                >
                  {UpdatedUploadFiles.map((file, index) => (
                    <ListItem
                      secondaryAction={
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => handleRemoveItem(file)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      }
                    >
                      <ListItemAvatar style={{ display: "flex" }}>
                        <FolderIcon />
                        <Typography>{file.name}</Typography>{" "}
                      </ListItemAvatar>
                      <ListItemText />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid container columns={12} spacing={5} justifyContent="center" mb={3}>
          <PrimaryButton
            fullWidth
            type="submit"
            style={{
              padding: "0",
              width: "55%",
              margin: "20px auto",
              height: "42px",
            }}
            className="saveButton"
          >
            {send_design_reqq?.isLodaing ? (
              <CircularProgress size={25} thickness={18} />
            ) : null}
            Save
          </PrimaryButton>
        </Grid>
      </form>
    </>
  );
};
const Input = styled.input`
  display: none;
`;
const mapStateToProps = (state) => {
  return {
    initialValues: {
      customer_name:
        state.single_ticket?.singleTicket?.data?.ticket?.client_name,
      customer_address:
        state.single_ticket?.singleTicket?.data?.ticket?.client_address,
      installation_type:
        state.single_ticket?.singleTicket?.data?.opportunity?.installation_type,
      system_size:
        state.single_ticket?.singleTicket?.data?.opportunity?.system_size_ac,
      panels_wattage:
        state.single_ticket?.singleTicket?.data?.opportunity?.panel_wattage,
      panels_number:
        state.single_ticket?.singleTicket?.data?.opportunity?.number_of_panels,
    },
  };
};

export default connect(mapStateToProps)(
  reduxForm({ form: "DesignRequestForm", enableReinitialize: true })(
    DesignReqForm
  )
);
