import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AddersCostCardService from "./AddersCardService";

const initialState = {
  AddersCostCard: {},
  isError: false,
  isSuccess: false,
  isLodaing: false,
  massage: "",
};

export const get_AddersCostCard = createAsyncThunk(
  "AddersCostCard/get",
  async (data, thunkAPI) => {
    try {
      return await AddersCostCardService.showAddersCostCard(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);
export const updateAddersCostSpecificCard = createAsyncThunk(
  "AddersCostSpecificCards/put",
  async (data, thunkAPI) => {
    try {
      return await AddersCostCardService.UpdateAddersCostSpecificCards(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const AddersCostSpecificCardSlice = createSlice({
  name: "AddersCostCard",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLodaing = false;
      state.massage = "";
    },
  },
  extraReducers: {
    // get AddersCostCard  Action
    [get_AddersCostCard.pending]: (state) => {
      state.isLodaing = true;
    },
    [get_AddersCostCard.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.AddersCostCard = payload;
    },
    [get_AddersCostCard.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.AddersCostCard = null;
      state.massage = action.payload;
    },

    // update AddersCostCard  Action
    [updateAddersCostSpecificCard.pending]: (state) => {
      state.isLodaing = true;
    },
    [updateAddersCostSpecificCard.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.AddersCostCard = payload;
    },
    [updateAddersCostSpecificCard.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.AddersCostCard = null;
      state.massage = action.payload;
    },
  },
});

export const { reset } = AddersCostSpecificCardSlice.actions;
export default AddersCostSpecificCardSlice.reducer;
