import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import designPlanCostService from "./DesignPlanCostService";
import { toast } from "react-toastify";
const initialState = {
  designPlanCost: {},
  isError: false,
  isSuccess: false,
  isLodaing: false,
  massage: "",
};

export const store_designPlanCost = createAsyncThunk(
  "designPlanCost/post",
  async (data, thunkAPI) => {
    try {
      return await designPlanCostService.designPlanCost(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          console.log(error?.response , "error?.response"),
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      error?.response?.data?.errors?.map((err) =>
        toast.error(err.msg, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      );
      return thunkAPI.rejectWithValue(massage);
    }
  }
);





export const designPlanCostSlice = createSlice({
  name: "designPlanCost",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLodaing = false;
      state.massage = "";
    },
  },
  extraReducers: {
    //post design plan cost   Action
    [store_designPlanCost.pending]: (state) => {
      state.isLodaing = true;
    },
    [store_designPlanCost.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.designPlanCost = payload;
    },
    [store_designPlanCost.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.designPlanCost = null;
      state.massage = action.payload;
    },

  },
});

export const { reset } = designPlanCostSlice.actions;
export default designPlanCostSlice.reducer;
