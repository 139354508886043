import axios from "axios";
import { send_client_plan } from "../../../Api/Api";
import { toast } from "react-toastify";
const sendClientPlanAction = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${send_client_plan}`,
    headers: headers,
    data: data,
    // params: {
    //   id: id,
    // },
  });
  if (response?.status == 200) {
    toast.success("Client Plan Sent Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const sendClientPlanService = {
  sendClientPlanAction,
};
export default sendClientPlanService;
