import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get_ProductAvailability } from "../../Redux/Slices/ProductAvailability/ProductAvailabilitySlice";
import { getTicketStatus } from "../../Redux/Slices/TicketStatus/TicketStatusSlice";
import { Grid, IconButton, Typography, Card } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import CheckIcon from "@mui/icons-material/Check";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import EditIcon from "@mui/icons-material/Edit";
import EditCallSupplierModal from "../../Components/Modals/EditCallSupplierData/EditCallSupplierModal";
import ProductAvailabilityModal from "../../Components/Modals/ProductAvailabilityModal/ProductAvailabilityModal";
const ProductAvailabilityDetails = ({ lead_id }) => {
    const [openProductAvailabilityModal, setOpenProductAvailabilityModal] = useState(false);
    const dispatch = useDispatch();
    const productAvailability_Data = useSelector((state) => state?.productAvailability);
    const cardStatus = useSelector((state) => state.TicketStatus);
    const productAvailability_status = cardStatus?.TicketStatus?.data?.check_product_availability;
    const productAvailability_details = productAvailability_Data?.productAvailability?.data
        ;
        console.log(productAvailability_status , "productAvailability_status")
    console.log(productAvailability_Data?.isLodaing
        , "productAvailability_Data")

    useEffect(() => {
        dispatch(get_ProductAvailability(lead_id));
        dispatch(getTicketStatus(lead_id));
    }, [openProductAvailabilityModal]);

    const handleOpenProductAvailabilityModal = () => {
        setOpenProductAvailabilityModal(true);
    };

    const handleCloseProductAvailabilityModal = () => {
        setOpenProductAvailabilityModal(false);
    };
    return productAvailability_Data?.isLodaing ? (
        <h1>loading</h1>
    ) : productAvailability_Data?.productAvailability !== null ? (
        <Grid item xs={11} style={{ padding: "10px 55px" }} >
            <Card

                style={{
                    background: "rgb(249, 245, 255)",
                    border: "1px solid rgb(214, 187, 251)",
                    borderRadius: "8px",
                    padding: "1rem",
                    margin: "0.5rem 0px",
                    position: "relative",


                }}
            >

                <CardContent
                    style={{ marginTop: "40px" }}

                >

                    {/* <Divider
          variant="inset"
          align="center"
          style={{ marginBottom: "8px" }}
        /> */}



                    <Typography>
                        {" "}
                        <span
                            style={{
                                fontWeight: "bold",
                                color: "#7e7ec0",
                            }}
                        >
                            {" "}
                            vendor
                            :
                            {" "}
                        </span>{" "}
                        {productAvailability_details?.vendor_id
                        }
                    </Typography>

                    <Typography>
                        {" "}
                        <span
                            style={{
                                fontWeight: "bold",
                                color: "#7e7ec0",
                            }}
                        >
                            {" "}
                            note

                            :
                            {" "}
                        </span>{" "}
                        {productAvailability_details?.note

                        }
                    </Typography>
                    <Typography>
                        {" "}
                        <span
                            style={{
                                fontWeight: "bold",
                                color: "#7e7ec0",
                            }}
                        >
                            {" "}
                            Status :
                            {" "}
                        </span>{" "}
                        {productAvailability_details?.status}
                    </Typography>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <IconButton onClick={handleOpenProductAvailabilityModal}>
                            <EditIcon />
                        </IconButton>
                    </div>
                </CardContent>
            </Card>
            {openProductAvailabilityModal && (
        <ProductAvailabilityModal
          productAvailability_details={productAvailability_details}
          initialValues={{
            note:productAvailability_details?.note,
            status:productAvailability_details?.status,
            vendor_id:productAvailability_details?.vendor_id,
          }}
          openProductAvailabilityModal={openProductAvailabilityModal}
          setOpenProductAvailabilityModal={setOpenProductAvailabilityModal}
          productAvailability_status={productAvailability_status}
          handleCloseProductAvailabilityModal={handleCloseProductAvailabilityModal}
          lead_id={lead_id}
        />
      )}
        </Grid>
    ) : (
        <Grid>
            <Typography align="center">
                Theres is no data for this card
            </Typography>
        </Grid>
    );
};

export default ProductAvailabilityDetails;
