import React, { useState, useEffect } from "react";
import { reduxForm, Field } from "redux-form";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Grid } from "@mui/material";
import { TextField } from "@mui/material";
import { PrimaryButton } from "../../../Layouts/ButtonsControlled";
import { ModalContent } from "../../Modals/PmAssignModal/PmAssignModalStyle";
import { getTicketStatus } from "../../../Redux/Slices/TicketStatus/TicketStatusSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import MuiTextField from "../../../Common/muiTextField/MuiTextField";
import {
  STORE_quality_control_step_of_survey_Action,
  Edit_quality_control_step_of_survey_Action,
} from "../../../Redux/Slices/SurveyStepsSlice/SurveyStepsSlice";
const QualityControlModal = ({
  handleSubmit,
  handleopenModal,
  handleCloseModal,
  set_Open_Modal,
  survey_details_data,
  openEdit_quality_control_Modal,
  surveyor_quality_control_step_status,
  setOpenEditModal,
  setOpenEdit_quality_control_Modal
}) => {
  const dispatch = useDispatch();
  const { lead_id } = useParams();
  const cardStatus_state = useSelector((state) => state.TicketStatus);
  const card_status = cardStatus_state?.TicketStatus?.data?.surveyor_quality_control;
  console.log(card_status , "card_status")
  console.log(survey_details_data , "survey_details_data123")
  const [was_polite, set_was_polite] = React.useState(
    +survey_details_data?.was_polite
  );
  const [wearing_company_uniform, set_wearing_company_uniform] = React.useState(
    survey_details_data?.wearing_company_uniform
  );
  const [rate, set_rate] = React.useState(0);

  useEffect(() => {
    dispatch(getTicketStatus(lead_id));
  }, [lead_id]);

  useEffect(() => {
    set_was_polite(+survey_details_data?.was_polite );
    set_wearing_company_uniform(
      survey_details_data?.wearing_company_uniform
    );
  }, [openEdit_quality_control_Modal]);
  console.log(survey_details_data, "survey_details_data1212");
  const handleChange_was_polite = (event) => {
    set_was_polite(event.target.checked);
    console.log(event.target);
    console.log(event.target.checked);
  };

  const handleChange_wearing_company_uniform = (event) => {
    set_wearing_company_uniform(event.target.checked);
    console.log(event.target);
    console.log(event.target.checked);
  };

  const submit = async (values) => {
    console.log(values, "values");
    const reqData = {
      ticket_id: +lead_id,
      wearing_company_uniform: wearing_company_uniform ? 1 : 0,
      was_polite: was_polite ? 1 : 0,
      id: surveyor_quality_control_step_status == "5" && survey_details_data?.id,
      // rate: rate,
    };
    // await dispatch(
    //   STORE_quality_control_step_of_survey_Action({ ...values, ...reqData })
    // );
    // await set_Open_Modal(false);
    // await dispatch(getTicketStatus(lead_id));

    surveyor_quality_control_step_status == "5" | card_status == "5" ?
      (await dispatch(
        Edit_quality_control_step_of_survey_Action({
          ...values,
          ...reqData,
        })
      )) &&
      (await setOpenEdit_quality_control_Modal(false)) : 

      (await dispatch(
        STORE_quality_control_step_of_survey_Action({ ...values, ...reqData })
      )) &&
      (await set_Open_Modal(false));
    await dispatch(getTicketStatus(lead_id));
  };

  // const HandelSubmit = async (e) => {
  //   e.preventDefault();
  //   const reqData = {
  //     ticket_id: +lead_id,
  //     wearing_company_uniform: wearing_company_uniform ? 1 : 0,
  //     was_polite: was_polite ? 1 : 0,
  //     rate: rate,
  //   };
  //   await dispatch(STORE_quality_control_step_of_survey_Action(reqData));
  //   await set_Open_Modal(false);
  //   await dispatch(getTicketStatus(lead_id));
  // };

  return (
    <Dialog
      maxWidth={"md"}
      fullWidth
      open={handleopenModal}
      onClose={handleCloseModal}
    >
      <DialogContent>
        <ModalContent>
          <Box>
            {/* <form onSubmit={HandelSubmit}> */}
            <form onSubmit={handleSubmit((values) => submit(values))}>
              <Grid>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={was_polite}
                        name="was_polite"
                        onChange={handleChange_was_polite}
                        checked={was_polite}
                      />
                    }
                    label="was polite ?"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        value={wearing_company_uniform}
                        name="wearing_company_uniform"
                        onChange={handleChange_wearing_company_uniform}
                        checked={wearing_company_uniform}
                      />
                    }
                    label="wearing company uniform ?"
                  />

                  <Field
                    required
                    rows={1}
                    type="number"
                    name="rate"
                    component={MuiTextField}
                    label="rate"
                  />
                </FormGroup>
              </Grid>

              <DialogActions>
                <PrimaryButton
                  type="submit"
                  fullWidth
                  style={{
                    padding: "0",
                    width: "85%",
                    margin: "15px auto",
                    height: "42px",
                  }}
                  className="saveButton"
                >
                  {surveyor_quality_control_step_status == "5" || card_status == "5"
                    ? "update"
                    : "save"}
                </PrimaryButton>
              </DialogActions>
            </form>
          </Box>
        </ModalContent>
      </DialogContent>
    </Dialog>
  );
};

export default reduxForm({
  form: "QualityControlForm",
})(QualityControlModal);
