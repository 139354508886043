import axios from "axios";
import { get_adders_table , update_adders_table } from "../../../Api/Api";
import { toast } from "react-toastify";
const Get_adders_table_Action = async (ticket_id) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${get_adders_table}/${ticket_id}`,
    headers: headers,
   
  });
  return response.data;
};


const edit_adders_data_Action = async (data) => {
    const { token } = JSON.parse(localStorage.getItem("user"));
    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    };
    const response = await axios({
      method: "POST",
      baseURL: `${update_adders_table}`,
      headers: headers,
      data: data,
    });
    if (response?.status == 200) {
      toast.success("modified Successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return response.data;
  };

const adders_table_Service = {
    Get_adders_table_Action,
    edit_adders_data_Action
};
export default adders_table_Service;
