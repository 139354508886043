import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { SteperContainer } from "../../Components/ProcessSteper/ProcessSteperStyle";
import {
  getCharterDetails,
  approveCharter,
} from "../../Redux/Slices/CharterSlice/CharterSlice";
import { getDesignReqDetails } from "../../Redux/Slices/DesignRequest/DesignRequestSlice";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { PrimaryButton } from "../../Layouts/ButtonsControlled";
import DesignReqData from "../../Components/DesignReqData/DesignReqData";
import { Grid, IconButton, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
const DesignReqDetails = () => {
  const navigate = useNavigate();
  const { lead_id } = useParams();
  const { type } = useParams();
  const dispatch = useDispatch();
  const [status, setStatus] = useState();
  const design_req_details = useSelector((state) => state.design_req);

  //   const charter_id = design_req?.charter?.data?.id;
  //   console.log(
  //     lead_id,
  //     charter_details?.charter?.data?.id,
  //     "lead_id in charter details"
  //   );
  useEffect(() => {
    dispatch(getDesignReqDetails(lead_id));
  }, []);

  console.log(status, "status");
  const change = (event) => {
    //this.setState({value: event.target.value});
    setStatus(event.target.value);
  };

  //   const handleConfiremCharter = () => {
  //     const reqData = {
  //       id: charter_id,
  //       status: status,
  //     };
  //     dispatch(approveCharter(reqData));
  //   };
  return (
    <>
      <>
        <MainTabsStyled>
          <Box
            sx={{ width: "100%", typography: "body1" }}
            style={{ backgroundColor: "white" }}
          >
            <Grid container columns={12}>
              {/* <p>test</p> */}
              <AppBar position="static"
                            style={{ backgroundColor: "white", color: "black" }}
                        >
                            <Toolbar>
                                <IconButton
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    sx={{ mr: 2 }}
                                    onClick={() => navigate(`/home/SolarSolution/start-project/${lead_id}`)}

                                >
                                    <ArrowBackIcon />
                                </IconButton>

                                <Typography variant="h5" align="center">Design request details</Typography>
                            </Toolbar>
                        </AppBar>
              <Grid item xs={12} sm={12} lg={12}>
                <SteperContainer>
                  <DesignReqData design_req_details={design_req_details} />
                </SteperContainer>
              </Grid>
            </Grid>
          </Box>
        </MainTabsStyled>
      </>
    </>
  );
};
const MainTabsStyled = styled.div`
  margin: 1rem 0.8rem;
  border-radius: 5px;
`;
export default DesignReqDetails;
