import React, { useEffect, useState } from "react";
import {
  Button,
  Typography,
  Grid,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  IconButton,
} from "@mui/material";
import LottieAnimation from "../../Components/LottieAnimation/LottieAnimation";
import loading from "../../Assets/lottie/loading.json";
import { useDispatch, useSelector } from "react-redux";
import { get_call_jurisdiction_data } from "../../Redux/Slices/call_jurisdictions_slice/call_jurisdictions_slice";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import CalljurisdictionsModal from "../../Components/Modals/call-jurisdictions-Modal/call-jurisdictions-Modal";
import { getTicketStatus } from "../../Redux/Slices/TicketStatus/TicketStatusSlice";
const Call_jurisdiction_Details = ({ lead_id }) => {
  const dispatch = useDispatch();
  const call_jurisdictions = useSelector((state) => state.call_jurisdictions);
  const cardStatus = useSelector((state) => state.TicketStatus);
  const call_jurisdictions_status =
    cardStatus?.TicketStatus?.data?.call_jurisdiction;
  const [open_edit_mmodal, set_edit_modal] = useState(false);
  const call_jurisdiction_details =
    call_jurisdictions?.call_jurisdictions?.data;
    console.log(call_jurisdictions?.call_jurisdictions , "call_jurisdiction_details")

  const handle_open_edit_modal = () => {
    set_edit_modal(true);
  };
  const handleCloseModal = () => {
    set_edit_modal(false);
  };
  useEffect(() => {
    dispatch(get_call_jurisdiction_data(lead_id));
    dispatch(getTicketStatus(lead_id));
  }, [open_edit_mmodal]);
  return (
    <>
      {call_jurisdictions?.isLodaing ? (
        <>
          <LottieAnimation lotti={loading} width={180} height={180} />
        </>
      ) : (
        <>
          <Grid item xs={12} sm={12} lg={12}>
            {call_jurisdictions?.call_jurisdictions == null ? (
              <Typography>There is no data </Typography>
            ) : (
              <Card>
                <CardHeader
                  title="call jurisdiction Data"
                  subheader={`Created at: ${new Date(
                    call_jurisdictions?.call_jurisdictions?.data?.created_at
                  ).toLocaleDateString("en-US", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  })}
                  
                  `}
                />

                <CardContent>
                  <Typography variant="h6" color="text.secondary">
                    Status:{" "}
                    {call_jurisdictions?.call_jurisdictions?.data?.status}
                  </Typography>
                  <Typography variant="h6" color="text.primary" mb={1}>
                    is under jurisdiction:{" "}
                    {call_jurisdictions?.call_jurisdictions?.data
                      ?.is_under_jurisdiction == 1 ? (
                      <span>
                        <CheckIcon style={{ color: "green" }} />
                      </span>
                    ) : (
                      <CloseIcon />
                    )}
                  </Typography>
                  <Typography variant="h6" color="text.primary">
                    is need license:{" "}
                    {call_jurisdictions?.call_jurisdictions?.data
                      ?.is_need_license == 1 ? (
                      <CheckIcon style={{ color: "green" }} />
                    ) : (
                      <CloseIcon />
                    )}
                  </Typography>
                  <Typography variant="h6" color="text.primary">
                    is have license:{" "}
                    {call_jurisdictions?.call_jurisdictions?.data
                      ?.is_have_license == 1 ? (
                      <CheckIcon style={{ color: "green" }} />
                    ) : (
                      <CloseIcon style={{ color: "red" }} />
                    )}
                  </Typography>
                  {call_jurisdictions?.call_jurisdictions?.data
                    ?.is_have_license == 0 && (
                    <Typography variant="h6" color="text.primary">
                      is have license:{" "}
                      <span style={{ color: "gray" }}>
                        {
                          call_jurisdictions?.call_jurisdictions?.data
                            ?.how_to_renew_or_acquired
                        }
                      </span>
                    </Typography>
                  )}
                  <Typography variant="h6" color="text.primary">
                    permit submit type:{" "}
                    <span style={{ color: "gray" }}>
                      {
                        call_jurisdictions?.call_jurisdictions?.data
                          ?.permit_submit_type
                      }
                    </span>
                  </Typography>
                  {call_jurisdictions?.call_jurisdictions?.data
                    ?.permit_submit_type == "over_the_counter" && (
                    <>
                      <Typography variant="h6" color="text.primary">
                        permit copies needed:{" "}
                        <span style={{ color: "gray" }}>
                          {
                            call_jurisdictions?.call_jurisdictions?.data
                              ?.permit_copies_needed
                          }
                        </span>
                      </Typography>

                      <Typography variant="h6" color="text.primary">
                        plan size:{" "}
                        <span style={{ color: "gray" }}>
                          {
                            call_jurisdictions?.call_jurisdictions?.data
                              ?.plan_size
                          }
                        </span>
                      </Typography>

                      <Typography variant="h6" color="text.primary">
                        is colored:{" "}
                        <span style={{ color: "gray" }}>
                          {call_jurisdictions?.call_jurisdictions?.data
                            ?.is_colored == 1 ? (
                            <CheckIcon style={{ color: "green" }} />
                          ) : (
                            <CloseIcon style={{ color: "red" }} />
                          )}
                        </span>
                      </Typography>
                    </>
                  )}
                  <Typography variant="h6" color="text.primary">
                    is rough inspection for racking:{" "}
                    <span style={{ color: "gray" }}>
                      {call_jurisdictions?.call_jurisdictions?.data
                        ?.is_rough_inspection_for_racking == 1 ? (
                        <CheckIcon style={{ color: "green" }} />
                      ) : (
                        <CloseIcon style={{ color: "red" }} />
                      )}
                    </span>
                  </Typography>
                  <Typography variant="h6" color="text.primary">
                    permit timeline to be acquired:{" "}
                    <span style={{ color: "gray" }}>
                      {
                        call_jurisdictions?.call_jurisdictions?.data
                          ?.permit_timeline_to_be_acquired
                      }
                    </span>
                  </Typography>
                  <Typography variant="h6" color="text.primary">
                    is split permit needed:{" "}
                    <span style={{ color: "gray" }}>
                      {call_jurisdictions?.call_jurisdictions?.data
                        ?.is_split_permit_needed == 1 ? (
                        <CheckIcon style={{ color: "green" }} />
                      ) : (
                        <CloseIcon style={{ color: "red" }} />
                      )}
                    </span>
                  </Typography>
                  <Typography variant="h6" color="text.primary">
                    is person to follow:{" "}
                    <span style={{ color: "gray" }}>
                      {call_jurisdictions?.call_jurisdictions?.data
                        ?.is_person_to_follow == 1 ? (
                        <CheckIcon style={{ color: "green" }} />
                      ) : (
                        <CloseIcon style={{ color: "red" }} />
                      )}
                    </span>
                  </Typography>
                  <Typography variant="h6" color="text.primary">
                    person phone or email:{" "}
                    <span style={{ color: "gray" }}>
                      {
                        call_jurisdictions?.call_jurisdictions?.data
                          ?.person_phone_or_email
                      }
                    </span>
                  </Typography>

                  <Typography variant="h6" color="text.primary">
                    is stamped plans needed:{" "}
                    <span style={{ color: "gray" }}>
                      {call_jurisdictions?.call_jurisdictions?.data
                        ?.is_stamped_plans_needed == 1 ? (
                        <CheckIcon style={{ color: "green" }} />
                      ) : (
                        <CloseIcon style={{ color: "red" }} />
                      )}
                    </span>
                  </Typography>

                  {call_jurisdictions?.call_jurisdictions?.data
                    ?.is_stamped_plans_needed == 1 && (
                    <Typography variant="h6" color="text.primary">
                      stamped plan type:{" "}
                      <span style={{ color: "gray" }}>
                        {
                          call_jurisdictions?.call_jurisdictions?.data
                            ?.stamped_plan_type
                        }
                      </span>
                    </Typography>
                  )}

                  <Typography variant="h6" color="text.primary">
                    is smoke detectors needed:{" "}
                    <span style={{ color: "gray" }}>
                      {call_jurisdictions?.call_jurisdictions?.data
                        ?.is_smoke_detectors_needed == 1 ? (
                        <CheckIcon style={{ color: "green" }} />
                      ) : (
                        <CloseIcon style={{ color: "red" }} />
                      )}
                    </span>
                  </Typography>
                  {call_jurisdictions?.call_jurisdictions?.data
                    ?.is_smoke_detectors_needed == 1 && (
                    <>
                      <Typography variant="h6" color="text.primary">
                        is notarized:{" "}
                        <span style={{ color: "gray" }}>
                          {call_jurisdictions?.call_jurisdictions?.data
                            ?.is_notarized == 1 ? (
                            <CheckIcon style={{ color: "green" }} />
                          ) : (
                            <CloseIcon style={{ color: "red" }} />
                          )}
                        </span>
                      </Typography>
                      <Typography variant="h6" color="text.primary">
                        how to get smoke detectors:{" "}
                        <span style={{ color: "gray" }}>
                          {
                            call_jurisdictions?.call_jurisdictions?.data
                              ?.how_to_get_smoke_detectors
                          }
                        </span>
                      </Typography>
                    </>
                  )}

                  <Typography variant="h6" color="text.primary">
                    special request to plans:{" "}
                    <span style={{ color: "gray" }}>
                      {
                        call_jurisdictions?.call_jurisdictions?.data
                          ?.special_request_to_plans
                      }
                    </span>
                  </Typography>

                  <Typography variant="h6" color="text.primary">
                    additional requirements:{" "}
                    <span style={{ color: "gray" }}>
                      {
                        call_jurisdictions?.call_jurisdictions?.data
                          ?.additional_requirements
                      }
                    </span>
                  </Typography>
                </CardContent>
                <CardActions disableSpacing>
                  <IconButton
                    aria-label="share"
                    // onClick={handleopen_second_step_Modal}
                    // onClick={handleopen_second_step_Modal}
                    onClick={handle_open_edit_modal}
                  >
                    <EditIcon />
                  </IconButton>
                </CardActions>
              </Card>
            )}
            {open_edit_mmodal && (
              <CalljurisdictionsModal
                handleopenModal={handle_open_edit_modal}
                handleCloseModal={handleCloseModal}
                call_jurisdiction_details={call_jurisdiction_details}
                set_edit_modal={set_edit_modal}
                call_jurisdictions_status={call_jurisdictions_status}
                call_jurisdictions_data ={call_jurisdictions?.call_jurisdictions}
                initialValues={{
                  additional_requirements:
                    call_jurisdiction_details?.additional_requirements,
                  special_request_to_plans:
                    call_jurisdiction_details?.special_request_to_plans,
                  how_to_get_smoke_detectors:
                    call_jurisdiction_details?.how_to_get_smoke_detectors,

                  person_phone_or_email:
                    call_jurisdiction_details?.person_phone_or_email,
                  permit_timeline_to_be_acquired:
                    call_jurisdiction_details?.permit_timeline_to_be_acquired,
                  plan_size: call_jurisdiction_details?.plan_size,
                  permit_copies_needed:
                    call_jurisdiction_details?.permit_copies_needed,
                  permit_submit_type:
                    call_jurisdiction_details?.permit_submit_type,
                  status: call_jurisdiction_details?.status,
                  how_to_renew_or_acquired:
                    call_jurisdiction_details?.how_to_renew_or_acquired,
                  stamped_plan_type:
                    call_jurisdiction_details?.stamped_plan_type,
                }}
              />
              // <p>ghjkhvghj</p>
            )}
          </Grid>
        </>
      )}
    </>
  );
};

export default Call_jurisdiction_Details;
