import axios from "axios";
import {
  apply_pto,
  delete_PTO_files,
  NTP,
  delete_NTP_files,
  apply_meter_spot,
  delete_meter_spot_files,
  quotes_for_products,
  delete_quotes_for_products,
  product_orders,
  delete_product_orders,
  permit_to_finance,
  delete_permit_to_finance_files,
  order_place_cards,
  delete_order_place_cards,
  coordinate_product_delivery,
  delete_coordinate_product_delivery,
  coordinate_installations,
  delete_coordinate_installations_media,
  schedule_inspections,
  delete_schedule_inspections_media,
  installer_invoices,
  delete_installer_invoices_files,
  upload_to_pto,
  delete_upload_to_pto_files,
  send_full_details_to_client,
  delete_send_full_details_to_client_files,
  review_profit_loss_statement,
  delete_review_profit_loss_statement_files,
  closure_report,
  delete_closure_report_files,
  installation_payment_collection,
  delete_installation_payment_collection_files,
  installer_commissions,
  delete_installer_commissions_files,
  installation_pics,
  delete_installation_pics_files,
  confirm_inspection,
  delete_confirm_inspection_files,
  apply_permits,
  delete_apply_permits_files,
  end_project_call,
  delete_end_project_call_files,
  upload_PTO_to_finance,
  delete_upload_PTO_to_finance_files,
  final_job_card,
  delete_final_job_card_files,
  schedule_with_city_office,
  delete_schedule_with_city_office_files,
  inform_inspection_result,
  delete_inspection_result_files,
  monitoring_optimizers,
  delete_monitoring_optimizers_files,
  m1_collection,
  delete_m1_collection,
  m2_collection,
  delete_m2_collection,
  StoreFinalInspection,
  delete_final_inspection_files,
  Store_call_designer,
  edit_call_designer,
} from "../../../Api/Api";
import { toast } from "react-toastify";
const apply_pto_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${apply_pto}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Status added Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const get_PTO_data = async (id) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${apply_pto}/${id}`,
    headers: headers,
    // data: data,
  });
  return response.data;
};

const edit__PTO_data = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "Post",
    baseURL: `${apply_pto}/${data.id}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Updated Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const delete_pto_file_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${delete_PTO_files}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("file deleted Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const create_NTP_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${NTP}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Status added Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const get_NTP_data = async (id) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${NTP}/${id}`,
    headers: headers,
    // data: data,
  });
  return response.data;
};

const edit__NTP_data = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "Post",
    baseURL: `${NTP}/${data.id}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Updated Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const delete_NTP_file_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${delete_NTP_files}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("file deleted Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const apply_meter_spot_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${apply_meter_spot}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Status added Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const get_meter_spot_data = async (id) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${apply_meter_spot}/${id}`,
    headers: headers,
    // data: data,
  });
  return response.data;
};

const edit__meter_spot_data = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "Post",
    baseURL: `${apply_meter_spot}/${data.id}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Updated Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const delete_meter_spot_file_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${delete_meter_spot_files}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("file deleted Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const create_quotes_for_products_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${quotes_for_products}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Status added Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const get_quotes_for_products_data = async (id) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${quotes_for_products}/${id}`,
    headers: headers,
    // data: data,
  });
  return response.data;
};

const edit__quotes_for_products_data = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "Post",
    baseURL: `${quotes_for_products}/${data.id}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Updated Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const delete_quotes_for_products_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${delete_quotes_for_products}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("file deleted Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const create_product_orders_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${product_orders}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Status added Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const get_product_orders_data = async (id) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${product_orders}/${id}`,
    headers: headers,
    // data: data,
  });
  return response.data;
};

const edit__product_orders_data = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "Post",
    baseURL: `${product_orders}/${data.id}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Updated Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const delete_product_orders_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${delete_product_orders}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("file deleted Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const create_permit_to_finance_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${permit_to_finance}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Status added Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const get_permit_to_finance_data = async (id) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${permit_to_finance}/${id}`,
    headers: headers,
    // data: data,
  });
  return response.data;
};

const edit__permit_to_finance_data = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "Post",
    baseURL: `${permit_to_finance}/${data.id}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Updated Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const delete_permit_to_finance_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${delete_permit_to_finance_files}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("file deleted Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const create_coordinate_product_delivery_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${coordinate_product_delivery}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Status added Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const get_coordinate_product_delivery_data = async (id) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${coordinate_product_delivery}/${id}`,
    headers: headers,
    // data: data,
  });
  return response.data;
};

const edit__coordinate_product_delivery_data = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "Post",
    baseURL: `${coordinate_product_delivery}/${data.id}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Updated Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const delete_coordinate_product_delivery_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${delete_coordinate_product_delivery}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("file deleted Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const create_coordinate_installations_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${coordinate_installations}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Status added Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const get_coordinate_installations_data = async (id) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${coordinate_installations}/${id}`,
    headers: headers,
    // data: data,
  });
  return response.data;
};

const edit_coordinate_installations_data = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "Post",
    baseURL: `${coordinate_installations}/${data.id}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Updated Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const delete_coordinate_installations_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${delete_coordinate_installations_media}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("file deleted Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const create_order_place_cards_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${order_place_cards}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Status added Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const get_order_place_cards_data = async (id) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${order_place_cards}/${id}`,
    headers: headers,
    // data: data,
  });
  return response.data;
};

const edit__order_place_cards_data = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "Post",
    baseURL: `${order_place_cards}/${data.id}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Updated Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const delete_order_place_cards_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${delete_order_place_cards}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("file deleted Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const create_schedule_inspections_cards_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${schedule_inspections}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Status added Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const get_schedule_inspections_data = async (id) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${schedule_inspections}/${id}`,
    headers: headers,
    // data: data,
  });
  return response.data;
};

const edit__schedule_inspections_data = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "Post",
    baseURL: `${schedule_inspections}/${data.id}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Updated Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const delete_schedule_inspections_files_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${delete_schedule_inspections_media}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("file deleted Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const create_installer_invoices_cards_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${installer_invoices}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Status added Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const get_installer_invoices_data = async (id) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${installer_invoices}/${id}`,
    headers: headers,
    // data: data,
  });
  return response.data;
};

const edit__installer_invoices_data = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "Post",
    baseURL: `${installer_invoices}/${data.id}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Updated Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const delete_installer_invoices_files_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${delete_installer_invoices_files}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("file deleted Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const create_upload_to_pto_cards_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${upload_to_pto}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Status added Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const get_upload_to_pto_data = async (id) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${upload_to_pto}/${id}`,
    headers: headers,
    // data: data,
  });
  return response.data;
};

const edit__upload_to_pto_data = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "Post",
    baseURL: `${upload_to_pto}/${data.id}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Updated Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const delete__upload_to_pto_files_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${delete_upload_to_pto_files}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("file deleted Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const create_send_full_details_to_client_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${send_full_details_to_client}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Status added Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const get_send_full_details_to_client_data = async (id) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${send_full_details_to_client}/${id}`,
    headers: headers,
    // data: data,
  });
  return response.data;
};

const edit__send_full_details_to_client_data = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "Post",
    baseURL: `${send_full_details_to_client}/${data.id}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Updated Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const delete_send_full_details_to_client_filess = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${delete_send_full_details_to_client_files}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("file deleted Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const create_review_profit_loss_statement_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${review_profit_loss_statement}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Status added Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const get_review_profit_loss_statement_data = async (id) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${review_profit_loss_statement}/${id}`,
    headers: headers,
    // data: data,
  });
  return response.data;
};

const edit__review_profit_loss_statement_data = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "Post",
    baseURL: `${review_profit_loss_statement}/${data.id}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Updated Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const delete_review_profit_loss_statement_filess = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${delete_review_profit_loss_statement_files}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("file deleted Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const create_closure_report_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${closure_report}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Status added Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const get_closure_report_data = async (id) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${closure_report}/${id}`,
    headers: headers,
    // data: data,
  });
  return response.data;
};

const edit__closure_report_data = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "Post",
    baseURL: `${closure_report}/${data.id}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Updated Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const delete_closure_report__filess = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${delete_closure_report_files}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("file deleted Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const create_installation_payment_collection_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${installation_payment_collection}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Status added Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const get_installation_payment_collection_data = async (id) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${installation_payment_collection}/${id}`,
    headers: headers,
    // data: data,
  });
  return response.data;
};

const edit__installation_payment_collection_data = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "Post",
    baseURL: `${installation_payment_collection}/${data.id}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Updated Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const delete_installation_payment_collection__filess = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${delete_installation_payment_collection_files}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("file deleted Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const create_installer_commissions_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${installer_commissions}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Status added Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const get_installer_commissions_data = async (id) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${installer_commissions}/${id}`,
    headers: headers,
    // data: data,
  });
  return response.data;
};

const edit__installer_commissions_data = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "Post",
    baseURL: `${installer_commissions}/${data.id}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Updated Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const delete_installer_commissions_filess = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${delete_installer_commissions_files}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("file deleted Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const create_installation_pics_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${installation_pics}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Status added Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const get_installation_pics_data = async (id) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${installation_pics}/${id}`,
    headers: headers,
    // data: data,
  });
  return response.data;
};

const edit__installation_pics_data = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "Post",
    baseURL: `${installation_pics}/${data.id}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Updated Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const delete_installation_pics_filess = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${delete_installation_pics_files}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("file deleted Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const create_confirm_inspection_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${confirm_inspection}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Status added Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const get_confirm_inspection_data = async (id) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${confirm_inspection}/${id}`,
    headers: headers,
    // data: data,
  });
  return response.data;
};

const edit__confirm_inspection_data = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "Post",
    baseURL: `${confirm_inspection}/${data.id}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Updated Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const delete_confirm_inspection_filess = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${delete_confirm_inspection_files}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("file deleted Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};
const apply_permits_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${apply_permits}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Status added Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const get_apply_permits_data = async (id) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${apply_permits}/${id}`,
    headers: headers,
    // data: data,
  });
  return response.data;
};

const edit__apply_permits_data = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "Post",
    baseURL: `${apply_permits}/${data.id}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Updated Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const delete_apply_permits_filess = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${delete_apply_permits_files}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("file deleted Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const create_end_project_call_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${end_project_call}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Status added Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const get_end_project_call_data = async (id) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${end_project_call}/${id}`,
    headers: headers,
    // data: data,
  });
  return response.data;
};

const edit__end_project_call_data = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "Post",
    baseURL: `${end_project_call}/${data.id}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Updated Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const delete_end_project_call_filess = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${delete_end_project_call_files}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("file deleted Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const create_upload_PTO_to_finance_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${upload_PTO_to_finance}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Status added Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const get_upload_PTO_to_finance_data = async (id) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${upload_PTO_to_finance}/${id}`,
    headers: headers,
    // data: data,
  });
  return response.data;
};

const edit__upload_PTO_to_finance_data = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "Post",
    baseURL: `${upload_PTO_to_finance}/${data.id}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Updated Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const delete_upload_PTO_to_finance_filess = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${delete_upload_PTO_to_finance_files}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("file deleted Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const create_final_job_card_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${final_job_card}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Status added Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const get_final_job_card_data = async (id) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${final_job_card}/${id}`,
    headers: headers,
    // data: data,
  });
  return response.data;
};

const edit__final_job_card_data = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "Post",
    baseURL: `${final_job_card}/${data.id}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Updated Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const delete_final_job_card_filess = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${delete_final_job_card_files}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("file deleted Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const create_schedule_with_city_office_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${schedule_with_city_office}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Status added Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const get_schedule_with_city_office_data = async (id) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${schedule_with_city_office}/${id}`,
    headers: headers,
    // data: data,
  });
  return response.data;
};

const edit__schedule_with_city_office_data = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "Post",
    baseURL: `${schedule_with_city_office}/${data.id}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Updated Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const delete_schedule_with_city_office_filess = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${delete_schedule_with_city_office_files}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("file deleted Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const create_inform_inspection_result_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${inform_inspection_result}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Status added Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const get_inform_inspection_result_data = async (id) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${inform_inspection_result}/${id}`,
    headers: headers,
    // data: data,
  });
  return response.data;
};

const edit__inform_inspection_result_data = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "Post",
    baseURL: `${inform_inspection_result}/${data.id}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Updated Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const delete_inspection_result_filess = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${delete_inspection_result_files}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("file deleted Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const create_monitoring_optimizers_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${monitoring_optimizers}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Status added Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const get_monitoring_optimizers_data = async (id) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${monitoring_optimizers}/${id}`,
    headers: headers,
    // data: data,
  });
  return response.data;
};

const edit__monitoring_optimizers_data = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "Post",
    baseURL: `${monitoring_optimizers}/${data.id}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Updated Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const delete_monitoring_optimizers_filess = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${delete_monitoring_optimizers_files}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("file deleted Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const create_m1_collection_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${m1_collection}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Status added Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const get_m1_collection_data = async (id) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${m1_collection}/${id}`,
    headers: headers,
    // data: data,
  });
  return response.data;
};

const edit__m1_collection_data = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "Post",
    baseURL: `${m1_collection}/${data.id}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Updated Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const delete_m1_collection_files = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${delete_m1_collection}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("file deleted Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};



const create_m2_collection_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${m2_collection}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Status added Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const get_m2_collection_data = async (id) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${m2_collection}/${id}`,
    headers: headers,
    // data: data,
  });
  return response.data;
};

const edit__m2_collection_data = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "Post",
    baseURL: `${m2_collection}/${data.id}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Updated Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const delete_m2_collection_files = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${delete_m2_collection}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("file deleted Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const create_final_inspection_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${StoreFinalInspection}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Status added Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const get_final_inspection_data = async (id) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${StoreFinalInspection}/${id}`,
    headers: headers,
    // data: data,
  });
  return response.data;
};

const edit__final_inspection_data = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "Post",
    baseURL: `${StoreFinalInspection}/${data.id}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Updated Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const delete_final_inspection_filess = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${delete_final_inspection_files}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("file deleted Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const create_call_designer = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${Store_call_designer}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Status added Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const get_call_designer_data = async (id) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${Store_call_designer}/${id}`,
    headers: headers,
    // data: data,
  });
  return response.data;
};

const edit_call_designer_data = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "Post",
    baseURL: `${edit_call_designer}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Updated Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const cardStatusService = {
  apply_pto_Action,
  get_PTO_data,
  edit__PTO_data,
  delete_pto_file_Action,
  create_NTP_Action,
  get_NTP_data,
  edit__NTP_data,
  delete_NTP_file_Action,
  apply_meter_spot_Action,
  get_meter_spot_data,
  edit__meter_spot_data,
  delete_meter_spot_file_Action,
  create_quotes_for_products_Action,
  get_quotes_for_products_data,
  edit__quotes_for_products_data,
  delete_quotes_for_products_Action,
  create_product_orders_Action,
  get_product_orders_data,
  edit__product_orders_data,
  delete_product_orders_Action,
  create_permit_to_finance_Action,
  get_permit_to_finance_data,
  edit__permit_to_finance_data,
  delete_permit_to_finance_Action,
  create_order_place_cards_Action,
  get_order_place_cards_data,
  edit__order_place_cards_data,
  delete_order_place_cards_Action,
  create_coordinate_product_delivery_Action,
  get_coordinate_product_delivery_data,
  edit__coordinate_product_delivery_data,
  delete_coordinate_product_delivery_Action,
  create_coordinate_installations_Action,
  get_coordinate_installations_data,
  edit_coordinate_installations_data,
  delete_coordinate_installations_Action,
  create_schedule_inspections_cards_Action,
  get_schedule_inspections_data,
  edit__schedule_inspections_data,
  delete_schedule_inspections_files_Action,
  create_installer_invoices_cards_Action,
  get_installer_invoices_data,
  edit__installer_invoices_data,
  delete_installer_invoices_files_Action,
  create_upload_to_pto_cards_Action,
  get_upload_to_pto_data,
  edit__upload_to_pto_data,
  delete__upload_to_pto_files_Action,
  create_send_full_details_to_client_Action,
  get_send_full_details_to_client_data,
  edit__send_full_details_to_client_data,
  delete_send_full_details_to_client_filess,
  create_review_profit_loss_statement_Action,
  get_review_profit_loss_statement_data,
  edit__review_profit_loss_statement_data,
  delete_review_profit_loss_statement_filess,
  create_closure_report_Action,
  get_closure_report_data,
  edit__closure_report_data,
  delete_closure_report__filess,
  create_installation_payment_collection_Action,
  get_installation_payment_collection_data,
  edit__installation_payment_collection_data,
  delete_installation_payment_collection__filess,
  create_installer_commissions_Action,
  get_installer_commissions_data,
  edit__installer_commissions_data,
  delete_installer_commissions_filess,
  create_installation_pics_Action,
  get_installation_pics_data,
  edit__installation_pics_data,
  delete_installation_pics_filess,
  create_confirm_inspection_Action,
  get_confirm_inspection_data,
  edit__confirm_inspection_data,
  delete_confirm_inspection_filess,
  apply_permits_Action,
  get_apply_permits_data,
  edit__apply_permits_data,
  delete_apply_permits_filess,
  create_end_project_call_Action,
  get_end_project_call_data,
  edit__end_project_call_data,
  delete_end_project_call_filess,
  create_upload_PTO_to_finance_Action,
  get_upload_PTO_to_finance_data,
  edit__upload_PTO_to_finance_data,
  delete_upload_PTO_to_finance_filess,
  create_final_job_card_Action,
  get_final_job_card_data,
  edit__final_job_card_data,
  delete_final_job_card_filess,
  create_schedule_with_city_office_Action,
  get_schedule_with_city_office_data,
  edit__schedule_with_city_office_data,
  delete_schedule_with_city_office_filess,
  delete_inspection_result_filess,
  edit__inform_inspection_result_data,
  get_inform_inspection_result_data,
  create_inform_inspection_result_Action,
  create_monitoring_optimizers_Action,
  get_monitoring_optimizers_data,
  edit__monitoring_optimizers_data,
  delete_monitoring_optimizers_filess,
  create_m1_collection_Action,
  get_m1_collection_data,
  edit__m1_collection_data,
  delete_m1_collection_files,
  create_m2_collection_Action,
  get_m2_collection_data,
  edit__m2_collection_data,
  delete_m2_collection_files,
  create_final_inspection_Action,
  get_final_inspection_data,
  edit__final_inspection_data,
  delete_final_inspection_filess,
  create_call_designer,
  get_call_designer_data,
  edit_call_designer_data,
};
export default cardStatusService;
