import axios from "axios";
import {
  Creat_charter,
  charter_details,
  approve_chartet,
} from "../../../Api/Api";
import { toast } from "react-toastify";
const createCharterAction = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${Creat_charter}`,
    headers: headers,
    data: data,
  });
  console.log(response, "response");
  if (response?.status == 200) {
    toast.success("Charter Created Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } else {
    toast.error("something went wrong", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const getCharterDetailsAction = async (id) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${charter_details}/${id}`,
    headers: headers,
    // data: data,
  });
  return response.data;
};

const approveCharterAction = async (data) => {
  console.log("data", data);
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${approve_chartet}/${data.id}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Welcome Package Sent Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const createCharterService = {
  createCharterAction,
  getCharterDetailsAction,
  approveCharterAction,
};
export default createCharterService;
