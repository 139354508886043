import { Typography, Grid ,Card  , Badge} from "@mui/material";
import React from "react";
import styled from "styled-components";
import Divider from "@mui/material/Divider";
import LottieAnimation from "../../Components/LottieAnimation/LottieAnimation";
import loading from "../../Assets/lottie/loading.json";
import CardContent from "@mui/material/CardContent";
import ImageIcon from "@mui/icons-material/Image";
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
const DesignReqData = ({ design_req_details  , lead_id}) => {
  const navigate = useNavigate()
  const design_req_data = design_req_details?.design_req?.data;
  console.log(design_req_details?.design_req?.data
    , "design_req_details454NNNN");
  console.log(design_req_data, "design_req_details");

  return (
    <div style={{ marginLeft: "20px", padding: "5px" }}>
      {design_req_details?.isLodaing ? (
        <LottieAnimation lotti={loading} width={180} height={180} />
      ) : design_req_details?.design_req !== null ? (
        // <Grid item>
        //   <Grid
        //     container
        //     direction="row"
        //     justifyContent="flex-start"
        //     alignItems="baseline"
        //     columns={18}
        //     spacing={4}
        //     style={{ textAlign: "left" }}
        //   >
        //     <Grid item lg={18} md={18} sm={18} xs={18}>
        //       {/* <Heading> */}
        //       <Typography align="center" variant="h6">
        //         Design Request Details
        //       </Typography>
        //       {/* </Heading> */}
        //     </Grid>
        //     <Grid item lg={18} md={18} sm={18} xs={18}>
        //       {/* <Heading> */}
        //       <Typography variant="h6">Customer Information : </Typography>
        //       {/* </Heading> */}
        //     </Grid>
        //     <Grid item lg={6} md={6} sm={12} xs={12}>
        //       <p>
        //         customer_name : <Value>{design_req_data?.customer_name}</Value>
        //       </p>
        //     </Grid>
        //     <Grid item lg={8} md={6} sm={12} xs={12}>
        //       <p>
        //         customer_address :{" "}
        //         <Value>{design_req_data?.customer_address}</Value>
        //       </p>
        //     </Grid>

        //     <Grid item xs={18} style={{ margin: "5px auto" }}>
        //       <Divider />
        //     </Grid>
        //     <Grid item lg={18} md={18} sm={18} xs={18}>
        //       {/* <Heading> */}
        //       <Typography variant="h6">Designer Information : </Typography>
        //       {/* </Heading> */}
        //     </Grid>
        //     <Grid item lg={6} md={6} sm={12} xs={12}>
        //       <p>
        //         designer : <Value>{design_req_data?.designer_id}</Value>
        //       </p>
        //     </Grid>
        //     <Grid item lg={6} md={6} sm={12} xs={12}>
        //       <p>
        //         Designer email: <Value>{design_req_data?.email}</Value>
        //       </p>
        //     </Grid>

        //     <Grid item xs={18} style={{ margin: "5px auto" }}>
        //       <Divider />
        //     </Grid>
        //     <Grid item lg={18} md={18} sm={18} xs={18}>
        //       {/* <Heading> */}
        //       <Typography variant="h6">Details : </Typography>
        //       {/* </Heading> */}
        //     </Grid>

        //     <Grid item lg={6} md={6} sm={12} xs={12}>
        //       <p>
        //         panels_wattage :{" "}
        //         <Value>{design_req_data?.panels_wattage}</Value>
        //       </p>
        //     </Grid>

        //     <Grid item lg={6} md={6} sm={12} xs={12}>
        //       <p>
        //         panels_model : <Value>{design_req_data?.panels_model}</Value>
        //       </p>
        //     </Grid>

        //     <Grid item lg={6} md={6} sm={12} xs={12}>
        //       <p>
        //         inverter_manufacture :{" "}
        //         <Value>{design_req_data?.invert_manufacture}</Value>
        //       </p>
        //     </Grid>

        //     <Grid item lg={6} md={6} sm={12} xs={12}>
        //       <p>
        //         inverter_model : <Value>{design_req_data?.invert_model}</Value>
        //       </p>
        //     </Grid>

        //     <Grid item lg={6} md={6} sm={12} xs={12}>
        //       <p>
        //         is_mpu : <Value>{design_req_data?.is_mpu}</Value>
        //       </p>
        //     </Grid>

        //     <Grid item lg={6} md={6} sm={12} xs={12}>
        //       <p>
        //         mpu_from_to : <Value>{design_req_data?.mpu_from_to}</Value>
        //       </p>
        //     </Grid>

        //     <Grid item lg={6} md={6} sm={12} xs={12}>
        //       <p>
        //         msp : <Value>{design_req_data?.msp}</Value>
        //       </p>
        //     </Grid>
        //     <Grid item lg={6} md={6} sm={12} xs={12}>
        //       <p>
        //         location : <Value>{design_req_data?.location}</Value>
        //       </p>
        //     </Grid>

        //     <Grid item lg={6} md={6} sm={12} xs={12}>
        //       <p>
        //         battery : <Value>{design_req_data?.battery}</Value>
        //       </p>
        //     </Grid>

        //     <Grid item lg={6} md={6} sm={12} xs={12}>
        //       <p>
        //         contractor_name :{" "}
        //         <Value>{design_req_data?.contractor_name}</Value>
        //       </p>
        //     </Grid>
        //     <Grid item lg={6} md={6} sm={12} xs={12}>
        //       <p>
        //         contractor_address :{" "}
        //         <Value>{design_req_data?.contractor_address}</Value>
        //       </p>
        //     </Grid>
        //     <Grid item lg={6} md={6} sm={12} xs={12}>
        //       <p>
        //         contractor_license :{" "}
        //         <Value>{design_req_data?.contractor_license}</Value>
        //       </p>
        //     </Grid>
        //   </Grid>
        // </Grid>
      
        <>
             <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                      {design_req_data&&
                      design_req_data
                        ?.length > 0 ? (
                        design_req_data?.map(
                          (data) => (
                            <Grid item sm={6} xs={12}>
                                <Card
                            style={{
                              background: "rgb(249, 245, 255)",
                              border: "1px solid rgb(214, 187, 251)",
                              borderRadius: "8px",
                              padding: "1rem",
                              margin: "0.5rem 0px",
                              position: "relative",

                            }}
                          >

                            <Badge sx={{ position: "absolute", left: "50.5%", top: "23px" }}  badgeContent={

                              // site_survey?.installer_approval
                              <div  style={{
                                width: "120px",
                                height: "47px",
                                backgroundColor: "slategrey",
                                color: "blanchedalmond",
                                fontSize: "14px",
                                borderRadius: "6%",
                                padding: "13px 6px",
                                display:"flex",
                                justifyContent:"center"

                              }}>
                                {data?.status}
                              </div>

                            }
                              style={{ position: "absolute", left: "89%", top: "23px" }}>

                            </Badge>
                              <CardContent
                                style={{
                                  // background: "rgb(249, 245, 255)",
                                  // border: "1px solid rgb(214, 187, 251)",
                                  // borderRadius: "8px",
                                  // padding: "1rem",
                                  marginTop: "20px",
                                }}
                              >
                                <Typography>
                                  {" "}
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: "#7e7ec0",
                                    }}
                                  >
                                    {" "}
                                    installation type :{" "}
                                  </span>{" "}
                                  {data?.installation_type}
                                </Typography>

                                <Typography>
                                  {" "}
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: "#7e7ec0",
                                    }}
                                  >
                                    {" "}
                                    invert manufacture :{" "}
                                  </span>{" "}
                                  {data?.invert_manufacture}
                                </Typography>
                                <Typography>
                                  {" "}
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: "#7e7ec0",
                                    }}
                                  >
                                    {" "}
                                    panels manufacture :{" "}
                                  </span>{" "}
                                  {data?.panels_manufacture}
                                </Typography>
                                <Typography>
                                  {" "}
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: "#7e7ec0",
                                    }}
                                  >
                                    {" "}
                                    roof type :{" "}
                                  </span>{" "}
                                  {data?.roof_type}
                                </Typography>

                                <Button variant="outlined" size="small"  onClick={()=>navigate(`/home/SolarSolution/single_design_req_details/${data.ticket_id}/${data.id}`)}>
                                     Details
                                 </Button>
                                {/* {business_licence?.file?.license_img && (
                                  <Typography>
                                    {" "}
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        color: "#7e7ec0",
                                      }}
                                    >
                                      {" "}
                                      License Image :{" "}
                                    </span>{" "}
                                    <a
                                      href={business_licence?.file?.license_img}
                                      target="_blank"
                                    >
                                      <ImageIcon />{" "}
                                    </a>
                                  </Typography>
                                )} */}
                              </CardContent>
                              </Card>
                            </Grid>
                          )
                        )
                      ) : (
                        <Grid
                          container
                          rowSpacing={1}
                          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                          style={{ marginLeft: "20px", padding: "5px" , display:"flex" , justifyContent:"center" }}
                        >
                          <Typography  align="center" variant="h5">There is no data</Typography>
                        </Grid>
                      )}
                    </Grid>
        </>
      ) : (
        <div style={{ marginLeft: "20px", padding: "5px" }}>
          <Typography align="center" variant="h6">
            there is no data for this Card
          </Typography>
        </div>
      )}
    </div>
  );
};
const Value = styled.span`
  color: rgb(136, 136, 136);
`;
export default DesignReqData;
