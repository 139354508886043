import React from "react";
import Tooltip from "@mui/material/Tooltip";
import CardMedia from "@mui/material/CardMedia";
import {
  Button,
  Typography,
  Grid,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  IconButton,
} from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import LottieAnimation from "../LottieAnimation/LottieAnimation";
import loading from "../../Assets/lottie/loading.json";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
const WelcomeCallDetails = ({ welcomCallDetails }) => {
  console.log(welcomCallDetails, "welcomCallDetails");
  return (
    <>
      {welcomCallDetails?.isLodaing ? (
        <LottieAnimation lotti={loading} width={180} height={180} />
      ) : (
        <Grid style={{ padding: "10px 27px" }}>
          <Grid Grid item xs={12} sm={12} lg={12}>
            <Card>
              <CardHeader
                title="Welcome call Data"
                subheader={`Created at: ${new Date(
                  welcomCallDetails?.WelcomeCall?.data?.created_at
                ).toLocaleDateString("en-US", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })}

                 `}
              />
              {/* <Tooltip title="view Image">
                <a
                  href={welcomCallDetails?.WelcomeCall?.data?.image}
                  target="_blank"
                >
                  <CardMedia
                    component="img"
                    height="200"
                    width="250"
                    image={welcomCallDetails?.WelcomeCall?.data?.image}
                    alt="Paella dish"
                  />
                </a>
              </Tooltip> */}

{/* {welcomCallDetails?.WelcomeCall?.data?.image && (
                                  <Typography>
                                    {" "}
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        color: "#7e7ec0",
                                      }}
                                    >
                                      {" "}
                                     Image :{" "}
                                    </span>{" "}
                                    <a
                                     href={welcomCallDetails?.WelcomeCall?.data?.image}
                                      target="_blank"
                                    >
                                      <ImageIcon />{" "}
                                    </a>
                                  </Typography>
                                )} */}
              <CardContent>
                <Grid
                  container
                  columns={12}
                  spacing={4}
                  // justifyContent="center"
                  style={{ margin: "10px" }}
                >
                  <Grid xs={12} lg={6}>
                    {" "}
                    <Typography variant="h6"  style={{
                                      
                                      color: "#7e7ec0",
                                    }}>
                      Status :{" "}
                      <span style={{ color: "gray" }}>
                        {welcomCallDetails?.WelcomeCall?.data?.status}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid xs={12} lg={6}>
                    <Typography variant="h6" color="text.primary"  style={{
                                      
                                      color: "#7e7ec0",
                                    }}>
                      any locked gates:{" "}
                      {welcomCallDetails?.WelcomeCall?.data?.any_locked_gates ==
                      1 ? (
                        <span>
                          <CheckIcon style={{ color: "green" }} />
                        </span>
                      ) : (
                        <CloseIcon />
                      )}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  container
                  columns={12}
                  spacing={4}
                  // justifyContent="center"
                  style={{ margin: "10px" }}
                >
                  <Grid xs={12} lg={6}>
                    <Typography variant="h6" color="text.primary" mb={1}  style={{
                                      
                                      color: "#7e7ec0",
                                    }}>
                      any dogs:{" "}
                      {welcomCallDetails?.WelcomeCall?.data?.any_dogs == 1 ? (
                        <span>
                          <CheckIcon style={{ color: "green" }} />
                        </span>
                      ) : (
                        <CloseIcon style={{ color: "red" }} />
                      )}
                    </Typography>
                  </Grid>
                  <Grid xs={12} lg={6}>
                    <Typography variant="h6" color="text.primary" mb={1}  style={{
                                      
                                      color: "#7e7ec0",
                                    }}>
                      any unpermitted:{" "}
                      {welcomCallDetails?.WelcomeCall?.data?.any_unpermitted ==
                      1 ? (
                        <span>
                          <CheckIcon style={{ color: "green" }} />
                        </span>
                      ) : (
                        <CloseIcon   style={{ color: "red" }} />
                      )}
                    </Typography>
                  </Grid>
                </Grid>

                {/* <Grid
                  container
                  columns={12}
                  spacing={4}
               
                  style={{ margin: "10px" }}
                >
                  <Grid xs={12} lg={6}>
                    <Typography variant="h6" color="text.primary" mb={1}>
                      be on site:{" "}
                      {welcomCallDetails?.WelcomeCall?.data?.be_on_site == 1 ? (
                        <span>
                          <CheckIcon style={{ color: "green" }} />
                        </span>
                      ) : (
                        <CloseIcon />
                      )}
                    </Typography>
                  </Grid>
                  <Grid xs={12} lg={6}>
                    <Typography variant="h6" color="text.primary" mb={1}>
                      confirm survey time:{" "}
                      {welcomCallDetails?.WelcomeCall?.data
                        ?.confirm_survey_time == 1 ? (
                        <span>
                          <CheckIcon style={{ color: "green" }} />
                        </span>
                      ) : (
                        <CloseIcon />
                      )}
                    </Typography>
                  </Grid>
                </Grid> */}

                <Grid
                  container
                  columns={12}
                  spacing={4}
                  // justifyContent="center"
                  style={{ margin: "10px" }}
                >
                  {/* <Grid xs={12} lg={6}> */}
                    {/* <Typography variant="h6" color="text.primary">
                      comment:{" "}
                      <span style={{ color: "gray" }}>
                        {welcomCallDetails?.WelcomeCall?.data?.comment}
                      </span>
                    </Typography> */}
                    {/* <Typography variant="h6" color="text.primary">
                      time:{" "}
                      <span style={{ color: "gray" }}>
                        {welcomCallDetails?.WelcomeCall?.data?.time}
                      </span>
                    </Typography> */}
                  {/* </Grid> */}
                  {/* <Grid xs={12} lg={6}>
                    <Typography variant="h6" color="text.primary">
                      event:{" "}
                      <span style={{ color: "gray" }}>
                        {welcomCallDetails?.WelcomeCall?.data?.event}
                      </span>
                    </Typography>
                  </Grid> */}
                </Grid>
                <Grid
                  container
                  columns={12}
                  spacing={4}
                  // justifyContent="center"
                  style={{ margin: "10px" }}
                >
                  <Grid xs={12} lg={6}>
                    <Typography variant="h6" color="text.primary"  style={{
                                      
                                        color: "#7e7ec0",
                                      }}>
                      comment:{" "}
                      <span style={{ color: "gray" }}>
                        {welcomCallDetails?.WelcomeCall?.data?.comment ===  undefined ? "...." : welcomCallDetails?.WelcomeCall?.data?.comment }
                      </span>
                    </Typography>

                    
                  </Grid>


                  <Grid xs={12} lg={6}>
                    <Typography variant="h6" color="text.primary" >
              
                      <span style={{ color: "gray" }}>
                        
                      {welcomCallDetails?.WelcomeCall?.data?.image && (
                                  <Typography style={{display:"flex" }}>
                                    {" "}
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        color: "#7e7ec0",
                                      }}
                                    >
                                      {" "}
                                     Image :{" "}
                                    </span>{" "}
                                    <a
                                     href={welcomCallDetails?.WelcomeCall?.data?.image}
                                      target="_blank"
                                    >
                                      <ImageIcon />{" "}
                                    </a>
                                  </Typography>
                                )}
                      </span>
                    </Typography>

                    
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default WelcomeCallDetails;
