import React, { useEffect } from "react";
import { SideLeadStyled } from "./LeadSideInfoStyle";
import leadUser from "../../Assets/Images/leadUser.png";
import { useDispatch, useSelector } from "react-redux";
import { GetTickets } from "../../Redux/Slices/Tickets/TicketSlice";
import { Link, useParams } from "react-router-dom";
import { getSingleTicket } from "../../Redux/Slices/SingleTicketSlice/SingleTicketSlice";
import InstallersList from "./InstallersList";
import SalesRep from "./SalesRep";
import DealType from "./DealType";
import CardsSummary from "./CardsSummary";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
const LeadSideInfo = () => {
  const isAutherized = useSelector((state) => state.auth?.data);
  const single_ticket = useSelector((state) => state.single_ticket);
  const { lead_id } = useParams();
  const params = useParams();


  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSingleTicket(lead_id));
    console.log(lead_id, "lead_id innmnmnmnm");
  }, [lead_id]);
  return (
    <>
      <SideLeadStyled>
        <div className="mainInfo">
          <h3 className="mainInfo__title"> Customer basic info.</h3>
        </div>
        <div className="LeadInfo">
          {single_ticket?.isLodaing ? (
            <p>Loading</p>
          ) : (
            <ul>
              <li>
                <p style={{ color: "black", fontWeight: "bold" ,  marginBottom:"8px" }}>Full Name :</p>
                <span>
                  {" "}
                  {single_ticket?.singleTicket?.data?.lead?.first_name}{" "}
                  {single_ticket?.singleTicket?.data?.lead?.last_name}
                </span>
              </li>
              <li>
                <p style={{ color: "black", fontWeight: "bold" ,  marginBottom:"8px" }}>Email :</p>
                <span> {single_ticket?.singleTicket?.data?.lead?.email} </span>
              </li>

              <li>
                <p style={{ color: "black", fontWeight: "bold" , marginBottom:"8px" }}>Phone :</p>
                <span> {single_ticket?.singleTicket?.data?.lead?.mobile}</span>
              </li>

              <li >
                <p style={{ color: "black", fontWeight: "bold" , marginBottom:"8px" }}>Address :</p>
                <span>
                  {" "}
                  {single_ticket?.singleTicket?.data?.lead?.location}
                </span>
              </li>

              <li>
                <p style={{ color: "black", fontWeight: "bold"  , marginBottom:"10px"}}>County / city office :</p>
                <Link to={`/home/procedures/${lead_id}`}>
                  <Button>
                    <span style={{ borderBottom: "1px solid blue" ,  }}>
                      {" "}
                      {single_ticket?.singleTicket?.data?.lead?.county}
                    </span>
                  </Button>
                </Link>
              </li>
            </ul>
          )}
        </div>
      </SideLeadStyled>

      <SideLeadStyled>
        <div className="mainInfo">
          <h3 className="mainInfo__title">Deal Type</h3>
        </div>
        <div className="mainInfo">
          <DealType ticket_id={lead_id} />
        </div>
      </SideLeadStyled>

      <SideLeadStyled>
        <div className="mainInfo">
          <h3 className="mainInfo__title">Cards Info</h3>
        </div>
        <div className="mainInfo">
          <CardsSummary ticket_id={lead_id} />
        </div>
      </SideLeadStyled>

      <SideLeadStyled>
        <div className="mainInfo">
          {/* <h3 className="mainInfo__title">Installers</h3> */}
          <Link to={`/home/state_Installers/${lead_id}`}>
            {" "}
            <Button>View Eligible Installers</Button>
          </Link>
        </div>

        {/* <div className="mainInfo">
          <InstallersList />
        </div> */}
      </SideLeadStyled>

      <SideLeadStyled>
        <div className="mainInfo">
          <h3>sales Rep.</h3>
        </div>
        <SalesRep ticket_id={lead_id} />
      </SideLeadStyled>
    </>
  );
};

export default LeadSideInfo;
