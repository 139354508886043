import React, { useState , useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Grid, Button, Typography, IconButton } from "@mui/material";
import { ModalContent } from "../PmAssignModal/PmAssignModalStyle";
import FormControl from "@mui/material/FormControl";
import { TextField } from "@mui/material";
import { PrimaryButton } from "../../../Layouts/ButtonsControlled";
import FormGroup from "@mui/material/FormGroup";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { postAddersCostSpecificCard } from "../../../Redux/Slices/AddersCostSpecificCards/AddersCostSpecificCardsSlice";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import {get_AddersCostSpecificCards} from "../../../Redux/Slices/AddersCostSpecificCards/AddersCostSpecificCardsSlice"
const ExtraAddersModal = ({
  handleOpenAddersFormModal,
  handleCloseAddersFormModal,
  setOpenAddersFormModal,
  setAddIssueForm,
  ticket_id,
  pm_status_id,
  issueCardType,
}) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [UpdatedUploadFiles, setUpdatedUploadedFiles] = useState([]);
  const [fileLimit, setFileLimit] = useState(false);
  const [inputList, setInputList] = useState([
    { name: "", other: "", cost: "" },
  ]);

  const [adders, setAdders] = useState({
    media: [],
  });
  const { type } = useParams();
  console.log(type, "typetype");
  console.log(uploadedFiles, "uploadedFiles");
  console.log(UpdatedUploadFiles, "UpdatedUploadFiles");
  const [showUpload, setShowUpload] = useState(false);

  const MAX_COUNT = 1;
  const dispatch = useDispatch();

  const handleUploadFiles = (files) => {
    const uploaded = [...UpdatedUploadFiles];
    let limitExceeded = false;
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        setUpdatedUploadedFiles(uploaded);
        if (uploaded.length === MAX_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_COUNT) {
          alert(`You can only add a maximum of ${MAX_COUNT} files`);
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    });
    if (!limitExceeded) {
      setUploadedFiles(uploaded);
    }
  };
  //   const handleFileEvent = (e, i) => {
  //     const chosenFiles = Array.prototype.slice.call(e.target.files);

  //     handleUploadFiles(chosenFiles);
  //   };

  const handleInputChange = (e, index) => {
    const { name, value, files } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    list[index][files] = value;
    const chosenFiless = Array.prototype.slice.call(
      //   list[index][files]
      list[index][files]
    );
    setInputList(list);
    handleUploadFiles(chosenFiless);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([
      ...inputList,
      { title: "", cost: "", status: "", files: "" },
    ]);
  };

  const handleShowUploadFiles = () => {
    setShowUpload(true);
  };
  const handlecloseUpload = () => {
    setShowUpload(false);
  };

  const handleRemoveItem = (file) => {
    const name = UpdatedUploadFiles.map((file) => file.name);
    setUpdatedUploadedFiles(
      UpdatedUploadFiles.filter((item) => item.name !== file.name)
    );
    console.log(name, "name");
    console.log(UpdatedUploadFiles, "UpdatedUploadFilesssssssss");
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    console.log(inputList, "inputList in submit");
    formData.append("ticket_id", ticket_id);
    formData.append("type", "ssss");
    // formData.append("type", type);
    inputList.map((e, i) => {
      formData.append(`adders[${i}][cost]`, e.cost);
      formData.append(`adders[${i}][name]`, e.name);
      formData.append(`adders[${i}][other]`, e.other);
      formData.append(`adders[${i}][media]`, e.media);
    });

    await dispatch(postAddersCostSpecificCard(formData));
    await setOpenAddersFormModal(false);
  };
  const UpdateValue = (event, index) => {
    const { name, value, files } = event.target;
    // console.log("check this", name, value);
    const newArr = inputList.map((obj, i) => {
      if (i === index) {
        return { ...obj, [name]: files[0] };
      }
      return obj;
    });
    setInputList(newArr);
  };
  console.log("inputListinputListinputList", inputList);
  useEffect(() => {
    dispatch(get_AddersCostSpecificCards(ticket_id))
  }, []);
  return (
    <Dialog
      maxWidth={"sm"}
      open={handleOpenAddersFormModal}
      onClose={handleCloseAddersFormModal}
    >
      <DialogContent>
        <ModalContent>
          <Typography align="center" variant="h5">
            {" "}
            Extra Adders modal 
          </Typography>
          {inputList.map((x, i) => {
            return (
              <Grid style={{ display: "flex", alignItems: "baseline" }}>
                <form>
                  <Grid container spacing={2}>
                    <Grid item xs={6} md={8}>
                      <FormGroup>
                        {/* <FormControl variant="outlined">
                          <div style={{ marginTop: "20px" }}>
                            <Select
                              style={{ width: "300px", marginTop: "15px" }}
                              name="name"
                              value={x.name}
                              onChange={(e) => handleInputChange(e, i)}
                              label="Name"
                            >
                              <MenuItem value="online_monitoring">
                                online monitoring
                              </MenuItem>
                              <MenuItem value="site_survey">
                                site survey
                              </MenuItem>
                              <MenuItem value="permit_fees">
                                permit fees
                              </MenuItem>
                              <MenuItem value="permit_run">permit run</MenuItem>
                              <MenuItem value="roofing_price">
                                roofing price
                              </MenuItem>

                              <MenuItem value="mpu_fees">mpu fees</MenuItem>
                              <MenuItem value="solar_installation_fees">
                                solar installation fees
                              </MenuItem>

                              <MenuItem value="pto_fees">pto fees</MenuItem>

                              <MenuItem value="solar_products">
                                solar products
                              </MenuItem>
                              <MenuItem value="hvac">hvac</MenuItem>
                              <MenuItem value="attic_run"> attic run</MenuItem>
                              <MenuItem value="tree_trimming">
                                {" "}
                                tree trimming
                              </MenuItem>

                              <MenuItem value="mpu_relocation">
                                {" "}
                                mpu relocation
                              </MenuItem>
                              <MenuItem value="de_rate"> de rate</MenuItem>
                              <MenuItem value="sub_panel"> sub panel</MenuItem>
                              <MenuItem value="meter_socket">
                                {" "}
                                meter socket
                              </MenuItem>
                              <MenuItem value="ev_charger">
                                {" "}
                                ev_charger
                              </MenuItem>
                              <MenuItem value="ev_mlo"> ev_mlo</MenuItem>
                              <MenuItem value="designated_plugs">
                                {" "}
                                designated_plugs
                              </MenuItem>
                              <MenuItem value="battery"> battery</MenuItem>
                              <MenuItem value="generator"> generator</MenuItem>
                              <MenuItem value="ducting"> ducting</MenuItem>
                              <MenuItem value="led lighting">
                                {" "}
                                led lighting
                              </MenuItem>
                              <MenuItem value="energy efficient">
                                {" "}
                                energy efficient
                              </MenuItem>
                              <MenuItem value="solar_lip"> solar_lip</MenuItem>
                              <MenuItem value="bird_netting">
                                {" "}
                                bird_netting
                              </MenuItem>
                              <MenuItem value="other">other</MenuItem>
                            </Select>
                          </div>
                          {x.name == "other" && (
                            <TextField
                              fullWidth
                              label="other"
                              multiline
                              rows={1}
                              style={{ width: "300px", marginTop: "50px" }}
                              name="other"
                              value={x.other}
                              onChange={(e) => handleInputChange(e, i)}
                            />
                          )}

                          <TextField
                            fullWidth
                            label="Cost"
                            rows={1}
                            style={{ width: "300px", marginTop: "50px" }}
                            name="cost"
                            value={x.cost}
                            onChange={(e) => handleInputChange(e, i)}
                          />
                        </FormControl> */}
                        <TextField
                          fullWidth
                          label="name"
                          multiline
                          rows={1}
                          style={{ width: "300px", marginTop: "50px" }}
                          name="name"
                          value={x.name}
                          onChange={(e) => handleInputChange(e, i)}
                        />

                        <TextField
                          fullWidth
                          label="Cost"
                          rows={1}
                          style={{ width: "300px", marginTop: "50px" }}
                          name="cost"
                          value={x.cost}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                      </FormGroup>

                      <div mt={4}>
                        <IconButton onClick={() => handleShowUploadFiles()}>
                          <Typography>
                            <span
                              style={{ color: "black", fontWeight: "bold" }}
                            >
                              Upload Files
                            </span>{" "}
                          </Typography>{" "}
                          {!showUpload && (
                            <CloudUploadIcon style={{ marginLeft: "30px" }} />
                          )}
                        </IconButton>

                        {showUpload && (
                          <IconButton onClick={handlecloseUpload}>
                            <CloseIcon />
                          </IconButton>
                        )}
                      </div>

                      {showUpload && (
                        <Grid xs={12} mt={4}>
                          <Grid
                            className="display"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <input
                              type="file"
                              name="media"
                              onChange={(e) => {
                                UpdateValue(e, i);
                              }}
                            ></input>
                            {/* {UpdatedUploadFiles.map((file, index) => (
                              <div>
                                <ListItem
                                  secondaryAction={
                                    <IconButton
                                      edge="end"
                                      aria-label="delete"
                                      onClick={() => handleRemoveItem(file)}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  }
                                >
                                  <ListItemAvatar style={{ display: "flex" }}>
                                    <FolderIcon />
                                    <Typography>{file.name}</Typography>{" "}
                                  </ListItemAvatar>
                                  <ListItemText
                                  // primary={file.image}
                                  // secondary={"Secondary text"}
                                  />
                                </ListItem>
                              </div>
                            ))} */}
                            <Grid className="display" mt={4}></Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </form>
                <div className="btn-box">
                  {inputList.length !== 1 && (
                    <Button
                      variant="danger"
                      onClick={() => handleRemoveClick(i)}
                    >
                      <DeleteForeverIcon />
                    </Button>
                  )}
                  {inputList.length - 1 === i && (
                    <Button variant="warning" onClick={handleAddClick}>
                      <AddCircleOutlineIcon />
                    </Button>
                  )}
                </div>
              </Grid>
            );
          })}
        </ModalContent>
      </DialogContent>
      <DialogActions>
        <PrimaryButton
          fullWidth
          style={{
            padding: "0",
            width: "85%",
            margin: "15px auto",
            height: "42px",
          }}
          className="saveButton"
          onClick={handleSubmit}
        >
          Submit
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

const Input = styled.input`
  display: none;
`;
export default ExtraAddersModal;
