import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import SurveyStepsService from "./SurveyStepsService";
import { toast } from "react-toastify";
const initialState = {
  SurveySteps: {},
  isError: false,
  isSuccess: false,
  isLodaing: false,
  massage: "",
};

export const STORE_first_step_of_survey_Action = createAsyncThunk(
  "SurveyStep/post",
  async (data, thunkAPI) => {
    try {
      return await SurveyStepsService.store_first_step_of_survey_Action(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      error?.response?.data?.errors?.map((err) =>
        toast.error(err.msg, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      );
      return thunkAPI.rejectWithValue(massage);
    }
  }
);
export const GET_first_step_of_survey_Action = createAsyncThunk(
  "SurveyStep/get",
  async (id, thunkAPI) => {
    try {
      return await SurveyStepsService.get_first_step_of_survey_Action(id);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);
export const Edit_first_step_of_survey_Action = createAsyncThunk(
  "SurveyStep/post",
  async (data, thunkAPI) => {
    try {
      return await SurveyStepsService.update_first_step_of_survey_Action(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      error?.response?.data?.errors?.map((err) =>
        toast.error(err.msg, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      );
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const STORE_second_step_of_survey_Action = createAsyncThunk(
  "SurveyStep/post",
  async (data, thunkAPI) => {
    try {
      return await SurveyStepsService.store_second_step_of_survey_Action(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      error?.response?.data?.errors?.map((err) =>
        toast.error(err.msg, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      );
      return thunkAPI.rejectWithValue(massage);
    }
  }
);
export const GET_second_step_of_survey_Action = createAsyncThunk(
  "SurveyStep/get",
  async (id, thunkAPI) => {
    try {
      return await SurveyStepsService.get_second_step_of_survey_Action(id);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);
export const Edit_second_step_of_survey_Action = createAsyncThunk(
  "SurveyStep/post",
  async (data, thunkAPI) => {
    try {
      return await SurveyStepsService.update_second_step_of_survey_Action(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      error?.response?.data?.errors?.map((err) =>
        toast.error(err.msg, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      );
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const STORE_quality_control_step_of_survey_Action = createAsyncThunk(
  "SurveyStep/post",
  async (data, thunkAPI) => {
    try {
      return await SurveyStepsService.store_quality_control_step_of_survey_Action(
        data
      );
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      error?.response?.data?.errors?.map((err) =>
        toast.error(err.msg, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      );
      return thunkAPI.rejectWithValue(massage);
    }
  }
);
export const GET_quality_control_step_of_survey_Action = createAsyncThunk(
  "SurveyStep/get",
  async (id, thunkAPI) => {
    try {
      return await SurveyStepsService.get_quality_control_step_of_survey_Action(
        id
      );
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);
export const Edit_quality_control_step_of_survey_Action = createAsyncThunk(
  "SurveyStep/post",
  async (data, thunkAPI) => {
    try {
      return await SurveyStepsService.update_quality_control_step_of_survey_Action(
        data
      );
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      error?.response?.data?.errors?.map((err) =>
        toast.error(err.msg, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      );
      return thunkAPI.rejectWithValue(massage);
    }
  }
);
export const SurveyStepsSlice = createSlice({
  name: "SurveySteps",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLodaing = false;
      state.massage = "";
    },
  },
  extraReducers: {
    // STORE_first_step_of_survey_Action
    [STORE_first_step_of_survey_Action.pending]: (state) => {
      state.isLodaing = true;
    },
    [STORE_first_step_of_survey_Action.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.SurveySteps = payload;
    },
    [STORE_first_step_of_survey_Action.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.SurveySteps = null;
      state.massage = action.payload;
    },
    //GET_first_step_of_survey_Action
    [GET_first_step_of_survey_Action.pending]: (state) => {
      state.isLodaing = true;
    },
    [GET_first_step_of_survey_Action.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.SurveySteps = payload;
    },
    [GET_first_step_of_survey_Action.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
      state.SurveySteps = null;
    },

    // Edit_first_step_of_survey_Action
    [Edit_first_step_of_survey_Action.pending]: (state) => {
      state.isLodaing = true;
    },
    [Edit_first_step_of_survey_Action.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.SurveySteps = payload;
    },
    [Edit_first_step_of_survey_Action.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.SurveySteps = null;
      state.massage = action.payload;
    },

    // STORE_second_step_of_survey_Action
    [STORE_second_step_of_survey_Action.pending]: (state) => {
      state.isLodaing = true;
    },
    [STORE_second_step_of_survey_Action.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.SurveySteps = payload;
    },
    [STORE_second_step_of_survey_Action.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.SurveySteps = null;
      state.massage = action.payload;
    },
    //GET_second_step_of_survey_Action
    [GET_second_step_of_survey_Action.pending]: (state) => {
      state.isLodaing = true;
    },
    [GET_second_step_of_survey_Action.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.SurveySteps = payload;
    },
    [GET_second_step_of_survey_Action.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
      state.SurveySteps = null;
    },

    // Edit_second_step_of_survey_Action
    [Edit_second_step_of_survey_Action.pending]: (state) => {
      state.isLodaing = true;
    },
    [Edit_second_step_of_survey_Action.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.SurveySteps = payload;
    },
    [Edit_second_step_of_survey_Action.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.SurveySteps = null;
      state.massage = action.payload;
    },

    // STORE_quality_control_step_of_survey_Action
    [STORE_quality_control_step_of_survey_Action.pending]: (state) => {
      state.isLodaing = true;
    },
    [STORE_quality_control_step_of_survey_Action.fulfilled]: (
      state,
      { payload }
    ) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.SurveySteps = payload;
    },
    [STORE_quality_control_step_of_survey_Action.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.SurveySteps = null;
      state.massage = action.payload;
    },
    //GET_quality_control_step_of_survey_Action
    [GET_quality_control_step_of_survey_Action.pending]: (state) => {
      state.isLodaing = true;
    },
    [GET_quality_control_step_of_survey_Action.fulfilled]: (
      state,
      { payload }
    ) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.SurveySteps = payload;
    },
    [GET_quality_control_step_of_survey_Action.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
      state.SurveySteps = null;
    },

    // Edit_quality_control_step_of_survey_Action
    [Edit_quality_control_step_of_survey_Action.pending]: (state) => {
      state.isLodaing = true;
    },
    [Edit_quality_control_step_of_survey_Action.fulfilled]: (
      state,
      { payload }
    ) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.SurveySteps = payload;
    },
    [Edit_quality_control_step_of_survey_Action.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.SurveySteps = null;
      state.massage = action.payload;
    },
  },
});

export const { reset } = SurveyStepsSlice.actions;
export default SurveyStepsSlice.reducer;
