import React, { useEffect } from "react";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { TicketInfo } from "./TicketsTypesRoutsStyle";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getTicketsCountsAction } from "../../Redux/Slices/TicketsCounts/TicketCountsSlice";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));
function TicketsTypesRouts() {
  const dispatch = useDispatch();
  const ticketsCounts = useSelector((state) => state.ticketsCounts);

  useEffect(() => {
    dispatch(getTicketsCountsAction());
  }, []);
  return (
    <TicketInfo>
      <Stack className="flexDirection">
        <NavLink to={`allTickets`} className="link">
          <Item className="item-info">
            <Typography style={{ marginTop: "1px" }}>All</Typography>

            <div className="ticketsInfoNum" style={{ background: "#0d63bf" }}>
              {ticketsCounts?.ticketsCounts?.data?.all_tickets}
            </div>
          </Item>
        </NavLink>
        <NavLink to="AssignedTickets" className="link">
          <Item className="item-info">
            <Typography style={{ marginTop: "1px" }}>Assigned</Typography>

            <div
              style={{ background: "rgb(22 171 175)" }}
              className="ticketsInfoNum"
            >
              {ticketsCounts?.ticketsCounts?.data?.assigned_tickets}
            </div>
          </Item>
        </NavLink>
        <NavLink to="pendingTeckets" className="link">
          <Item className="item-info">
            <Typography style={{ marginTop: "1px" }}>Pending</Typography>

            <div style={{ background: "#FE9500" }} className="ticketsInfoNum">
              {ticketsCounts?.ticketsCounts?.data?.pending_tickets}
            </div>
          </Item>
        </NavLink>
        <NavLink to="ApprovedTickets" className="link">
          <Item className="item-info">
            <Typography style={{ marginTop: "1px" }}>Approved</Typography>

            <div style={{ background: "#0dbf7c" }} className="ticketsInfoNum">
              {ticketsCounts?.ticketsCounts?.data?.approved_tickets}
            </div>
          </Item>
        </NavLink>
        <NavLink to="RejectedTickets" className="link">
          <Item className="item-info">
            <Typography style={{ marginTop: "1px" }}>Rejected</Typography>

            <div style={{ background: "#DC1E0B" }} className="ticketsInfoNum">
              {ticketsCounts?.ticketsCounts?.data?.rejected_tickets}
            </div>
          </Item>
        </NavLink>
      </Stack>
    </TicketInfo>
  );
}

export default TicketsTypesRouts;
