import axios from "axios";
import { ticket_status } from "../../../Api/Api";

const getTicketStatusAction = async (id) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${ticket_status}/${id}`,
    headers: headers,
    // data: data,
  });
  return response.data;
};

const TicketStatusService = {
  getTicketStatusAction,
};
export default TicketStatusService;
