import React from "react";
import Typography from "@mui/material/Typography";
import { FormControl, Grid } from "@mui/material";
import { TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
function ForthCharterForm({ formik }) {
  return (
    <>
      <Grid item xs={12} lg={4} md={6} mt={3}>
        <Typography className="fieldDetails" variant="h6" mb={1}>
          {" "}
          Schedule{" "}
        </Typography>
      </Grid>
      <Grid
        container
        columns={12}
        spacing={6}
        justifyContent="center"
        mb={3}
        // mt={2}
      >
        <Grid item xs={12} sm={10} lg={8}>
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                name="welcome_call"
                variant="inline"
                label="welcome call"
                //   format="dd MMM yyyy"
                margin="normal"
                value={formik.values.welcome_call}
                onChange={(newDate) => {
                  formik.setFieldValue(
                    "welcome_call",
                    new Date(newDate).toISOString().split("T")[0] 
                  );
                }}
                renderInput={(params) => <TextField {...params} />}
                error={
                  formik.touched.welcome_call &&
                  Boolean(formik.errors.welcome_call)
                }
                helperText={
                  formik.touched.welcome_call && formik.errors.welcome_call
                }
              />
            </LocalizationProvider>
            {formik.errors.welcome_call ? (
              <small style={{ color: "red", display: "inline-block" }}>
                {formik.errors.welcome_call}
              </small>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>
      </Grid>
      <Grid
        container
        columns={12}
        spacing={6}
        justifyContent="center"
        mb={3}
        // mt={2}
      >
        <Grid item xs={12} sm={5} lg={4}>
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                name="site_survey"
                variant="inline"
                label="site_survey"
                //   format="dd MMM yyyy"
                margin="normal"
                value={formik.values.site_survey}
                onChange={(newDate) => {
                  formik.setFieldValue(
                    "site_survey",
                    new Date(newDate).toISOString().split("T")[0]
                  );
                }}
                renderInput={(params) => <TextField {...params} />}
                error={
                  formik.touched.site_survey &&
                  Boolean(formik.errors.site_survey)
                }
                helperText={
                  formik.touched.site_survey && formik.errors.site_survey
                }
              />
            </LocalizationProvider>
            {formik.errors.site_survey ? (
              <small style={{ color: "red", display: "inline-block" }}>
                {formik.errors.site_survey}
              </small>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={5} lg={4}>
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                name="Business_license_date"
                variant="inline"
                label="Business License Date"
                //   format="dd MMM yyyy"
                margin="normal"
                value={formik.values.Business_license_date}
                onChange={(newDate) => {
                  formik.setFieldValue(
                    "Business_license_date",
                    new Date(newDate).toISOString().split("T")[0]
                  );
                }}
                renderInput={(params) => <TextField {...params} />}
                error={
                  formik.touched.Business_license_date &&
                  Boolean(formik.errors.Business_license_date)
                }
                helperText={
                  formik.touched.Business_license_date &&
                  formik.errors.Business_license_date
                }
              />
            </LocalizationProvider>
            {formik.errors.Business_license_date ? (
              <small style={{ color: "red", display: "inline-block" }}>
                {formik.errors.Business_license_date}
              </small>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>
      </Grid>
      <Grid container columns={12} spacing={2} justifyContent="center" mb={3}>
        <Grid item xs={12} sm={10} lg={8}>
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                name="meterspot_date"
                variant="inline"
                label="meterspot_date"
                //   format="dd MMM yyyy"
                margin="normal"
                value={formik.values.meterspot_date}
                onChange={(newDate) => {
                  formik.setFieldValue(
                    "meterspot_date",
                    new Date(newDate).toISOString().split("T")[0]
                  );
                }}
                renderInput={(params) => <TextField {...params} />}
                error={
                  formik.touched.meterspot_date &&
                  Boolean(formik.errors.meterspot_date)
                }
                helperText={
                  formik.touched.meterspot_date && formik.errors.meterspot_date
                }
              />
            </LocalizationProvider>
            {formik.errors.meterspot_date ? (
              <small style={{ color: "red", display: "inline-block" }}>
                {formik.errors.meterspot_date}
              </small>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>
        {/* <Grid item xs={12} sm={5} lg={4}></Grid> */}
      </Grid>
      <Grid item xs={12} lg={4} md={6} mt={3}>
        <Typography className="fieldDetails" variant="h6" mb={1}>
          {" "}
          MPU Application{" "}
        </Typography>
      </Grid>
      <Grid container columns={12} spacing={5} justifyContent="center" mb={3}>
        <Grid item xs={12} sm={5} lg={4}>
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                name="mpu_application_submission_date_start"
                variant="inline"
                label="mpu_application_submission_date_start"
                //   format="dd MMM yyyy"
                margin="normal"
                value={formik.values.mpu_application_submission_date_start}
                onChange={(newDate) => {
                  formik.setFieldValue(
                    "mpu_application_submission_date_start",
                    new Date(newDate).toISOString().split("T")[0]
                  );
                }}
                renderInput={(params) => <TextField {...params} />}
                error={
                  formik.touched.mpu_application_submission_date_start &&
                  Boolean(formik.errors.mpu_application_submission_date_start)
                }
                helperText={
                  formik.touched.mpu_application_submission_date_start &&
                  formik.errors.mpu_application_submission_date_start
                }
              />
            </LocalizationProvider>
            {formik.errors.mpu_application_submission_date_start ? (
              <small style={{ color: "red", display: "inline-block" }}>
                {formik.errors.mpu_application_submission_date_start}
              </small>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={5} lg={4}>
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                name="mpu_permit_issuance_date_end"
                variant="inline"
                label="mpu_permit_issuance_date_end"
                //   format="dd MMM yyyy"
                margin="normal"
                value={formik.values.mpu_permit_issuance_date_end}
                onChange={(newDate) => {
                  formik.setFieldValue(
                    "mpu_permit_issuance_date_end",
                    new Date(newDate).toISOString().split("T")[0]
                  );
                }}
                renderInput={(params) => <TextField {...params} />}
                error={
                  formik.touched.mpu_permit_issuance_date_end &&
                  Boolean(formik.errors.mpu_permit_issuance_date_end)
                }
                helperText={
                  formik.touched.mpu_permit_issuance_date_end &&
                  formik.errors.mpu_permit_issuance_date_end
                }
              />
            </LocalizationProvider>
            {formik.errors.mpu_permit_issuance_date_end ? (
              <small style={{ color: "red", display: "inline-block" }}>
                {formik.errors.mpu_permit_issuance_date_end}
              </small>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={4} md={6} mt={3}>
        <Typography className="fieldDetails" variant="h6" mb={1}>
          {" "}
          Solar Permit{" "}
        </Typography>
      </Grid>
      <Grid container columns={12} spacing={5} justifyContent="center" mb={3}>
        <Grid item xs={12} sm={5} lg={4}>
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                name="solar_application_submission_date_start"
                variant="inline"
                label="solar_application_submission_date_start"
                //   format="dd MMM yyyy"
                margin="normal"
                value={formik.values.solar_application_submission_date_start}
                onChange={(newDate) => {
                  formik.setFieldValue(
                    "solar_application_submission_date_start",
                    new Date(newDate).toISOString().split("T")[0]
                  );
                }}
                renderInput={(params) => <TextField {...params} />}
                error={
                  formik.touched.solar_application_submission_date_start &&
                  Boolean(formik.errors.solar_application_submission_date_start)
                }
                helperText={
                  formik.touched.solar_application_submission_date_start &&
                  formik.errors.solar_application_submission_date_start
                }
              />
            </LocalizationProvider>
            {formik.errors.solar_application_submission_date_start ? (
              <small style={{ color: "red", display: "inline-block" }}>
                {formik.errors.solar_application_submission_date_start}
              </small>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={5} lg={4}>
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                name="solar_permit_issuance_date_end"
                variant="inline"
                label="solar_permit_issuance_date_end"
                //   format="dd MMM yyyy"
                margin="normal"
                value={formik.values.solar_permit_issuance_date_end}
                onChange={(newDate) => {
                  formik.setFieldValue(
                    "solar_permit_issuance_date_end",
                    new Date(newDate).toISOString().split("T")[0]
                  );
                }}
                renderInput={(params) => <TextField {...params} />}
                error={
                  formik.touched.solar_permit_issuance_date_end &&
                  Boolean(formik.errors.solar_permit_issuance_date_end)
                }
                helperText={
                  formik.touched.solar_permit_issuance_date_end &&
                  formik.errors.solar_permit_issuance_date_end
                }
              />
            </LocalizationProvider>
            {formik.errors.solar_permit_issuance_date_end ? (
              <small style={{ color: "red", display: "inline-block" }}>
                {formik.errors.solar_permit_issuance_date_end}
              </small>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={4} md={6} mt={3}>
        <Typography className="fieldDetails" variant="h6" mb={1}>
          {" "}
          Roofing Permit{" "}
        </Typography>
      </Grid>
      <Grid container columns={12} spacing={5} justifyContent="center" mb={3}>
        <Grid item xs={12} sm={5} lg={4}>
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                name="roofing_application_submission_date_start"
                variant="inline"
                label="roofing_application_submission_date_start"
                //   format="dd MMM yyyy"
                margin="normal"
                value={formik.values.roofing_application_submission_date_start}
                onChange={(newDate) => {
                  formik.setFieldValue(
                    "roofing_application_submission_date_start",
                    new Date(newDate).toISOString().split("T")[0]
                  );
                }}
                renderInput={(params) => <TextField {...params} />}
                error={
                  formik.touched.roofing_application_submission_date_start &&
                  Boolean(
                    formik.errors.roofing_application_submission_date_start
                  )
                }
                helperText={
                  formik.touched.roofing_application_submission_date_start &&
                  formik.errors.roofing_application_submission_date_start
                }
              />
            </LocalizationProvider>
            {formik.errors.roofing_application_submission_date_start ? (
              <small style={{ color: "red", display: "inline-block" }}>
                {formik.errors.roofing_application_submission_date_start}
              </small>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={5} lg={4}>
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                name="roofing_permit_issuance_date_end"
                variant="inline"
                label="roofing_permit_issuance_date_end"
                //   format="dd MMM yyyy"
                margin="normal"
                value={formik.values.roofing_permit_issuance_date_end}
                onChange={(newDate) => {
                  formik.setFieldValue(
                    "roofing_permit_issuance_date_end",
                    new Date(newDate).toISOString().split("T")[0]
                  );
                }}
                renderInput={(params) => <TextField {...params} />}
                error={
                  formik.touched.roofing_permit_issuance_date_end &&
                  Boolean(formik.errors.roofing_permit_issuance_date_end)
                }
                helperText={
                  formik.touched.roofing_permit_issuance_date_end &&
                  formik.errors.roofing_permit_issuance_date_end
                }
              />
            </LocalizationProvider>
            {formik.errors.roofing_permit_issuance_date_end ? (
              <small style={{ color: "red", display: "inline-block" }}>
                {formik.errors.roofing_permit_issuance_date_end}
              </small>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={4} md={6} mt={3}>
        <Typography className="fieldDetails" variant="h6" mb={1}>
          {" "}
          Roofing Installation{" "}
        </Typography>
      </Grid>
      <Grid container columns={12} spacing={5} justifyContent="center" mb={3}>
        <Grid item xs={12} sm={5} lg={4}>
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                name="roofing_installation_start_time"
                variant="inline"
                label="roofing_installation_start_time"
                //   format="dd MMM yyyy"
                margin="normal"
                value={formik.values.roofing_installation_start_time}
                onChange={(newDate) => {
                  formik.setFieldValue(
                    "roofing_installation_start_time",
                    new Date(newDate).toISOString().split("T")[0]
                  );
                }}
                renderInput={(params) => <TextField {...params} />}
                error={
                  formik.touched.roofing_installation_start_time &&
                  Boolean(formik.errors.roofing_installation_start_time)
                }
                helperText={
                  formik.touched.roofing_installation_start_time &&
                  formik.errors.roofing_installation_start_time
                }
              />
            </LocalizationProvider>
            {formik.errors.roofing_installation_start_time ? (
              <small style={{ color: "red", display: "inline-block" }}>
                {formik.errors.roofing_installation_start_time}
              </small>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={5} lg={4}>
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                name="roofing_installation_complete"
                variant="inline"
                label="roofing_installation_complete"
                //   format="dd MMM yyyy"
                margin="normal"
                value={formik.values.roofing_installation_complete}
                onChange={(newDate) => {
                  formik.setFieldValue(
                    "roofing_installation_complete",
                    new Date(newDate).toISOString().split("T")[0]
                  );
                }}
                renderInput={(params) => <TextField {...params} />}
                error={
                  formik.touched.roofing_installation_complete &&
                  Boolean(formik.errors.roofing_installation_complete)
                }
                helperText={
                  formik.touched.roofing_installation_complete &&
                  formik.errors.roofing_installation_complete
                }
              />
            </LocalizationProvider>
            {formik.errors.roofing_installation_complete ? (
              <small style={{ color: "red", display: "inline-block" }}>
                {formik.errors.roofing_installation_complete}
              </small>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={4} md={6} mt={3}>
        <Typography className="fieldDetails" variant="h6" mb={1}>
          {" "}
          MPU Installation{" "}
        </Typography>
      </Grid>
      <Grid container columns={12} spacing={5} justifyContent="center" mb={3}>
        <Grid item xs={12} sm={5} lg={4}>
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                name="mpu_installation_start_time"
                variant="inline"
                label="mpu_installation_start_time"
                //   format="dd MMM yyyy"
                margin="normal"
                value={formik.values.mpu_installation_start_time}
                onChange={(newDate) => {
                  formik.setFieldValue(
                    "mpu_installation_start_time",
                    new Date(newDate).toISOString().split("T")[0]
                  );
                }}
                renderInput={(params) => <TextField {...params} />}
                error={
                  formik.touched.mpu_installation_start_time &&
                  Boolean(formik.errors.mpu_installation_start_time)
                }
                helperText={
                  formik.touched.mpu_installation_start_time &&
                  formik.errors.mpu_installation_start_time
                }
              />
            </LocalizationProvider>
            {formik.errors.mpu_installation_start_time ? (
              <small style={{ color: "red", display: "inline-block" }}>
                {formik.errors.mpu_installation_start_time}
              </small>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={5} lg={4}>
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                name="mpu_installation_complete"
                variant="inline"
                label="mpu_installation_complete"
                //   format="dd MMM yyyy"
                margin="normal"
                value={formik.values.mpu_installation_complete}
                onChange={(newDate) => {
                  formik.setFieldValue(
                    "mpu_installation_complete",
                    new Date(newDate).toISOString().split("T")[0]
                  );
                }}
                renderInput={(params) => <TextField {...params} />}
                error={
                  formik.touched.mpu_installation_complete &&
                  Boolean(formik.errors.mpu_installation_complete)
                }
                helperText={
                  formik.touched.mpu_installation_complete &&
                  formik.errors.mpu_installation_complete
                }
              />
            </LocalizationProvider>
            {formik.errors.mpu_installation_complete ? (
              <small style={{ color: "red", display: "inline-block" }}>
                {formik.errors.mpu_installation_complete}
              </small>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>
      </Grid>
      {/* solar installation */}
      <Grid item xs={12} lg={4} md={6} mt={3}>
        <Typography className="fieldDetails" variant="h6" mb={1}>
          {" "}
          Solar Installation{" "}
        </Typography>
      </Grid>
      <Grid container columns={12} spacing={5} justifyContent="center" mb={3}>
        <Grid item xs={12} sm={5} lg={4}>
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                name="solar_installation_start_time"
                variant="inline"
                label="solar_installation_start_time"
                //   format="dd MMM yyyy"
                margin="normal"
                value={formik.values.solar_installation_start_time}
                onChange={(newDate) => {
                  formik.setFieldValue(
                    "solar_installation_start_time",
                    new Date(newDate).toISOString().split("T")[0]
                  );
                }}
                renderInput={(params) => <TextField {...params} />}
                error={
                  formik.touched.solar_installation_start_time &&
                  Boolean(formik.errors.solar_installation_start_time)
                }
                helperText={
                  formik.touched.solar_installation_start_time &&
                  formik.errors.solar_installation_start_time
                }
              />
            </LocalizationProvider>
            {formik.errors.solar_installation_start_time ? (
              <small style={{ color: "red", display: "inline-block" }}>
                {formik.errors.solar_installation_start_time}
              </small>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={5} lg={4}>
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                name="solar_installation_complete"
                variant="inline"
                label="complete"
                //   format="dd MMM yyyy"
                margin="normal"
                value={formik.values.solar_installation_complete}
                onChange={(newDate) => {
                  formik.setFieldValue(
                    "solar_installation_complete",
                    new Date(newDate).toISOString().split("T")[0]
                  );
                }}
                renderInput={(params) => <TextField {...params} />}
                error={
                  formik.touched.solar_installation_complete &&
                  Boolean(formik.errors.solar_installation_complete)
                }
                helperText={
                  formik.touched.solar_installation_complete &&
                  formik.errors.solar_installation_complete
                }
              />
            </LocalizationProvider>
            {formik.errors.solar_installation_complete ? (
              <small style={{ color: "red", display: "inline-block" }}>
                {formik.errors.solar_installation_complete}
              </small>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={4} md={6} mt={3}>
        <Typography className="fieldDetails" variant="h6" mb={1}>
          {" "}
          Roofing Inspection{" "}
        </Typography>
      </Grid>
      <Grid
        container
        columns={12}
        spacing={5}
        justifyContent="center"
        mb={3}
        // mt={2}
      >
        <Grid item xs={12} md={10} lg={8}>
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                name="roofing_inspection_start_date"
                variant="inline"
                label="roofing_inspection_start_date"
                //   format="dd MMM yyyy"
                margin="normal"
                value={formik.values.roofing_inspection_start_date}
                onChange={(newDate) => {
                  formik.setFieldValue(
                    "roofing_inspection_start_date",
                    new Date(newDate).toISOString().split("T")[0]
                  );
                }}
                renderInput={(params) => <TextField {...params} />}
                error={
                  formik.touched.roofing_inspection_start_date &&
                  Boolean(formik.errors.roofing_inspection_start_date)
                }
                helperText={
                  formik.touched.roofing_inspection_start_date &&
                  formik.errors.roofing_inspection_start_date
                }
              />
            </LocalizationProvider>
            {formik.errors.roofing_inspection_start_date ? (
              <small style={{ color: "red", display: "inline-block" }}>
                {formik.errors.roofing_inspection_start_date}
              </small>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={4} md={6} mt={3}>
        <Typography className="fieldDetails" variant="h6" mb={1}>
          {" "}
          MPU Inspection{" "}
        </Typography>
      </Grid>
      <Grid
        container
        columns={12}
        spacing={5}
        justifyContent="center"
        mb={3}
        // mt={2}
      >
        <Grid item xs={12} md={10} lg={8}>
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                name="mpu_inspection_start_date"
                variant="inline"
                label="mpu_inspection_start_date"
                //   format="dd MMM yyyy"
                margin="normal"
                value={formik.values.mpu_inspection_start_date}
                onChange={(newDate) => {
                  formik.setFieldValue(
                    "mpu_inspection_start_date",
                    new Date(newDate).toISOString().split("T")[0]
                  );
                }}
                renderInput={(params) => <TextField {...params} />}
                error={
                  formik.touched.mpu_inspection_start_date &&
                  Boolean(formik.errors.mpu_inspection_start_date)
                }
                helperText={
                  formik.touched.mpu_inspection_start_date &&
                  formik.errors.mpu_inspection_start_date
                }
              />
            </LocalizationProvider>
            {formik.errors.mpu_inspection_start_date ? (
              <small style={{ color: "red", display: "inline-block" }}>
                {formik.errors.mpu_inspection_start_date}
              </small>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={4} md={6} mt={3}>
        <Typography className="fieldDetails" variant="h6" mb={1}>
          {" "}
          Solar Inspection{" "}
        </Typography>
      </Grid>
      <Grid
        container
        columns={12}
        spacing={5}
        justifyContent="center"
        mb={3}
        // mt={2}
      >
        <Grid item xs={12} md={10} lg={8}>
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                name="solar_inspection_start_date"
                variant="inline"
                label="Start Time"
                //   format="dd MMM yyyy"
                margin="normal"
                value={formik.values.solar_inspection_start_date}
                onChange={(newDate) => {
                  formik.setFieldValue(
                    "solar_inspection_start_date",
                    new Date(newDate).toISOString().split("T")[0]
                  );
                }}
                renderInput={(params) => <TextField {...params} />}
                error={
                  formik.touched.solar_inspection_start_date &&
                  Boolean(formik.errors.solar_inspection_start_date)
                }
                helperText={
                  formik.touched.solar_inspection_start_date &&
                  formik.errors.solar_inspection_start_date
                }
              />
            </LocalizationProvider>
            {formik.errors.solar_inspection_start_date ? (
              <small style={{ color: "red", display: "inline-block" }}>
                {formik.errors.solar_inspection_start_date}
              </small>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={4} md={6} mt={3}>
        <Typography className="fieldDetails" variant="h6" mb={1}>
          {" "}
          PTO{" "}
        </Typography>
      </Grid>
      <Grid
        container
        columns={12}
        spacing={5}
        justifyContent="center"
        mb={3}
        // mt={2}
      >
        <Grid item xs={12} sm={5} lg={4}>
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                name="pto_application_submittal_date_start"
                variant="inline"
                label="Application Submittal Date"
                //   format="dd MMM yyyy"
                margin="normal"
                value={formik.values.pto_application_submittal_date_start}
                onChange={(newDate) => {
                  formik.setFieldValue(
                    "pto_application_submittal_date_start",
                    new Date(newDate).toISOString().split("T")[0]
                  );
                }}
                renderInput={(params) => <TextField {...params} />}
                error={
                  formik.touched.pto_application_submittal_date_start &&
                  Boolean(formik.errors.pto_application_submittal_date_start)
                }
                helperText={
                  formik.touched.pto_application_submittal_date_start &&
                  formik.errors.pto_application_submittal_date_start
                }
              />
            </LocalizationProvider>
            {formik.errors.pto_application_submittal_date_start ? (
              <small style={{ color: "red", display: "inline-block" }}>
                {formik.errors.pto_application_submittal_date_start}
              </small>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={5} lg={4}>
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                name="pto_application_submittal_date_end"
                variant="inline"
                label="PTO Acquired Date"
                //   format="dd MMM yyyy"
                margin="normal"
                value={formik.values.pto_application_submittal_date_end}
                onChange={(newDate) => {
                  formik.setFieldValue(
                    "pto_application_submittal_date_end",
                    new Date(newDate).toISOString().split("T")[0]
                  );
                }}
                renderInput={(params) => <TextField {...params} />}
                error={
                  formik.touched.pto_application_submittal_date_end &&
                  Boolean(formik.errors.pto_application_submittal_date_end)
                }
                helperText={
                  formik.touched.pto_application_submittal_date_end &&
                  formik.errors.pto_application_submittal_date_end
                }
              />
            </LocalizationProvider>
            {formik.errors.pto_application_submittal_date_end ? (
              <small style={{ color: "red", display: "inline-block" }}>
                {formik.errors.pto_application_submittal_date_end}
              </small>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}

export default ForthCharterForm;
