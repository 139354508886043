import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import singleTicketService from "./singleTicketService";

const initialState = {
  singleTicket: null,
  isError: false,
  isSuccess: false,
  isLodaing: false,
  massage: "",
};

export const getSingleTicket = createAsyncThunk(
  "ticket/getsingleTicket",
  async (id, thunkAPI) => {
    try {
      console.log("Id in get single ticket async", id);
      return await singleTicketService.Sigle_Ticket_Action(id);
    } catch (error) {
      const massage = error.message;
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const singleTicketSlice = createSlice({
  name: "single_ticket",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLodaing = false;
      state.isDeleteLodaing = false;
      state.massage = "";
    },
  },
  extraReducers: {
    [getSingleTicket.pending]: (state) => {
      state.isLodaing = true;
    },
    [getSingleTicket.fulfilled]: (state, action) => {
      state.isLodaing = false;
      state.singleTicket = action.payload;
      state.isSuccess = true;
    },
    [getSingleTicket.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
  },
});

export const { reset } = singleTicketSlice.actions;
export default singleTicketSlice.reducer;
