import React from "react";
import { reduxForm, Field } from "redux-form";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Grid } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { PrimaryButton } from "../../../Layouts/ButtonsControlled";
import { ModalContent } from "../../Modals/PmAssignModal/PmAssignModalStyle";
import { getTicketStatus } from "../../../Redux/Slices/TicketStatus/TicketStatusSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import MuiTextField from "../../../Common/muiTextField/MuiTextField";
import MuiSelectField from "../../../Common/MuiSelectField/MuiSelectField";
import { store_confirm_with_salesAndCustomer } from "../../../Redux/Slices/ConfirmWithSalesAndCustomerSlice/ConfirmWithSalesAndCustomerSlice";
import { update_confirm_with_salesAndCustomer } from "../../../Redux/Slices/ConfirmWithSalesAndCustomerSlice/ConfirmWithSalesAndCustomerSlice";
import { validate } from "../../../Common/Valiation/Validate";
const ConfirmWithSalesAndCustomer = ({
  handleopenModal,
  handleCloseModal,
  set_Open_Modal,
  handleSubmit,
  initialValues,
  openEditModal,
}) => {
  const dispatch = useDispatch();
  const { lead_id } = useParams();
  let id = {
    ticket_id: lead_id,
  };
  const confirm_with_salesAndCustomer_Data = useSelector(
    (state) => state?.confirm_with_sales_and_customer
  );
  console.log(
    confirm_with_salesAndCustomer_Data?.confirm_with_sales_and_customer?.data
      ?.id,
    "confirm_with_salesAndCustomer_Data787"
  );
  console.log(openEditModal, "openEditModal");
  let updated_id = {
    id: confirm_with_salesAndCustomer_Data?.confirm_with_sales_and_customer
      ?.data?.id,
  };

  const submit = async (values) => {
    console.log(values, "redux form values");
    openEditModal
      ? await dispatch(
          update_confirm_with_salesAndCustomer({
            ...values,
            ...id,
            ...updated_id,
          })
        )
      : await dispatch(
          store_confirm_with_salesAndCustomer({
            ...values,
            ...id,
            ...updated_id,
          })
        );
    await set_Open_Modal(false);
    await dispatch(getTicketStatus(lead_id));
  };
  return (
    <Dialog
      maxWidth={"xs"}
      fullWidth
      open={handleopenModal}
      onClose={handleCloseModal}
    >
      <DialogContent>
        <ModalContent>
          <Box>
            <Grid className="" style={{ marginTop: "10px" }}>
              <form onSubmit={handleSubmit((values) => submit(values))}>
                <DialogContent>
                  <Field
                    required
                    type="text"
                    name="confirm_items"
                    multiline
                    rows={3}
                    component={MuiTextField}
                    label="confirm Items"
                  />

                  <Field
                    required
                    name="status"
                    component={MuiSelectField}
                    label="status"
                  >
                    <MenuItem value="pending">pending</MenuItem>
                    <MenuItem value="completed">completed</MenuItem>
                  </Field>
                </DialogContent>
                <DialogActions>
                  <PrimaryButton
                    type="submit"
                    fullWidth
                    style={{
                      padding: "0",
                      width: "85%",
                      margin: "15px auto",
                      height: "42px",
                    }}
                    className="saveButton"
                  >
                    {openEditModal ? "update" : "Save"}
                  </PrimaryButton>
                </DialogActions>
              </form>
            </Grid>
          </Box>
        </ModalContent>
      </DialogContent>
    </Dialog>
  );
};

export default reduxForm({
  form: "confirm_with_sales_and_customer",
  validate,
})(ConfirmWithSalesAndCustomer);
