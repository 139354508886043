import styled from "styled-components";
export const TicketContainer = styled.div`
  margin: 20px auto;
  overflow: hidden;
  /* background-color: #ffffff; */
  background-color: #f9ffff;
  max-width: 500px;
  position: relative;
  /* box-shadow: 1px 0px 4px rgb(0 0 0 / 14%); */
  border-radius: 10px;
  height: 244px !important;
  padding: 2px;
  .ticketHover &:hover {
    color: red;
  }
  .ticketDetails {
    color: #4b4b4b;
    font-size: 13px;
    margin-left: 6px;
    @media only screen and (min-width: 321px) and (max-width: 552px) {
      font-size: 12px;
    }
    @media only screen and (max-width: 320px) {
      font-size: 10px;
    }
  }
  svg {
    font-size: 19px;
    color: #4b4b4b;
    @media only screen and (min-width: 321px) and (max-width: 552px) {
      font-size: 13px;
    }
    @media only screen and (max-width: 320px) {
      font-size: 10px;
    }
  }
  .leftItemData {
    display: flex;
    text-align: center;
    margin-bottom: 15px;
  }
  .buttons {
    position: absolute;
    width: 100%;
    background-color: transparent;
    bottom: 8%;
    width: 100%;
    left: 83%;
  }
`;

export const LeftItem = styled.div`
  padding: 0px 4px;
  display: table-cell;
  margin-right: 20px;
  width: 38%;
  position: relative;
  height: 250px;
  padding-top: 15px;
  .display {
    display: "flex";
    text-align: "center";
  }
`;

export const UpBorder = styled.span`
  padding: 12px 15px;
  background-color: #f3f3f3;
  border-radius: 50%;
  position: absolute;
  top: -11px;
  right: -16px;
`;

export const DownBorder = styled.span`
  padding: 14px 15px;
  background-color: #f3f3f3;
  border-radius: 50%;
  position: absolute;
  bottom: -5px;
  right: -16px;
`;
export const RightItem = styled.div`
  padding: 20px;
  display: table-cell;
  color: #555;
  font-size: 20px;
  margin-bottom: 9px;
  width: 62%;
  /* padding: 34px 0px 19px 20px; */
  padding: 15px 0px 0px 14px;
  border-left: 2px dashed rgba(105, 105, 105, 0.17);
  word-spacing: 5px;
  letter-spacing: 1px;
  color: #888;
  margin-bottom: 10px;
  margin-right: 10px;
  font-size: 20px;
  color: #666;
  .status {
    color: #9ca3af;
    margin-bottom: 5px;
    font-size: 13px;
    @media only screen and (min-width: 321px) and (max-width: 552px) {
      font-size: 12px;
    }
    @media only screen and (max-width: 320px) {
      font-size: 10px;
    }
  }
  .roundedDetal {
    margin-bottom: 5px;
    background-color: #ecffe9;
    width: fit-content;
    padding: 1px 0px;
    font-size: 13px;
    @media only screen and (min-width: 321px) and (max-width: 552px) {
      font-size: 12px;
    }
    @media only screen and (max-width: 320px) {
      font-size: 10px;
    }
  }
  .detailsBtn {
    font-size: 15px;
    margin-top: 17px;
    font-weight: 600;
    text-transform: lowercase;

    @media screen and (max-width: 1286px) {
      margin-top: -5px;
    }
    @media screen and (min-width: 1026px) {
      margin-top: 30px;
    }
  }
  .tecketActions {
    display: flex;
    justify-content: space-between;
    margin-top: 17px !important;
    @media screen and (min-width: 768px) {
      width: 260px;
    }
    .shareIcon {
      color: #358ee1;
    }
  }
  .btn {
    font-size: 13px;
    font-weight: 600;
    text-transform: capitalize;
    border: none;
    background-color: none;
    color: blue;
    cursor: pointer;
    @media only screen and (min-width: 321px) and (max-width: 552px) {
      font-size: 10px;
    }
    @media only screen and (max-width: 320px) {
      font-size: 9px;
    }
  }

  .btn_success {
    font-size: 13px;
    font-weight: 600;
    text-transform: capitalize;
    border: none;
    background-color: none;
    color: green;
    cursor: pointer;
    @media only screen and (min-width: 321px) and (max-width: 552px) {
      font-size: 10px;
    }
    @media only screen and (max-width: 320px) {
      font-size: 9px;
    }
  }
`;

export const ShareIcon = styled.div`
  position: absolute;
  right: 15px;
  bottom: 24px;
`;
