import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Grid } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { TextField } from "@mui/material";
import { PrimaryButton } from "../../../Layouts/ButtonsControlled";
import { ModalContent } from "./PmAssignModalStyle";
import { getAllPM_Users } from "../../../Redux/Slices/UsersSlice/UsersSlice";
import { postAssignPM_User } from "../../../Redux/Slices/AssignSlice/AssignSlice";
import { useDispatch, useSelector } from "react-redux";
import { getSingleTicket } from "../../../Redux/Slices/SingleTicketSlice/SingleTicketSlice";
import { getWorkOrders } from "../../../Redux/Slices/WorkOrders/WorkOrdersSlice";
function PmAssignModal({
  handleCloseTicketModal,
  openTicketModal,
  selectedTicket,
  setOpenTicketModal,
  ticket_id,
}) {
  const dispatch = useDispatch();
  const PM_user = useSelector((state) => state.PM_user);

  const [user, setUser] = useState();
  const [note, setNote] = useState();
  useEffect(() => {
    dispatch(getAllPM_Users());
  }, []);

  console.log(selectedTicket, "selectedTicket in modal");
  const handleSelectUser = (user) => {
    setUser(user);
    console.log(user.id, "pppppm user");
  };
  const handleNoteChange = (e) => {
    setNote(e.target.value);
  };
  const handleSubmitAssign_PM = async (e) => {
    e.preventDefault();
    const reqData = {
      user_id: user.id,
      ticket_id: selectedTicket ? selectedTicket.id : ticket_id,
      note: note,
    };
    console.log(reqData);
    await dispatch(postAssignPM_User(reqData));
    await setOpenTicketModal(false);
    await dispatch(getSingleTicket(ticket_id));
    await dispatch(getWorkOrders(ticket_id));
  };

  return (
    <Dialog
      maxWidth={"xs"}
      open={openTicketModal}
      onClose={handleCloseTicketModal}
    >
      <DialogContent>
        <ModalContent>
          <form>
            <Grid className="display">
              <FormControl variant="outlined">
                <InputLabel htmlFor="component-simple">PM </InputLabel>
                <Select
                  style={{ width: "300px", marginTop: "15px" }}
                  name="user_id"
                  // value={user}
                  onChange={(e) => setUser(e.target.value)}
                >
                  {PM_user?.PMusers?.data?.map((user) => (
                    <MenuItem
                      value={user}
                      name="user_id"
                      onClick={() => handleSelectUser(user)}
                    >
                      {user.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid className="display">
              <FormControl variant="outlined">
                <InputLabel htmlFor="component-simple">Notes</InputLabel>
                <TextField
                  multiline
                  rows={3}
                  style={{ width: "300px", marginTop: "50px" }}
                  name="note"
                  value={note}
                  onChange={handleNoteChange}
                />
              </FormControl>
            </Grid>
            <DialogActions>
              <PrimaryButton
                fullWidth
                style={{
                  padding: "0",
                  width: "85%",
                  margin: "15px auto",
                  height: "42px",
                }}
                className="saveButton"
                onClick={handleSubmitAssign_PM}
              >
                Save
              </PrimaryButton>
            </DialogActions>
          </form>
        </ModalContent>
      </DialogContent>
    </Dialog>
  );
}

export default PmAssignModal;
