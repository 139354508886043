import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Tickets_Counts_Service from "./TicketsCountsService";

const initialState = {
  ticketsCounts: null,
  isError: false,
  isSuccess: false,
  isLodaing: false,
  massage: "",
};

export const getTicketsCountsAction = createAsyncThunk(
  "ticketsCounts/getAll",
  async (thunkAPI) => {
    try {
      return await Tickets_Counts_Service.Get_Tickets_Counts_Action();
    } catch (error) {
      const massage = error.message;
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const TicketsCountsSlice = createSlice({
  name: "ticketsCounts",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLodaing = false;
      state.isDeleteLodaing = false;
      state.massage = "";
    },
  },
  extraReducers: {
    [getTicketsCountsAction.pending]: (state) => {
      state.isLodaing = true;
    },
    [getTicketsCountsAction.fulfilled]: (state, action) => {
      state.isLodaing = false;
      state.ticketsCounts = action.payload;
      state.isSuccess = true;
    },
    [getTicketsCountsAction.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
  },
});

export const { reset } = TicketsCountsSlice.actions;
export default TicketsCountsSlice.reducer;
