import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import TicketStatusService from "./TicketStatusService";

const initialState = {
  TicketStatus: {},
  isError: false,
  isSuccess: false,
  isLodaing: false,
  massage: "",
};

export const getTicketStatus = createAsyncThunk(
  "getTicketStatus/get",
  async (id, thunkAPI) => {
    try {
      return await TicketStatusService.getTicketStatusAction(id);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const TicketStatusSlice = createSlice({
  name: "TicketStatus",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLodaing = false;
      state.massage = "";
    },
  },
  extraReducers: {
    //get ticket status call
    [getTicketStatus.pending]: (state) => {
      state.isLodaing = true;
    },
    [getTicketStatus.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.TicketStatus = payload;
    },
    [getTicketStatus.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
      state.TicketStatus = null;
    },
  },
});

export const { reset } = TicketStatusSlice.actions;
export default TicketStatusSlice.reducer;
