import axios from "axios";
import { craete_card ,  get_single_card , update_single_card , delete_single_card}  from "../../../Api/Api";


  const getSingleCardofticketAction = async (data) => {
    const { token } = JSON.parse(localStorage.getItem("user"));
    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    };
    const response = await axios({
      method: "GET",
      baseURL: `https://pmb.boxbyld.tech/api/cards/data/${data.card_id}/${data.ticket_id}`,
     
      headers: headers,
      // data: data,
    });
    return response.data;
  };



 



const getticketCardService = {

  getSingleCardofticketAction,


};
export default getticketCardService;
