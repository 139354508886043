import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getTicketWelcomeCll } from "../../Redux/Slices/WelcomeCallSlice/WelcomeCallSlice";
import { SteperContainer } from "../../Components/ProcessSteper/ProcessSteperStyle";
import {
  getCharterDetails,
  approveCharter,
} from "../../Redux/Slices/CharterSlice/CharterSlice";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { PrimaryButton } from "../../Layouts/ButtonsControlled";
import CharterData from "../../Components/CharterData/CharterData";
import LottieAnimation from "../../Components/LottieAnimation/LottieAnimation";
import loading from "../../Assets/lottie/loading.json";
const CharterDetails = () => {
  const { lead_id } = useParams();

  const dispatch = useDispatch();
  const [status, setStatus] = useState();
  const charter_details = useSelector((state) => state.charter);
  const charter_id = charter_details?.charter?.data?.id;
  console.log(charter_details?.isLodaing, "charter_details245");

  useEffect(() => {
    dispatch(getCharterDetails(lead_id));
  }, []);

  console.log(status, "status");
  const change = (event) => {
    //this.setState({value: event.target.value});
    setStatus(event.target.value);
  };

  const handleConfiremCharter = async () => {
    const reqData = {
      id: charter_id,
      status: status,
    };
    await dispatch(approveCharter(reqData));
    await dispatch(getCharterDetails(lead_id));
  };
  return (
    <>
      <>
        <MainTabsStyled>
          {charter_details?.isLodaing ? (
            <Box
              sx={{ width: "100%", typography: "body1" }}
              style={{ backgroundColor: "white" }}
            >
              <LottieAnimation lotti={loading} width={180} height={180} />
            </Box>
          ) : (
            <Box
              sx={{ width: "100%", typography: "body1" }}
              style={{ backgroundColor: "white" }}
            >
              {charter_details?.charter == null ? (
                <Box
                  sx={{ width: "100%", typography: "body1" }}
                  style={{ backgroundColor: "white", padding: "30px" }}
                >
                  <Typography variant="h6" align="center">
                    {" "}
                    There is no data
                  </Typography>
                </Box>
              ) : (
                <Grid container columns={12}>
                  <Grid item xs={12} sm={12} lg={12}>
                    <SteperContainer>
                      <CharterData charter_details={charter_details} />

                      <form>
                        <Grid className="display" mt={4}>
                          <FormControl variant="outlined">
                            <InputLabel htmlFor="component-simple">
                              Status
                            </InputLabel>
                            <Select
                              style={{ width: "300px", marginTop: "15px" }}
                              id="status"
                              onChange={(event) => change(event)}
                              value={status}
                              name="status"
                            >
                              <MenuItem value="pending">pending </MenuItem>
                              <MenuItem value="rejected">rejected</MenuItem>
                              <MenuItem value="approved">approved</MenuItem>
                            </Select>
                          </FormControl>
                          <PrimaryButton
                            fullWidth
                            style={{
                              padding: "0",
                              width: "85%",
                              margin: "15px auto",
                              height: "42px",
                            }}
                            className="saveButton"
                            onClick={handleConfiremCharter}
                          >
                            Save
                          </PrimaryButton>
                        </Grid>
                      </form>
                    </SteperContainer>
                  </Grid>
                </Grid>
              )}
            </Box>
          )}
        </MainTabsStyled>
      </>
    </>
  );
};
const MainTabsStyled = styled.div`
  margin: 1rem 0.8rem;
  border-radius: 5px;
`;
export default CharterDetails;
