import axios from "axios";
import {
  create_adders_costs_specific_cards,
  show_adders_costs_specific_cards,
} from "../../../Api/Api";
import { toast } from "react-toastify";
const createAddersCostSpecificCards = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${create_adders_costs_specific_cards}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("Adders added Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const showAddersCostSpecificCards = async (id) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${show_adders_costs_specific_cards}/${id}`,
    headers: headers,
    // params: {
    //   type: data.type,
    // },
    // data: data,
  });

  return response.data;
};

const deleteAddersCostSpecificCards = async (id) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "DELETE",
    baseURL: `${show_adders_costs_specific_cards}/${id}`,
    headers: headers,
  });

  return response.data;
};

const updateAddersCostSpecificCards = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${show_adders_costs_specific_cards}/${data.id}`,
    headers: headers,
    data: data,
  });

  return response.data;
};



const AddersCostSpecificCardsService = {
  createAddersCostSpecificCards,
  showAddersCostSpecificCards,
  deleteAddersCostSpecificCards,
  updateAddersCostSpecificCards
};
export default AddersCostSpecificCardsService;
