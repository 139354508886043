import axios from "axios";
import { store_job_reviews, update_job_reviews } from "../../../Api/Api";
import { toast } from "react-toastify";
const store_job_review_with_sales = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${store_job_reviews}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success(" created Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const get_job_review_with_sales_data = async (ticket_id) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${store_job_reviews}/${ticket_id}`,
    headers: headers,
    // data: data,
  });
  return response.data;
};

const edit_job_review_with_sales_data = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${update_job_reviews}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("modified Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const job_review_with_sales_Service = {
  store_job_review_with_sales,
  get_job_review_with_sales_data,
  edit_job_review_with_sales_data,
};
export default job_review_with_sales_Service;
