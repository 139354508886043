import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Grid, Typography, MenuItem, Select, IconButton } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { TextField } from "@mui/material";
import { PrimaryButton } from "../../../Layouts/ButtonsControlled";
import { ModalContent } from "./WelcomeCallModalStyle";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { postWelcomeCall } from "../../../Redux/Slices/WelcomeCallSlice/WelcomeCallSlice";
//import { getTicketWelcomeCll } from "../../../Redux/Slices/WelcomeCallSlice/WelcomeCallSlice";
import { getTicketStatus } from "../../../Redux/Slices/TicketStatus/TicketStatusSlice";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloseIcon from "@mui/icons-material/Close";
function WelcomCallModal({
  handleCloseWelcomeCallModal,
  openWelcomeCallModal,
  setOpenWelcomeCallModal,
  welcomeCall,
}) {
  const { lead_id } = useParams();
  const [lockedGatesChecked, setLockedGatesChecked] = React.useState(0);
  const [anyDogsChecked, setanyDogsChecked] = React.useState(0);
  const [anyUnpermittedChecked, setAnyUnpermittedChecked] = React.useState(0);
  const [comment, setComment] = React.useState();
  const dispatch = useDispatch();
  const welcomCallDetails = useSelector((state) => state.WelcomeCall);
  console.log(welcomCallDetails?.WelcomeCall?.data, "welcomCallDetails");
  const [timePickerValue, setTimePickerValue] = React.useState(
    new Date()?.toISOString()?.split("T", 1)[0]
  );
  const [datePickerValue, setDatePickerValue] = React.useState(
    new Date()?.toISOString()?.split("T", 1)[0]
  );
  const [be_on_site, set_be_on_site] = React.useState();
  const [media, set_media] = React.useState();
  const [confirm_survey_time, set_confirm_survey_time] = React.useState();
  const [showUpload, setShowUpload] = React.useState(false);
  const [event, setEvent] = useState("");
  const [time, setTime] = useState("");
  console.log(media?.File , "media12345")
  const handleChangelockedGatesCheck = () => {
    setLockedGatesChecked(1);
  };

  const handleChangeAnyDogs = () => {
    setanyDogsChecked(1);
  };

  const handleChangeAnyUnpermitted = () => {
    setAnyUnpermittedChecked(1);
  };

  const handleCommentChane = (e) => {
    setComment(e.target.value);
  };

  const handleShowUploadFiles = () => {
    setShowUpload(true);
  };
  const handlecloseUpload = () => {
    setShowUpload(false);
  };

  const UpdateValue = (event, index) => {
    const { name, value, files } = event.target;
    // const media =  [name]: files
    set_media(files[0]);
    console.log(media, "api media ");
    console.log("check this", files[0]);
  };
  let sendTime = new Date(timePickerValue)?.toLocaleString("en-US", {
    hour12: false,
  });
  console.log("sendTimesendTime", sendTime);
  const handleSubmitWelcomeCall = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("any_locked_gates", lockedGatesChecked);
    formData.append("any_dogs", anyDogsChecked);
    formData.append("any_unpermitted", anyUnpermittedChecked);
    formData.append("comment", comment);
    // formData.append("be_on_site", be_on_site);
   // formData.append("confirm_survey_time", confirm_survey_time);
    //formData.append("event", event);
    // formData.append("time", timePickerValue?.$d.toLocaleTimeString("it-IT"));
    // formData.append("time", sendTime.split(", ")[1]);
   // formData.append("time", time);

    formData.append("ticket_id", lead_id);
    formData.append("media", media);
    await dispatch(postWelcomeCall(formData));
    await setOpenWelcomeCallModal(false);
    await dispatch(getTicketStatus(lead_id));
  };

  return (
    <Dialog
      maxWidth="xs"
      fullWidth={true}
      open={openWelcomeCallModal}
      onClose={handleCloseWelcomeCallModal}
    >
      <DialogContent>
        <ModalContent>
          <form onSubmit={handleSubmitWelcomeCall}>
            <Grid>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleChangelockedGatesCheck}
                      value={lockedGatesChecked}
                      name="any_locked_gates"
                    />
                  }
                  label="Any locked gates"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleChangeAnyDogs}
                      value={anyDogsChecked}
                      name="any_dogs"
                    />
                  }
                  label="Any Dogs"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleChangeAnyUnpermitted}
                      value={anyUnpermittedChecked}
                      name="any_unpermitted"
                    />
                  }
                  label="Are there any unpermitted structures"
                />
              </FormGroup>
            </Grid>

            {/* <Grid style={{ margin: "15px 0px " }}>
              <TextField
                style={{ width: "280px" }}
                required
                label="event"
                type="date"
                onChange={(e) => setEvent(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid> */}
            {/* <Grid style={{ margin: "15px 0px " }}>
         
              <TextField
                style={{ width: "280px" }}
                label="time"
                type="time"
                onChange={(e) => setTime(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid> */}
            {/* <Grid style={{ marginBottom: "13px" }}>
              <FormControl variant="outlined">
                <InputLabel htmlFor="component-simple">
                  Prefer To be On Site during Survey ?
                </InputLabel>

                <Select
                  style={{ width: "300px", marginTop: "15px" }}
                  value={be_on_site}
                  onChange={(e) => set_be_on_site(e.target.value)}
                >
                  <MenuItem value="1">Yes</MenuItem>
                  <MenuItem value="0">No</MenuItem>
                </Select>
              </FormControl>
            </Grid> */}

            {/* <Grid>
              <FormControl variant="outlined">
                <InputLabel htmlFor="component-simple">
                  Confirm Survey Time Works?
                </InputLabel>

                <Select
                  style={{ width: "300px", marginTop: "15px" }}
                  value={confirm_survey_time}
                  onChange={(e) => set_confirm_survey_time(e.target.value)}
                >
                  <MenuItem value="1">Yes</MenuItem>
                  <MenuItem value="0">No</MenuItem>
                </Select>
              </FormControl>
            </Grid> */}
            <Grid>
              <div mt={4}>
                <IconButton onClick={() => handleShowUploadFiles()}>
                  <Typography>
                    <span style={{ color: "black", fontWeight: "bold" }}>
                      Upload Files
                    </span>{" "}
                  </Typography>{" "}
                  {!showUpload && (
                    <CloudUploadIcon style={{ marginLeft: "30px" }} />
                  )}
                </IconButton>

                {showUpload && (
                  <IconButton onClick={handlecloseUpload}>
                    <CloseIcon />
                  </IconButton>
                )}
              </div>

              {showUpload && (
                <Grid xs={12} mt={4}>
                  <Grid
                    className="display"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {/* <lable>File</lable> */}
                    <input
                      type="file"
                      name="media"
                      // value={set_media}
                      onChange={(e) => {
                        UpdateValue(e);
                      }}
                      // onChange={(e) => set_media(e.target.files)}
                    ></input>

                    <Grid className="display" mt={4}></Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid>
              <FormControl variant="outlined">
                <InputLabel htmlFor="component-simple">
                  Are you have any comments/questions
                </InputLabel>
                <TextField
                  multiline
                  rows={2}
                  style={{ width: "300px", marginTop: "50px" }}
                  name="comment"
                  value={comment}
                  onChange={handleCommentChane}
                />
              </FormControl>
            </Grid>
            <DialogActions>
              <PrimaryButton
                fullWidth
                style={{
                  padding: "0",
                  width: "85%",
                  margin: "15px auto",
                  height: "42px",
                }}
                className="saveButton"
                // onClick={}
                type="submit"
               disabled={media === undefined}
             
              >
                Send
              </PrimaryButton>
            </DialogActions>
          </form>
        </ModalContent>
      </DialogContent>
    </Dialog>
  );
}

export default WelcomCallModal;
