import styled from "styled-components";
//ticket modal component
export const ModalContent = styled.div`
  padding: 30px 30px 20px;
  .display {
    display: flex;
    justify-content: center;
  }
`;
export const Tabs = styled.section`
  /* background: gray; */
  padding: 1rem;
  /* box-shadow: 0px 3px 6px rgb(0 0 0 / 14%); */
  border-radius: 1rem;
`;
