import axios from "axios";
import {upload_files } from "../../../Api/Api";

const uploadFilesAction = async (data  ) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${upload_files}/${data.get('type')}/${"upload-media"}`,
    headers: headers,
    data: data,
  });
  return response.data;
};




const UploadFilesService = {
    uploadFilesAction,

};
export default UploadFilesService;
