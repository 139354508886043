import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getSingleTicket } from "../../Redux/Slices/SingleTicketSlice/SingleTicketSlice";
import { SideLeadStyled } from "./LeadSideInfoStyle";
const DealType = ({ ticket_id }) => {
  const dispatch = useDispatch();
  const single_ticket = useSelector((state) => state.single_ticket);
  // const { ticket_id } = useParams();

  console.log(single_ticket , "single_ticket 1236")
  useEffect(() => {
    dispatch(getSingleTicket(ticket_id));
  }, [ticket_id]);
  return (
    <>
      <div className="LeadInfo">
        {single_ticket?.isLodaing ? (
          <p>Loading</p>
        ) : (
          <ul>
           

<li >
                <p style={{ color: "black", fontWeight: "bold" , marginBottom:"8px" }}>Financial Institution  :</p>
                <span style={{fontSize:"18px"}}>
                {" "}
                {single_ticket?.singleTicket?.data?.opportunity
                  ?.financial_institution == null
                  ? "cash"
                  : single_ticket?.singleTicket?.data?.opportunity
                      ?.financial_institution}
              </span>
              </li>

              <li >
                <p style={{ color: "black", fontWeight: "bold" , marginBottom:"8px" }}>Dealer Fees:</p>
                <span style={{fontSize:"18px"}}>
                {" "}
                {single_ticket?.singleTicket?.data?.ticket?.project_cost?.dealer_fee} %
                  
              </span>
              </li>

              <li >
                <p style={{ color: "black", fontWeight: "bold" , marginBottom:"8px" }}>Rate / Term :</p>
                <span style={{fontSize:"18px"}}>
                {" "}
               {`${single_ticket?.singleTicket?.data?.opportunity?.rate}% /  ${single_ticket?.singleTicket?.data?.ticket?.project_cost?.term}Years`}
                {/* {single_ticket?.singleTicket?.data?.ticket?.project_cost?.dealer_fee} % */}
                  
              </span>
              </li>
            {single_ticket?.singleTicket?.data?.opportunity
              ?.financial_institution == "goodleap" && (
              <li>
                <p style={{ color: "black" }}>Status:</p>
                <span>
                  {" "}
                  {
                    single_ticket?.singleTicket?.data?.opportunity
                      ?.goodleap_loan?.status
                  }
                </span>
              </li>
            )}
          </ul>
        )}
      </div>
    </>
  );
};

export default DealType;
