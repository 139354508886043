import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Grid, Typography } from "@mui/material";
const TicketConcertDataModal = ({
  open,
  handleCloseconcertDataModal,
  ticket,
}) => {
  console.log(ticket, "ticket");
  return (
    <Dialog
      maxWidth={"sm"}
      fullWidth
      open={open}
      onClose={handleCloseconcertDataModal}
    >
      <DialogContent>
        <DialogContent>
          <Grid
            //container
            columns={12}
            spacing={4}
            // justifyContent="center"
            // style={{ margin: "10px" }}
          >
            <Grid xs={12} lg={12}>
              <Typography variant="h6">
                step doc decision change :{" "}
                <span>
                  <span style={{ color: "gray" }}>
                    {" "}
                    {ticket?.finance_status?.step_doc_decision_change?.length ==
                    0
                      ? "NA"
                      : ticket?.finance_status?.step_doc_decision_change}{" "}
                  </span>
                </span>
              </Typography>
            </Grid>

            <Grid xs={12} lg={12}>
              <Typography variant="h6">
                step status change :{" "}
                <span>
                  <span style={{ color: "gray" }}>
                    {" "}
                    {ticket?.finance_status?.step_status_change?.length == 0
                      ? "NA"
                      : ticket?.finance_status?.step_status_change}{" "}
                  </span>
                </span>
              </Typography>
            </Grid>

            <Grid xs={12} lg={12}>
              <Typography variant="h6">
                stip required change :{" "}
                <span>
                  <span style={{ color: "gray" }}>
                    {" "}
                    {ticket?.finance_status?.stip_required_change?.length == 0
                      ? "NA"
                      : ticket?.finance_status?.stip_required_change}{" "}
                  </span>
                </span>
              </Typography>
            </Grid>
            <Grid xs={12} lg={12}>
              <Typography variant="h6">
                stip status change :{" "}
                <span>
                  <span style={{ color: "gray" }}>
                    {" "}
                    {ticket?.finance_status?.stip_status_change?.length == 0
                      ? "NA"
                      : ticket?.finance_status?.stip_status_change}{" "}
                  </span>
                </span>
              </Typography>
            </Grid>

            <Grid xs={12} lg={12}>
              <Typography variant="h6">
                title status change :{" "}
                <span>
                  <span style={{ color: "gray" }}>
                    {" "}
                    {ticket?.finance_status?.title_status_change?.length == 0
                      ? "NA"
                      : ticket?.finance_status?.title_status_change}{" "}
                  </span>
                </span>
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
      </DialogContent>
    </Dialog>
  );
};

export default TicketConcertDataModal;
