import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getSingleTicket } from "../../Redux/Slices/SingleTicketSlice/SingleTicketSlice";
import InstallersList from "./InstallersList";
const CreatSiteSurvey = () => {
  const dispatch = useDispatch();
  const { ticket_id } = useParams();

  const single_ticket = useSelector((state) => state.single_ticket);

  console.log(single_ticket, "single_ticket");
  useEffect(() => {
    dispatch(getSingleTicket(ticket_id));
    // dispatch(getInstallersListAction(state_id));
  }, []);

  return (
    <>
      <InstallersList />
    </>
  );
};
export default CreatSiteSurvey;
