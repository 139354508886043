import React from "react";
import { Grid, TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import { PrimaryButton } from "../../Layouts/ButtonsControlled";
import CircularProgress from "@mui/material/CircularProgress";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { authentication } from "../../Redux/Slices/AuthSlice/AuthSlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string("Enter your password")
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});
function LoginForm() {
  const navigate = useNavigate();
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const dispatch = useDispatch();

  const { status, isAuth, message } = useSelector((state) => state?.auth);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await dispatch(authentication(values));
      await navigate(`/home/user-section/tickets/allTickets`);
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid container>
          <Grid item mb={2} xs={12}>
            <InputLabel htmlFor="component-simple">Email</InputLabel>
            <TextField
              fullWidth
              id="input-with-icon-textfield"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item mb={2} xs={12}>
            <InputLabel htmlFor="component-simple">password</InputLabel>
            <TextField
              fullWidth
              id="input-with-icon-textfield"
              name="password"
              type="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </Grid>
        </Grid>

        <Grid item style={{ display: "flex", justifyContent: "space-between" }}>
          <div>forget password </div>
        </Grid>
        <PrimaryButton
          fullWidth
          type="submit"
          endIcon={
            status === "loading" ? (
              <CircularProgress size={25} thickness={18} />
            ) : null
          }
        >
          Sign in
        </PrimaryButton>
      </form>
    </>
  );
}

export default LoginForm;
