import React from "react";
import Typography from "@mui/material/Typography";
import { Grid, Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import { useSelector } from "react-redux";
function ThirdCharterForm({ formik }) {
  const single_ticket = useSelector((state) => state.single_ticket);
  const is_meter_socket =
    single_ticket?.singleTicket?.data?.opportunity?.is_meter_socket;
  const project_valuation = single_ticket?.singleTicket?.data?.contract?.cost;
  const Panels_Manufacturer =
    single_ticket?.singleTicket?.data?.opportunity?.panels;
  const inverter_type =
    single_ticket?.singleTicket?.data?.opportunity?.inverter_type;

  return (
    <>
      <Grid item xs={12} lg={4} md={6} mt={3}>
        <Typography variant="h5" mb={1} ml={32}>
          {" "}
          Roofing{" "}
        </Typography>
      </Grid>
      <Grid
        container
        columns={12}
        spacing={5}
        justifyContent="center"
        mb={3}
      // mt={2}
      >
        <Grid item xs={12} sm={5} lg={4}>
          <InputLabel htmlFor="component-simple">
            Is there Redecking in contract?
          </InputLabel>
          <TextField
            fullWidth
            id="input-with-icon-textfield"
            name="roofing_contract_redecking"
            value={formik.values.roofing_contract_redecking}
            onChange={formik.handleChange}
            error={
              formik.touched.roofing_contract_redecking &&
              Boolean(formik.errors.roofing_contract_redecking)
            }
            helperText={
              formik.touched.roofing_contract_redecking &&
              formik.errors.roofing_contract_redecking
            }
          />
        </Grid>

        <Grid item xs={12} sm={5} lg={4}>
          <InputLabel htmlFor="component-simple">Shingles?</InputLabel>
          <TextField
            fullWidth
            id="input-with-icon-textfield"
            name="roofing_shingles"
            value={formik.values.roofing_shingles}
            onChange={formik.handleChange}
            error={
              formik.touched.roofing_shingles &&
              Boolean(formik.errors.roofing_shingles)
            }
            helperText={
              formik.touched.roofing_shingles && formik.errors.roofing_shingles
            }
            disabled
          />
        </Grid>
      </Grid>
      <Grid container columns={12} spacing={5} justifyContent="center" mb={3}>
        <Grid item xs={12} sm={10} lg={8}>
          <InputLabel htmlFor="component-simple">
            Type Of Inspections
          </InputLabel>
          <TextField
            fullWidth
            id="input-with-icon-textfield"
            name="type_of_inspections_roofing"
            value={formik.values.type_of_inspections_roofing}
            onChange={formik.handleChange}
            error={
              formik.touched.type_of_inspections_roofing &&
              Boolean(formik.errors.type_of_inspections_roofing)
            }
            helperText={
              formik.touched.type_of_inspections_roofing &&
              formik.errors.type_of_inspections_roofing
            }
          />
        </Grid>
      </Grid>
      <Grid item xs={12} lg={4} md={6} mt={3}>
        <Typography className="fieldDetails" variant="h5" mb={1}>
          {" "}
          MPU{" "}
        </Typography>
      </Grid>

      <Grid container columns={12} spacing={5} justifyContent="center" mb={3}>
        {is_meter_socket !== 0 && (
          <Grid item xs={12} sm={5} lg={4}>
            <InputLabel htmlFor="component-simple">
              Meter spot / Shutoff
            </InputLabel>
            <TextField
              fullWidth
              id="input-with-icon-textfield"
              name="mpu_meter_spot"
              // value={formik.values.mpu_meter_spot}
              value={is_meter_socket === 0 ? "No" : ""}
              onChange={formik.handleChange}
              error={
                formik.touched.mpu_meter_spot &&
                Boolean(formik.errors.mpu_meter_spot)
              }
              helperText={
                formik.touched.mpu_meter_spot && formik.errors.mpu_meter_spot
              }
              disabled={is_meter_socket === 0}
            />
          </Grid>
        )}


        <Grid item xs={12} sm={5} lg={4}>
          <InputLabel htmlFor="component-simple">Shutoff Required?</InputLabel>
          <Select
            fullWidth
            id="input-with-icon-textfield"
            name="mpu_shutoff_required"
            value={formik.values.mpu_shutoff_required}
            onChange={formik.handleChange}
            error={
              formik.touched.mpu_shutoff_required &&
              Boolean(formik.errors.mpu_shutoff_required)
            }
            helperText={
              formik.touched.mpu_shutoff_required &&
              formik.errors.mpu_shutoff_required
            }
          >
            <MenuItem value="yes" label="yes">
              yes
            </MenuItem>
            <MenuItem value="no" label="No">
              No
            </MenuItem>
          </Select>
        </Grid>
      </Grid>
      <Grid container columns={12} spacing={5} justifyContent="center" mb={3}>
        <Grid item xs={12} sm={10} lg={8}>
          <InputLabel htmlFor="component-simple">
            Type Of Inspections
          </InputLabel>
          <TextField
            fullWidth
            id="input-with-icon-textfield"
            name="type_of_inspections_mpu"
            value={formik.values.type_of_inspections_mpu}
            onChange={formik.handleChange}
            error={
              formik.touched.type_of_inspections_mpu &&
              Boolean(formik.errors.type_of_inspections_mpu)
            }
            helperText={
              formik.touched.type_of_inspections_mpu &&
              formik.errors.type_of_inspections_mpu
            }
          />
        </Grid>
      </Grid>
      <Grid item xs={12} lg={4} md={6} mt={3}>
        <Typography className="fieldDetails" variant="h5" mb={1}>
          {" "}
          Products{" "}
        </Typography>
      </Grid>
      <Grid container columns={12} spacing={5} justifyContent="center" mb={3}>
        <Grid item xs={12} sm={5} lg={4}>
          <InputLabel htmlFor="component-simple">Project Valuation</InputLabel>
          <TextField
            fullWidth
            id="input-with-icon-textfield"
            name="project_valuation"
            //value={formik.values.project_valuation}
            value={project_valuation}
            onChange={formik.handleChange}
            error={
              formik.touched.project_valuation &&
              Boolean(formik.errors.project_valuation)
            }
            helperText={
              formik.touched.project_valuation &&
              formik.errors.project_valuation
            }
            disabled
          />
        </Grid>

        <Grid item xs={12} sm={5} lg={4}>
          <InputLabel htmlFor="component-simple">
            Panels Manufacturer & Model
          </InputLabel>
          <TextField
            fullWidth
            id="input-with-icon-textfield"
            name="products_manufacturer"
            // value={formik.values.products_manufacturer}
            value={Panels_Manufacturer}
            onChange={formik.handleChange}
            error={
              formik.touched.products_manufacturer &&
              Boolean(formik.errors.products_manufacturer)
            }
            helperText={
              formik.touched.products_manufacturer &&
              formik.errors.products_manufacturer
            }
            disabled
          />
        </Grid>
      </Grid>

      <Grid container columns={12} spacing={5} justifyContent="center" mb={3}>
        <Grid item xs={12} sm={5} lg={4}>
          <InputLabel htmlFor="component-simple">
            Inverter(s) Manufacturer & Model
          </InputLabel>
          <TextField
            fullWidth
            id="input-with-icon-textfield"
            name="inverter_manufacturer_model"
            // value={formik.values.inverter_manufacturer_model}
            value={inverter_type}
            onChange={formik.handleChange}
            error={
              formik.touched.inverter_manufacturer_model &&
              Boolean(formik.errors.inverter_manufacturer_model)
            }
            helperText={
              formik.touched.inverter_manufacturer_model &&
              formik.errors.inverter_manufacturer_model
            }
            disabled
          />
        </Grid>

        <Grid item xs={12} sm={5} lg={4}>
          <InputLabel htmlFor="component-simple">
            Products Total Price
          </InputLabel>
          <TextField
            fullWidth
            id="input-with-icon-textfield"
            name="products_total_price"
            value={formik.values.products_total_price}
            onChange={formik.handleChange}
            error={
              formik.touched.products_total_price &&
              Boolean(formik.errors.products_total_price)
            }
            helperText={
              formik.touched.products_total_price &&
              formik.errors.products_total_price
            }
          />
        </Grid>
      </Grid>
    </>
  );
}

export default ThirdCharterForm;
