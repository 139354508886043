import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { SteperContainer } from "../../Components/ProcessSteper/ProcessSteperStyle";
import {
  getCharterDetails,
  approveCharter,
} from "../../Redux/Slices/CharterSlice/CharterSlice";
import { getSingleDesignReqDetails } from "../../Redux/Slices/DesignRequest/DesignRequestSlice";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { PrimaryButton } from "../../Layouts/ButtonsControlled";
import SingleDesignReqData from "../../Components/SigngleDesignReqData/SingleDesignReqData";
const SingleDesignReqDetails = () => {
    const params = useParams();
    console.log(params , "params")
  const { design_req_id  , lead_id} = useParams();
  const dispatch = useDispatch();
  const [status, setStatus] = useState();
  const design_req_details = useSelector((state) => state.design_req);

  console.log(design_req_details?.design_req?.data?.plan?.cost , "design_req_details?.design_req?.data?.plan?.cost")

  //   const charter_id = design_req?.charter?.data?.id;
  //   console.log(
  //     lead_id,
  //     charter_details?.charter?.data?.id,
  //     "lead_id in charter details"
  //   );
  useEffect(() => {
    dispatch(getSingleDesignReqDetails(design_req_id));
  }, []);

  console.log(status, "status");
  const change = (event) => {
    //this.setState({value: event.target.value});
    setStatus(event.target.value);
  };

  //   const handleConfiremCharter = () => {
  //     const reqData = {
  //       id: charter_id,
  //       status: status,
  //     };
  //     dispatch(approveCharter(reqData));
  //   };
  return (
    <>
      <>
        <MainTabsStyled>
          <Box
            sx={{ width: "100%", typography: "body1" }}
            style={{ backgroundColor: "white" }}
          >
            <Grid container columns={12}>
              <Grid item xs={12} sm={12} lg={12}>
                <SteperContainer>
                  <SingleDesignReqData design_req_details={design_req_details} 
                  lead_id={lead_id}
                   initialValues={{
                    cost:design_req_details?.design_req?.data?.plan?.cost
                   }}
                  />
                </SteperContainer>
              </Grid>
            </Grid>
          </Box>
        </MainTabsStyled>
      </>
    </>
  );
};
const MainTabsStyled = styled.div`
  margin: 1rem 0.8rem;
  border-radius: 5px;
`;
export default SingleDesignReqDetails;
