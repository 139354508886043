import axios from "axios";
import { WelcomeCall, getWelcomeCall, ticket_status } from "../../../Api/Api";

const welcomeCallAction = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${WelcomeCall}`,
    headers: headers,
    data: data,
  });
  return response.data;
};

const getWelcomeCallAction = async (id) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${getWelcomeCall}/${id}`,
    headers: headers,
    // data: data,
  });
  return response.data;
};

const getTicketWelcomeCallsAction = async (id) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${ticket_status}/${id}`,
    headers: headers,
    // data: data,
  });
  return response.data;
};

const welcomeCallService = {
  welcomeCallAction,
  getWelcomeCallAction,
  getTicketWelcomeCallsAction,
};
export default welcomeCallService;
