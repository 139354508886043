import {
  Grid,
  TextField,
  InputLabel,
  MenuItem,
  Switch,
  Button,
  FormControl,
  Select,
  Slider,
  Typography,
  Snackbar,
  Alert,
  Modal,
  Box,
  Checkbox,
  FormControlLabel,
  TextareaAutosize,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { v4 as uuidv4 } from "uuid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import React, { useEffect, useState } from "react";
import styleds from "styled-components";
import { LocalizationProvider, DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { useDispatch, useSelector } from "react-redux";
import Table from "@mui/material/Table";
// import TableContainer from "@mui/material/TableContainer";
import { useLocation, useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import TableContainer from "@mui/material/TableContainer";
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { store_addons_data } from "../../Redux/Slices/AddonsSlice/AddonsSlice";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "1rem",
  boxShadow: 24,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  p: 4,
};
const styleHvac = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
  // height: "90%",
  display: "block",
};

const hvacLocations = [
  { name: "Same Location", value: "same_location" },
  { name: "New Location Electrical", value: "new_location_electrical" },
  { name: "New Location Plumbing", value: "new_location_plumbing" },
  { name: "Roof Unit", value: "roof_unit" },
];

const betteryPower = [
  { name: "3KwH", value: "3KwH" },
  { name: "10KwH", value: "10KwH" },
];
const generatorPower = [
  { name: "BLUETTI 5KwH", value: "5KwH" },
  { name: "BLUETTI 10KwH", value: "10KwH" },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const AddonsForm = ({ id }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const { lead_id } = useParams();
  console.log(lead_id, "lead_id");

  // Main State
  const [value, setValue] = useState({});
  const [open, setOpen] = useState(true);
  // Add Hvac
  const [openHvac, setOpenHvac] = React.useState(false);
  const handleOpenHvac = () => setOpenHvac(true);

  const [hvacs, setHvacs] = React.useState([]);
  console.log(hvacs, "hvacs test555");
  const handleCloseHvac = () => {
    setOpenHvac(false);
  };
  const [singleHvac, setSignleHvac] = React.useState();
  // Add Battery
  const [openBattery, setOpenBattery] = React.useState(false);
  const handleOpenBattery = () => setOpenBattery(true);
  const handleCloseBattery = () => setOpenBattery(false);
  const [batteries, setBatteries] = React.useState([]);
  console.log(batteries, "batteries");
  const [singleBattery, setSignleBattery] = React.useState();
  // Add Generator
  const [openGenerator, setOpenGenerator] = React.useState(false);
  const handleOpenGenerator = () => setOpenGenerator(true);
  const handleCloseGenerator = () => setOpenGenerator(false);
  const [generators, setGenerators] = React.useState([]);

  const [singleGenerator, setSignleGenerator] = React.useState();
  console.log("generatorsgeneratorsgenerators", generators);
  React.useEffect(() => {
    if (hvacs.length < 1) {
      setValue({ ...value, is_hvac: false });
    }
  }, [hvacs]);

  const AddHvac = async () => {
    await setHvacs((preResult) => [
      ...preResult,
      {
        name: singleHvac?.name,
        number: singleHvac?.number || 1,
        price: singleHvac?.price,
        id: uuidv4(),
        type: "hvac",
      },
    ]);

    await setValue({
      ...value,
      is_hvac: true,
    });
    await setSignleHvac(null);
    await handleCloseHvac();

    console.log(hvacs, "hvacshvacs");
  };
  const AddBattery = async () => {
    await setBatteries((preResult) => [
      ...preResult,
      {
        name: singleBattery?.name,
        number: singleBattery?.number || 1,
        price: singleBattery?.price,
        id: uuidv4(),
        type: "battery",
      },
    ]);
    await setValue({
      ...value,
      is_battery: true,
    });
    await setSignleBattery(null);
    await handleCloseBattery();
  };
  const AddGenerator = async () => {
    await setGenerators((preResult) => [
      ...preResult,
      {
        name: singleGenerator?.name,
        number: singleGenerator?.number || 1,
        id: uuidv4(),
        price: singleGenerator?.price,
        type: "generator",
      },
    ]);

    await setValue({
      ...value,
      is_generator: true,
    });
    await setSignleGenerator(null);
    await handleCloseGenerator();
  };
  React.useEffect(() => {
    if (batteries.length < 1) {
      setValue({ ...value, is_battery: false });
    }
  }, [batteries]);

  console.log("value", value);
  let all_required_data = [...generators, ...hvacs, ...batteries];
  const HandelSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("ticket_id", lead_id);
    all_required_data?.map((e, i) => {
      for (const property in e) {
        formData.append(`addons[${i}][${property}]`, e[property]);
      }
    });
    await dispatch(store_addons_data(formData));
  };

  const deleteFromLicenses = (id) => {
    const newHvac = [...hvacs];
    const index = hvacs.findIndex((e) => e.id === id);
    // console.log("TheRow", index);
    newHvac.splice(index, 1);
    setHvacs(newHvac);
  };
  const deleteFromBatteries = (id) => {
    const newBattery = [...batteries];
    const index = batteries.findIndex((e) => e.id === id);
    // console.log("TheRow", index);
    newBattery.splice(index, 1);
    setBatteries(newBattery);
  };
  const deleteFromGenerators = (id) => {
    const newGenerator = [...generators];
    const index = generators.findIndex((e) => e.id === id);
    // console.log("TheRow", index);
    newGenerator.splice(index, 1);
    setGenerators(newGenerator);
  };

  return (
    <>
      <>
        <Box sx={{ flexGrow: 1 }} style={{ margin: "25px 0px" }}>
          <AppBar position="static" style={{ backgroundColor: "#eeebeb" }}>
            <Toolbar>
              <Typography
                variant="h6"
                component="div"
                sx={{ flexGrow: 1 }}
                style={{ color: "black" }}
              >
                {/* All Addons */}
                <Link to={`/home/all_addons/${lead_id}`}>
                  <Button variant="contained" startIcon={<ArrowBackIcon />}>
                    {" "}
                    Addons List
                  </Button>
                </Link>
              </Typography>

              <Typography variant="h6" style={{ color: "black" }}>
                Create Addon
              </Typography>
            </Toolbar>
          </AppBar>
        </Box>

        <FormConatiner>
          <form onSubmit={HandelSubmit}>
            {/* <form> */}
            <Grid item container spacing={2} columns={18}>
              <Grid item xs={18}>
                <div className="hvac">
                  <h3> HVAC Information</h3>{" "}
                </div>
                <hr />
              </Grid>
              <Grid item xs={18}>
                <Button
                  onClick={handleOpenHvac}
                  className="btn"
                  endIcon={<AddIcon></AddIcon>}
                  // style={{ color: "#4acfac" }}
                >
                  Add Hvac
                </Button>
              </Grid>

              {hvacs.length > 0 && (
                <Grid item lg={18} sm={18} xs={18}>
                  <TableContainer>
                    <Table>
                      <thead>
                        <tr>
                          <th
                            style={{
                              border: " 1px solid #dddddd",
                              textAlign: "left",
                              padding: "8px",
                            }}
                          >
                            Index
                          </th>
                          <th
                            style={{
                              border: " 1px solid #dddddd",
                              textAlign: "left",
                              padding: "8px",
                            }}
                          >
                            hvac location
                          </th>
                          <th
                            style={{
                              border: " 1px solid #dddddd",
                              textAlign: "left",
                              padding: "8px",
                            }}
                          >
                            number hvac ft
                          </th>
                          <th
                            style={{
                              border: " 1px solid #dddddd",
                              textAlign: "left",
                              padding: "8px",
                            }}
                          >
                            price
                          </th>
                          <th
                            style={{
                              border: " 1px solid #dddddd",
                              textAlign: "left",
                              padding: "8px",
                            }}
                          >
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {hvacs.map((e, i) => {
                          return (
                            <tr key={i}>
                              <td
                                style={{
                                  border: " 1px solid #dddddd",
                                  textAlign: "left",
                                  padding: "8px",
                                }}
                              >
                                {++i}
                              </td>
                              <td
                                style={{
                                  border: " 1px solid #dddddd",
                                  textAlign: "left",
                                  padding: "8px",
                                }}
                              >
                                {e.name}
                              </td>
                              <td
                                style={{
                                  border: " 1px solid #dddddd",
                                  textAlign: "left",
                                  padding: "8px",
                                }}
                              >
                                {e.number}
                              </td>
                              <td
                                style={{
                                  border: " 1px solid #dddddd",
                                  textAlign: "left",
                                  padding: "8px",
                                }}
                              >
                                {e.price}
                              </td>
                              <td
                                style={{
                                  border: " 1px solid #dddddd",
                                  textAlign: "left",
                                  padding: "8px",
                                }}
                              >
                                <button
                                  style={{
                                    background: "none",
                                    color: "red",
                                    border: "none",
                                    cursor: "pointer",
                                  }}
                                  type="button"
                                  onClick={() => {
                                    deleteFromLicenses(e.id);
                                  }}
                                >
                                  <DeleteIcon></DeleteIcon>
                                  {/* <DeleteIcon></DeleteIcon> */}
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </TableContainer>
                </Grid>
              )}

              {/*------------- start the Electrical  ------------*/}
              <Grid item xs={18}>
                <div className="hvac">
                  <h3>Battery Information</h3>
                </div>
                <hr />
              </Grid>
              <Grid item xs={18}>
                <Button
                  onClick={handleOpenBattery}
                  className="btn"
                  endIcon={<AddIcon></AddIcon>}
                >
                  Add Battery
                </Button>
              </Grid>
              {/* <Grid container columns={18}> */}
              {batteries.length > 0 && (
                <Grid item lg={18} sm={18} xs={18}>
                  <TableContainer>
                    <Table
                      style={{
                        fontFamily: "arial, sans-serif",
                        borderCollapse: "collapse",
                        width: "100%",
                      }}
                    >
                      <thead>
                        <tr>
                          <th
                            style={{
                              border: " 1px solid #dddddd",
                              textAlign: "left",
                              padding: "8px",
                            }}
                          >
                            Index
                          </th>
                          <th
                            style={{
                              border: " 1px solid #dddddd",
                              textAlign: "left",
                              padding: "8px",
                            }}
                          >
                            Battery Power
                          </th>
                          <th
                            style={{
                              border: " 1px solid #dddddd",
                              textAlign: "left",
                              padding: "8px",
                            }}
                          >
                            Number of batteries
                          </th>
                          <th
                            style={{
                              border: " 1px solid #dddddd",
                              textAlign: "left",
                              padding: "8px",
                            }}
                          >
                            price
                          </th>
                          <th
                            style={{
                              border: " 1px solid #dddddd",
                              textAlign: "left",
                              padding: "8px",
                            }}
                          >
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {batteries.map((e, i) => {
                          return (
                            <tr key={i}>
                              <td
                                style={{
                                  border: " 1px solid #dddddd",
                                  textAlign: "left",
                                  padding: "8px",
                                }}
                              >
                                {++i}
                              </td>
                              <td
                                style={{
                                  border: " 1px solid #dddddd",
                                  textAlign: "left",
                                  padding: "8px",
                                }}
                              >
                                {e.name}
                              </td>
                              <td
                                style={{
                                  border: " 1px solid #dddddd",
                                  textAlign: "left",
                                  padding: "8px",
                                }}
                              >
                                {e.number}
                              </td>

                              <td
                                style={{
                                  border: " 1px solid #dddddd",
                                  textAlign: "left",
                                  padding: "8px",
                                }}
                              >
                                {e.price}
                              </td>
                              <td
                                style={{
                                  border: " 1px solid #dddddd",
                                  textAlign: "left",
                                  padding: "8px",
                                }}
                              >
                                <button
                                  style={{
                                    background: "none",
                                    color: "red",
                                    border: "none",
                                    cursor: "pointer",
                                  }}
                                  type="button"
                                  onClick={() => {
                                    deleteFromBatteries(e.id);
                                  }}
                                >
                                  <DeleteIcon></DeleteIcon>
                                  {/* <DeleteIcon></DeleteIcon> */}
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </TableContainer>
                </Grid>
              )}

              {/* Generators */}
              <Grid item xs={18}>
                <div className="hvac">
                  <h3>Generators Information</h3>
                </div>
                <hr />
              </Grid>
              <Grid item xs={18}>
                <Button
                  onClick={handleOpenGenerator}
                  className="btn"
                  endIcon={<AddIcon></AddIcon>}
                >
                  Add Generator
                </Button>
              </Grid>
              {/* <Grid container columns={18}> */}
              {generators.length > 0 && (
                <Grid item lg={18} sm={18} xs={18}>
                  <TableContainer>
                    <Table
                      style={{
                        fontFamily: "arial, sans-serif",
                        borderCollapse: "collapse",
                        width: "100%",
                      }}
                    >
                      <thead>
                        <tr>
                          <th
                            style={{
                              border: " 1px solid #dddddd",
                              textAlign: "left",
                              padding: "8px",
                            }}
                          >
                            Index
                          </th>
                          <th
                            style={{
                              border: " 1px solid #dddddd",
                              textAlign: "left",
                              padding: "8px",
                            }}
                          >
                            Generator Power
                          </th>
                          <th
                            style={{
                              border: " 1px solid #dddddd",
                              textAlign: "left",
                              padding: "8px",
                            }}
                          >
                            Number of Generators
                          </th>
                          <th
                            style={{
                              border: " 1px solid #dddddd",
                              textAlign: "left",
                              padding: "8px",
                            }}
                          >
                            price
                          </th>
                          <th
                            style={{
                              border: " 1px solid #dddddd",
                              textAlign: "left",
                              padding: "8px",
                            }}
                          >
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {generators.map((e, i) => {
                          return (
                            <tr key={i}>
                              <td
                                style={{
                                  border: " 1px solid #dddddd",
                                  textAlign: "left",
                                  padding: "8px",
                                }}
                              >
                                {++i}
                              </td>
                              <td
                                style={{
                                  border: " 1px solid #dddddd",
                                  textAlign: "left",
                                  padding: "8px",
                                }}
                              >
                                {e.name}
                              </td>
                              <td
                                style={{
                                  border: " 1px solid #dddddd",
                                  textAlign: "left",
                                  padding: "8px",
                                }}
                              >
                                {e.number}
                              </td>

                              <td
                                style={{
                                  border: " 1px solid #dddddd",
                                  textAlign: "left",
                                  padding: "8px",
                                }}
                              >
                                {e.price}
                              </td>
                              <td
                                style={{
                                  border: " 1px solid #dddddd",
                                  textAlign: "left",
                                  padding: "8px",
                                }}
                              >
                                <button
                                  style={{
                                    background: "none",
                                    color: "red",
                                    border: "none",
                                    cursor: "pointer",
                                  }}
                                  type="button"
                                  onClick={() => {
                                    deleteFromGenerators(e.id);
                                  }}
                                >
                                  <DeleteIcon></DeleteIcon>
                                  {/* <DeleteIcon></DeleteIcon> */}
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </TableContainer>
                </Grid>
              )}
            </Grid>

            <ButtonContainer style={{ margin: "20px" }}>
              <Button
                type="submit"
                variant="contained"
                disabled={all_required_data.length == 0}
              >
                Save
              </Button>
            </ButtonContainer>
          </form>
        </FormConatiner>
      </>

      {/* Hvacs */}
      <Modal
        open={openHvac}
        onClose={handleCloseHvac}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleHvac}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            align="center"
          >
            HVAC Form
            <hr></hr>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <form onSubmit={AddHvac}>
              <Grid container columns={18} spacing={2}>
                <>
                  <Grid item md={6} sm={9} xs={18} mb={3}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        HVAC Location
                      </InputLabel>
                      <Select
                        required={true}
                        id="demo-simple-select"
                        value={singleHvac?.name || ""}
                        label="HVAC Location"
                        onChange={(e) =>
                          setSignleHvac({
                            ...singleHvac,
                            name: e.target.value,
                          })
                        }
                      >
                        {hvacLocations?.map((data) => (
                          <MenuItem key={data.value} value={data.value}>
                            {data.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  {singleHvac?.name === "new_location_electrical" ||
                  singleHvac?.name === "new_location_plumbing" ? (
                    <>
                      <Grid item md={6} sm={9} xs={18} mb={3}>
                        <TextField
                          fullWidth
                          label="Number HVAC FT"
                          value={singleHvac?.number}
                          onChange={(e) =>
                            setSignleHvac({
                              ...singleHvac,
                              number: Number(e.target.value),
                            })
                          }
                        />
                      </Grid>
                    </>
                  ) : null}

                  {/* Existing Electrical Panel*/}
                  {/* <Grid item md={6} sm={9} xs={18} mb={3}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        HVAC Horse Power
                      </InputLabel>
                      <Select
                        id="demo-simple-select"
                        value={singleHvac?.hvac_horse_power}
                        label="  HVAC Horse Power"
                        required
                        onChange={(e) =>
                          setSignleHvac({
                            ...singleHvac,
                            hvac_horse_power: e.target.value,
                          })
                        }
                      >
                        {hvacHorsePower?.map((data) => (
                          <MenuItem key={data.value} value={data.value}>
                            {data.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid> */}
                  <Grid item md={6} sm={9} xs={18} mb={3}>
                    <TextField
                      fullWidth
                      label="price"
                      required
                      value={singleHvac?.price}
                      onChange={(e) =>
                        setSignleHvac({
                          ...singleHvac,
                          price: Number(e.target.value),
                        })
                      }
                    />
                  </Grid>
                  <Grid lg={18} md={18} style={{ textAlign: "center" }}>
                    <Button type="submit" style={{ color: "#4acfac" }}>
                      Add Hvac
                    </Button>
                  </Grid>
                </>
                {/* )} */}
              </Grid>
            </form>
          </Typography>
        </Box>
      </Modal>
      {/* Batteries */}
      <Modal
        open={openBattery}
        onClose={handleCloseBattery}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleHvac}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            align="center"
          >
            <Grid container columns={18}>
              <Grid item lg={18}>
                Add Battery
              </Grid>
            </Grid>
          </Typography>
          <form onSubmit={AddBattery}>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <Grid container columns={18} spacing={2}>
                {/* is_battery*/}
                {/* <Grid item md={6} sm={9} xs={18} mb={3}>
                  <InputLabel>Battery</InputLabel>
                  <span>No</span>
                  <Switch
                    checked={value.is_battery}
                    onChange={(e) =>
                      setValue({
                        ...value,
                        is_battery: e.target.checked,
                      })
                    }
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <span>Yes</span>
                </Grid> */}

                {/* number_of_solar_lip  */}
                {/* {value.is_battery && ( */}
                <>
                  <Grid item md={6} sm={9} xs={18} mb={3}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Battery Power
                      </InputLabel>
                      <Select
                        id="demo-simple-select"
                        value={singleBattery?.name}
                        label="battery_power"
                        required
                        onChange={(e) =>
                          setSignleBattery({
                            ...singleBattery,
                            name: e.target.value,
                          })
                        }
                      >
                        {betteryPower?.map((data) => (
                          <MenuItem key={data.value} value={data.value}>
                            {data.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={6} sm={9} xs={18} mb={3}>
                    <TextField
                      fullWidth
                      label="number_of_batteries"
                      required
                      value={singleBattery?.number}
                      onChange={(e) =>
                        setSignleBattery({
                          ...singleBattery,
                          number: Number(e.target.value),
                        })
                      }
                    />
                  </Grid>

                  <Grid item md={6} sm={9} xs={18} mb={3}>
                    <TextField
                      fullWidth
                      label="price"
                      required
                      value={singleBattery?.price}
                      onChange={(e) =>
                        setSignleBattery({
                          ...singleBattery,
                          price: Number(e.target.value),
                        })
                      }
                    />
                  </Grid>
                  <Grid lg={18} md={18} style={{ textAlign: "center" }}>
                    <Button
                      style={{ color: "#4acfac" }}
                      type="submit"

                      // onClick={async () => {}
                    >
                      Add Battery
                    </Button>
                  </Grid>
                </>
                {/* )} */}
              </Grid>
            </Typography>
          </form>
        </Box>
      </Modal>
      {/* Generators */}
      <Modal
        open={openGenerator}
        onClose={handleCloseGenerator}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleHvac}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            align="center"
          >
            <Grid container columns={18}>
              <Grid item lg={18}>
                Add Generator
              </Grid>
            </Grid>
          </Typography>
          <form onSubmit={AddGenerator}>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <Grid container columns={18} spacing={2}>
                <>
                  <Grid item md={6} sm={9} xs={18} mb={3}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Generator Power
                      </InputLabel>
                      <Select
                        id="demo-simple-select"
                        value={singleGenerator?.name}
                        label="Generator Power"
                        required
                        onChange={(e) =>
                          setSignleGenerator({
                            ...singleGenerator,
                            name: e.target.value,
                          })
                        }
                      >
                        {generatorPower?.map((data) => (
                          <MenuItem key={data.value} value={data.value}>
                            {data.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={6} sm={9} xs={18} mb={3}>
                    <TextField
                      fullWidth
                      label="Number of Generators"
                      required
                      value={singleGenerator?.number}
                      onChange={(e) =>
                        setSignleGenerator({
                          ...singleGenerator,
                          number: Number(e.target.value),
                        })
                      }
                    />
                  </Grid>
                  <Grid item md={6} sm={9} xs={18} mb={3}>
                    <TextField
                      fullWidth
                      label="price"
                      required
                      value={singleGenerator?.price}
                      onChange={(e) =>
                        setSignleGenerator({
                          ...singleGenerator,
                          price: Number(e.target.value),
                        })
                      }
                    />
                  </Grid>
                  <Grid lg={18} md={18} style={{ textAlign: "center" }}>
                    <Button
                      style={{ color: "#4acfac" }}
                      type="submit"
                      // onClick={async () => {}
                    >
                      Add Generator
                    </Button>
                  </Grid>
                </>
                {/* )} */}
              </Grid>
            </Typography>
          </form>
        </Box>
      </Modal>
    </>
  );
};

const FormConatiner = styleds.div`
  position: relative;
  .hvac {
    position: relative;
  }
  .btn {
    /* position: absolute; */
    /* left: 0; */
    /* top: 1.5rem; */
    font-size: 0.8rem;
    color: black;
    background-color: #f0f0f0;
  }
`;

const ButtonContainer = styleds.div`
  text-align: center;
  button {
    border-radius: 2rem;
    padding: 0.7rem 3rem;
    background-size: 400% 100%;
    transition: all 0.4s ease-in-out;
    background-image: linear-gradient(
      132.6deg,
      rgba(71, 139, 214, 1) 23.3%,
      rgba(37, 216, 211, 1) 84.7%
    );
    &:hover {
      background-position: 100% 0;
    }
  }
`;

export default AddonsForm;
