import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import SurveyImagesService from "./SiteSurveyImagesService";

const initialState = {
  SurveyImages: {},
  isError: false,
  isSuccess: false,
  isLodaing: false,
  massage: "",
};
export const GET_survey_images_Action = createAsyncThunk(
  "SurveyImages/get",
  async (data, thunkAPI) => {
    try {
      return await SurveyImagesService.site_survey_images_Action(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const survey_imagesSlice = createSlice({
  name: "SurveyImages",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLodaing = false;
      state.isDeleteLodaing = false;
      state.massage = "";
    },
  },
  extraReducers: {
    [GET_survey_images_Action.pending]: (state) => {
      state.isLodaing = true;
    },
    [GET_survey_images_Action.fulfilled]: (state, action) => {
      state.isLodaing = false;
      state.SurveyImages = action.payload;
      state.isSuccess = true;
    },
    [GET_survey_images_Action.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.SurveyImages = null;
      state.massage = action.payload;
    },
  },
});

export const { reset } = survey_imagesSlice.actions;
export default survey_imagesSlice.reducer;
