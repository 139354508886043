import axios from "axios";
import { work_orders_list, get_site_servey_data } from "../../../Api/Api";
const getWorkOrdrs_listAction = async (id) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${work_orders_list}/${id}`,
    headers: headers,
    // data: data,
  });
  return response.data;
};

const gesiteSurvey_listAction = async (id) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${get_site_servey_data}/${id}`,
    headers: headers,
    // data: data,
  });
  return response.data;
};
const workOrdersService = {
  getWorkOrdrs_listAction,
  gesiteSurvey_listAction,
};
export default workOrdersService;
