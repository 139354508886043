import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Grid } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { PrimaryButton } from "../../../Layouts/ButtonsControlled";
import { ModalContent } from "../../Modals/PmAssignModal/PmAssignModalStyle";
import { Construction } from "@mui/icons-material";
import { store_business_license  , edit__business_license_data ,  get_business_license,} from "../../../Redux/Slices/businessLicenseSlice/businessLicenseSlice";
import { getTicketStatus } from "../../../Redux/Slices/TicketStatus/TicketStatusSlice"
import { useDispatch, useSelector } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { useParams } from "react-router-dom";
import MuiSelectField from "../../../Common/MuiSelectField/MuiSelectField";
import MuiTextField from "../../../Common/muiTextField/MuiTextField"
const BusinessLicenseModal = ({
  handleSubmit,
  openBusinessLicenseModal,
  handleCloseBusinessLicenseModal,
  setOpenBusinessLicenseModal,
  ticket_status,
  ticket_statuss,
  set_open_edit_business_license_modal_,
  business_data

}) => {
  const [business_license_status, setBusiness_license_status] = useState();
  const dispatch = useDispatch();
  const { lead_id } = useParams();
  console.log(ticket_status?.business_license  , "ticket_status")
  console.log(ticket_status?.business_license , "ticket_statuss")
 console.log(business_data , "business_data")
  const submit = async (values) => {
    console.log(values, "values")
      const id = {
       ticket_id: lead_id,
       id:  ticket_status?.business_license == "5" || ticket_statuss == "5"  && business_data?.data?.id
    };


  ticket_status?.business_license == "5" || ticket_statuss == "5" ?
  (await dispatch(edit__business_license_data({...values,...id}))) &&
  (await set_open_edit_business_license_modal_(false))
  (await dispatch(get_business_license(lead_id)))
  : 
  (await dispatch(store_business_license({...values , ...id}))) &&
  (await setOpenBusinessLicenseModal(false));
  await dispatch(getTicketStatus(lead_id));

  }

  return (
    <Dialog
      maxWidth={"md"}
      open={openBusinessLicenseModal}
      onClose={handleCloseBusinessLicenseModal}
    >
      <DialogContent>
        <ModalContent>
          <form onSubmit={handleSubmit((values) => submit(values))}>
            <Grid>
              <FormControl variant="outlined" style={{ width: "300px", marginTop: "15px" }}>
                <Field
                  required
                  name="status"
                  component={MuiSelectField}
                  label="status"
                  style={{ width: "300px", marginTop: "15px" }}
                >
                  <MenuItem value="portal">portal </MenuItem>
                  <MenuItem value="in_person">in person</MenuItem>
                  <MenuItem value="have_license">have license</MenuItem>
                  <MenuItem value="no_have_license">no have license</MenuItem>
                </Field>
              </FormControl>
            </Grid>
            <Grid>
              <FormControl variant="outlined" style={{ width: "300px", marginTop: "15px" }}>
                <Field
                  required
                  type="number"
                  name="cost"
                  component={MuiTextField}
                  label="cost"
                />
              </FormControl>
            </Grid>
            <DialogActions>
              <PrimaryButton
              type="submit"
                fullWidth
                style={{
                  padding: "0",
                  width: "85%",
                  margin: "15px auto",
                  height: "42px",
                }}
                className="saveButton"
              >
              {(ticket_status?.business_license == "5" || ticket_statuss == "5") ? "update" : "save" }
              </PrimaryButton>
            </DialogActions>
          </form>
        </ModalContent>
      </DialogContent>




    </Dialog>
  );
};


export default reduxForm({
  form: "BusinessLicenseForm",
})(BusinessLicenseModal);
