import React, { useState, useEffect } from "react";
import { reduxForm, Field } from "redux-form";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Grid, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { PrimaryButton } from "../../../Layouts/ButtonsControlled";
import { ModalContent } from "../../Modals/PmAssignModal/PmAssignModalStyle";
import { getTicketStatus } from "../../../Redux/Slices/TicketStatus/TicketStatusSlice";
import { get_business_license } from "../../../Redux/Slices/businessLicenseSlice/businessLicenseSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import { store_call_suupliers } from "../../../Redux/Slices/CallSupplierSlice/CallSupplierSlice";
import { update_call_suupliers } from "../../../Redux/Slices/CallSupplierSlice/CallSupplierSlice";
import MuiSelectField from "../../../Common/MuiSelectField/MuiSelectField";
import MuiTextField from "../../../Common/muiTextField/MuiTextField";
const SiteSurveyModal = ({
  handleSubmit,
  handleopenModal,
  handleCloseModal,
  set_open_site_survey_Modal,
  call_suppliers_status,
  call_supplier_details,
  card_status,
  setOpenEditModal,
  open_site_survey_Modal,
}) => {
  const dispatch = useDispatch();

  const { lead_id } = useParams();
  console.log(
    call_supplier_details,
    call_suppliers_status,
    card_status,
    "call_supplier_details 565"
  );

  const submit = async (values) => {
    const reqData = {
      ticket_id: +lead_id,
      id: call_supplier_details?.id,
    };
    call_suppliers_status == "1" || card_status == "1"
      ? (await dispatch(store_call_suupliers({ ...values, ...reqData }))) &&
        (await set_open_site_survey_Modal(false))
      : (await dispatch(update_call_suupliers({ ...values, ...reqData }))) &&
        (await setOpenEditModal(false));
    await dispatch(getTicketStatus(lead_id));
  };

  return (
    <Dialog
      maxWidth={"md"}
      fullWidth
      open={handleopenModal}
      onClose={handleCloseModal}
    >
      <DialogContent>
        <ModalContent>
          <Box>
            <form onSubmit={handleSubmit((values) => submit(values))}>
              <>
                <Grid
                  container
                  columns={12}
                  spacing={5}
                  justifyContent="center"
                  mb={3}
                >
                  <Grid item xs={12} sm={6} lg={3}>
                    <Field
                      required
                      type="date"
                      name="survey_date"
                      component={MuiTextField}
                      label="survey date"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <Field
                      required
                      name="is_any_animal"
                      component={MuiSelectField}
                      label="is any animal"
                    >
                      <MenuItem value={0} label="option 1">
                        NO
                      </MenuItem>
                      <MenuItem value={1} label="option 2">
                        Yes
                      </MenuItem>
                    </Field>
                  </Grid>

                  <Grid item xs={12} sm={12} lg={3}>
                    <Field
                      required
                      name="want_be_on_site"
                      component={MuiSelectField}
                      label="want be on site"
                    >
                      <MenuItem value={0} label="option 1">
                        NO
                      </MenuItem>
                      <MenuItem value={1} label="option 2">
                        Yes
                      </MenuItem>
                    </Field>
                  </Grid>
                </Grid>

                <Grid
                  container
                  columns={12}
                  spacing={5}
                  justifyContent="center"
                  mb={3}
                >
                  <Grid item xs={12} sm={6} lg={3}>
                    <Field
                      required
                      type="number"
                      name="system_size"
                      component={MuiTextField}
                      label="system size"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} lg={3}>
                    <Field
                      required
                      type="number"
                      name="number_of_panels"
                      component={MuiTextField}
                      label="number of panels"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} lg={3}>
                    <Field
                      required
                      name="type_of_inverter"
                      component={MuiTextField}
                      label="type of inverter"
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  columns={12}
                  spacing={5}
                  justifyContent="center"
                  mb={3}
                >
                  <Grid item xs={12} sm={12} lg={9}>
                    <Field
                      required
                      multiline
                      rows={2}
                      name="adders"
                      component={MuiTextField}
                      label="adders"
                    />
                  </Grid>
                </Grid>
              </>
              <DialogActions>
                <PrimaryButton
                  fullWidth
                  type="submit"
                  style={{
                    padding: "0",
                    width: "85%",
                    margin: "15px auto",
                    height: "42px",
                  }}
                  className="saveButton"
                >
                  {call_suppliers_status == "5" ? "Update" : "save"}
                </PrimaryButton>
              </DialogActions>
            </form>
          </Box>
        </ModalContent>
      </DialogContent>
    </Dialog>
  );
};

export default reduxForm({
  form: "SiteSurveyForm",
})(SiteSurveyModal);
