import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import CardStepsService from "./CardStepsService";
import { toast } from "react-toastify";

const initialState = {
  cardStatus: {},
  isError: false,
  isSuccess: false,
  isLodaing: false,
  massage: "",
};

export const apply_pto = createAsyncThunk(
  "apply_pto/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.apply_pto_Action(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const get_pto_data = createAsyncThunk(
  "get_pto/get",
  async (id, thunkAPI) => {
    try {
      return await CardStepsService.get_PTO_data(id);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);
export const edit__PTO_data = createAsyncThunk(
  "edit__PTO_data/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.edit__PTO_data(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const delete_pto_file = createAsyncThunk(
  "delete_pto/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.delete_pto_file_Action(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const create_NTP = createAsyncThunk(
  "create_NTP/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.create_NTP_Action(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const get_NTP_data = createAsyncThunk(
  "get_NTP/get",
  async (id, thunkAPI) => {
    try {
      return await CardStepsService.get_NTP_data(id);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);
export const edit__NTP_data = createAsyncThunk(
  "edit__PTO_data/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.edit__NTP_data(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const delete_NTP_file = createAsyncThunk(
  "delete_ntp/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.delete_NTP_file_Action(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const apply_meter_spot = createAsyncThunk(
  "apply_meter_spot/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.apply_meter_spot_Action(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const get_meter_spot_data = createAsyncThunk(
  "meter_spot/get",
  async (id, thunkAPI) => {
    try {
      return await CardStepsService.get_meter_spot_data(id);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);
export const edit_meter_spot_data = createAsyncThunk(
  "edit_meter_spot_data/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.edit__meter_spot_data(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const delete_meter_spot_file = createAsyncThunk(
  "delete_meter_spot/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.delete_meter_spot_file_Action(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const create_quotes_for_products = createAsyncThunk(
  "quotes_for_products/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.create_quotes_for_products_Action(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const get_quotes_for_products_data = createAsyncThunk(
  "quotes_for_products/get",
  async (id, thunkAPI) => {
    try {
      return await CardStepsService.get_quotes_for_products_data(id);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);
export const edit__quotes_for_products_data = createAsyncThunk(
  "quotes_for_products_data/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.edit__quotes_for_products_data(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const delete_quotes_for_products_Action = createAsyncThunk(
  "delete_quotes_for_products/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.delete_quotes_for_products_Action(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const create_product_orders_Action = createAsyncThunk(
  "product_orders/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.create_product_orders_Action(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const get_product_orders_data = createAsyncThunk(
  "product_orders/get",
  async (id, thunkAPI) => {
    try {
      return await CardStepsService.get_product_orders_data(id);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);
export const edit__product_orders_data = createAsyncThunk(
  "product_orders/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.edit__product_orders_data(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const delete_product_orders_Action = createAsyncThunk(
  "product_orders/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.delete_product_orders_Action(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const create_permit_to_finance_Action = createAsyncThunk(
  "permit-to-finance/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.create_permit_to_finance_Action(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const get_permit_to_finance_data = createAsyncThunk(
  "permit_to_finance/get",
  async (id, thunkAPI) => {
    try {
      return await CardStepsService.get_permit_to_finance_data(id);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);
export const edit__permit_to_finance_data = createAsyncThunk(
  "permit_to_finance/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.edit__permit_to_finance_data(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const delete_permit_to_finance_Action = createAsyncThunk(
  "permit_to_finance/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.delete_permit_to_finance_Action(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const create_order_place_cards_Action = createAsyncThunk(
  "order_place_cards/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.create_order_place_cards_Action(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const get_order_place_cards_data = createAsyncThunk(
  "order_place_cards/get",
  async (id, thunkAPI) => {
    try {
      return await CardStepsService.get_order_place_cards_data(id);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);
export const edit__order_place_cards_data = createAsyncThunk(
  "order_place_cards/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.edit__order_place_cards_data(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const delete_order_place_cards_Action = createAsyncThunk(
  "order_place_cards/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.delete_order_place_cards_Action(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const create_coordinate_product_delivery_Action = createAsyncThunk(
  "coordinate_product_delivery/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.create_coordinate_product_delivery_Action(
        data
      );
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const get_coordinate_product_delivery_data = createAsyncThunk(
  "coordinate_product_delivery/get",
  async (id, thunkAPI) => {
    try {
      return await CardStepsService.get_coordinate_product_delivery_data(id);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);
export const edit__coordinate_product_delivery_data = createAsyncThunk(
  "coordinate_product_delivery/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.edit__coordinate_product_delivery_data(
        data
      );
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const delete_coordinate_product_delivery_Action = createAsyncThunk(
  "coordinate_product_delivery/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.delete_coordinate_product_delivery_Action(
        data
      );
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const create_coordinate_installations_Action = createAsyncThunk(
  "coordinate_installations/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.create_coordinate_installations_Action(
        data
      );
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const get_coordinate_installations_data = createAsyncThunk(
  "coordinate_installations/get",
  async (id, thunkAPI) => {
    try {
      return await CardStepsService.get_coordinate_installations_data(id);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);
export const edit_coordinate_installations_data = createAsyncThunk(
  "coordinate_installations/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.edit_coordinate_installations_data(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const delete_coordinate_installations_Action = createAsyncThunk(
  "coordinate_installations/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.delete_coordinate_installations_Action(
        data
      );
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const create_schedule_inspections_cards_Action = createAsyncThunk(
  "schedule_inspections/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.create_schedule_inspections_cards_Action(
        data
      );
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const get_schedule_inspections_data = createAsyncThunk(
  "schedule_inspections/get",
  async (id, thunkAPI) => {
    try {
      return await CardStepsService.get_schedule_inspections_data(id);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);
export const edit__schedule_inspections_data = createAsyncThunk(
  "schedule_inspections/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.edit__schedule_inspections_data(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const delete_schedule_inspections_files_Action = createAsyncThunk(
  "schedule_inspections/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.delete_schedule_inspections_files_Action(
        data
      );
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const create_installer_invoices_cards_Action = createAsyncThunk(
  "installer_invoices/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.create_installer_invoices_cards_Action(
        data
      );
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const get_installer_invoices_data = createAsyncThunk(
  "installer_invoices/get",
  async (id, thunkAPI) => {
    try {
      return await CardStepsService.get_installer_invoices_data(id);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);
export const edit__installer_invoices_data = createAsyncThunk(
  "installer_invoices/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.edit__installer_invoices_data(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const delete_installer_invoices_files_Action = createAsyncThunk(
  "installer_invoices/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.delete_installer_invoices_files_Action(
        data
      );
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const create_upload_to_pto_cards_Action = createAsyncThunk(
  "upload_to_pto/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.create_upload_to_pto_cards_Action(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const get_upload_to_pto_data = createAsyncThunk(
  "upload_to_pto/get",
  async (id, thunkAPI) => {
    try {
      return await CardStepsService.get_upload_to_pto_data(id);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);
export const edit__upload_to_pto_data = createAsyncThunk(
  "upload_to_pto/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.edit__upload_to_pto_data(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const delete__upload_to_pto_files_Action = createAsyncThunk(
  "upload_to_pto_delete/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.delete__upload_to_pto_files_Action(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const create_send_full_details_to_client_Action = createAsyncThunk(
  "send_full_details_to_client/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.create_send_full_details_to_client_Action(
        data
      );
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const get_send_full_details_to_client_data = createAsyncThunk(
  "send_full_details_to_client/get",
  async (id, thunkAPI) => {
    try {
      return await CardStepsService.get_send_full_details_to_client_data(id);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);
export const edit__send_full_details_to_client_data = createAsyncThunk(
  "send_full_details_to_client/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.edit__send_full_details_to_client_data(
        data
      );
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const delete_send_full_details_to_client_filess = createAsyncThunk(
  "send_full_details_to_client/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.delete_send_full_details_to_client_filess(
        data
      );
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const create_review_profit_loss_statement_Action = createAsyncThunk(
  "review_profit_loss_statement/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.create_review_profit_loss_statement_Action(
        data
      );
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const get_review_profit_loss_statement_data = createAsyncThunk(
  "review_profit_loss_statement/get",
  async (id, thunkAPI) => {
    try {
      return await CardStepsService.get_review_profit_loss_statement_data(id);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);
export const edit__review_profit_loss_statement_data = createAsyncThunk(
  "review_profit_loss_statement/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.edit__review_profit_loss_statement_data(
        data
      );
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const delete_review_profit_loss_statement_filess = createAsyncThunk(
  "review_profit_loss_statement/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.delete_review_profit_loss_statement_filess(
        data
      );
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const create_closure_report_Action = createAsyncThunk(
  "closure_report/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.create_closure_report_Action(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
        console.log(error?.response?.data?.message , "error?.response")
        error?.response?.data?.message?.map((err) =>
        toast.error(err.value, {
          position: "top-right",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      );
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const get_closure_report_data = createAsyncThunk(
  "closure_report/get",
  async (id, thunkAPI) => {
    try {
      return await CardStepsService.get_closure_report_data(id);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);
export const edit__closure_report_data = createAsyncThunk(
  "closure_report/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.edit__closure_report_data(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const delete_closure_report__filess = createAsyncThunk(
  "closure_report/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.delete_closure_report__filess(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const create_installation_payment_collection_Action = createAsyncThunk(
  "installation_payment_collection/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.create_installation_payment_collection_Action(
        data
      );
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const get_installation_payment_collection_data = createAsyncThunk(
  "installation_payment_collection/get",
  async (id, thunkAPI) => {
    try {
      return await CardStepsService.get_installation_payment_collection_data(
        id
      );
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);
export const edit__installation_payment_collection_data = createAsyncThunk(
  "installation_payment_collection/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.edit__installation_payment_collection_data(
        data
      );
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const delete_installation_payment_collection__filess = createAsyncThunk(
  "installation_payment_collection/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.delete_installation_payment_collection__filess(
        data
      );
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const create_installer_commissions_Action = createAsyncThunk(
  "installer_commissions/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.create_installer_commissions_Action(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const get_installer_commissions_data = createAsyncThunk(
  "installer_commissions/get",
  async (id, thunkAPI) => {
    try {
      return await CardStepsService.get_installer_commissions_data(id);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);
export const edit__installer_commissions_data = createAsyncThunk(
  "installer_commissions/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.edit__installer_commissions_data(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const delete_installer_commissions_filess = createAsyncThunk(
  "installer_commissions/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.delete_installer_commissions_filess(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const create_installation_pics_Action = createAsyncThunk(
  "installation_pics/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.create_installation_pics_Action(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const get_installation_pics_data = createAsyncThunk(
  "installation_pics/get",
  async (id, thunkAPI) => {
    try {
      return await CardStepsService.get_installation_pics_data(id);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);
export const edit__installation_pics_data = createAsyncThunk(
  "installation_pics/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.edit__installation_pics_data(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const delete_installation_pics_filess = createAsyncThunk(
  "installation_pics/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.delete_installation_pics_filess(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const create_confirm_inspection_Action = createAsyncThunk(
  "confirm_inspection/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.create_confirm_inspection_Action(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const get_confirm_inspection_data = createAsyncThunk(
  "confirm_inspection/get",
  async (id, thunkAPI) => {
    try {
      return await CardStepsService.get_confirm_inspection_data(id);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);
export const edit__confirm_inspection_data = createAsyncThunk(
  "confirm_inspection/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.edit__confirm_inspection_data(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const delete_confirm_inspection_filess = createAsyncThunk(
  "confirm_inspection/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.delete_confirm_inspection_filess(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);
export const apply_permits = createAsyncThunk(
  "apply_permits/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.apply_permits_Action(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const get_apply_permits_data = createAsyncThunk(
  "apply_permits/get",
  async (id, thunkAPI) => {
    try {
      return await CardStepsService.get_apply_permits_data(id);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);
export const edit__apply_permits_data = createAsyncThunk(
  "apply_permits/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.edit__apply_permits_data(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const delete_apply_permits_filess = createAsyncThunk(
  "apply_permits/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.delete_apply_permits_filess(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const create_end_project_call_Action = createAsyncThunk(
  "end_project_call/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.create_end_project_call_Action(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const get_end_project_call_data = createAsyncThunk(
  "end_project_call/get",
  async (id, thunkAPI) => {
    try {
      return await CardStepsService.get_end_project_call_data(id);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);
export const edit__end_project_call_data = createAsyncThunk(
  "end_project_call/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.edit__end_project_call_data(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const delete_end_project_call_filess = createAsyncThunk(
  "end_project_call/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.delete_end_project_call_filess(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const create_upload_PTO_to_finance_Action = createAsyncThunk(
  "upload_PTO_to_finance/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.create_upload_PTO_to_finance_Action(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const get_upload_PTO_to_finance_data = createAsyncThunk(
  "upload_PTO_to_finance/get",
  async (id, thunkAPI) => {
    try {
      return await CardStepsService.get_upload_PTO_to_finance_data(id);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);
export const edit__upload_PTO_to_finance_data = createAsyncThunk(
  "upload_PTO_to_finance/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.edit__upload_PTO_to_finance_data(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const delete_upload_PTO_to_finance_filess = createAsyncThunk(
  "upload_PTO_to_finance/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.delete_upload_PTO_to_finance_filess(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const create_final_job_card_Action = createAsyncThunk(
  "final_job_card/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.create_final_job_card_Action(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const get_final_job_card_data = createAsyncThunk(
  "final_job_card/get",
  async (id, thunkAPI) => {
    try {
      return await CardStepsService.get_final_job_card_data(id);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);
export const edit__final_job_card_data = createAsyncThunk(
  "final_job_card/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.edit__final_job_card_data(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const delete_final_job_card_filess = createAsyncThunk(
  "final_job_card/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.delete_final_job_card_filess(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const create_schedule_with_city_office_Action = createAsyncThunk(
  "schedule_with_city_office/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.create_schedule_with_city_office_Action(
        data
      );
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const get_schedule_with_city_office_data = createAsyncThunk(
  "schedule_with_city_office/get",
  async (id, thunkAPI) => {
    try {
      return await CardStepsService.get_schedule_with_city_office_data(id);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);
export const edit__schedule_with_city_office_data = createAsyncThunk(
  "schedule_with_city_office/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.edit__schedule_with_city_office_data(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const delete_schedule_with_city_office_filess = createAsyncThunk(
  "schedule_with_city_office/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.delete_schedule_with_city_office_filess(
        data
      );
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const create_inform_inspection_result_Action = createAsyncThunk(
  "inform_inspection_result/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.create_inform_inspection_result_Action(
        data
      );
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const get_inform_inspection_result_data = createAsyncThunk(
  "inform_inspection_result/get",
  async (id, thunkAPI) => {
    try {
      return await CardStepsService.get_inform_inspection_result_data(id);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);
export const edit__inform_inspection_result_data = createAsyncThunk(
  "inform_inspection_result/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.edit__inform_inspection_result_data(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const delete_inspection_result_filess = createAsyncThunk(
  "inspection_result/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.delete_inspection_result_filess(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const create_monitoring_optimizers_Action = createAsyncThunk(
  "monitoring_optimizers/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.create_monitoring_optimizers_Action(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const get_monitoring_optimizers_data = createAsyncThunk(
  "monitoring_optimizers/get",
  async (id, thunkAPI) => {
    try {
      return await CardStepsService.get_monitoring_optimizers_data(id);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);
export const edit__monitoring_optimizers_data = createAsyncThunk(
  "monitoring_optimizers/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.edit__monitoring_optimizers_data(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const delete_monitoring_optimizers_filess = createAsyncThunk(
  "inspection_result/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.delete_monitoring_optimizers_filess(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const create_m1_collection_Action = createAsyncThunk(
  "m1_collection/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.create_m1_collection_Action(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const get_m1_collection_data = createAsyncThunk(
  "m1_collection/get",
  async (id, thunkAPI) => {
    try {
      return await CardStepsService.get_m1_collection_data(id);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);
export const edit__m1_collection_data = createAsyncThunk(
  "m1_collection/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.edit__m1_collection_data(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const delete_m1_collection_files = createAsyncThunk(
  "m1_collection/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.delete_m1_collection_files(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);


export const create_m2_collection_Action = createAsyncThunk(
  "m2_collection/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.create_m2_collection_Action(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const get_m2_collection_data = createAsyncThunk(
  "m2_collection/get",
  async (id, thunkAPI) => {
    try {
      return await CardStepsService.get_m2_collection_data(id);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);
export const edit__m2_collection_data = createAsyncThunk(
  "m2_collection/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.edit__m2_collection_data(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const delete_m2_collection_files = createAsyncThunk(
  "m2_collection/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.delete_m2_collection_files(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const create_final_inspection_Action = createAsyncThunk(
  "final_inspection/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.create_final_inspection_Action(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);
export const get_final_inspection_data = createAsyncThunk(
  "final_inspection/get",
  async (id, thunkAPI) => {
    try {
      return await CardStepsService.get_final_inspection_data(id);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);
export const edit__final_inspection_data = createAsyncThunk(
  "final_inspection/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.edit__final_inspection_data(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const delete_final_inspection_files = createAsyncThunk(
  "final_inspection/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.delete_final_inspection_filess(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const call_designer = createAsyncThunk(
  "call_designer/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.create_call_designer(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const get_call_designer_data = createAsyncThunk(
  "call_designer/get",
  async (id, thunkAPI) => {
    try {
      return await CardStepsService.get_call_designer_data(id);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);
export const edit__call_designer_data = createAsyncThunk(
  "edit_call_designer/post",
  async (data, thunkAPI) => {
    try {
      return await CardStepsService.edit_call_designer_data(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);
export const cardStatusSlice = createSlice({
  name: "cardStatus",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLodaing = false;
      state.massage = "";
    },
  },
  extraReducers: {
    //post apply_pto  Action
    [apply_pto.pending]: (state) => {
      state.isLodaing = true;
    },
    [apply_pto.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [apply_pto.rejected]: (state, action) => {
      console.log(state, "state in rejected");
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
    //get apply_pto  Action
    [get_pto_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [get_pto_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [get_pto_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.cardStatus = null;
      state.massage = action.payload;
    },

    //edit apply_pto  Action
    [edit__PTO_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [edit__PTO_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [edit__PTO_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
    //delete pto files
    [delete_pto_file.pending]: (state) => {
      state.isLodaing = true;
    },
    [delete_pto_file.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [delete_pto_file.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
    //post create_NTP  Action
    [create_NTP.pending]: (state) => {
      state.isLodaing = true;
    },
    [create_NTP.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [create_NTP.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
    //get get_NTP_data  Action
    [get_NTP_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [get_NTP_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [get_NTP_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.cardStatus = null;
      state.massage = action.payload;
    },

    //edit__NTP_data  Action
    [edit__NTP_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [edit__NTP_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [edit__NTP_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
    //delete delete_NTP_file
    [delete_NTP_file.pending]: (state) => {
      state.isLodaing = true;
    },
    [delete_NTP_file.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [delete_NTP_file.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
    //post apply_meter_spot  Action
    [apply_meter_spot.pending]: (state) => {
      state.isLodaing = true;
    },
    [apply_meter_spot.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [apply_meter_spot.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
    //get get_meter_spot_data  Action
    [get_meter_spot_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [get_meter_spot_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [get_meter_spot_data.rejected]: (state, action) => {
      console.log(state.cardStatus, "state in rejectedddddd");
      console.log(action, "action . payloaddddd");
      state.isLodaing = false;
      state.isError = true;
      state.cardStatus = null;
      state.massage = action.payload;
    },

    //edit__meter_spot_data  Action
    [edit_meter_spot_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [edit_meter_spot_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [edit_meter_spot_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
    //delete meter_spot_file
    [delete_meter_spot_file.pending]: (state) => {
      state.isLodaing = true;
    },
    [delete_meter_spot_file.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [delete_meter_spot_file.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },

    //post quotes_for_products  Action
    [create_quotes_for_products.pending]: (state) => {
      state.isLodaing = true;
    },
    [create_quotes_for_products.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [create_quotes_for_products.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
    //get quotes_for_products_data  Action
    [get_quotes_for_products_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [get_quotes_for_products_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [get_quotes_for_products_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.cardStatus = null;
      state.massage = action.payload;
    },

    //edit__quotes_for_products_data  Action
    [edit__quotes_for_products_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [edit__quotes_for_products_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [edit__quotes_for_products_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
    //delete quotes_for_products_file
    [delete_quotes_for_products_Action.pending]: (state) => {
      state.isLodaing = true;
    },
    [delete_quotes_for_products_Action.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [delete_quotes_for_products_Action.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },

    //post product_orders  Action
    [create_product_orders_Action.pending]: (state) => {
      state.isLodaing = true;
    },
    [create_product_orders_Action.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [create_product_orders_Action.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
    //get product_orders_data  Action
    [get_product_orders_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [get_product_orders_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [get_product_orders_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.cardStatus = null;
      state.massage = action.payload;
    },

    //edit__product_orders_data  Action
    [edit__product_orders_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [edit__product_orders_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [edit__product_orders_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
    //delete quotes_for_products_file
    [delete_product_orders_Action.pending]: (state) => {
      state.isLodaing = true;
    },
    [delete_product_orders_Action.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [delete_product_orders_Action.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },

    //post create_permit_to_finance_Action
    [create_permit_to_finance_Action.pending]: (state) => {
      state.isLodaing = true;
    },
    [create_permit_to_finance_Action.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [create_permit_to_finance_Action.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
    //get_permit_to_finance_data
    [get_permit_to_finance_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [get_permit_to_finance_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [get_permit_to_finance_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.cardStatus = null;
      state.massage = action.payload;
    },

    //edit__permit_to_finance_data
    [edit__permit_to_finance_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [edit__permit_to_finance_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [edit__permit_to_finance_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
    //delete_permit_to_finance_Action _file
    [delete_permit_to_finance_Action.pending]: (state) => {
      state.isLodaing = true;
    },
    [delete_permit_to_finance_Action.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [delete_permit_to_finance_Action.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },

    //create_order_place_cards_Action
    [create_order_place_cards_Action.pending]: (state) => {
      state.isLodaing = true;
    },
    [create_order_place_cards_Action.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [create_order_place_cards_Action.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
    //get_order_place_cards_data
    [get_order_place_cards_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [get_order_place_cards_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [get_order_place_cards_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.cardStatus = null;
      state.massage = action.payload;
    },

    //edit__order_place_cards_data
    [edit__order_place_cards_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [edit__order_place_cards_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [edit__order_place_cards_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
    //delete_order_place_cards_Action _file
    [delete_order_place_cards_Action.pending]: (state) => {
      state.isLodaing = true;
    },
    [delete_order_place_cards_Action.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [delete_order_place_cards_Action.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },

    //create_coordinate_product_delivery_Action
    [create_coordinate_product_delivery_Action.pending]: (state) => {
      state.isLodaing = true;
    },
    [create_coordinate_product_delivery_Action.fulfilled]: (
      state,
      { payload }
    ) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [create_coordinate_product_delivery_Action.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
    //get_coordinate_product_delivery_data
    [get_coordinate_product_delivery_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [get_coordinate_product_delivery_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [get_coordinate_product_delivery_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.cardStatus = null;
      state.massage = action.payload;
    },

    //edit__coordinate_product_delivery_data
    [edit__coordinate_product_delivery_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [edit__coordinate_product_delivery_data.fulfilled]: (
      state,
      { payload }
    ) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [edit__coordinate_product_delivery_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
    //delete_coordinate_product_delivery_Action _file
    [delete_coordinate_product_delivery_Action.pending]: (state) => {
      state.isLodaing = true;
    },
    [delete_coordinate_product_delivery_Action.fulfilled]: (
      state,
      { payload }
    ) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [delete_coordinate_product_delivery_Action.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },

    //create_create_coordinate_installations_Action
    [create_coordinate_installations_Action.pending]: (state) => {
      state.isLodaing = true;
    },
    [create_coordinate_installations_Action.fulfilled]: (
      state,
      { payload }
    ) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [create_coordinate_installations_Action.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
    //get_coordinate_installations_data
    [get_coordinate_installations_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [get_coordinate_installations_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [get_coordinate_installations_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.cardStatus = null;
      state.massage = action.payload;
    },

    //edit_coordinate_installations_data
    [edit_coordinate_installations_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [edit_coordinate_installations_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [edit_coordinate_installations_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
    //delete_coordinate_installations_files_Action
    [delete_coordinate_installations_Action.pending]: (state) => {
      state.isLodaing = true;
    },
    [delete_coordinate_installations_Action.fulfilled]: (
      state,
      { payload }
    ) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [delete_coordinate_installations_Action.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },

    //create_schedule_inspections_cards_Action
    [create_schedule_inspections_cards_Action.pending]: (state) => {
      state.isLodaing = true;
    },
    [create_schedule_inspections_cards_Action.fulfilled]: (
      state,
      { payload }
    ) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [create_schedule_inspections_cards_Action.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
    //get_schedule_inspections_data
    [get_schedule_inspections_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [get_schedule_inspections_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [get_schedule_inspections_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.cardStatus = null;
      state.massage = action.payload;
    },

    //edit__schedule_inspections_data
    [edit__schedule_inspections_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [edit__schedule_inspections_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [edit__schedule_inspections_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
    //delete_schedule_inspections_files_Action
    [delete_schedule_inspections_files_Action.pending]: (state) => {
      state.isLodaing = true;
    },
    [delete_schedule_inspections_files_Action.fulfilled]: (
      state,
      { payload }
    ) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [delete_schedule_inspections_files_Action.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },

    //create_installer_invoices_cards_Action
    [create_installer_invoices_cards_Action.pending]: (state) => {
      state.isLodaing = true;
    },
    [create_installer_invoices_cards_Action.fulfilled]: (
      state,
      { payload }
    ) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [create_installer_invoices_cards_Action.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
    //get_installer_invoices_data
    [get_installer_invoices_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [get_installer_invoices_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [get_installer_invoices_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.cardStatus = null;
      state.massage = action.payload;
    },

    //edit__installer_invoices_data
    [edit__installer_invoices_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [edit__installer_invoices_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [edit__installer_invoices_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
    //delete_installer_invoices_files_Action
    [delete_installer_invoices_files_Action.pending]: (state) => {
      state.isLodaing = true;
    },
    [delete_installer_invoices_files_Action.fulfilled]: (
      state,
      { payload }
    ) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [delete_installer_invoices_files_Action.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },

    //create_upload_to_pto_cards_Action
    [create_upload_to_pto_cards_Action.pending]: (state) => {
      state.isLodaing = true;
    },
    [create_upload_to_pto_cards_Action.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [create_upload_to_pto_cards_Action.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
    //get_upload_to_pto_data
    [get_upload_to_pto_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [get_upload_to_pto_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [get_upload_to_pto_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.cardStatus = null;
      state.massage = action.payload;
    },

    //edit__upload_to_pto_data
    [edit__upload_to_pto_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [edit__upload_to_pto_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [edit__upload_to_pto_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
    //delete__upload_to_pto_files_Action
    [delete__upload_to_pto_files_Action.pending]: (state) => {
      state.isLodaing = true;
    },
    [delete__upload_to_pto_files_Action.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [delete__upload_to_pto_files_Action.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },

    //create_send_full_details_to_client_Action
    [create_send_full_details_to_client_Action.pending]: (state) => {
      state.isLodaing = true;
    },
    [create_send_full_details_to_client_Action.fulfilled]: (
      state,
      { payload }
    ) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [create_send_full_details_to_client_Action.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
    //get_send_full_details_to_client_data
    [get_send_full_details_to_client_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [get_send_full_details_to_client_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [get_send_full_details_to_client_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.cardStatus = null;
      state.massage = action.payload;
    },

    //edit__send_full_details_to_client_data
    [edit__send_full_details_to_client_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [edit__send_full_details_to_client_data.fulfilled]: (
      state,
      { payload }
    ) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [edit__send_full_details_to_client_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
    //delete_send_full_details_to_client_filess
    [delete_send_full_details_to_client_filess.pending]: (state) => {
      state.isLodaing = true;
    },
    [delete_send_full_details_to_client_filess.fulfilled]: (
      state,
      { payload }
    ) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [delete_send_full_details_to_client_filess.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },

    //create_review_profit_loss_statement_Action
    [create_review_profit_loss_statement_Action.pending]: (state) => {
      state.isLodaing = true;
    },
    [create_review_profit_loss_statement_Action.fulfilled]: (
      state,
      { payload }
    ) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [create_review_profit_loss_statement_Action.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
    //get_review_profit_loss_statement_data
    [get_review_profit_loss_statement_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [get_review_profit_loss_statement_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [get_review_profit_loss_statement_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.cardStatus = null;
      state.massage = action.payload;
    },

    //edit__review_profit_loss_statement_data
    [edit__review_profit_loss_statement_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [edit__review_profit_loss_statement_data.fulfilled]: (
      state,
      { payload }
    ) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [edit__review_profit_loss_statement_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
    //delete_review_profit_loss_statement_filess
    [delete_review_profit_loss_statement_filess.pending]: (state) => {
      state.isLodaing = true;
    },
    [delete_review_profit_loss_statement_filess.fulfilled]: (
      state,
      { payload }
    ) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [delete_review_profit_loss_statement_filess.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },

    //create_closure_report_Action
    [create_closure_report_Action.pending]: (state) => {
      state.isLodaing = true;
    },
    [create_closure_report_Action.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [create_closure_report_Action.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
    //get_closure_report_data
    [get_closure_report_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [get_closure_report_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [get_closure_report_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.cardStatus = null;
      state.massage = action.payload;
    },

    //edit__closure_report_data
    [edit__closure_report_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [edit__closure_report_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [edit__closure_report_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
    //delete_closure_report__filess
    [delete_closure_report__filess.pending]: (state) => {
      state.isLodaing = true;
    },
    [delete_closure_report__filess.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [delete_closure_report__filess.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },

    //create_installation_payment_collection_Action
    [create_installation_payment_collection_Action.pending]: (state) => {
      state.isLodaing = true;
    },
    [create_installation_payment_collection_Action.fulfilled]: (
      state,
      { payload }
    ) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [create_installation_payment_collection_Action.rejected]: (
      state,
      action
    ) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
    //get_installation_payment_collection_data
    [get_installation_payment_collection_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [get_installation_payment_collection_data.fulfilled]: (
      state,
      { payload }
    ) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [get_installation_payment_collection_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.cardStatus = null;
      state.massage = action.payload;
    },

    //edit__installation_payment_collection_data
    [edit__installation_payment_collection_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [edit__installation_payment_collection_data.fulfilled]: (
      state,
      { payload }
    ) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [edit__installation_payment_collection_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
    //delete_installation_payment_collection__filess
    [delete_installation_payment_collection__filess.pending]: (state) => {
      state.isLodaing = true;
    },
    [delete_installation_payment_collection__filess.fulfilled]: (
      state,
      { payload }
    ) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [delete_installation_payment_collection__filess.rejected]: (
      state,
      action
    ) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },

    //create_closure_report_Action
    [create_installer_commissions_Action.pending]: (state) => {
      state.isLodaing = true;
    },
    [create_installer_commissions_Action.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [create_installer_commissions_Action.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
    //get_installer_commissions_data
    [get_installer_commissions_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [get_installer_commissions_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [get_installer_commissions_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.cardStatus = null;
      state.massage = action.payload;
    },

    //edit__installer_commissions_data
    [edit__installer_commissions_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [edit__installer_commissions_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [edit__installer_commissions_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
    //delete_installer_commissions_filess
    [delete_installer_commissions_filess.pending]: (state) => {
      state.isLodaing = true;
    },
    [delete_installer_commissions_filess.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [delete_installer_commissions_filess.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },

    //create_installation_pics_Action
    [create_installation_pics_Action.pending]: (state) => {
      state.isLodaing = true;
    },
    [create_installation_pics_Action.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [create_installation_pics_Action.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
    //get_installation_pics_data
    [get_installation_pics_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [get_installation_pics_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [get_installation_pics_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.cardStatus = null;
      state.massage = action.payload;
    },

    //edit__installer_commissions_data
    [edit__installation_pics_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [edit__installation_pics_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [edit__installation_pics_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
    //delete_installer_commissions_filess
    [delete_installation_pics_filess.pending]: (state) => {
      state.isLodaing = true;
    },
    [delete_installation_pics_filess.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [delete_installation_pics_filess.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },

    //create_confirm_inspection_Action
    [create_confirm_inspection_Action.pending]: (state) => {
      state.isLodaing = true;
    },
    [create_confirm_inspection_Action.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [create_confirm_inspection_Action.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
    //get_confirm_inspection_data
    [get_confirm_inspection_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [get_confirm_inspection_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [get_confirm_inspection_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.cardStatus = null;
      state.massage = action.payload;
    },

    //edit__confirm_inspection_data
    [edit__confirm_inspection_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [edit__confirm_inspection_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [edit__confirm_inspection_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
    //delete_confirm_inspection_filess
    [delete_confirm_inspection_filess.pending]: (state) => {
      state.isLodaing = true;
    },
    [delete_confirm_inspection_filess.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [delete_confirm_inspection_filess.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
    //post apply_permits  Action
    [apply_permits.pending]: (state) => {
      state.isLodaing = true;
    },
    [apply_permits.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [apply_permits.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },

    //get_apply_permits_data
    [get_apply_permits_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [get_apply_permits_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [get_apply_permits_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.cardStatus = null;
      state.massage = action.payload;
    },

    //edit__apply_permits_data
    [edit__apply_permits_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [edit__apply_permits_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [edit__apply_permits_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
    //delete_apply_permits_filess
    [delete_apply_permits_filess.pending]: (state) => {
      state.isLodaing = true;
    },
    [delete_apply_permits_filess.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [delete_apply_permits_filess.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },

    //create_end_project_call_Action
    [create_end_project_call_Action.pending]: (state) => {
      state.isLodaing = true;
    },
    [create_end_project_call_Action.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [create_end_project_call_Action.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
    //get_end_project_call_data
    [get_end_project_call_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [get_end_project_call_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [get_end_project_call_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.cardStatus = null;
      state.massage = action.payload;
    },

    //edit__end_project_call_data
    [edit__end_project_call_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [edit__end_project_call_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [edit__end_project_call_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
    //delete_end_project_call_filess
    [delete_end_project_call_filess.pending]: (state) => {
      state.isLodaing = true;
    },
    [delete_end_project_call_filess.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [delete_end_project_call_filess.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },

    //create_upload_PTO_to_finance_Action
    [create_upload_PTO_to_finance_Action.pending]: (state) => {
      state.isLodaing = true;
    },
    [create_upload_PTO_to_finance_Action.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [create_upload_PTO_to_finance_Action.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
    //get_upload_PTO_to_finance_data
    [get_upload_PTO_to_finance_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [get_upload_PTO_to_finance_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [get_upload_PTO_to_finance_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.cardStatus = null;
      state.massage = action.payload;
    },

    //edit__upload_PTO_to_finance_data
    [edit__upload_PTO_to_finance_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [edit__upload_PTO_to_finance_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [edit__upload_PTO_to_finance_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
    //delete_upload_PTO_to_finance_filess
    [delete_upload_PTO_to_finance_filess.pending]: (state) => {
      state.isLodaing = true;
    },
    [delete_upload_PTO_to_finance_filess.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [delete_upload_PTO_to_finance_filess.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },

    //create_final_job_card_Action
    [create_final_job_card_Action.pending]: (state) => {
      state.isLodaing = true;
    },
    [create_final_job_card_Action.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [create_final_job_card_Action.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
    //get_final_job_card_data
    [get_final_job_card_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [get_final_job_card_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [get_final_job_card_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.cardStatus = null;
      state.massage = action.payload;
    },

    //edit__final_job_card_data
    [edit__final_job_card_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [edit__final_job_card_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [edit__final_job_card_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
    //delete_final_job_card_filess
    [delete_final_job_card_filess.pending]: (state) => {
      state.isLodaing = true;
    },
    [delete_final_job_card_filess.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [delete_final_job_card_filess.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },

    //  create_schedule_with_city_office_Action
    [create_schedule_with_city_office_Action.pending]: (state) => {
      state.isLodaing = true;
    },
    [create_schedule_with_city_office_Action.fulfilled]: (
      state,
      { payload }
    ) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [create_schedule_with_city_office_Action.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
    //get_schedule_with_city_office_data
    [get_schedule_with_city_office_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [get_schedule_with_city_office_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [get_schedule_with_city_office_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.cardStatus = null;
      state.massage = action.payload;
    },

    //edit__schedule_with_city_office_data
    [edit__schedule_with_city_office_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [edit__schedule_with_city_office_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [edit__schedule_with_city_office_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
    // delete_schedule_with_city_office_filess
    [delete_schedule_with_city_office_filess.pending]: (state) => {
      state.isLodaing = true;
    },
    [delete_schedule_with_city_office_filess.fulfilled]: (
      state,
      { payload }
    ) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [delete_schedule_with_city_office_filess.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },

    //  create_inform_inspection_result_Action
    [create_inform_inspection_result_Action.pending]: (state) => {
      state.isLodaing = true;
    },
    [create_inform_inspection_result_Action.fulfilled]: (
      state,
      { payload }
    ) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [create_inform_inspection_result_Action.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
    //get_inform_inspection_result_data
    [get_inform_inspection_result_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [get_inform_inspection_result_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [get_inform_inspection_result_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.cardStatus = null;
      state.massage = action.payload;
    },

    //edit__inform_inspection_result_data
    [edit__inform_inspection_result_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [edit__inform_inspection_result_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [edit__inform_inspection_result_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
    // delete_inspection_result_filess
    [delete_inspection_result_filess.pending]: (state) => {
      state.isLodaing = true;
    },
    [delete_inspection_result_filess.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [delete_inspection_result_filess.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },

    //  create_monitoring_optimizers_Action
    [create_monitoring_optimizers_Action.pending]: (state) => {
      state.isLodaing = true;
    },
    [create_monitoring_optimizers_Action.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [create_monitoring_optimizers_Action.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
    //get_monitoring_optimizers_data
    [get_monitoring_optimizers_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [get_monitoring_optimizers_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [get_monitoring_optimizers_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.cardStatus = null;
      state.massage = action.payload;
    },

    //edit__monitoring_optimizers_data
    [edit__monitoring_optimizers_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [edit__monitoring_optimizers_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [edit__monitoring_optimizers_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
    // delete_monitoring_optimizers_filess
    [delete_monitoring_optimizers_filess.pending]: (state) => {
      state.isLodaing = true;
    },
    [delete_monitoring_optimizers_filess.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [delete_monitoring_optimizers_filess.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },

    //  create_m1_collection_Action
    [create_m1_collection_Action.pending]: (state) => {
      state.isLodaing = true;
    },
    [create_m1_collection_Action.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [create_m1_collection_Action.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
    //get_m1_collection_data
    [get_m1_collection_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [get_m1_collection_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [get_m1_collection_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.cardStatus = null;
      state.massage = action.payload;
    },

    //edit__m1_collection_data
    [edit__m1_collection_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [edit__m1_collection_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [edit__m1_collection_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
    // delete_m1_collection_files
    [delete_m1_collection_files.pending]: (state) => {
      state.isLodaing = true;
    },
    [delete_m1_collection_files.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [delete_m1_collection_files.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },



    //  create_m2_collection_Action
    [create_m2_collection_Action.pending]: (state) => {
      state.isLodaing = true;
    },
    [create_m2_collection_Action.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [create_m2_collection_Action.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
    //get_m2_collection_data
    [get_m2_collection_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [get_m2_collection_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [get_m2_collection_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.cardStatus = null;
      state.massage = action.payload;
    },

    //edit__m2_collection_data
    [edit__m2_collection_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [edit__m2_collection_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [edit__m2_collection_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
    // delete_m2_collection_files
    [delete_m2_collection_files.pending]: (state) => {
      state.isLodaing = true;
    },
    [delete_m2_collection_files.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [delete_m2_collection_files.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },

    // create_final_inspection_Action
    [create_final_inspection_Action.pending]: (state) => {
      state.isLodaing = true;
    },
    [create_final_inspection_Action.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [create_final_inspection_Action.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },

    //get_final_inspection_data
    [get_final_inspection_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [get_final_inspection_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [get_final_inspection_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.cardStatus = null;
      state.massage = action.payload;
    },

    //edit__final_inspection_data
    [edit__final_inspection_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [edit__final_inspection_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [edit__final_inspection_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
    // delete_final_inspection_files
    [delete_final_inspection_files.pending]: (state) => {
      state.isLodaing = true;
    },
    [delete_final_inspection_files.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [delete_final_inspection_files.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },

    // call_designer
    [call_designer.pending]: (state) => {
      state.isLodaing = true;
    },
    [call_designer.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [call_designer.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },

    //get_call_designer_data
    [get_call_designer_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [get_call_designer_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [get_call_designer_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.cardStatus = null;
      state.massage = action.payload;
    },

    //edit__call_designer_data
    [edit__call_designer_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [edit__call_designer_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.cardStatus = payload;
    },
    [edit__call_designer_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
  },
});

export const { reset } = cardStatusSlice.actions;
export default cardStatusSlice.reducer;
