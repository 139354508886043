import React, { useState } from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./EditorStyle.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { useEffect } from "react";

const RichTextEditor = ({ defaultValue = "", onChange }) => {
  const [state, setState] = useState(EditorState.createEmpty());

  useEffect(() => {
    const contentBlock = htmlToDraft(defaultValue);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorDefaultState = EditorState.createWithContent(contentState);
      setState(editorDefaultState);
    }
  }, [defaultValue]);

  const handleEditorStateChange = (editorState) => {
    const stateInHtmlFormat = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    onChange && onChange(stateInHtmlFormat);
    setState(editorState);
  };

  return (
    <Editor
      editorState={state}
      onEditorStateChange={handleEditorStateChange}
      toolbar={{
        options: [
          "inline",
          "blockType",
          "fontSize",
          "fontFamily",
          "list",
          "textAlign",
          "link",
          "remove",
          "history",
        ],
      }}
    />
  );
};

export default RichTextEditor;
