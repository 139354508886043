import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getSingleTicket } from "../../Redux/Slices/SingleTicketSlice/SingleTicketSlice";
const SalesRep = ({ ticket_id }) => {
  const dispatch = useDispatch();
  const single_ticket = useSelector((state) => state.single_ticket);

  useEffect(() => {
    dispatch(getSingleTicket(ticket_id));
  }, [ticket_id]);
  return (
    <>
      <div className="LeadInfo">
        {single_ticket?.isLodaing ? (
          <p>Loading</p>
        ) : (
          <ul>


            <li>
            <p style={{ color: "black", fontWeight: "bold" ,  marginBottom:"8px" }}>Sales Rep. Name :</p>
              <span>
                {" "}
                {
                  single_ticket?.singleTicket?.data?.opportunity?.user?.name
                }{" "}
              </span>
            </li>

            <li>
            <p style={{ color: "black", fontWeight: "bold" ,  marginBottom:"8px" }}>Email :</p>
              <span>
                {single_ticket?.singleTicket?.data?.opportunity?.user?.email}
              </span>
            </li>

            <li>
            <p style={{ color: "black", fontWeight: "bold" ,  marginBottom:"8px" }}>Phone :</p>
              <span>
                {single_ticket?.singleTicket?.data?.opportunity?.user?.phone}
              </span>
            </li>
          </ul>
        )}
      </div>
    </>
  );
};

export default SalesRep;
