import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AddersCostSpecificCardsService from "./AddersCostSpecificCardsService";

const initialState = {
  AddersCostSpecificCards: {},
  isError: false,
  isSuccess: false,
  isLodaing: false,
  massage: "",
};

export const postAddersCostSpecificCard = createAsyncThunk(
  "AddersCostSpecificCards/post",
  async (data, thunkAPI) => {
    try {
      return await AddersCostSpecificCardsService.createAddersCostSpecificCards(
        data
      );
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const get_AddersCostSpecificCards = createAsyncThunk(
  "AddersCostSpecificCards/get",
  async (id, thunkAPI) => {
    try {
      return await AddersCostSpecificCardsService.showAddersCostSpecificCards(
        id
      );
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const update_AddersCostSpecificCards = createAsyncThunk(
  "updateersCostSpecificCards/post",
  async (data, thunkAPI) => {
    try {
      return await AddersCostSpecificCardsService.updateAddersCostSpecificCards(
        data
      );
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);



export const delete_AddersCostSpecificCards = createAsyncThunk(
  "deleteAddersCostSpecificCards/get",
  async (id, thunkAPI) => {
    try {
      return await AddersCostSpecificCardsService.deleteAddersCostSpecificCards(
        id
      );
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const AddersCostSpecificCardSlice = createSlice({
  name: "AddersCostSpecificCards",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLodaing = false;
      state.massage = "";
    },
  },
  extraReducers: {
    // postAddersCostCard  Action
    [postAddersCostSpecificCard.pending]: (state) => {
      state.isLodaing = true;
    },
    [postAddersCostSpecificCard.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.AddersCostSpecificCards = payload;
    },
    [postAddersCostSpecificCard.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },

    // get AddersCostCard  Action
    [get_AddersCostSpecificCards.pending]: (state) => {
      state.isLodaing = true;
    },
    [get_AddersCostSpecificCards.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.AddersCostSpecificCards = payload;
    },
    [get_AddersCostSpecificCards.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.AddersCostSpecificCards = null;
      state.massage = action.payload;
    },

        // delete_AddersCostSpecificCards
        [delete_AddersCostSpecificCards.pending]: (state) => {
          state.isLodaing = true;
        },
        [delete_AddersCostSpecificCards.fulfilled]: (state, { payload }) => {
          state.isLodaing = false;
          state.isSuccess = true;
          state.AddersCostSpecificCards = payload;
        },
        [delete_AddersCostSpecificCards.rejected]: (state, action) => {
          state.isLodaing = false;
          state.isError = true;
          state.AddersCostSpecificCards = null;
          state.massage = action.payload;
        },

  // update_AddersCostSpecificCards
    [update_AddersCostSpecificCards.pending]: (state) => {
      state.isLodaing = true;
    },
    [update_AddersCostSpecificCards.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.AddersCostSpecificCards = payload;
    },
    [update_AddersCostSpecificCards.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
  },
});

export const { reset } = AddersCostSpecificCardSlice.actions;
export default AddersCostSpecificCardSlice.reducer;
