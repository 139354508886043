import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import addons_Service from "./AddonsService";

const initialState = {
  addons: {},
  isError: false,
  isSuccess: false,
  isLodaing: false,
  massage: "",
};

export const store_addons_data = createAsyncThunk(
  "store_addons/post",
  async (data, thunkAPI) => {
    try {
      return await addons_Service.create_addons_data_Action(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const get_all_addons = createAsyncThunk(
  "all_addons/get",
  async (id, thunkAPI) => {
    try {
      return await addons_Service.get_all_addons_data(id);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);
export const delete_addon = createAsyncThunk(
  "delete_addone/post",
  async (data, thunkAPI) => {
    try {
      return await addons_Service.delete_addon_Action(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const addons_Slice = createSlice({
  name: "addons",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLodaing = false;
      state.isDeleteLodaing = false;
      state.massage = "";
    },
  },
  extraReducers: {
    //store_addons_data
    [store_addons_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [store_addons_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.addons = payload;
    },
    [store_addons_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },

    //get_all_addons
    [get_all_addons.pending]: (state) => {
      state.isLodaing = true;
    },
    [get_all_addons.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.addons = payload;
    },
    [get_all_addons.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.addons = null;
      state.massage = action.payload;
    },

    //delete addons
    [delete_addon.pending]: (state) => {
      state.isLodaing = true;
    },
    [delete_addon.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.addons = payload;
    },
    [delete_addon.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
  },
});

export const { reset } = addons_Slice.actions;
export default addons_Slice.reducer;
