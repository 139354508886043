import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Grid, Typography, CardContent } from "@mui/material";
import LeadSideInfo from "../../Components/LeadSideInfo/LeadSideInfo";
import { get_procedures } from "../../Redux/Slices/Procedures/ProceduresSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getSingleTicket } from "../../Redux/Slices/SingleTicketSlice/SingleTicketSlice";
import AddIcon from "@mui/icons-material/Add";
import { Box } from "@mui/system";
import CountyModal from "../../Components/Modals/CountyModal/CountyModal";
import LottieAnimation from "../../Components/LottieAnimation/LottieAnimation";
import loading from "../../Assets/lottie/loading.json";
import ReactHtmlParser from "react-html-parser";
import EditIcon from "@mui/icons-material/Edit";
function Procedures() {
  const dispatch = useDispatch();
  const single_ticket = useSelector((state) => state.single_ticket);
  const procedures = useSelector((state) => state.procedures);
  const { lead_id } = useParams();
  const params = useParams();
  console.log(params, "params in procveduresss");
  const [openModal, setOpenModal] = useState(false);
  console.log(single_ticket?.isLodaing, "klklk");
  const county = single_ticket?.singleTicket?.data?.opportunity?.lead?.county;
  console.log(procedures?.procedures, ",m,m,");
  console.log(procedures?.procedures?.data, "test");

  useEffect(() => {
    !single_ticket?.isLodaing && dispatch(get_procedures(county));
    dispatch(getSingleTicket(lead_id));
  }, [county, openModal]);

  const handleopenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const openEditModal = () => {};
  return (
    <>
      <Grid container columns={18}>
        <Grid item lg={4} md={6} xs={18}>
          <LeadSideInfo />
        </Grid>

        <Grid item lg={14} md={12} xs={18}>
          <MainTabsStyled>
            <Box
              sx={{ width: "100%", typography: "body1" }}
              style={{ backgroundColor: "white" }}
            >
              <Grid style={{ padding: "20px" }}>
                {procedures?.procedures == null ? (
                  <>
                    <Typography variant="h6">no procedures found</Typography>
                    <Button
                      variant="outlined"
                      startIcon={<AddIcon />}
                      onClick={handleopenModal}
                    >
                      Add County
                    </Button>
                  </>
                ) : procedures?.isLodaing ? (
                  <LottieAnimation lotti={loading} width={180} height={180} />
                ) : (
                  procedures?.procedures?.data && (
                    //   <Grid item sm={6} xs={12}>
                    <CardContent
                      style={{
                        background: "rgb(249, 245, 255)",
                        border: "1px solid rgb(214, 187, 251)",
                        borderRadius: "8px",
                        padding: "1rem",
                        margin: "0.5rem 0px",
                      }}
                    >
                      <Typography style={{ fontWeight: "bold" }}>
                        {" "}
                        <span
                          style={{
                            fontWeight: "bolder",
                            color: "#7e7ec0",
                          }}
                        >
                          {" "}
                          County :{" "}
                        </span>{" "}
                        {procedures?.procedures?.data?.county}
                      </Typography>
                      <Typography>
                        {" "}
                        {ReactHtmlParser(procedures?.procedures?.data?.text)}
                      </Typography>

                      {procedures?.procedures?.data?.created_at && (
                        <>
                          <Typography>
                            {" "}
                            <span style={{ fontWeight: "bold" }}>
                              created at :{" "}
                            </span>
                            {
                              new Date(procedures?.procedures?.data?.created_at)
                                ?.toISOString()
                                ?.split("T", 1)[0]
                            }
                          </Typography>

                          {procedures?.procedures?.data?.updated_at && (
                            <Typography>
                              {" "}
                              <span style={{ fontWeight: "bold" }}>
                                updated at :{" "}
                              </span>
                              {
                                new Date(
                                  procedures?.procedures?.data?.updated_at
                                )
                                  ?.toISOString()
                                  ?.split("T", 1)[0]
                              }
                            </Typography>
                          )}
                          <div
                            style={{ display: "flex", justifyContent: "end" }}
                          >
                            {/* <Button onClick={handleopenModal}>
                              <EditIcon />
                            </Button> */}
                            <Button
                              variant="outlined"
                              startIcon={<AddIcon />}
                              onClick={handleopenModal}
                            >
                              Edit
                            </Button>
                          </div>
                        </>
                      )}
                    </CardContent>
                  )
                )}
              </Grid>
            </Box>
          </MainTabsStyled>
        </Grid>
      </Grid>
      {openModal && (
        <CountyModal
          handleopenModal={handleopenModal}
          handleCloseModal={handleCloseModal}
          setOpenModal={setOpenModal}
          openModal={openModal}
          ticket_id={lead_id}
          procedure={procedures?.procedures?.data}
        />
      )}
    </>
  );
}
const MainTabsStyled = styled.div`
  margin: 1rem 0.8rem;
  border-radius: 5px;
`;
export default Procedures;
