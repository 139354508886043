import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  FormControl,
  IconButton,
  Button,
  Typography,
  MenuItem,
  InputLabel,
  Select,
  ListItemText,
  List,
  ListItem,
} from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import Avatar from "@mui/material/Avatar";
import CloseIcon from "@mui/icons-material/Close";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import EditIcon from "@mui/icons-material/Edit";
import { PrimaryButton } from ".././../Layouts/ButtonsControlled";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useSelector, useDispatch } from "react-redux";
import CallSupplierDetails from "./CallSupplierDetails";
import ConfirmWithSalesAndCustomerDetails from "./ConfirmWithSalesAndCustomerDetails";
import JobReviewsWithSalesDetails from "./JobReviewsWithSalesDetails";
import SurveyStepsDetails from "./SurveyStepsDetails";
import Call_jurisdiction_Details from "./Call_jurisdiction_Details";
import CallCityOffice from "./CallCityOffice";
import WelcomeCallDetails from "./WelcomeCallDetails";
import ExtraAddersDetails from "./ExtraAddersDetails";
import CallUtilityCompany from "./CallUtilityCompany";
import BusinessLicenseData from "./BusinessLicenseData";
import ProductAvailabilityDetails from "./ProductAvailabilityDetails"
import DeleteIcon from "@mui/icons-material/Delete";
import UploadMediaForCard from "./UploadMediaForCards"
import {
  get_pto_data,
  edit__PTO_data,
  get_NTP_data,
  edit__NTP_data,
  edit_meter_spot_data,
  get_meter_spot_data,
  get_quotes_for_products_data,
  edit__quotes_for_products_data,
  get_product_orders_data,
  edit__product_orders_data,
  get_permit_to_finance_data,
  edit__permit_to_finance_data,
  get_order_place_cards_data,
  edit__order_place_cards_data,
  get_coordinate_product_delivery_data,
  edit__coordinate_product_delivery_data,
  get_coordinate_installations_data,
  edit_coordinate_installations_data,
  get_schedule_inspections_data,
  edit__schedule_inspections_data,
  edit__installer_invoices_data,
  get_installer_invoices_data,
  edit__upload_to_pto_data,
  get_upload_to_pto_data,
  edit__send_full_details_to_client_data,
  get_send_full_details_to_client_data,
  get_review_profit_loss_statement_data,
  edit__review_profit_loss_statement_data,
  get_closure_report_data,
  edit__closure_report_data,
  get_installation_payment_collection_data,
  edit__installation_payment_collection_data,
  get_installer_commissions_data,
  edit__installer_commissions_data,
  get_installation_pics_data,
  edit__installation_pics_data,
  get_confirm_inspection_data,
  edit__confirm_inspection_data,
  get_apply_permits_data,
  edit__apply_permits_data,
  get_end_project_call_data,
  edit__end_project_call_data,
  get_upload_PTO_to_finance_data,
  edit__upload_PTO_to_finance_data,
  get_final_job_card_data,
  edit__final_job_card_data,
  get_schedule_with_city_office_data,
  edit__schedule_with_city_office_data,
  get_inform_inspection_result_data,
  edit__inform_inspection_result_data,
  get_monitoring_optimizers_data,
  edit__monitoring_optimizers_data,
  get_m1_collection_data,
  get_m2_collection_data,
  edit__m2_collection_data,
  edit__m1_collection_data,
  get_final_inspection_data,
  edit__final_inspection_data,
  get_call_designer_data,
  edit__call_designer_data,
} from "../../Redux/Slices/CardsStatusSlics/CardStepsSlice";
import {
  delete_quotes_for_products_Action,
  delete_pto_file,
  delete_NTP_file,
  delete_meter_spot_file,
  delete_product_orders_Action,
  delete_permit_to_finance_Action,
  delete_order_place_cards_Action,
  delete_coordinate_product_delivery_Action,
  delete_coordinate_installations_Action,
  delete_schedule_inspections_files_Action,
  delete_installer_invoices_files_Action,
  delete__upload_to_pto_files_Action,
  delete_send_full_details_to_client_filess,
  delete_review_profit_loss_statement_filess,
  delete_closure_report__filess,
  delete_installation_payment_collection__filess,
  delete_installer_commissions_filess,
  delete_installation_pics_filess,
  delete_confirm_inspection_filess,
  delete_apply_permits_filess,
  delete_end_project_call_filess,
  delete_upload_PTO_to_finance_filess,
  delete_final_job_card_filess,
  delete_schedule_with_city_office_filess,
  delete_inspection_result_filess,
  delete_monitoring_optimizers_filess,
  delete_m1_collection_files,
  delete_final_inspection_files,
  delete_m2_collection_files
} from "../../Redux/Slices/CardsStatusSlics/CardStepsSlice";
import { get_job_reviews_data } from "../../Redux/Slices/JobReviewsWithSalesSlice/JobReviewsSlice";
import { get_call_suupliers_data } from "../../Redux/Slices/CallSupplierSlice/CallSupplierSlice";
import { get_confirm_with_salesAndCustomer_data } from "../../Redux/Slices/ConfirmWithSalesAndCustomerSlice/ConfirmWithSalesAndCustomerSlice";

import {
  get_business_license,
  edit__business_license_data,
} from "../../Redux/Slices/businessLicenseSlice/businessLicenseSlice";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { getWelcomeCall } from "../../Redux/Slices/WelcomeCallSlice/WelcomeCallSlice";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { get_ProductAvailability } from "../../Redux/Slices/ProductAvailability/ProductAvailabilitySlice";
const CardStatusDetails = ({ type, lead_id, ticket_status }) => {
  const dispatch = useDispatch();
  const cardStatus = useSelector((state) => state?.cardStatus);
  const business_license_Data = useSelector((state) => state?.business_license);
  const welcomCallDetails = useSelector((state) => state.WelcomeCall);
  const productAvailability_Data = useSelector((state) => state?.productAvailability);
  const [openEditForm, setOpenEditForm] = useState(false);
  const [openCardData, setOpenCardData] = useState(true);
  console.log(type, "typeedit");
  console.log(cardStatus?.cardStatus , "cardStatus123")
  console.log(productAvailability_Data , "productAvailability_Data")

  console.log(welcomCallDetails, "welcomCallDetails4545");
  const [PTOData, setPTOData] = useState({
    status: "",
    comment: "",
    cost: "",
    event_from: new Date()?.toISOString()?.split("T", 1)[0],
    event_to: new Date()?.toISOString()?.split("T", 1)[0],
    event: cardStatus?.cardStatus?.data?.event,
    //coordinate product delievery card
    estimated_time: "",
    access_to_driveway: "",
    will_be_at_home: "",
    leave_the_product: "",
    date_time: new Date()?.toISOString()?.split("T", 1)[0],
    // coordinate_client_installer_installation card
    customer_comments: "",
    confirm_survey_time: "",
    home_during_installation: "",
    animals_outside_property: "",
  });
  console.log(cardStatus, "cardStatusjkjkk");

  const [timePickerValue, setTimePickerValue] = React.useState(
    new Date()?.toISOString()?.split("T", 1)[0]
  );
  console.log(cardStatus?.cardStatus?.data?.time, "gfgfgfg");
  const [businessLicenseStatus, setBusinessLicenseData] = useState();
  console.log(businessLicenseStatus, "businessLicenseStatus");
  console.log(
    new Date(
      cardStatus?.cardStatus?.data?.event_to ||
      new Date()?.toISOString()?.split("T", 1)[0]
    )
      .toISOString()
      .split("T", 1)[0]
  );
  console.log(business_license_Data, "business_license_Data card statusssss");
  console.log(cardStatus, "test..... test");
  console.log(cardStatus?.isLodaing, "test 2");
  console.log(openEditForm, type);

  // useEffect(() => {
  //   if (type == "welcome_call") {
  //     dispatch(getWelcomeCall(lead_id));
  //   }
  // }, [type]);

  useEffect(() => {
    dispatch(get_ProductAvailability(lead_id));

}, []);

  useEffect(() => {
    // if (type == "welcome_call") {
    //   dispatch(getWelcomeCall(lead_id));
    // }

    if (type == "apply_pto") {
      dispatch(get_pto_data(lead_id));
    } else if (type == "ntp_finance_portal") {
      dispatch(get_NTP_data(lead_id));
    } else if (type == "apply_meter_spot") {
      dispatch(get_meter_spot_data(lead_id));
    } else if (type == "products_quotes") {
      dispatch(get_quotes_for_products_data(lead_id));
    } else if (type == "order_product") {
      dispatch(get_product_orders_data(lead_id));
    } else if (type == "upload_permit_to_finance") {
      dispatch(get_permit_to_finance_data(lead_id));
    } else if (type == "order_placards") {
      dispatch(get_order_place_cards_data(lead_id));
    } else if (type == "coordinate_product_delivery") {
      dispatch(get_coordinate_product_delivery_data(lead_id));
    } else if (type == "coordinate_client_installer_installation") {
      dispatch(get_coordinate_installations_data(lead_id));
    } else if (type == "schedule_inspection") {
      dispatch(get_schedule_inspections_data(lead_id));
    } else if (type == "invoice_for_installer") {
      dispatch(get_installer_invoices_data(lead_id));
    } else if (type == "upload_to_pto") {
      dispatch(get_upload_to_pto_data(lead_id));
    } else if (type == "send_full_details_to_client") {
      dispatch(get_send_full_details_to_client_data(lead_id));
    } else if (type == "review_profit_loss_statement") {
      dispatch(get_review_profit_loss_statement_data(lead_id));
    } else if (type == "closure_report") {
      dispatch(get_closure_report_data(lead_id));
    } else if (type == "payment_collection") {
      dispatch(get_installation_payment_collection_data(lead_id));
    } else if (type == "installer_commissions") {
      dispatch(get_installer_commissions_data(lead_id));
    } else if (type == "upload_installation_pics_to_finance") {
      dispatch(get_installation_pics_data(lead_id));
    } else if (type == "confirm_installer_inspection") {
      dispatch(get_confirm_inspection_data(lead_id));
    } else if (type == "apply_all_required_permits") {
      dispatch(get_apply_permits_data(lead_id));
    } else if (type == "end_project_call") {
      dispatch(get_end_project_call_data(lead_id));
    } else if (type == "pto_to_finance") {
      dispatch(get_upload_PTO_to_finance_data(lead_id));
    } else if (type == "final_job_card") {
      dispatch(get_final_job_card_data(lead_id));
    } else if (type == "confirm_city_office_time_frame") {
      dispatch(get_schedule_with_city_office_data(lead_id));
    } else if (type == "inform_inspection_result") {
      dispatch(get_inform_inspection_result_data(lead_id));
    } else if (type == "monitoring_optimizer") {
      dispatch(get_monitoring_optimizers_data(lead_id));
    } else if (type == "m1_collection") {
      dispatch(get_m1_collection_data(lead_id));
    } else if (type == "m2_payment_collection") {
      dispatch(get_m2_collection_data(lead_id));}
    else if (type == "business_license") {
      dispatch(get_business_license(lead_id));
    } else if (type == "final_inspection") {
      dispatch(get_final_inspection_data(lead_id));
    } else if (type == "call_supplier") {
      dispatch(get_call_suupliers_data(lead_id));
    } else if (type == "confirm_items_with_sales_and_customer") {
      dispatch(get_confirm_with_salesAndCustomer_data(lead_id));
    } else if (type == "job_review") {
      dispatch(get_job_reviews_data(lead_id));
    } else if (type == "call_designer") {
      dispatch(get_call_designer_data(lead_id));
    } else if (type == "welcome_call") {
      dispatch(getWelcomeCall(lead_id));
    }
  }, [type, openEditForm]);

  useEffect(() => {
    setPTOData({
      comment: cardStatus?.cardStatus?.data?.comment,
      is_confirmed: cardStatus?.cardStatus?.data?.is_confirmed,
      status: cardStatus?.cardStatus?.data?.status,
      event_from:
        cardStatus?.cardStatus?.data?.event_from ||
        new Date()?.toISOString()?.split("T", 1)[0],
      event_to:
        cardStatus?.cardStatus?.data?.event_to ||
        new Date()?.toISOString()?.split("T", 1)[0],
      event: cardStatus?.cardStatus?.data?.event,
      estimated_time: cardStatus?.cardStatus?.data?.estimated_time,
      access_to_driveway: cardStatus?.cardStatus?.data?.access_to_driveway,
      will_be_at_home: cardStatus?.cardStatus?.data?.will_be_at_home,
      leave_the_product: cardStatus?.cardStatus?.data?.leave_the_product,
      date_time:
        cardStatus?.cardStatus?.data?.date_time ||
        new Date()?.toISOString()?.split("T", 1)[0],
      // coordinate_client_installer_installation card
      customer_comments: cardStatus?.cardStatus?.data?.customer_comments,
      confirm_survey_time: cardStatus?.cardStatus?.data?.confirm_survey_time,
      home_during_installation:
        cardStatus?.cardStatus?.data?.home_during_installation,
      animals_outside_property:
        cardStatus?.cardStatus?.data?.animals_outside_property,
    });
    // setTimePickerValue(cardStatus?.cardStatus?.data?.time);
    type == "business_license" &&
      setBusinessLicenseData(
        business_license_Data?.business_license?.data?.status
      );
  }, [type, openEditForm]);

  useEffect(() => {
    if (type == "welcome_call") {
      dispatch(getWelcomeCall(lead_id));
    }
    setPTOData({
      comment: cardStatus?.cardStatus?.data?.comment,
      cost: cardStatus?.cardStatus?.data?.cost,
      is_confirmed: cardStatus?.cardStatus?.data?.is_confirmed,
      status: cardStatus?.cardStatus?.data?.status,
      event_from:
        cardStatus?.cardStatus?.data?.event_from ||
        new Date()?.toISOString()?.split("T", 1)[0],
      event_to:
        cardStatus?.cardStatus?.data?.event_to ||
        new Date()?.toISOString()?.split("T", 1)[0],
      event: cardStatus?.cardStatus?.data?.event,
      // coordinate product delivery card data
      estimated_time: cardStatus?.cardStatus?.data?.estimated_time,
      access_to_driveway: cardStatus?.cardStatus?.data?.access_to_driveway,
      will_be_at_home:
        cardStatus?.cardStatus?.data?.will_be_at_home == 1 ? "Yes" : "No",
      leave_the_product: cardStatus?.cardStatus?.data?.leave_the_product,
      date_time:
        cardStatus?.cardStatus?.data?.date_time ||
        new Date()?.toISOString()?.split("T", 1)[0],
      // coordinate_client_installer_installation card
      customer_comments: cardStatus?.cardStatus?.data?.customer_comments,
      confirm_survey_time: cardStatus?.cardStatus?.data?.confirm_survey_time,
      home_during_installation:
        cardStatus?.cardStatus?.data?.home_during_installation,
      animals_outside_property:
        cardStatus?.cardStatus?.data?.animals_outside_property,
    });
    // setTimePickerValue(cardStatus?.cardStatus?.data?.time);
    type == "business_license" &&
      setBusinessLicenseData(
        business_license_Data?.business_license?.data?.status
      );
    openEditForm &&
      setPTOData({
        comment: cardStatus?.cardStatus?.data?.comment,
        cost: cardStatus?.cardStatus?.data?.cost,
        is_confirmed: cardStatus?.cardStatus?.data?.is_confirmed,
        status: cardStatus?.cardStatus?.data?.status,
        event_from:
          cardStatus?.cardStatus?.data?.event_from ||
          new Date()?.toISOString()?.split("T", 1)[0],
        event_to:
          cardStatus?.cardStatus?.data?.event_to ||
          new Date()?.toISOString()?.split("T", 1)[0],
        event: cardStatus?.cardStatus?.data?.event,
        estimated_time: cardStatus?.cardStatus?.data?.estimated_time,
        access_to_driveway: cardStatus?.cardStatus?.data?.access_to_driveway,
        will_be_at_home: cardStatus?.cardStatus?.data?.will_be_at_home,
        leave_the_product: cardStatus?.cardStatus?.data?.leave_the_product,
        date_time:
          cardStatus?.cardStatus?.data?.date_time ||
          new Date()?.toISOString()?.split("T", 1)[0],
        // coordinate_client_installer_installation card
        customer_comments: cardStatus?.cardStatus?.data?.customer_comments,
        confirm_survey_time: cardStatus?.cardStatus?.data?.confirm_survey_time,
        home_during_installation:
          cardStatus?.cardStatus?.data?.home_during_installation,
        animals_outside_property:
          cardStatus?.cardStatus?.data?.animals_outside_property,
      });
    // setTimePickerValue(cardStatus?.cardStatus?.data?.time);
    type == "business_license" &&
      setBusinessLicenseData(
        business_license_Data?.business_license?.data?.status
      );
  }, [openEditForm]);

  const handleShowEditForm = () => {
    setOpenEditForm(true);
  };
  const handleChangeDate = (newValue) => {
    setPTOData({
      ...PTOData,
      event: new Date(newValue).toISOString().split("T")[0],
    });
  };
  const closeEditForm = () => {
    setOpenCardData(true);
    setOpenEditForm(false);
  };

  const handlePTOChange = (key, value) =>
    setPTOData({ ...PTOData, [key]: value });

  // const handleDelete_PTO_file = async (file) => {
  //   const reqData = {
  //     id: file.id,
  //   };
  //   dispatch(delete_pto_file(reqData));
  //   await dispatch(get_pto_data(lead_id));
  // };

  let toDate =
    type == "confirm_city_office_time_frame" &&
    new Date(PTOData?.event_to)?.toISOString()?.split("T", 1)[0];

  let toTime =
    type == "confirm_city_office_time_frame" &&
    new Date(PTOData?.event_to)?.toLocaleTimeString("it-IT");
  let fromDate =
    type == "confirm_city_office_time_frame" &&
    new Date(PTOData?.event_from)?.toISOString()?.split("T", 1)[0];
  let fromTime =
    type == "confirm_city_office_time_frame" &&
    new Date(PTOData?.event_from)?.toLocaleTimeString("it-IT");
  let final_inspection_Date =
    type == "final_inspection" &&
    new Date(PTOData?.date_time)?.toISOString()?.split("T", 1)[0];

  let final_inspection_Time =
    type == "final_inspection" &&
    new Date(PTOData?.date_time)?.toLocaleTimeString("it-IT");

  let sendTime =
    type == "coordinate_client_installer_installation" &&
    new Date(timePickerValue)?.toLocaleString("en-US", {
      hour12: false,
    });

  // deletet func pending
  const handle_delete_file = async (file) => {
    const reqData = {
      id: file.id,
    };

    // if (type == "products_quotes") {
    //   let submitAction = delete_quotes_for_products_Action;
    //   await dispatch(submitAction(reqData));
    //   await dispatch(get_quotes_for_products_data(lead_id));
    // }

    if (type == "apply_pto") {
      let submitAction = delete_pto_file;
      await dispatch(submitAction(reqData));
      await dispatch(get_pto_data(lead_id));
    } else if (type == "ntp_finance_portal") {
      let submitAction = delete_NTP_file;
      await dispatch(submitAction(reqData));
      dispatch(get_NTP_data(lead_id));
    } else if (type == "apply_meter_spot") {
      let submitAction = delete_meter_spot_file;
      await dispatch(submitAction(reqData));
      dispatch(get_meter_spot_data(lead_id));
    } else if (type == "products_quotes") {
      let submitAction = delete_quotes_for_products_Action;
      await dispatch(submitAction(reqData));
      dispatch(get_quotes_for_products_data(lead_id));
    } else if (type == "order_product") {
      let submitAction = delete_product_orders_Action;
      await dispatch(submitAction(reqData));
      dispatch(get_product_orders_data(lead_id));
    } else if (type == "upload_permit_to_finance") {
      let submitAction = delete_permit_to_finance_Action;
      await dispatch(submitAction(reqData));
      dispatch(get_permit_to_finance_data(lead_id));
    } else if (type == "order_placards") {
      let submitAction = delete_order_place_cards_Action;
      await dispatch(submitAction(reqData));
      dispatch(get_order_place_cards_data(lead_id));
    } else if (type == "coordinate_product_delivery") {
      let submitAction = delete_coordinate_product_delivery_Action;
      await dispatch(submitAction(reqData));
      dispatch(get_coordinate_product_delivery_data(lead_id));
    } else if (type == "coordinate_client_installer_installation") {
      let submitAction = delete_coordinate_installations_Action;
      await dispatch(submitAction(reqData));
      dispatch(get_coordinate_installations_data(lead_id));
    } else if (type == "schedule_inspection") {
      let submitAction = delete_schedule_inspections_files_Action;
      await dispatch(submitAction(reqData));
      dispatch(get_schedule_inspections_data(lead_id));
    } else if (type == "invoice_for_installer") {
      let submitAction = delete_installer_invoices_files_Action;
      await dispatch(submitAction(reqData));
      dispatch(get_installer_invoices_data(lead_id));
    } else if (type == "upload_to_pto") {
      let submitAction = delete__upload_to_pto_files_Action;
      await dispatch(submitAction(reqData));
      dispatch(get_upload_to_pto_data(lead_id));
    } else if (type == "send_full_details_to_client") {
      let submitAction = delete_send_full_details_to_client_filess;
      await dispatch(submitAction(reqData));
      dispatch(get_send_full_details_to_client_data(lead_id));
    } else if (type == "review_profit_loss_statement") {
      let submitAction = delete_review_profit_loss_statement_filess;
      await dispatch(submitAction(reqData));
      dispatch(get_review_profit_loss_statement_data(lead_id));
    } else if (type == "closure_report") {
      let submitAction = delete_closure_report__filess;
      await dispatch(submitAction(reqData));
      dispatch(get_closure_report_data(lead_id));
    } else if (type == "payment_collection") {
      let submitAction = delete_installation_payment_collection__filess;
      await dispatch(submitAction(reqData));
      dispatch(get_installation_payment_collection_data(lead_id));
    } else if (type == "installer_commissions") {
      let submitAction = delete_installer_commissions_filess;
      await dispatch(submitAction(reqData));
      dispatch(get_installer_commissions_data(lead_id));
    } else if (type == "upload_installation_pics_to_finance") {
      let submitAction = delete_installation_pics_filess;
      await dispatch(submitAction(reqData));
      dispatch(get_installation_pics_data(lead_id));
    } else if (type == "confirm_installer_inspection") {
      let submitAction = delete_confirm_inspection_filess;
      await dispatch(submitAction(reqData));
      dispatch(get_confirm_inspection_data(lead_id));
    } else if (type == "apply_all_required_permits") {
      let submitAction = delete_apply_permits_filess;
      await dispatch(submitAction(reqData));
      dispatch(get_apply_permits_data(lead_id));
    } else if (type == "end_project_call") {
      let submitAction = delete_end_project_call_filess;
      await dispatch(submitAction(reqData));
      dispatch(get_end_project_call_data(lead_id));
    } else if (type == "pto_to_finance") {
      let submitAction = delete_upload_PTO_to_finance_filess;
      await dispatch(submitAction(reqData));
      dispatch(get_upload_PTO_to_finance_data(lead_id));
    } else if (type == "final_job_card") {
      let submitAction = delete_final_job_card_filess;
      await dispatch(submitAction(reqData));
      dispatch(get_final_job_card_data(lead_id));
    } else if (type == "confirm_city_office_time_frame") {
      let submitAction = delete_schedule_with_city_office_filess;
      await dispatch(submitAction(reqData));
      dispatch(get_schedule_with_city_office_data(lead_id));
    } else if (type == "inform_inspection_result") {
      let submitAction = delete_inspection_result_filess;
      await dispatch(submitAction(reqData));
      dispatch(get_inform_inspection_result_data(lead_id));
    } else if (type == "monitoring_optimizer") {
      let submitAction = delete_monitoring_optimizers_filess;
      await dispatch(submitAction(reqData));
      dispatch(get_monitoring_optimizers_data(lead_id));
    } else if (type == "m1_collection") {
      let submitAction = delete_m1_collection_files;
      await dispatch(submitAction(reqData));
      dispatch(get_m1_collection_data(lead_id));
    }
    else if (type == "m2_payment_collection") {
      let submitAction = delete_m2_collection_files;
      await dispatch(submitAction(reqData));
      dispatch(get_m2_collection_data(lead_id));
    }
    else if (type == "final_inspection") {
      let submitAction = delete_final_inspection_files;
      await dispatch(submitAction(reqData));
      dispatch(get_final_inspection_data(lead_id));
    } else if (type == "call_designer") {
      let submitAction = edit__call_designer_data;
      await dispatch(submitAction(reqData));
      dispatch(get_call_designer_data(lead_id));
    }
  };

  // const handleDelete_PTO_file = async (file) => {
  //   const reqData = {
  //     id: file.id,
  //   };
  //   dispatch(delete_pto_file(reqData));
  //   await dispatch(get_pto_data(lead_id));
  // };

  const handle_edit = async () => {
    const reqData = {
      id: cardStatus?.cardStatus?.data?.id,
      ticket_id: lead_id,
      comment: PTOData.comment,
      cost: PTOData.cost,
      status: PTOData.status,
      event_from: `${fromDate} ${fromTime}`,
      event_to: `${toDate} ${toTime}`,
      event: PTOData.event,
      estimated_time: PTOData.estimated_time,
      access_to_driveway: PTOData.access_to_driveway,
      will_be_at_home: PTOData.will_be_at_home,
      leave_the_product: PTOData.leave_the_product,
      date_time: `${final_inspection_Date} ${final_inspection_Time}`,
      customer_comments: PTOData.customer_comments,
      confirm_survey_time: PTOData.confirm_survey_time,
      home_during_installation: PTOData.home_during_installation,
      animals_outside_property: PTOData.animals_outside_property,
      is_confirmed: PTOData.is_confirmed,
      // time: sendTime.split(", ")[1],
    };

    if (type == "apply_pto") {
      let submitAction = edit__PTO_data;
      await dispatch(submitAction(reqData));
      await dispatch(get_pto_data(lead_id));
    } else if (type == "ntp_finance_portal") {
      let submitAction = edit__NTP_data;
      await dispatch(submitAction(reqData));
      dispatch(get_NTP_data(lead_id));
    } else if (type == "apply_meter_spot") {
      let submitAction = edit_meter_spot_data;
      await dispatch(submitAction(reqData));
      dispatch(get_meter_spot_data(lead_id));
    } else if (type == "products_quotes") {
      let submitAction = edit__quotes_for_products_data;
      await dispatch(submitAction(reqData));
      dispatch(get_quotes_for_products_data(lead_id));
    } else if (type == "order_product") {
      let submitAction = edit__product_orders_data;
      await dispatch(submitAction(reqData));
      dispatch(get_product_orders_data(lead_id));
    } else if (type == "upload_permit_to_finance") {
      let submitAction = edit__permit_to_finance_data;
      await dispatch(submitAction(reqData));
      dispatch(get_permit_to_finance_data(lead_id));
    } else if (type == "order_placards") {
      let submitAction = edit__order_place_cards_data;
      await dispatch(submitAction(reqData));
      dispatch(get_order_place_cards_data(lead_id));
    } else if (type == "coordinate_product_delivery") {
      let submitAction = edit__coordinate_product_delivery_data;
      await dispatch(submitAction(reqData));
      dispatch(get_coordinate_product_delivery_data(lead_id));
    } else if (type == "coordinate_client_installer_installation") {
      let submitAction = edit_coordinate_installations_data;
      await dispatch(submitAction(reqData));
      dispatch(get_coordinate_installations_data(lead_id));
    } else if (type == "schedule_inspection") {
      let submitAction = edit__schedule_inspections_data;
      await dispatch(submitAction(reqData));
      dispatch(get_schedule_inspections_data(lead_id));
    } else if (type == "invoice_for_installer") {
      let submitAction = edit__installer_invoices_data;
      await dispatch(submitAction(reqData));
      dispatch(get_installer_invoices_data(lead_id));
    } else if (type == "upload_to_pto") {
      let submitAction = edit__upload_to_pto_data;
      await dispatch(submitAction(reqData));
      dispatch(get_upload_to_pto_data(lead_id));
    } else if (type == "send_full_details_to_client") {
      let submitAction = edit__send_full_details_to_client_data;
      await dispatch(submitAction(reqData));
      dispatch(get_send_full_details_to_client_data(lead_id));
    } else if (type == "review_profit_loss_statement") {
      let submitAction = edit__review_profit_loss_statement_data;
      await dispatch(submitAction(reqData));
      dispatch(get_review_profit_loss_statement_data(lead_id));
    } else if (type == "closure_report") {
      let submitAction = edit__closure_report_data;
      await dispatch(submitAction(reqData));
      dispatch(get_closure_report_data(lead_id));
    } else if (type == "payment_collection") {
      let submitAction = edit__installation_payment_collection_data;
      await dispatch(submitAction(reqData));
      dispatch(get_installation_payment_collection_data(lead_id));
    } else if (type == "installer_commissions") {
      let submitAction = edit__installer_commissions_data;
      await dispatch(submitAction(reqData));
      dispatch(get_installer_commissions_data(lead_id));
    } else if (type == "upload_installation_pics_to_finance") {
      let submitAction = edit__installation_pics_data;
      await dispatch(submitAction(reqData));
      dispatch(get_installation_pics_data(lead_id));
    } else if (type == "confirm_installer_inspection") {
      let submitAction = edit__confirm_inspection_data;
      await dispatch(submitAction(reqData));
      dispatch(get_confirm_inspection_data(lead_id));
    } else if (type == "apply_all_required_permits") {
      let submitAction = edit__apply_permits_data;
      await dispatch(submitAction(reqData));
      dispatch(get_apply_permits_data(lead_id));
    } else if (type == "end_project_call") {
      let submitAction = edit__end_project_call_data;
      await dispatch(submitAction(reqData));
      dispatch(get_end_project_call_data(lead_id));
    } else if (type == "pto_to_finance") {
      let submitAction = edit__upload_PTO_to_finance_data;
      await dispatch(submitAction(reqData));
      dispatch(get_upload_PTO_to_finance_data(lead_id));
    } else if (type == "final_job_card") {
      let submitAction = edit__final_job_card_data;
      await dispatch(submitAction(reqData));
      dispatch(get_final_job_card_data(lead_id));
    } else if (type == "confirm_city_office_time_frame") {
      let submitAction = edit__schedule_with_city_office_data;
      await dispatch(submitAction(reqData));
      dispatch(get_schedule_with_city_office_data(lead_id));
    } else if (type == "inform_inspection_result") {
      let submitAction = edit__inform_inspection_result_data;
      await dispatch(submitAction(reqData));
      dispatch(get_inform_inspection_result_data(lead_id));
    } else if (type == "monitoring_optimizer") {
      let submitAction = edit__monitoring_optimizers_data;
      await dispatch(submitAction(reqData));
      dispatch(get_monitoring_optimizers_data(lead_id));
    } else if (type == "m1_collection") {
      let submitAction = edit__m1_collection_data;
      await dispatch(submitAction(reqData));
      dispatch(get_m1_collection_data(lead_id));
    } 
    else if (type == "m2_payment_collection") {
      let submitAction = edit__m2_collection_data;
      await dispatch(submitAction(reqData));
      dispatch(get_m2_collection_data(lead_id));
    }
    else if (type == "business_license") {
      dispatch(get_business_license(lead_id));
    } else if (type == "final_inspection") {
      let submitAction = edit__final_inspection_data;
      await dispatch(submitAction(reqData));
      dispatch(get_final_inspection_data(lead_id));
    } else if (type == "call_designer") {
      let submitAction = edit__call_designer_data;
      await dispatch(submitAction(reqData));
      dispatch(get_call_designer_data(lead_id));
    }

    await setOpenEditForm(false);
  };
  return (
    <Grid style={{ padding: "10px 27px" }}>
      <Grid>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {openEditForm && (
            <div style={{ display: "flex", flexDirection: "column" }}>
              {type !== "business_license" && (
                <>
                  <Grid>
                    <FormControl variant="outlined">
                      <InputLabel htmlFor="component-simple">status</InputLabel>
                      <Select
                        name="status"
                        style={{
                          width: "300px",
                          marginTop: "15px",
                          marginBottom: "15px",
                        }}
                        value={PTOData.status}
                        onChange={({ target: { name, value } }) =>
                          handlePTOChange(name, value)
                        }
                      >
                        <MenuItem value="completed">completed </MenuItem>
                        <MenuItem value="pending">pending</MenuItem>
                        {(type == "apply_pto" ||
                          type == "ntp_finance_portal" ||
                          type == "upload_permit_to_finance" ||
                          type == "order_placards" ||
                          type == "coordinate_product_delivery" ||
                          type == "coordinate_client_installer_installation" ||
                          type == "order_product" ||
                          type == "schedule_inspection" ||
                          type == "apply_all_required_permits" ||
                          type == "products_quotes") && (
                            <MenuItem value="in_process">in progress </MenuItem>
                          )}
                      </Select>
                    </FormControl>
                  </Grid>

                  {(type == "apply_pto" ||
                    type == "order_placards" ||
                    type == "order_product" ||
                    type == "apply_all_required_permits" ||
                    type == "m1_collection" ||
                    type == "m2_payment_collection"
                    ) && (
                      <TextField
                        style={{ width: "300px", marginTop: "50px" }}
                        name="cost"
                        label="Cost"
                        value={PTOData.cost}
                        onChange={({ target: { name, value } }) =>
                          handlePTOChange(name, value)
                        }
                      />
                    )}

                  {type == "call_designer" && (
                    <FormControl variant="outlined">
                      <InputLabel htmlFor="component-simple">
                        is Confirmed ?
                      </InputLabel>
                      <Select
                        name="is_confirmed"
                        style={{ width: "300px", marginTop: "15px" }}
                        value={PTOData.is_confirmed}
                        onChange={({ target: { name, value } }) =>
                          handlePTOChange(name, value)
                        }
                      >
                        <MenuItem value="1">yes </MenuItem>
                        <MenuItem value="0">no</MenuItem>
                      </Select>
                    </FormControl>
                  )}

                  <Grid mt={3}>
                    <TextField
                      multiline
                      rows={3}
                      style={{ width: "300px", marginTop: "50px" }}
                      name="comment"
                      value={PTOData.comment}
                      onChange={({ target: { name, value } }) =>
                        handlePTOChange(name, value)
                      }
                    />
                  </Grid>
                </>
              )}

              {type == "confirm_city_office_time_frame" && (
                <>
                  <Grid mt={2} mb={2}>
                    <FormControl variant="outlined" style={{ width: "300px" }}>
                      <LocalizationProvider
                        sx={{ with: "100%", color: "#414251" }}
                        dateAdapter={AdapterDateFns}
                      >
                        <DateTimePicker
                          label="From"
                          value={PTOData.event_from}
                          onChange={(e) =>
                            setPTOData({ ...PTOData, event_from: e })
                          }
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  </Grid>

                  <Grid>
                    <FormControl variant="outlined" style={{ width: "300px" }}>
                      <LocalizationProvider
                        sx={{ with: "100%", color: "#414251" }}
                        dateAdapter={AdapterDateFns}
                      >
                        <DateTimePicker
                          label="to"
                          value={PTOData.event_to}
                          onChange={(e) =>
                            setPTOData({ ...PTOData, event_to: e })
                          }
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  </Grid>
                </>
              )}

              {type == "final_inspection" && (
                <>
                  <Grid className="display" mt={6}>
                    <FormControl variant="outlined" style={{ width: "300px" }}>
                      <LocalizationProvider
                        sx={{ with: "100%", color: "#414251" }}
                        dateAdapter={AdapterDateFns}
                      >
                        <DateTimePicker
                          label="date and time"
                          value={PTOData.date_time}
                          onChange={(e) =>
                            setPTOData({ ...PTOData, date_time: e })
                          }
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  </Grid>
                </>
              )}

              {(type == "order_product" ||
                type == "coordinate_product_delivery" ||
                type == "coordinate_client_installer_installation" ||
                type == "schedule_inspection" ||
                type == "confirm_installer_inspection") && (
                  <Grid className="display" mt={6}>
                    <FormControl variant="outlined" style={{ width: "300px" }}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          style={{ width: "400px", marginTop: "15px" }}
                          variant="inline"
                          label="date"
                          format="dd MMM yyyy"
                          margin="normal"
                          value={PTOData.event}
                          name="event"
                          onChange={handleChangeDate}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  </Grid>
                )}
              {type == "coordinate_product_delivery" && (
                <>
                  <Grid className="display" mt={2}>
                    <FormControl variant="outlined" style={{ width: "300px" }}>
                      <InputLabel htmlFor="component-simple">
                        Time Estimation
                      </InputLabel>

                      <TextField
                        multiline
                        rows={1}
                        style={{ width: "300px", marginTop: "50px" }}
                        name="estimated_time"
                        value={PTOData.estimated_time}
                        onChange={({ target: { name, value } }) =>
                          handlePTOChange(name, value)
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid className="display" mt={2}>
                    <FormControl variant="outlined" style={{ width: "300px" }}>
                      <InputLabel htmlFor="component-simple">
                        Do you have access to your driveWay
                      </InputLabel>
                      <Select
                        name="access_to_driveway"
                        style={{ width: "300px", marginTop: "15px" }}
                        value={PTOData.access_to_driveway}
                        onChange={({ target: { name, value } }) =>
                          handlePTOChange(name, value)
                        }
                      >
                        <MenuItem value="1">Yes </MenuItem>
                        <MenuItem value="0">No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid className="display" mt={2}>
                    <FormControl variant="outlined" style={{ width: "300px" }}>
                      <InputLabel htmlFor="component-simple">
                        will you be at home to sign off
                      </InputLabel>
                      <Select
                        name="will_be_at_home"
                        style={{ width: "300px", marginTop: "15px" }}
                        value={PTOData.will_be_at_home}
                        onChange={({ target: { name, value } }) =>
                          handlePTOChange(name, value)
                        }
                      >
                        <MenuItem value={1}>Yes </MenuItem>
                        <MenuItem value={0}>No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid className="display" mt={2}>
                    <FormControl variant="outlined" style={{ width: "300px" }}>
                      <InputLabel htmlFor="component-simple">
                        Is it ok with you to leave the product
                      </InputLabel>
                      <Select
                        name="leave_the_product"
                        style={{ width: "300px", marginTop: "15px" }}
                        value={PTOData.leave_the_product}
                        onChange={({ target: { name, value } }) =>
                          handlePTOChange(name, value)
                        }
                      >
                        <MenuItem value="1">Yes </MenuItem>
                        <MenuItem value="0">No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              )}

              {type == "coordinate_client_installer_installation" && (
                <>
                  <Grid className="display" mt={6}>
                    <FormControl variant="outlined" style={{ width: "300px" }}>
                      {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          value={timePickerValue}
                          label="Time"
                          onChange={(newValue) => setTimePickerValue(newValue)}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider> */}
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                      // adapterLocale={locale}
                      >
                        <TimePicker
                          value={timePickerValue}
                          onChange={(newValue) => setTimePickerValue(newValue)}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  </Grid>
                  <Grid className="display" mt={2}>
                    <FormControl variant="outlined">
                      <InputLabel htmlFor="component-simple">
                        Survey Time Confirmation ?
                      </InputLabel>
                      <Select
                        name="confirm_survey_time"
                        style={{ width: "300px", marginTop: "15px" }}
                        value={PTOData.confirm_survey_time}
                        onChange={({ target: { name, value } }) =>
                          handlePTOChange(name, value)
                        }
                      >
                        <MenuItem value="1">Yes </MenuItem>
                        <MenuItem value="0">No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid className="display" mt={2}>
                    <FormControl variant="outlined">
                      <InputLabel htmlFor="component-simple">
                        Home During Installation ?
                      </InputLabel>
                      <Select
                        name="home_during_installation"
                        style={{ width: "300px", marginTop: "15px" }}
                        value={PTOData.home_during_installation}
                        onChange={({ target: { name, value } }) =>
                          handlePTOChange(name, value)
                        }
                      >
                        <MenuItem value="1">Yes </MenuItem>
                        <MenuItem value="0">No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid className="display" mt={2}>
                    <FormControl variant="outlined">
                      <InputLabel htmlFor="component-simple">
                        animals outside ?
                      </InputLabel>
                      <Select
                        name="animals_outside_property"
                        style={{ width: "300px", marginTop: "15px" }}
                        value={PTOData.animals_outside_property}
                        onChange={({ target: { name, value } }) =>
                          handlePTOChange(name, value)
                        }
                      >
                        <MenuItem value="1">Yes </MenuItem>
                        <MenuItem value="0">No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid className="display">
                    <FormControl variant="outlined">
                      <InputLabel htmlFor="component-simple">
                        Customer Comments
                      </InputLabel>
                      <TextField
                        multiline
                        rows={2}
                        style={{ width: "300px", marginTop: "50px" }}
                        name="customer_comments"
                        value={PTOData.customer_comments}
                        onChange={({ target: { name, value } }) =>
                          handlePTOChange(name, value)
                        }
                      />
                    </FormControl>
                  </Grid>
                </>
              )}

              {type == "business_license" && (
                <Grid className="display">
                  <FormControl variant="outlined">
                    <InputLabel htmlFor="component-simple">status</InputLabel>

                    <Select
                      style={{ width: "300px", marginTop: "15px" }}
                      value={businessLicenseStatus}
                      onChange={(e) => setBusinessLicenseData(e.target.value)}
                    >
                      <MenuItem value="portal">portal </MenuItem>
                      <MenuItem value="in_person">in person</MenuItem>
                      <MenuItem value="have_license">have license</MenuItem>
                      <MenuItem value="no_have_license">
                        no have license
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              )}

              <PrimaryButton
                fullWidth
                style={{
                  padding: "0",
                  width: "85%",
                  margin: "15px auto",
                  height: "42px",
                }}
                className="saveButton"
                onClick={handle_edit}
              >
                Updatee
              </PrimaryButton>
            </div>
          )}

          {cardStatus?.isLodaing ? (
            <p>loading ..... </p>
          ) : (
            <>
              {openCardData && !openEditForm && (
                <div style={{ padding: "10px 5px" }}>
                  {cardStatus?.cardStatus == null && (
                    !type.includes("surveyor") &&
                    type !== "welcome_call" &&
                    type !== "business_license" &&
                    type !== "call_city_utility" &&
                    type !== "call_supplier" &&
                    type !== "call_utility_companies" &&
                    type !== "job_review" &&
                    type !== "confirm_items_with_sales_and_customer" &&
                    type !== "call_jurisdiction" &&
                    type !== "check_product_availability" &&
                    type !== "call_city_office" &&
                    type !== "extra_adders" &&
                    <Typography align="center" variant="h6">
                      there is no data for this card
                    </Typography>
                  )}
                  {cardStatus?.cardStatus?.data?.comment &&
                    type !== "welcome_call" &&
                    type !== "business_license" &&
                    !type.includes("surveyor") &&
                    type !== "call_city_utility" &&
                    type !== "call_supplier" &&
                    type !== "call_utility_companies" &&
                    type !== "job_review" &&
                    type !== "confirm_items_with_sales_and_customer" &&
                    type !== "call_jurisdiction" &&
                    type !== "call_city_office" &&
                    type !== "check_product_availability" &&
                    type !== "extra_adders" &&
                    (
                      <Grid className="display" mb={1}>
                        <Typography>
                          {" "}
                          Comment:
                          <span style={{ color: "gray", fontWeight: "bold" }}>
                            {cardStatus?.cardStatus?.data?.comment}
                          </span>{" "}
                        </Typography>
                      </Grid>
                    )}

                  {((cardStatus?.cardStatus?.data?.cost) &&
                    (type == "apply_pto" ||
                    type == "order_placards" ||
                    type == "order_product" ||
                    type == "apply_all_required_permits" ||
                    type == "m1_collection" ||
                    type == "m2_payment_collection")
                    ) && (
                      <Grid className="display" mb={1}>
                      <Typography>
                        {" "}
                        Cost:
                        <span style={{ color: "gray", fontWeight: "bold" }}>
                          {cardStatus?.cardStatus?.data?.cost}
                      
                        </span>{" "}
                      </Typography>
                    </Grid>

                    )}

                  {cardStatus?.cardStatus?.data?.status &&
                    type !== "welcome_call" &&
                    type !== "business_license" &&
                    !type.includes("surveyor") &&
                    type !== "call_city_utility" &&
                    type !== "call_supplier" &&
                    type !== "call_utility_companies" &&
                    type !== "job_review" &&
                    type !== "confirm_items_with_sales_and_customer" &&
                    type !== "call_jurisdiction" &&
                    type !== "check_product_availability" &&
                    type !== "call_city_office" &&
                    type !== "extra_adders" &&
                    (
                      <Grid mb={1}>
                        <Typography>
                          {" "}
                          status:
                          <span style={{ color: "gray", fontWeight: "bold" }}>
                            {cardStatus?.cardStatus?.data?.status}
                          </span>{" "}
                        </Typography>
                      </Grid>
                    )}
                  {(cardStatus?.cardStatus?.data?.is_confirmed == 1 ||
                    cardStatus?.cardStatus?.data?.is_confirmed == 0) &&
                    type == "call_designer" && (
                      <Grid mb={1}>
                        <Typography>
                          {" "}
                          is confirmed:
                          <span style={{ color: "gray", fontWeight: "bold" }}>
                            {cardStatus?.cardStatus?.data?.is_confirmed == 1
                              ? "yes"
                              : "no"}
                          </span>{" "}
                        </Typography>
                      </Grid>
                    )}
                  {cardStatus?.cardStatus?.data?.event &&
                    (type !== "welcome_call" &&(type == "order_product" ||
                    type == "coordinate_product_delivery" ||
                    type == "coordinate_client_installer_installation" ||
                    type == "schedule_inspection" ||
                    type == "confirm_installer_inspection") &&   <Grid mb={1}>
                        <Typography>
                          {" "}
                          Date:
                          <span style={{ color: "gray", fontWeight: "bold" }}>
                            {cardStatus?.cardStatus?.data?.event}
                          </span>{" "}
                        </Typography>
                      </Grid>
                    )}
                  {cardStatus?.cardStatus?.data?.event_from &&
                    type !== "welcome_call" &&
                    !type.includes("surveyor") &&
                    type !== "call_city_utility" &&
                    type !== "call_supplier" &&
                    type !== "call_utility_companies" &&
                    type !== "job_review" &&
                    type !== "confirm_items_with_sales_and_customer" &&
                    type !== "call_jurisdiction" &&
                    type !== "check_product_availability" &&
                    type !== "call_city_office" &&
                    type !== "extra_adders" && (
                      <Grid className="display" mb={1}>
                        <Typography>
                          {" "}
                          event from:
                          <span style={{ color: "gray", fontWeight: "bold" }}>
                            {cardStatus?.cardStatus?.data?.event_from}
                          </span>{" "}
                        </Typography>
                      </Grid>
                    )}
                  {cardStatus?.cardStatus?.data?.event_to &&
                    type !== "welcome_call" &&
                    !type.includes("surveyor") &&
                    type !== "call_city_utility" &&
                    type !== "call_supplier" &&
                    type !== "call_utility_companies" &&
                    type !== "job_review" &&
                    type !== "confirm_items_with_sales_and_customer" &&
                    type !== "call_jurisdiction" &&
                    type !== "check_product_availability" &&
                    type !== "extra_adders" &&
                    type !== "call_city_office" &&
                    (
                      <Grid className="display">
                        <Typography>
                          {" "}
                          event to:
                          <span style={{ color: "gray", fontWeight: "bold" }}>
                            {cardStatus?.cardStatus?.data?.event_to}
                          </span>{" "}
                        </Typography>
                      </Grid>
                    )}
                  {cardStatus?.cardStatus?.data?.date_time &&
                    type !== "welcome_call" &&
                    !type.includes("surveyor") &&
                    type !== "call_city_utility" &&
                    type !== "call_supplier" &&
                    type !== "call_utility_companies" &&
                    type !== "job_review" &&
                    type !== "confirm_items_with_sales_and_customer" &&
                    type !== "call_jurisdiction" &&
                    type !== "check_product_availability" &&
                    type !== "extra_adders" &&
                    type !== "call_city_office" &&
                    (
                      <Grid className="display" mb={1}>
                        <Typography>
                          {" "}
                          event from:
                          <span style={{ color: "gray", fontWeight: "bold" }}>
                            {cardStatus?.cardStatus?.data?.date_time}
                          </span>{" "}
                        </Typography>
                      </Grid>
                    )}
                  {cardStatus?.cardStatus?.data?.estimated_time &&
                    type !== "welcome_call" &&
                    !type.includes("surveyor") &&
                    type !== "call_city_utility" &&
                    type !== "call_supplier" &&
                    type !== "call_utility_companies" &&
                    type !== "job_review" &&
                    type !== "confirm_items_with_sales_and_customer" &&
                    type !== "call_jurisdiction" &&
                    type !== "check_product_availability" &&
                    type !== "extra_adders" &&
                    type !== "call_city_office" &&
                    (
                      <Grid className="display">
                        <Typography>
                          {" "}
                          Time Estimation :
                          <span style={{ color: "gray", fontWeight: "bold" }}>
                            {cardStatus?.cardStatus?.data?.estimated_time}
                          </span>{" "}
                        </Typography>
                      </Grid>
                    )}
                  {(cardStatus?.cardStatus?.data?.leave_the_product == 1 ||
                    cardStatus?.cardStatus?.data?.leave_the_product == 0) &&
                    type !== "welcome_call" &&
                    !type.includes("surveyor") &&
                    type !== "call_city_utility" &&
                    type !== "call_supplier" &&
                    type !== "call_utility_companies" &&
                    type !== "job_review" &&
                    type !== "confirm_items_with_sales_and_customer" &&
                    type !== "call_jurisdiction" &&
                    type !== "check_product_availability" &&
                    type !== "extra_adders" &&
                    type !== "call_city_office" &&
                    (
                      <Grid className="display">
                        <Typography>
                          {" "}
                          Leave the product :
                          <span style={{ color: "gray", fontWeight: "bold" }}>
                            {cardStatus?.cardStatus?.data?.leave_the_product ==
                              1
                              ? "Yes"
                              : "No"}
                          </span>{" "}
                        </Typography>
                      </Grid>
                    )}
                  {(cardStatus?.cardStatus?.data?.will_be_at_home == 1 ||
                    cardStatus?.cardStatus?.data?.will_be_at_home == 0) &&
                    type !== "welcome_call" &&
                    !type.includes("surveyor") &&
                    type !== "call_city_utility" &&
                    type !== "call_supplier" &&
                    type !== "call_utility_companies" &&
                    type !== "job_review" &&
                    type !== "confirm_items_with_sales_and_customer" &&
                    type !== "call_jurisdiction" &&
                    type !== "check_product_availability" &&
                    type !== "extra_adders" &&
                    type !== "call_city_office" &&
                    (
                      <Grid className="display">
                        <Typography>
                          {" "}
                          will be at home :
                          <span style={{ color: "gray", fontWeight: "bold" }}>
                            {cardStatus?.cardStatus?.data?.will_be_at_home == 1
                              ? "Yes"
                              : "No"}
                          </span>{" "}
                        </Typography>
                      </Grid>
                    )}
                  {(cardStatus?.cardStatus?.data?.access_to_driveway == 1 ||
                    cardStatus?.cardStatus?.data?.access_to_driveway == 0) &&
                    type !== "welcome_call" &&
                    !type.includes("surveyor") &&
                    type !== "call_city_utility" &&
                    type !== "call_supplier" &&
                    type !== "call_utility_companies" &&
                    type !== "job_review" &&
                    type !== "confirm_items_with_sales_and_customer" &&
                    type !== "call_jurisdiction" &&
                    type !== "check_product_availability" &&
                    type !== "extra_adders" &&
                    type !== "call_city_office" &&
                    (
                      <Grid className="display">
                        <Typography>
                          {" "}
                          access to driveway :
                          <span style={{ color: "gray", fontWeight: "bold" }}>
                            {cardStatus?.cardStatus?.data?.access_to_driveway ==
                              1
                              ? "Yes"
                              : "No"}
                          </span>{" "}
                        </Typography>
                      </Grid>
                    )}
                  {/* coordinate_client_installer_installation data  */}
                  {(cardStatus?.cardStatus?.data?.confirm_survey_time == 1 ||
                    cardStatus?.cardStatus?.data?.confirm_survey_time == 0) &&
                    type !== "welcome_call" &&
                    !type.includes("surveyor") &&
                    type !== "call_city_utility" &&
                    type !== "call_supplier" &&
                    type !== "call_utility_companies" &&
                    type !== "job_review" &&
                    type !== "confirm_items_with_sales_and_customer" &&
                    type !== "call_jurisdiction" &&
                    type !== "check_product_availability" &&
                    type !== "extra_adders" &&
                    type !== "call_city_office" &&
                    (
                      <Grid className="display">
                        <Typography>
                          {" "}
                          confirm survey time :
                          <span style={{ color: "gray", fontWeight: "bold" }}>
                            {cardStatus?.cardStatus?.data
                              ?.confirm_survey_time == 1
                              ? "Yes"
                              : "No"}
                          </span>{" "}
                        </Typography>
                      </Grid>
                    )}
                  {(cardStatus?.cardStatus?.data?.home_during_installation ==
                    1 ||
                    cardStatus?.cardStatus?.data?.home_during_installation ==
                    0) &&
                    type !== "welcome_call" &&
                    !type.includes("surveyor") &&
                    type !== "call_city_utility" &&
                    type !== "call_supplier" &&
                    type !== "call_utility_companies" &&
                    type !== "job_review" &&
                    type !== "confirm_items_with_sales_and_customer" &&
                    type !== "call_jurisdiction" &&
                    type !== "check_product_availability" &&
                    type !== "extra_adders" &&
                    type !== "call_city_office" &&
                    (
                      <Grid className="display">
                        <Typography>
                          {" "}
                          home during installation :
                          <span style={{ color: "gray", fontWeight: "bold" }}>
                            {cardStatus?.cardStatus?.data
                              ?.home_during_installation == 1
                              ? "Yes"
                              : "No"}
                          </span>{" "}
                        </Typography>
                      </Grid>
                    )}
                  {(cardStatus?.cardStatus?.data?.animals_outside_property ==
                    1 ||
                    cardStatus?.cardStatus?.data?.animals_outside_property ==
                    0) &&
                    type !== "welcome_call" &&
                    !type.includes("surveyor") &&
                    type !== "call_city_utility" &&
                    type !== "call_supplier" &&
                    type !== "call_utility_companies" &&
                    type !== "job_review" &&
                    type !== "confirm_items_with_sales_and_customer" &&
                    type !== "call_jurisdiction" &&
                    type !== "check_product_availability" &&
                    type !== "extra_adders" &&
                    type !== "call_city_office" &&
                    (
                      <Grid className="display">
                        <Typography>
                          {" "}
                          animals outside property :
                          <span style={{ color: "gray", fontWeight: "bold" }}>
                            {cardStatus?.cardStatus?.data
                              ?.animals_outside_property == 1
                              ? "Yes"
                              : "No"}
                          </span>{" "}
                        </Typography>
                      </Grid>
                    )}
                  {cardStatus?.cardStatus?.data?.customer_comments &&
                    type !== "welcome_call" &&
                    type !== "business_license" &&
                    !type.includes("surveyor") &&
                    type !== "call_city_utility" &&
                    type !== "call_supplier" &&
                    type !== "call_utility_companies" &&
                    type !== "job_review" &&
                    type !== "confirm_items_with_sales_and_customer" &&
                    type !== "call_jurisdiction" &&
                    type !== "check_product_availability" &&
                    type !== "extra_adders" &&
                    type !== "call_city_office" &&
                    (
                      <Grid className="display" mb={1}>
                        <Typography>
                          {" "}
                          customer comments:
                          <span style={{ color: "gray", fontWeight: "bold" }}>
                            {cardStatus?.cardStatus?.data?.customer_comments}
                          </span>{" "}
                        </Typography>
                      </Grid>
                    )}

                  {cardStatus?.cardStatus?.data?.time &&
                    type !== "welcome_call" &&
                    type !== "business_license" &&
                    !type.includes("surveyor") &&
                    type !== "call_city_utility" &&
                    type !== "call_supplier" &&
                    type !== "call_utility_companies" &&
                    type !== "job_review" &&
                    type !== "confirm_items_with_sales_and_customer" &&
                    type !== "call_jurisdiction" &&
                    type !== "check_product_availability" &&
                    type !== "extra_adders" &&
                    type !== "call_city_office" &&
                    (
                      <Grid className="display" mb={1}>
                        <Typography>
                          {" "}
                          time:
                          <span style={{ color: "gray", fontWeight: "bold" }}>
                            {cardStatus?.cardStatus?.data?.time}
                          </span>{" "}
                        </Typography>
                      </Grid>
                    )}

                </div>
              )}

              {!openEditForm &&
                business_license_Data.business_license !== null &&
                cardStatus?.cardStatus !== null &&
                type !== "welcome_call" &&
                !type.includes("surveyor") &&
                type !== "call_city_utility" &&
                type !== "call_supplier" &&
                type !== "call_utility_companies" &&
                type !== "job_review" &&
                type !== "confirm_items_with_sales_and_customer" &&
                type !== "call_jurisdiction" &&
                type !== "check_product_availability" &&
                type !== "extra_adders" &&
                type !== "business_license" &&
                type !== "call_city_office" &&
                (cardStatus?.cardStatus?.data?.status ||
                  cardStatus?.cardStatus?.data?.comment ||
                  (type !== "business_license" &&
                    business_license_Data?.business_license?.data?.status)) ? (
                <IconButton onClick={handleShowEditForm}>
                  <EditIcon />
                </IconButton>
              ) : (
                (cardStatus?.cardStatus?.data?.status ||
                  cardStatus?.cardStatus?.data?.comment ||
                  (type !== "business_license" &&
                    business_license_Data?.business_license?.data?.status)) &&
                    cardStatus?.cardStatus !== null &&
                type !== "welcome_call" &&
                !type.includes("surveyor") &&
                type !== "call_city_utility" &&
                type !== "call_supplier" &&
                type !== "call_utility_companies" &&
                type !== "job_review" &&
                type !== "confirm_items_with_sales_and_customer" &&
                type !== "call_jurisdiction" &&
                type !== "check_product_availability" &&
                type !== "extra_adders" &&
                type !== "business_license" &&
                type !== "call_city_office" &&
                business_license_Data.business_license !== null && (
                  <IconButton onClick={closeEditForm}>
                    <CloseIcon />
                  </IconButton>
                )
              )}
            </>
          )}
        </div>

        {cardStatus?.isLodaing ? (
          <p></p>
        ) :(
   <List>
   {cardStatus?.cardStatus?.data?.media?.length > 0 && 
   (!type.includes("surveyor") &&
   type !== "welcome_call" &&
   type !== "business_license" &&
   type !== "call_city_utility" &&
   type !== "call_supplier" &&
   type !== "call_utility_companies" &&
   type !== "job_review" &&
   type !== "confirm_items_with_sales_and_customer" &&
   type !== "call_jurisdiction" &&
   type !== "check_product_availability" &&
   type !== "extra_adders" &&
   type !== "call_city_office" 
   ) && (
     <Typography variant="h6" align="center" mt={5}>
       Uploaded Files
     </Typography>
   )}

   {type !== "welcome_call" &&
     type !== "business_license" &&
     !type.includes("surveyor") &&
     type !== "call_city_utility" &&
     type !== "call_utility_companies" &&
     type !== "job_review" &&
     type !== "confirm_items_with_sales_and_customer" &&
     type !== "call_jurisdiction" &&
     type !== "extra_adders" &&
     type !== "call_supplier" &&
     type !== "check_product_availability" &&
     type !== "call_city_office" &&
     cardStatus?.cardStatus?.data?.media?.length > 0 ? (
     cardStatus?.cardStatus?.data?.media?.map((file) => (
       <>
         <ListItem
           secondaryAction={
             <IconButton
               edge="end"
               aria-label="delete"
               onClick={() => handle_delete_file(file)}
             >
               <DeleteIcon />
             </IconButton>
           }
         >
           <ListItemAvatar>
             <a href={file.path} target="_blank">
               <Avatar>
                 <FolderIcon />
               </Avatar>
             </a>
           </ListItemAvatar>
           <ListItemText
             primary="View File"
           // secondary={"Secondary text"}
           />
         </ListItem>
       </>
     ))
   ) : (
     cardStatus?.isLodaing ? (
       <p></p>
     ):(
       
       <Typography variant="h6" align="center" mt={5}>
       {
       cardStatus?.cardStatus !== null &&
       !type.includes("surveyor") &&
         type !== "welcome_call" &&
         type !== "business_license" &&
         type !== "call_city_utility" &&
         type !== "call_supplier" &&
         type !== "call_utility_companies" &&
         type !== "job_review" &&
         type !== "confirm_items_with_sales_and_customer" &&
         type !== "call_jurisdiction" &&
         type !== "extra_adders" &&
         type !== "check_product_availability" &&
         type !== "call_city_office" &&
         "no uploaded files" 
         &&
         <UploadMediaForCard  type={type} cardStatus={cardStatus} lead_id={lead_id}/>
         
         
         }
     </Typography>
     )
    
   )}
 </List>
        )}
     



        {
          type !== "welcome_call" &&
          type == "business_license" &&
          !type.includes("surveyor") &&
          type !== "call_city_utility" &&
          type !== "call_supplier" &&
          type !== "call_utility_companies" &&
          type !== "job_review" &&
          type !== "confirm_items_with_sales_and_customer" &&
          type !== "call_jurisdiction" &&
          type !== "extra_adders" &&
          type !== "check_product_availability" &&
          (
            <Grid mb={1}>
              <BusinessLicenseData business_license_Data={business_license_Data} ticket_status={ticket_status} />
            </Grid>
          )}

        {type == "call_supplier" && <CallSupplierDetails lead_id={lead_id} />}
        {type == "confirm_items_with_sales_and_customer" && (
          <ConfirmWithSalesAndCustomerDetails />
        )}
        {type == "job_review" && (
          <JobReviewsWithSalesDetails lead_id={lead_id} />
        )}
        {type.includes("surveyor") && (
          <SurveyStepsDetails lead_id={lead_id} type={type} />
        )}

        {type == "call_jurisdiction" && (
          <Call_jurisdiction_Details lead_id={lead_id} />
        )}

        {type == "call_utility_companies" && (
          <CallUtilityCompany lead_id={lead_id} />
        )}

        {(type == "call_city_office" || type == "call_city_utility") && (
            <CallCityOffice lead_id={lead_id} type={type} />
          )}

        {type == "welcome_call" && welcomCallDetails?.WelcomeCall !== null && (
          <WelcomeCallDetails welcomCallDetails={welcomCallDetails} />
        )}

        {type == "welcome_call" && welcomCallDetails?.WelcomeCall == null && (
          <Grid>
            <Typography align="center" variant="h6">
              There is no welcome call details for this ticket
            </Typography>
          </Grid>
        )}


        {type == "extra_adders" && (
          <ExtraAddersDetails lead_id={lead_id} />

        )}

        {type == "check_product_availability" && productAvailability_Data?.productAvailability !== null && (
       
          <ProductAvailabilityDetails  lead_id={lead_id}/>
        )}
      </Grid>
    </Grid>
  );
};

export default CardStatusDetails;
