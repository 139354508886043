import React from "react";
import { Field } from "redux-form";
import MuiTextField from "../../../Common/muiTextField/MuiTextField";
import MuiSelectField from "../../../Common/MuiSelectField/MuiSelectField";
import { MenuItem } from "@material-ui/core";

const exportedObject = {
  selectField: (field, label) => (
    <Field required name={`${field.type}_${field.key}`} label={label} component={MuiSelectField}>
      {field?.values.length > 0 ? (
        field?.values.map((option, index) => (
          <MenuItem key={index} value={option.key}>
            {option.key}
          </MenuItem>
        ))
      ) : (
        <MenuItem disabled={true} value={"none"}>
         noChoices
        </MenuItem>
      )}
    </Field>
  ),
  textField: (field, label) => (
    <Field
      required
      type={(field.type == "TextInput"  || field.type == "TextArea") ? "text" : field.type == "NumberInput" ? "number" : "date"}
      name={`${field.type}_${field.key}`}
      label={label}
      placeholder="*"
      multiline={field.type == "TextArea"}
      rows={field.type == "TextArea"  && 4 }
      component={MuiTextField}
   
    />
  ),
};

export default exportedObject;

