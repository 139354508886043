import React from "react";
import Header from "../../Components/Header/Header";
import styled from "styled-components";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../Redux/Slices/AuthSlice/AuthSlice";

import "react-toastify/dist/ReactToastify.css";
function Home() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const SignOut = async () => {
    await dispatch(logout());
    navigate("/");
  };

  return (
    <>
      <Header />
    
      <GridBox
        style={{
          backgroundColor: `${
            location.pathname === "/home/CreateCharter" ||
            location.pathname === "/home"
              ? "white"
              : "#f3f3f3"
          }`,
        }}
      >
      
        <Outlet></Outlet>
       
      </GridBox>
    </>
  );
}

const GridBox = styled.section`
  padding: 2rem 2rem 0 2rem;
  min-height: 94vh;
`;

export default Home;
