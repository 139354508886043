import React, { useState, useEffect } from "react";
import { CardActions, Grid, Button, CircularProgress } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CheckIcon from "@mui/icons-material/Check";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import Tooltip from "@mui/material/Tooltip";
import LanIcon from "@mui/icons-material/Lan";
import AddIssueForm from "../Modals/AddIssueFormModal/AddIssueForm";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from "react-router-dom";
import { sendWelcomePackage } from "../../Redux/Slices/SendWelcomePackage/SendWelcomePackageSlice";
import { sendUtilityBill } from "../../Redux/Slices/UtiltiyBillSlice/UtilityBillSlice";
import { CardContainer } from "./ProcessSteperStyle";
import { PrimaryButton } from "../../Layouts/ButtonsControlled";
import StatusFileCommentModalForCards from "../../Components/Modals/StatusFileCommentModalForCard/StatusFileCommentForCards";
import WelcomCallModal from "../../Components/Modals/WelcomeCallModal/WelcomeCallModal";
import BusinessLicenseModal from "../../Components/Modals/BusinessLicenseModal/BusinessLicenseModal";
import ProductAvailabilityModal from "../../Components/Modals/ProductAvailabilityModal/ProductAvailabilityModal";
import SendCustomerPlanModal from "../../Components/Modals/SendCustomerPlanModal/SendCustomerPlanModal";
import ExtraAddersModal from "../../Components/Modals/ExtraAddersModal/ExtraAddersModal";
import NewExtraAdders from "../../Components/Modals/NewExtraAdders/NewExtraAdders"
import CallSupplierModal from "../../Components/Modals/CallSupplierModal/CallSupplierModal";
import CallUtilityCompanyModal from "../../Components/Modals/CallUtilityCompany/CallUtilityCompany";
import JobReviewWithSales from "../../Components/Modals/jobReviewWithSales/JobReviewWithSales";
import ConfirmWithSalesAndCustomer from "../../Components/Modals/ConfirmWithSalesAndCustomerModal/ConfirmWithSalesAndCustomer";
import FirstStepOfSurveyModal from "../../Components/Modals/SurveyModals/FirstStepOfSurveyModal";
import SecondStepOfSurveyModal from "../../Components/Modals/SurveyModals/SecondStepOfSurveyModal";
import QualityControlModal from "../../Components/Modals/SurveyModals/QualityControlModal";
import CalljurisdictionsModal from "../../Components/Modals/call-jurisdictions-Modal/call-jurisdictions-Modal";
import CallCityofficeutilityModal from "../../Components/Modals/Call_City_office_utility_Modal/CallCityofficeutilityModal";
import SiteSurveyModal from "../../Components/Modals/SiteSurveyModal/SiteSurveyModal";
import TransferCardModal from "../../Components/Modals/TransferCardModal/TransferCardModal";
import { useDispatch, useSelector } from "react-redux";
import { getTicketStatus } from "../../Redux/Slices/TicketStatus/TicketStatusSlice";
import { getSingleCard } from "../../Redux/Slices/CreateCard/CreateCardSlice";
import NewCardFieldsModal from "../../Components/Modals/new_cards_fields/NewCardsFields";
import { DeleteCardAcion } from "../../Redux/Slices/CreateCard/CreateCardSlice";
import {getCardsOrder,} from "../../Redux/Slices/CardsListSlice/CardsListSlice";
import DeleteCardModal from "../../Components/Modals/DeleteCardModal/DeleteCard"

const CardData = ({
  ReviewProfitLossStatment_status,
  lead_id,
  pm_status_id,
  card,
  card_status,
  handleUpdate_Receiving_Projectcards,
  Receiving_Projectcards,
  Receiving_Project,
}) => {
  const sendWelcomePackageState = useSelector(
    (state) => state?.sendWelcomePackage
  );
  const TicketStatus = useSelector((state) => state.TicketStatus);
  const single_card_fields_data = useSelector((state) => state.create_card);
  const cardsOrder = useSelector((state) => state.cardsOrder);
  const ticket_status = TicketStatus?.TicketStatus?.data
  // console.log(single_card_fields_data?.create_card?.data?.forms, "single_card_fields_data")

  let fieldsData = {};

  // single_card_fields_data?.create_card?.data?.forms?.length > 0 &&
  //   single_card_fields_data?.create_card?.data?.forms?.map((field) =>

  //     fieldsData[`${field.type}_${field.key}`] = field.value ? field.value : ""
  //   )
  // console.log(!isObjEmpty(fieldsData) && fieldsData, "fieldsData1223");

  function isObjEmpty(obj) {
    return Object.keys(obj).length == 0;
  }

  const utilitybillState = useSelector((state) => state?.sendUtilityBill);
  const [openNewCardsFieldsModal, setOpenNewCardsFieldsModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [transferCard, setTransferCard] = useState(false);
  const [selectedCard, set_selectedCard] = useState();
  const [openAddIssueForm, setAddIssueForm] = useState(false);
  const [openStatusFilesCommentModal, setOpenStatusFilesCommentModal] =
    useState(false);
  const [openWelcomeCallModal, setOpenWelcomeCallModal] = useState(false);
  const [openBusinessLicenseModal, setOpenBusinessLicenseModal] =
    useState(false);
  const [openProductAvailabilityModal, setOpenProductAvailabilityModal] =
    useState(false);
  const [openSendCustomerPlanModal, setOpenSendCustomerPlanModal] =
    useState(false);
  const [openAddersFormModal, setOpenAddersFormModal] = useState(false);
  const [open_Call_supplier_Modal, set_Open_Call_supplier_Modal] =
    useState(false);
  const [open_CallUtilityCompany_Modal, set_Open_CallUtilityCompany_Modal] =
    useState(false);
  const [
    open_job_review_with_sales_Modal,
    set_Open_job_review_with_sales_Modal,
  ] = useState(false);
  const [
    open_confirm_items_with_sales_and_customer_Modal,
    set_Open_confirm_items_with_sales_and_customer_Modal,
  ] = useState(false);

  const [open_first_step_of_survey_Modal, set_open_first_step_of_survey_Modal] =
    useState(false);

  const [
    open_second_step_of_survey_Modal,
    set_open_second_step_of_survey_Modal,
  ] = useState(false);

  const [
    open_quality_control_step_of_survey_Modal,
    set_open_quality_control_step_of_survey_Modal,
  ] = useState(false);
  const handleopenconfirm_items_with_sales_and_customerModal = () => {
    set_Open_confirm_items_with_sales_and_customer_Modal(true);
  };

  const handleCloseconfirm_items_with_sales_and_customerModal = () => {
    set_Open_confirm_items_with_sales_and_customer_Modal(false);
  };

  const [open_call_jurisdiction_Modal, set_open_call_jurisdiction_Modal] =
    useState(false);

  const [
    open_Call_City_office_utility_Modal,
    set_open_Call_City_office_utility_Modal,
  ] = useState(false);

  const [openDeleteCardModal, setOpenDeleteCardModal] = React.useState(false);

  const handleClickOpenDeleteCardModal = (card) => {
    setOpenDeleteCardModal(true);
    set_selectedCard(card);
  };
  const [open_site_survey_Modal, set_open_site_survey_Modal] = useState(false);
  const type = card?.key;
  const title = card?.title;

  // useEffect(() => {
  //   card.card_id > 0 && dispatch(getSingleCard(card.card_id));

  // }, []);

  const openTransferCardtModal = (card) => {
    setTransferCard(true);
    set_selectedCard(card);
  };

  const handleCloseTransferCardtModal = () => {
    setTransferCard(false);
  };

  const handleClosesite_survey_Modal = () => {
    set_open_site_survey_Modal(false);
  };

  const handleCloseCall_City_office_utility_Modal = () => {
    set_open_Call_City_office_utility_Modal(false);
  };

  const handleCloseStatusFilesCommentModal = () => {
    setOpenStatusFilesCommentModal(false);
  };
  const handleCloseWelcomeCallModal = () => setOpenWelcomeCallModal(false);

  const handleCloseNewCardsFieldsModal = () => setOpenNewCardsFieldsModal(false);

  const handleClosecall_jurisdictionModal = () =>
    set_open_call_jurisdiction_Modal(false);

  const handleCloseBusinessLicenseModal = () => {
    setOpenBusinessLicenseModal(false);

  };

  const handleCloseProductAvailabilityModal = () => {
    setOpenProductAvailabilityModal(false);

  };

  const handleCloseSendCustomerPlanModal = () => {
    setOpenSendCustomerPlanModal(false);

  };

  const handleOpenAddersFormModal = () => {
    setOpenAddersFormModal(true);
  };

  const handleOpensite_surveyModal = () => {
    set_open_site_survey_Modal(true);
  };

  const handleCloseAddersFormModal = () => {
    setOpenAddersFormModal(false);
  };

  const handleopenCall_supplierModal = () => {
    set_Open_Call_supplier_Modal(true);
  };

  const handleCloseCall_supplierModal = () => {
    set_Open_Call_supplier_Modal(false);
  };

  const handleopenCallUtilityCompanyModal = () => {
    set_Open_CallUtilityCompany_Modal(true);
  };

  const handleCloseCallUtilityCompanyModal = () => {
    set_Open_CallUtilityCompany_Modal(false);
  };

  const handleopenjob_review_with_salesModal = () => {
    set_Open_job_review_with_sales_Modal(true);
  };

  const handleClosejob_review_with_salesModal = () => {
    set_Open_job_review_with_sales_Modal(false);
  };

  const handleClosefirst_step_of_survey_Modal = () => {
    set_open_first_step_of_survey_Modal(false);
  };
  const handleOpenfirst_step_of_survey_Modal = () => {
    set_open_first_step_of_survey_Modal(true);
  };

  const handleCloseSecond_step_of_survey_Modal = () => {
    set_open_second_step_of_survey_Modal(false);
  };
  const handleOpenSecond_step_of_survey_Modal = () => {
    set_open_second_step_of_survey_Modal(true);
  };

  const handleClose_quality_control_step_of_survey_Modal = () => {
    set_open_quality_control_step_of_survey_Modal(false);
  };
  const handleOpen_quality_control_step_of_survey_Modal = () => {
    set_open_quality_control_step_of_survey_Modal(true);
  };

  const handleOpen_call_jurisdiction_Modal = () => {
    set_open_call_jurisdiction_Modal(true);
  };
  const handleSend = async () => {
    const reqData = {
      id: lead_id,
    };
    type == "welcome_package" && (await dispatch(sendWelcomePackage(reqData)));
    card.title == "Request utility bill" &&
      (await dispatch(sendUtilityBill(reqData)));
    await dispatch(getTicketStatus(lead_id));
  };
  const handleopenAddIssueForm = () => {
    setAddIssueForm(true);
  };

  const handleCloseAddIssueForm = () => {
    setAddIssueForm(false);
  };




  const handleDeleteCard = async (card) => {
    console.log(card, "delete card -- card_id")
    await dispatch(DeleteCardAcion({ id: card.card_id }));
    await dispatch(getCardsOrder())
    //window.location.reload()

  }
  const handleCardClick = () => {


    if (card.card_id == 0) {
      console.log("if stat")
      switch (type || card.title) {

        case "ntp_finance_portal":
          setOpenStatusFilesCommentModal(true);
          break;
        case "welcome_call":
          setOpenWelcomeCallModal(true);
          break;
        case "create_charter":
          navigate(`/home/CreateCharter/${lead_id}`);
          break;
        case "request_to_designer":
          navigate(`/home/DesignReq/${lead_id}`);
          break;
        case "business_license":
          setOpenBusinessLicenseModal(true);
          break;
        case "apply_meter_spot":
          setOpenStatusFilesCommentModal(true);
          break;
        case "check_product_availability":
          setOpenProductAvailabilityModal(true);
          break;
        case "send_plan_to_client":
          setOpenSendCustomerPlanModal(true);
          break;
        case "upload_installation_pics_to_finance":
          setOpenStatusFilesCommentModal(true);
          break;
        case "confirm_city_office_time_frame":
          setOpenStatusFilesCommentModal(true);
          break;
        case "final_inspection":
          setOpenStatusFilesCommentModal(true);
          break;
        case "products_quotes":
          setOpenStatusFilesCommentModal(true);
          break;
        case "upload_permit_to_finance":
          setOpenStatusFilesCommentModal(true);
          break;
        case "order_placards":
          setOpenStatusFilesCommentModal(true);
          break;
        case "coordinate_product_delivery":
          setOpenStatusFilesCommentModal(true);
          break;
        case "coordinate_client_installer_installation":
          setOpenStatusFilesCommentModal(true);
          break;
        case "order_product":
          setOpenStatusFilesCommentModal(true);
          break;
        case "Work orders":
          navigate(`/home/SolarSolution/Work_orders/${lead_id}`);
          break;
        case "confirm_installer_inspection":
          setOpenStatusFilesCommentModal(true);
          break;
        case "m2_payment_collection":
          setOpenStatusFilesCommentModal(true);
          break;
        case "schedule_inspection":
          setOpenStatusFilesCommentModal(true);
          break;
        case "apply_pto":
          setOpenStatusFilesCommentModal(true);
          break;
        case "apply_all_required_permits":
          setOpenStatusFilesCommentModal(true);
          break;
        case "m1_collection":
          setOpenStatusFilesCommentModal(true);
          break;
        case "monitoring_optimizer":
          setOpenStatusFilesCommentModal(true);
          break;
        case "final_job_card":
          setOpenStatusFilesCommentModal(true);
          break;
        case "Extra adders":
          setOpenAddersFormModal(true);
          break;
        case "invoice_for_installer":
          setOpenStatusFilesCommentModal(true);
          break;
        case "pto_to_finance":
          setOpenStatusFilesCommentModal(true);
          break;
        case "send_full_details_to_client":
          setOpenStatusFilesCommentModal(true);
          break;
        case "closure_report":
          setOpenStatusFilesCommentModal(true);
          break;
        case "end_project_call":
          setOpenStatusFilesCommentModal(true);
          break;
        case "adders":
          navigate(`/home/SolarSolution/Adders/${lead_id}`);
          break;
        case "addons":
          navigate(`/home/all_addons/${lead_id}`);
          break;
        case "call_supplier":
          set_Open_Call_supplier_Modal(true);
          break;
        case "call_utility_companies":
          set_Open_CallUtilityCompany_Modal(true);
          break;
        case "job_review":
          set_Open_job_review_with_sales_Modal(true);
          break;
        case "confirm_items_with_sales_and_customer":
          set_Open_confirm_items_with_sales_and_customer_Modal(true);
          // set_open_first_step_of_survey_Modal(true);
          // set_open_second_step_of_survey_Modal(true);
          // set_open_quality_control_step_of_survey_Modal(true);
          // navigate(
          //   `/home/SolarSolution/step_details/${lead_id}/${"surveys_second-step"}`
          // );
          break;
        case "surveyor_first_step":
          set_open_first_step_of_survey_Modal(true);

          break;

        case "surveyor_second_step":
          navigate(`/home/SolarSolution/step_details/${lead_id}/${type}`);
          break;
        case "surveyor_quality_control":
          set_open_quality_control_step_of_survey_Modal(true);
          break;
        case "call_designer":
          setOpenStatusFilesCommentModal(true);
          break;
        case "call_jurisdiction":
          set_open_call_jurisdiction_Modal(true);
          break;
        case "call_city_office":
          set_open_Call_City_office_utility_Modal(true);
          break;

        case "call_city_utility":
          set_open_Call_City_office_utility_Modal(true);
          break;

        case "site_survey":
          { card_status == "1" && navigate(`/home/Create_Site_survey/${lead_id}`) }
          break;

        default:
        // code block
      }

    }
    else {
      console.log(card.card_id, "card.card_id")
      setOpenNewCardsFieldsModal(true);

    }

  };

  const handleShowCardDetails = () => {
    switch (type || card.title) {
      case "request_to_designer":
        navigate(`/home/SolarSolution/design_req_details/${lead_id}`);
        break;
      case "ntp_finance_portal":
        navigate(`/home/SolarSolution/step_details/${lead_id}/${type}`);
        break;
      case "welcome_call":
        navigate(`/home/SolarSolution/step_details/${lead_id}/${type}`);
        break;
      case "create_charter":
        navigate(`/home/SolarSolution/charter_details/${lead_id}`);
        break;
      case "business_license":
        navigate(`/home/SolarSolution/step_details/${lead_id}/${type}`);
        break;
      case "apply_meter_spot":
        navigate(`/home/SolarSolution/step_details/${lead_id}/${type}`);
        break;
      case "check_product_availability":
        navigate(`/home/SolarSolution/step_details/${lead_id}/${type}`);
        break;
      case "send_plan_to_client":
        navigate(`/home/SolarSolution/step_details/${lead_id}/${type}`);
        break;
      case "upload_installation_pics_to_finance":
        navigate(
          `/home/SolarSolution/step_details/${lead_id}/${type}`
        );
        break;
      case "confirm_city_office_time_frame":
        navigate(`/home/SolarSolution/step_details/${lead_id}/${type}`);
        break;
      case "final_inspection":
        navigate(`/home/SolarSolution/step_details/${lead_id}/${type}`);
        break;
      case "products_quotes":
        navigate(`/home/SolarSolution/step_details/${lead_id}/${type}`);
        break;
      case "upload_permit_to_finance":
        navigate(`/home/SolarSolution/step_details/${lead_id}/${type}`);
        break;
      case "order_placards":
        navigate(`/home/SolarSolution/step_details/${lead_id}/${type}`);
        break;
      case "coordinate_product_delivery":
        navigate(`/home/SolarSolution/step_details/${lead_id}/${type}`);
        break;
      case "coordinate_client_installer_installation":
        navigate(`/home/SolarSolution/step_details/${lead_id}/${type}`);
        break;
      case "order_product":
        navigate(`/home/SolarSolution/step_details/${lead_id}/${type}`);
        break;
      case "Work orders":
        navigate(
          `/home/SolarSolution/step_details/${lead_id}/${"documents_to_installer"}`
        );
        break;
      case "confirm_installer_inspection":
        navigate(`/home/SolarSolution/step_details/${lead_id}/${type}`);
        break;
      case "m2_payment_collection":
        navigate(
          `/home/SolarSolution/step_details/${lead_id}/${type}`
        );
        break;
      case "schedule_inspection":
        navigate(`/home/SolarSolution/step_details/${lead_id}/${type}`);
        break;
      case "apply_pto":
        navigate(`/home/SolarSolution/step_details/${lead_id}/${type}`);
        break;
      case "apply_all_required_permits":
        navigate(`/home/SolarSolution/step_details/${lead_id}/${type}`);
        break;
      case "m1_collection":
        navigate(`/home/SolarSolution/step_details/${lead_id}/${type}`);
        break;
      case "monitoring_optimizer":
        navigate(`/home/SolarSolution/step_details/${lead_id}/${type}`);
        break;
      case "final_job_card":
        navigate(`/home/SolarSolution/step_details/${lead_id}/${type}`);
        break;
      case "Extra adders":
        navigate(`/home/SolarSolution/step_details/${lead_id}/${"extra_adders"}`);
        break;
      case "invoice_for_installer":
        navigate(`/home/SolarSolution/step_details/${lead_id}/${type}`);
        break;
      case "pto_to_finance":
        navigate(`/home/SolarSolution/step_details/${lead_id}/${type}`);
        break;
      case "send_full_details_to_client":
        navigate(`/home/SolarSolution/step_details/${lead_id}/${type}`);
        break;
      case "closure_report":
        navigate(`/home/SolarSolution/step_details/${lead_id}/${type}`);
        break;
      case "end_project_call":
        navigate(`/home/SolarSolution/step_details/${lead_id}/${type}`);
        break;
      case "call_supplier":
        navigate(`/home/SolarSolution/step_details/${lead_id}/${type}`);
        break;
      case "call_utility_companies":
        navigate(`/home/SolarSolution/step_details/${lead_id}/${type}`);
        break;
      case "job_review":
        navigate(`/home/SolarSolution/step_details/${lead_id}/${type}`);
        break;
      case "confirm_items_with_sales_and_customer":
        navigate(`/home/SolarSolution/step_details/${lead_id}/${type}`);
        break;
      case "surveyor_first_step":
        navigate(`/home/SolarSolution/step_details/${lead_id}/${type}`);
        break;
      case "surveyor_quality_control":
        navigate(`/home/SolarSolution/step_details/${lead_id}/${type}`);
        break;
      case "call_designer":
        navigate(`/home/SolarSolution/step_details/${lead_id}/${type}`);

        break;
      case "call_jurisdiction":
        navigate(`/home/SolarSolution/step_details/${lead_id}/${type}`);
        break;
      case "call_city_office":
        navigate(`/home/SolarSolution/step_details/${lead_id}/${type}`);
        break;
      case "call_city_utility":
        navigate(`/home/SolarSolution/step_details/${lead_id}/${type}`);
        break;
      case "site_survey":
        navigate(`/home/SolarSolution/site_survey_data/${lead_id}`);
        break;

      default:
      // code block
    }
  };

  const handleShowNewCardDetails = (card)=>{
    navigate(`/home/SolarSolution/New_card_details/${lead_id}/${type}/${card.card_id}`);

  }



  return (
    <>
      {TicketStatus?.isLodaing && cardsOrder?.isLodaing ? (

        <p>loading...</p>
      ) : (
        <Grid
          item
          xs={12}
          sm={12}
          lg={12}
        // style={{ position: "relative" }}
        >
          {openAddIssueForm && (
            <AddIssueForm
              handleopenAddIssueForm={handleopenAddIssueForm}
              handleCloseAddIssueForm={handleCloseAddIssueForm}
              ticket_id={lead_id}
              pm_status_id={pm_status_id}
              setAddIssueForm={setAddIssueForm}
              issueCardType={type}
              title={title}
            />
          )}
          <CardContainer>
            <Card
              style={{
                cursor: "pointer",
                marginBottom: "10px",
                borderRadius: "10px",
                background: `${card_status == "1"
                  ? "#dedede"
                  : card_status == "5"
                    ? "#C0FBBC"
                    : "rgb(230 231 111)"
                  }`,
              }}
              sx={{ minWidth: 270, width: 270 }}
            >
              <CardContent onClick={handleCardClick}>
                <Typography
                  variant="h6"
                  component="div"
                  ml={2}
                  mb={1}
                // onClick={handleCardClick}
                >
                  {ReviewProfitLossStatment_status === "5" && <CheckIcon />}
                  {card.title}
                </Typography>
                <Typography
                  sx={{ mb: 1.5, ml: 2 }}
                  color="text.secondary"
                ></Typography>
              </CardContent>
              <CardActions>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    marginTop: "20px",
                  }}
                >

                  {card_status == "1" && (
                    <Tooltip title="Add Issue">
                      <div>
                        {(type !== "welcome_package" &&
                          type !== "create_charter" &&
                          card.title !== "Request utility bill" &&
                          type !== "request_to_designer" &&
                          type !== "site_survey" &&
                          card.title !== "Adders" &&
                          card.title !== "Addons" &&
                          type !== "surveyor_first_step" &&
                          type !== "confirm_items_with_sales_and_customer" &&
                          type !== "surveyor_quality_control" &&
                          type !== "surveyor_second_step" &&
                          type !== "call_city_utility" &&
                          type !== "call_utility_companies" &&
                          card.title !== "Extra adders" &&
                          type !== "send_plan_to_client" &&
                          type !== "call_city_office" &&
                          type !== "m1_collection" &&
                          type !== "m2_payment_collection" &&
                          type !== "call_jurisdiction" &&
                          type !== "call_supplier") && (
                            <Button
                              style={{ color: "gray", marginRight: "0px" }}
                              onClick={handleopenAddIssueForm}
                            >
                              <LanIcon />
                            </Button>
                          )}
                      </div>
                    </Tooltip>
                  )}
                    {card.card_id == 0 && (
                       <Tooltip title="View Details">
                       <div>
                         {type !== "welcome_package" &&
                           card.title !== "Request utility bill" &&
                           type !== "send_plan_to_client" &&
                           card.title !== "Adders" &&
                           card.title !== "Addons" &&
   
                           card.title !== "Work orders" && (
                             <Button
                               style={{ color: "gray" }}
                               onClick={handleShowCardDetails}
                             >
                               <RemoveRedEyeIcon />
                             </Button>
                           )}
                       </div>
                     </Tooltip>
                    )}

{card.card_id > 0 && (
                       <Tooltip title="View Details">
                       <div>
                         
                             <Button
                               style={{ color: "gray" }}
                              //  onClick={()=>handleShowNewCardDetails(card)}
                             >
                               {/* <RemoveRedEyeIcon /> */}
                             </Button>
                        
                       </div>
                     </Tooltip>
                    )}
                

                  <Tooltip title="Delete Card">
                    <div>
                      {card.card_id > 0 && (
                        <Button
                          style={{ color: "gray" }}
                          // onClick={() => handleDeleteCard(card)}
                          onClick={()=>handleClickOpenDeleteCardModal(card)}
                        >
                          <DeleteIcon />
                        </Button>
                      )}
                    </div>
                  </Tooltip>
                  <Tooltip title="transfer card to">
                    <div>
                      <Button
                        style={{ color: "gray", marginRight: "0px" }}
                        //onClick={handleopenAddIssueForm}
                        onClick={() => openTransferCardtModal(card)}
                      >
                        <SwapHorizIcon />
                      </Button>
                    </div>
                  </Tooltip>
                </div>
              </CardActions>
              {(type == "welcome_package" ||
                card.title == "Request utility bill") && (
                  <CardActions>
                    {/* {welcome_package === "1" && ( */}
                    {card_status !== "5" && (
                      <PrimaryButton
                        onClick={() => handleSend()}
                        style={{
                          padding: "0% 3%",
                          width: "150px",
                          margin: "auto",
                          marginBottom: "6px",
                          height: "42px",
                          fontSize: "10px",
                          background: "rgb(222, 222, 222)",
                          color: "black",
                        }}
                        disabled={
                          sendWelcomePackageState?.isLodaing ||
                          utilitybillState?.isLodaing
                        }
                      >
                        {sendWelcomePackageState?.isLodaing ||
                          utilitybillState?.isLodaing ? (
                          <CircularProgress size={25} thickness={18} />
                        ) : null}
                        Send
                      </PrimaryButton>
                    )}
                  </CardActions>
                )}
            </Card>
          </CardContainer>
          {openStatusFilesCommentModal && card_status == "1" && (
            <StatusFileCommentModalForCards
              handleCloseStatusFilesCommentModal={
                handleCloseStatusFilesCommentModal
              }
              openStatusFilesCommentModal={openStatusFilesCommentModal}
              setOpenStatusFilesCommentModal={setOpenStatusFilesCommentModal}
              ticket_id={lead_id}
              type={type}
            />
          )}
          {openWelcomeCallModal && card_status == "1" && (
            <WelcomCallModal
              handleCloseWelcomeCallModal={handleCloseWelcomeCallModal}
              openWelcomeCallModal={openWelcomeCallModal}
              setOpenWelcomeCallModal={setOpenWelcomeCallModal}
              lead_id={lead_id}
            />
          )}

          {openNewCardsFieldsModal && (
            <NewCardFieldsModal
              handleCloseNewCardsFieldsModal={handleCloseNewCardsFieldsModal}
              openNewCardsFieldsModal={openNewCardsFieldsModal}
              setOpenNewCardsFieldsModal={setOpenNewCardsFieldsModal}
              lead_id={lead_id}
              card={card}
              fieldsData={fieldsData}
              // initialValues={fieldsData}
              cardName={type}
            />
          )}
          {openBusinessLicenseModal && card_status == "1" && (
            <BusinessLicenseModal
              handleCloseBusinessLicenseModal={handleCloseBusinessLicenseModal}
              openBusinessLicenseModal={openBusinessLicenseModal}
              setOpenBusinessLicenseModal={setOpenBusinessLicenseModal}
              ticket_status={ticket_status}
            />
          )}
          {openProductAvailabilityModal && card_status == "1" && (
            <ProductAvailabilityModal
              handleCloseProductAvailabilityModal={
                handleCloseProductAvailabilityModal
              }
              openProductAvailabilityModal={openProductAvailabilityModal}
              setOpenProductAvailabilityModal={setOpenProductAvailabilityModal}
              lead_id={lead_id}
              card_status={card_status}
            />
          )}
          {openSendCustomerPlanModal && (
            <SendCustomerPlanModal
              handleCloseSendCustomerPlanModal={handleCloseSendCustomerPlanModal}
              openSendCustomerPlanModal={openSendCustomerPlanModal}
              setOpenSendCustomerPlanModal={setOpenSendCustomerPlanModal}
              ticket_id={lead_id}
            />
          )}
          {openAddersFormModal && (
            // <ExtraAddersModal
            // <NewExtraAdders
            <ExtraAddersModal
              handleOpenAddersFormModal={handleOpenAddersFormModal}
              handleCloseAddersFormModal={handleCloseAddersFormModal}
              setOpenAddersFormModal={setOpenAddersFormModal}
              ticket_id={lead_id}
              pm_status_id={pm_status_id}
            />
          )}
          {open_Call_supplier_Modal && card_status === "1" && (
            <CallSupplierModal
              handleopenModal={handleopenCall_supplierModal}
              handleCloseModal={handleCloseCall_supplierModal}
              open_Call_supplier_Modal={open_Call_supplier_Modal}
              set_Open_Call_supplier_Modal={set_Open_Call_supplier_Modal}
              ticket_id={lead_id}
              card_status={card_status}
            />
          )}
          {open_CallUtilityCompany_Modal && card_status === "1" && (
            <CallUtilityCompanyModal
              handleopenModal={handleopenCallUtilityCompanyModal}
              handleCloseModal={handleCloseCallUtilityCompanyModal}
              open_CallUtilityCompany_Modal={open_CallUtilityCompany_Modal}
              set_Open_CallUtilityCompany_Modal={set_Open_CallUtilityCompany_Modal}
              ticket_id={lead_id}
            />
          )}
          {open_job_review_with_sales_Modal && card_status === "1" && (
            <JobReviewWithSales
              handleopenModal={handleopenjob_review_with_salesModal}
              handleCloseModal={handleClosejob_review_with_salesModal}
              open_job_review_with_sales_Modal={open_job_review_with_sales_Modal}
              set_Open_job_review_with_sales_Modal={
                set_Open_job_review_with_sales_Modal
              }
              ticket_id={lead_id}
            />
          )}
          {open_confirm_items_with_sales_and_customer_Modal && (
            <ConfirmWithSalesAndCustomer
              handleopenModal={handleopenconfirm_items_with_sales_and_customerModal}
              handleCloseModal={
                handleCloseconfirm_items_with_sales_and_customerModal
              }
              open_Modal={open_confirm_items_with_sales_and_customer_Modal}
              set_Open_Modal={set_Open_confirm_items_with_sales_and_customer_Modal}
              ticket_id={lead_id}
            />
          )}
          {open_first_step_of_survey_Modal && card_status === "1" && (
            <FirstStepOfSurveyModal
              handleopenModal={handleOpenfirst_step_of_survey_Modal}
              handleCloseModal={handleClosefirst_step_of_survey_Modal}
              open_Modal={open_first_step_of_survey_Modal}
              set_Open_Modal={set_open_first_step_of_survey_Modal}
              ticket_id={lead_id}
            />
          )}
          {open_second_step_of_survey_Modal && card_status == "1" && (
            <SecondStepOfSurveyModal
              handleopenModal={handleOpenSecond_step_of_survey_Modal}
              handleCloseModal={handleCloseSecond_step_of_survey_Modal}
              open_Modal={open_second_step_of_survey_Modal}
              set_Open_Modal={set_open_second_step_of_survey_Modal}
              ticket_id={lead_id}
            />
          )}
          {open_quality_control_step_of_survey_Modal && card_status == "1" && (
            <QualityControlModal
              handleopenModal={handleOpen_quality_control_step_of_survey_Modal}
              handleCloseModal={handleClose_quality_control_step_of_survey_Modal}
              open_Modal={open_quality_control_step_of_survey_Modal}
              set_Open_Modal={set_open_quality_control_step_of_survey_Modal}
              ticket_id={lead_id}
              card_status={card_status}
            />
          )}
          {open_call_jurisdiction_Modal && card_status == "1" && (
            <CalljurisdictionsModal
              handleopenModal={handleOpen_call_jurisdiction_Modal}
              handleCloseModal={handleClosecall_jurisdictionModal}
              open_Modal={open_call_jurisdiction_Modal}
              set_Open_Modal={set_open_call_jurisdiction_Modal}
              ticket_id={lead_id}
              card_status={card_status}
            />
          )}

          {open_Call_City_office_utility_Modal && card_status == "1" && (
            <CallCityofficeutilityModal
              handleopenModal={handleopenCallUtilityCompanyModal}
              handleCloseModal={handleCloseCall_City_office_utility_Modal}
              open_Modal={open_call_jurisdiction_Modal}
              set_Open_Modal={set_open_Call_City_office_utility_Modal}
              ticket_id={lead_id}
              card_status={card_status}
              card_type={type}
            />
          )}
          {transferCard && (
            <TransferCardModal
              openTransferCardtModal={openTransferCardtModal}
              handleCloseTransferCardtModal={handleCloseTransferCardtModal}
              transferCard={transferCard}
              selectedCard={selectedCard}
              handleUpdate_Receiving_Projectcards={
                handleUpdate_Receiving_Projectcards
              }
              Receiving_Projectcards={Receiving_Projectcards}
              Receiving_Project={Receiving_Project}
              setTransferCard={setTransferCard}
            />
          )}
          {openDeleteCardModal && (
            <DeleteCardModal
              handleClickOpenDeleteCardModal={handleClickOpenDeleteCardModal}
              openDeleteCardModal={openDeleteCardModal}
              setOpenDeleteCardModal={setOpenDeleteCardModal}
              selectedCard={selectedCard}
              card={card}
              

            />
          )}
        </Grid>
      )}


    </>
  );
};

export default CardData;
