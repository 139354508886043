import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import confirm_with_salesAndCustomer_Service from "./ConfirmWithSalesAndCustomerService";
import { toast } from "react-toastify";
const initialState = {
  confirm_with_sales_and_customer: {},
  isError: false,
  isSuccess: false,
  isLodaing: false,
  massage: "",
};

export const store_confirm_with_salesAndCustomer = createAsyncThunk(
  "confirm_with_salesAndCustomer/post",
  async (data, thunkAPI) => {
    try {
      return await confirm_with_salesAndCustomer_Service.store_confirm_with_salesAndCustomer(
        data
      );
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      error?.response?.data?.errors?.map((err) =>
        toast.error(err.msg, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      );
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const get_confirm_with_salesAndCustomer_data = createAsyncThunk(
  "confirm_with_salesAndCustomer/get",
  async (ticket_id, thunkAPI) => {
    try {
      return await confirm_with_salesAndCustomer_Service.get_confirm_with_salesAndCustomer_data(
        ticket_id
      );
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const update_confirm_with_salesAndCustomer = createAsyncThunk(
  "confirm_with_salesAndCustomers/post",
  async (data, thunkAPI) => {
    try {
      return await confirm_with_salesAndCustomer_Service.edit_confirm_with_salesAndCustomer(
        data
      );
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      error?.response?.data?.errors?.map((err) =>
        toast.error(err.msg, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      );
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const confirm_with_sales_and_customerSlice = createSlice({
  name: "confirm_with_sales_and_customer",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLodaing = false;
      state.massage = "";
    },
  },
  extraReducers: {
    //store_confirm_with_salesAndCustomer
    [store_confirm_with_salesAndCustomer.pending]: (state) => {
      state.isLodaing = true;
    },
    [store_confirm_with_salesAndCustomer.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.confirm_with_sales_and_customer = payload;
    },
    [store_confirm_with_salesAndCustomer.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },

    //confirm_with_sales_and_customer
    [get_confirm_with_salesAndCustomer_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [get_confirm_with_salesAndCustomer_data.fulfilled]: (
      state,
      { payload }
    ) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.confirm_with_sales_and_customer = payload;
    },
    [get_confirm_with_salesAndCustomer_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.confirm_with_sales_and_customer = null;
      state.massage = action.payload;
    },

    //update_confirm_with_salesAndCustomer
    [update_confirm_with_salesAndCustomer.pending]: (state) => {
      state.isLodaing = true;
    },
    [update_confirm_with_salesAndCustomer.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.confirm_with_sales_and_customer = payload;
    },
    [update_confirm_with_salesAndCustomer.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
  },
});

export const { reset } = confirm_with_sales_and_customerSlice.actions;
export default confirm_with_sales_and_customerSlice.reducer;
