import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import workOrdersService from "./workOrdersService";

const initialState = {
  workOrders: {},
  isError: false,
  isSuccess: false,
  isLodaing: false,
  massage: "",
};

export const getWorkOrders = createAsyncThunk(
  "getWorkOrders/get",
  async (id, thunkAPI) => {
    try {
      return await workOrdersService.getWorkOrdrs_listAction(id);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const getSiteSurveyData = createAsyncThunk(
  "getSiteSurvey/get",
  async (id, thunkAPI) => {
    try {
      return await workOrdersService.gesiteSurvey_listAction(id);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const WorkOrdersSlice = createSlice({
  name: "workOrders",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLodaing = false;
      state.massage = "";
    },
  },
  extraReducers: {
    //get
    [getWorkOrders.pending]: (state) => {
      state.isLodaing = true;
    },
    [getWorkOrders.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.workOrders = payload;
    },
    [getWorkOrders.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
      state.workOrders = null;
    },

    //get getSiteSurveyData
    [getSiteSurveyData.pending]: (state) => {
      state.isLodaing = true;
    },
    [getSiteSurveyData.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.workOrders = payload;
    },
    [getSiteSurveyData.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
      state.workOrders = null;
    },
  },
});

export const { reset } = WorkOrdersSlice.actions;
export default WorkOrdersSlice.reducer;
