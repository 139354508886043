import React, { useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { CircularProgress } from "@material-ui/core";
import CropOriginalIcon from "@mui/icons-material/CropOriginal";
import { PrimaryButton } from "../../Layouts/ButtonsControlled";
import { getTicketStatus } from "../../Redux/Slices/TicketStatus/TicketStatusSlice";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
const SolarAttachments = () => {
  const dispatch = useDispatch();
  const single_ticket = useSelector((state) => state.single_ticket);
  const cardStatus = useSelector((state) => state.TicketStatus);
  const welcomeCall = cardStatus?.TicketStatus?.data?.welcome_call;
  const signature_contract = `${"http://crm.boxbyld.tech/profiles/"}${
    single_ticket?.singleTicket?.data?.contract?.eversign_document?.url
  }`;
  const ticketID = single_ticket?.singleTicket?.data?.ticket?.id;
  //ticket_media
  const { lead_id } = useParams();
  console.log(
    single_ticket?.singleTicket?.data?.ticket?.id,
    "single ticket in attachment"
  );

  console.log(lead_id, "lead_id in params");
  console.log(
    single_ticket?.singleTicket?.data?.ticket?.ticket_media,
    "tickedgdfgdfgdfgdsd"
  );
  useEffect(() => {
    dispatch(getTicketStatus(lead_id));
  }, [lead_id]);
  return (
    <>
      <Grid>
        {single_ticket?.singleTicket?.data?.opportunity?.pdf_path && (
          <Box sx={{ flexGrow: 1 }}>
            <AppBar
              position="static"
              style={{
                backgroundColor: "#F7F7F7",
                color: "black",
                boxShadow: "none",
                marginBottom: "10px",
              }}
            >
              <Toolbar>
                <CropOriginalIcon />
                <span>PDF</span>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ flexGrow: 1 }}
                ></Typography>

                <Button color="inherit">
                  <a
                    target="_blank"
                    href={
                      single_ticket?.singleTicket?.data?.opportunity?.pdf_path
                    }
                  >
                    Solution
                  </a>
                </Button>
              </Toolbar>
            </AppBar>
          </Box>
        )}
        <Box sx={{ flexGrow: 1 }}>
          <AppBar
            position="static"
            style={{
              backgroundColor: "#F7F7F7",
              color: "black",
              boxShadow: "none",
              marginBottom: "10px",
            }}
          >
            <Toolbar>
              <CropOriginalIcon />
              <span>PDF</span>
              <Typography
                variant="h6"
                component="div"
                sx={{ flexGrow: 1 }}
              ></Typography>

              <Button color="inherit">
                <a target="_blank" href={signature_contract}>
                  Contract Url
                </a>
              </Button>
            </Toolbar>
          </AppBar>
        </Box>
        {single_ticket?.singleTicket?.data?.ticket?.ticket_media &&
          single_ticket?.singleTicket?.data?.ticket?.ticket_media.length > 0 &&
          single_ticket?.singleTicket?.data?.ticket?.ticket_media?.map(
            (urll) => (
              <Box sx={{ flexGrow: 1 }}>
                <AppBar
                  position="static"
                  style={{
                    backgroundColor: "#F7F7F7",
                    color: "black",
                    boxShadow: "none",
                    marginBottom: "10px",
                  }}
                >
                  {urll.type !== "contract" && urll.type !== "site_media" && (
                    <Toolbar>
                      <CropOriginalIcon />
                      <span>Image</span>
                      <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1 }}
                      ></Typography>

                      <Button color="inherit">
                        <a target="_blank" href={urll.url}>
                          {urll.type}
                        </a>
                      </Button>
                    </Toolbar>
                  )}
                </AppBar>
              </Box>
            )
          )}
        <Link to={`/home/SolarSolution/start-project/${lead_id}`}>
          {" "}
          {cardStatus?.isLodaing ? (
            <Grid alignItems="center">
              <CircularProgress size={25} thickness={18} />
            </Grid>
          ) : (
            <PrimaryButton
              fullWidth
              style={{ background: "#0085FF", padding: "0.7rem" }}
            >
              {welcomeCall == "5" ? "View project" : "Create Project"}
            </PrimaryButton>
          )}
        </Link>{" "}
      </Grid>
    </>
  );
};

export default SolarAttachments;
