import React, { useEffect, useState, useRef } from "react";
import classNames from "classnames";
import "./Styles.css";
import styled from "styled-components";
import { Grid, Typography, Button, IconButton } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { Box } from "@mui/system";
import { SteperContainer } from "../Components/ProcessSteper/ProcessSteperStyle";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import LottieAnimation from "../Components/LottieAnimation/LottieAnimation";
import loading from "../Assets/lottie/loading.json";
import { Container, Draggable } from "react-smooth-dnd";
import { arrayMoveImmutable } from "array-move";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { getTicketStatus } from "../Redux/Slices/TicketStatus/TicketStatusSlice";
import {
  getCardsOrder,
  Update_Cards_order,
} from "../Redux/Slices/CardsListSlice/CardsListSlice";
import CardData from "../Components/ProcessSteper/card";
import { PrimaryButton } from "../Layouts/ButtonsControlled";
import useSticky from "./UseSticky";
import AddCardWithForm from "./AddCardWithForm"
const ProcessSteper = () => {
  const { sticky, stickyRef } = useSticky();
  console.log(stickyRef, "stickyRef");
  const single_ticket = useSelector((state) => state.single_ticket);
  const cardStatus = useSelector((state) => state.TicketStatus);
  const cardsOrder = useSelector((state) => state.cardsOrder);
  const [Receiving_Projectcards, setReceiving_Projectcards] = useState([]);
  const [Design_Submittalcards, setDesign_Submittalcards] = useState([]);
  const [InspectionCards, set_InspectionCards] = useState([]);
  const [InstallationCards, set_InstallationCards] = useState([]);
  const [interconnectionCards, set_interconnectionCards] = useState([]);
  const [PermitsCards, set_PermitsCards] = useState([]);
  const [PTOCards, set_PTOCards] = useState([]);
  const [SurveyCards, setSurveyCards] = useState([]);
  const [untitledCards, set_untitledCards] = useState([]);
  const [Closing_The_ProjectCards, set_Closing_The_ProjectCards] = useState([]);
  const [PTO_submittalCards, set_PTO_submittalCards] = useState([]);
  const Receiving_Project = cardsOrder?.cardsOrder?.data["Receiving Project"];
  const Design_Submittal = cardsOrder?.cardsOrder?.data["Design Submittal"];
  const Inspection = cardsOrder?.cardsOrder?.data["Inspection"];
  const Installation = cardsOrder?.cardsOrder?.data["Installation"];
  const interconnection = cardsOrder?.cardsOrder?.data["interconnection"];
  const Permits = cardsOrder?.cardsOrder?.data["Permits"];
  const PTO = cardsOrder?.cardsOrder?.data["PTO"];
  const Survey = cardsOrder?.cardsOrder?.data["Survey"];
  const untitled = cardsOrder?.cardsOrder?.data[""];
  const Closing_The_Project =
    cardsOrder?.cardsOrder?.data["Closing The Project"];
  const PTO_submittal = cardsOrder?.cardsOrder?.data["PTO submittal"];
  const [open, setOpen] = React.useState(false);
  const [card_group, set_card_group] = useState();
  console.log(cardsOrder, "cardsOrder123JJJJ");
  console.log(cardStatus, "cardStatusnmnnhJJJJ");
  const { lead_id } = useParams();
  const params = useParams();
  console.log(params, "KKLKLKL");

  const dispatch = useDispatch();
  const stepStatus =
    single_ticket?.singleTicket?.data?.ticket?.project_custom_status;
  const utility_bill_status =
    single_ticket?.singleTicket?.data?.ticket?.request_utility_bill;
  const card_status = cardStatus?.TicketStatus?.data;
  const welcomeCall = cardStatus?.TicketStatus?.data?.welcome_call;
  const welcome_package = cardStatus?.TicketStatus?.data?.welcome_package;
  const create_charter = cardStatus?.TicketStatus?.data?.create_charter;
  const business_license = cardStatus?.TicketStatus?.data?.business_license;
  const apply_meter_spot = cardStatus?.TicketStatus?.data?.apply_meter_spot;
  // const apply_pto_status = cardStatus?.TicketStatus?.data?.apply_pto;
  const products_quotes_status =
    cardStatus?.TicketStatus?.data?.products_quotes;
  const product_availability_status =
    cardStatus?.TicketStatus?.data?.check_product_availability;
  const NTP_status = cardStatus?.TicketStatus?.data?.ntp_finance_portal;
  const order_product_status = cardStatus?.TicketStatus?.data?.order_product;
  const upload_permit_to_finance_status =
    cardStatus?.TicketStatus?.data?.upload_permit_to_finance;
  const order_placards_status = cardStatus?.TicketStatus?.data?.order_placards;
  const coordinate_product_delivery_status =
    cardStatus?.TicketStatus?.data?.coordinate_product_delivery;
  const coordinate_client_installer_installation_status =
    cardStatus?.TicketStatus?.data?.coordinate_client_installer_installation;
  const ScheduleInspections_status =
    cardStatus?.TicketStatus?.data?.schedule_inspection;
  const SendCustomerPlan_status =
    cardStatus?.TicketStatus?.data?.send_plan_to_client;
  const installer_invoices_status =
    cardStatus?.TicketStatus?.data?.invoice_for_installer;
  const upload_to_pto_status = cardStatus?.TicketStatus?.data?.upload_to_pto;
  const send_full_details_to_client_status =
    cardStatus?.TicketStatus?.data?.send_full_details_to_client;
  const ReviewProfitLossStatment_status =
    cardStatus?.TicketStatus?.data?.review_profit_loss_statement;
  const closure_report_status = cardStatus?.TicketStatus?.data?.closure_report;
  const payment_collection_status =
    cardStatus?.TicketStatus?.data?.payment_collection;
  const InstallerCommissionsCard_status =
    cardStatus?.TicketStatus?.data?.installer_commissions;
  const installation_pics_status =
    cardStatus?.TicketStatus?.data?.upload_installation_pics_to_finance;
  const confirm_installer_inspection_status =
    cardStatus?.TicketStatus?.data?.confirm_installer_inspection;
  const apply_permits_status =
    cardStatus?.TicketStatus?.data?.apply_all_required_permits;
  const end_project_card_status =
    cardStatus?.TicketStatus?.data?.end_project_call;
  const PTOToFinance_status = cardStatus?.TicketStatus?.data?.pto_to_finance;
  const FinalJobCard_status = cardStatus?.TicketStatus?.data?.final_job_card;
  const request_to_designer_status =
    cardStatus?.TicketStatus?.data?.request_to_designer;
  const confirm_city_office_time_frame =
    cardStatus?.TicketStatus?.data?.confirm_city_office_time_frame;
  const inform_inspection_result_status =
    cardStatus?.TicketStatus?.data?.inform_inspection_result;
  const MonitoringOptimizers_status =
    cardStatus?.TicketStatus?.data?.monitoring_optimizer;
  const M1CollectionCard_status = cardStatus?.TicketStatus?.data?.m1_collection;
  const Work_order_status =
    cardStatus?.TicketStatus?.data?.documents_to_installer;
  const final_inspection_status =
    cardStatus?.TicketStatus?.data?.final_inspection;
  // console.log(cardStatus?.TicketStatus?.data, "cardStatus?.TicketStatus?.data");
  const pm_status_id = cardStatus?.TicketStatus?.data?.id;
  const call_supplier = cardStatus?.TicketStatus?.data?.call_supplier;
  const job_review_with_sales = cardStatus?.TicketStatus?.data?.job_review;
  const call_utility_companies =
    cardStatus?.TicketStatus?.data?.call_utility_companies;
  const confirm_items_with_sales_and_customer =
    cardStatus?.TicketStatus?.data?.confirm_items_with_sales_and_customer;

  const onRecievingDrop = ({ removedIndex, addedIndex }) => {
    setReceiving_Projectcards((items) =>
      arrayMoveImmutable(items, removedIndex, addedIndex)
    );
  };

  const onDesign_SubmittalDrop = ({ removedIndex, addedIndex }) => {
    setDesign_Submittalcards((items) =>
      arrayMoveImmutable(items, removedIndex, addedIndex)
    );
  };

  const onInspectionDrop = ({ removedIndex, addedIndex }) => {
    set_InspectionCards((items) =>
      arrayMoveImmutable(items, removedIndex, addedIndex)
    );
  };

  const onInstallationDrop = ({ removedIndex, addedIndex }) => {
    set_InstallationCards((items) =>
      arrayMoveImmutable(items, removedIndex, addedIndex)
    );
  };

  const oninterconnectionDrop = ({ removedIndex, addedIndex }) => {
    set_interconnectionCards((items) =>
      arrayMoveImmutable(items, removedIndex, addedIndex)
    );
  };

  const onPermitsDrop = ({ removedIndex, addedIndex }) => {
    set_PermitsCards((items) =>
      arrayMoveImmutable(items, removedIndex, addedIndex)
    );
  };
  const onPTODrop = ({ removedIndex, addedIndex }) => {
    set_PTOCards((items) =>
      arrayMoveImmutable(items, removedIndex, addedIndex)
    );
  };

  const onClosing_The_ProjectDrop = ({ removedIndex, addedIndex }) => {
    set_Closing_The_ProjectCards((items) =>
      arrayMoveImmutable(items, removedIndex, addedIndex)
    );
  };

  const onuntitledDrop = ({ removedIndex, addedIndex }) => {
    set_untitledCards((items) =>
      arrayMoveImmutable(items, removedIndex, addedIndex)
    );
  };

  const onSurveyDrop = ({ removedIndex, addedIndex }) => {
    setSurveyCards((items) =>
      arrayMoveImmutable(items, removedIndex, addedIndex)
    );
  };

  const onPTO_submittalDrop = ({ removedIndex, addedIndex }) => {
    set_PTO_submittalCards((items) =>
      arrayMoveImmutable(items, removedIndex, addedIndex)
    );
  };
  const handleClickOpenRecievingProjectGroup = () => {
    setOpen(true);
    set_card_group("Receiving Project")
  };

  const handleClickOpenDesignSubmittalGroup = () => {
    setOpen(true);
    set_card_group("Design Submittal")
  };

  const handleClickOpenInspectionGroup = () => {
    setOpen(true);
    set_card_group("Inspection")
  };

  const handleClickOpenInstallationGroup = () => {
    setOpen(true);
    set_card_group("Installation")
  };

  const handleClickOpenInterconnectionGroup = () => {
    setOpen(true);
    set_card_group("interconnection")
  }

  const handleClickOpenPermitsGroup = () => {
    setOpen(true);
    set_card_group("Permits")
  }

  const handleClickOpenPTOGroup = () => {
    setOpen(true);
    set_card_group("PTO")
  }

  const handleClickOpenClosingTheProjectGroup = () => {
    setOpen(true);
    set_card_group("Closing The Project")
  }

  const handleClickOpenSurveyGroup = ()=>{
    setOpen(true);
    set_card_group("Survey") 
  }

  const handleClickOpenPTOSubmittalGroup = ()=>{
    setOpen(true);
    set_card_group("PTO submittal") 
  }
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getTicketStatus(lead_id));
    cardStatus?.isSuccess && dispatch(getCardsOrder());
  }, [lead_id]);

  useEffect(() => {
    dispatch(getTicketStatus(lead_id));
    cardStatus?.isSuccess && dispatch(getCardsOrder());
  }, [lead_id]);

  // useEffect(() => {
  //   // dispatch(getTicketStatus(lead_id));
  //   dispatch(getCardsOrder());
  // }, []);

  useEffect(() => {
    setReceiving_Projectcards(Receiving_Project);
    setDesign_Submittalcards(Design_Submittal);
    set_InspectionCards(Inspection);
    set_InstallationCards(Installation);
    set_interconnectionCards(interconnection);
    set_PermitsCards(Permits);
    set_PTOCards(PTO);
    set_Closing_The_ProjectCards(Closing_The_Project);
    set_untitledCards(untitled);
    setSurveyCards(Survey);
    set_PTO_submittalCards(PTO_submittal);

  }, [cardsOrder]);

  if (cardStatus.TicketStatus.data == 'undefined' || !cardsOrder.cardsOrder) {
    // console.log(`hello testttt ${cardsOrder.cardsOrder}`)
    // console.log(`hello testttt ${cardStatus.TicketStatus.data}`)
    return '';
  } else {

  }
  const handleUpdate_Receiving_Projectcards = async () => {
    const reqData = Receiving_Projectcards.map((item, index) => ({
      order: index + 1,
      card_group: "Receiving Project",
      id: item.id,
      key: item.key,
      title: item.title,
    }));
    console.log(reqData, "reqData");
    await dispatch(Update_Cards_order(reqData));
    await dispatch(getCardsOrder());
  };

  const handleUpdate_designsubmittalcards = async () => {
    const reqData = Design_Submittalcards.map((item, index) => ({
      order: index + 1,
      card_group: "Design Submittal",
      id: item.id,
      key: item.key,
      title: item.title,
    }));

    await dispatch(Update_Cards_order(reqData));
    await dispatch(getCardsOrder());
  };

  const handleUpdate_Inspection_cards = async () => {
    const reqData = InspectionCards.map((item, index) => ({
      order: index + 1,
      card_group: item.card_group,
      id: item.id,
      key: item.key,
      title: item.title,
    }));

    await dispatch(Update_Cards_order(reqData));
    await dispatch(getCardsOrder());
  };

  const handleUpdate_Installation_cards = async () => {
    const reqData = InstallationCards.map((item, index) => ({
      order: index + 1,
      card_group: item.card_group,
      id: item.id,
      key: item.key,
      title: item.title,
    }));

    await dispatch(Update_Cards_order(reqData));
    await dispatch(getCardsOrder());
  };

  const handleUpdate_interconnection_cards = async () => {
    const reqData = interconnectionCards.map((item, index) => ({
      order: index + 1,
      card_group: item.card_group,
      id: item.id,
      key: item.key,
      title: item.title,
    }));

    await dispatch(Update_Cards_order(reqData));
    await dispatch(getCardsOrder());
  };

  const handleUpdate_PermitsCards = async () => {
    const reqData = PermitsCards.map((item, index) => ({
      order: index + 1,
      card_group: item.card_group,
      id: item.id,
      key: item.key,
      title: item.title,
    }));

    await dispatch(Update_Cards_order(reqData));
    await dispatch(getCardsOrder());
  };
  const handleUpdate_PTOCards = async () => {
    const reqData = PTOCards.map((item, index) => ({
      order: index + 1,
      card_group: item.card_group,
      id: item.id,
      key: item.key,
      title: item.title,
    }));

    await dispatch(Update_Cards_order(reqData));
    await dispatch(getCardsOrder());
  };

  const handleUpdate_Closing_The_ProjectCards = async () => {
    const reqData = Closing_The_ProjectCards.map((item, index) => ({
      order: index + 1,
      card_group: item.card_group,
      id: item.id,
      key: item.key,
      title: item.title,
    }));

    await dispatch(Update_Cards_order(reqData));
    await dispatch(getCardsOrder());
  };

  const handleUpdate_untitledCards = async () => {
    const reqData = untitledCards.map((item, index) => ({
      order: index + 1,
      card_group: "null",
      id: item.id,
      key: item.key,
      title: item.title,
    }));

    await dispatch(Update_Cards_order(reqData));
    await dispatch(getCardsOrder());
  };

  const handleUpdate_SurveyCards = async () => {
    const reqData = SurveyCards.map((item, index) => ({
      order: index + 1,
      card_group: "Survey",
      id: item.id,
      key: item.key,
      title: item.title,
    }));

    await dispatch(Update_Cards_order(reqData));
    await dispatch(getCardsOrder());
  };

  const handleUpdate_PTO_submittalCards = async () => {
    const reqData = PTO_submittalCards.map((item, index) => ({
      order: index + 1,
      card_group: "Survey",
      id: item.id,
      key: item.key,
      title: item.title,
    }));

    await dispatch(Update_Cards_order(reqData));
    await dispatch(getCardsOrder());
  };
  return (
    <>
      <MainTabsStyled>
        <Box
          className={classNames("nav flex title", { sticky })}
          sx={{ width: "100%", typography: "body1" }}
          style={{
            backgroundColor: "white",
            height: "1400px",
            display: "flex",
            // background: green;
            flexDirection: "row",
            justifyContent: "start",
            // height: "100%",
            maxHeight: "100%",
            overflowX: "auto",
            // maxWidth: "1071px",
            maxWidth: "-webkit-fill-available",
            marginTop: "12px",
          }}
        >
          {cardStatus?.isLodaing && cardsOrder?.isLodaing ? (
            <LottieAnimation lotti={loading} width={180} height={180} />
          ) : (
            //  { drag && drop}

            <>
              {cardStatus?.isSuccess && (
                <div className="list">
                  {sticky && (
                    <div
                      style={{
                        height: `${stickyRef.current?.clientHeight}px`,
                      }}
                    />
                  )}
                  <div className="content">
                    <div className="title">
                      <Typography
                        variant="h5"
                        ref={stickyRef}
                        style={{
                          color: "darkslategray",
                          // backgroundColor: "#f1c40f",
                          maxWidth: "fit-content",
                          fontWeight: "bold",
                          padding: "14px",
                        }}
                      >
                        Recieving Project
                        <IconButton aria-label="add" onClick={handleClickOpenRecievingProjectGroup} >
                          <AddIcon />
                        </IconButton>
                      </Typography>
                    </div>
                    <div className="cards">
                      <Container
                        dragHandleSelector=".drag-handle"
                        lockAxis="y"
                        onDrop={onRecievingDrop}
                        style={{ margin: "10px 0px" }}
                      >
                        {Receiving_Projectcards?.map((card) => (
                          <Draggable
                            className="drag-handle"
                            key={card.id}
                            style={{ cursor: "pointer" }}
                          >
                            <div
                            // className={`${
                            //   card_status[card?.key] == "1"
                            //     ? "step pending"
                            //     : card_status[card?.key] == "5"
                            //     ? "step completed"
                            //     : "step progress"
                            // }`}
                            >
                              <div className="v-stepper">
                                <div className="circle"></div>
                                <div className="line"></div>
                              </div>
                              <div>
                                <div className="content">
                                  {/* <p>{card_status[card.key]}</p> */}
                                  <CardData
                                    card={card}
                                    lead_id={lead_id}
                                    card_status={card_status[card?.key]}
                                    handleUpdate_Receiving_Projectcards={
                                      handleUpdate_Receiving_Projectcards
                                    }
                                    Receiving_Projectcards={
                                      Receiving_Projectcards
                                    }
                                    Receiving_Project={Receiving_Project}
                                    pm_status_id={pm_status_id}
                                  />
                                </div>
                              </div>
                            </div>
                          </Draggable>
                        ))}
                      </Container>
                      <PrimaryButton
                        fullWidth
                        style={{
                          padding: "0",
                          width: "100%",
                          margin: "15px auto",
                          height: "42px",
                          fontSize: "14px",
                        }}
                        className="saveButton"
                        onClick={handleUpdate_Receiving_Projectcards}
                      >
                        Save cards order
                      </PrimaryButton>
                    </div>
                  </div>
                  <div className="content">
                    <div className="title">
                      <Typography
                        variant="h5"
                        style={{
                          color: "darkslategray",
                          maxWidth: "fit-content",
                          fontWeight: "bold",
                          padding: "14px",
                        }}
                      >
                        Design Submittal
                        <IconButton aria-label="add" onClick={handleClickOpenDesignSubmittalGroup} >
                          <AddIcon />
                        </IconButton>
                      </Typography>
                    </div>
                    <div className="cards">
                      <Container
                        dragHandleSelector=".drag-handle"
                        lockAxis="y"
                        onDrop={onDesign_SubmittalDrop}
                        style={{ margin: "10px 0px" }}
                      >
                        {Design_Submittalcards?.map((card) => (
                          <Draggable
                            className="drag-handle"
                            key={card.id}
                            style={{ cursor: "pointer" }}
                          >
                            <div
                            // className={`${
                            //   card_status[card.key] == "1"
                            //     ? "step pending"
                            //     : card_status[card.key] == "5"
                            //     ? "step completed"
                            //     : "step progress"
                            // }`}
                            >
                              <div className="v-stepper">
                                <div className="circle"></div>
                                <div className="line"></div>
                              </div>
                              <div>
                                <div className="content">
                                  <CardData
                                    card={card}
                                    lead_id={lead_id}
                                    card_status={card_status[card?.key]}
                                    pm_status_id={pm_status_id}
                                  />
                                </div>
                              </div>
                            </div>
                          </Draggable>
                        ))}
                      </Container>
                      <PrimaryButton
                        fullWidth
                        style={{
                          padding: "0",
                          width: "100%",
                          margin: "15px auto",
                          height: "42px",
                          fontSize: "14px",
                        }}
                        className="saveButton"
                        onClick={handleUpdate_designsubmittalcards}
                      >
                        Save cards order
                      </PrimaryButton>
                    </div>
                  </div>
                  <div className="content">
                    <div className="title">
                      <Typography
                        variant="h5"
                        style={{
                          color: "darkslategray",
                          maxWidth: "fit-content",
                          fontWeight: "bold",
                          padding: "14px",
                        }}
                      >
                        Inspection
                        <IconButton aria-label="add" onClick={handleClickOpenInspectionGroup} >
                          <AddIcon />
                        </IconButton>
                      </Typography>
                    </div>
                    <div className="cards">
                      <Container
                        dragHandleSelector=".drag-handle"
                        lockAxis="y"
                        onDrop={onInspectionDrop}
                        style={{ margin: "10px 0px" }}
                      >
                        {InspectionCards?.map((card) => (
                          <Draggable
                            className="drag-handle"
                            key={card.id}
                            style={{ cursor: "pointer" }}
                          >
                            <div
                            // className={`${
                            //   card_status[card.key] == "1"
                            //     ? "step pending"
                            //     : card_status[card.key] == "5"
                            //     ? "step completed"
                            //     : "step progress"
                            // }`}
                            >
                              <div className="v-stepper">
                                <div className="circle"></div>
                                <div className="line"></div>
                              </div>
                              <div>
                                <div className="content">
                                  <CardData
                                    card={card}
                                    lead_id={lead_id}
                                    card_status={card_status[card?.key]}
                                    pm_status_id={pm_status_id}
                                  />
                                </div>
                              </div>
                            </div>
                          </Draggable>
                        ))}
                      </Container>
                      <PrimaryButton
                        fullWidth
                        style={{
                          padding: "0",
                          width: "100%",
                          margin: "15px auto",
                          height: "42px",
                          fontSize: "14px",
                        }}
                        className="saveButton"
                        onClick={handleUpdate_Inspection_cards}
                      >
                        Save cards order
                      </PrimaryButton>
                    </div>
                  </div>
                  <div className="content">
                    <div className="title">
                      <Typography
                        variant="h5"
                        style={{
                          color: "darkslategray",
                          maxWidth: "fit-content",
                          fontWeight: "bold",
                          padding: "14px",
                        }}
                      >
                        Installation
                        <IconButton aria-label="add" onClick={handleClickOpenInstallationGroup} >
                          <AddIcon />
                        </IconButton>
                      </Typography>
                    </div>
                    <div className="cards">
                      <Container
                        dragHandleSelector=".drag-handle"
                        lockAxis="y"
                        onDrop={onInstallationDrop}
                        style={{ margin: "10px 0px" }}
                      >
                        {InstallationCards?.map((card) => (
                          <Draggable
                            className="drag-handle"
                            key={card.id}
                            style={{ cursor: "pointer" }}
                          >
                            <div
                            // className={`${
                            //   card_status[card.key] == "1"
                            //     ? "step pending"
                            //     : card_status[card.key] == "5"
                            //     ? "step completed"
                            //     : "step progress"
                            // }`}
                            >
                              <div className="v-stepper">
                                <div className="circle"></div>
                                <div className="line"></div>
                              </div>
                              <div>
                                <div className="content">
                                  <CardData
                                    card={card}
                                    lead_id={lead_id}
                                    card_status={card_status[card?.key]}
                                    pm_status_id={pm_status_id}
                                  />
                                </div>
                              </div>
                            </div>
                          </Draggable>
                        ))}
                      </Container>
                      <PrimaryButton
                        fullWidth
                        style={{
                          padding: "0",
                          width: "100%",
                          margin: "15px auto",
                          height: "42px",
                          fontSize: "14px",
                        }}
                        className="saveButton"
                        onClick={handleUpdate_Installation_cards}
                      >
                        Save cards order
                      </PrimaryButton>
                    </div>
                  </div>
                  <div className="content">
                    <div className="title">
                      <Typography
                        variant="h5"
                        style={{
                          color: "darkslategray",
                          maxWidth: "fit-content",
                          fontWeight: "bold",
                          padding: "14px",
                        }}
                      >
                        interconnection
                        <IconButton aria-label="add" onClick={handleClickOpenInterconnectionGroup} >
                          <AddIcon />
                        </IconButton>
                      </Typography>
                    </div>
                    <div className="cards">
                      <Container
                        dragHandleSelector=".drag-handle"
                        lockAxis="y"
                        onDrop={oninterconnectionDrop}
                        style={{ margin: "10px 0px" }}
                      >
                        {interconnection?.map((card) => (
                          <Draggable
                            className="drag-handle"
                            key={card.id}
                            style={{ cursor: "pointer" }}
                          >
                            <div
                            // className={`${
                            //   card_status[card.key] == "1"
                            //     ? "step pending"
                            //     : card_status[card.key] == "5"
                            //     ? "step completed"
                            //     : "step progress"
                            // }`}
                            >
                              <div className="v-stepper">
                                <div className="circle"></div>
                                <div className="line"></div>
                              </div>
                              <div>
                                <div className="content">
                                  <CardData
                                    card={card}
                                    lead_id={lead_id}
                                    card_status={card_status[card?.key]}
                                    pm_status_id={pm_status_id}
                                  />
                                </div>
                              </div>
                            </div>
                          </Draggable>
                        ))}
                      </Container>
                      <PrimaryButton
                        fullWidth
                        style={{
                          padding: "0",
                          width: "100%",
                          margin: "15px auto",
                          height: "42px",
                          fontSize: "14px",
                        }}
                        className="saveButton"
                        onClick={handleUpdate_interconnection_cards}
                      >
                        Save cards order
                      </PrimaryButton>
                    </div>
                  </div>
                  <div className="content">
                    <div className="title">
                      <Typography
                        variant="h5"
                        style={{
                          color: "darkslategray",
                          maxWidth: "fit-content",
                          fontWeight: "bold",
                          padding: "14px",
                        }}
                      >
                        Permits
                        <IconButton aria-label="add" onClick={handleClickOpenPermitsGroup} >
                          <AddIcon />
                        </IconButton>
                      </Typography>
                    </div>
                    <div className="cards">
                      <Container
                        dragHandleSelector=".drag-handle"
                        lockAxis="y"
                        onDrop={onPermitsDrop}
                        style={{ margin: "10px 0px" }}
                      >
                        {PermitsCards?.map((card) => (
                          <Draggable
                            className="drag-handle"
                            key={card.id}
                            style={{ cursor: "pointer" }}
                          >
                            <div
                            // className={`${
                            //   card_status[card.key] == "1"
                            //     ? "step pending"
                            //     : card_status[card.key] == "5"
                            //     ? "step completed"
                            //     : "step progress"
                            // }`}
                            >
                              <div className="v-stepper">
                                <div className="circle"></div>
                                <div className="line"></div>
                              </div>
                              <div>
                                <div className="content">
                                  <CardData
                                    card={card}
                                    lead_id={lead_id}
                                    card_status={card_status[card?.key]}
                                    pm_status_id={pm_status_id}
                                  />
                                </div>
                              </div>
                            </div>
                          </Draggable>
                        ))}
                      </Container>
                      <PrimaryButton
                        fullWidth
                        style={{
                          padding: "0",
                          width: "100%",
                          margin: "15px auto",
                          height: "42px",
                          fontSize: "14px",
                        }}
                        className="saveButton"
                        onClick={handleUpdate_PermitsCards}
                      >
                        Save cards order
                      </PrimaryButton>
                    </div>
                  </div>
                  <div className="content">
                    <div className="title">
                      <Typography
                        variant="h5"
                        style={{
                          color: "darkslategray",
                          maxWidth: "fit-content",
                          fontWeight: "bold",
                          padding: "14px",
                        }}
                      >
                        PTO
                        <IconButton aria-label="add" onClick={handleClickOpenPTOGroup} >
                          <AddIcon />
                        </IconButton>
                      </Typography>
                    </div>
                    <div className="cards">
                      <Container
                        dragHandleSelector=".drag-handle"
                        lockAxis="y"
                        onDrop={onPTODrop}
                        style={{ margin: "10px 0px" }}
                      >
                        {PTOCards?.map((card) => (
                          <Draggable
                            className="drag-handle"
                            key={card.id}
                            style={{ cursor: "pointer" }}
                          >
                            <div
                            // className={`${
                            //   card_status[card.key] == "1"
                            //     ? "step pending"
                            //     : card_status[card.key] == "5"
                            //     ? "step completed"
                            //     : "step progress"
                            // }`}
                            >
                              <div className="v-stepper">
                                <div className="circle"></div>
                                <div className="line"></div>
                              </div>
                              <div>
                                <div className="content">
                                  <CardData
                                    card={card}
                                    lead_id={lead_id}
                                    card_status={card_status[card?.key]}
                                    pm_status_id={pm_status_id}
                                  />
                                </div>
                              </div>
                            </div>
                          </Draggable>
                        ))}
                      </Container>
                      <PrimaryButton
                        fullWidth
                        style={{
                          padding: "0",
                          width: "100%",
                          margin: "15px auto",
                          height: "42px",
                          fontSize: "14px",
                        }}
                        className="saveButton"
                        onClick={handleUpdate_PTOCards}
                      >
                        Save cards order
                      </PrimaryButton>
                    </div>
                  </div>

                  <div className="content">
                    <div className="title">
                      <Typography
                        variant="h6"
                        style={{
                          color: "darkslategray",
                          maxWidth: "fit-content",
                          fontWeight: "bold",
                          padding: "14px",
                        }}
                      >
                        Closing The Project
                        <IconButton aria-label="add" onClick={handleClickOpenClosingTheProjectGroup} >
                          <AddIcon />
                        </IconButton>
                      </Typography>
                    </div>
                    <div className="cards">
                      <Container
                        dragHandleSelector=".drag-handle"
                        lockAxis="y"
                        onDrop={onClosing_The_ProjectDrop}
                        style={{ margin: "10px 0px" }}
                      >
                        {Closing_The_ProjectCards?.map((card) => (
                          <Draggable
                            className="drag-handle"
                            key={card.id}
                            style={{ cursor: "pointer" }}
                          >
                            <div
                            // className={`${
                            //   card_status[card.key] == "1"
                            //     ? "step pending"
                            //     : card_status[card.key] == "5"
                            //     ? "step completed"
                            //     : "step progress"
                            // }`}
                            >
                              <div className="v-stepper">
                                <div className="circle"></div>
                                <div className="line"></div>
                              </div>
                              <div>
                                <div className="content">
                                  <CardData
                                    card={card}
                                    lead_id={lead_id}
                                    card_status={card_status[card?.key]}
                                    pm_status_id={pm_status_id}
                                  />
                                </div>
                              </div>
                            </div>
                          </Draggable>
                        ))}
                      </Container>
                      <PrimaryButton
                        fullWidth
                        style={{
                          padding: "0",
                          width: "100%",
                          margin: "15px auto",
                          height: "42px",
                          fontSize: "14px",
                        }}
                        className="saveButton"
                        onClick={handleUpdate_Closing_The_ProjectCards}
                      >
                        Save cards order
                      </PrimaryButton>
                    </div>
                  </div>
                  <div className="content">
                    <div className="title">
                      <Typography
                        variant="h5"
                        style={{
                          color: "darkslategray",
                          maxWidth: "fit-content",
                          fontWeight: "bold",
                          padding: "14px",
                        }}
                      >
                        ..........
                      </Typography>
                    </div>
                    <div className="cards">
                      <Container
                        dragHandleSelector=".drag-handle"
                        lockAxis="y"
                        onDrop={onuntitledDrop}
                        style={{ margin: "10px 0px" }}
                      >
                        {untitledCards?.map((card) => (
                          <Draggable
                            className="drag-handle"
                            key={card.id}
                            style={{ cursor: "pointer" }}
                          >
                            <div
                            // className={`${
                            //   card_status[card.key] == "1"
                            //     ? "step pending"
                            //     : card_status[card.key] == "5"
                            //     ? "step completed"
                            //     : "step progress"
                            // }`}
                            >
                              <div className="v-stepper">
                                <div className="circle"></div>
                                <div className="line"></div>
                              </div>
                              <div>
                                <div className="content">
                                  <CardData
                                    card={card}
                                    lead_id={lead_id}
                                    card_status={card_status[card?.key]}
                                    pm_status_id={pm_status_id}
                                  />
                                </div>
                              </div>
                            </div>
                          </Draggable>
                        ))}
                      </Container>
                      <PrimaryButton
                        fullWidth
                        style={{
                          padding: "0",
                          width: "100%",
                          margin: "15px auto",
                          height: "42px",
                          fontSize: "14px",
                        }}
                        className="saveButton"
                        onClick={handleUpdate_untitledCards}
                      >
                        Save cards order
                      </PrimaryButton>
                    </div>
                  </div>
                  <div className="content">
                    <div className="title">
                      <Typography
                        variant="h5"
                        style={{
                          color: "darkslategray",
                          maxWidth: "fit-content",
                          fontWeight: "bold",
                          padding: "14px",
                        }}
                      >
                        Survey
                        <IconButton aria-label="add" onClick={handleClickOpenSurveyGroup} >
                          <AddIcon />
                        </IconButton>
                      </Typography>
                    </div>
                    <div className="cards">
                      <Container
                        dragHandleSelector=".drag-handle"
                        lockAxis="y"
                        onDrop={onSurveyDrop}
                        style={{ margin: "10px 0px" }}
                      >
                        {SurveyCards?.map((card) => (
                          <Draggable
                            className="drag-handle"
                            key={card.id}
                            style={{ cursor: "pointer" }}
                          >
                            <div
                            // className={`${
                            //   card_status[card.key] == "1"
                            //     ? "step pending"
                            //     : card_status[card.key] == "5"
                            //     ? "step completed"
                            //     : "step progress"
                            // }`}
                            >
                              <div className="v-stepper">
                                <div className="circle"></div>
                                <div className="line"></div>
                              </div>
                              <div>
                                <div className="content">
                                  <CardData
                                    card={card}
                                    lead_id={lead_id}
                                    card_status={card_status[card?.key]}
                                    pm_status_id={pm_status_id}
                                  />
                                </div>
                              </div>
                            </div>
                          </Draggable>
                        ))}
                      </Container>
                      <PrimaryButton
                        fullWidth
                        style={{
                          padding: "0",
                          width: "100%",
                          margin: "15px auto",
                          height: "42px",
                          fontSize: "14px",
                        }}
                        className="saveButton"
                        onClick={handleUpdate_SurveyCards}
                      >
                        Save cards order
                      </PrimaryButton>
                    </div>
                  </div>
                  <div className="content">
                    <div className="title">
                      <Typography
                        variant="h5"
                        style={{
                          color: "darkslategray",
                          maxWidth: "fit-content",
                          fontWeight: "bold",
                          padding: "14px",
                        }}
                      >
                        PTO submittal
                        <IconButton aria-label="add" onClick={handleClickOpenPTOSubmittalGroup} >
                          <AddIcon />
                        </IconButton>
                      </Typography>
                    </div>
                    <div className="cards">
                      <Container
                        dragHandleSelector=".drag-handle"
                        lockAxis="y"
                        onDrop={onPTO_submittalDrop}
                        style={{ margin: "10px 0px" }}
                      >
                        {PTO_submittalCards?.map((card) => (
                          <Draggable
                            className="drag-handle"
                            key={card.id}
                            style={{ cursor: "pointer" }}
                          >
                            <div
                            // className={`${
                            //   card_status[card.key] == "1"
                            //     ? "step pending"
                            //     : card_status[card.key] == "5"
                            //     ? "step completed"
                            //     : "step progress"
                            // }`}
                            >
                              <div className="v-stepper">
                                <div className="circle"></div>
                                <div className="line"></div>
                              </div>
                              <div>
                                <div className="content">
                                  <CardData
                                    card={card}
                                    lead_id={lead_id}
                                    card_status={card_status[card?.key]}
                                  />
                                </div>
                              </div>
                            </div>
                          </Draggable>
                        ))}
                      </Container>
                      <PrimaryButton
                        fullWidth
                        style={{
                          padding: "0",
                          width: "100%",
                          margin: "15px auto",
                          height: "42px",
                          fontSize: "14px",
                        }}
                        className="saveButton"
                        onClick={handleUpdate_PTO_submittalCards}
                      >
                        Save cards order
                      </PrimaryButton>
                    </div>
                  </div>
                </div>
              )}

            </>
          )}
        </Box>
        {open && (
          <AddCardWithForm
            handleClose={handleClose}
            open={open}
            lead_id={lead_id}
            setOpen={setOpen}
            cardGroup={card_group}
          />
        )}
      </MainTabsStyled>

    </>
  );
};
const MainTabsStyled = styled.div`
  /* margin: 1rem 0.8rem;
  border-radius: 5px;
  min-height: 100px; */

  .list {
    display: flex;
    // background: green;
    flex-direction: row;
    justify-content: start;
    height: 100%;
    max-height: 100%;
    overflow-x: auto;
  }

  .content {
    // background: red;
    display: flex;
    flex-direction: column;
    width: 300px;
    min-width: 300px;
    height: 100%;
    max-height: 100%;
    margin-right: 10px;
  }
  .content::-webkit-scrollbar {
    width: 0.5em;
  }

  .content::-webkit-scrollbar-thumb {
    background-color: rgba(83, 83, 83, 0.8);

    border-radius: 2px;
  }

  .content::-webkit-scrollbar-thumb:hover {
    background-color: rgba(83, 83, 83, 1);
  }
  .title {
    padding: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid black;
    background-color: white;
    justify-content: center;
  }
  .cards {
    // background: yellow;
    padding: 4px 9px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    flex: 1 1 auto;
    overflow-y: auto;
    display: block;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    height: 460px;
    min-height: 460px;
  }
`;

export default ProcessSteper;
