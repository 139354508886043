import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get_job_reviews_data } from "../../Redux/Slices/JobReviewsWithSalesSlice/JobReviewsSlice";
import { Grid, IconButton, Typography, Card } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import EditIcon from "@mui/icons-material/Edit";
import EditJobReviewWithSales from "../../Components/Modals/jobReviewWithSales/EditJobReviewWithSales";
const JobReviewsWithSalesDetails = ({ lead_id }) => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const dispatch = useDispatch();
  const job_review_data = useSelector((state) => state?.job_reviews);
  console.log(job_review_data?.job_reviews, "job_reviewsdsdx123123");
  useEffect(() => {
    dispatch(get_job_reviews_data(lead_id));
  }, [openEditModal]);

  const handleopenModal = () => {
    setOpenEditModal(true);
  };

  const handleCloseModal = () => {
    setOpenEditModal(false);
  };
  return job_review_data?.isLodaing ? (
    <h1>loading</h1>
  ) : job_review_data?.job_reviews !== null ? (
    <Grid item xs={11} style={{ padding: "10px 55px" }} >
      <Card

        style={{
          background: "rgb(249, 245, 255)",
          border: "1px solid rgb(214, 187, 251)",
          borderRadius: "8px",
          padding: "1rem",
          margin: "0.5rem 0px",
          position: "relative",


        }}
      >


        <CardContent
          style={{ marginTop: "40px" }}

        >


          <Typography>
            {" "}
            <span
              style={{
                fontWeight: "bold",
                color: "#7e7ec0",
              }}
            >
              {" "}
              Adders Confirmation :
              {" "}
            </span>{" "}
            {job_review_data?.job_reviews?.data?.confirm_address == 1 ? (
              "Yes"
            ) : (
              "No"
            )}
          </Typography>

          <Divider
            variant="inset"
            align="center"
            style={{ marginBottom: "8px" }}
          />
      


          <Typography>
            {" "}
            <span
              style={{
                fontWeight: "bold",
                color: "#7e7ec0",
              }}
            >
              {" "}
              Contract confirmation :
              {" "}
            </span>{" "}
            {job_review_data?.job_reviews?.data?.confirm_contract == 1 ? (
              "Yes"
            ) : (
              "No"
            )}
          </Typography>
          <Divider
            variant="inset"
            align="center"
            style={{ marginBottom: "8px" }}
          />

       
          <Typography>
            {" "}
            <span
              style={{
                fontWeight: "bold",
                color: "#7e7ec0",
              }}
            >
              {" "}
              electrical bill Confirmation :
              {" "}
            </span>{" "}
            {job_review_data?.job_reviews?.data?.confirm_electrical_bill == 1 ? (
              "Yes"
            ) : (
              "No"
            )}
          </Typography>

          <Divider
            variant="inset"
            align="center"
            style={{ marginBottom: "8px" }}
          />
        
    

          <Typography>
            {" "}
            <span
              style={{
                fontWeight: "bold",
                color: "#7e7ec0",
              }}
            >
              {" "}
              is re-decking included :
              {" "}
            </span>{" "}
            {job_review_data?.job_reviews?.data?.is_re_decking == 1 ? (
              "Yes"
            ) : (
              "No"
            )}
          </Typography>
          <Divider
            variant="inset"
            align="center"
            style={{ marginBottom: "8px" }}
          />

          {job_review_data?.job_reviews?.data?.is_re_decking == 1 && (
            <>


              <Typography>
                {" "}
                <span
                  style={{
                    fontWeight: "bold",
                    color: "#7e7ec0",
                  }}
                >
                  {" "}
                  percentage of the roof allowed to perform re-decking on :
                  {" "}
                </span>{" "}
                {job_review_data?.job_reviews?.data?.re_decking_percentage}
              </Typography>

              <Divider
                variant="inset"
                align="center"
                style={{ marginBottom: "8px" }}
              />
            </>
          )}

 

<Typography>
            {" "}
            <span
              style={{
                fontWeight: "bold",
                color: "#7e7ec0",
              }}
            >
              {" "}
              Is re-roofing suggested :
              {" "}
            </span>{" "}
            {job_review_data?.job_reviews?.data?.is_re_roof == 1 ? (
              "Yes"
            ) : (
              "No"
            )}
          </Typography>
          <Divider
          variant="inset"
          align="center"
          style={{ marginBottom: "8px" }}
        />
     

<Typography>
                {" "}
                <span
                  style={{
                    fontWeight: "bold",
                    color: "#7e7ec0",
                  }}
                >
                  {" "}
                  Main panel size :
                  {" "}
                </span>{" "}
                {job_review_data?.job_reviews?.data?.main_panel_size}
              </Typography>
          <Divider
          variant="inset"
          align="center"
          style={{ marginBottom: "8px" }}
        />
      

          
<Typography>
            {" "}
            <span
              style={{
                fontWeight: "bold",
                color: "#7e7ec0",
              }}
            >
              {" "}
              Is re-roofing suggested :
              {" "}
            </span>{" "}
            {job_review_data?.job_reviews?.data?.is_upgrade_suggested == 1 ? (
              "Yes"
            ) : (
              "No"
            )}
          </Typography>
          <Divider
          variant="inset"
          align="center"
          style={{ marginBottom: "8px" }}
        />
        

<Typography>
                {" "}
                <span
                  style={{
                    fontWeight: "bold",
                    color: "#7e7ec0",
                  }}
                >
                  {" "}
                  Status :
                  {" "}
                </span>{" "}
                {job_review_data?.job_reviews?.data?.status}
              </Typography>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton onClick={handleopenModal}>
              <EditIcon />
            </IconButton>
          </div>
        </CardContent>
      </Card>
      {openEditModal && (
        <EditJobReviewWithSales
          setOpenEditModal={setOpenEditModal}
          handleopenModal={handleopenModal}
          handleCloseModal={handleCloseModal}
          lead_id={lead_id}
          job_review_data={job_review_data}
          openEditModal={openEditModal}
        />
      )}
    </Grid>
  ) : (
    <Grid>
      <Typography align="center">
        Theres is no data for this card
      </Typography>
    </Grid>
  );
};

export default JobReviewsWithSalesDetails;
