import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import AddonsList from "../../Components/AllAddons/AllAddons";
import { Grid } from "@mui/material";
const AllAddons = () => {
  const { lead_id } = useParams();
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" style={{ backgroundColor: "#eeebeb" }}>
          <Toolbar>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1 }}
              style={{ color: "black" }}
            >
              All Addons
            </Typography>

            <Link to={`/home/addons_form/${lead_id}`}>
              <Button variant="contained" endIcon={<ArrowForwardIcon />}>
                Create
              </Button>
            </Link>
          </Toolbar>
        </AppBar>
      </Box>
      <Grid>
        <AddonsList lead_id={lead_id} />
      </Grid>
    </>
  );
};

export default AllAddons;
