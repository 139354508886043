import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useDispatch, useSelector } from "react-redux";
import { getInstallersListAction } from "../../Redux/Slices/Installers/InstallersSlice";
import { getSingleTicket } from "../../Redux/Slices/SingleTicketSlice/SingleTicketSlice";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate, useParams } from "react-router-dom";
import { Grid , Typography  , IconButton ,Toolbar , AppBar } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import SiteSurveyForm from "./SiteSurveyForm";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#003656",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function InstallersList() {
  const dispatch = useDispatch();
  const { ticket_id } = useParams();
  const navigate = useNavigate()
  const installersList = useSelector((state) => state.installers);
  const installers_list = installersList?.installers?.data;
  const single_ticket = useSelector((state) => state.single_ticket);
  console.log(single_ticket, "single_ticket in work order");
  console.log(installersList, "installersList");
  console.log(installers_list, "installers_listmap");
  const state_id =
    single_ticket?.singleTicket?.data?.opportunity?.lead?.state_id;
  const lat = single_ticket?.singleTicket?.data?.opportunity?.lead?.lat;
  const lng = single_ticket?.singleTicket?.data?.opportunity?.lead?.lng;
  const reqData = {
    state_id: state_id,
    lat: lat,
    lng: lng,
  };
  console.log(state_id, "state_id");
  useEffect(() => {
    dispatch(getSingleTicket(ticket_id));
    !single_ticket?.isLodaing && dispatch(getInstallersListAction(reqData));
  }, [ticket_id, state_id]);
  const [checkedInstaller, setCheckedInstaller] = useState({});

  const handleCheckbox = (user) => {
    setCheckedInstaller(user);
  };
  console.log(checkedInstaller, "checkedInstaller selected installer");
  return (
    <>
    <AppBar position="static"
              style={{ backgroundColor: "white", color: "black" }}
            >
              <Toolbar>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                  onClick={() => navigate(`/home/SolarSolution/start-project/${ticket_id}`)}

                >
                  <ArrowBackIcon />
                </IconButton>
            
                {/* <Typography variant="h5" align="center"> Site Surveys </Typography> */}
              </Toolbar>
            </AppBar>
      <TableContainer
        component={Paper}
        style={{ marginBottom: "15px" }}
      >
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Installer Name</StyledTableCell>
              <StyledTableCell align="center">distance</StyledTableCell>
              <StyledTableCell align="center">Email</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {installers_list?.length > 0 ? (
              installers_list?.map((user) => (
                <StyledTableRow key={user.name}>
                  <StyledTableCell component="th" scope="row">
                    <>
                      <FiberManualRecordIcon
                        style={{
                          fontSize: "10px",
                          color: "#0DBF7C",
                          marginRight: "5px",
                        }}
                      />
                      <Checkbox
                        onChange={() => handleCheckbox(user)}
                        checked={checkedInstaller?.id == user.id}
                      />{" "}
                      {user.name}
                    </>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {user.distance}
                  </StyledTableCell>
                  <StyledTableCell align="center">{user.email}</StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <StyledTableRow
                component="th"
                scope="row"
                align="center"
                style={{
                  display: "flex",
                  justifyContent: "end",
                  margin: "15px",
                  backgroundColor: "transparent",
                }}
              >
                {/* <StyledTableCell component="th" scope="row" align="center"> */}
                There are no Installers
                {/* </StyledTableCell> */}
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Grid>
        <SiteSurveyForm selectedInstaller={checkedInstaller} />
      </Grid>
    </>
  );
}

export default InstallersList;
