import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {
  Grid,
  TextField,
  FormControl,
  IconButton,
  Button,
  Typography,
} from "@mui/material";
import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import InputLabel from "@mui/material/InputLabel";
import { PrimaryButton } from "../../../Layouts/ButtonsControlled";
import { ModalContent } from "../../Modals/PmAssignModal/PmAssignModalStyle";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Add } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import FolderIcon from "@mui/icons-material/Folder";
import DeleteIcon from "@mui/icons-material/Delete";
import Avatar from "@mui/material/Avatar";
import styled from "styled-components";
import MuiSelectField from "../../../Common/MuiSelectField/MuiSelectField";
import MuiTextField from "../../../Common/muiTextField/MuiTextField";
import {
  apply_pto,
  apply_permits,
  create_NTP,
  apply_meter_spot,
  create_quotes_for_products,
  create_product_orders_Action,
  create_permit_to_finance_Action,
  create_order_place_cards_Action,
  create_coordinate_product_delivery_Action,
  create_coordinate_installations_Action,
  create_schedule_inspections_cards_Action,
  create_installer_invoices_cards_Action,
  create_upload_to_pto_cards_Action,
  create_send_full_details_to_client_Action,
  create_review_profit_loss_statement_Action,
  create_closure_report_Action,
  create_installation_payment_collection_Action,
  create_installer_commissions_Action,
  create_installation_pics_Action,
  create_confirm_inspection_Action,
  create_end_project_call_Action,
  create_upload_PTO_to_finance_Action,
  create_final_job_card_Action,
  create_schedule_with_city_office_Action,
  create_inform_inspection_result_Action,
  create_monitoring_optimizers_Action,
  create_m1_collection_Action,
  create_m2_collection_Action,
  create_final_inspection_Action,
  call_designer,
} from "../../../Redux/Slices/CardsStatusSlics/CardStepsSlice";
import { getTicketStatus } from "../../../Redux/Slices/TicketStatus/TicketStatusSlice";
const StatusFileCommentModalForCards = ({
  handleSubmit,
  openStatusFilesCommentModal,
  handleCloseStatusFilesCommentModal,
  ticket_id,
  setOpenStatusFilesCommentModal,
  type,
}) => {
  const dispatch = useDispatch();
  const [showUpload, setShowUpload] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [UpdatedUploadFiles, setUpdatedUploadedFiles] = useState([]);
  const [fileLimit, setFileLimit] = useState(false);
  const MAX_COUNT = 5;

  console.log(type, "type");
  const submit = async (values) => {
    console.log(values, "values");
    const event_from = `${values.event_from} ${values.time_from}`
    const event_to = `${values.event_to} ${values.time_to}`
    const final_inspection_date_time =  `${values.final_inspection_date} ${values.final_inspection_time}`
    console.log(event_from , "event_from252525")
    const formData = new FormData();
    uploadedFiles.map((e, i) => {
      formData.append(`media[${i}]`, e);
    });
    formData.append("ticket_id", ticket_id);
    formData.append("status", values.status);
    (type == "apply_pto" ||
    type == "order_placards" ||
    type == "order_product" ||
    type == "apply_all_required_permits" ||
    type == "products_quotes" ||
    type == "m1_collection" ||
    type == "m2_payment_collection" ) &&  formData.append("cost", values.cost);
    (type == "order_product" ||
    type == "coordinate_product_delivery" ||
    type == "coordinate_client_installer_installation" ||
    type == "schedule_inspection" ||
    type == "confirm_installer_inspection" ||
    type == "apply_all_required_permits") &&
    formData.append("event", values.date);

     type == "coordinate_product_delivery" &&
      formData.append("estimated_time", values.estimated_time);
      type == "coordinate_product_delivery" &&
      formData.append("access_to_driveway", values.access_to_driveway);
      type == "coordinate_product_delivery" &&
      formData.append("will_be_at_home", values.will_be_at_home);
      type == "coordinate_product_delivery" &&
      formData.append("leave_the_product", values.leave_the_product);
      (type == "inform_inspection_result" ||
      type == "confirm_city_office_time_frame") &&
      formData.append("event_from", event_from);
    (type == "inform_inspection_result" ||
      type == "confirm_city_office_time_frame") &&
      formData.append("event_to", event_to);

      type == "call_designer" &&
      formData.append("is_confirmed", values.is_confirmed);

      type == "final_inspection" &&
      formData.append(
        "date_time",
        final_inspection_date_time
      );

      type == "upload_to_pto" &&
      formData.append("date_of_approval", values.upload_to_pto_date);

      type == "coordinate_client_installer_installation" &&
      formData.append("time", values.coordinate_client_time);
      type == "coordinate_client_installer_installation" &&
      formData.append("confirm_survey_time", values.confirm_survey_time);
      type == "coordinate_client_installer_installation" &&
      formData.append(
        "home_during_installation",
        values.home_during_installation
      );
      type == "coordinate_client_installer_installation" &&
      formData.append(
        "animals_outside_property",
        values.animals_outside_property
      );

      type == "coordinate_client_installer_installation" &&
      formData.append("customer_comments", values.customer_comments);
      formData.append("comment", values.comment);
      if (type == "apply_pto") {
        let submitAction = apply_pto;
        await dispatch(submitAction(formData));
      } else if (type == "apply_all_required_permits") {
        let submitAction = apply_permits;
        await dispatch(submitAction(formData));
      } else if (type == "ntp_finance_portal") {
        let submitAction = create_NTP;
        await dispatch(submitAction(formData));
      } else if (type == "apply_meter_spot") {
        let submitAction = apply_meter_spot;
        await dispatch(submitAction(formData));
      } else if (type == "products_quotes") {
        let submitAction = create_quotes_for_products;
        await dispatch(submitAction(formData));
      } else if (type == "order_product") {
        let submitAction = create_product_orders_Action;
        await dispatch(submitAction(formData));
      } else if (type == "upload_permit_to_finance") {
        let submitAction = create_permit_to_finance_Action;
        await dispatch(submitAction(formData));
      } else if (type == "order_placards") {
        let submitAction = create_order_place_cards_Action;
        await dispatch(submitAction(formData));
      } else if (type == "coordinate_product_delivery") {
        let submitAction = create_coordinate_product_delivery_Action;
        await dispatch(submitAction(formData));
      } else if (type == "coordinate_client_installer_installation") {
        let submitAction = create_coordinate_installations_Action;
        await dispatch(submitAction(formData));
      } else if (type == "schedule_inspection") {
        let submitAction = create_schedule_inspections_cards_Action;
        await dispatch(submitAction(formData));
      } else if (type == "invoice_for_installer") {
        let submitAction = create_installer_invoices_cards_Action;
        await dispatch(submitAction(formData));
      } else if (type == "upload_to_pto") {
        let submitAction = create_upload_to_pto_cards_Action;
        await dispatch(submitAction(formData));
      } else if (type == "send_full_details_to_client") {
        let submitAction = create_send_full_details_to_client_Action;
        await dispatch(submitAction(formData));
      } else if (type == "review_profit_loss_statement") {
        let submitAction = create_review_profit_loss_statement_Action;
        await dispatch(submitAction(formData));
      } else if (type == "closure_report") {
        let submitAction = create_closure_report_Action;
        await dispatch(submitAction(formData));
      } else if (type == "payment_collection") {
        let submitAction = create_installation_payment_collection_Action;
        await dispatch(submitAction(formData));
      } else if (type == "installer_commissions") {
        let submitAction = create_installer_commissions_Action;
        await dispatch(submitAction(formData));
      } else if (type == "upload_installation_pics_to_finance") {
        let submitAction = create_installation_pics_Action;
        await dispatch(submitAction(formData));
      } else if (type == "confirm_installer_inspection") {
        let submitAction = create_confirm_inspection_Action;
        await dispatch(submitAction(formData));
      } else if (type == "end_project_call") {
        let submitAction = create_end_project_call_Action;
        await dispatch(submitAction(formData));
      } else if (type == "pto_to_finance") {
        let submitAction = create_upload_PTO_to_finance_Action;
        await dispatch(submitAction(formData));
      } else if (type == "final_job_card") {
        let submitAction = create_final_job_card_Action;
        await dispatch(submitAction(formData));
      } else if (type == "confirm_city_office_time_frame") {
        let submitAction = create_schedule_with_city_office_Action;
        await dispatch(submitAction(formData));
      } else if (type == "inform_inspection_result") {
        let submitAction = create_inform_inspection_result_Action;
        await dispatch(submitAction(formData));
      } else if (type == "monitoring_optimizer") {
        let submitAction = create_monitoring_optimizers_Action;
        await dispatch(submitAction(formData));
      } else if (type == "m1_collection") {
        let submitAction = create_m1_collection_Action;
        await dispatch(submitAction(formData));
      } 
      else if (type == "m2_payment_collection") {
        let submitAction = create_m2_collection_Action;
        await dispatch(submitAction(formData));
      }
      else if (type == "final_inspection") {
        let submitAction = create_final_inspection_Action;
        await dispatch(submitAction(formData));
      } else if (type == "call_designer") {
        let submitAction = call_designer;
        await dispatch(submitAction(formData));
      }
  
      await setOpenStatusFilesCommentModal(false);
      await dispatch(getTicketStatus(ticket_id));
  }
 
  const handleShowUploadFiles = () => {
    setShowUpload(true);
  };
  const handlecloseUpload = () => {
    setShowUpload(false);
  };

  const handleUploadFiles = (files) => {
    const uploaded = [...UpdatedUploadFiles];
    let limitExceeded = false;
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        setUpdatedUploadedFiles(uploaded);
        if (uploaded.length === MAX_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_COUNT) {
          alert(`You can only add a maximum of ${MAX_COUNT} files`);
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    });
    if (!limitExceeded) {
      setUploadedFiles(uploaded);
    }
  };
  const handleRemoveItem = (file) => {
    const name = UpdatedUploadFiles.map((file) => file.name);
    setUpdatedUploadedFiles(
      UpdatedUploadFiles.filter((item) => item.name !== file.name)
    );
    console.log(name, "name");
    console.log(UpdatedUploadFiles, "UpdatedUploadFilesssssssss");
  };
  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };

  // const handlePTOChange = (key, value) =>
  //   setPTOData({ ...PTOData, [key]: value });

  return (
    <Dialog
      maxWidth={"xs"}
      open={openStatusFilesCommentModal}
      onClose={handleCloseStatusFilesCommentModal}
    >
      <DialogContent>
        <ModalContent>
        <form onSubmit={handleSubmit((values) => submit(values))}>
          <Grid className="display">
                <Field
              required
              name="status"
              component={MuiSelectField}
              label="status"
            >
                <MenuItem value="completed">completed </MenuItem>
                <MenuItem value="pending">pending</MenuItem>
                {(type == "apply_pto" ||
                  type == "ntp_finance_portal" ||
                  type == "upload_permit_to_finance" ||
                  type == "order_placards" ||
                  type == "coordinate_product_delivery" ||
                  type == "coordinate_client_installer_installation" ||
                  type == "order_product" ||
                  type == "schedule_inspection" ||
                  type == "apply_all_required_permits" ||
                  type == "products_quotes") && (
                  <MenuItem value="in_process">in progress </MenuItem>
                )}
           
            </Field>
          </Grid>
          {(type == "order_product" ||
            type == "coordinate_product_delivery" ||
            type == "coordinate_client_installer_installation" ||
            type == "schedule_inspection" ||
            type == "confirm_installer_inspection" ||
            type == "apply_all_required_permits") && (
            <Grid className="display" mt={6}>
              <Field
              required
              type="date"
              name="date"
              component={MuiTextField}
              label="date"
            />
            </Grid>
          )}
          {type == "coordinate_product_delivery" && (
            <>
              <Grid className="display" mt={2}>
             
              <Field
              required
              type="time"
              name="estimated_time"
              component={MuiTextField}
              label="estimated time"
            />
              </Grid>
              <Grid className="display" mt={2}>
               
                        <Field
              required
              name="access_to_driveway"
              component={MuiSelectField}
              label="access to driveway"
            >
                    <MenuItem value="1">Yes </MenuItem>
                    <MenuItem value="0">No</MenuItem>
                    </Field>
           
              </Grid>

              <Grid className="display" mt={2}>
               
                                  <Field
              required
              name="will_be_at_home"
              component={MuiSelectField}
              label=" will you be at home to sign off"
            >
                    <MenuItem value="1">Yes </MenuItem>
                    <MenuItem value="0">No</MenuItem>
                    </Field>
           
              </Grid>

              <Grid className="display" mt={2}>
             
                                        <Field
              required
              name="leave_the_product"
              component={MuiSelectField}
              label="Is it ok with you to leave the product"
            >
                    <MenuItem value="1">Yes </MenuItem>
                    <MenuItem value="0">No</MenuItem>
                    </Field>
              </Grid>
            </>
          )}
          {type == "confirm_city_office_time_frame" && (
            <>
              <Grid className="display" mt={6} >
             
                  <Field
              required
              type="date"
              name="event_from"
              component={MuiTextField}
              label="date From"
         
            />
            <div style={{marginLeft:"10px"}}>
            <Field
              required
              type="time"
              name="time_from"
              component={MuiTextField}
              label="time from"
              
            />
            </div>


              </Grid>

              <Grid className="display" mt={6}>
             

<Field
              required
              type="date"
              name="event_to"
              component={MuiTextField}
              label="date to"
            />

<div style={{marginLeft:"10px"}}>
<Field
              required
              type="time"
              name="time_to"
              component={MuiTextField}
              label="time to"
            />
            </div>
              </Grid>
            </>
          )}

                 {(type == "apply_pto" ||
                  type == "order_placards" ||
                  type == "order_product" ||
                  type == "apply_all_required_permits" ||
                  type == "products_quotes" ||
                  type == "m1_collection" ||
                  type == "m2_payment_collection"
                  ) && (
                    <Field
                    required
                    type="number"
                    name="cost"
                    component={MuiTextField}
                    label="cost"
                  />
                  )}


          {type == "call_designer" && (
       
            <Field
            required
            name="is_confirmed"
            component={MuiSelectField}
            label="is Confirmed"
          >
                <MenuItem value="1">yes </MenuItem>
                <MenuItem value="0">no</MenuItem>
                </Field>
   
          )}
          {type == "final_inspection" && (
            <>
              <Grid className="display" mt={6}>
               

<Grid className="display" >
             

             <Field
                           required
                           type="date"
                           name="final_inspection_date"
                           component={MuiTextField}
                           label="date"
                         />
             
             <div style={{marginLeft:"10px"}}>
             <Field
                           required
                           type="time"
                           name="final_inspection_time"
                           component={MuiTextField}
                           label="time"
                         />
                         </div>
                           </Grid>
              </Grid>
            </>
          )}

          {type == "upload_to_pto" && (
            <>
              <Grid className="display" mt={6}>
              
                 <Field
                           required
                           type="date"
                           name="upload_to_pto_date"
                           component={MuiTextField}
                           label="date"
                         />
              </Grid>
            </>
          )}

          {type == "coordinate_client_installer_installation" && (
            <>
              <Grid className="display" mt={6}>
               
                <Field
                           required
                           type="time"
                           name="coordinate_client_time"
                           component={MuiTextField}
                           label="time"
                         />
              </Grid>
              <Grid className="display" mt={2}>
               
                      
                      <Field
              required
              name="confirm_survey_time"
              component={MuiSelectField}
              label="Survey Time Confirmation ?"
            >
                    <MenuItem value="1">Yes </MenuItem>
                    <MenuItem value="0">No</MenuItem>
                    </Field>
              
              </Grid>
              <Grid className="display" mt={2}>
               
                              <Field
              required
              name="home_during_installation"
              component={MuiSelectField}
              label=" Home During Installation ?"
            >
                    <MenuItem value="1">Yes </MenuItem>
                    <MenuItem value="0">No</MenuItem>
                    </Field>
              </Grid>
              <Grid className="display" mt={2}>
            
                                    <Field
              required
              name="animals_outside_property"
              component={MuiSelectField}
              label="animals outside ?"
            >
                    <MenuItem value="1">Yes </MenuItem>
                    <MenuItem value="0">No</MenuItem>
                    </Field>
            
              </Grid>

              <Grid className="display">
             
                <Field
              required
              type="text"
              name="customer_comments"
              component={MuiTextField}
              label="Customer Comments"
            />
              </Grid>
            </>
          )}
          <Grid className="display" style={{ width: "300px", marginTop: "5px" }}>
       
                <Field
              required
              multiline
              rows={4}
              type="text"
              name="comment"
              component={MuiTextField}
              label="Notes"
            />
          </Grid>
          {type !== "call_designer" && (
            <Grid>
              <>
                <div mt={4}>
                  <IconButton onClick={() => handleShowUploadFiles()}>
                    <Typography>
                      <span style={{ color: "black", fontWeight: "bold" }}>
                        Upload Files
                      </span>{" "}
                    </Typography>{" "}
                    {!showUpload && (
                      <CloudUploadIcon style={{ marginLeft: "30px" }} />
                    )}
                  </IconButton>

                  {showUpload && (
                    <IconButton onClick={handlecloseUpload}>
                      <CloseIcon />
                    </IconButton>
                  )}
                </div>

                {showUpload && (
                  <Grid xs={12} mt={4}>
                    <Grid
                      className="display"
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <FormControl variant="outlined">
                        <label htmlFor="contained-button-file">
                          <Input
                            accept="image/*,video/*"
                            id="contained-button-file"
                            multiple
                            type="file"
                            // value={utiltyBill.media}
                            onChange={handleFileEvent}
                          />
                          <Button component="span" className="btn_upload">
                            <span className="icon">
                              <Add />
                            </span>
                            <span className="title"> Upload Files</span>
                          </Button>
                        </label>
                        {/* </Grid> */}
                      </FormControl>
                      {UpdatedUploadFiles.map((file, index) => (
                        <div>
                          <ListItem
                            secondaryAction={
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => handleRemoveItem(file)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            }
                          >
                            <ListItemAvatar style={{ display: "flex" }}>
                              <FolderIcon />
                              <Typography>{file.name}</Typography>{" "}
                            </ListItemAvatar>
                            <ListItemText
                            // primary={file.image}
                            // secondary={"Secondary text"}
                            />
                          </ListItem>
                        </div>
                      ))}
                      <Grid className="display" mt={4}></Grid>
                    </Grid>
                  </Grid>
                )}
              </>
            </Grid>
          )}

<DialogActions>
        <PrimaryButton
          fullWidth
          type="submit"
          style={{
            padding: "0",
            width: "85%",
            margin: "15px auto",
            height: "42px",
          }}
          className="saveButton"
          //onClick={handle_submit}
        >
          Save
        </PrimaryButton>
      </DialogActions>
          </form>
        </ModalContent>
      </DialogContent>
     
    </Dialog>
  );
};
const Input = styled.input`
  display: none;
`;

export default connect(null)(
  reduxForm({ form: "StatusFileCommentModalForCardsForm", enableReinitialize: true })(
    StatusFileCommentModalForCards
  )
);
