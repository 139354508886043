import React, { useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import CreateCharterForm from "../../Pages/CreateCharterForm/CreateCharterForm";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
const steps = ["", "", "", "", ""];
function CharterSteper() {
  const { ticket_id } = useParams();
  const [activeStep, setActiveStep] = useState(0);
  const single_ticket = useSelector((state) => state.single_ticket);
  console.log(single_ticket, "single_ticketfsfdfs");
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={index} {...stepProps}>
                <StepLabel {...labelProps}></StepLabel>
              </Step>
            );
          })}
        </Stepper>

        {activeStep === steps.length ? (
          <p>completed</p>
        ) : (
          <CreateCharterForm
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            ticket_id={ticket_id}
            steps={steps}
          />
        )}
      </Box>
    </>
  );
}

export default CharterSteper;
