import React, { useState, useEffect } from "react";
import { reduxForm, Field } from "redux-form";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Grid, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { PrimaryButton } from "../../../Layouts/ButtonsControlled";
import { ModalContent } from "../../Modals/PmAssignModal/PmAssignModalStyle";
import { getTicketStatus } from "../../../Redux/Slices/TicketStatus/TicketStatusSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import {
  store_city_office_utilities,
  update_city_office_utilities,
  get_city_office_utilities_data
} from "../../../Redux/Slices/city_office_utilities_slice/city_office_utilities_slice";
import MuiSelectField from "../../../Common/MuiSelectField/MuiSelectField";
import MuiTextField from "../../../Common/muiTextField/MuiTextField";
const CallCityofficeutilityModal = ({
  handleSubmit,
  handleopenModal,
  handleCloseModal,
  set_Open_Modal,
  call_suppliers_status,
  city_office_utilities_details,
  card_status,
  CallCityOffice_status,
  set_edit_modal,
  city_office_utilities_data,
  // type,
  card_type
}) => {
  const dispatch = useDispatch();
  const formNotify = useSelector((state) => state.form);
  const { lead_id } = useParams();
  const { type } = useParams();
  const params = useParams();
  console.log(params , "kk")
  const [is_can_get_permit_faster, set_is_can_get_permit_faster] =
    React.useState(0);

  const handleChange_is_can_get_permit_faster = (event) => {
    set_is_can_get_permit_faster(event.target.checked);
  };
  console.log(type, "type21212");
  console.log(CallCityOffice_status , "CallCityOffice_status")
  useEffect(() => {
    // (CallCityOffice_status == "5" || card_status == "5") &&
    set_is_can_get_permit_faster(
      city_office_utilities_details?.is_can_get_permit_faster
    );
  }, []);
  const submit = async (values) => {
    const reqData = {
      ticket_id: +lead_id,
      id: city_office_utilities_details?.id,
      type:  card_type == "call_city_office"
      ? "permit"
      : card_type == "call_city_utility"
      ? "pto_submittal"
      : "",
      is_can_get_permit_faster: is_can_get_permit_faster ? 1 : 0,
    };

    const update_reqData = {
      ticket_id: +lead_id,
      id: city_office_utilities_details?.id,
      type:  type == "call_city_office"
      ? "permit"
      : type == "call_city_utility"
      ? "pto_submittal"
      : "",
      is_can_get_permit_faster: is_can_get_permit_faster ? 1 : 0,
    };

    const reqDataType = {
      type:
      card_type == "call_city_office"
          ? "permit"
          : card_type == "call_city_utility"
          ? "pto_submittal"
          : "",
      // type: type == "call_city_utility" && "pto_submittal",
      ticket_id: lead_id,
    };

    city_office_utilities_data == undefined
      ? (await dispatch(
          store_city_office_utilities({ ...values, ...reqData })
        )) && (await set_Open_Modal(false))
        (await dispatch(getTicketStatus(lead_id)))
      : (await dispatch(
          update_city_office_utilities({ ...values, ...update_reqData })
        )) && (await set_edit_modal(false)) ;
        await (dispatch(get_city_office_utilities_data(reqDataType)))



        
  };

  return (
    <Dialog
      maxWidth={"md"}
      fullWidth
      open={handleopenModal}
      onClose={handleCloseModal}
    >
      <DialogContent>
        <ModalContent>
          <Box>
            <form onSubmit={handleSubmit((values) => submit(values))}>
              <Grid>
                <Grid
                  container
                  columns={12}
                  spacing={5}
                  //   justifyContent="center"
                >
                  <Grid item xs={12} sm={5} lg={6}>
                    <Field
                      required
                      type="text"
                      name="timeline"
                      component={MuiTextField}
                      label="timeline"
                    />
                  </Grid>

                  <Grid item xs={12} sm={5} lg={6}>
                    <Field
                      required
                      type="text"
                      name="person_to_follow_name"
                      component={MuiTextField}
                      label="person to follow name"
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  columns={12}
                  spacing={5}
                  //   justifyContent="center"
                >
                  <Grid item xs={12} sm={5} lg={6}>
                    <Field
                      required
                      type="number"
                      name="person_to_follow_phone"
                      component={MuiTextField}
                      label="person to follow phone"
                    />
                  </Grid>

                  <Grid item xs={12} sm={5} lg={6}>
                    <Field
                      required
                      type="text"
                      name="person_to_follow_email"
                      component={MuiTextField}
                      label="person to follow email"
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  columns={12}
                  spacing={5}
                  //   justifyContent="center"
                >
                  <Grid item xs={12} sm={5} lg={6}>
                    <Field
                      required
                      type="text"
                      name="inspection_type"
                      component={MuiTextField}
                      label="inspection type"
                    />
                  </Grid>

                  <Grid item xs={12} sm={5} lg={6}>
                    <Field
                      required
                      name="payment_type"
                      component={MuiSelectField}
                      label="payment type"
                    >
                      <MenuItem value="online" label="option 1">
                        online
                      </MenuItem>
                      <MenuItem value="phone" label="option 2">
                        phone
                      </MenuItem>
                      <MenuItem value="check" label="option 2">
                        check
                      </MenuItem>
                    </Field>
                  </Grid>
                </Grid>
                {formNotify?.CallCityofficeutilityForm?.values?.payment_type ==
                  "check" && (
                  <Grid
                    container
                    columns={12}
                    spacing={5}
                    //   justifyContent="center"
                  >
                    <Grid item xs={12} sm={5} lg={6}>
                      <Field
                        required={
                          formNotify?.CallCityofficeutilityForm?.values
                            ?.payment_type == "check"
                        }
                        type="text"
                        name="send_check_to"
                        component={MuiTextField}
                        label="send check to"
                      />
                    </Grid>

                    <Grid item xs={12} sm={5} lg={6}>
                      <Field
                        required={
                          formNotify?.CallCityofficeutilityForm?.values
                            ?.payment_type == "check"
                        }
                        type="text"
                        name="information_required_to_send_check"
                        component={MuiTextField}
                        label="information required to send check"
                      />
                    </Grid>
                  </Grid>
                )}

                <Grid container columns={12} spacing={2}>
                  <Grid item xs={12} sm={5} lg={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={is_can_get_permit_faster}
                          name="is_can_get_permit_faster"
                          onChange={handleChange_is_can_get_permit_faster}
                          checked={is_can_get_permit_faster}
                        />
                      }
                      label="is can get permit faster"
                    />
                  </Grid>
                  {is_can_get_permit_faster == "1" && (
                    <Grid item xs={12} sm={5} lg={6}>
                      <Field
                        required={is_can_get_permit_faster == "1"}
                        type="text"
                        name="method_to_get_permit_faster"
                        component={MuiTextField}
                        label="method to get permit faster"
                      />
                    </Grid>
                  )}
                </Grid>

                <Grid className="" style={{ marginTop: "10px" }}>
                  <Field
                    required
                    name="status"
                    component={MuiSelectField}
                    label="status"
                  >
                    <MenuItem value="pending">pending </MenuItem>
                    <MenuItem value="completed">completed</MenuItem>
                  </Field>
                </Grid>
              </Grid>
              <DialogActions>
                <PrimaryButton
                  fullWidth
                  type="submit"
                  style={{
                    padding: "0",
                    width: "85%",
                    margin: "15px auto",
                    height: "42px",
                  }}
                  className="saveButton"
                >
                  {city_office_utilities_data == undefined
                    ? "Save"
                    : "Update"}
                </PrimaryButton>
              </DialogActions>
            </form>
          </Box>
        </ModalContent>
      </DialogContent>
    </Dialog>
  );
};

export default reduxForm({
  form: "CallCityofficeutilityForm",
})(CallCityofficeutilityModal);
