import styled from "styled-components";
export const UserInfo = styled.div`
  .userdropdown {
    background: #f5fdff;
    width: 215px;
    color: black;
    margin: 0px 7px;
    display: flex;
    justify-content: space-between;
    &:hover {
      background: #f5fdff;
    }
  }
  .userName {
    margin: 4px 0px 0px 8px;
  }
`;
