import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import LeadSideInfo from "../../Components/LeadSideInfo/LeadSideInfo";
import { Outlet, Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation } from "react-router-dom";
function SolarSolution() {
  const location = useLocation();
  return (
    <>
      <Grid container columns={18}>
        <Grid item lg={4} md={6} xs={18}>
          <LeadSideInfo />
         
        </Grid>

        <Grid item lg={14} md={12} xs={18}>
          
          {location.pathname === "/home/SolarSolution/start-project" && (
            <>
          
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  position: "relative",
                  marginBottom: "20px",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    left: "1%",
                  }}
                >
                  <Link to="/home/SolarSolution/tabs">
                    <Button
                      startIcon={<ArrowBackIcon></ArrowBackIcon>}
                      style={{ color: "black", fontSize: "18px" }}
                    >
                      Back
                    </Button>
                  </Link>
                </div>

                <div>
                  <Typography variant="h6">Process</Typography>
                </div>
                
              </div>
            </>
          )}
          <Outlet></Outlet>
        </Grid>
      </Grid>
    </>
  );
}

export default SolarSolution;
