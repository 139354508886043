import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Grid, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import { PrimaryButton } from "../../../Layouts/ButtonsControlled";
import { ModalContent } from "../../Modals/PmAssignModal/PmAssignModalStyle";
import { getTicketStatus } from "../../../Redux/Slices/TicketStatus/TicketStatusSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import {
  STORE_second_step_of_survey_Action,
  Edit_second_step_of_survey_Action,
  GET_second_step_of_survey_Action,
} from "../../../Redux/Slices/SurveyStepsSlice/SurveyStepsSlice";
const SecondStepOfSurveyModal = ({
  handleopenModal,
  handleCloseModal,
  set_Open_Modal,
  openEditModal,
  survey_data,
  setOpenEditModal,
}) => {
  const dispatch = useDispatch();
  const { lead_id } = useParams();
  const [compare_items, set_compare_items] = React.useState(0);
  const [status, set_status] = useState();
  console.log(survey_data?.SurveySteps?.data?.compare_items, "survey_data");
  console.log(openEditModal , "openEditModal")
  useEffect(() => {
    // set_compare_items(survey_data?.SurveySteps?.data?.compare_items);
    // dispatch(GET_second_step_of_survey_Action());
  }, []);
  const handleChange_compare_items = (event) => {
    set_compare_items(event.target.checked);
    console.log(event.target);
    console.log(event.target.checked);
  };

  const HandelSubmit = async (e) => {
    e.preventDefault();
    const reqData = {
      ticket_id: +lead_id,
      status: status,
      compare_items: compare_items ? 1 : 0,
      id: openEditModal && survey_data?.SurveySteps?.data?.id,
    };
    openEditModal
      ? (await dispatch(Edit_second_step_of_survey_Action(reqData))) &&
        (await setOpenEditModal(false)) &&
        (await dispatch(GET_second_step_of_survey_Action()))
      : await dispatch(STORE_second_step_of_survey_Action(reqData)) &&
      await setOpenEditModal(false) &&
   await set_Open_Modal(false) &&
    await setOpenEditModal(false) && 
    await dispatch(GET_second_step_of_survey_Action()) &&
    await dispatch(getTicketStatus(lead_id));
  };

  return (
    <Dialog
      maxWidth={"md"}
      fullWidth
      open={handleopenModal}
      onClose={handleCloseModal}
    >
      <DialogContent>
        <ModalContent>
          <Box>
            <form onSubmit={HandelSubmit}>
              <Grid>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={
                          openEditModal
                            ? survey_data?.SurveySteps?.data?.compare_items
                            : compare_items
                        }
                        name="compare_items"
                        onChange={handleChange_compare_items}
                      />
                    }
                    label="compare items ?"
                  />

                  <Grid className="" style={{ marginTop: "10px" }}>
                    <FormControl variant="outlined">
                      <InputLabel htmlFor="component-simple">status</InputLabel>
                      <Select
                        required
                        style={{ width: "300px", marginTop: "15px" }}
                        value={
                          openEditModal
                            ? survey_data?.SurveySteps?.data?.status
                            : status
                        }
                        onChange={(e) => set_status(e.target.value)}
                      >
                        <MenuItem value="pending">pending </MenuItem>
                        <MenuItem value="completed">completed</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </FormGroup>
              </Grid>

              <DialogActions>
                <PrimaryButton
                  type="submit"
                  fullWidth
                  style={{
                    padding: "0",
                    width: "85%",
                    margin: "15px auto",
                    height: "42px",
                  }}
                  className="saveButton"
                >
                  {openEditModal ? "update" : "Save"}
                </PrimaryButton>
              </DialogActions>
            </form>
          </Box>
        </ModalContent>
      </DialogContent>
    </Dialog>
  );
};

export default SecondStepOfSurveyModal;
