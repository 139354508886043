import axios from "axios";
import { craete_card ,  get_single_card , update_single_card , delete_single_card}  from "../../../Api/Api";

const CreateCardAction = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${craete_card}`,
    headers: headers,
    data: data,
  });
  return response.data;
};

const getSingleCardAction = async (id) => {
    const { token } = JSON.parse(localStorage.getItem("user"));
    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    };
    const response = await axios({
      method: "GET",
      baseURL: `${get_single_card}/${id}`,
      headers: headers,
      // data: data,
    });
    return response.data;
  };

  const UpdateCardAction = async (data) => {
    const { token } = JSON.parse(localStorage.getItem("user"));
    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    };
    const response = await axios({
      method: "POST",
      baseURL: `${update_single_card}`,
      headers: headers,
      data: data,
    });
    return response.data;
  };

  const DeleteCardAction = async (data) => {
    const { token } = JSON.parse(localStorage.getItem("user"));
    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    };
    const response = await axios({
      method: "POST",
      baseURL: `${delete_single_card}`,
      headers: headers,
      data: data,
    });
    return response.data;
  };



const CreateCardService = {
  CreateCardAction,
  getSingleCardAction,
  UpdateCardAction,
  DeleteCardAction

};
export default CreateCardService;
