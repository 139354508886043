import React, { useEffect, useState } from "react";
import { Button, Typography, Grid } from "@mui/material";
import { Box } from "@mui/system";
import EditIcon from "@mui/icons-material/Edit";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_first_step_of_survey_Action,
  Edit_quality_control_step_of_survey_Action,
  GET_second_step_of_survey_Action,
  GET_quality_control_step_of_survey_Action,
  Edit_second_step_of_survey_Action,
  STORE_second_step_of_survey_Action,
} from "../../Redux/Slices/SurveyStepsSlice/SurveyStepsSlice";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import FavoriteIcon from "@mui/icons-material/Favorite";

import Card from "@mui/material/Card";
import ShareIcon from "@mui/icons-material/Share";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import { getTicketStatus } from "../../Redux/Slices/TicketStatus/TicketStatusSlice";
import { GET_survey_images_Action } from "../../Redux/Slices/SiteSurverImagesSlice/SiteSurveyImagesSlice";
import LottieAnimation from "../../Components/LottieAnimation/LottieAnimation";
import loading from "../../Assets/lottie/loading.json";
import SecondStepOfSurveyModal from "../Modals/SurveyModals/SecondStepOfSurveyModal";
import FirstStepOfSurveyModal from "../Modals/SurveyModals/FirstStepOfSurveyModal";
import QualityControlModal from "../Modals/SurveyModals/QualityControlModal";
import { CardActionArea } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { PrimaryButton } from "../../Layouts/ButtonsControlled";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom";

const SurveyStepsDetails = ({ lead_id, type }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const SurveySteps_details = useSelector((state) => state?.SurveySteps);
  const SurveyImages = useSelector((state) => state?.SurveyImages);
  const cardStatus = useSelector((state) => state.TicketStatus);
  const surveyor_second_step_status =
    cardStatus?.TicketStatus?.data?.surveyor_second_step;
  const surveyor_first_step_status =
    cardStatus?.TicketStatus?.data?.surveyor_first_step;
    console.log(surveyor_first_step_status , "surveyor_first_step_status")
  const surveyor_quality_control_step_status =
    cardStatus?.TicketStatus?.data?.surveyor_quality_control;
  const survey_media = SurveyImages?.SurveyImages?.data?.media;
  const survey_details_data = SurveySteps_details?.SurveySteps?.data;
  const [openEdit_second_step_Modal, setOpenEdit_second_step_Modal] =
    useState(false);
  const [openEdit_first_step_Modal, setOpenEdit_first_step_Modal] =
    useState(false);
  const [openEdit_quality_control_Modal, setOpenEdit_quality_control_Modal] =
    useState(false);
  const [images_status, set_images_status_status] = useState("");
  const [compare_items, set_compare_items] = React.useState(0);
  const [status, set_status] = useState();
  console.log(type, "typeeeeeeeeeeee");
  console.log(SurveySteps_details, "SurveySteps_details123");
  console.log(
    cardStatus?.TicketStatus?.data?.surveyor_first_step,
    "cardStatusmmmnnnn"
  );
  console.log(SurveySteps_details?.SurveySteps, "SurveySteps_detailsnnnn87");
  console.log(SurveyImages?.SurveyImages?.data?.media, "SurveyImages");
  const handleChange_compare_items = (event) => {
    set_compare_items(event.target.checked);
    console.log(event.target);
    console.log(event.target.checked);
  };
  const handleopen_second_step_Modal = () => {
    setOpenEdit_second_step_Modal(true);
    console.log("tst open", openEdit_second_step_Modal);
  };
  const handleClose_second_step_Modal = () => {
    setOpenEdit_second_step_Modal(false);
  };

  const handleopen_first_step_Modal = () => {
    setOpenEdit_first_step_Modal(true);
    console.log("tst open", openEdit_second_step_Modal);
  };
  const handleClose_first_step_Modal = () => {
    setOpenEdit_first_step_Modal(false);
  };

  const handleopen_quality_control_Modal = () => {
    setOpenEdit_quality_control_Modal(true);
    console.log("tst open", openEdit_second_step_Modal);
  };
  const handleClose_quality_control_Modal = () => {
    setOpenEdit_quality_control_Modal(false);
  };
  console.log(SurveySteps_details, "SurveySteps_detailsmmmm");
  console.log(
    SurveySteps_details?.SurveySteps?.data,
    "SurveySteps_details?.data?.SurveySteps?.data?.status1212121"
  );

  useEffect(() => {
    type == "surveyor_second_step" &&
      dispatch(GET_second_step_of_survey_Action(lead_id));
    type == "surveyor_first_step" &&
      dispatch(GET_first_step_of_survey_Action(lead_id));
    type == "surveyor_quality_control" &&
      dispatch(GET_quality_control_step_of_survey_Action(lead_id));
  }, [
    type,
    openEdit_second_step_Modal,
    openEdit_first_step_Modal,
    openEdit_quality_control_Modal,
  ]);

  useEffect(() => {
    const reqData = {
      id: lead_id,
      status: images_status,
    };
    type == "surveyor_second_step" &&
      dispatch(GET_survey_images_Action(reqData));
  }, [status]);

  useEffect(() => {
    dispatch(getTicketStatus(lead_id));
  }, [lead_id]);

  useEffect(() => {
    type == "surveyor_second_step" &&
      dispatch(GET_second_step_of_survey_Action(lead_id));
  }, []);

  const handle_open_edit_modal = () => {
    console.log("open edit modal");
  };
  const first_step_survey_details = () => (
    <MainTabsStyled>
      {SurveySteps_details?.isLodaing ? (
        <LottieAnimation lotti={loading} width={180} height={180} />
      ) : (
        <Box
          sx={{ width: "100%", typography: "body1" }}
          style={{ backgroundColor: "white" }}
        >
          {SurveySteps_details?.SurveySteps?.data == null ? (
            <Typography align="center">There is no data </Typography>
          ) : (
            <div class="main-container">
              <div class="heading">
                <p class="heading__credits">
                  {" "}
                  First Step of site survey details{" "}
                </p>
              </div>
              
              <Grid item xs={11} style={{ padding: "10px 55px" }} >
      <Card

        style={{
          background: "rgb(249, 245, 255)",
          border: "1px solid rgb(214, 187, 251)",
          borderRadius: "8px",
          padding: "1rem",
          margin: "0.5rem 0px",
          position: "relative",


        }}
      >


        <CardContent
          style={{ marginTop: "40px" }}

        >
              <div class="">
                <div class="">
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    <Grid item xs={12}>
                      <span style={{ color: "#421313", fontWeight: "bold" }}>
                        confirm customer surveyor arrival :{" "}
                      </span>
                      {SurveySteps_details?.SurveySteps?.data
                        ?.confirm_customer_surveyor_arrival == 1 ? (
                        <Typography>yes</Typography>
                      ) : (
                        <Typography>No</Typography>
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      <span style={{ color: "#421313", fontWeight: "bold" }}>
                        confirm surveyor on the way :{" "}
                      </span>
                      {SurveySteps_details?.SurveySteps?.data
                        ?.confirm_surveyor_on_the_way == 1 ? (
                        <Typography>yes</Typography>
                      ) : (
                        <Typography>No</Typography>
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      <span style={{ color: "#421313", fontWeight: "bold" }}>
                        Status :
                      </span>
                      {SurveySteps_details?.SurveySteps?.data?.status}
                    </Grid>

                    <Grid item xs={12}>
                      <span style={{ color: "#421313", fontWeight: "bold" }}>
                        comment :
                      </span>
                      {SurveySteps_details?.SurveySteps?.data?.comment}
                    </Grid>

                    <Grid item xs={12}>
                      <span style={{ color: "#421313", fontWeight: "bold" }}>
                        {" "}
                        created at :{" "}
                      </span>

                      {new Date(
                        SurveySteps_details?.SurveySteps?.data?.created_at
                      ).toLocaleDateString("en-US", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                      })}
                    </Grid>

                    <Grid item xs={12}>
                      <span style={{ color: "#421313", fontWeight: "bold" }}>
                        {" "}
                        updated at :{" "}
                      </span>

                      {new Date(
                        SurveySteps_details?.SurveySteps?.data?.updated_at
                      ).toLocaleDateString("en-US", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                      })}
                    </Grid>
                  </Grid>

                  <p class="card__apply">
                    <Button
                      //   onClick={handleopenModal}
                      onClick={handleopen_first_step_Modal}
                    >
                      {" "}
                      Update <EditIcon />
                    </Button>
                  </p>
                </div>
              </div>
              </CardContent>
              </Card>
              </Grid>

            </div>
          )}
        </Box>
      )}

      {openEdit_first_step_Modal && (
        <FirstStepOfSurveyModal
          setOpenEditModal={setOpenEdit_first_step_Modal}
          handleopenModal={handleopen_first_step_Modal}
          handleCloseModal={handleClose_first_step_Modal}
          lead_id={lead_id}
          survey_data={SurveySteps_details}
          openEditModal={openEdit_second_step_Modal}
          surveyor_first_step_status={surveyor_first_step_status}
          survey_details_data={survey_details_data}
          openEdit_first_step_Modal={openEdit_first_step_Modal}
          
        />
      )}
    </MainTabsStyled>
  );

  const surveyor_quality_control = () => (
    <MainTabsStyled>
      {SurveySteps_details?.isLodaing ? (
        <LottieAnimation lotti={loading} width={180} height={180} />
      ) : (
        <Box
          sx={{ width: "100%", typography: "body1" }}
          style={{ backgroundColor: "white" }}
        >
          {SurveySteps_details?.SurveySteps?.data == null ? (
            <Typography align="center">There is no data </Typography>
          ) : (
            <div class="main-container">
              <div class="heading">
                <p class="heading__credits">
                  {" "}
                  quality control site survey details{" "}
                </p>
              </div>
              <div class="cards">
                <div class="card card-5">
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    <Grid item xs={12}>
                      <span style={{ color: "#421313", fontWeight: "bold" }}>
                        was polite :{" "}
                      </span>
                      {SurveySteps_details?.SurveySteps?.data?.was_polite ==
                      1 ? (
                        <Typography>yes</Typography>
                      ) : (
                        <Typography>No</Typography>
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      <span style={{ color: "#421313", fontWeight: "bold" }}>
                        wearing company uniform :{" "}
                      </span>
                      {SurveySteps_details?.SurveySteps?.data
                        ?.wearing_company_uniform == 1 ? (
                        <Typography>yes</Typography>
                      ) : (
                        <Typography>No</Typography>
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      <span style={{ color: "#421313", fontWeight: "bold" }}>
                        rate :
                      </span>
                      {SurveySteps_details?.SurveySteps?.data?.rate}
                    </Grid>

                    <Grid item xs={12}>
                      <span style={{ color: "#421313", fontWeight: "bold" }}>
                        {" "}
                        created at :{" "}
                      </span>

                      {new Date(
                        SurveySteps_details?.SurveySteps?.data?.created_at
                      ).toLocaleDateString("en-US", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                      })}
                    </Grid>

                    <Grid item xs={12}>
                      <span style={{ color: "#421313", fontWeight: "bold" }}>
                        {" "}
                        updated at :{" "}
                      </span>

                      {new Date(
                        SurveySteps_details?.SurveySteps?.data?.updated_at
                      ).toLocaleDateString("en-US", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                      })}
                    </Grid>
                  </Grid>

                  <p class="card__apply">
                    <Button
                      //   onClick={handleopenModal}
                      onClick={handleopen_quality_control_Modal}
                    >
                      {" "}
                      Update <EditIcon />
                    </Button>
                  </p>
                </div>
              </div>
            </div>
          )}
        </Box>
      )}

      {openEdit_quality_control_Modal && (
        <QualityControlModal
          setOpenEditModal={setOpenEdit_first_step_Modal}
          handleopenModal={handleopen_quality_control_Modal}
          handleCloseModal={handleClose_quality_control_Modal}
          lead_id={lead_id}
          survey_data={SurveySteps_details}
          openEditModal={openEdit_second_step_Modal}
          surveyor_quality_control_step_status={
            surveyor_quality_control_step_status
          }
          setOpenEdit_quality_control_Modal={setOpenEdit_quality_control_Modal}
          survey_details_data={survey_details_data}
          openEdit_quality_control_Modal={openEdit_quality_control_Modal}
          initialValues={{
            rate: survey_details_data?.rate,
          }}
        />
      )}
    </MainTabsStyled>
  );
  const second_step_survey_details = () => (
    <>
      <MainTabsStyled>
        {SurveySteps_details?.isLodaing ? (
          <LottieAnimation lotti={loading} width={180} height={180} />
        ) : (
          <Box
            sx={{ width: "100%", typography: "body1" }}
            style={{ backgroundColor: "white" }}
          >
            <div class="main-container">
              <div class="heading">
                {/* <h1 class="heading__title">
                  First Step of Site Survey details{" "}
                </h1> */}
                <p class="heading__credits">
                  {" "}
                  second Step of site survey details{" "}
                </p>
              </div>
              <div class="cards">
                <div class="card card-5">
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    <Grid item xs={12}>
                      <span style={{ color: "#421313", fontWeight: "bold" }}>
                        Compare items :{" "}
                      </span>
                      {SurveySteps_details?.SurveySteps?.data?.compare_items ==
                      1 ? (
                        <Typography>yes</Typography>
                      ) : (
                        <Typography>No</Typography>
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      <span style={{ color: "#421313", fontWeight: "bold" }}>
                        Status :
                      </span>
                      {SurveySteps_details?.SurveySteps?.data?.status}
                    </Grid>

                    <Grid item xs={12}>
                      <span style={{ color: "#421313", fontWeight: "bold" }}>
                        {" "}
                        created at :{" "}
                      </span>

                      {new Date(
                        SurveySteps_details?.SurveySteps?.data?.created_at
                      ).toLocaleDateString("en-US", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                      })}
                    </Grid>
                  </Grid>

                  <p class="card__apply">
                    <Button onClick={handleopen_second_step_Modal}>
                      {" "}
                      Update <EditIcon />
                    </Button>
                  </p>
                </div>
              </div>
            </div>
          </Box>
        )}
      </MainTabsStyled>
    </>
  );
  const HandelSubmit = async (e) => {
    e.preventDefault();
    const reqData = {
      ticket_id: +lead_id,
      status: status,
      compare_items: compare_items ? 1 : 0,
      // id: openEditModal && survey_data?.SurveySteps?.data?.id,
    };
    surveyor_second_step_status == 5
      ? (await dispatch(Edit_second_step_of_survey_Action(reqData))) &&
        (await navigate(`/home/SolarSolution/start-project/${lead_id}`)) &&
        (await dispatch(GET_second_step_of_survey_Action()))
      : await dispatch(STORE_second_step_of_survey_Action(reqData));
    await navigate(`/home/SolarSolution/start-project/${lead_id}`);
    // await setOpenEditModal(false);
    await dispatch(GET_second_step_of_survey_Action());
    await dispatch(getTicketStatus(lead_id));
  };
  const second_step_with_site_survey_images = () => (
    <>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        {survey_media && survey_media.length > 0 ? (
          <>
            <Grid
              container
              // spacing={{ xs: 2, md: 3 }}
              // columns={{ xs: 4, sm: 8, md: 12 }}
              style={{
                marginBottom: "10px",
                padding: "10px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography align="center"> Site Survey Images</Typography>
            </Grid>
            <Grid
              container
              // spacing={{ xs: 2, md: 3 }}
              // columns={{ xs: 4, sm: 8, md: 12 }}
              style={{
                marginTop: "10px",
                padding: "10px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
                style={{ marginBottom: "10px", padding: "10px" }}
              >
                <FormControl variant="outlined">
                  <InputLabel htmlFor="component-simple">
                    {" "}
                    Image status
                  </InputLabel>
                  <Select
                    name="status"
                    style={{ width: "300px", marginTop: "15px" }}
                    value={images_status}
                    onChange={(e) => set_images_status_status(e.target.value)}
                  >
                    <MenuItem value="pending">pending</MenuItem>
                    <MenuItem value="">all</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid
            container
            // spacing={{ xs: 2, md: 3 }}
            // columns={{ xs: 4, sm: 8, md: 12 }}
            style={{
              marginTop: "10px",
              padding: "10px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography align="center">
              {" "}
              No Site survey images founded
            </Typography>
          </Grid>
        )}

        {survey_media &&
          survey_media.length > 0 &&
          survey_media.map((m) => (
            <>
              <Grid item xs={2} sm={4} md={4}>
                <Card sx={{ maxWidth: 345 }}>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      height="180"
                      image={`https://pmb.boxbyld.tech/${m.path}`}
                      alt="green iguana"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        {m.status}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            </>
          ))}
      </Grid>
      <Box>
        {surveyor_second_step_status == 5 &&
        SurveySteps_details?.SurveySteps?.data !== null ? (
          <Grid
            container
            columns={12}
            spacing={5}
            justifyContent="center"
            // mb={3}
            mt={4}
          >
            <Grid item xs={12} sm={12} lg={12}>
              <Card>
                <CardHeader
                  title="Compare items to Site Survey PDF Data"
                  subheader={`Created at: ${new Date(
                    SurveySteps_details?.SurveySteps?.data?.created_at
                  ).toLocaleDateString("en-US", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  })}
                  
                  `}
                />

                <CardContent>
                  <Typography variant="body2" color="text.secondary">
                    Status: {SurveySteps_details?.SurveySteps?.data?.status}
                  </Typography>

                  <Typography variant="body2" color="text.secondary">
                    compare items:{" "}
                    {SurveySteps_details?.SurveySteps?.data?.compare_items == 1
                      ? "Yes "
                      : "No"}
                  </Typography>
                </CardContent>
                <CardActions disableSpacing>
                  <IconButton
                    aria-label="share"
                    // onClick={handleopen_second_step_Modal}
                    onClick={handleopen_second_step_Modal}
                  >
                    <EditIcon />
                  </IconButton>
                </CardActions>
              </Card>
            </Grid>
            {openEdit_second_step_Modal && (
              <SecondStepOfSurveyModal
                setOpenEditModal={setOpenEdit_second_step_Modal}
                handleopenModal={handleopen_second_step_Modal}
                handleCloseModal={handleClose_second_step_Modal}
                lead_id={lead_id}
                survey_data={SurveySteps_details}
                openEditModal={openEdit_second_step_Modal}
              />
            )}
          </Grid>
        ) : (
          survey_media &&
          survey_media.length > 0 && (
            <form onSubmit={HandelSubmit}>
              <Grid style={{ display: "flex" }}>
                <FormGroup>
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={
                            surveyor_second_step_status == 5
                              ? SurveySteps_details?.SurveySteps?.data
                                  ?.compare_items
                              : compare_items
                          }
                          name="compare_items"
                          onChange={handleChange_compare_items}
                        />
                      }
                      label="compare items ?"
                    />
                  </div>
                  <div>
                    <Grid className="" style={{ marginTop: "10px" }}>
                      <FormControl variant="outlined">
                        <InputLabel htmlFor="component-simple">
                          status
                        </InputLabel>
                        <Select
                          required
                          style={{ width: "300px", marginTop: "15px" }}
                          value={
                            surveyor_second_step_status == "5"
                              ? SurveySteps_details?.SurveySteps?.data?.status
                              : status
                          }
                          onChange={(e) => set_status(e.target.value)}
                        >
                          <MenuItem value="pending">pending </MenuItem>
                          <MenuItem value="completed">completed</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </div>
                </FormGroup>
              </Grid>

              <PrimaryButton
                type="submit"
                fullWidth
                style={{
                  padding: "0",
                  width: "85%",
                  margin: "15px auto",
                  height: "42px",
                }}
                className="saveButton"
              >
                {surveyor_second_step_status == 5 ? "update" : "Save"}
              </PrimaryButton>
            </form>
          )
        )}
      </Box>
    </>
  );
  console.log(lead_id, "lead_id");
  console.log(type, "type");
  return (
    <>
      {/* {type == "surveys_second-step" && second_step_survey_details()} */}
      {type == "surveyor_first_step" && first_step_survey_details()}
      {type == "surveyor_second_step" && second_step_with_site_survey_images()}
      {type == "surveyor_quality_control" && surveyor_quality_control()}
    </>
  );
};
const MainTabsStyled = styled.div`
  margin: 1rem 0.8rem;
  border-radius: 5px;
  .main-container {
    padding: 30px;
  }

  .heading {
    text-align: center;
  }

  .heading__title {
    font-weight: 600;
  }

  .heading__credits {
    margin: 10px 0px;
    color: #888888;
    font-size: 25px;
    transition: all 0.5s;
  }

  .heading__link {
    text-decoration: none;
  }
  .heading__credits .heading__link {
    color: inherit;
  }

  /* CARDS */

  .cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .card {
    margin: 20px;
    padding: 20px;
    width: 930px;
    min-height: 350px;
    display: grid;
    grid-template-rows: 20px 50px 1fr 50px;
    border-radius: 10px;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    transition: all 0.2s;
  }

  .card:hover {
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
    transform: scale(1.01);
  }

  .card__link,
  .card__exit,
  .card__icon {
    position: relative;
    text-decoration: none;
    color: rgba(255, 255, 255, 0.9);
  }

  .card__link::after {
    position: absolute;
    top: 25px;
    left: 0;
    content: "";
    width: 0%;
    height: 3px;
    background-color: rgba(255, 255, 255, 0.6);
    transition: all 0.5s;
  }

  .card__link:hover::after {
    width: 100%;
  }

  .card__exit {
    grid-row: 1/2;
    justify-self: end;
  }

  .card__icon {
    grid-row: 2/3;
    font-size: 30px;
  }

  .card__title {
    grid-row: 3/4;
    font-weight: 400;
    color: #ffffff;
  }

  .card__apply {
    grid-row: 4/5;
    align-self: center;
  }

  /* CARD BACKGROUNDS */

  .card-1 {
    background: radial-gradient(#1fe4f5, #3fbafe);
    overflow-wrap: anywhere;
  }

  .card-2 {
    background: radial-gradient(#fbc1cc, #fa99b2);
    overflow-wrap: anywhere;
  }

  .card-3 {
    background: radial-gradient(#76b2fe, #b69efe);
    overflow-wrap: anywhere;
  }

  .card-4 {
    background: radial-gradient(#60efbc, #58d5c9);
    overflow-wrap: anywhere;
  }

  .card-5 {
    background: radial-gradient(#f588d8, #c0a3e5);
    overflow-wrap: anywhere;
  }

  /* RESPONSIVE */

  @media (max-width: 1600px) {
    .cards {
      justify-content: center;
    }
  }
`;
export default SurveyStepsDetails;
