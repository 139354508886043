import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styledd from "styled-components";
import { Box } from "@mui/system";
import { getWorkOrders } from "../../Redux/Slices/WorkOrders/WorkOrdersSlice";
import { getSingleTicket } from "../../Redux/Slices/SingleTicketSlice/SingleTicketSlice";
import { useParams } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LottieAnimation from "../../Components/LottieAnimation/LottieAnimation";
import loading from "../../Assets/lottie/loading.json";
import { Link } from "react-router-dom";
import { PrimaryButton } from "../../Layouts/ButtonsControlled";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import PmAssignModal from "../../Components/Modals/PmAssignModal/PmAssignModal";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const WokOrdersList = () => {
  const dispatch = useDispatch();
  const { lead_id } = useParams();
  const work_order_list = useSelector((state) => state.workOrders);
  const single_ticket = useSelector((state) => state.single_ticket);
  const assigned_ticket =
    single_ticket?.singleTicket?.data?.ticket?.user_id == 0;
  const [openTicketModal, setOpenTicketModal] = useState(false);

  const handleOpenTicketModal = (e) => {
    setOpenTicketModal(true);

    console.log("open modal");
  };
  const handleCloseTicketModal = () => {
    setOpenTicketModal(false);
  };
  console.log(
    single_ticket?.singleTicket?.data?.ticket?.user_id,
    "assigned_usersdsdsdsdsssdsssdssd"
  );
  console.log(single_ticket?.isLodaing, "single_ticketsingle_ticket");
  console.log(work_order_list?.workOrders?.data, "work_order_list");
  console.log(work_order_list, "work_order_list loading");

  useEffect(() => {
    dispatch(getSingleTicket(lead_id));
    // dispatch(getInstallersListAction(state_id));
  }, []);
  useEffect(() => {
    dispatch(getWorkOrders(lead_id));
  }, [lead_id]);
  //
  return (
    <MainTabsStyled>
      <Box
        sx={{ width: "100%", typography: "body1" }}
        style={{ backgroundColor: "white" }}
      >
        {work_order_list?.isLodaing && single_ticket?.isLodaing ? (
          <LottieAnimation lotti={loading} width={180} height={180} />
        ) : (
          <>
            <Grid style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <Typography variant="h6" align="center" m={3} mt={2}>
                  {" "}
                  Work Orders List
                </Typography>
              </div>
              <div>
                {" "}
                {!assigned_ticket && (
                  <Link to={`/home/Create_work_order/${lead_id}`}>
                    <Button
                      color="success"
                      variant="contained"
                      style={{ margin: "10px" }}
                      className="saveButton"
                    >
                      Add Work Order
                    </Button>
                  </Link>
                )}
                {assigned_ticket && (
                  <Button
                    variant="contained"
                    style={{ margin: "10px" }}
                    endIcon={<SendIcon />}
                    onClick={() => handleOpenTicketModal()}
                  >
                    assign this ticket to pm agints to add work Order
                  </Button>
                )}
              </div>
            </Grid>

            {work_order_list?.workOrders?.data &&
            work_order_list?.workOrders?.data?.length > 0 ? (
              <div>
                {work_order_list?.workOrders?.data?.map((work_order) => (
                  <>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography stye={{ fontWeight: "bold" }}>
                          {work_order?.work_description}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {/* <Typography>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Suspendisse malesuada lacus ex, sit amet
                            blandit leo lobortis eget.
                          </Typography> */}
                        <Grid item>
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="baseline"
                            columns={18}
                            spacing={4}
                            style={{ textAlign: "left" }}
                          >
                            <Grid item lg={18} md={18} sm={18} xs={18}>
                              <Grid item lg={6} md={6} sm={12} xs={12} mb={1}>
                                <p>
                                  Installer Name:{" "}
                                  <Value>{work_order?.installer_name}</Value>
                                </p>
                              </Grid>

                              <Grid item lg={6} md={6} sm={12} xs={12} mb={1}>
                                <p>
                                  Installer Email:{" "}
                                  <Value>{work_order?.installer_email}</Value>
                                </p>
                              </Grid>

                              <Grid item lg={6} md={6} sm={12} xs={12} mb={1}>
                                <p>
                                Installer approval:{" "}
                                  <Value>{work_order?.installer_approval}</Value>
                                </p>
                              </Grid>

                              <Grid item lg={6} md={6} sm={12} xs={12} mb={1}>
                                <p>
                                  Tax rate:{" "}
                                  <Value>{work_order?.tax_rate}</Value>
                                </p>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>

                        {work_order?.work_order_labors?.length > 0 && (
                          <>
                            <Typography variant="h6">
                              Work Order Labors (Total:{" "}
                              {work_order?.labor_total})
                            </Typography>

                            <TableContainer component={Paper}>
                              <Table
                                sx={{ minWidth: 700 }}
                                aria-label="customized table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <StyledTableCell>
                                      description
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                      price
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                      Quantity
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                      Total&nbsp;(g)
                                    </StyledTableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {work_order?.work_order_labors?.map((row) => (
                                    <StyledTableRow key={row.id}>
                                      <StyledTableCell
                                        component="th"
                                        scope="row"
                                      >
                                        {row?.description}
                                      </StyledTableCell>
                                      <StyledTableCell align="right">
                                        {row?.price}
                                      </StyledTableCell>
                                      <StyledTableCell align="right">
                                        {row?.qty}
                                      </StyledTableCell>
                                      <StyledTableCell align="right">
                                        {row?.total}
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  </>
                ))}
              </div>
            ) : (
              <Grid>
                <Typography
                  align="center"
                  variant="h5"
                  style={{ margin: "10px 0px" }}
                >
                  there are no work orders yet
                </Typography>
              </Grid>
            )}
          </>
        )}
      </Box>
      {openTicketModal && (
        <PmAssignModal
          handleCloseTicketModal={handleCloseTicketModal}
          openTicketModal={openTicketModal}
          //selectedTicket={selectedTicket}
          ticket_id={lead_id}
          setOpenTicketModal={setOpenTicketModal}
        />
      )}
    </MainTabsStyled>
  );
};

const MainTabsStyled = styledd.div`
  margin: 1rem 0.8rem;
  border-radius: 5px;
`;
const Value = styledd.span`
  color: rgb(136, 136, 136);
  font-weight:bold
`;
export default WokOrdersList;
