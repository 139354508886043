import React, { useState } from "react";
import {
  Grid,
  FormControl,
  IconButton,
  Button,
  Typography,
  ListItemText,
  ListItem,
} from "@mui/material";
import styled from "styled-components";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloseIcon from "@mui/icons-material/Close";
import { PrimaryButton } from ".././../Layouts/ButtonsControlled";
import FolderIcon from "@mui/icons-material/Folder";
import DeleteIcon from "@mui/icons-material/Delete";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { Add } from "@mui/icons-material";
import {
  getIssues,
  Upload_issue,
  delete_Issues,
} from "../../Redux/Slices/CreateIssue/Create_Issue_Slice";
import { useDispatch } from "react-redux";
const UploadFilesForCard = ({
  handleShowUploadFiles,
  issue,
  selectedIssue,
  lead_id,
  type,
  setShowUpload,
  showUpload,
  uploadedFiles,
  setUploadedFiles,
  setUpdatedUploadedFiles,
  UpdatedUploadFiles,
  MAX_COUNT,
}) => {
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  //   const [uploadedFiles, setUploadedFiles] = useState([]);
  //   const [UpdatedUploadFiles, setUpdatedUploadedFiles] = useState([]);
  const [fileLimit, setFileLimit] = useState(false);
  const handlecloseUpload = () => {
    setShowUpload(false);
  };

  console.log(uploadedFiles, UpdatedUploadFiles, "uploadedFiles");
  const handleUploadFiles = (files) => {
    const uploaded = [...UpdatedUploadFiles];
    let limitExceeded = false;
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        setUpdatedUploadedFiles(uploaded);
        if (uploaded.length === MAX_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_COUNT) {
          alert(`You can only add a maximum of ${MAX_COUNT} files`);
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    });
    if (!limitExceeded) {
      setUploadedFiles(uploaded);
    }
  };

  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };

  //   const handleRemoveItem = (file) => {
  //     const name = UpdatedUploadFiles.map((file) => file.name);
  //     setUpdatedUploadedFiles(
  //       UpdatedUploadFiles.filter((item) => item.name !== file.name)
  //     );
  //     console.log(name, "name");
  //     console.log(UpdatedUploadFiles, "UpdatedUploadFilesssssssss");
  //   };

  //   const handleUploadIssues = async () => {
  //     const formData = new FormData();
  //     uploadedFiles.map((e, i) => {
  //       // for (const property in e) {
  //       //   console.log(`${property}: ${e[property]}`);
  //       formData.append(`media[${i}]`, e);
  //       // }
  //     });
  //     formData.append("pm_status_issue_id", selectedIssue.id);

  //     await dispatch(Upload_issue(formData));
  //     const reqData = { id: lead_id, type: type };
  //     await dispatch(getIssues(reqData));
  //     console.log(formData, "formData", files, "files");
  //   };
  return (
    <>
      <IconButton onClick={() => handleShowUploadFiles(selectedIssue)}>
        <Typography>
          <span style={{ color: "black", fontWeight: "bold" }}>
            Upload Filesdd
          </span>{" "}
        </Typography>{" "}
        {!showUpload && <CloudUploadIcon style={{ marginLeft: "30px" }} />}
      </IconButton>
      {showUpload && (
        <IconButton onClick={handlecloseUpload}>
          <CloseIcon />
        </IconButton>
      )}

      {showUpload && (
        <Grid xs={12}>
          <Grid
            className="display"
            mt={4}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <FormControl variant="outlined">
              <label htmlFor="contained-button-file">
                <Input
                  accept="image/*,video/*"
                  id="contained-button-file"
                  multiple
                  type="file"
                  // value={utiltyBill.media}
                  onChange={handleFileEvent}
                />
                <Button component="span" className="btn_upload">
                  <span className="icon">
                    <Add />
                  </span>
                  <span className="title"> Upload Files</span>
                </Button>
              </label>
              {/* </Grid> */}
            </FormControl>
            {/* {UpdatedUploadFiles.map((file, index) => (
              <ListItem
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleRemoveItem(file)}
                  >
                    <DeleteIcon />
                  </IconButton>
                }
              >
                <ListItemAvatar style={{ display: "flex" }}>
                  <FolderIcon />
                  <Typography>{file.name}</Typography>{" "}
                </ListItemAvatar>
                <ListItemText
                // primary={file.image}
                // secondary={"Secondary text"}
                />
              </ListItem>
            ))}
            <Grid className="display" mt={4}>
              <PrimaryButton
                fullWidth
                style={{
                  padding: "0",
                  width: "85%",
                  margin: "15px auto",
                  height: "42px",
                }}
                className="saveButton"
                onClick={handleUploadIssues}
              >
                Save
              </PrimaryButton>
            </Grid> */}
          </Grid>
        </Grid>
      )}
    </>
  );
};
const Input = styled.input`
  display: none;
`;
export default UploadFilesForCard;
