import { configureStore } from "@reduxjs/toolkit";
import { reducer as formReducer } from "redux-form";
import authSliceReucer from "../Slices/AuthSlice/AuthSlice";
import WelcomeCallReducer from "../Slices/WelcomeCallSlice/WelcomeCallSlice";
import ticketSlice from "../Slices/Tickets/TicketSlice";
import ticketsCountsReducer from "../Slices/TicketsCounts/TicketCountsSlice";
import UsersReducer from "../Slices/UsersSlice/UsersSlice";
import Assign_PMReducer from "../Slices/AssignSlice/AssignSlice";
import singleTicketReducer from "../Slices/SingleTicketSlice/SingleTicketSlice";
import sendWelcomePackageReducer from "../Slices/SendWelcomePackage/SendWelcomePackageSlice";
import charterReducer from "../Slices/CharterSlice/CharterSlice";
import createIssueReducer from "../Slices/CreateIssue/Create_Issue_Slice";
import vendorsReducer from "../Slices/VendorsSlice/VendorsSlice";
import productAvailabilityReducer from "../Slices/ProductAvailability/ProductAvailabilitySlice";
import designersReducer from "../Slices/Designers/DesignsersSlice";
import contractorsReducer from "../Slices/contractors/contractorsSlice";
import designRequestReducer from "../Slices/DesignRequest/DesignRequestSlice";
import cardStatusReducer from "../Slices/CardsStatusSlics/CardStepsSlice";
import sendClientPlanReducer from "../Slices/SendClientPlan/SendClientPlanSlice";
import installersReducer from "../Slices/Installers/InstallersSlice";
import workOrderReducer from "../Slices/SendDocumentsToInstallerSlice/SendDocumentsSlice";
import businessLicenseReducer from "../Slices/businessLicenseSlice/businessLicenseSlice";
import workOrdersListReducer from "../Slices/WorkOrders/WorkOrdersSlice";
import sendUtilityBillReducer from "../Slices/UtiltiyBillSlice/UtilityBillSlice";
import AddersCostSpecificCardsReducer from "../Slices/AddersCostSpecificCards/AddersCostSpecificCardsSlice";
import AddersCostCardReducer from "../Slices/AddersCardSlice/AddersCardSlice";
import TicketStatusReducer from "../Slices/TicketStatus/TicketStatusSlice";
import addersTableReducer from "../Slices/AddersTableSlice/AddersTableSlice";
import proceduresReducer from "../Slices/Procedures/ProceduresSlice";
import addonsReducer from "../Slices/AddonsSlice/AddonsSlice";
import callSuppliersReducer from "../Slices/CallSupplierSlice/CallSupplierSlice";
import call_utility_companie_Reducer from "../Slices/CallUtilityCompany/CallUtilityCompanySlice";
import job_reviewsReducer from "../Slices/JobReviewsWithSalesSlice/JobReviewsSlice";
import confirm_with_sales_and_customer_Reducer from "../Slices/ConfirmWithSalesAndCustomerSlice/ConfirmWithSalesAndCustomerSlice";
import cardsOrderReducer from "../Slices/CardsListSlice/CardsListSlice";
import SurveyStepsReducer from "../Slices/SurveyStepsSlice/SurveyStepsSlice";
import SurveyImagesReducer from "../Slices/SiteSurverImagesSlice/SiteSurveyImagesSlice";
import call_jurisdictionsReducer from "../Slices/call_jurisdictions_slice/call_jurisdictions_slice";
import city_office_utilitiesReducer from "../Slices/city_office_utilities_slice/city_office_utilities_slice";
import designPlanCostReducer from "../Slices/designPlanCost/DesignPlanCostSlice";
import UploadFilesReducer from "../Slices/UploadFiles/UploadFilesSlice";
import createCardReducer from "../Slices/CreateCard/CreateCardSlice";
import ticketCardReducer from "../Slices/ticketCards/ticketCardSlice"
export const store = configureStore({
  reducer: {
    form: formReducer,
    auth: authSliceReucer,
    WelcomeCall: WelcomeCallReducer,
    tickets: ticketSlice,
    single_ticket: singleTicketReducer,
    ticketsCounts: ticketsCountsReducer,
    PM_user: UsersReducer,
    Assign_PM: Assign_PMReducer,
    sendWelcomePackage: sendWelcomePackageReducer,
    charter: charterReducer,
    issues: createIssueReducer,
    vendors: vendorsReducer,
    productAvailability: productAvailabilityReducer,
    designers: designersReducer,
    contractors: contractorsReducer,
    design_req: designRequestReducer,
    cardStatus: cardStatusReducer,
    sendClientPlan: sendClientPlanReducer,
    installers: installersReducer,
    work_order: workOrderReducer,
    business_license: businessLicenseReducer,
    workOrders: workOrdersListReducer,
    sendUtilityBill: sendUtilityBillReducer,
    AddersCostSpecificCards: AddersCostSpecificCardsReducer,
    AddersCostCard: AddersCostCardReducer,
    TicketStatus: TicketStatusReducer,
    addersTable: addersTableReducer,
    procedures: proceduresReducer,
    addons: addonsReducer,
    callSuppliers: callSuppliersReducer,
    call_utility_companie: call_utility_companie_Reducer,
    job_reviews: job_reviewsReducer,
    confirm_with_sales_and_customer: confirm_with_sales_and_customer_Reducer,
    cardsOrder: cardsOrderReducer,
    SurveySteps: SurveyStepsReducer,
    SurveyImages: SurveyImagesReducer,
    call_jurisdictions: call_jurisdictionsReducer,
    city_office_utilities: city_office_utilitiesReducer,
    designPlanCost:designPlanCostReducer,
    UploadFiles :UploadFilesReducer,
    create_card : createCardReducer,
    get_ticket_card :ticketCardReducer
  },
});
