import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Vendors_Service from "./VendorsServeice";

const initialState = {
  vendors: {},
  isError: false,
  isSuccess: false,
  isLodaing: false,
  massage: "",
};

export const getVendorsListAction = createAsyncThunk(
  "vendors/getAll",
  async (thunkAPI) => {
    try {
      return await Vendors_Service.Get_vendorsList_Action();
    } catch (error) {
      const massage = error.message;
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const VendorsSlice = createSlice({
  name: "vendors",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLodaing = false;
      state.isDeleteLodaing = false;
      state.massage = "";
    },
  },
  extraReducers: {
    [getVendorsListAction.pending]: (state) => {
      state.isLodaing = true;
    },
    [getVendorsListAction.fulfilled]: (state, action) => {
      state.isLodaing = false;
      state.Vendors = action.payload;
      state.isSuccess = true;
    },
    [getVendorsListAction.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
  },
});

export const { reset } = VendorsSlice.actions;
export default VendorsSlice.reducer;
