import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";
import DesignReqForm from "../../Components/DesignReqForm/DesignReqForm";
import { DesignReqFormm } from "./styles";

import { useSelector, useDispatch } from "react-redux";
import { getSingleTicket } from "../../Redux/Slices/SingleTicketSlice/SingleTicketSlice";
import { useNavigate } from "react-router-dom";
import { get_designers } from "../../Redux/Slices/Designers/DesignsersSlice";
import { get_all_contractors } from "../../Redux/Slices/contractors/contractorsSlice";
import LottieAnimation from "../../Components/LottieAnimation/LottieAnimation";
import loading from "../../Assets/lottie/loading.json";
import styled from "styled-components";

function SendDesignRequestForm({ handleSubmit, initialValues }) {
  const { ticket_id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const designers = useSelector((state) => state.designers);
  const contractors = useSelector((state) => state.contractors);
  const single_ticket = useSelector((state) => state.single_ticket);
  const send_design_reqq = useSelector((state) => state.design_req);

  console.log(single_ticket, "single_ticket");
  let id = {
    ticket_id: ticket_id,
  };
  useEffect(() => {
    dispatch(getSingleTicket(ticket_id));
  }, [ticket_id]);
  useEffect(() => {
    dispatch(getSingleTicket(ticket_id));
    // dispatch(getInstallersListAction(state_id));
  }, [ticket_id]);

  useEffect(() => {
    dispatch(get_designers());
    dispatch(get_all_contractors());
  }, []);

  return (
    <>
      {single_ticket?.isLodaing ? (
        <LottieAnimation lotti={loading} width={180} height={180} />
      ) : (
        <Box sx={{ width: "100%" }}>
          <DesignReqFormm>
            <DesignReqForm ticket_id={ticket_id} />
          </DesignReqFormm>
        </Box>
      )}
    </>
  );
}
const Input = styled.input`
  display: none;
`;

export default SendDesignRequestForm;
