import axios from "axios";
import { post_product_availability } from "../../../Api/Api";
import { toast } from "react-toastify";
const post_product_availability_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${post_product_availability}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success(" Done ", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const get_product_availability_data = async (id) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${post_product_availability}/${id}`,
    headers: headers,
    // data: data,
  });
  return response.data;
};

const update_product_availability_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${post_product_availability}/${data.id}`,
    headers: headers,
    data: data,
  });
  if (response?.status == 200) {
    toast.success("updated Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return response.data;
};

const postProductAvailabilityService = {
  post_product_availability_Action,
  get_product_availability_data,
  update_product_availability_Action
};
export default postProductAvailabilityService;
