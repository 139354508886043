export const validate = (val) => {
  const errors = {};

  if (!val.message) {
    console.log("email is required");
    errors.message = "Required";
  } else if (!/-?\d*\.?\d+/gi.test(val.message)) {
    console.log("email is invalid");
    errors.message = "numbers only";
  }

  return errors;
};
