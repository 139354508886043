import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Grid, IconButton, Button, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getTicketStatus } from "../../Redux/Slices/TicketStatus/TicketStatusSlice";
import { SteperContainer } from "../../Components/ProcessSteper/ProcessSteperStyle";
import CardIssues from "../../Components/CardIssues/CardIssues";
import CardStatusDetails from "../../Components/CardIssues/CardStatusDetails"
import CardAddersDetails from "../../Components/CardsAddersDetails/CardAddersDetails";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router-dom";
const CardIssuess = () => {
  const navigate = useNavigate()
  const single_ticket = useSelector((state) => state.single_ticket);
  const TicketStatus = useSelector((state) => state.TicketStatus);

  //console.log(TicketStatus?.TicketStatus?.data?.business_license  , "TicketStatus1234")
  const ticket_status = TicketStatus?.TicketStatus?.data
  const { lead_id } = useParams();
  const { type } = useParams();
  const dispatch = useDispatch();
  console.log(single_ticket, "project_status steper");
  console.log(lead_id, "lead_id in steper");
  console.log(type, "type in adders ");

  useEffect(() => {
    dispatch(getTicketStatus(lead_id));
  }, [lead_id]);
  return (
    <>
      <>
        <MainTabsStyled>

        <Box
            sx={{ width: "100%", typography: "body1" }}
            style={{ backgroundColor: "white" ,    
            boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)"
                       }}
          >

            <AppBar position="static"
              style={{ backgroundColor: "white", color: "black" }}
            >
              <Toolbar>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                  onClick={() => navigate(`/home/SolarSolution/start-project/${lead_id}`)}

                >
                  <ArrowBackIcon />
                </IconButton>
              
                <Typography variant="h5" align="center"> {type?.replace(
                  /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '  '
                )}</Typography>
              </Toolbar>
            </AppBar>
            <Grid container columns={12}>
              <Grid item xs={12} sm={12} lg={12}>
                <SteperContainer>
            <CardStatusDetails  type={type} lead_id={lead_id}  ticket_status={ticket_status}/>
                  
                </SteperContainer>

                
              </Grid>
            </Grid>
          </Box>


         

          {(type == "documents_to_installer" ||
            type == "request_to_designer" ||
            type == "apply_all_required_permits" ||
            type == "apply_pto" ||
            type == "order_placards" ||
            type == "business_license" ||
            type == "order_product" ||
            type == "adders") && (
              <Box
                sx={{ width: "100%", typography: "body1", marginTop: "20px" }}
                style={{ backgroundColor: "white" }}
              >
                
                {type == "adders" && (
                  <>
                 
                  <Grid container columns={12}>
                    <Grid item xs={12} sm={12} lg={12}>
                      <SteperContainer>
                        <CardAddersDetails lead_id={lead_id} type={type} ticket_status={ticket_status} />
                      </SteperContainer>
                    </Grid>
                  </Grid>
                  </>
                )}

              </Box>
           
           )}
        </MainTabsStyled>


        <MainTabsStyled>


  <Box
    sx={{ width: "100%", typography: "body1" }}
    style={{ backgroundColor: "white" ,    
     boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)"
                }}
  >

    <AppBar position="static"
      style={{ backgroundColor: "white", color: "black" }}
    >
      <Toolbar>
        
      
        <Typography variant="h5" align="center">
          Card Issues
        </Typography>
      </Toolbar>
    </AppBar>
    <Grid container columns={12}>
      <Grid item xs={12} sm={12} lg={12}>
        <SteperContainer>
          <CardIssues lead_id={lead_id} ticket_status={ticket_status} />
          
        </SteperContainer>
      </Grid>
    </Grid>
  </Box>

 

 
</MainTabsStyled>
      </>
    </>
  );
};
const MainTabsStyled = styled.div`
  margin: 1rem 0.8rem;
  border-radius: 5px;
`;
export default CardIssuess;
