import styled from "styled-components";
export const CharterForm = styled.div`
  input {
    height: 15px;
  }
  label {
    margin-bottom: 5px;
  }

  .fieldDetails {
    font-weight: 400;
    margin-bottom: 10px;
    @media screen and (min-width: 1286px) {
      margin-left: 250px;
    }
    @media only screen and (max-width: 1286px) and (min-width: 768px) {
      margin-left: 200px;
    }
  }
`;
