import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Grid, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import { PrimaryButton } from "../../../Layouts/ButtonsControlled";
import { ModalContent } from "../../Modals/PmAssignModal/PmAssignModalStyle";
import { getTicketStatus } from "../../../Redux/Slices/TicketStatus/TicketStatusSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import {
  STORE_first_step_of_survey_Action,
  Edit_first_step_of_survey_Action,
  GET_first_step_of_survey_Action
} from "../../../Redux/Slices/SurveyStepsSlice/SurveyStepsSlice";
const FirstStepOfSurveyModal = ({
  handleopenModal,
  handleCloseModal,
  set_Open_Modal,
  setOpenEditModal,
  surveyor_first_step_status,
  survey_details_data,
  openEdit_first_step_Modal,
  survey_data

}) => {
  const dispatch = useDispatch();
  const { lead_id } = useParams();
  const cardStatus_state = useSelector((state) => state.TicketStatus);
  const card_status = cardStatus_state?.TicketStatus?.data?.surveyor_first_step
  console.log(surveyor_first_step_status , "surveyor_first_step_status")
  console.log(card_status , "card_status")
  console.log(cardStatus_state?.TicketStatus?.data?.surveyor_first_step

    , "cardStatus")
    console.log(survey_data?.SurveySteps 
      , "survey_data12222")
  const [confirm_surveyor_on_the_way, set_confirm_surveyor_on_the_way] =
    React.useState(+survey_details_data?.confirm_surveyor_on_the_way);
  const [
    confirm_customer_surveyor_arrival,
    set_confirm_customer_surveyor_arrival,
  ] = React.useState(+survey_details_data?.confirm_customer_surveyor_arrival);

  const [comment, set_comment] = useState(
    surveyor_first_step_status == "5" ? survey_details_data?.comment : ""
  );
  const [status, set_status] = useState(
    surveyor_first_step_status == "5" && survey_details_data?.status
  );
  console.log(survey_details_data?.comment, "survey_details_data");

  useEffect(() => {
    dispatch(getTicketStatus(lead_id));
    dispatch(GET_first_step_of_survey_Action(lead_id))
  }, [lead_id]);

  useEffect(() => {
    set_comment(survey_details_data?.comment);
    set_status(survey_details_data?.status);
    set_confirm_surveyor_on_the_way(
      survey_details_data?.confirm_surveyor_on_the_way
    );
    set_confirm_customer_surveyor_arrival(
      survey_details_data?.confirm_customer_surveyor_arrival
    );
  }, [openEdit_first_step_Modal]);

  const handleChange_confirm_customer_surveyor_arrival = (event) => {
    set_confirm_customer_surveyor_arrival(event.target.checked);
    console.log(event.target);
    console.log(event.target.checked);
  };

  const handleChange_confirm_surveyor_on_the_way = (event) => {
    set_confirm_surveyor_on_the_way(event.target.checked);
    console.log(event.target);
    console.log(event.target.checked);
  };

  const HandelSubmit = async (e) => {
    e.preventDefault();
    const reqData = {
      ticket_id: +lead_id,
      id: survey_data?.SurveySteps !== undefined && survey_details_data?.id,
      status: status,
      comment: comment,
      confirm_surveyor_on_the_way: confirm_surveyor_on_the_way ? 1 : 0,
      confirm_customer_surveyor_arrival: confirm_customer_surveyor_arrival
        ? 1
        : 0,
    };
    survey_data?.SurveySteps !== undefined ?
      (await dispatch(Edit_first_step_of_survey_Action(reqData))) &&
      (await setOpenEditModal(false)) : 

      (await dispatch(STORE_first_step_of_survey_Action(reqData))) &&
      (await set_Open_Modal(false));
    await dispatch(getTicketStatus(lead_id));
  };

  return (
    <Dialog
      maxWidth={"md"}
      fullWidth
      open={handleopenModal}
      onClose={handleCloseModal}
    >
      <DialogContent>
        <ModalContent>
          <Box>
            <form onSubmit={HandelSubmit}>
              <Grid>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={confirm_surveyor_on_the_way}
                        name="confirm_surveyor_on_the_way"
                        onChange={handleChange_confirm_surveyor_on_the_way}
                        checked={confirm_surveyor_on_the_way}
                      />
                    }
                    label="confirm surveyor on the way ?"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        value={confirm_customer_surveyor_arrival}
                        name="confirm_customer_surveyor_arrival"
                        onChange={
                          handleChange_confirm_customer_surveyor_arrival
                        }
                        checked={confirm_customer_surveyor_arrival}
                      />
                    }
                    label="confirm customer surveyor arrivall ?"
                  />

                  <Grid>
                    <FormControl variant="outlined">
                      <InputLabel htmlFor="component-simple">
                        Comment
                      </InputLabel>
                      <TextField
                        multiline
                        required
                        rows={2}
                        style={{ width: "400px", marginTop: "50px" }}
                        name="comment"
                        value={comment}
                        onChange={(e) => set_comment(e.target.value)}
                      />
                    </FormControl>
                  </Grid>

                  <Grid className="" style={{ marginTop: "10px" }}>
                    <FormControl variant="outlined">
                      <InputLabel htmlFor="component-simple">status</InputLabel>
                      <Select
                        required
                        style={{ width: "300px", marginTop: "15px" }}
                        value={status}
                        onChange={(e) => set_status(e.target.value)}
                      >
                        <MenuItem value="pending">pending </MenuItem>
                        <MenuItem value="completed">completed</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </FormGroup>
              </Grid>

              <DialogActions>
                <PrimaryButton
                  type="submit"
                  fullWidth
                  style={{
                    padding: "0",
                    width: "85%",
                    margin: "15px auto",
                    height: "42px",
                  }}
                  className="saveButton"
                >
                  {survey_data?.SurveySteps !== undefined ? "update" : "save"}
                </PrimaryButton>
              </DialogActions>
            </form>
          </Box>
        </ModalContent>
      </DialogContent>
    </Dialog>
  );
};

export default FirstStepOfSurveyModal;
