import * as yup from "yup";
export const FirstStepValidation = {
  project_name: yup
    .string("Enter  projectName")
    .required("project Name is required"),
  project_manager: yup
    .string("Enter  project Manager")
    .required("project Manager  is required"),
  project_address: yup
    .string("Enter  project Address")
    .required("project Address is required"),
  project_system_size: yup
    .string("Enter system Size")
    .required("system Size is required"),
  project_installation_type: yup
    .string("Enter  Installation Type")
    .required("Installation Type is required"),
  project_additional_permits: yup
    .string("Enter  Additional Permits")
    .required("Additional Permits is required"),
  project_roofer: yup.string("Enter  Roofer").required("Roofer is required"),
  project_designer: yup.string().required("Designer is required!"),
};
export const secStepValidation = {
  jurisdiction: yup
    .string("Enter Jurisdiction")
    .required("Jurisdiction is required"),
  submittal_process: yup
    .string("Enter Submittal Process")
    .required("Submittal Process is required"),
  jurisdiction_contact_email: yup
    .string("Enter Contact Info")
    .email("Enter a valid email")
    .required("Contact Info is required"),
  jurisdiction_contact_mobile: yup
    .number("enter jurisdiction contact mobile")
    .required("Contact Info is required"),
  jurisdiction_estimated_duration: yup
    .number("Enter Estimated Duration")
    .required("Estimated Duration is required"),
  business: yup
    .string("Enter Business License")
    .required("Business License is required"),
  utility_company: yup.string("Enter Utility Company"),
  // .required("Utility Company is required"),
  utility_pto_process: yup
    .string("Enter PTO Process")
    .required("PTO Process is required"),
  utility_contact_email: yup
    .string("Enter Contact Info")
    .email("Enter a valid email")
    .required("Contact Info is required"),
  utility_contact_mobile: yup
    .number("enter jurisdiction contact mobile")
    .required("Contact Info is required"),
  utility_estimated_duration: yup
    .string("Enter Estimated Duration")
    .required("Estimated Duration is required"),
};

export const thirdStepValidation = {
  roofing_contract_redecking: yup
    .string("Enter Redecking in contract")
    .required("Redecking in contract is required"),
  roofing_shingles: yup
    .string("Enter Shingles")
    .required("Shingles is required"),
  mpu_meter_spot: yup
    .string("Enter Meter spot")
    .required("Meter spot is required"),
  mpu_shutoff_required: yup
    .string("Enter Shutoff Required")
    .required("Shutoff Required is required"),
  project_valuation: yup
    .string("Enter Project Valuation")
    .required("Project Valuation is required"),
  products_manufacturer: yup
    .string("Enter Panels Manufacturer")
    .required("Panels Manufacturer is required"),
  inverter_manufacturer_model: yup
    .string("Enter Inverter(s) Manufacturer & Model")
    .required("Inverter(s) Manufacturer & Model is required"),
  products_total_price: yup
    .string("Enter Products Total Price")
    .required("Products Total Price is required"),
};

export const fourthStepValidation = {
  welcome_call: yup
    .date()
    // .nullable()
    // .min(new Date(), " the  welcome Call Date must be later than today")
    .required("the welcome call is required"),
  site_survey: yup
    .date()
    .nullable()
    // .min(new Date(), " the SiteSurvey Date must be later than today")
    .required("the SiteSurvey is required"),
  Business_license_date: yup
    .date()
    .nullable()
    // .min(new Date(), " the SiteSurvey Date must be later than today")
    .required("Business lLicense Date is required"),
  meterspot_date: yup
    .date()
    .nullable()
    //.min(new Date(), " the Meterspot Date must be later than today")
    .required("Meterspot Date is required"),
  mpu_application_submission_date_start: yup
    .date()
    .nullable()
    //.min(new Date(), " Application Submission Date must be later than today")
    .required("Application Submission Date is required"),
  mpu_permit_issuance_date_end: yup
    .date()
    .nullable()
    //.min(new Date(), " Application Submission Date must be later than today")
    .required("Application Submission Date is required"),
  solar_application_submission_date_start: yup
    .date()
    .nullable()
    //.min(new Date(), " Application Submission Date must be later than today")
    .required("Application Submission Date is required"),
  roofing_application_submission_date_start: yup
    .date()
    .nullable()
    //.min(new Date(), " Permit Issuance Date must be later than today")
    .required("Permit Issuance Date is required"),
  roofing_permit_issuance_date_end: yup
    .date()
    .nullable()
    //.min(new Date(), " Application Submission Date must be later than today")
    .required("Application Submission Date is required"),
  // roofingPermitIssuanceDate: yup
  //   .date()
  //   .nullable()
  //   //.min(new Date(), " Permit Issuance Date must be later than today")
  //   .required("Permit Issuance Date is required"),
  roofing_installation_start_time: yup
    .date()
    .nullable()
    //.min(new Date(), "Starttime Date must be later than today")
    .required("Starttime Date is required"),
  roofing_installation_complete: yup
    .date()
    .nullable()
    //.min(new Date(), "complete Date must be later than today")
    .required("complete Date is required"),
  mpu_installation_start_time: yup
    .date()
    .nullable()
    //.min(new Date(), "start time  Date must be later than today")
    .required("start time  Date is required"),
  mpu_installation_complete: yup
    .date()
    .nullable()
    //.min(new Date(), "Complete Date must be later than today")
    .required("Complete Date is required"),
  solar_installation_start_time: yup
    .date()
    .nullable()
    //.min(new Date(), "start time Date must be later than today")
    .required("start time Date is required"),
  solar_installation_complete: yup
    .date()
    .nullable()
    //.min(new Date(), "Complete Date must be later than today")
    .required("Complete Date is required"),
  roofing_inspection_start_date: yup
    .date()
    .nullable()
    //.min(new Date(), "Start Time Date must be later than today")
    .required("Start Time Date is required"),
  mpu_inspection_start_date: yup
    .date()
    .nullable()
    //.min(new Date(), "Start Time Date must be later than today")
    .required("Start Time Date is required"),
  solar_inspection_start_date: yup
    .date()
    .nullable()
    //.min(new Date(), "Start Time Date must be later than today")
    .required("Start Time Date is required"),
  pto_application_submittal_date_start: yup
    .date()
    .nullable()
    //.min(new Date(), "Application Submittal Date must be later than today")
    .required("Application Submittal Date is required"),
  pto_application_submittal_date_end: yup
    .date()
    .nullable()
    //.min(new Date(), "PTO Acquired Date must be later than today")
    .required("PTO Acquired Date is required"),
};
export const fifthStepValidation = {
  site_survey_cost: yup
    .number("enter site survey price")
    .required("site survey price is required"),
  permit_fees_cost: yup
    .number("enter permit fees")
    .required("permit fees is required"),
  mpu_fees_cost: yup
    .number("enter mpu price")
    .required("mpu price is required"),
  permit_run_cost: yup
    .number("enter permit run fees")
    .required(" permit run fees is required"),
  roofing_price: yup
    .number("enter roofing price")
    .required(" roofing fees is required"),
  solar_installation_fees_cost: yup
    .number("enter solar installation price")
    .required("solar installation price is required"),
  sitting_for_inspection: yup
    .number("enter sitting for inspection")
    .required("sitting for inspection price is required"),
  pto_fees_cost: yup.number("enter pto fees").required("pto fees is required"),
  solar_products_cost: yup
    .number("enter solar products cost")
    .required("solar products cost is required"),
  hvac_cost: yup.number("enter hvac cost").required("hvac cost is required"),
  online_monitoring_cost: yup
    .number("enter online monitoring cost")
    .required("online monitoring price is required"),
  attic_run_cost: yup
    .number("enter attic run cost")
    .required(" attic run is required"),
  tree_trimming_cost: yup
    .number("enter tree trimming cost")
    .required("tree trimming cost required"),
  mpu_relocation_cost: yup
    .number("enter mpu relocation cost")
    .required("mpu relocation cost required"),
  de_rate_cost: yup
    .number("enter de rate cost")
    .required("de rate cost required"),
  sub_panel_cost: yup
    .number("enter sub panel cost")
    .required("sub panel cost required"),
  meter_socket_cost: yup
    .number("enter meter socket cost")
    .required("meter socket cost required"),
  ev_charger_cost: yup
    .number("enter ev charger cost")
    .required("ev charger cost is  required"),
  ev_mlo_cost: yup
    .number("enter ev mlo cost")
    .required("ev mlo cost is   required"),
  designated_plugs_cost: yup
    .number("enter designated plugs cost")
    .required("designated plugs cost is required"),
  battery_cost: yup
    .number("enter battery cost")
    .required("battery cost is required"),
  generator_cost: yup
    .number("enter generator cost")
    .required("generator cost is required"),
  ducting_cost_cost: yup
    .number("enter ducting cost cost")
    .required("ducting cost is required"),
  led_lighting_cost: yup
    .number("enter led lighting cost")
    .required("led lighting cost is required"),
  energy_efficient_cost: yup
    .number("enter energy efficient cost")
    .required("energy efficient cost is required"),
  solar_lip_cost: yup
    .number("enter solar lip  cost")
    .required("energy efficient cost is required"),
  bird_netting_cost: yup
    .number("enter bird netting cost")
    .required("bird netting  cost is required"),
};
