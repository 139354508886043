import axios from "axios";
import {
  CardsListOrder,
  UpdateCardsOrder,
  transferCard,
} from "../../../Api/Api";

const get_cards_order_Action = async () => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${CardsListOrder}`,
    headers: headers,
    // params: {
    //   ticket_id: data,
    // },
  });
  return response.data;
};

const update_order = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${UpdateCardsOrder}`,
    headers: headers,
    data: data,
  });
  return response.data;
};

const transferCard_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${transferCard}`,
    headers: headers,
    data: data,
  });
  return response.data;
};

const CardsListService = {
  get_cards_order_Action,
  update_order,
  transferCard_Action,
};
export default CardsListService;
