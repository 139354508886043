import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import business_licenseService from "./busibessLicenseService";
import { toast } from "react-toastify";
const initialState = {
  business_license: {},
  isError: false,
  isSuccess: false,
  isLodaing: false,
  massage: "",
};

export const store_business_license = createAsyncThunk(
  "business_licence/post",
  async (data, thunkAPI) => {
    try {
      return await business_licenseService.store_business_license_Action(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
        console.log( error?.response , " error?.response")
        error?.response?.data?.errors?.map((err) =>
        toast.error(err.msg, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      );
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const get_business_license = createAsyncThunk(
  "business_license/get",
  async (id, thunkAPI) => {
    try {
      return await business_licenseService.get_business_license_data(id);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const edit__business_license_data = createAsyncThunk(
  "edit__business_license_data/post",
  async (data, thunkAPI) => {
    try {
      return await business_licenseService.edit__business_license_data(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
        console.log( error?.response , " error?.response")
        error?.response?.data?.errors?.map((err) =>
        toast.error(err.msg, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      );
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const business_licenseSlice = createSlice({
  name: "business_license",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLodaing = false;
      state.massage = "";
    },
  },
  extraReducers: {
    //post business_license
    [store_business_license.pending]: (state) => {
      state.isLodaing = true;
    },
    [store_business_license.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.business_license = payload;
    },
    [store_business_license.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },

    //get business_license
    [get_business_license.pending]: (state) => {
      state.isLodaing = true;
    },
    [get_business_license.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.business_license = payload;
    },
    [get_business_license.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.business_license = null;
      state.massage = action.payload;
    },

    //update business_license
    [edit__business_license_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [edit__business_license_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.business_license = payload;
    },
    [edit__business_license_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
  },
});

export const { reset } = business_licenseSlice.actions;
export default business_licenseSlice.reducer;
