import styled from "styled-components";
export const HeaderForm = styled.div`
  width: 70%;
  margin: auto;
  h2 {
    font-size: 2.7rem;
    font-weight: 500;
    -webkit-text-fill-color: black;
    /* -webkit-background-clip: text; */
    margin: auto;
  }
  > p {
    font-size: 1rem;
    color: #78778b;
    margin-top: 1px;
  }
`;
