import React, { useState, useEffect } from "react";
import { reduxForm, Field } from "redux-form";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Grid, Typography } from "@mui/material";
import { PrimaryButton } from "../../../Layouts/ButtonsControlled";
import { ModalContent } from "../PmAssignModal/PmAssignModalStyle";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import FormGroup from "@mui/material/FormGroup";
import Box from "@mui/material/Box";
import { get_AddersCostSpecificCards, reset, update_AddersCostSpecificCards } from "../../../Redux/Slices/AddersCostSpecificCards/AddersCostSpecificCardsSlice"
import MuiTextField from "../../../Common/muiTextField/MuiTextField";
const EditExtraAddersModal = ({
  handleSubmit,
  handleEditAdder,
  handleCloseModal,
  selectedAdder,
  setOpenEditModal,
  handleOpenModal,
  extra_adders_state,
  lead_id
}) => {
  const dispatch = useDispatch();
  const submit = async (values) => {

    const data = {
      id: selectedAdder.id,
      cost: values.cost,
      name: values.name,
      _method: "PUT"
    }

    await dispatch(update_AddersCostSpecificCards(data));
    await dispatch(get_AddersCostSpecificCards(lead_id));
    await dispatch(reset())

  };
  React.useEffect(() => {
    if (extra_adders_state?.isSuccess) {
      handleCloseModal()

    }
  }, [extra_adders_state])

  return (
    <Dialog
      maxWidth={"sm"}
      fullWidth
      open={handleOpenModal}
      onClose={handleCloseModal}
    >
      <DialogContent>
        <ModalContent>
          <Box>
            <form onSubmit={handleSubmit((values) => submit(values))}>
              <Grid>
                <FormGroup>
                  <Grid>
                    <Field
                      type="number"
                      name="cost"
                      component={MuiTextField}
                      label="cost"
                    />
                  </Grid>

                  <Grid>
                    <Field
                      type="text"
                      name="name"
                      component={MuiTextField}
                      label="adder name"
                    />
                  </Grid>
                </FormGroup>
              </Grid>
              <DialogActions>
                <PrimaryButton
                  fullWidth
                  type="submit"
                  style={{
                    padding: "0",
                    width: "85%",
                    margin: "15px auto",
                    height: "42px",
                  }}
                  className="saveButton"
                >
                  Update
                </PrimaryButton>
              </DialogActions>
            </form>
          </Box>
        </ModalContent>
      </DialogContent>
    </Dialog>
  );
};

export default reduxForm({
  form: "EditExtraAddersModalForm",
})(EditExtraAddersModal);
