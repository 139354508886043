import React, { useEffect } from "react";
import { reduxForm, Field } from "redux-form";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Grid } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { PrimaryButton } from "../../../Layouts/ButtonsControlled";
import { ModalContent } from "../../Modals/PmAssignModal/PmAssignModalStyle";
import { getTicketStatus } from "../../../Redux/Slices/TicketStatus/TicketStatusSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import {
  store_call_jurisdictions,
  update_call_jurisdictions,
} from "../../../Redux/Slices/call_jurisdictions_slice/call_jurisdictions_slice";
import MuiSelectField from "../../../Common/MuiSelectField/MuiSelectField";
import MuiTextField from "../../../Common/muiTextField/MuiTextField";
const CalljurisdictionsModal = ({
  handleSubmit,
  handleopenModal,
  handleCloseModal,
  set_Open_Modal,
  call_supplier_details,
  set_edit_modal,
  call_jurisdiction_details,
  card_status,
  call_jurisdictions_status,
  call_jurisdictions_data
}) => {
  const dispatch = useDispatch();
  const formNotify = useSelector((state) => state.form);
  const { lead_id } = useParams();
  const [is_under_jurisdiction, set_is_under_jurisdiction] = React.useState(0);
  const [is_need_license, set_is_need_license] = React.useState(0);
  const [is_have_license, set_is_have_license] = React.useState(0);
  const [is_colored, set_is_colored] = React.useState(0);
  console.log(call_jurisdictions_status , "call_jurisdictions_status121");
  console.log(card_status , "card_status")
  console.log(call_jurisdictions_data , "call_jurisdictions_data")
  const [is_rough_inspection_for_racking, set_is_rough_inspection_for_racking] =
    React.useState(0);
  const [is_person_to_follow, set_is_person_to_follow] = React.useState(0);
  const [is_split_permit_needed, set_is_split_permit_needed] =
    React.useState(0);
  const [is_stamped_plans_needed, set_is_stamped_plans_needed] =
    React.useState(0);

  const [is_smoke_detectors_needed, set_is_smoke_detectors_needed] =
    React.useState(0);

  const [is_notarized, set_is_notarized] = React.useState(0);
  const [is_license_active, set_is_license_active] = React.useState(0);

  const handleChange_is_license_active = (event) => {
    set_is_license_active(event.target.checked);
  };

  const handleChange_is_notarized = (event) => {
    set_is_notarized(event.target.checked);
  };

  const handleChange_is_smoke_detectors_needed = (event) => {
    set_is_smoke_detectors_needed(event.target.checked);
  };

  const handleChange_is_stamped_plans_needed = (event) => {
    set_is_stamped_plans_needed(event.target.checked);
  };

  const handleChange_is_split_permit_needed = (event) => {
    set_is_split_permit_needed(event.target.checked);
  };

  const handleChange_is_person_to_follow = (event) => {
    set_is_person_to_follow(event.target.checked);
  };
  const handleChange_is_rough_inspection_for_racking = (event) => {
    set_is_rough_inspection_for_racking(event.target.checked);
  };

  const handleChange_is_need_license = (event) => {
    set_is_need_license(event.target.checked);
  };

  const handleChange_is_colored = (event) => {
    set_is_colored(event.target.checked);
  };
  const handleChange_is_under_jurisdiction = (event) => {
    set_is_under_jurisdiction(event.target.checked);
  };

  const handleChange_is_have_license = (event) => {
    set_is_have_license(event.target.checked);
  };
  // useEffect(() => {
  //   (call_jurisdictions_status == "5" || card_status == "5") &&
  //     set_is_under_jurisdiction(
  //       call_jurisdiction_details?.is_under_jurisdiction
  //     );
  //   (call_jurisdictions_status == "5" || card_status == "5") &&
  //     set_is_need_license(call_jurisdiction_details?.is_need_license);
  //   (call_jurisdictions_status == "5" || card_status == "5") &&
  //     set_is_have_license(call_jurisdiction_details?.is_have_license);
  //   (call_jurisdictions_status == "5" || card_status == "5") &&
  //     set_is_colored(call_jurisdiction_details?.is_colored);
  //   (call_jurisdictions_status == "5" || card_status == "5") &&
  //     set_is_rough_inspection_for_racking(
  //       call_jurisdiction_details?.is_rough_inspection_for_racking
  //     );
  //   (call_jurisdictions_status == "5" || card_status == "5") &&
  //     set_is_person_to_follow(call_jurisdiction_details?.is_person_to_follow);
  //   (call_jurisdictions_status == "5" || card_status == "5") &&
  //     set_is_split_permit_needed(
  //       call_jurisdiction_details?.is_split_permit_needed
  //     );
  //   (call_jurisdictions_status == "5" || card_status == "5") &&
  //     set_is_stamped_plans_needed(
  //       call_jurisdiction_details?.is_stamped_plans_needed
  //     );
  //   (call_jurisdictions_status == "5" || card_status == "5") &&
  //     set_is_smoke_detectors_needed(
  //       call_jurisdiction_details?.is_smoke_detectors_needed
  //     );
  //   (call_jurisdictions_status == "5" || card_status == "5") &&
  //     set_is_notarized(call_jurisdiction_details?.is_notarized);
  //   (call_jurisdictions_status == "5" || card_status == "5") &&
  //     set_is_license_active(call_jurisdiction_details?.is_license_active);
  // }, []);
  useEffect(() => {
    // (call_jurisdictions_status == "5" || card_status == "5") &&
      set_is_under_jurisdiction(
        call_jurisdiction_details?.is_under_jurisdiction
      );
    // (call_jurisdictions_status == "5" || card_status == "5") &&
      set_is_need_license(call_jurisdiction_details?.is_need_license);
    // (call_jurisdictions_status == "5" || card_status == "5") &&
      set_is_have_license(call_jurisdiction_details?.is_have_license);
    // (call_jurisdictions_status == "5" || card_status == "5") &&
      set_is_colored(call_jurisdiction_details?.is_colored);
    // (call_jurisdictions_status == "5" || card_status == "5") &&
      set_is_rough_inspection_for_racking(
        call_jurisdiction_details?.is_rough_inspection_for_racking
      );
    // (call_jurisdictions_status == "5" || card_status == "5") &&
      set_is_person_to_follow(call_jurisdiction_details?.is_person_to_follow);
    // (call_jurisdictions_status == "5" || card_status == "5") &&
      set_is_split_permit_needed(
        call_jurisdiction_details?.is_split_permit_needed
      );
    // (call_jurisdictions_status == "5" || card_status == "5") &&
      set_is_stamped_plans_needed(
        call_jurisdiction_details?.is_stamped_plans_needed
      );
    // (call_jurisdictions_status == "5" || card_status == "5") &&
      set_is_smoke_detectors_needed(
        call_jurisdiction_details?.is_smoke_detectors_needed
      );
    // (call_jurisdictions_status == "5" || card_status == "5") &&
      set_is_notarized(call_jurisdiction_details?.is_notarized);
    // (call_jurisdictions_status == "5" || card_status == "5") &&
      set_is_license_active(call_jurisdiction_details?.is_license_active);
  }, []);
  const submit = async (values) => {
    console.log(values  , "values")
    const reqData = {
      ticket_id: +lead_id,
      id: call_jurisdiction_details?.id,
      is_under_jurisdiction: is_under_jurisdiction ? 1 : 0,
      is_need_license: is_need_license ? 1 : 0,
      is_have_license: is_have_license ? 1 : 0,
      is_colored: is_colored ? 1 : 0,
      is_rough_inspection_for_racking: is_rough_inspection_for_racking ? 1 : 0,
      is_split_permit_needed: is_split_permit_needed ? 1 : 0,
      is_person_to_follow: is_person_to_follow ? 1 : 0,
      is_stamped_plans_needed: is_stamped_plans_needed ? 1 : 0,
      is_smoke_detectors_needed: is_smoke_detectors_needed ? 1 : 0,
      is_notarized: is_notarized ? 1 : 0,
      is_license_active: is_license_active ? 1 : 0,
    };
   call_jurisdictions_data !== undefined
      ? (await dispatch(update_call_jurisdictions({ ...values, ...reqData }))) &&
        (await set_edit_modal(false))
         (await dispatch(getTicketStatus(lead_id)))
      : (await dispatch(store_call_jurisdictions({ ...values, ...reqData }))) &&
       (await set_Open_Modal(false));
  };

  return (
    <Dialog
      maxWidth={"md"}
      fullWidth
      open={handleopenModal}
      onClose={handleCloseModal}
    >
      <DialogContent>
        <ModalContent>
          <Box>
            <form onSubmit={handleSubmit((values) => submit(values))}>
              <Grid>
                <Grid container columns={12} spacing={5}>
                  <Grid item xs={12} sm={5} lg={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={is_under_jurisdiction}
                          name="is under jurisdiction ?"
                          onChange={handleChange_is_under_jurisdiction}
                          checked={is_under_jurisdiction}
                        />
                      }
                      label="Confirm address is in under the jurisdiction."
                    />
                  </Grid>
                </Grid>
                <Grid container columns={12}>
                  <Grid item xs={12} sm={5} lg={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={is_need_license}
                          name="is_need_license"
                          onChange={handleChange_is_need_license}
                          checked={is_need_license}
                        />
                      }
                      label="is need license"
                    />
                  </Grid>
                  <Grid item xs={12} sm={5} lg={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={is_have_license}
                          name="is_have_license"
                          onChange={handleChange_is_have_license}
                          checked={is_have_license}
                        />
                      }
                      label="is have license"
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  columns={12}
                  spacing={1}
                  justifyContent="center"
                  //   mb={3}
                >
                  <Grid item xs={12} sm={12} lg={12}>
                    {is_have_license == 0 ? (
                      <Grid>
                        <Field
                          required={is_have_license == 0}
                          type="text"
                          rows={2}
                          multiline
                          name="how_to_renew_or_acquired"
                          component={MuiTextField}
                          label="how to renew or acquired"
                        />
                      </Grid>
                    ) : (
                      <Grid>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={is_license_active}
                              name="is_license_active"
                              onChange={handleChange_is_license_active}
                              checked={is_license_active}
                            />
                          }
                          label="is license_active"
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  columns={12}
                  spacing={1}
                  justifyContent="center"
                  //   mb={3}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    lg={
                      formNotify?.CalljurisdictionsForm?.values
                        ?.permit_submit_type == "over_the_counter"
                        ? 6
                        : 12
                    }
                  >
                    <Field
                      required
                      name="permit_submit_type"
                      component={MuiSelectField}
                      label="permit submit type"
                    >
                      <MenuItem value="over_the_counter">
                        over the counter{" "}
                      </MenuItem>
                      <MenuItem value="online">online</MenuItem>
                      <MenuItem value="email">email</MenuItem>
                    </Field>
                  </Grid>
                  {formNotify?.CalljurisdictionsForm?.values
                    ?.permit_submit_type == "over_the_counter" && (
                    <Grid item xs={12} sm={12} lg={6}>
                      {/* <Field
                        required={
                          formNotify?.CalljurisdictionsForm?.values
                            ?.permit_submit_type == "over_the_counter"
                        }
                        type="number"
                        name="permit_copies_needed"
                        component={MuiTextField}
                        label="permit copies needed"
                      /> */}

                      <FormControlLabel
                        control={
                          <Checkbox
                            value={is_colored}
                            name="is_colored"
                            onChange={handleChange_is_colored}
                            checked={is_colored}
                          />
                        }
                        label="is colored"
                      />
                    </Grid>
                  )}
                </Grid>
                {formNotify?.CalljurisdictionsForm?.values
                  ?.permit_submit_type == "over_the_counter" && (
                  <Grid container columns={12} spacing={2}>
                    <Grid item xs={12} sm={5} lg={6}>
                      <Field
                        required={
                          formNotify?.CalljurisdictionsForm?.values
                            ?.permit_submit_type == "over_the_counter"
                        }
                        type="number"
                        name="permit_copies_needed"
                        component={MuiTextField}
                        label="permit copies needed"
                      />
                    </Grid>
                    <Grid item xs={12} sm={5} lg={6}>
                      <Field
                        required={
                          formNotify?.CalljurisdictionsForm?.values
                            ?.permit_submit_type == "over_the_counter"
                        }
                        type="text"
                        name="plan_size"
                        component={MuiTextField}
                        label="plan size"
                      />
                    </Grid>
                  </Grid>
                )}
                <Grid container columns={12} spacing={2}>
                  <Grid item xs={12} sm={5} lg={6}>
                    <Field
                      required
                      name="permit_timeline_to_be_acquired"
                      component={MuiTextField}
                      label="permit timeline to be acquired"
                    />
                  </Grid>
                  <Grid item xs={12} sm={5} lg={6}>
                    <Field
                      required
                      name="person_phone_or_email"
                      component={MuiTextField}
                      label="person phone or email"
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  columns={12}
                  //spacing={1}
                  //   justifyContent="center"
                  //   mb={3}
                >
                  <Grid item xs={12} sm={5} lg={5}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={is_rough_inspection_for_racking}
                          name="is_rough_inspection_for_racking"
                          onChange={
                            handleChange_is_rough_inspection_for_racking
                          }
                          checked={is_rough_inspection_for_racking}
                        />
                      }
                      label="is rough inspection for racking"
                    />
                  </Grid>

                  <Grid item xs={12} sm={5} lg={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={is_person_to_follow}
                          name="is_person_to_follow"
                          onChange={handleChange_is_person_to_follow}
                          checked={is_person_to_follow}
                        />
                      }
                      label="is person to follow"
                    />
                  </Grid>
                  <Grid item xs={12} sm={5} lg={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={is_split_permit_needed}
                          name="is_split_permit_needed"
                          onChange={handleChange_is_split_permit_needed}
                          checked={is_split_permit_needed}
                        />
                      }
                      label="is split permit needed"
                    />
                  </Grid>
                </Grid>
                <Grid container columns={12} spacing={1}>
                  <Grid item xs={12} sm={12} lg={5}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={is_stamped_plans_needed}
                          name="is_stamped_plans_needed"
                          onChange={handleChange_is_stamped_plans_needed}
                          checked={is_stamped_plans_needed}
                        />
                      }
                      label="is stamped plans needed"
                    />
                  </Grid>
                  {is_stamped_plans_needed == "1" && (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      lg={is_stamped_plans_needed == "1" ? 7 : 12}
                    >
                      <Field
                        required
                        name="stamped_plan_type"
                        component={MuiSelectField}
                        label="stamped plan type"
                      >
                        <MenuItem value="emailed">emailed </MenuItem>
                        <MenuItem value="mailed">mailed</MenuItem>
                        <MenuItem value="picked_up">picked up</MenuItem>
                      </Field>
                    </Grid>
                  )}
                </Grid>
                <Grid
                  container
                  columns={12}
                  spacing={1}
                  // justifyContent="center"
                  //   mb={3}
                >
                  <Grid item xs={12} sm={12} lg={5}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={is_smoke_detectors_needed}
                          name=" is_smoke_detectors_needed"
                          onChange={handleChange_is_smoke_detectors_needed}
                          checked={is_smoke_detectors_needed}
                        />
                      }
                      label="is smoke detectors needed"
                    />
                  </Grid>
                </Grid>

                {is_smoke_detectors_needed == "1" && (
                  <Grid
                    container
                    columns={12}
                    spacing={1}
                    // justifyContent="center"
                    //   mb={3}
                  >
                    <Grid item xs={12} sm={12} lg={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={is_notarized}
                            name=" is_notarized"
                            onChange={handleChange_is_notarized}
                            checked={is_notarized}
                          />
                        }
                        label="is notarized"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} lg={6}>
                      <Field
                        required
                        name="how_to_get_smoke_detectors"
                        component={MuiTextField}
                        label="how to get smoke detectors"
                      />
                    </Grid>
                  </Grid>
                )}
                <Grid className="" style={{ marginTop: "10px" }}>
                  <Field
                    required
                    name="status"
                    component={MuiSelectField}
                    label="status"
                  >
                    <MenuItem value="pending">pending </MenuItem>
                    <MenuItem value="completed">completed</MenuItem>
                  </Field>
                </Grid>

                <Grid className="" style={{ marginTop: "10px" }}>
                  <Field
                    // required
                    type="text"
                    rows={2}
                    multiline
                    name="special_request_to_plans"
                    component={MuiTextField}
                    label="special request to plans"
                  />
                </Grid>

                <Grid className="" style={{ marginTop: "10px" }}>
                  <Field
                    type="text"
                    rows={2}
                    multiline
                    // required
                    name="additional_requirements"
                    component={MuiTextField}
                    label="additional requirements"
                  />
                </Grid>
              </Grid>
              <DialogActions>
                <PrimaryButton
                  fullWidth
                  type="submit"
                  style={{
                    padding: "0",
                    width: "85%",
                    margin: "15px auto",
                    height: "42px",
                  }}
                  className="saveButton"
                >
                  {call_jurisdictions_data !== undefined
                    ? "Update"
                    : "save"}
                </PrimaryButton>
              </DialogActions>
            </form>
          </Box>
        </ModalContent>
      </DialogContent>
    </Dialog>
  );
};

export default reduxForm({
  form: "CalljurisdictionsForm",
})(CalljurisdictionsModal);
