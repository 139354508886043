import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getTicketStatus } from "../../Redux/Slices/TicketStatus/TicketStatusSlice";
import {getCardsOrder} from "../../Redux/Slices/CardsListSlice/CardsListSlice"
const CardsSummary = ({ ticket_id }) => {
  const dispatch = useDispatch();
  const cardStatus = useSelector((state) => state.TicketStatus);
  const cardsOrder = useSelector((state) => state.cardsOrder);
  const Receiving_Project = cardsOrder?.cardsOrder?.data["Receiving Project"];
  const Design_Submittal = cardsOrder?.cardsOrder?.data["Design Submittal"];
  const Inspection = cardsOrder?.cardsOrder?.data["Inspection"];
  const Installation = cardsOrder?.cardsOrder?.data["Installation"];
  const interconnection = cardsOrder?.cardsOrder?.data["interconnection"];
  const Permits = cardsOrder?.cardsOrder?.data["Permits"];
  const PTO = cardsOrder?.cardsOrder?.data["PTO"];
  const Survey = cardsOrder?.cardsOrder?.data["Survey"];
  const untitled = cardsOrder?.cardsOrder?.data[""];
  const Closing_The_Project =
    cardsOrder?.cardsOrder?.data["Closing The Project"];
  const PTO_submittal = cardsOrder?.cardsOrder?.data["PTO submittal"];
  console.log(+Receiving_Project?.length  +  +Design_Submittal?.length  + +Inspection?.length + +Installation?.length + +interconnection?.length  + +Permits?.length + +PTO?.length + +Survey?.length + +untitled?.length + +Closing_The_Project?.length + +PTO_submittal?.length,   "cardsOrder246810 SUMMARUY")
  // const { ticket_id } = useParams();
  // const params = useParams();
  // console.log(params, "NNNNNNNN");
  let myObject =
    (!cardStatus?.isLodaing && cardStatus?.TicketStatus?.data) || {};

  //const theme_keys = Object.keys(myObject);
  //this returns the values
  useEffect(() => {
    dispatch(getTicketStatus(ticket_id));
    dispatch(getCardsOrder());
  }, [ticket_id]);
  let theme_values = Object.values(myObject);
  let nweArr = [];
  const number_of_finished = theme_values?.filter((val) => val == "5");

  let allcardssize = Object.keys(myObject).length;
  let unfinishedcardssize = number_of_finished.length;

  return (
    <>
      <div className="LeadInfo">
        {cardStatus?.isLodaing ? (
          <p>Loading</p>
        ) : (
          <p style={{ color: "black" }}>
            <span style={{ color: "green", fontWeight: "bolder" }}>
              {unfinishedcardssize} finished of
            </span>{" "}
            {+(allcardssize - 15)}
          </p>
        )}
      </div>
    </>
  );
};

export default CardsSummary;
