import React from "react";
import { UserContainer } from "./UserStyle";
import userimg from "../../Assets/Images/userimg.png";
import { Search } from "@mui/icons-material";
import { useSelector } from "react-redux";

function UserSection() {
  const isAutherized = useSelector((state) => state.auth?.data);
  return (
    <>
      <UserContainer>
        {/* user info  */}
        <div className="user">
          <div className="user__data">
            <div className="user__data--img">
              <img src={userimg} alt="user" />
            </div>

            <div className="user__data--content">
              {/* <h3 className="userName">moo</h3> */}
              <h3 className="mainInfo__title">{isAutherized?.name}</h3>

              <span className="caption">
                Check your activities
                <br /> in this dashboard.
              </span>
            </div>
          </div>
        </div>

        {/* search */}
        <div className="search">
          <div className="search-input">
            <input
              type="text"
              className="form-control"
              placeholder="Who you are looking for...."
              onChange={(e) => console.log(e.target.value)}
            />
            <Search className="searchIcon" />
          </div>
        </div>
      </UserContainer>
    </>
  );
}

export default UserSection;
