import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Grid } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { PrimaryButton } from "../../../Layouts/ButtonsControlled";
import { ModalContent } from "../../../Components/Modals/PmAssignModal/PmAssignModalStyle";
import { useDispatch, useSelector } from "react-redux";
import { getVendorsListAction } from "../../../Redux/Slices/VendorsSlice/VendorsSlice";
import { postProductAvailability , updateProductAvailability } from "../../../Redux/Slices/ProductAvailability/ProductAvailabilitySlice";
import { getTicketStatus } from "../../../Redux/Slices/TicketStatus/TicketStatusSlice"
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import MuiSelectField from "../../../Common/MuiSelectField/MuiSelectField";
import MuiTextField from "../../../Common/muiTextField/MuiTextField"
const ProductAvailabilityModal = ({
  openProductAvailabilityModal,
  handleCloseProductAvailabilityModal,
  lead_id,
  setOpenProductAvailabilityModal,
  handleSubmit,
  productAvailability_status,
  card_status,
  productAvailability_details
}) => {
  const dispatch = useDispatch();
  const vendors = useSelector((state) => state.vendors);
  const productAvailability = useSelector((state) => state.productAvailability);
  console.log(!productAvailability.isError, "productAvailability");
  const vendorsList = vendors?.Vendors?.data;
console.log(productAvailability_status , "productAvailability_status")
console.log(card_status , "card_status")
console.log(productAvailability_details , "productAvailability_details")




  useEffect(() => {
    dispatch(getVendorsListAction());
  }, []);


  const submit = async (values) => {

    console.log(values, "values");
       const reqID = {
      id: productAvailability_details?.id,
      _method : productAvailability_details !== undefined && "PUT",
      ticket_id:lead_id


    };
    //     await dispatch(postProductAvailability({...values,...reqID}));
    // (await !productAvailability.isError) &&
    //   setOpenProductAvailabilityModal(false);
    // await dispatch(getTicketStatus (lead_id));

   (productAvailability_status == "1" || card_status == "1")
    ? (await dispatch(postProductAvailability ({ ...values, ...reqID }))) &&
      (await setOpenProductAvailabilityModal(false))
    : (await dispatch(updateProductAvailability({ ...values, ...reqID }))) &&
      (await setOpenProductAvailabilityModal(false));
  await dispatch(getTicketStatus(lead_id));
  }

  return (
    <Dialog
      maxWidth={"xs"}
      open={openProductAvailabilityModal}
      onClose={handleCloseProductAvailabilityModal}
    >
      <DialogContent>
        <ModalContent>
        <form onSubmit={handleSubmit((values) => submit(values))}>
          <Grid className="display">
        
               <Field
              required
              name="vendor_id"
              component={MuiSelectField}
              label="Vendor"
            >
                {vendorsList?.map((vendor) => (
                  <MenuItem value={vendor.id}>{vendor?.name} </MenuItem>
                ))}
                </Field>
             
          </Grid>
          <Grid className="display">
            
                 <Field
              required
              name="status"
              component={MuiSelectField}
              label="status"
            >
                <MenuItem value="pending">pending </MenuItem>
                <MenuItem value="approved">approved</MenuItem>
                <MenuItem value="rejected">rejected</MenuItem>
                </Field>
           
          </Grid>

          <Grid className="display" style={{ width: "300px", marginTop: "5px" }}>
       
               <Field
               multiline
               rows={3}
              required
              type="text"
              name="note"
              component={MuiTextField}
              label="notes"
            />
          </Grid>

          <DialogActions>
        <PrimaryButton
         // onClick={handleSubmit}
         type="submit"
          fullWidth
          style={{
            padding: "0",
            width: "85%",
            margin: "15px auto",
            height: "42px",
          }}
          className="saveButton"
        >
          {productAvailability_status == "5" ? "Update" : "save"}
        </PrimaryButton>
      </DialogActions>
          </form>
        </ModalContent>
      </DialogContent>
   
    </Dialog>
  );
};


export default connect(null)(
  reduxForm({ form: "ProductAvailabilityForm", enableReinitialize: true })(
    ProductAvailabilityModal
  )
);
