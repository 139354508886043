import React, { useEffect, useState } from "react";
import { reduxForm, Field } from "redux-form";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import {
  Grid,
  Typography,
  MenuItem,
  Select,
  IconButton,
  Button,
  FormControl,
} from "@mui/material";
import { PrimaryButton } from "../../../Layouts/ButtonsControlled";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { getSingleCard } from "../../../Redux/Slices/CreateCard/CreateCardSlice";
import { useDispatch, useSelector } from "react-redux";
import FormFields from "./FormFields";
import { UpdateCardAcion } from "../../../Redux/Slices/CreateCard/CreateCardSlice";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloseIcon from "@mui/icons-material/Close";
import FolderIcon from "@mui/icons-material/Folder";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import DeleteIcon from "@mui/icons-material/Delete";
import styled from "styled-components";
import { Add } from "@mui/icons-material";
import { getSingleCardofTicket , reset_ticket_cards } from "../../../Redux/Slices/ticketCards/ticketCardSlice";
import { Navigate, useNavigate  , useLocation} from "react-router-dom";

function NewCardFieldsModal({
  handleCloseNewCardsFieldsModal,
  openNewCardsFieldsModal,
  setOpenNewCardsFieldsModal,
  card,
  handleSubmit,
  lead_id,
  fieldsData,
  card_id,
  allCardData,
  cardName,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [checkBox_value, setCheckBox_value] = React.useState(0);
  const [fileLimit, setFileLimit] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [UpdatedUploadFiles, setUpdatedUploadedFiles] = useState([]);
  const MAX_COUNT = 500;
  const all_form_fields = useSelector((state) => state.create_card);
  const ticket_cards = useSelector((state) => state.get_ticket_card);
  console.log(ticket_cards, "ticket_cards");
  console.log(all_form_fields?.create_card?.data?.forms
    , "all_form_fields7777777777777777777");
  console.log(location.pathname , "location__________________________________________")
  console.log(!location.pathname.includes("New_card_details") , "+++++++++++++++++++++++++++++++++++++++")

  function isObjEmpty(obj) {
    return Object.keys(obj).length == 0;
  }

  // console.log( fieldsData ,isObjEmpty(fieldsData) , "isObjEmpty(fieldsData)")

  useEffect(() => {
    console.log("**************************");
    let reqData = {
      card_id: card_id || card.card_id,
      ticket_id: lead_id,
    };
    dispatch(getSingleCardofTicket(reqData));
  }, [lead_id]);

  useEffect(() => {
    let reqData = {
      card_id: card_id,
      ticket_id: lead_id,
    };
    console.log("#########################################");
    if(card){
      dispatch(getSingleCard(card.card_id));
    } else if( card_id){
      dispatch(getSingleCard(card_id));
    }
    // card && dispatch(getSingleCard(card.card_id));
    // card_id && dispatch(getSingleCard(card_id));
  }, []);

  React.useEffect(() => {
    let apis = async () => {
      if (ticket_cards?.isSuccess) {
        await dispatch(reset_ticket_cards());
      }
    };
    apis();
  }, [ticket_cards?.isSuccess]);

  const handleShowUploadFiles = (id) => {
    setShowUpload(true);
  };

  const handleRemoveItem = (file) => {
    const name = UpdatedUploadFiles.map((file) => file.name);
    setUpdatedUploadedFiles(
      UpdatedUploadFiles.filter((item) => item.name !== file.name)
    );
    console.log(name, "name");
    console.log(UpdatedUploadFiles, "UpdatedUploadFilesssssssss");
  };

  const handleUploadIssues = async () => {
    const formData = new FormData();
    UpdatedUploadFiles.map((e, i) => {
      // for (const property in e) {
      //   console.log(`${property}: ${e[property]}`);
      formData.append(`media[${i}]`, e);
      // }
    });
    // formData.append("pm_status_issue_id", selectedIssue.id);

    // await dispatch(Upload_issue(formData));
    // await dispatch(getIssues(reqData));
    //console.log(formData, "formData", files, "files");
  };

  const handleUploadFiles = (files) => {
    const uploaded = [...UpdatedUploadFiles];
    let limitExceeded = false;
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        setUpdatedUploadedFiles(uploaded);
        if (uploaded.length === MAX_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_COUNT) {
          alert(`You can only add a maximum of ${MAX_COUNT} files`);
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    });
    if (!limitExceeded) {
      setUploadedFiles(uploaded);
    }
  };

  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };

  const handlecloseUpload = () => {
    setShowUpload(false);
  };
  const handleChange_checkbox_value = (event) => {
    setCheckBox_value(event.target.checked);
    console.log(event.target);
    console.log(event.target.checked);
  };

  const getFieldByType = (field) => {
    const label = field.label;

    if (field.type == "Dropdown") return FormFields.selectField(field, label);
    else if (field.type == "Checkboxes")
      return (
        <>
          <Typography>{field?.label}</Typography>
          {field?.values?.length > 0 &&
            field?.values?.map((item) => (
              <FormControlLabel
                control={
                  <Checkbox
                    value={checkBox_value}
                    name={field.id}
                    onChange={handleChange_checkbox_value}
                  />
                }
                label={item.key}
              />
            ))}
        </>
      );
    else if (field.type == "file")
      return (
        <>
          <Typography>{field?.label}</Typography>
          <IconButton onClick={() => handleShowUploadFiles()}>
            <Typography>
              <span style={{ color: "black", fontWeight: "bold" }}>
                Upload Files
                {!showUpload && (
                  <CloudUploadIcon style={{ marginLeft: "30px" }} />
                )}
              </span>{" "}
            </Typography>
          </IconButton>

          {showUpload && (
            <IconButton onClick={handlecloseUpload}>
              <CloseIcon />
            </IconButton>
          )}

          {showUpload && (
            <Grid xs={12}>
              <Grid className="display" mt={4}>
                <FormControl variant="outlined">
                  <label htmlFor="contained-button-file">
                    <Input
                      accept="image/*,video/*"
                      id="contained-button-file"
                      multiple
                      type="file"
                      // value={utiltyBill.media}
                      onChange={handleFileEvent}
                    />
                    <Button component="span" className="btn_upload">
                      <span className="icon">
                        <Add />
                      </span>
                      <span className="title"> Upload Files</span>
                    </Button>
                  </label>
                  {/* </Grid> */}
                </FormControl>
                <List>
                  {UpdatedUploadFiles.map((file, index) => (
                    <ListItem
                      key={index}
                      secondaryAction={
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => handleRemoveItem(file)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      }
                    >
                      <ListItemAvatar style={{ display: "flex" }}>
                        <FolderIcon />
                        <Typography>{file.name}</Typography>{" "}
                      </ListItemAvatar>
                      <ListItemText />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
          )}
        </>
      );
    else return FormFields.textField(field, label);
  };

  const submit = async (values) => {
    console.log(values, "valuesssss");
    const formData = new FormData();
    formData.append("ticket_id", lead_id);
    card && formData.append("title", card?.title);
    //  card &&  formData.append("status", "5");
    allCardData && formData.append("title", allCardData?.title);
    card && formData.append("id", card?.card_id);
    allCardData && formData.append("id", allCardData?.id);
    all_form_fields?.create_card?.data?.forms &&
      all_form_fields?.create_card?.data?.forms?.map((e, i) => {
        console.log(e.type, "typeeeeeeeee");
        for (const property in e) {
        }
        formData.append(`forms[${i}][type]`, e.type);
        formData.append(`forms[${i}][key]`, e.key);
        e.type == "TextInput" &&
          formData.append(`forms[${i}][value]`, values[`${e.type}_${e.key}`]);
        e.type == "TextArea" &&
          formData.append(`forms[${i}][value]`, values[`${e.type}_${e.key}`]);
        e.type == "NumberInput" &&
          formData.append(`forms[${i}][value]`, values[`${e.type}_${e.key}`]);
        e.type == "date" &&
          formData.append(`forms[${i}][value]`, values[`${e.type}_${e.key}`]);
        e.type == "Dropdown" &&
          formData.append(`forms[${i}][value]`, values[`${e.type}_${e.key}`]);
        // e.type == "Checkboxes" && formData.append(`forms[${i}][checked]`,checkBox_value);

        formData.append(`forms[${i}][label]`, e.label);
        e.type == "Dropdown" &&
          e.values.map((e, j) => {
            for (const property in e) {
              formData.append(`forms[${i}][values][${j}][key]`, e.key);
            }
          });

        e.type == "file" &&
          UpdatedUploadFiles.map((e, j) => {
            formData.append(`forms[${i}][value][${j}]`, e);
          });
        // e.type == "Checkboxes" && e.values.map((e, j) => {
        //     for (const property in e) {
        //         formData.append(`forms[${i}][values][${j}][key]`, e.key);
        //         formData.append(`forms[${i}][values][${j}][key][checked]`, checkBox_value);
        //         //  formData.append(`forms[${i}][values][${j}][value]`, "test");

        //     }
        // });
      });
    await dispatch(UpdateCardAcion(formData));
    await setOpenNewCardsFieldsModal(false);
    (await card_id) && dispatch(getSingleCard(card_id));
  };

  // ticket_cards?.ticket_card

  const openCardFieldsWithValues = () => {
    // !location.pathname.includes("New_card_details")
    if(card){
      navigate(
        `/home/SolarSolution/New_card_details/${lead_id}/${cardName}/${card.card_id}`
      )
    }
    else if(card_id){
      navigate(
        `/home/SolarSolution/New_card_details/${lead_id}/${cardName}/${card_id}`
      ); 
    }
    // else if(card_id && location.pathname.includes("New_card_details") ){
    //   navigate(`/home/SolarSolution/New_card_details/${lead_id}/${cardName}/${card.card_id}`);

    // }
    // card
    //   ? navigate(
    //       `/home/SolarSolution/New_card_details/${lead_id}/${cardName}/${card.card_id}`
    //     )
    //   : navigate(
    //       `/home/SolarSolution/New_card_details/${lead_id}/${cardName}/${card_id}`
    //     );
        // navigate(
        //   `/home/SolarSolution/New_card_details/${lead_id}/${cardName}/${card.card_id}`
        // );
  };

  const openCardFieldsWithoutValues = () => (
    <Dialog
      maxWidth="xs"
      fullWidth={true}
      open={openNewCardsFieldsModal}
      onClose={handleCloseNewCardsFieldsModal}
    >
      <form onSubmit={handleSubmit((values) => submit(values))}>
        <DialogContent>
          {all_form_fields?.isLodaing ? (
            <p>loading....</p>
          ) : (
            <>
              <p>cards fields</p>
              {/* {!isObjEmpty(fieldsData) && ( */}
                <div className="">
                  {all_form_fields?.create_card?.data?.forms?.map(
                    (field, index) => (
                      <div key={index}>{getFieldByType(field)}</div>
                    )
                  )}
                </div>
              {/* // )} */}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <PrimaryButton
            fullWidth
            type="submit"
            style={{
              padding: "0",
              width: "55%",
              margin: "20px auto",
              height: "42px",
            }}
            className="saveButton"
          >
            Save
          </PrimaryButton>
        </DialogActions>
      </form>
    </Dialog>
  );

  return (


    <>

    {/* {(ticket_cards?.isLodaing && all_form_fields?.isLodaing) && ( <p>loading......</p>)}
  
    {ticket_cards?.ticket_card == null && openCardFieldsWithoutValues() }
    {ticket_cards?.ticket_card !== null &&  openCardFieldsWithValues() } */}
      {/* {ticket_cards?.isLodaing && all_form_fields?.isLodaing ? (
        <p>loading......</p>
      ) : ticket_cards?.ticket_card == null ? (
        openCardFieldsWithoutValues()
      ) : (
        openCardFieldsWithValues()
      )} */}

      {!ticket_cards?.isLodaing &&
        !all_form_fields?.isLodaing &&
        ((ticket_cards?.ticket_card !== null && location.pathname.includes("New_card_details"))
          ? openCardFieldsWithoutValues() : ticket_cards?.ticket_card == null ? openCardFieldsWithoutValues() 
          : openCardFieldsWithValues())
          
          }
    </>
  );
}

const Input = styled.input`
  display: none;
`;
export default reduxForm({
  form: "NewCardFieldsForm",
})(NewCardFieldsModal);
