import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import {
  Grid,
  Button,
  Typography,
  IconButton,
  InputLabel,
} from "@mui/material";
import { reduxForm, Field } from "redux-form";
import { TextField } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import UploadFilesForCard from "../CardIssues/UploadFilesForCard";
import { getInstallersListAction } from "../../Redux/Slices/Installers/InstallersSlice";
import { useDispatch, useSelector } from "react-redux";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import FolderIcon from "@mui/icons-material/Folder";
import DeleteIcon from "@mui/icons-material/Delete";
import { useParams } from "react-router-dom";
import {
  send_work_order_req,
  resetWorkOrder,
} from "../../Redux/Slices/SendDocumentsToInstallerSlice/SendDocumentsSlice";
import { PrimaryButton } from "../../Layouts/ButtonsControlled";
import { useNavigate } from "react-router-dom";
import { getSingleTicket } from "../../Redux/Slices/SingleTicketSlice/SingleTicketSlice";
import MuiSelectField from "../../Common/MuiSelectField/MuiSelectField";
import MuiTextField from "../../Common/muiTextField/MuiTextField";

const WorkOrderForm = ({ selectedInstaller, handleSubmit }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { ticket_id } = useParams();
  const formNotify = useSelector((state) => state.form);
  const installersList = useSelector((state) => state.installers);
  const work_order_state = useSelector((state) => state.work_order);
  const [installer, setInstaller] = useState();
  const [inputList, setInputList] = useState([
    { description: "", qty: "", price: "" },
  ]);
  const installers_list = installersList?.installers?.data;
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [UpdatedUploadFiles, setUpdatedUploadedFiles] = useState([]);
  // const [files, setFiles] = useState([]);
  const [showUpload, setShowUpload] = useState(false);
  const [selectedIssue, setSelectedIssue] = useState();
  const [selectedUploadedFile, setSelectedUploadedFiles] = useState();
  const total = "5";
  const single_ticket = useSelector((state) => state.single_ticket);
  const work_order = useSelector((state) => state.work_order);
  const state_id =
    single_ticket?.singleTicket?.data?.opportunity?.lead?.state_id;

  useEffect(() => {
    dispatch(getSingleTicket(ticket_id));
    // dispatch(getInstallersListAction(state_id));
  }, []);
  //   useEffect(() => {
  //     dispatch(getSingleTicket(ticket_id));
  //   }, []);
  const handleShowUploadFiles = (issue) => {
    setSelectedIssue(issue);
    setShowUpload(true);
  };
  const handleRemoveItem = (file) => {
    const name = UpdatedUploadFiles.map((file) => file.name);
    setUpdatedUploadedFiles(
      UpdatedUploadFiles.filter((item) => item.name !== file.name)
    );
  };

  const submit = async (values) => {
    console.log(values, "values");
    const formData = new FormData();
    formData.append("ticket_id", ticket_id);
    formData.append("installer_email", selectedInstaller?.email);
    formData.append("installer_name", selectedInstaller?.name);
    formData.append("installer_id", selectedInstaller.id);
    // formData.append("site_survey", values.site_survey);
    formData.append("tax_rate", values.taxRate);
    formData.append("ppw", values.ppw);
    // formData.append("survey_date", values.survey_date);
    // formData.append("is_any_animal", values.is_any_animal);
    // formData.append("want_be_on_site", values.want_be_on_site);
    // formData.append("system_size", values.system_size);
    // formData.append("number_of_panels", values.number_of_panels);
    // formData.append("type_of_inverter", values.type_of_inverter);
    // formData.append("adders", values.adders);
    formData.append("mail_body", values.mail_body);
    formData.append("work_description", values.work_description);
    formData.append("additional_comments", values.additional_comments);
    formData.append("site_survey", 0);
    inputList.map((e, i) => {
      for (const property in e) {
        formData.append(`labor[${i}][${property}]`, e[property]);
      }
    });
    uploadedFiles.map((e, i) => {
      formData.append(`pdfs[${i}]`, e);
    });

    await dispatch(send_work_order_req(formData));
    // work_order?.isSuccess &&
    //   (await navigate(`/home/SolarSolution/Work_orders/${ticket_id}`));
  };

  React.useEffect(() => {
    let apis = async () => {
      if (work_order?.isSuccess) {
        await navigate(`/home/SolarSolution/start-project/${ticket_id}`);
        await dispatch(resetWorkOrder());
      }
    };
    apis();
  }, [work_order?.isSuccess]);

  const handleSelectUser = (installer) => {
    setInstaller(installer);
    console.log(installer, "selected installer");
  };
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { description: "", qty: "", price: "" }]);
  };
  return (
    <Paper style={{ padding: "30px 10px" }}>
      <form onSubmit={handleSubmit((values) => submit(values))}>
        <Grid container columns={12} spacing={5} justifyContent="center" mb={3}>
          {/* <Grid item xs={12} sm={6} lg={3}>
            <Field
              required
              name="site_survey"
              component={MuiSelectField}
              label="site survey"
            >
              <MenuItem value={0} label="option 1">
                NO
              </MenuItem>
              <MenuItem value={1} label="option 2">
                Yes
              </MenuItem>
            </Field>
          </Grid> */}
          <Grid item xs={12} sm={6} lg={6}>
            <Field
              required
              type="number"
              name="taxRate"
              component={MuiTextField}
              label="tax Rate"
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={6}>
            <Field
              required
              type="number"
              name="ppw"
              component={MuiTextField}
              label="ppw"
            />
          </Grid>
        </Grid>
        {/* {formNotify?.work_order_Form?.values?.site_survey == 1 && (
          <>
            <Grid
              container
              columns={12}
              spacing={5}
              justifyContent="center"
              mb={3}
            >
              <Grid item xs={12} sm={6} lg={3}>
                <Field
                  required
                  type="date"
                  name="survey_date"
                  component={MuiTextField}
                  label="survey date"
                />
              </Grid>
              <Grid item xs={12} sm={3} lg={3}>
                <Field
                  required
                  name="is_any_animal"
                  component={MuiSelectField}
                  label="is any animal"
                >
                  <MenuItem value={0} label="option 1">
                    NO
                  </MenuItem>
                  <MenuItem value={1} label="option 2">
                    Yes
                  </MenuItem>
                </Field>
              </Grid>

              <Grid item xs={12} sm={3} lg={3}>
                <Field
                  required
                  name="want_be_on_site"
                  component={MuiSelectField}
                  label="want be on site"
                >
                  <MenuItem value={0} label="option 1">
                    NO
                  </MenuItem>
                  <MenuItem value={1} label="option 2">
                    Yes
                  </MenuItem>
                </Field>
              </Grid>
            </Grid>

            <Grid
              container
              columns={12}
              spacing={5}
              justifyContent="center"
              mb={3}
            >
              <Grid item xs={12} sm={3} lg={3}>
                <Field
                  required
                  type="number"
                  name="system_size"
                  component={MuiTextField}
                  label="system size"
                />
              </Grid>

              <Grid item xs={12} sm={3} lg={3}>
                <Field
                  required
                  type="number"
                  name="number_of_panels"
                  component={MuiTextField}
                  label="number of panels"
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Field
                  required
                  name="type_of_inverter"
                  component={MuiTextField}
                  label="type of inverter"
                />
              </Grid>
            </Grid>
            <Grid
              container
              columns={12}
              spacing={5}
              justifyContent="center"
              mb={3}
            >
              <Grid item xs={12} sm={12} lg={9}>
                <Field
                  required
                  multiline
                  rows={2}
                  name="adders"
                  component={MuiTextField}
                  label="adders"
                />
              </Grid>
            </Grid>
          </>
        )} */}
        <Grid container columns={12} spacing={5} justifyContent="center" mb={3}>
          <Grid item xs={12} sm={12} lg={9}>
            <Field
              required
              multiline
              rows={2}
              name="mail_body"
              component={MuiTextField}
              label="Email Body"
            />
          </Grid>
        </Grid>
        <Grid container columns={12} spacing={5} justifyContent="center" mb={3}>
          <Grid item xs={12} sm={12} lg={9}>
            <Field
              required
              multiline
              rows={2}
              name="work_description"
              component={MuiTextField}
              label="work description"
            />
          </Grid>
        </Grid>
        <Grid container columns={12} spacing={5} justifyContent="center" mb={3}>
          <Grid item xs={12} sm={12} lg={9}>
            <Field
              required
              multiline
              rows={2}
              name="additional_comments"
              component={MuiTextField}
              label="additional Comments"
            />
          </Grid>
        </Grid>
        <Grid
          container
          columns={12}
          spacing={5}
          justifyContent="center"
          // mb={5}
          mt={4}
        >
          {inputList.map((x, i) => {
            return (
              <div>
                <Grid mb={3}>
                  <Grid item style={{ display: "flex", flexDirection: "row" }}>
                    <Grid style={{ margin: "1px 10px" }}>
                      {" "}
                      <TextField
                        fullWidth
                        label="description"
                        multiline
                        rows={1}
                        name="description"
                        value={x.description}
                        onChange={(e) => handleInputChange(e, i)}
                      />
                    </Grid>

                    <Grid style={{ margin: "1px 10px" }}>
                      {" "}
                      <TextField
                        fullWidth
                        type="number"
                        label="quantity"
                        multiline
                        rows={1}
                        name="qty"
                        value={x.qty}
                        onChange={(e) => handleInputChange(e, i)}
                      />
                    </Grid>

                    <Grid style={{ margin: "1px 10px" }}>
                      {" "}
                      <TextField
                        fullWidth
                        label="price"
                        multiline
                        rows={1}
                        name="price"
                        value={x.price}
                        onChange={(e) => handleInputChange(e, i)}
                      />
                    </Grid>

                    {inputList.length !== 1 && (
                      <Button
                        variant="danger"
                        onClick={() => handleRemoveClick(i)}
                      >
                        <DeleteForeverIcon />
                      </Button>
                    )}
                    {inputList.length - 1 === i && (
                      <Button variant="warning" onClick={handleAddClick}>
                        <AddCircleOutlineIcon />
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </div>
            );
          })}
        </Grid>
        <Grid container columns={12} spacing={5} justifyContent="center" mb={3}>
          <Grid item xs={12} sm={12} lg={9}>
            <div>
              <Grid
                container
                columns={12}
                spacing={6}
                justifyContent="center"
                mt={5}
                mb={3}
                style={{ padding: "1px 25px" }}
              >
                <UploadFilesForCard
                  handleShowUploadFiles={handleShowUploadFiles}
                  selectedIssue={selectedIssue}
                  setShowUpload={setShowUpload}
                  showUpload={showUpload}
                  setUploadedFiles={setUploadedFiles}
                  uploadedFiles={uploadedFiles}
                  UpdatedUploadFiles={UpdatedUploadFiles}
                  setUpdatedUploadedFiles={setUpdatedUploadedFiles}
                />
                {UpdatedUploadFiles?.map((file, index) => (
                  <>
                    <ListItem
                      secondaryAction={
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => handleRemoveItem(file)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      }
                    >
                      <ListItemAvatar style={{ display: "flex" }}>
                        <FolderIcon />
                        <Typography>{file.name}</Typography>{" "}
                      </ListItemAvatar>
                      <ListItemText />
                    </ListItem>
                  </>
                ))}
              </Grid>
            </div>
          </Grid>
        </Grid>
        <Grid container columns={12} spacing={5} justifyContent="center" mb={3}>
          <PrimaryButton
            fullWidth
            type="submit"
            style={{
              padding: "0",
              width: "55%",
              margin: "20px auto",
              height: "42px",
            }}
            className="saveButton"
          >
            {work_order_state?.isLodaing ? (
              <CircularProgress size={25} thickness={18} />
            ) : null}
            Save
          </PrimaryButton>
        </Grid>
      </form>
    </Paper>
  );
};

export default reduxForm({ form: "work_order_Form", enableReinitialize: true })(
  WorkOrderForm
);
