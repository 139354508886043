import axios from "axios";
import { vendorsList } from "../../../Api/Api";

const Get_vendorsList_Action = async () => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${vendorsList}`,
    headers: headers,
    params: {
      return_all: 1,
    },
  });
  return response.data;
};

const Vendors_Service = {
  Get_vendorsList_Action,
};
export default Vendors_Service;
