import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styledd from "styled-components";
import { Box } from "@mui/system";
import Avatar from '@mui/material/Avatar';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ListItemAvatar from "@mui/material/ListItemAvatar";
import FolderIcon from "@mui/icons-material/Folder";
import {
  Button,
  Typography,
  Grid,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  IconButton,
  List,
  ListItem,
  ListItemText

} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { getWorkOrders } from "../../Redux/Slices/WorkOrders/WorkOrdersSlice";
import { getSiteSurveyData } from "../../Redux/Slices/WorkOrders/WorkOrdersSlice";
import { getSingleTicket } from "../../Redux/Slices/SingleTicketSlice/SingleTicketSlice";
import { useParams } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LottieAnimation from "../../Components/LottieAnimation/LottieAnimation";
import loading from "../../Assets/lottie/loading.json";
import { Link } from "react-router-dom";
import { PrimaryButton } from "../../Layouts/ButtonsControlled";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import SendIcon from "@mui/icons-material/Send";
import PmAssignModal from "../../Components/Modals/PmAssignModal/PmAssignModal";
import ImageIcon from "@mui/icons-material/Image";
import Badge from '@mui/material/Badge';
import Toolbar from '@mui/material/Toolbar';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AppBar from '@mui/material/AppBar';
import { useNavigate } from "react-router-dom";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const SiteSurveyList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { lead_id } = useParams();
  const Site_survey_data = useSelector((state) => state.workOrders);
  const single_ticket = useSelector((state) => state.single_ticket);
  const site_survey_list = Site_survey_data?.workOrders?.data
  console.log(
    "ticket_idticket_idticket_idticket_idticket_idticket_idticket_idticket_idticket_idticket_idticket_id",
    lead_id
  );
  const assigned_ticket =
    single_ticket?.singleTicket?.data?.ticket?.user_id == 0;
  const [openTicketModal, setOpenTicketModal] = useState(false);

  const handleOpenTicketModal = (e) => {
    setOpenTicketModal(true);

    console.log("open modal");
  };
  const handleCloseTicketModal = () => {
    setOpenTicketModal(false);
  };
  console.log(
    single_ticket?.singleTicket?.data?.ticket?.user_id,
    "assigned_usersdsdsdsdsssdsssdssd"
  );
  console.log(single_ticket?.isLodaing, "single_ticketsingle_ticket");
  console.log(Site_survey_data?.workOrders?.data, "workOrders");
  console.log(Site_survey_data, "work_order_list loading");

  useEffect(() => {
    let apis = async () => {
      if (lead_id) {
        console.log("ticket_id in IF", lead_id);
        // await dispatch(getSingleTicket(ticket_id));
        // await dispatch(getSiteSurveyData(ticket_id));
      }
    };
    // apis();
    // dispatch(getInstallersListAction(state_id));
  }, [lead_id]);
  useEffect(() => {
    dispatch(getSiteSurveyData(lead_id));
  }, []);
  //
  return (
    <MainTabsStyled>
      <Box
        sx={{ width: "100%", typography: "body1" }}
        style={{ backgroundColor: "white" }}
      >
        {Site_survey_data?.isLodaing ? (
          <LottieAnimation lotti={loading} width={180} height={180} />
        ) : (
          <>
            <Box
              sx={{ width: "100%", typography: "body1" }}
            // style={{ backgroundColor: "white" }}
            >
  <AppBar position="static"
              style={{ backgroundColor: "white", color: "black" }}
            >
              <Toolbar>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                  onClick={() => navigate(`/home/SolarSolution/start-project/${lead_id}`)}

                >
                  <ArrowBackIcon />
                </IconButton>
              
                <Typography variant="h5" align="center"> Site Surveys </Typography>
              </Toolbar>
            </AppBar>

              <Grid style={{ padding: "20px" }}>
               
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  {site_survey_list &&
                    site_survey_list
                      ?.length > 0 ? (
                    site_survey_list?.map(
                      (site_survey) => (
                        <Grid item sm={12} xs={12} lg={6} >
                          <Card
                            style={{
                              background: "rgb(249, 245, 255)",
                              border: "1px solid rgb(214, 187, 251)",
                              borderRadius: "8px",
                              padding: "1rem",
                              margin: "0.5rem 0px",
                              position: "relative",

                            }}
                          >

                            <Badge sx={{ position: "absolute", left: "50.5%", top: "23px" }}  badgeContent={

                              // site_survey?.installer_approval
                              <div  style={{
                                width: "120px",
                                height: "47px",
                                backgroundColor: "slategrey",
                                color: "blanchedalmond",
                                fontSize: "14px",
                                borderRadius: "6%",
                                padding: "13px 6px",
                                display:"flex",
                                justifyContent:"center"

                              }}>
                                {site_survey?.installer_approval}
                              </div>

                            }
                              style={{ position: "absolute", left: "88.5%", top: "23px" }}>

                            </Badge>
                            <CardContent
                            style={{marginTop:"40px"}}

                            >

                              {/* <Button variant="contained" color="success" style={{ position: "relative", left: "65%" , }}>
                              Success
                            </Button> */}
                              {/* <CardHeader
                                 style={{
                                  background: "rgb(249, 245, 255)",}}
                              avatar={
                                <Avatar  aria-label="recipe">
                                  R
                                </Avatar>
                              }
                            
                            /> */}
                              <Typography>
                                {" "}
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    color: "#7e7ec0",
                                  }}
                                >
                                  {" "}
                                  installer name
                                  :{" "}
                                </span>{" "}
                                {site_survey?.installer_name}
                              </Typography>

                              <Typography>
                                {" "}
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    color: "#7e7ec0",
                                  }}
                                >
                                  {" "}
                                  installer email
                                  :{" "}
                                </span>{" "}
                                {site_survey?.installer_email
                                }
                              </Typography>

                              <Typography>
                                {" "}
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    color: "#7e7ec0",
                                  }}
                                >
                                  {" "}
                                  survey date :{" "}
                                </span>{" "}
                                {new Date(
                                  site_survey?.survey_date
                                ).toLocaleDateString("en-US", {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                                })}

                              </Typography>
                              <Typography>
                                {" "}
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    color: "#7e7ec0",
                                  }}
                                >
                                  {" "}
                                  Do you have any animals outside the property? :{" "}
                                </span>{" "}
                                {site_survey?.is_any_animal == "1" ? "Yes" : "No"}
                              </Typography>

                              <Typography>
                                {" "}
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    color: "#7e7ec0",
                                  }}
                                >
                                  {" "}
                                  Would vou prefer to be on site during survey? :{" "}
                                </span>{" "}
                                {site_survey?.want_be_on_site == "1" ? "Yes" : "No"}
                              </Typography>

                              <Typography>
                                {" "}
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    color: "#7e7ec0",
                                  }}
                                >
                                  {" "}
                                  ppw :{" "}
                                </span>{" "}
                                {site_survey?.ppw}
                              </Typography>

                              <Typography>
                                {" "}
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    color: "#7e7ec0",
                                  }}
                                >
                                  {" "}
                                  system size :{" "}
                                </span>{" "}
                                {site_survey?.system_size}
                              </Typography>

                              <Typography>
                                {" "}
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    color: "#7e7ec0",
                                  }}
                                >
                                  {" "}
                                  number of panels :{" "}
                                </span>{" "}
                                {site_survey?.number_of_panels}
                              </Typography>

                              <Typography>
                                {" "}
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    color: "#7e7ec0",
                                  }}
                                >
                                  {" "}
                                  type of inverter:{" "}
                                </span>{" "}
                                {site_survey?.type_of_inverter}
                              </Typography>
                              <Typography>
                                {" "}
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    color: "#7e7ec0",
                                  }}
                                >
                                  {" "}
                                  labor total:{" "}
                                </span>{" "}
                                {site_survey?.labor_total}
                              </Typography>
                              <Typography>
                                {" "}
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    color: "#7e7ec0",
                                  }}
                                >
                                  {" "}
                                  adders:{" "}
                                </span>{" "}
                                {site_survey?.adders}
                              </Typography>

                              <Typography>
                                {" "}
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    color: "#7e7ec0",
                                  }}
                                >
                                  {" "}
                                  mail body:{" "}
                                </span>{" "}
                                {site_survey?.mail_body}
                              </Typography>

                              <Typography>
                                {" "}
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    color: "#7e7ec0",
                                  }}
                                >
                                  {" "}
                                  additional comments:{" "}
                                </span>{" "}
                                {site_survey?.additional_comments}
                              </Typography>
                              {site_survey?.file?.license_img && (
                                <Typography>
                                  {" "}
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: "#7e7ec0",
                                    }}
                                  >
                                    {" "}
                                    License Image :{" "}
                                  </span>{" "}
                                  <a
                                    href={site_survey?.file?.license_img}
                                    target="_blank"
                                  >
                                    <ImageIcon />{" "}
                                  </a>
                                </Typography>
                              )}

<Grid>
                              <Typography variant="h6" align="center" style={{margin:"15px 0px"}}>
                                Uploaded Files
                              </Typography>
                              <List>
                                {site_survey?.media &&
                                  site_survey?.media?.map((file, index) => (
                                    <ListItem
                                      key={index}
                                      // secondaryAction={
                                      //   <IconButton
                                      //     edge="end"
                                      //     aria-label="delete"
                                      //    // onClick={() => handleDelete(file)}
                                      //   >
                                      //     <DeleteIcon />
                                      //   </IconButton>
                                      // }
                                    >
                                      <ListItemAvatar>
                                        <a href={file.path} target="_blank">
                                          <Avatar>
                                            <FolderIcon />
                                          </Avatar>
                                        </a>
                                      </ListItemAvatar>
                                      <ListItemText
                                        primary="View File"
                                      />
                                    </ListItem>
                                  ))}
                              </List>
                            </Grid>
                            </CardContent>

                          </Card>
                        </Grid>
                      )
                    )
                  ) : (
                    <Grid
                      container
                      // rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                      <Grid item sm={6} lg={12} xs={12} style={{ padding: "20px 0px" }}>
                        <Typography align="center">There is no site surveys created</Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </Box>
    </MainTabsStyled>
  );
};

const MainTabsStyled = styledd.div`
  margin: 1rem 0.8rem;
  border-radius: 5px;
`;
const Value = styledd.span`
  color: rgb(136, 136, 136);
`;
export default SiteSurveyList;
