import styled from "styled-components";
export const SteperContainer = styled.div`
  /* width: 100%; */
  margin-top: 50px;

  .step {
    padding: 10px;
    margin: 10px 10px;
    display: flex;
    /* flex-direction: row; */
    /* justify-content: flex-start; */

    /* background-color: #f8e9c0; */
  }

  .v-stepper {
    position: relative;

    top: 35px;
    margin-right: 10px;

    /*   visibility: visible; */
  }

  /* regular step */
  .step .circle {
    background-color: white;
    border: 3px solid #dedede;
    border-radius: 100%;
    width: 30px; /* +6 for border */
    height: 30px;
    display: inline-block;
  }

  .step .line {
    top: 32px;
    left: 14px;
    /*   height: 120px; */
    height: 117%;

    position: absolute;
    border-left: 3px solid #dedede;
  }

  .step.completed .circle {
    visibility: visible;
    /* background-color: rgb(6, 150, 215); */
    background-color: white;
    border: 8px solid #5da723;
  }

  .step.completed .line {
    border-left: 3px solid #bae29b;
  }

  .step.progress .circle {
    visibility: visible;
    border: 8px solid #dedede;
  }

  .step.pending .circle {
    visibility: visible;
    border: 8px solid #dedede;
  }

  .step.empty .circle {
    visibility: hidden;
  }

  .step.empty .line {
    /*     visibility: hidden; */
    /*   height: 150%; */
    top: 0;
    height: 150%;
  }

  .step:last-child .line {
    border-left: 3px solid white;
    z-index: -1; /* behind the circle to completely hide */
  }

  .content {
    margin-left: 20px;
    display: inline-block;
  }
`;
export const CardContainer = styled.div`
  width: 400px;
  @media only screen and (min-width: 321px) and (max-width: 580px) {
    width: 350px;
  }
`;
