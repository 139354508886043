import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Grid, Button, Typography } from "@mui/material";
import { ModalContent } from "../PmAssignModal/PmAssignModalStyle";
import FormControl from "@mui/material/FormControl";
import { TextField } from "@mui/material";
import { PrimaryButton } from "../../../Layouts/ButtonsControlled";
import FormGroup from "@mui/material/FormGroup";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CircularProgress from "@mui/material/CircularProgress";
import {
  create_issue,
  reset,
} from "../../../Redux/Slices/CreateIssue/Create_Issue_Slice";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import InputLabel from "@mui/material/InputLabel";
const AddIssueForm = ({
  handleopenAddIssueForm,
  handleCloseAddIssueForm,
  setAddIssueForm,
  ticket_id,
  pm_status_id,
  issueCardType,
  title,
}) => {
  const [inputList, setInputList] = useState([
    { title: "", note: "", status: "" },
  ]);
  const dispatch = useDispatch();
  const issues = useSelector((state) => state.issues);
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { title: "", note: "", status: "" }]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const reqData = {
      ticket_id: +ticket_id,
      pm_status_id: pm_status_id,
      issues: inputList,
      type: issueCardType,
    };
    await dispatch(create_issue(reqData));
    // await setAddIssueForm(false);
  };
  React.useEffect(() => {
    let apis = async () => {
      if (issues?.isSuccess) {
        await setAddIssueForm(false);
        await dispatch(reset());
      }
    };
    apis();
  }, [issues?.isSuccess]);

  useEffect(() => {
    let apis = async () => {
      await dispatch(reset());
    };
    apis();
  }, []);

  return (
    <Dialog
      maxWidth={"sm"}
      open={handleopenAddIssueForm}
      onClose={handleCloseAddIssueForm}
    >
      <DialogContent>
        <ModalContent>
          <Typography align="center" variant="h5">
            {" "}
            {` Add Issues for ${title} card `}
          </Typography>
          {inputList.map((x, i) => {
            return (
              <Grid style={{ display: "flex", alignItems: "baseline" }}>
                <form>
                  <Grid container spacing={2}>
                    <Grid item xs={6} md={8}>
                      <FormGroup>
                        <FormControl variant="outlined">
                          <TextField
                            fullWidth
                            label="title"
                            multiline
                            rows={1}
                            style={{ width: "300px", marginTop: "50px" }}
                            name="title"
                            value={x.title}
                            onChange={(e) => handleInputChange(e, i)}
                          />

                          <TextField
                            fullWidth
                            label="note"
                            multiline
                            rows={3}
                            style={{ width: "300px", marginTop: "50px" }}
                            name="note"
                            value={x.note}
                            onChange={(e) => handleInputChange(e, i)}
                          />
                          <div style={{ marginTop: "20px" }}>
                            <FormControl sx={{ width: 300 }}>
                              <InputLabel id="demo-multiple-chip-label">
                                Status
                              </InputLabel>
                              <Select
                                labelId="demo-multiple-chip-label"
                                id="demo-multiple-chip"
                                name="status"
                                value={x.status}
                                onChange={(e) => handleInputChange(e, i)}
                                label="status"
                              >
                                <MenuItem value="pending">pending</MenuItem>
                                <MenuItem value="in_progress">
                                  in progress
                                </MenuItem>
                                <MenuItem value="hold">hold</MenuItem>
                                <MenuItem value="completed">completed</MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                        </FormControl>
                      </FormGroup>
                    </Grid>
                  </Grid>
                </form>

                <div className="btn-box">
                  {inputList.length !== 1 && (
                    <Button
                      variant="danger"
                      onClick={() => handleRemoveClick(i)}
                    >
                      <DeleteForeverIcon />
                    </Button>
                  )}
                  {inputList.length - 1 === i && (
                    <Button variant="warning" onClick={handleAddClick}>
                      <AddCircleOutlineIcon />
                    </Button>
                  )}
                </div>
              </Grid>
            );
          })}
        </ModalContent>
      </DialogContent>
      <DialogActions>
        <PrimaryButton
          fullWidth
          style={{
            padding: "0",
            width: "85%",
            margin: "15px auto",
            height: "42px",
          }}
          className="saveButton"
          onClick={handleSubmit}
          disabled={issues?.isLodaing}
        >
          Submit
          {issues?.isLodaing ? (
            <CircularProgress size={25} thickness={18} />
          ) : null}
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddIssueForm;
