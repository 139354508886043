import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import adders_table_Service from "./AddersTableService";

const initialState = {
  addersTable: {},
  isError: false,
  isSuccess: false,
  isLodaing: false,
  massage: "",
};

export const get_all_adders_table = createAsyncThunk(
  "addersTable/getAll",
  async (ticket_id, thunkAPI) => {
    try {
      return await adders_table_Service.Get_adders_table_Action(ticket_id);
    } catch (error) {
      const massage = error.message;
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const edit_adders_data = createAsyncThunk(
  "editaddersTable/post",
  async (data, thunkAPI) => {
    try {
      return await adders_table_Service.edit_adders_data_Action(data);
    } catch (error) {
      const massage =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(massage);
    }
  }
);

export const adders_Slice = createSlice({
  name: "addersTable",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLodaing = false;
      state.isDeleteLodaing = false;
      state.massage = "";
    },
  },
  extraReducers: {
    [get_all_adders_table.pending]: (state) => {
      state.isLodaing = true;
    },
    [get_all_adders_table.fulfilled]: (state, action) => {
      state.isLodaing = false;
      state.addersTable = action.payload;
      state.isSuccess = true;
    },
    [get_all_adders_table.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.addersTable = null;
      state.massage = action.payload;
    },

    //edit_adders_data
    [edit_adders_data.pending]: (state) => {
      state.isLodaing = true;
    },
    [edit_adders_data.fulfilled]: (state, { payload }) => {
      state.isLodaing = false;
      state.isSuccess = true;
      state.addersTable = payload;
    },
    [edit_adders_data.rejected]: (state, action) => {
      state.isLodaing = false;
      state.isError = true;
      state.massage = action.payload;
    },
  },
});

export const { reset } = adders_Slice.actions;
export default adders_Slice.reducer;
