import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Grid } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { TextField } from "@mui/material";
import { PrimaryButton } from "../../../Layouts/ButtonsControlled";
import { ModalContent } from "../../Modals/PmAssignModal/PmAssignModalStyle";
import CircularProgress from "@mui/material/CircularProgress";
import {
  store_procedures,
  edit_procedures,
  reset,
} from "../../../Redux/Slices/Procedures/ProceduresSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { get_procedures } from "../../../Redux/Slices/Procedures/ProceduresSlice";
import { getSingleTicket } from "../../../Redux/Slices/SingleTicketSlice/SingleTicketSlice";
import RichTextEditor from "../../../Common/richTextEditor/RichTextEditor";
const CountyModal = ({
  handleopenModal,
  handleCloseModal,
  setOpenModal,
  openModal,
  procedure,
}) => {
  const dispatch = useDispatch();
  const { lead_id } = useParams();
  const single_ticket = useSelector((state) => state.single_ticket);
  const county = single_ticket?.singleTicket?.data?.opportunity?.lead?.county;
  const [county_name, set_county_name] = useState();
  const [county_note, set_county_note] = useState("");
  const [county_noteValue, setcounty_noteValue] = useState();
  // selectedChoice.description

  const handleEditorChange = (content) => {
    setcounty_noteValue(content);
  };
  console.log(county_noteValue, "county_noteValue");
  const procedurest = useSelector((state) => state.procedures);
  console.log(procedurest?.procedures, ",m,m,modal");
  useEffect(() => {
    !single_ticket?.isLodaing && dispatch(get_procedures(county));
    dispatch(getSingleTicket(lead_id));
  }, [county, openModal]);
  console.log(procedurest?.isSuccess, "procedurest123");
  // const handleStore_business_license = async () => {
  //   const reqData = {
  //     county: county,
  //     text: county_noteValue,
  //   };
  //   await dispatch(store_procedures(reqData));
  //   await setOpenModal(false);
  // };

  const handleSubmitCountyModal = async (e) => {
    e.preventDefault();
    const reqData = {
      county: county,
      text: county_noteValue,
    };
    await dispatch(store_procedures(reqData));
    if (procedurest?.isSuccess) {
      await setOpenModal(false);
      await dispatch(reset());
    }
  };

  const handleSubmitCountyUpdatingModal = async (e) => {
    e.preventDefault();
    const reqData = {
      county: county,
      text: county_noteValue,
      procedure_id: procedure?.id,
      _method: "PUT",
    };
    await dispatch(edit_procedures(reqData));
    if (procedurest?.isSuccess) {
      await setOpenModal(false);
      await dispatch(reset());
    }
  };
  // React.useEffect(() => {
  //   let apis = async () => {
  //     if (procedurest?.isSuccess) {
  //       // await setOpenModal(false);
  //       await dispatch(reset());
  //     }
  //   };
  //   apis();
  // }, [procedurest?.isSuccess]);

  useEffect(() => {
    let apis = async () => {
      await dispatch(reset());
    };
    apis();
  }, []);
  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={handleopenModal}
      onClose={handleCloseModal}
    >
      {/* <form onSubmit={handleSubmitCountyModal}> */}
      <DialogContent>
        <ModalContent>
          <Grid className="display">
            <FormControl variant="outlined">
              <TextField
                style={{ width: "300px", marginTop: "50px" }}
                label="county"
                name="county"
                disabled
                value={county}
                onChange={(e) => set_county_name(e.target.value)}
              />
              <RichTextEditor
                required
                onChange={handleEditorChange}
                defaultValue={procedure?.id && procedure.text}
              />
            </FormControl>
          </Grid>
        </ModalContent>
      </DialogContent>
      <DialogActions>
        <PrimaryButton
          fullWidth
          type="submit"
          style={{
            padding: "0",
            width: "85%",
            margin: "15px auto",
            height: "42px",
          }}
          className="saveButton"
          disabled={procedurest?.isLodaing}
          onClick={
            procedure?.id
              ? handleSubmitCountyUpdatingModal
              : handleSubmitCountyModal
          }
        >
          {procedure?.id ? "Update" : "Save"}

          {procedurest?.isLodaing ? (
            <CircularProgress size={25} thickness={18} />
          ) : null}
        </PrimaryButton>
      </DialogActions>
      {/* </form> */}
    </Dialog>
  );
};
const ModalContentt = styled.div`
  padding: 30px 30px 20px;
  .display {
    display: flex;
    justify-content: center;
  }
`;

export default CountyModal;
