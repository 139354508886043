import axios from "axios";
import { designesList } from "../../../Api/Api";

const Get_designers_Action = async () => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${designesList}`,
    headers: headers,
    params: {
      return_all: "1",
    },
  });
  return response.data;
};

const designers_Service = {
  Get_designers_Action,
};
export default designers_Service;
