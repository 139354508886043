import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import styled from "styled-components";
import { Box } from "@mui/system";
import EditIcon from "@mui/icons-material/Edit";
import { Button, Typography  ,CardContent , Card , CardActionArea} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CallUtilityCompanyModal from "../../Components/Modals/CallUtilityCompany/CallUtilityCompany";
import { get_call_utility_companies_data } from "../../Redux/Slices/CallUtilityCompany/CallUtilityCompanySlice";
const CallUtilityCompany = () => {
  const [open_to_edit, set_Open_CallUtilityCompany_Modal] = useState();
  const dispatch = useDispatch();
  const { lead_id } = useParams();
  const call_utility_company = useSelector(
    (state) => state?.call_utility_companie
  );

  console.log(call_utility_company?.call_utility_companie , "call_utility_company")

  useEffect(() => {
    dispatch(get_call_utility_companies_data(lead_id));
  }, [lead_id, open_to_edit]);
  const handleopenModal = () => {
    set_Open_CallUtilityCompany_Modal(true);
  };
  const handleCloseModal = () => {
    set_Open_CallUtilityCompany_Modal(false);
  };
  console.log(open_to_edit, "open_to_edit");
  return (
    <>
     
        {call_utility_company?.isLodaing ? (
    <h1>loading</h1>
  ) : call_utility_company?.call_utility_companie !== null ? (

    <MainTabsStyled>
          <Box
          sx={{ width: "100%", typography: "body1" }}
          style={{ backgroundColor: "white" }}
        >
          <div class="main-container">
            {/* <div class="heading">
              <h1 class="heading__title">Call utility company card details </h1>
           
            </div> */}
            <div class="">
              <div class="">
              <Grid  xs={12} style={{  }} >
      <Card

        style={{
          background: "rgb(249, 245, 255)",
          border: "1px solid rgb(214, 187, 251)",
          borderRadius: "8px",
          padding: "1rem",
          margin: "0.5rem 0px",
          position: "relative",


        }}
      >


        <CardContent
          // style={{ marginTop: "40px" }}

        >
                <Grid
                  // container
                  // rowSpacing={1}
                  // columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={12}>
                    <Typography style={{color: "#7e7ec0", }}>
                      What exactly is the process of acquiring PTO (Permission
                      to Operate) in the utility company.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      {
                        call_utility_company?.call_utility_companie?.data
                          ?.process_of_acquiring_PTO
                      }
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography style={{color: "#7e7ec0", }}>
                      How to submit for interconnection
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      {
                        call_utility_company?.call_utility_companie?.data
                          ?.submit_for_interconnection
                      }
                    </Typography>
                  </Grid>
                  {call_utility_company?.call_utility_companie?.data
                    ?.submit_for_interconnection == "online" && (
                    <>
                      <Grid item xs={12}>
                        <Typography style={{color: "#7e7ec0", }}>
                          submit for interconnection link
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>
                          {
                            call_utility_company?.call_utility_companie?.data
                              ?.submit_for_interconnection_link
                          }
                        </Typography>
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12}>
                    <Typography style={{color: "#7e7ec0", }}>
                      How long does it take to be approved
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      {
                        call_utility_company?.call_utility_companie?.data
                          ?.approval_time
                      }
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography style={{color: "#7e7ec0", }}>
                      when interconnection submitting before or after
                      installation?
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      {
                        call_utility_company?.call_utility_companie?.data
                          ?.when_submit_for_interconnection
                      }
                    </Typography>
                  </Grid>
                  {call_utility_company?.call_utility_companie?.data
                    ?.when_submit_for_interconnection ==
                    "before_installation" && (
                    <>
                      <Grid item xs={12}>
                        <Typography style={{color: "#7e7ec0", }}>
                          how long does it take from final inspection date for
                          PTO to be acquired
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>
                          {
                            call_utility_company?.call_utility_companie?.data
                              ?.before_installation_timeline_for_pto
                          }
                        </Typography>
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12}>
                    <Typography style={{color: "#7e7ec0", }}>
                      How much does it cost?
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      {call_utility_company?.call_utility_companie?.data?.cost}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography style={{color: "#7e7ec0", }}>
                      any special requirements on plans
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      {
                        call_utility_company?.call_utility_companie?.data
                          ?.any_special_requirements
                      }
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography style={{color: "#7e7ec0", }}>status</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      {
                        call_utility_company?.call_utility_companie?.data
                          ?.status
                      }
                    </Typography>
                  </Grid>
                </Grid>
               
                </CardContent>
                <CardActionArea> <p class="card__apply">
                  <Button onClick={handleopenModal}>
                    {" "}
                    Update <EditIcon />
                  </Button>
                </p></CardActionArea>
                </Card>
                </Grid>

               
              </div>
            </div>
          </div>
        </Box>
        </MainTabsStyled>
        ) : (
          <Typography align="center" variant="h5">No data</Typography>
        )
}
       
     
      {open_to_edit && (
        <CallUtilityCompanyModal
          open_to_edit={open_to_edit}
          set_Open_CallUtilityCompany_Modal={set_Open_CallUtilityCompany_Modal}
          handleopenModal={handleopenModal}
          handleCloseModal={handleCloseModal}
          lead_id={lead_id}
          initialValues={{
            process_of_acquiring_PTO:
              call_utility_company?.call_utility_companie?.data
                ?.process_of_acquiring_PTO,
            speed_up_process:
              call_utility_company?.call_utility_companie?.data
                ?.speed_up_process,
            submit_for_interconnection:
              call_utility_company?.call_utility_companie?.data
                ?.submit_for_interconnection,
            when_submit_for_interconnection:
              call_utility_company?.call_utility_companie?.data
                ?.when_submit_for_interconnection,
            submit_for_interconnection_link:
              call_utility_company?.call_utility_companie?.data
                ?.submit_for_interconnection_link,
            before_installation_timeline_for_pto:
              call_utility_company?.call_utility_companie?.data
                ?.before_installation_timeline_for_pto,
            approval_time:
              call_utility_company?.call_utility_companie?.data?.approval_time,
            cost: call_utility_company?.call_utility_companie?.data?.cost,
            status: call_utility_company?.call_utility_companie?.data?.status,
            any_special_requirements:
              call_utility_company?.call_utility_companie?.data
                ?.any_special_requirements,
          }}
          updated_id={call_utility_company?.call_utility_companie?.data?.id}
        />
      )}
    </>
  );
};
const MainTabsStyled = styled.div`
  margin: 1rem 0.8rem;
  border-radius: 5px;
  .main-container {
    padding: 30px;
  }

  .heading {
    text-align: center;
  }

  .heading__title {
    font-weight: 600;
  }

  .heading__credits {
    margin: 10px 0px;
    color: #888888;
    font-size: 25px;
    transition: all 0.5s;
  }

  .heading__link {
    text-decoration: none;
  }
  .heading__credits .heading__link {
    color: inherit;
  }

  /* CARDS */

  .cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .card {
    margin: 20px;
    padding: 20px;
    width: 930px;
    min-height: 700px;
    display: grid;
    grid-template-rows: 20px 50px 1fr 50px;
    border-radius: 10px;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    transition: all 0.2s;
  }

  .card:hover {
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
    transform: scale(1.01);
  }

  .card__link,
  .card__exit,
  .card__icon {
    position: relative;
    text-decoration: none;
    color: rgba(255, 255, 255, 0.9);
  }

  .card__link::after {
    position: absolute;
    top: 25px;
    left: 0;
    content: "";
    width: 0%;
    height: 3px;
    background-color: rgba(255, 255, 255, 0.6);
    transition: all 0.5s;
  }

  .card__link:hover::after {
    width: 100%;
  }

  .card__exit {
    grid-row: 1/2;
    justify-self: end;
  }

  .card__icon {
    grid-row: 2/3;
    font-size: 30px;
  }

  .card__title {
    grid-row: 3/4;
    font-weight: 400;
    color: #ffffff;
  }

  .card__apply {
    grid-row: 4/5;
    align-self: center;
  }

  /* CARD BACKGROUNDS */

  .card-1 {
    background: radial-gradient(#1fe4f5, #3fbafe);
  }

  .card-2 {
    background: radial-gradient(#fbc1cc, #fa99b2);
  }

  .card-3 {
    background: radial-gradient(#76b2fe, #b69efe);
  }

  .card-4 {
    background: radial-gradient(#60efbc, #58d5c9);
  }

  .card-5 {
    background: radial-gradient(#f588d8, #c0a3e5);
  }

  /* RESPONSIVE */

  @media (max-width: 1600px) {
    .cards {
      justify-content: center;
    }
  }
`;
export default CallUtilityCompany;
