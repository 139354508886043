import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Grid, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import { PrimaryButton } from "../../../Layouts/ButtonsControlled";
import { ModalContent } from "../../Modals/PmAssignModal/PmAssignModalStyle";
import { getTicketStatus } from "../../../Redux/Slices/TicketStatus/TicketStatusSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import { update_job_reviews_data } from "../../../Redux/Slices/JobReviewsWithSalesSlice/JobReviewsSlice";
import CircularProgress from "@mui/material/CircularProgress";
const EditJobReviewWithSales = ({
  handleopenModal,
  handleCloseModal,
  setOpenEditModal,
  job_review_data,
  openEditModal,
}) => {
  const dispatch = useDispatch();
  const { lead_id } = useParams();
  const [confirm_contract, set_confirm_contract] = React.useState(
    job_review_data?.job_reviews?.data?.confirm_contract
  );
  const [confirm_electrical_bill, set_confirm_electrical_bill] = React.useState(
    job_review_data?.job_reviews?.data?.confirm_electrical_bill
  );
  const [confirm_address, set_confirm_address] = useState(
    job_review_data?.job_reviews?.data?.confirm_address
  );
  const [is_upgrade_suggested, set_is_upgrade_suggested] = useState(
    job_review_data?.job_reviews?.data?.is_upgrade_suggested
  );
  const [is_re_roof, set_is_re_roof] = useState(
    job_review_data?.job_reviews?.data?.is_re_roof
  );
  const [is_re_decking, set_is_re_decking] = useState(
    job_review_data?.job_reviews?.data?.is_re_decking
  );
  const [re_decking_percentage, set_re_decking_percentage] = useState(
    job_review_data?.job_reviews?.data?.re_decking_percentage
  );
  const [main_panel_size, set_main_panel_size] = useState(
    job_review_data?.job_reviews?.data?.main_panel_size
  );
  const [status, set_status] = useState(
    job_review_data?.job_reviews?.data?.status
  );
  const handleChange_confirm_electrical_bill = (event) => {
    set_confirm_electrical_bill(event.target.checked);
    console.log(event.target);
    console.log(event.target.checked);
  };

  console.log(
    job_review_data?.job_reviews?.data?.confirm_contract,
    "job_review_with_saleslklkl"
  );
  console.log(
    job_review_data?.isSuccess,
    "job_review_with_sales?.isSuccesssss"
  );

  const handleChange_confirm_contract = (event) => {
    set_confirm_contract(event.target.checked);
    console.log(event.target);
    console.log(event.target.checked);
  };

  const handleChange_confirm_address = (event) => {
    set_confirm_address(event.target.checked);
    console.log(event.target);
    console.log(event.target.checked);
  };

  const handleChange_is_upgrade_suggested = (event) => {
    set_is_upgrade_suggested(event.target.checked);
    console.log(event.target);
    console.log(event.target.checked);
  };

  const handleChange_is_re_roof = (event) => {
    set_is_re_roof(event.target.checked);
    console.log(event.target);
    console.log(event.target.checked);
  };

  const handleChange_is_re_decking = (event) => {
    set_is_re_decking(event.target.checked);
    console.log(event.target);
    console.log(event.target.checked);
  };

  useEffect(() => {
    set_confirm_contract(job_review_data?.job_reviews?.data?.confirm_contract);
    set_confirm_electrical_bill(
      job_review_data?.job_reviews?.data?.confirm_electrical_bill
    );
    set_confirm_address(job_review_data?.job_reviews?.data?.confirm_address);
    set_main_panel_size(job_review_data?.job_reviews?.data?.main_panel_size);
    set_is_upgrade_suggested(
      job_review_data?.job_reviews?.data?.is_upgrade_suggested
    );
    set_is_re_roof(job_review_data?.job_reviews?.data?.is_re_roof);
    set_is_re_decking(job_review_data?.job_reviews?.data?.is_re_decking);
    set_re_decking_percentage(
      job_review_data?.job_reviews?.data?.re_decking_percentage
    );
    set_status(job_review_data?.job_reviews?.data?.status);
  }, [openEditModal]);
  const handleStore_call_supplier = async () => {
    const reqData = {
      id: job_review_data?.job_reviews?.data?.id,
      ticket_id: +lead_id,
      status: status,
      confirm_address: confirm_address ? 1 : 0,
      confirm_contract: confirm_contract ? 1 : 0,
      confirm_electrical_bill: confirm_electrical_bill ? 1 : 0,
      main_panel_size: main_panel_size,
      is_re_decking: is_re_decking,
      re_decking_percentage: re_decking_percentage,
      is_re_roof: is_re_roof,
      is_upgrade_suggested: is_upgrade_suggested,
    };
    await dispatch(update_job_reviews_data(reqData));
    job_review_data?.isSuccess
      ? await setOpenEditModal(false)
      : await setOpenEditModal(true);
    await dispatch(getTicketStatus(lead_id));
  };
  return (
    <Dialog
      maxWidth={"xs"}
      fullWidth
      open={handleopenModal}
      onClose={handleCloseModal}
    >
      <DialogContent>
        <ModalContent>
          <Box>
            <form>
              <Grid>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={confirm_contract}
                        name="confirm_contract"
                        onChange={handleChange_confirm_contract}
                        checked={confirm_contract}
                      />
                    }
                    label="Confirm Contract."
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        value={confirm_electrical_bill}
                        name="confirm_electrical_bill"
                        onChange={handleChange_confirm_electrical_bill}
                        checked={confirm_electrical_bill}
                      />
                    }
                    label="Confirm Electric Bill."
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        value={confirm_address}
                        name="confirm_address"
                        onChange={handleChange_confirm_address}
                        checked={confirm_address}
                      />
                    }
                    label="Confirm Adders."
                  />

                  <FormControl variant="outlined">
                    <InputLabel htmlFor="component-simple">
                      Main panel size
                    </InputLabel>
                    <TextField
                      style={{ width: "300px", marginTop: "50px" }}
                      name="main_panel_size"
                      value={main_panel_size}
                      onChange={(e) => set_main_panel_size(Number(e.target.value))}
                    />
                  </FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={is_upgrade_suggested}
                        name="is_upgrade_suggested"
                        onChange={handleChange_is_upgrade_suggested}
                        checked={is_upgrade_suggested}
                      />
                    }
                    label="is upgrade suggested"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        value={is_re_roof}
                        name="is_re_roof"
                        onChange={handleChange_is_re_roof}
                        checked={is_re_roof}
                      />
                    }
                    label="Is re roofing suggested?"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        value={is_re_decking}
                        name="is_re_decking"
                        onChange={handleChange_is_re_decking}
                        checked={is_re_decking}
                      />
                    }
                    label=" is re-decking included ?"
                  />
                  {is_re_decking == 1 && (
                    <FormControl variant="outlined">
                      <InputLabel htmlFor="component-simple">
                        what percentage of the roof are <br /> we allowed to
                        perform re-decking on?
                      </InputLabel>
                      <TextField
                        style={{ width: "300px", marginTop: "70px" }}
                        name="re_decking_percentage"
                        value={re_decking_percentage}
                        onChange={(e) =>
                          set_re_decking_percentage(e.target.value)
                        }
                      />
                    </FormControl>
                  )}

                  <Grid className="" style={{ marginTop: "10px" }}>
                    <FormControl variant="outlined">
                      <InputLabel htmlFor="component-simple">status</InputLabel>
                      <Select
                        style={{ width: "300px", marginTop: "15px" }}
                        value={status}
                        onChange={(e) => set_status(e.target.value)}
                      >
                        <MenuItem value="pending">pending </MenuItem>
                        <MenuItem value="completed">completed</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </FormGroup>
              </Grid>
            </form>
          </Box>
        </ModalContent>
      </DialogContent>
      <DialogActions>
        <PrimaryButton
          fullWidth
          style={{
            padding: "0",
            width: "85%",
            margin: "15px auto",
            height: "42px",
          }}
          className="saveButton"
          onClick={handleStore_call_supplier}
        >
          {job_review_data?.isLodaing ? (
            <CircularProgress size={25} thickness={18} />
          ) : null}
          Update
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default EditJobReviewWithSales;
