import React, { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import CardMedia from "@mui/material/CardMedia";
import {
  Button,
  Typography,
  Grid,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import LottieAnimation from "../LottieAnimation/LottieAnimation";
import loading from "../../Assets/lottie/loading.json";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import BusinessLicenseModal from "../Modals/BusinessLicenseModal/BusinessLicenseModal";
import {
  get_business_license,
  edit__business_license_data,
} from "../../Redux/Slices/businessLicenseSlice/businessLicenseSlice";
const BusinessLicenseData = ({ business_license_Data, ticket_status }) => {
  const [open_edit_business_license_modal, set_open_edit_business_license_modal_] = useState(false)
  const business_data = business_license_Data?.business_license;

  console.log(business_data, "business_data122")
  console.log(business_license_Data, "business_license_Data")
  const handleopenEditModal = () => {

    set_open_edit_business_license_modal_(true)
  }

  const handleCloseBusinessLicenseModal = () => {
    set_open_edit_business_license_modal_(false)
  }
  return (
    <>
      {/* {welcomCallDetails?.isLodaing ? (
        <LottieAnimation lotti={loading} width={180} height={180} />
      ) : (
        <Grid style={{ padding: "10px 27px" }}>
         <h1>kjkj</h1>
        </Grid>
      )} */}
      <Grid style={{ padding: "10px 27px" }}>
        {business_license_Data?.business_license !== null ? (
          <Card >
            <CardHeader
              title="Business License Data"
              subheader={`Created at: ${new Date(
                business_data?.data?.created_at
              ).toLocaleDateString("en-US", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })}
  
  `}
            />

            <CardContent>
              <Typography variant="h6" color="text.secondary">
                Status: {business_data?.data?.status}
              </Typography>
              <Typography variant="h6" color="text.primary">
                cost:{" "}
                <span style={{ color: "gray" }}>
                  {business_data?.data?.cost}
                </span>
              </Typography>
            </CardContent>
            <CardActions disableSpacing>
              <IconButton aria-label="share"
                onClick={handleopenEditModal}
              >
                <EditIcon />
              </IconButton>
            </CardActions>
            {open_edit_business_license_modal && (
              <BusinessLicenseModal
                openBusinessLicenseModal={open_edit_business_license_modal}
                set_open_edit_business_license_modal_={set_open_edit_business_license_modal_}
                ticket_statuss={ticket_status?.business_license}
                business_data={business_data}
                handleCloseBusinessLicenseModal={handleCloseBusinessLicenseModal}
                initialValues={{
                  status: business_data?.data?.status,
                  cost: business_data?.data?.cost
                }}


              />
            )}
          </Card>
        ) :  (

          <CardContent>
               <Typography align="center">there is no data for this card</Typography>
          </CardContent>
        )}


      </Grid>


    </>

  );
};

export default BusinessLicenseData;
