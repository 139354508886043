import axios from "axios";
import { Get_Single_Ticket } from "../../../Api/Api";

const Sigle_Ticket_Action = async (id) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${Get_Single_Ticket}/${id}`,
    headers: headers,
    // params: {
    //   ticket_id: data,
    // },
  });
  return response.data;
};

const singleTicketService = {
  Sigle_Ticket_Action,
};
export default singleTicketService;
