import axios from "axios";
import { sendDesignReq, designRequestDetails  , single_design_req_data} from "../../../Api/Api";

const send_design_req_Action = async (data) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "POST",
    baseURL: `${sendDesignReq}`,
    headers: headers,
    data: data,
  });
  return response.data;
};

const getDesignReqDetailsAction = async (id) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${designRequestDetails}/${id}`,
    headers: headers,
    // data: data,
  });
  return response.data;
};

const getSingleDesignReqDetailsAction = async (id) => {
  const { token } = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const response = await axios({
    method: "GET",
    baseURL: `${single_design_req_data}/${id}`,
    headers: headers,
    // data: data,
  });
  return response.data;
};

const DesignReqService = {
  send_design_req_Action,
  getDesignReqDetailsAction,
  getSingleDesignReqDetailsAction
};
export default DesignReqService;
